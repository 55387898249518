import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "../../../Components/Css/Students/Student.scss";
import Api from "../../../Api";

function InternshipPage() {
  const location = useLocation();
  const viewIntern = location.state;
  const [createForm, setCreateForm] = useState();
  const userEmail = localStorage.getItem("email");
  const userId = localStorage.getItem("userId");
  const role = localStorage.getItem("role");
  const id = viewIntern._id;

  useEffect(() => {
    getCreateForm();
  }, []);

  const getCreateForm = async () => {
    const response = await Api.get(`createForm/getForm/${id}`);
    setCreateForm([response?.data?.data]);
  };

  const onSubmit = () => {
    const details = {
      role: role,
      UserID: userId,
      userEmail: userEmail,
      jobTitle: viewIntern.jobTitle,
      jobRole: viewIntern.jobRole,
      careerLevel: viewIntern.careerLevel,
      companyWebsite: viewIntern.companyWebsite,
      email: viewIntern.email,
      experience: viewIntern.experience,
      jobId: viewIntern.jobId,
      internshipPeriod: viewIntern.internshipPeriod,
      locations: viewIntern.locations,
      jobDescription: viewIntern.jobDescription,
      qualification: viewIntern.qualification,
      salaryRange: viewIntern.salaryRange,
      skill: viewIntern.skill,
      technology: viewIntern.technology,
    };
    Api.post("blog/createinternship", details).then((res) => {
      console.log("Response:", res);
    });
  };

  return (
    <Container>
      <div>
        <h4 className="mt-3 text-center internship-heading">Internship Details</h4>
      </div>
      <div className="mt-3 ms-4">
        <h5 className="text-primary mb-4 text-center">{viewIntern.jobTitle}</h5>
        <Row className="ms-4">
          <Col xs={12} md={6} lg={4} className="internship-details-card">
            <h6>Job Role</h6>
            <p>{viewIntern.jobRole}</p>
          </Col>
          <Col xs={12} md={6} lg={4} className="internship-details-card">
            <h6>Skill</h6>
            <p>{viewIntern.skill}</p>
          </Col>
          <Col xs={12} md={6} lg={4} className="internship-details-card">
            <h6>Experience</h6>
            <p>{viewIntern.experience}</p>
          </Col>
          <Col xs={12} md={6} lg={4} className="internship-details-card">
            <h6>Qualification</h6>
            <p>{viewIntern.qualification}</p>
          </Col>
          <Col xs={12} md={6} lg={4} className="internship-details-card">
            <h6>Salary Range</h6>
            <p>{viewIntern.salaryRange}</p>
          </Col>
          <Col xs={12} md={6} lg={4} className="internship-details-card">
            <h6>Location</h6>
            <p>{viewIntern.location}</p>
          </Col>
          <Col xs={12} md={6} lg={4} className="internship-details-card">
            <h6>Language Preference</h6>
            <p>{viewIntern.languagePreference}</p>
          </Col>
          <Col xs={12} md={6} lg={4} className="internship-details-card">
            <h6>Internship Period</h6>
            <p>{viewIntern.internshipPeriod}</p>
          </Col>
          <Col xs={12} md={6} lg={4} className="internship-details-card">
            <h6>Company Mail ID</h6>
            <p>{viewIntern.companyMailId}</p>
          </Col>
          <Col xs={12} md={6} lg={4} className="internship-details-card">
            <h6>Company Website</h6>
            <p>
              <Link
                to={viewIntern.companyWebsite}
                target={process.env.NODE_ENV === "development" ? "_blank" : ""}
              >
                {viewIntern.companyWebsite}
              </Link>
            </p>
          </Col>
          <Col xs={12} md={6} lg={4} className="internship-details-card">
            <h6>Job Type</h6>
            <p>{viewIntern.jobType}</p>
          </Col>
          <Col xs={12} md={6} lg={4} className="internship-details-card">
            <h6>Job Mode</h6>
            <p>{viewIntern.jobMode}</p>
          </Col>
          <Col xs={12} md={6} lg={6} className="internship-details-card">
            <h6>Company Description</h6>
            <p>{viewIntern.companyDescription}</p>
          </Col>
          <Col xs={12} md={6} lg={6} className="internship-details-card">
            <h6>Job Description</h6>
            <p>{viewIntern.jobDescription}</p>
          </Col>
        </Row>
        <div className="d-flex justify-content-center">
          <Button
            className="login-button mt-3"
            variant="primary"
            onClick={onSubmit}
          >
            Apply
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default InternshipPage;
