import React from 'react'
import { Col, Row, Card } from "antd";
import {  FaGraduationCap } from "react-icons/fa";
import {  BiBuilding } from "react-icons/bi";
import "../../../Components/Css/Dashboard.scss";
import { NavLink } from "react-router-dom";

function EmployeeDashboard() {
  return (
    <div>
        <Row className="admin-row ">
        <Col sm={24} md={12} lg={8} className="dash-col">
          <Card className="Dash-cards">
            <div className="card-field">
              <div>
                <FaGraduationCap
                  className="dashIcons"
                  // style={{ fontSize: "350%", color: "#4f7eb5" }}
                />
              </div>
              <div>
                <h4 className="fieldsname">Interview</h4>
                <h4 className="fieldscount">2</h4>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm={24} md={12} lg={8} className="dash-col">
          <Card className="Dash-cards">
            <div className="card-field">
              <div>
                <BiBuilding
                  className="dashIcons"
                  // style={{ fontSize: "350%", color: " #4f7eb5" }}
                />
              </div>
              <div>
                <h4 className="fieldsname"> Client Interview</h4>
                <h4 className="fieldscount">1</h4>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm={24} md={12} lg={8} className="dash-col">
          <Card className="Dash-cards">
            <div className="card-field">
              <div>
                <BiBuilding
                  className="dashIcons"
                  // style={{ fontSize: "350%", color: " #4f7eb5" }}
                />
              </div>
              <NavLink to="">
                <h4 className="fieldsname">Notifications</h4>
                <h4 className="fieldscount">10</h4>
              </NavLink>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default EmployeeDashboard