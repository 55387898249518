import { Button } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import Api from "../../../../Api.js";
// import DataListTable from "../DataListTable";
// import EmployerListActions from "./EmployerListActions";
import Api from "../../../Api.js";
import DataListTable from "../../Admin/Dashboard/DataListTable.js";
// import EmployerListActions from "../../Admin/Dashboard/EmployerList/EmployerListActions.js";
// import JobListActions from "./JobListActions.js";

const JobList = () => {
  const [getData, setGetData] = useState();
  const navigate = useNavigate();
  const id = localStorage.getItem("id");
  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    Api.get(`createForm/getForm/${id}`)
      .then((res) => {
        setGetData(res.data.data);
      })
      .catch((error) => console.log(error));
  };

  const handleSearch = (e) => {
    const employerName = e.target.value;
    const newData = getData.filter((list) =>
      list.employerName.toLowerCase().includes(employerName.toLowerCase())
    );
    setGetData(newData);
    if (employerName === "") {
      setGetData();
    }
  };

  const columns = [
    {
      title: "Role",
      dataIndex: "jobRole",
    },
    {
      title: "Job title",
      dataIndex: "jobTitle",
    },
    {
      title: "Technology",
      dataIndex: "technology",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <div>
          {console.log("recordJobid", record?.jobId)}
          <Button
            type="primary"
            onClick={() => {
              navigate(`jobrequest/${record?.jobId}`);
            }}
          >
            View Applicants
          </Button>
          {/* <JobListActions record={record} /> */}
        </div>
      ),
    },
  ];

  return (
    <DataListTable
      showAddNewBtn={false}
      columns={columns}
      dataList={getData}
      handleSearch={handleSearch}
      title="Job List"
    />
  );
};

export default JobList;
