import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Api from "../../../Api";
import "../../../Components/seminar/viewSeminar.scss";
import { Card } from "react-bootstrap";

export default function SeminarRequest() {
  const [createForm, setcreateForm] = useState([]);
  const id = localStorage.getItem("userId");
  useEffect(() => {
    getCreateForm();
  }, []);

  const getCreateForm = async () => {
    await Api.get(`seminar/getoneseminar/${id}`).then((res) => {
      setcreateForm(res.data.data);
    });
  };
  const navigate = useNavigate();
  const onSubmit = (data) => {
    navigate("Seminarinnerpage", { state: data });
  };

  return (
    <div>
      <div className="viewseminar_container p-4">
        <div className="pages-title mb-3">Seminars</div>
        <Row className="p-3">
          {createForm.map((data, i) => (
            <Col sm={24} md={6} lg={5} style={{marginLeft:'30px'}}>
              <Card style={{width:'450px'}}>
             
                  
                    <Card.Header style={{backgroundColor:' #0073e6'}}>
                     
                      <h4 className="seminar_card_title" style={{fontSize:'23px', fontWeight:'750', marginTop:'5px', color:'azure', letterSpacing:'1px'}}>
                        {data.seminarTitle}
                      </h4>
                      <span style={{color:" rgb(220, 221, 222)"}}>{data.technology}</span>
                      </Card.Header>
                      <Card.Body>
                      <div >
                        <div >
                        
                          <p style={{fontSize:'18px'}}>Time :&nbsp; {data.fromdate} - {data.todate}</p>
                            <p style={{fontSize:'18px'}}>Time :&nbsp;{data.time}</p>
                            {/* <span>{data.technology}</span> */}
                           
                        </div>
                      </div>
                      
                          <p className="seminar_card_access" style={{fontSize:'18px'}} >
                            Access : {data.audience}
                          </p>
                          <p className="seminar_card_mode" style={{fontSize:'18px'}} >
                            Mode : {data.SeminarMode}
                          </p>
                     
                     
                          <p className="seminar_card_name" style={{fontSize:'18px'}} >
                            Contact person : {data.contactPerson}
                          </p>
                          <p className="seminar_card_number" style={{fontSize:'18px'}} >
                            Contact number : {data.contactNumber}
                          </p>
                      
                      <p className="seminar_card_link" style={{fontSize:'18px'}} >
                        {data.registrationLink}
                      </p>
                      <p className="seminar_card_link" style={{fontSize:'18px'}} >{data.fees}</p>
                      </Card.Body>
                      <Card.Footer style={{display:'flex', justifyContent:'end'}}>
                      <div >
                        <button className="clg-semi-btn"onClick={() => {onSubmit(data);}}>
                          View Details
                        </button>
                      </div>
                      </Card.Footer>
                    
            
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}
