import React from 'react'
import { Row, Col } from "antd";

function Questions() {
   
  return (
    <div>
        
      <div className="pages-title mt-5 ms-5">Questions</div>
         <Row className='mt-5 ms-5'>
          <Col sm={24} md={8} className="Stud-blog-col p-2">
              <label>What are the questions asked?</label>
              <textarea rows="3" className="Stud-blog-input" />
            </Col>
          </Row>
          <div className="submitbuttons p-2">
            <button className="button1 m-2 p-2" type="submit">
              Submit
            </button>
            <button className="button2 m-2 p-2" type="reset">
              Reset
            </button>
          </div>
    </div>
  )
}

export default Questions