import { EMAIL_VERIFICATION } from "../type";

const initialState = {
  verifyUrl: false,
};

export const emailVerificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMAIL_VERIFICATION: {
      return {
        ...state,
        verifyUrl: action.payload,
      };
    }
    default:
      return state;
  }
};
