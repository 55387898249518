import React, { useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import ModernCV from "./ModernCV";
import ClassicCV from "./ClassicCV";
import MinimalCV from "./MinimalisticCV";
import InfographicCV from "./InfographicCV";

const App = () => {
  // Set "modern" as the default template
  const [selectedTemplate, setSelectedTemplate] = useState("modern");

  const handleTemplateClick = (template) => {
    setSelectedTemplate(template);
  };

  return (
    <div className="app-container" style={{ paddingTop: "3%" }}>
      <Row>
        <Col md={9}>
          <div className="cv-preview">
            <div className="cv-template">
              {selectedTemplate === "modern" && <ModernCV />}
              {selectedTemplate === "classic" && <ClassicCV />}
              {selectedTemplate === "minimal" && <MinimalCV />}
              {selectedTemplate === "infographic" && <InfographicCV />}
            </div>
          </div>
        </Col>

        <Col md={3} style={{ padding: "2%" }}>
          <Card style={{ textAlign: "center" }}>
            <h3>Select CV Template</h3>
            <div className="cv-card-container">
              <div
                className="cv-card"
                onClick={() => handleTemplateClick("modern")}
              >
                <h5 style={{cursor:"pointer"}}>Modern</h5>
              </div>
              <div
                className="cv-card"
                onClick={() => handleTemplateClick("classic")}
              >
                <h5 style={{cursor:"pointer"}}>Classic</h5>
              </div>
              <div
                className="cv-card"
                onClick={() => handleTemplateClick("minimal")}
              >
                <h5 style={{cursor:"pointer"}}>Minimalistic</h5>
              </div>
              <div
                className="cv-card"
                onClick={() => handleTemplateClick("infographic")}
              >
                <h5 style={{cursor:"pointer"}}>Infographic</h5>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default App;
