import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Select } from "antd";
import "../../../Components/Css/Students/Student.scss";
import Api from "../Api";
import { Dropdown } from "primereact/dropdown";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Option } from "antd/lib/mentions/index.js";
import "aos/dist/aos.css";
import AOS from "aos";

function PersonalInfo() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Set animation duration in milliseconds
      easing: "ease-in-out", // Animation easing
    });
  }, []);

  const [countryList, setCountryList] = useState([]);
  const [countryValue, setCountryValue] = useState();
  const [stateList, setStateList] = useState([]);
  const [stateValue, setStateValue] = useState();
  const [districtList, setDistrictList] = useState([]);
  const [districtValue, setDistrictValue] = useState();
  const [cityList, setCityList] = useState([]);
  const [cityValue, setCityValue] = useState();
  const [universityList, setUniversityList] = useState([]);
  const [collegeList, setCollegeList] = useState([]);
  const [autonomousList, setAutonomousList] = useState([]);
  const [instituteValue, setInstituteValue] = useState();
  const [institute, setInstitute] = useState([]);
  const [college, setCollege] = useState([]);
  const [collegeValue, setCollegeValue] = useState();
  const [user, setUser] = useState(null);
  const [collegestype, setCollegestype] = useState("");
  // const userId = localStorage.getItem("userId");
  const [collegetype, setCollegetype] = useState();
  const [userData, setUserData] = useState({});

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm({});

  const navigate = useNavigate();
  const [resres, setResres] = useState({
    status: null,
    message: null,
  });
  const onGender = (e) => {
    setCollegestype(e.value);
  };

  const collegetypeOption = [
    {
      label: "Affilated",
      value: "Affilated",
    },
    {
      label: " University",
      value: "University",
    },
    {
      label: "Deemed University",
      value: "Deemed University",
    },
    { label: "Others", value: "Others" },
  ];

  // useEffect(() => {
  //   console.log("User data:", user); // Add this log to check user data
  //   // Add this log to check institute options
  //   if (user && user.university) {
  //     console.log("University value:", user.university);
  //     setValue("instituteName",user.university);
  //   }
  // }, [user]);

  useEffect(() => {
    getInstitute();
    getUniversity();
    getCountry();
    getDetails();
    getState();
  }, []);
  useEffect(() => {
    if (countryValue) {
      getState(countryValue);
    }
  }, [countryValue]);

  const getUniversity = async () => {
    try {
      const res = await Api.get("fields/getAll");
      setUniversityList(res.data.data);
    } catch (error) {
      console.error("Failed to fetch universities:", error);
    }
  };

  const getCountry = async () => {
    try {
      const res = await Api.get("country/getallcountry");
      setCountryList(res.data.data);
    } catch (error) {
      console.error("Failed to fetch countries:", error);
    }
  };

  const getState = async (country_id) => {
    console.log("country id:", country_id);
    setCountryValue(country_id);
    try {
      const res = await Api.get(`state/stateById/${country_id}`);
      setStateList(res.data.data);
      console.log(setStateList);
    } catch (error) {
      console.error("Failed to fetch states:", error);
    }
  };

  const getDistrict = async (state_id) => {
    setStateValue(state_id);
    try {
      const res = await Api.get(`district/districtById/${state_id}`);
      setDistrictList(res.data.data);
    } catch (error) {
      console.error("Failed to fetch districts:", error);
    }
  };

  const getCity = async (districtId) => {
    setDistrictValue(districtId);
    try {
      const res = await Api.get(`city/cityById/${districtId}`);
      setCityList(res.data.data);
    } catch (error) {
      console.error("Failed to fetch cities:", error);
    }
  };

  const getInstitute = async () => {
    try {
      const res = await Api.get("fields/getUniversityList");
      setInstitute(res.data.data);
      console.log("Institute data:", res.data.data);
    } catch (error) {
      console.error("Error fetching institute data:", error);
    }
  };

  const getCollege = (university_id) => {
    console.log("university_ID:", university_id);
    setInstituteValue(university_id);
    Api.get(`fields/getAllCollege/${university_id}`).then((res) => {
      setCollege(res.data.data);
      console.log("college_list:", res.data.data);
    });
  };

  // const prefillForm = (data) => {
  //   if (!data) return;
  //   console.log("Setting form values with user data:", data);

  //   // const instituteId = instituteValue ? instituteValue._id : null;
  //   // console.log("Prefilling university with ID:", instituteId);
  //   setValue("instituteName", data.university);
  //   setValue("college", data.college);
  //   setValue("address", data.address);
  //   setValue("country", data.country);
  //   setValue("state", data.state);
  //   setValue("district", data.district);
  //   setValue("city", data.city);
  //   setValue("pincode", data.pincode);
  //   setValue("instituteType", data.collegetype);
  //   setValue("websiteUrl", data.website);
  //   setValue("email", data.email);
  //   console.log("Form values set:", getValues());
  // };
  useEffect(() => {
    if (user) {
      setValue("university", user.university);
      setValue("college", user.college);
      setValue("collegetype", user.collegetype);
      setValue("address", user.address);
      setValue("country", user.country);
      setValue("state", user.state);
      setValue("district", user.district);
      setValue("city", user.city);
      setValue("pincode", user.pincode);
      setValue("instituteType", user.collegetype);
      setValue("websiteUrl", user.website);
      setValue("email", user.email);
    }
  }, [user]);

  const getDetails = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        console.error("User ID not found in localStorage");
        return;
      }

      const res = await Api.get(`college/college_get/${userId}`);
      console.log("Response data:", res.data);

      const data = res.data.data || res.data; // Adjust based on actual structure
      if (data) {
        setUser(data);
      } else {
        console.error("Data object is missing in response");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Endpoint not found (404):", error.response);
      } else {
        console.error("Error fetching details:", error);
      }
    }
  };
  const handleFormSubmit = async (data) => {
    try {
      const Details = {
        university: data.university,
        college: data.college,
        // address: data.address,
        // country: data.country,
        // state: data.state,
        // district: data.district,
        // city: data.city,
        // pincode: data.pincode,
        collegetype: data.collegetype,
        Name: data.Name,
        Designation: data.Designation,
        Degree: data.Degree,
        Department: data.Department,
        PhoneNumber: data.PhoneNumber,
        // website: data.websiteUrl,
        // email: data.email,
      };
      await Api.post(`collegeprofile/createprofile`, Details);
      console.log("Profile created/updated successfully");
    } catch (error) {
      console.error("Failed to create/update profile:", error);
    }
  };
  console.log("instituteName:", getValues("university"));
  console.log("Institute array:", institute);
  console.log("college array:", college);
  console.log("countrylist:", countryList);
  console.log("statelist:", stateList);
  return (
    <div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="personal-informations p-4">
          <div className="pages-title mb-3">Institute Information</div>
          <div data-aos="zoom-in">
            <Row className="mb-2">
              <Col sm={24} md={8} className=" p-2">
                <label className="input-title">University Name </label>
                {/* <Dropdown
                filter
                className="input-field"
                value={getValues("university")}
                options={institute}
                {...register("university", { required: true })}
                optionLabel={"UniversityName"}
                optionValue={"_id"}
                onChange={(e) => {
                  setValue("university", e.value);
                  getCollege(e.value);
                }}
              /> */}
                <Select
                  className="input-field"
                  value={getValues("university")}
                  onChange={(value) => {
                    setValue("university", value);
                    getCollege(value);
                  }}
                  placeholder="Select a University"
                  style={{ border: "none" }}
                >
                  {institute?.map((option) => (
                    <Option
                      key={option._id}
                      value={option._id}
                      label={option.UniversityName}
                    >
                      {option.UniversityName}
                    </Option>
                  ))}
                </Select>
                {errors.university && (
                  <p className="Stud-personal-error">University is required</p>
                )}
              </Col>
              <Col sm={24} md={8} className=" p-2">
                <label className="input-title">College Name</label>
                {/* <Dropdown
                filter
                className="input-field"
                value={getValues("college")}
                options={college}
                {...register("college", { required: true })}
                optionLabel={"collegeName"}
                optionValue={"_id"}
                
                 onChange={(e) => setCollegeValue(e.value)}
              /> */}
                <Select
                  className="input-field"
                  value={getValues("college")}
                  onChange={(value) => {
                    setValue("college", value);
                    setCollegeValue(value);
                  }}
                  placeholder="Select a University"
                  style={{ border: "none" }}
                >
                  {college?.map((option) => (
                    <Option
                      key={option._id}
                      value={option._id}
                      label={option.collegeName}
                    >
                      {option.collegeName}
                    </Option>
                  ))}
                </Select>

                {errors.collegeName && (
                  <p className="Stud-personal-error">
                    College Name is required
                  </p>
                )}
              </Col>

              <Col sm={24} md={8} className="p-2">
                {/* <div className="status p-1">
                <Row style={{ display: "flex" }}>
                  <Col className="radioGroup" xs={24} sm={12} md={5}>
                    <input
                      type="radio"
                      value="affiliated"
                      {...register("instituteType", { required: true })}
                      className="radioGroup__input m-2"
                    />
                    Affiliated College
                  </Col>
                  <Col className="radioGroup" xs={24} sm={12} md={5}>
                    <input
                      type="radio"
                      value="university"
                      {...register("instituteType", { required: true })}
                      className="radioGroup__input m-2"
                    />
                    University
                  </Col>
                  <Col className="radioGroup" xs={24} sm={12} md={5}>
                    <input
                      type="radio"
                      value="deemedUniversity"
                      {...register("instituteType", { required: true })}
                      className="radioGroup__input m-2"
                    />
                    Deemed-University
                  </Col>
                  <Col className="radioGroup" xs={24} sm={12} md={5}>
                    <input
                      type="radio"
                      value="others"
                      {...register("instituteType", { required: true })}
                      className="radioGroup__input m-2 m-2"
                    />
                    Others
                  </Col>
                </Row>
              </div> */}
                <label className="mb-1">College Type</label>
                <Select
                  filter
                  name="collegetype"
                  className="input1"
                  value={getValues("collegetype")}
                  options={collegetypeOption}
                  // options={universityList}
                  onChange={onGender}
                  {...register("collegetype", {
                    required: true,
                    onChange: (e) => {
                      setCollegetype(e.target.value);
                    },
                  })}
                />
                {errors.collegetype?.type === "required" && (
                  <p className="error-text-color">College is required</p>
                )}
              </Col>
            </Row>

            <Row className="mb-2">
              {/* <Col xs={24} sm={24} md={8} className=" p-2">
              <label className="input-title">College</label>
              <br></br>
             
              <input
                className="input-field"
                {...register("college", { required: true })}
              />
              <br />
              {errors.college && (
                <span className="input-error">College required</span>
              )}
            </Col> */}
              {/* <Col xs={24} sm={24} md={8} className=" p-2">
              <label className="input-title">Atomonous College</label>
              <br></br>
         
              <input
                className="input-field"
                {...register("autonomous", { required: true })}
              />
              <br />
              {errors.autonomous && (
                <span className="input-error">
                  Autonomous college is required
                </span>
              )}
            </Col> */}
              <Col xs={24} sm={24} md={8} className=" p-2">
                <label>Country</label>
                <br />
                <Select
                  className="input-field"
                  value={getValues("country")}
                  onChange={(value) => {
                    setValue("country", value);
                    getState(value); // Reset state value when country changes
                  }}
                  placeholder="Select a Country"
                  style={{ border: "none" }}
                >
                  {countryList?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
                {errors.country && (
                  <p className="error-text-color">country is required</p>
                )}
              </Col>
              <Col xs={24} sm={24} md={8} className=" p-2">
                <label>State</label>
                <br />
                <Select
                  className="input-field"
                  value={getValues("state")}
                  onChange={(value) => {
                    setValue("state", value);
                    getDistrict(value);
                  }}
                  placeholder="Select a State"
                  style={{ border: "none" }}
                >
                  {stateList?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
                <br />
                {errors.State && (
                  <span className="input-error">State is required</span>
                )}
              </Col>
              {/* <Col xs={24} sm={24} md={8} className=" p-2">
              <label className="input-title">District</label>
              <br />
              <select
                className="input-field"
                {...register("district", { required: true })}
              >
                <option value="">Select District</option>
                <option value="Thanjavur">Thanjavur</option>
                <option value="Chennai">Chennai</option>
                <option value="Cuddalore">Cuddalore</option>
                <option value="vellore">vellore</option>
              </select>
              <br />
              {errors.district && (
                <span className="Stud-personal-error">
                  District is required
                </span>
              )}
            </Col> */}
              <Col xs={24} sm={24} md={8} className=" p-2">
                <label>District</label>
                <br />
                {/* <Dropdown
                filter
                className="input-field"
                value={districtValue}
                options={districtList}
                {...register("district", { required: true })}
                optionLabel={"name"}
                optionValue={"id"}
                onChange={(e) => getCity(e.value)}
              /> */}
                <Select
                  className="input-field"
                  value={getValues("district")}
                  onChange={(value) => {
                    setValue("district", value);
                    getCity(value);
                  }}
                  placeholder="Select a State"
                  style={{ border: "none" }}
                >
                  {districtList?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>

                {errors.district && (
                  <p className="error-text-color">District is required</p>
                )}
              </Col>
              <Col xs={24} sm={24} md={8} className=" p-2">
                <label>City</label>
                <br />
                {/* <Dropdown
                className="input-field"
                value={cityValue}
                options={cityList}
                {...register("city", { required: true })}
                optionLabel={"cityName"}
                optionValue={"_id"}
                onChange={(e) => setCityValue(e.value)}
                placeholder="Select a city"
              /> */}
                <Select
                  className="input-field"
                  value={getValues("city")}
                  onChange={(value) => {
                    setValue("city", value);
                  }}
                  placeholder="Select a State"
                  style={{ border: "none" }}
                >
                  {cityList?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>

                {errors.city && <p className="text-danger">City is required</p>}
              </Col>

              <Col xs={24} sm={24} md={8} className=" p-2">
                <label className="input-title">Pincode </label>
                <br />
                <input
                  type="number"
                  className="input-field"
                  {...register("pincode", {
                    required: true,
                    pattern: {
                      value: /^0*?\d{6}$/,
                    },
                  })}
                />

                <br />
                {errors.pincode && (
                  <span className="Stud-personal-error">
                    Pincode is required
                  </span>
                )}
              </Col>

              <Col sm={12} md={8} className=" p-2">
                <label className="input-title">Address </label>
                <br />
                <input
                  className="input-field"
                  {...register("address", { required: true })}
                />

                <br />
                {errors.address && (
                  <span className="Stud-personal-error">
                    Address is required
                  </span>
                )}
              </Col>
            </Row>

            <Row className="mb-2">
              <Col xs={24} sm={24} md={8} className=" p-2">
                <label className="input-title">Contact Person </label>
                <br />
                <input
                  className="input-field"
                  {...register("Name", { required: true })}
                />

                <br />
                {errors.Name && (
                  <span className="Stud-personal-error">Name is required</span>
                )}
              </Col>
              <Col xs={24} sm={24} md={8} className=" p-2">
                <label className="input-title">Designation </label>
                <br />
                <input
                  className="input-field"
                  {...register("Designation", { required: true })}
                />

                <br />
                {errors.Designation && (
                  <span className="Stud-personal-error">
                    Designation is required
                  </span>
                )}
              </Col>
              <Col xs={24} sm={24} md={8} className=" p-2">
                <label className="input-title"> Degree </label>
                <br />
                <input
                  className="input-field"
                  {...register("Degree", { required: true })}
                />

                <br />
                {errors.Degree && (
                  <span className="Stud-personal-error">
                    Degree is required
                  </span>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={24} sm={24} md={8} className=" p-2">
                <label className="input-title"> Department </label>
                <br />
                <input
                  className="input-field"
                  {...register("Department", { required: true })}
                />

                <br />
                {errors.Department && (
                  <span className="Stud-personal-error">
                    Department is required
                  </span>
                )}
              </Col>
              <Col xs={24} sm={24} md={8} className=" p-2">
                <label className="input-title">Contact Number </label>
                <br />
                <input
                  className="input-field"
                  {...register("PhoneNumber", { required: true })}
                />

                <br />
                {errors.PhoneNumber && (
                  <span className="Stud-personal-error">
                    Contact number is required
                  </span>
                )}
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={24} sm={24} md={8} className=" p-2">
                <label className="input-title">Website </label>
                <br />
                <input
                  className="input-field"
                  {...register("websiteUrl", { required: true })}
                />

                <br />
                {errors.websiteUrl && (
                  <span className="Stud-personal-error">
                    Website is required
                  </span>
                )}
              </Col>
              <Col xs={24} sm={24} md={8} className=" p-2">
                <label className="input-title">E-mail </label>
                <br />
                <input
                  className="input-field"
                  {...register("email", { required: true })}
                />

                <br />
                {errors.email && (
                  <span className="Stud-personal-error">
                    E-mail is required
                  </span>
                )}
              </Col>
            </Row>

            <div className="submitbuttons p-2">
              <button className="button1 m-2 p-2" type="submit">
                Submit
              </button>
              <button className="button2 m-2 p-2" type="reset">
                Reset
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default PersonalInfo;
