import React, { useEffect, useState } from "react";
import { Table, Button, Input, Popconfirm, Modal } from "antd";
import "./score.scss";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
// import Api from "../../../Api";
import { useNavigate } from "react-router-dom";
// import DataListTable from "../datalistTable";

function StudentScore() {
  const [techList, setTechList] = useState([]);
  const [getData, setGetData] = useState();

  console.log(getData, "ghjhjhgjh");

  useEffect(() => {
    getPattern();
  }, []);

  const getPattern = async () => {
    // await Api.get("ExamPattern/get").then((res) => {
    //   console.log(res.data, "////");
    //   setTechList(res.data);
    // });
  };

  const columns = [
    {
      title: "Technology",
      dataIndex: "Technology",
    },
    {
      title: "week",
      dataIndex: "week",
    },
    {
      title: "score",
      dataIndex: "score",
    },
  ];

  const onSearch = "";
  const navigate = useNavigate();
  return (
    <div>
      <div className="data-list-main">
        <h4 className="data-list-header">Score </h4>

        <div className=".Datalist-Table">
          <Table
            columns={columns}
            dataSource={getData ? getData : techList}
            pagination={true}
            className="mt-2"
          />
        </div>
      </div>
    </div>
  );
}

export default StudentScore;
