import React from "react";
import { Row, Col } from "react-bootstrap";
import "../../Components/Css/Freelancing.scss";
import picture from "../../assets/red.jpg";
import Find from "../../assets/FindRed1.jpg";
import Rewards from "../../assets/Rewards.jpg";
import Approval from "../../assets/Approval.jpg";
import { Carousel } from "antd";
import { useNavigate } from "react-router-dom";

const Freelancing = () => {
  const FreelancingCard = [
    {
      id: 1,
      title: "POST PROJECT",
      imageurl: picture,
      description:
        "Post a Project to tell us about your project. We’ll quickly match you with awesome freelancers",
    },
    {
      id: 2,
      title: "FIND & HIRE",
      imageurl: Find,
      description:
        "Browse proposals, profiles of freelancers & their reviews. Compare, Interview & finalise the candidate",
    },
    {
      id: 3,
      title: "AWARD & PAY",
      imageurl: Rewards,
      description:
        "Award Project to the finalised freelancer on Truelancer & enjoy 100% money back guarantee using Safe Deposit",
    },
    {
      id: 4,
      title: "WORK & APPROVE",
      imageurl: Approval,
      description:
        "Use Cross Hurdle to chat, share files & collaborate with your freelancer. Release the payment once satisfied.",
    },
  ];
  const navigate = useNavigate();
  return (
    <div className="Container">
      <Row>
        <Col md={4} sm={12} className="ButtonCol">
          <div className="btnContainer ">
            <button
              className="button1"
              onClick={() => navigate("/freelancePost")}
            >
              POST
            </button>
            <button
              style={{ width: "150px" }}
              className="button1 m-2"
              onClick={() => navigate("/freelancingPay")}
            >
              YOU GOT PAY
            </button>
          </div>
        </Col>
        <Col md={8} sm={12}>
          <Carousel autoplay>
            <div>
              <img
                className="freelancerCarouselImg1"
                src="https://img.freepik.com/free-vector/business-teamwork-concept-teamwork-leadership-effort-hard-work-team-strategy-concept-brainstorm-workshop-management-skills-vector-cartoon-illustration-flat-design_1150-56223.jpg?w=740&t=st=1672386034~exp=1672386634~hmac=1579c5002fefcc362187c1faac46de9b3fc2a5d7a5be769844eac4c85f76adfb"
                alt=""
              />
            </div>
            <div>
              <img
                className="freelancerCarouselImg1"
                src="https://img.freepik.com/premium-vector/business-plan-target-with-team-discussion-achieve-target-goals-with-list-task_25147-322.jpg?w=1060"
                alt=""
              />
            </div>
            <div>
              <img
                className="freelancerCarouselImg1"
                src="https://img.freepik.com/free-vector/low-code-development-concept-illustration_114360-7294.jpg?w=740&t=st=1672386148~exp=1672386748~hmac=f2ac4cb1aeb5618d185edd53d857eaa305580a404a6f88541c0a20130e487a34"
                alt=""
              />
            </div>
            <div>
              <img
                className="freelancerCarouselImg1"
                src="https://img.freepik.com/free-vector/programming-concept-illustration_114360-1213.jpg?w=740&t=st=1672399652~exp=1672400252~hmac=b0171b6ade55fb55f348083b8bf19b8c4eb2366f8dff915a7e1e8a8604c63b82"
                alt=""
              />
            </div>
          </Carousel>
        </Col>
      </Row>

      <div className="Freelancing_Mainpage_title">HOW IT WORKS</div>
      <div className="Card_conttent">
        <Row className="cardRow_contents ms-2 ">
          {FreelancingCard.map((i) => (
            <Col className="col1 mt-4 mb-5 " md={3} sm={12}>
              <img
                className="card_contentImages"
                src={i.imageurl}
                alt="post_project"
              />
              <h6>{i.title}</h6>
              <p className="para1">{i.description}</p>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Freelancing;
