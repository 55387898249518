// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import { Button, message, Spin } from 'antd';
// import Api from "../../../Api.js";

// const NewExam = () => {
//   const { week } = useParams(); // Get week from URL params
//   const [questions, setQuestions] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [answers, setAnswers] = useState([]);
//   const [timer, setTimer] = useState(0); // Timer in seconds
//   const [intervalId, setIntervalId] = useState(null);
//   const [studentId, setStudentId] = useState(null); // Get from localStorage
//   const [courseId, setCourseId] = useState(null); // Get from student's applied courses
//   const [examSessionId, setExamSessionId] = useState(null); // Store the session ID

//   useEffect(() => {
//     // Step 1: Retrieve studentId from localStorage
//     const storedStudentId = localStorage.getItem('userId');
//     setStudentId(storedStudentId);

//     // Step 2: Fetch the applied courses from student details (this can be an API call)
//     const fetchStudentDetails = async () => {
//       try {
//         const response = await Api.get(`signup/student_getone/${storedStudentId}`); // API to fetch student details and applied courses
//         const appliedCourses = response.data.data.appliedCourses; // Assuming appliedCourses is an array of course IDs
//         const selectedCourseId = appliedCourses[0].courseId; // Assuming you want the first applied course
//         setCourseId(selectedCourseId);

//         // Step 3: Fetch exam questions based on studentId, courseId, and week
//         const fetchQuestions = async () => {
//           try {
//             const questionResponse = await Api.get(`/exam/${storedStudentId}/${selectedCourseId}/${week}`);
//             setQuestions(questionResponse.data.questions);
//             setExamSessionId(questionResponse.data.examSessionId); // Store the session ID from backend response
//             setLoading(false);
//             setTimer(questionResponse.data.duration * 60); // Convert minutes to seconds
//           } catch (error) {
//             message.error('Failed to load questions.');
//             setLoading(false);
//           }
//         };

//         fetchQuestions();
//       } catch (error) {
//         message.error('Failed to fetch student details.');
//         setLoading(false);
//       }
//     };

//     fetchStudentDetails();

//     // Start timer
//     const id = setInterval(() => {
//       setTimer(prev => (prev > 0 ? prev - 1 : clearInterval(id)));
//     }, 1000);
//     setIntervalId(id);

//     return () => clearInterval(id);
//   }, [week]);

//   // Step 4: Handle answer change for the questions
//   const handleAnswerChange = (index, value) => {
//     const updatedAnswers = [...answers];
//     updatedAnswers[index] = value;
//     setAnswers(updatedAnswers);
//   };

//   // Step 5: Submit exam answers
//   const handleSubmit = async () => {
//     try {
//       const response = await Api.post('/exam/submit', {
//         examSessionId,  // Send the session ID with the submission
//         answers,
//       });
//       message.success(`Your score is ${response.data.score}`);
//     } catch (error) {
//       message.error('Failed to submit answers.');
//     }
//   };

//   // Utility to format time in mm:ss
//   const formatTime = (time) => {
//     const minutes = Math.floor(time / 60);
//     const seconds = time % 60;
//     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
//   };

//   if (loading) return <Spin size="large" />;

//   return (
//     <div className="exam-container">
//       <h1>Exam</h1>
//       <h2>Time Remaining: {formatTime(timer)}</h2>
//       {questions.map((question, index) => (
//         <div key={question._id} className="question">
//           <h3>{index + 1}. {question.question}</h3>
//           <div className="options">
//             {question.options.map((option, optIndex) => (
//               <div key={optIndex}>
//                 <input
//                   type="radio"
//                   id={`q${index}-opt${optIndex}`}
//                   name={`question${index}`}
//                   value={option}
//                   onChange={() => handleAnswerChange(index, option)}
//                 />
//                 <label htmlFor={`q${index}-opt${optIndex}`}>{option}</label>
//               </div>
//             ))}
//           </div>
//         </div>
//       ))}
//       <Button type="primary" onClick={handleSubmit} disabled={timer === 0}>
//         Submit Exam
//       </Button>
//     </div>
//   );
// };

// export default NewExam;

import React, { useState, useEffect } from 'react';
import Api from "../../../Api"

const NewExam = () => {
  const [examData, setExamData] = useState(null);
  const [error, setError] = useState('');
  const courseId = "67061ef704d5bc3d285f20c8";

  useEffect(() => {
    // Fetch the exam automatically for the selected course
    const fetchExam = async () => {
      try {
        const response = await Api.get(`exam/fetch-exam/${courseId}`);
        setExamData(response.data);
      } catch (err) {
        setError('Error fetching exam questions');
      }
    };

    if (courseId) {
      fetchExam();
    }
  }, [courseId]); // Fetch the exam when courseId changes

  if (error) {
    return <p>{error}</p>;
  }

  if (!examData) {
    return <p>Loading exam...</p>;
  }

  return (
    <div>
      <h2>Exam for Week {examData.examPattern.week}</h2>
      <form>
        {examData.questions.map((question) => (
          <div key={question._id}>
            <p>{question.questionText}</p>
            {question.options.map((option, index) => (
              <div key={index}>
                <input type="radio" name={question._id} value={index} />
                <span>{option}</span>
              </div>
            ))}
            <input type="text" placeholder="Your Answer" required />
          </div>
        ))}
        <button type="submit">Submit Exam</button>
      </form>
    </div>
  );
};

export default NewExam;
