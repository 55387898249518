import React, { useState } from 'react';
import { Tabs } from 'antd';
import PersonalDetails from './PersonalDetails';
import QualificatoinDetails from "./QualificationDetails";
import SemesterScores from "./SemesterScores";
import AddDetails from './AddDetails';
import Training from './Training';

export default function ProfessionalProfile() {
  const [showTrainingTab, setShowTrainingTab] = useState(false);

  const handleInterestChange = (isInterested) => {
    setShowTrainingTab(isInterested);
  };

  return (
    <div className='professionaltabs'>
      <Tabs defaultActiveKey="1" className='p-3'>
        <Tabs.TabPane className='tabtext p-3' tab="Add Details" key="1">
          <AddDetails onInterestChange={handleInterestChange} />
        </Tabs.TabPane>
        <Tabs.TabPane className='tabtext p-3' tab="Personal Information" key="2">
          <PersonalDetails />
        </Tabs.TabPane>
        <Tabs.TabPane className='tabtext p-3' tab="Qualification" key="3">
          <QualificatoinDetails />
        </Tabs.TabPane>
        <Tabs.TabPane className='tabtext p-3' tab="Semester Scores" key="4">
          <SemesterScores />
        </Tabs.TabPane>
        {/* {showTrainingTab && (
          <Tabs.TabPane className='tabtext p-3' tab="Training" key="5">
            <div>Training Content</div>
          </Tabs.TabPane>
        )} */}
          <Tabs.TabPane className='tabtext p-3' tab="Training" key="5">
          <Training />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
