import { React, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "../../../Components/Css/Employer/FreelancingPost.scss";
import { Dropdown } from "primereact/dropdown";
import { useForm } from "react-hook-form";
import { Select } from "antd";
import Api from "../../../Api";
const { Option } = Select;

const FreelancingPost = () => {
  const Technology = [
    { label: "React", value: 1 },
    { label: "Angular", value: 2 },
    { label: "java", value: 3 },
  ];
  const Language = [
    { label: "English", value: "English" },
    { label: "Tamil", value: "Tamil" },
    { label: "Hindi", value: "Hindi" },
  ];
  const Country = [
    { label: "India", value: "1" },
    { label: "America", value: "2" },
    { label: "China", Value: "3" },
    { label: "Others", value: "4" },
  ];
  const timing = [
    { label: "/hr", value: "1" },
    { label: "/Day", value: "2" },
    { label: "/Week", value: "3" },
    { label: "/Month", value: "4" },
  ];

  const [review, setReview] = useState({
    language: "",
  });

  const [selectTech, setSelectedTech] = useState(0);
  // const [optionsIndex, setOptionsIndex] = useState(0);
  const [selectLanguage, setSelectLanguage] = useState([]);
  const [selectCountry, setSelectCountry] = useState(0);
  const [selectTiming, setSelectTiming] = useState(0);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {
    const Details = {
      jobTitle: getValues().jobTitle,
      price: getValues().price,
      country: getValues().country,
      language: getValues().language,
      timingForPay: getValues().timingForPay,
      technology: getValues().technology,
      description: getValues().description,
    };

    Api.post(`/freelancingPost/FreelancingCreate`, Details).then((resp) => {
      console.log("resp.data", resp.data);
    });
  };

  return (
    <div className="postContainer">
      <Row>
        <form className="postForm" onSubmit={handleSubmit(onSubmit)}>
          <h1 className="postheading">Post Job Here</h1>
          <div className="postRowContainer">
            <Row className="mt-3 ms-5">
              <Col lg={6} className="mt-3">
                <label className="posttitle">Job Title</label>
                <br />
                <input
                  type="text"
                  name="jobtitle"
                  id="jobtitle"
                  {...register("jobTitle", { required: true })}
                  className="inputBox"
                />
                <br />
                {errors.jobTitle && (
                  <span style={{ color: "red" }}>This field is required</span>
                )}
              </Col>
              <Col lg={6} className="mt-3">
                <label className="posttitle">Price</label> <br />
                <input
                  type="text"
                  name="price"
                  id="price"
                  {...register("price", { required: true })}
                  className="inputBox"
                />
                <br />
                {errors.price && (
                  <span style={{ color: "red" }}>This field is required</span>
                )}
              </Col>
            </Row>
            <Row className="mt-3 ms-5">
              <Col lg={6} className="mt-3">
                <label className="posttitle">Country</label>
                <br />
                <Dropdown
                  className="selectInputBox"
                  placeholder="select a Country"
                  value={selectCountry}
                  options={Country}
                  {...register("country", { required: true })}
                  onChange={({ value }) => setSelectCountry(value)}
                />
                <br />
                {errors.country && (
                  <span style={{ color: "red" }}>This field is required</span>
                )}
              </Col>
              <Col lg={6} className="mt-3">
                <label className="posttitle">Technology</label> <br />
                <Dropdown
                  className="selectInputBox"
                  placeholder="select a Technology"
                  value={selectTech}
                  options={Technology}
                  {...register("technology", { required: true })}
                  onChange={({ value }) => setSelectedTech(value)}
                />
                <br />
                {errors.technology && (
                  <span style={{ color: "red" }}>This field is required</span>
                )}
              </Col>
            </Row>
            <Row className="mt-3 ms-5">
              <Col lg={6} className="mt-3">
                <label className="posttitle">Language</label>
                <br />
                <Select
                  mode="multiple"
                  className="selectInputBox"
                  name="language"
                  placeholder="select a Language"
                  {...register("language", { required: true })}
                  onChange={(e) => {
                    setSelectLanguage(e);
                    setValue("language", e);
                  }}
                >
                  {Language.map((list, i) => {
                    return <Option value={list.label} key={i}></Option>;
                  })}
                </Select>
                <br />
                {selectLanguage.length > 0
                  ? null
                  : errors.language && (
                      <p className="text-danger">Qualification is required</p>
                    )}
              </Col>
              <Col lg={6} className="mt-3">
                <label className="posttitle">Timing for pay</label>
                <br />

                <Dropdown
                  value={selectTiming}
                  options={timing}
                  placeholder="select a time for pay"
                  className="selectInputBox"
                  {...register("timingForPay", { required: true })}
                  onChange={({ value }) => setSelectTiming(value)}
                />
                <br />
                {errors.timingForPay && (
                  <span style={{ color: "red" }}>This field is required</span>
                )}
              </Col>
            </Row>
            <Row className="mt-4 ms-5">
              <Col lg={6} className="mt-3">
                <label className="posttitle">Description</label>
                <br />
                {/* <input type="text" row={5} cols={5} style={{ height: 80 }} /> */}
                <textarea
                  className="descriptionInput"
                  name=""
                  id=""
                  type="text"
                  {...register("description", { required: true })}
                />
                <br />
                {errors.description && (
                  <span style={{ color: "red" }}>This field is required</span>
                )}
              </Col>
            </Row>

            <div className="submitbuttons px-4">
              <button className="button1 m-2 p-2" type="submit">
                Submit
              </button>
              <button className="button2 m-2 p-2" type="reset">
                Reset
              </button>
            </div>
          </div>
        </form>
      </Row>
    </div>
  );
};

export default FreelancingPost;
