import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { DatePicker } from "antd";
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  LockOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  ManOutlined,
  WomanOutlined,
} from "@ant-design/icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "./StudentSignupForm.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Api from "../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TrainerSignupForm = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [value, setValue] = useState(false);
  const navigate = useNavigate();
  const [resRes, setResRes] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = async (data) => {
    const userDetails = {
      role: "trainers",
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.mail,
      gender: data.gender,
      phone: data.phone,
      dob: data.dob,
      password: data.password,
      maritalStatus: data.maritalStatus,
    };

    try {
      const res = await Api.post(`trainer/Trainercreate`, userDetails);
      setResRes({
        status: res.data?.status,
        message: res.data?.message,
      });
      localStorage.setItem("userId", res.data.data._id);
      toast.success(res.data?.message);
      setTimeout(() => {
        navigate("/");
      }, 10000);
    } catch (err) {
      setResRes({
        status: err?.response.data?.status,
        message: err?.response.data?.message,
      });
      toast.error(err?.response.data?.message);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="signup-form-container">
      <h2 className="form-heading">Trainer Signup</h2>

      <h3 className="section-title">Personal Details</h3>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Row>
          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">First Name</Form.Label>
              <div className="input-icon-container">
                <UserOutlined className="input-icon" />
                <Form.Control
                  {...register("firstName", {
                    required: true,
                    pattern: /^[A-Za-z]+$/i,
                  })}
                  placeholder="Enter First Name"
                  className="custom-input"
                  type="text"
                />
              </div>
              {errors.firstName?.type === "required" && (
                <p className="error-text-color">First Name is required</p>
              )}
              {errors?.firstName?.type === "pattern" && (
                <p className="error-text-color">characters only</p>
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Last Name</Form.Label>
              <div className="input-icon-container">
                <UserOutlined className="input-icon" />
                <Form.Control
                  {...register("lastName", {
                    required: true,
                    pattern: /^[A-Za-z]+$/i,
                  })}
                  className="custom-input"
                  placeholder="Enter Last Name"
                  type="text"
                />
              </div>
              {errors.lastName?.type === "required" && (
                <p className="error-text-color">Last Name is required</p>
              )}
              {errors?.lastName?.type === "pattern" && (
                <p className="error-text-color">characters only</p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Phone Number</Form.Label>
              <div className="input-icon-container">
                <PhoneOutlined className="input-icon" />
                <Form.Control
                  {...register("phone", {
                    minLength: 10,
                    maxLength: 10,
                    required: true,
                  })}
                  className="custom-input"
                  placeholder="Enter Phone Number"
                  type="text"
                />
              </div>
              {errors.phone?.type === "required" && (
                <p className="error-text-color">Phone Number is required</p>
              )}
              {errors.phone?.type === "minLength" && (
                <p className="error-text-color">
                  minimum 10 number is required
                </p>
              )}
              {errors.phone?.type === "maxLength" && (
                <p className="error-text-color">
                  maximum 10 number is required
                </p>
              )}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Email ID</Form.Label>
              <div className="input-icon-container">
                <MailOutlined className="input-icon" />
                <Form.Control
                  {...register("mail", {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    },
                  })}
                  className="custom-input"
                  placeholder="Enter Email"
                  type="email"
                />
              </div>
              {errors.mail && (
                <p className="error-text-color">Email is required</p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Gender</Form.Label>
              <div className="input-icon-container">
                <ManOutlined className="input-icon" />
                <Form.Control
                  {...register("gender", { required: true })}
                  className="custom-input"
                  as="select"
                >
                  <option className="option">Male</option>
                  <option className="option">Female</option>
                  <option className="option">Other</option>
                </Form.Control>
              </div>
              {errors.gender && (
                <span className="error-text">Gender is required</span>
              )}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Marital Status</Form.Label>
              <div className="input-icon-container">
                <WomanOutlined className="input-icon" />
                <Form.Control
                  {...register("maritalStatus", { required: true })}
                  className="custom-input"
                  as="select"
                >
                  <option value="">Select</option>
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                  <option value="Other">Other</option>
                </Form.Control>
              </div>
              {errors.maritalStatus && (
                <p className="error-text-color">Marital Status is required</p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Date of Birth</Form.Label>
              <div className="input-icon-container">
                <DatePicker
                  format="DD-MM-YYYY"
                  onChange={(date, dateString) => setValue("dob", dateString)}
                  className="custom-input date"
                />
              </div>
              {errors.dob && (
                <p className="error-text-color">Date of Birth is required</p>
              )}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Password</Form.Label>
              <div className="input-icon-container">
                <LockOutlined className="input-icon" />
                <Form.Control
                  {...register("password", { required: true, minLength: 6 })}
                  className="custom-input"
                  placeholder="Enter Password"
                  type={passwordVisible ? "text" : "password"}
                />
                <div
                  className="eye-icon-container"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>
              </div>
              {errors.password?.type === "required" && (
                <p className="error-text-color">Password is required</p>
              )}
              {errors.password?.type === "minLength" && (
                <p className="error-text-color">
                  Password must be at least 6 characters
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Button className="btn-primary col-12" type="submit">
          Submit
        </Button>
      </Form>
      {resRes && (
        <div className={`response-message ${resRes.status}`}>
          <p>{resRes.message}</p>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default TrainerSignupForm;
