import React, { useEffect, useState } from "react";
import { Table, Button, Modal } from "antd";
import Api from "../../../Api.js";

const ApprovedCourse = () => {
  const [approvedCourses, setApprovedCourses] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [courseDetails, setCourseDetails] = useState(null);

  useEffect(() => {
    const fetchApprovedCourses = async () => {
      try {
        const response = await Api.get("/trainercourse/courses/approved");
        console.log("approvedCourse", response.data.data);
        setApprovedCourses(response.data.data);
      } catch (error) {
        console.error("Error fetching approved courses:", error);
      }
    };

    fetchApprovedCourses();
  }, []);

  const showModal = (course) => {
    setCourseDetails(course);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Define columns for the Ant Design table
  const columns = [
    {
      title: "Course Name",
      dataIndex: "courseName",
      align: "center",
      key: "courseName",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      align: "center",
      key: "startDate",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      align: "center",
      key: "endDate",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Total Weeks",
      dataIndex: "totalWeeks",
      align: "center",
      key: "totalWeeks",
    },
    {
      title: "Action",
      align: "center",
      key: "action",
      render: (_, record) => (
        <div>
        <Button onClick={() => showModal(record)} type="primary" style={{ marginRight: 8 }}>
          View
        </Button>
        <Button type="primary" disabled>
          Approved
        </Button>
      </div>
      ),
    },
  ];

  return (
    <div
      style={{
        maxWidth: "90%",
        margin: "0 auto",
        padding: "20px",
      }}
    >
      <h3 style={{ marginTop: "40px", marginBottom: "20px" }}>Approved Courses</h3>
      <Table 
      // dataSource={approvedCourses} 
      dataSource={approvedCourses.filter((approvedCourse) => approvedCourse.approval)}
      columns={columns} />

      <Modal
        title="Course Details"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {courseDetails && (
          <div>
          <p><strong>Course Name:</strong> {courseDetails.courseName}</p>
          <p><strong>Starting Date:</strong>{" "}{new Date(courseDetails.startDate).toLocaleDateString()}</p>
          <p><strong>Ending Date:</strong>{" "}{new Date(courseDetails.endDate).toLocaleDateString()}</p>
          <p><strong>Total Weeks:</strong> {courseDetails.totalWeeks}</p>
          <p><strong>Timing:</strong> {courseDetails.timing}</p>
          <p><strong>Student Count:</strong> {courseDetails.studentCount}</p>
          {/* <p><strong>Course Fee:</strong> ${courseDetails.courseFee.toFixed(2)}</p> */}
        </div>
        )}
      </Modal>
    </div>
  );
};

export default ApprovedCourse;
