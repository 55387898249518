import React from 'react';
import { Form, Input, Button, Row, Col, message } from 'antd';
import Api from "../../../Api.js";

const CreateTechnology = () => {
  const onFinishTechnology = (values) => {
    Api.post('/exam/createtechnology', values)
      .then(() => message.success('Technology created successfully'))
      .catch(() => message.error('Failed to create technology'));
  };

  return (
    <Form layout="vertical" onFinish={onFinishTechnology}>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label="Technology Name" name="tech_name" rules={[{ required: true }]}>
            <Input placeholder="Enter technology name" />
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item label="Description" name="description" rules={[{ required: true }]}>
            <Input placeholder="Enter description" />
          </Form.Item>
        </Col>
      </Row>
      <Button type="primary" htmlType="submit">Create Technology</Button>
    </Form>
  );
};

export default CreateTechnology;
