import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Api from '../../../Api';
import './Counselling.css'; // Import the CSS file

const Counselling = () => {
  const trainerId = localStorage.getItem('userId');
  const [counsellingDetails, setCounsellingDetails] = useState({
    yearsofexp: '',
    sessiontype: '',
    counsellingformat: '',
    clientscounselled: '',
    preferedlocation: '',
    qualifications: '',
    specializations: '',
    languages: '',
    careerAreasCovered: ''
  });
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  // Fetch the existing counselling details (optional)
  useEffect(() => {
    Api.get(`/trainer/Trainergetone/${trainerId}`)
      .then((response) => {
        console.log("counselling", response);
        setCounsellingDetails(response?.data?.data?.CounsellingDetails);
      })
      .catch((err) => {
        console.error(err);
        setError('Failed to fetch trainer details');
      });
  }, [trainerId]);

  // Handle input changes
  const handleChange = (e) => {
    setCounsellingDetails({
      ...counsellingDetails,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await Api.put(`/trainer/counsellingdetails/${trainerId}`, counsellingDetails);
      setMessage(response.data.message);
      setError('');
    } catch (error) {
      setError('Error updating counselling details');
      setMessage('');
    }
  };

  return (
    <div className="counselling-container">
      <h2>Update Counselling Details</h2>
      {message && <p className="success-message">{message}</p>}
      {error && <p className="error-message">{error}</p>}

      <form onSubmit={handleSubmit}>
        <div className="form-grid">
          <div className="form-group">
            <label>Years of Experience</label>
            <input
              type="text"
              name="yearsofexp"
              value={counsellingDetails.yearsofexp}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Session Type</label>
            <select
              name="sessiontype"
              value={counsellingDetails.sessiontype}
              onChange={handleChange}
              required
            >
              <option value="">Select Session Type</option>
              <option value="online">Online</option>
              <option value="offline">Offline</option>
              <option value="both">Both</option>
            </select>
          </div>

          <div className="form-group">
            <label>Counselling Format</label>
            {/* <input
              type="text"
              name="counsellingformat"
              value={counsellingDetails.counsellingformat}
              onChange={handleChange}
            /> */}
            <select
            name="counsellingformat"
            value={counsellingDetails.counsellingformat}
            onChange={handleChange}
            >
                <option value="">Select Counselling format</option>
                <option value="one-on-one session">One-on-One session</option>
                <option value="Group Session">Group Session</option>
            </select>
          </div>

          <div className="form-group">
            <label>Clients Counselled</label>
            <input
              type="text"
              name="clientscounselled"
              value={counsellingDetails.clientscounselled}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Preferred Location</label>
            <input
              type="text"
              name="preferedlocation"
              value={counsellingDetails.preferedlocation}
              onChange={handleChange}
            />
          </div>

          {/* New fields */}
          <div className="form-group">
            <label>Qualifications</label>
            <input
              type="text"
              name="qualifications"
              value={counsellingDetails.qualifications}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Specializations</label>
            <input
              type="text"
              name="specializations"
              value={counsellingDetails.specializations}
              onChange={handleChange}
              placeholder="Enter specializations, comma separated"
            />
          </div>

          <div className="form-group">
            <label>Languages</label>
            <input
              type="text"
              name="languages"
              value={counsellingDetails.languages}
              onChange={handleChange}
              placeholder="Enter languages, comma separated"
            />
          </div>

          <div className="form-group">
            <label>Career Areas Covered</label>
            <input
              type="text"
              name="careerAreasCovered"
              value={counsellingDetails.careerAreasCovered}
              onChange={handleChange}
              placeholder="Enter career areas, comma separated"
            />
          </div>
        </div>

        <div className="submit-container">
          <button type="submit" className="submit-button" style={{width: '230px'}}>Update Counselling Details</button>
        </div>
      </form>
    </div>
  );
};

export default Counselling;
