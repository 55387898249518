import { Col, Row } from 'antd';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from 'react-router-dom';
import Api from '../../../Api';
import "./Trainer.scss"

const TrainerCreate = () => {
    const navigate = useNavigate();
    const toast = useRef(null);
    const { state: locationState } = useLocation();
    // console.log(locationState.ele._id, "llllll");

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({ defaultValues: locationState ? locationState.ele : {} });

    const handleFormSubmit = (data) => {
        try {
            if (locationState) {
                //update
                Api.put(`/Trainer/update/${locationState.ele._id}`, data).then((res) => {
                    navigate(-1)
                })
            } else {
                //create
                Api.post(`Trainer/create`, data)
                    .then((resp) => {
                        navigate(-1)
                        console.log("111111", resp);
                    })
                    .catch((resp) => {
                        console.log("error");
                    });
            }
        } catch (error) {
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Something went wrong,please try again!!!",
                life: 3000,
            });
        }
    };

    const experienceData = [
        {
            value: '0-1',
            label: '0-1',
        },
        {
            value: '1-2',
            label: '1-2',
        },
        {
            value: '2-3',
            label: '2-3',
        },
        {
            value: '3-4',
            label: '3-4',
        },
        {
            value: 'more than 5',
            label: 'more than 5',
        },


    ];

    return (
        <div>
            <Form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="Blog-form p-5 ms-5">
                    <div className="pages-title mb-3">Create Trainer</div>
                    <Row className="mb-3">
                        <Col xs={24} sm={24} md={8} className="p-2">
                            <label className="input-title pe-5"> First Name</label>
                            <br />
                            <input
                                className="trainerInput"
                                {...register("firstName", { required: true })}
                            />
                            <br />
                            {errors.firstName && (
                                <span className="Blog-error">Trainer Name is required</span>
                            )}
                        </Col>
                        <Col xs={24} sm={24} md={8} className="p-2">
                            <label className="input-title pe-5"> Last Name </label>
                            <br />
                            <input
                                className="trainerInput"
                                {...register("lastName", { required: true })}
                            />
                            <br />
                            {errors.lastName && (
                                <span className="Blog-error">LastName is required</span>
                            )}
                        </Col>
                        <Col xs={24} sm={24} md={8} className="p-2">
                            <label className="input-title pe-5">Email Id</label>
                            <br />
                            <input
                                className="trainerInput" type="email"
                                {...register("email", { required: true })}
                            />

                            <br />
                            {errors.email && (
                                <span className="Blog-error">Email id is required</span>
                            )}
                        </Col>
                        <Col xs={24} sm={24} md={8} className="p-2">
                            <label className="input-title pe-5">Mobile No.</label>
                            <br />
                            <input type="tel" maxLength="10" minLength="10"
                                className="trainerInput"
                                {...register("phoneNumber", { required: true })}
                            />
                            <br />
                            {errors.phoneNumber && (
                                <span className="Blog-error">Mobile No. is required</span>
                            )}
                        </Col>

                        <Col xs={24} sm={24} md={8} className="p-2">
                            <label className="input-title pe-5">Qualification</label>
                            <br />
                            <input
                                className="trainerInput"
                                {...register("Qualification", { required: true })}
                            />
                            <br />
                            {errors.Qualification && (
                                <span className="Blog-error">Qualification is required</span>
                            )}
                        </Col>
                        <Col xs={24} sm={24} md={8} className="p-2">
                            <label className="input-title pe-5">Experience</label>
                            <br />

                            <select className="trainerSelect"
                                {...register("Experience", { required: true })} >
                                    <option value="" disabled selected>Select Experience</option>
                                {experienceData.map(obj =>
                                    <option value={obj.value} >{obj.value}</option>
                                )}
                            </select>
                            <br />
                            {errors.Experience && (
                                <span className="Blog-error">Experience is required</span>
                            )}
                        </Col>
                        <Col xs={24} sm={24} md={8} className="p-2">
                            <label className="input-title pe-5">Expertise</label>
                            <br />
                            <input
                                className="trainerInput"
                                {...register("Expertise", { required: true })}
                            />

                            <br />
                            {errors.Expertise && (
                                <span className="Blog-error">Expertise is required</span>
                            )}
                        </Col>
                    </Row>

                    <div className="submitbuttons p-2">
                        <button
                            className="button1 m-2 p-2"
                            type="submit"
                        >
                            Submit
                        </button>
                        <button className="button2 m-2 p-2" type="reset">
                            Reset
                        </button>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default TrainerCreate;