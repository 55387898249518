import React, { useState } from 'react';
import { Table, Button, Modal } from 'antd';

const TransactionHistory = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const columns = [
        {
            title: 'Transaction ID',
            dataIndex: 'transactionID',
            key: 'transactionID',
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            align: 'center',
            render: (amount) => `$${amount.toFixed(2)}`,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => (
                <Button onClick={() => viewTransaction(record)}>View</Button>
            ),
        },
    ];

    const data = [
            {
                key: '1',
                transactionID: 'OP05637',
                name: 'John Doe',
                date: '2024-07-26',
                amount: 5500,
                status: 'Receive',
            },
            {
                key: '2',
                transactionID: 'OP05894',
                name: 'Jane Smith',
                date: '2024-08-29',
                amount: 2500,
                status: 'Transfer',
            },
            {
                key: '3',
                transactionID: 'OP08284',
                name: 'Alice Johnson',
                date: '2024-09-06',
                amount: 2800,
                status: 'Withdraw',
            },
            {
                key: '4',
                transactionID: 'OP09899',
                name: 'Robert Brown',
                date: '2024-09-16',
                amount: 2200,
                status: 'Deposit',
            },
            {
                key: '5',
                transactionID: 'OP09784',
                name: 'Chris Lee',
                date: '2024-10-06',
                amount: 2600,
                status: 'Payment',
            },
            {
                key: '6',
                transactionID: 'OP06123',
                name: 'Michael Green',
                date: '2024-07-15',
                amount: 3000,
                status: 'Completed',
            },
            {
                key: '7',
                transactionID: 'OP06745',
                name: 'Sarah White',
                date: '2024-08-10',
                amount: 4000,
                status: 'Pending',
            },
            {
                key: '8',
                transactionID: 'OP07234',
                name: 'Daniel Black',
                date: '2024-09-01',
                amount: 3500,
                status: 'Failed',
            },
            {
                key: '9',
                transactionID: 'OP08012',
                name: 'Emily Davis',
                date: '2024-09-20',
                amount: 2900,
                status: 'Processing',
            },
            {
                key: '10',
                transactionID: 'OP08945',
                name: 'James Wilson',
                date: '2024-10-05',
                amount: 6000,
                status: 'Completed',
            }
        ];
        
    const viewTransaction = (transaction) => {
        setSelectedTransaction(transaction);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedTransaction(null);
    };

    return (
        <div style={{ marginTop: '40px', maxWidth: '90%', margin: '0 auto', padding: '20px' }}><br/>
            <h3>Transaction History</h3>
            <Table
                columns={columns}
                dataSource={data}
                pagination={{ pageSize: 5 }}
                style={{ textAlign: 'center' }}
            />

            {/* Modal for viewing transaction details */}
            <Modal
                title="Transaction Details"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="close" onClick={handleCancel}>
                        Close
                    </Button>
                ]}
            >
                {selectedTransaction && (
                    <div>
                        <p><strong>Transaction ID:</strong> {selectedTransaction.transactionID}</p>
                        <p><strong>Name:</strong> {selectedTransaction.name}</p>
                        <p><strong>Date:</strong> {selectedTransaction.date}</p>
                        <p><strong>Amount:</strong> ${selectedTransaction.amount.toFixed(2)}</p>
                        <p><strong>Status:</strong> {selectedTransaction.status}</p>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default TransactionHistory;
