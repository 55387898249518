import React from 'react'
import Calender from '../Pages/Employee/Calender';
import EmployeeDashboard from '../Pages/Employee/EmployeeDashboard/EmployeeDashboard';
import ClientInterview from '../Pages/Employee/Interview/ContractStaffing/ClientInterview';
import Question from '../Pages/Employee/Interview/ContractStaffing/Question';
import HrSchedule from '../Pages/Employee/Interview/HrSchedule';
import Questions from '../Pages/Employee/Interview/Questions';
import MyProfile from '../Pages/Employee/MyProfile/MyProfile';

const EmployeeRoute = [
    {
        path:"/employee",
        name:"dashboard",
        element:<EmployeeDashboard/>,

    },
    {
        path:"/employee/profile",
        name:"profile",
        element:<MyProfile/>,
    },
    {
        path:"/employee/calender",
        name:"calender",
        element:<Calender/>,
    },
    {
        path:"/employee/schedule",
        name:"schedule",
        element:<HrSchedule/>,
    },
    {
        path:"/employee/question",
        name:"question",
        element:<Questions/>,
    },
    {
        path:"/employee/question",
        name:"question",
        element:<Question/>,
    },
    {
        path:"/employee/interview",
        name:"interview",
        element:<ClientInterview/>,
    },
];

export default EmployeeRoute