import React, { useEffect, useState } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BiRupee } from "react-icons/bi";
import { Button, Col, Container, Row } from "react-bootstrap";

import { TfiBag } from "react-icons/tfi";
import { TbWorld } from "react-icons/tb";
import Api from "../../Api";
import { useLocation } from "react-router-dom";

function RequestRedirect() {
  const state = useLocation();
  const id = state.state;

  const [specificValue, setSpecificValue] = useState([]);
  useEffect(() => {
    getSpecficValue();
  }, []);

  const getSpecficValue = () => {
    Api.get(`projectpost/getoneSpecific/${id}`).then((data) => {
      console.log("e", data);
      setSpecificValue(data.data.data);
    });
  };

  return (
    // <div>
    //   <>
    //   <div className="ms-4">
    //     <h2 className="mt-3">{specificValue.role}</h2>
    //     <h6>{specificValue.projectTitle}</h6>
    //     <p>
    //       <AiOutlineClockCircle /> {specificValue.duration}{" "}
    //     </p>
    //     <p>
    //       <BiRupee /> 5000per month
    //     </p>
    //     <p>
    //       <TfiBag /> {specificValue.qualification}
    //     </p>
    //     <p>
    //       <TbWorld /> {specificValue.languages}
    //     </p>
    //     <p>{specificValue.description}</p>
    //     </div>
    //   </>
    // </div>
    <Container>
    <div>
      <div className="ms-4">
        <Row className="p-3">
          <Col>
            <h3 className="mt-3">{specificValue.role}</h3>

            <div className="ms-4 p-2">
                <h6>
              <AiOutlineClockCircle /> {specificValue.projectTitle}
              </h6>
              <h6>
              <AiOutlineClockCircle /> {specificValue.duration}
              </h6>
              <h6>
                   <BiRupee /> 5000per month
              </h6>
              <h6>
              <TfiBag /> {specificValue.qualification}
              </h6>
              <h6>
              <TbWorld /> {specificValue.languages}
              </h6>
              <h6>
              {specificValue.description}
              </h6>
            </div>
          </Col>
        </Row>

        <br />
       
      </div>
    </div>
  </Container>
  );
}

export default RequestRedirect;
