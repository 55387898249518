import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  MdPerson,
  MdAreaChart,
  MdRequestPage,
  MdSend,
  MdMenuBook,
  MdDetails,
} from "react-icons/md";
import { AiFillProject } from "react-icons/ai";
import { FaBlogger } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import "../../../Components/Css/Sidebar.scss";
import SidebarToggle from "./SidebarToogle";

function Sidebar() {
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [isRightSidebarCollapsed, setIsRightSidebarCollapsed] = useState(true);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);


  

  const toggleRightSidebar = () => {
    setIsRightSidebarCollapsed((prev) => !prev);
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prev) => !prev);
  };

  const handleSubmenuClick = (submenu) => {
    if (submenu === activeSubmenu) {
      setActiveSubmenu(null);
      setIsRightSidebarCollapsed(true);
    } else {
      setActiveSubmenu(submenu);
      setIsRightSidebarCollapsed(false);
    }
  };

  const role = localStorage.getItem("name");
  const id = localStorage.getItem("regid");

  const hasSubmenuItems = () => {
    return activeSubmenu === "training";
  };

  return (
    <div className={`sidebar-container ${isSidebarCollapsed ? "collapsed" : ""}`}>
      <SidebarToggle toggleSidebar={toggleSidebar} isSidebarCollapsed={isSidebarCollapsed} />

      {!isSidebarCollapsed && (
        <div className="sidebar-content">
          <div className="nav-list-sidebar">
            <NavLink
              to="/student"
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <div className="profile-icon">
                <CgProfile />
              </div>
              <div className="profile-details">
                <div className="profile-role">{role}</div>
                <div className="profile-id">{id}</div>
              </div>
            </NavLink>
            <NavLink
              to="/student/profile"
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdPerson className="nav-icon" />
              <span className="side-title">My Profile</span>
            </NavLink>
            <NavLink
              to="/student/areaInterest"
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdAreaChart className="nav-icon" />
              <span className="side-title">Area Of Interest</span>
            </NavLink>
            <div
              className="nav-item"
              onClick={() => handleSubmenuClick("training")}
            >
              <AiFillProject
                className="nav-icon"
                style={{ fontSize: "22px", marginLeft: "6px" }}
              />
              <span className="side-title">Training</span>
            </div>
            <NavLink
              to="/student/exam"
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdSend className="nav-icon" />
              <span className="side-title">Exam</span>
            </NavLink>
            <NavLink
              to="/student/project"
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdRequestPage className="nav-icon" />
              <span className="side-title">Project</span>
            </NavLink>
            <NavLink
              to="/student/seminar"
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdMenuBook className="nav-icon" />
              <span className="side-title">Seminar</span>
            </NavLink>
            <NavLink
              to="/student/blog"
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <FaBlogger className="nav-icon" />
              <span className="side-title">Blog</span>
            </NavLink>
            <NavLink
              to="/student/internshipDetails"
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdDetails className="nav-icon" />
              <span className="side-title">Internship Details</span>
            </NavLink>
            <NavLink
              to="/student/events"
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdMenuBook className="nav-icon" />
              <span className="side-title">Events</span>
            </NavLink>
          </div>
        </div>
      )}

      {hasSubmenuItems() && (
        <div
          className={`right-sidebar ${
            isRightSidebarCollapsed ? "collapsed" : ""
          }`}
        >
          <div className="right-sidebar-header">
            <div className="collapse-btn" onClick={toggleRightSidebar}>
              {isRightSidebarCollapsed ? ">" : "<"}
            </div>
          </div>
          <NavLink to="curriculam" className="submenu-item">
            <MdRequestPage className="nav-sub-icon" />
            <span className="sub-side-title"> Curriculum</span>
          </NavLink>
          <NavLink to="learning" className="submenu-item">
            <MdRequestPage className="nav-sub-icon" />
            <span className="sub-side-title"> Learning</span>
          </NavLink>
        </div>
      )}
    </div>
  );
}

export default Sidebar;
