import { Col, Row } from "antd";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Api from "../../../../Api";

function NewBlogForm() {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const [file, setFile] = useState(null);
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();

  function handleChange(e) {
    setFile(e.target.files[0]);
  }

  const handleFormSubmit = async () => {
    const formData = getValues();

    try {
      const Details = {
        id: userId,
        domain: formData.domain,
        subdomain: formData.subdomain,
        Tag: formData.Tag,
        Image: "",
        description: formData.description,
      };

      if (file) {
        const data = new FormData();
        data.append("file", file);
        data.append("upload_preset", "darshan");

        const response = await fetch(
          "https://api.cloudinary.com/v1_1/dzblzw7ll/image/upload",
          { method: "POST", body: data }
        );
        const cloudinaryData = await response.json();
        Details.Image = cloudinaryData.secure_url;
      }

      await Api.post(`counselor/createcounselorblog`, Details);
      toast.success("Blog entry successfully saved!");
      setTimeout(() => {
        navigate("/counsellor/blog/counsblogtable");
      }, 3000);
    } catch (error) {
      console.log(error);
      toast.error("Error saving blog entry.");
    }
  };

  return (
    <div>
      <div className="p-5 mt-5">
        <div className="pages-title mb-3">Blog</div>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Row className="mb-3">
            <Col sm={24} md={8} className="Stud-blog-col p-2">
              <label>Domain</label>
              <select
                {...register("domain", { required: true })}
                className="Stud-blog-input"
              >
                <option value="">Select Domain</option>
                <option value="IT">IT</option>
                <option value="Construction">Construction</option>
                <option value="Hr">Hr</option>
                <option value="Mech">Mech</option>
              </select>
              {errors.domain && <span>Domain is required</span>}
            </Col>
            <Col sm={24} md={8} className="Stud-blog-col p-2">
              <label>Sub-Domain</label>
              <select
                {...register("subdomain", { required: true })}
                className="Stud-blog-input"
              >
                <option value="">Select Sub-Domain</option>
                <option value="Software">Software</option>
                <option value="Hardware">Hardware</option>
                <option value="Engineer">Engineer</option>
                <option value="Finance">Finance</option>
              </select>
              {errors.subdomain && <span>Sub-Domain is required</span>}
            </Col>
          </Row>
          <Row>
            <Col sm={24} md={8} className="Stud-blog-col p-2">
              <label>Tag</label>
              <input
                type="text"
                className="Stud-blog-input"
                {...register("Tag", { required: true })}
              />
              {errors.Tag && <span>Tag is required</span>}
            </Col>
            <Col sm={24} md={8} className="Stud-blog-col p-2">
              <label>Upload Image</label>
              <input
                type="file"
                accept=".png,.jpg,.jpeg"
                className="Stud-blog-input"
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={24} md={8} className="Stud-blog-col p-2">
              <label>Description</label>
              <textarea
                rows="3"
                className="Stud-blog-input"
                {...register("description", { required: true })}
              />
              {errors.description && <span>Description is required</span>}
            </Col>
          </Row>
          <div className="submitbuttons p-2">
            <button className="button1 m-2 p-2" type="submit">
              Submit
            </button>
            <button className="button2 m-2 p-2" type="reset">
              Reset
            </button>
            <ToastContainer />
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewBlogForm;
