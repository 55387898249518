import React, { useState } from "react";
import { Row, Col, Button, Radio, Checkbox } from "antd";
import { useForm } from "react-hook-form";
import Api from "../../../../Api";
import { Dropdown } from "primereact/dropdown";

function Post() {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const id = localStorage.getItem("id");
  const name = localStorage.getItem("name");
  console.log("id", name);
  const handleFormSubmit = () => {
    const details = {
      CompanyID: id,
      companyName: name,
      PositionName: getValues().PositionName,
      technology: getValues().technology,
      Experience: getValues().Experience,
      budget: getValues().budget,
      JD: getValues().JD,
      location: getValues().location,
    };
    Api.post("/contract/createJobRequest", details).then((res) => {
      console.log("res", res);
    });
  };
  const [companyType, setCompanyType] = useState("");
  const types = [
    { name: "Chennai", value: "Chennai" },
    { name: "Bangalore", value: "Bangalore" },
    { name: "Coimbatore", value: "Coimbatore" },
  ];
  const [technologyType, setTechnologyType] = useState("");
  const technologytypes = [
    { name: "MERN", value: "MERN" },
    { name: "MEAN", value: "MEAN" },
    { name: "JAVA", value: "JAVA" },
  ];

  return (
    <div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="personal-informations p-4">
          <div className="staff-title mb-5 ">Post Management</div>
          <Row>
            <Col sm={24} md={10} lg={6} className="Stud-personal-col px-4 ">
              <label className="input-title">Position Name</label>
              <input
                className="seminarInput"
                type="text"
                {...register("PositionName", { required: true })}
              />
              {errors.PositionName && (
                <span className="Stud-personal-error">
                  PositionName is required
                </span>
              )}
            </Col>
            <Col sm={24} md={10} lg={6} className="Stud-personal-col px-4">
              <label className="input-title">Technology</label>
              <Dropdown
                className="seminarInput"
                name="companyType"
                value={technologyType}
                options={technologytypes}
                optionLabel="name"
                placeholder="Select a technology"
                {...register("technology", {
                  required: true,
                  onChange: (e) => {
                    setTechnologyType(e.target.value);
                  },
                })}
              />

              {errors.technology && (
                <span className="Stud-personal-error">technology required</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={24} md={10} lg={6} className="Stud-personal-col px-4 ">
              <label className="input-title">Experience</label>
              <input
                className="seminarInput"
                type="text"
                {...register("Experience", { required: true })}
              />
              {errors.Experience && (
                <span className="Stud-personal-error">
                  Experience is required
                </span>
              )}
            </Col>
            <Col sm={24} md={10} lg={6} className="Stud-personal-col px-4 ">
              <label className="input-title">Budget</label>
              <input
                className="seminarInput"
                type="text"
                {...register("budget", { required: true })}
              />
              {errors.budget && (
                <span className="Stud-personal-error">budget required</span>
              )}
            </Col>
          </Row>
          <Row className="mt-1">
            <Col sm={24} md={10} lg={6} className="Stud-personal-col px-4 ">
              <label className="input-title">JD</label>
              <input
                className="seminarInput"
                type="text"
                {...register("JD", { required: true })}
              />
              {errors.JD && (
                <span className="Stud-personal-error">JD is required</span>
              )}
            </Col>
            <Col sm={24} md={10} lg={6} className="Stud-personal-col px-4 ">
              <label className="input-title">Location</label>
              <br />
              <Dropdown
                className="seminarInput"
                name="companyType"
                value={companyType}
                options={types}
                optionLabel="name"
                placeholder="Select a location"
                {...register("location", {
                  required: true,
                  onChange: (e) => {
                    setCompanyType(e.target.value);
                  },
                })}
              />
              {errors.location && (
                <span className="Stud-personal-error">
                  location is required
                </span>
              )}
            </Col>
          </Row>

          <div className=" px-4 mt-5">
            <button className="button1 m-2" type="submit">
              Submit
            </button>
            <button className="button2 m-2" type="reset">
              Reset
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Post;
