import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Button } from "antd";
import "../../../../Components/Css/Professional/professionalstyle.scss";
import { Dropdown } from "primereact/dropdown";
import Api from "../../../../Api";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function PersonalInfo() {
  const { state: locationState } = useLocation();
  console.log("locationState", locationState);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({ defaultValues: locationState ? locationState.ele : {} });

  // const onSubmit = data => console.log(data);

  const [countryList, setcountryList] = useState([]);
  const [countryValue, setCountryValue] = useState();
  const [stateList, setstateList] = useState([]);
  const [stateValue, setStateValue] = useState();
  const [districtList, setdistrictList] = useState([]);
  const [districtValue, setDistrictValue] = useState();
  const [genderList, setGenderList] = useState();
  const [gender, setGender] = useState();
  const [maritalList, setMaritalList] = useState();
  const [Marital, setMarital] = useState();
  const [Check, setCheck] = useState(false);
  const [verify, setVerify] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [cityvalue, setCityValue] = useState();
  const [noticeList, setNoticeList] = useState([]);

  useEffect(() => {
    // getDetails();
    getCountry();
    getGender();
    getMarital();
  }, []);

  const getMarital = async () => {
    await Api.get("marital/getMarital").then((res) => {
      setMaritalList(res.data.data);
      console.log("res.data.data@@", res.data.data);
    });
  };

  const getGender = async () => {
    await Api.get("gender/getGenderlist").then((res) => {
      console.log("res", res);
      setGenderList(res.data.data);
    });
  };
  const getCountry = async () => {
    await Api.get("country/getallcountry").then((res) => {
      setcountryList(res.data.data);
      console.log("object :cccccc>> ", res.data.data);
    });
  };

  const getState = (country_id) => {
    console.log("stateList", country_id);
    setCountryValue(country_id);
    Api.get(`state/stateById/${country_id}`).then((res) => {
      setstateList(res.data.data);
      console.log("object sssss:>> ", res.data.data);
    });
  };
  // const toast = useRef(null);
  const navigate = useNavigate();

  const getDistrict = (state_id) => {
    console.log("state_id@@@", state_id);
    setStateValue(state_id);
    Api.get(`district/districtById/${state_id}`).then((res) => {
      setdistrictList(res.data.data);
      console.log("object ddddd:>> ", res.data.data);
    });
  };
  const getCity = (districtId) => {
    console.log("state_id@@@districtId", districtId);
    setDistrictValue(districtId);
    Api.get(`city/cityById/${districtId}`).then((res) => {
      setCityList(res.data.data);
      console.log("object ddddd:>> ", res.data.data);
    });
  };

  // const getDetails = async () => {
  //   const userId = localStorage.getItem("userId");
  //   console.log("userId", userId);

  //   await Api.get(`employeeUser/employee_getone/${userId}`).then((res) => {
  //     setValue("firstname", res.data.data?.firstName);
  //     setValue("lastname", res.data.data?.LastName);
  //     setValue("phoneNumber", res.data.data?.phone);
  //   });
  // };

  // const handleFormSubmit = async (data) => {
  //   const Details = {
  //     DEGREEyop: getValues().DEGREEyop,
  //     EmployeeId: getValues().EmployeeId,
  //     ExpCompanyName: getValues().ExpCompanyName,
  //     ExpFromDate: getValues().ExpFromDate,
  //     ExptoDate: getValues().ExptoDate,
  //     aadharNumber: getValues().aadharNumber,
  //     address: getValues().address,
  //     alternativeNumber: getValues().alternativeNumber,
  //     cityValue: getValues().cityValue,
  //     country: getValues().country,
  //     dateofBirth: getValues().dateofBirth,
  //     degreeboard: getValues().degreeboard,
  //     degreeper: getValues().degreeper,
  //     degrees: getValues().degrees,
  //     designation: getValues().designation,
  //     district: getValues().district,
  //     doj: getValues().doj,
  //     email: getValues().email,
  //     employerPasswordNumber: getValues().employerPasswordNumber,
  //     employerUserName:getValues().employerUserName,
  //     firstname: getValues().firstname,
  //     gboard:getValues().gboard,
  //     gender:getValues().gender,
  //     gmajor: getValues().gmajor,
  //     gper: getValues().gper,
  //     gyop:getValues().gyop,
  //     lastname:getValues().lastname,
  //     maritalstatus:getValues().maritalstatus,
  //     masmajor: getValues().masmajor,
  //     mmajor: getValues().mmajor,
  //     panNumber:getValues().panNumber,
  //     passportExpiryDate:getValues().passportExpiryDate,
  //     passportIssueDate: getValues().passportIssueDate,
  //     passportNumber: getValues().passportNumber,
  //     pgboard:getValues().pgboard,
  //     pgper: getValues().pgper,
  //     pgyop:getValues().pgyop,
  //     phoneNumber:getValues().phoneNumber,
  //     pincode: getValues().pincode,
  //     resume: getValues().resume,
  //     stateValue:getValues().stateValue,
  //     uploadPhoto: getValues().uploadPhoto,
  //     xboard: getValues().xboard,
  //     xiiboard: getValues().xiiboard,
  //     xiimajor:getValues().xiimajor,
  //     xiiper: getValues().xiiper,
  //     xiiyop: getValues().xiiyop,
  //     xmajor:getValues().xmajor,
  //     xper: getValues().xper,
  //     xyop: getValues().xyop,
  //   };

  //   await Api.post(`ResourceOurEmployee/ResourceEmployeeCreate`, Details).then((resp) => {
  //     console.log("resp.data", resp.data);
  //   });
  // };

  const handleFormSubmit = (data) => {
    try {
      const Details = {
        DEGREEyop: getValues().DEGREEyop,
        EmployeeId: getValues().EmployeeId,
        ExpCompanyName: getValues().ExpCompanyName,
        ExpFromDate: getValues().ExpFromDate,
        ExptoDate: getValues().ExptoDate,
        aadharNumber: getValues().aadharNumber,
        address: getValues().address,
        alternativeNumber: getValues().alternativeNumber,
        cityValue: getValues().cityValue,
        country: getValues().country,
        dateofBirth: getValues().dateofBirth,
        degreeboard: getValues().degreeboard,
        degreeper: getValues().degreeper,
        degrees: getValues().degrees,
        designation: getValues().designation,
        district: getValues().district,
        doj: getValues().doj,
        email: getValues().email,
        employerPasswordNumber: getValues().employerPasswordNumber,
        employerUserName: getValues().employerUserName,
        firstname: getValues().firstname,
        gboard: getValues().gboard,
        gender: getValues().gender,
        gmajor: getValues().gmajor,
        gper: getValues().gper,
        gyop: getValues().gyop,
        lastname: getValues().lastname,
        maritalstatus: getValues().maritalstatus,
        masmajor: getValues().masmajor,
        mmajor: getValues().mmajor,
        panNumber: getValues().panNumber,
        passportExpiryDate: getValues().passportExpiryDate,
        passportIssueDate: getValues().passportIssueDate,
        passportNumber: getValues().passportNumber,
        pgboard: getValues().pgboard,
        pgper: getValues().pgper,
        pgyop: getValues().pgyop,
        phoneNumber: getValues().phoneNumber,
        pincode: getValues().pincode,
        resume: getValues().resume,
        stateValue: getValues().stateValue,
        uploadPhoto: getValues().uploadPhoto,
        xboard: getValues().xboard,
        xiiboard: getValues().xiiboard,
        xiimajor: getValues().xiimajor,
        xiiper: getValues().xiiper,
        xiiyop: getValues().xiiyop,
        xmajor: getValues().xmajor,
        xper: getValues().xper,
        xyop: getValues().xyop,
      };

      if (locationState) {
        //update
        Api.put(
          `ResourceOurEmployee/putOurEmployees/${locationState.ele._id}`,
          data
        ).then((res) => {
          if (res.status === 200) {
            console.log("res", res);
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
              // onClose:()=>{
              //   navigate(-1);
              // }
            });
          } else {
            toast.error("Edit failed", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
              // onClose:()=>{
              //     navigate(-1);
              // }
              
            });
          }
        });
      } else {
        //create
        Api.post(`ResourceOurEmployee/ResourceEmployeeCreate`, Details)
          .then((resp) => {
            navigate(-1);
            console.log("111111", resp);
          })
          .catch((resp) => {
            console.log("error");
          });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong,please try again!!!",
        life: 3000,
      });
    }
  };

  // const handleFormSubmit = async (data) => {
  //   try {
  //     const formValues = { ...getValues() };

  //     if (locationState) {
  //       // Update existing resource
  //       await updateResource(formValues);
  //     } else {
  //       // Create a new resource
  //       await createResource(formValues);
  //     }

  //     toast.success("Operation successful", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 1000,
  //     });

  //     navigate(-1);
  //   } catch (error) {
  //     handleFormError(error);
  //   }
  // };

  // const updateResource = async (formValues) => {
  //   try {
  //     const response = await Api.put(
  //       `ResourceOurEmployee/putOurEmployees/${locationState.ele._id}`,
  //       formValues
  //     );
  //     toast.success(response.data.message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 1000,
  //     });

  //     // Handle the response here if needed
  //   } catch (error) {
  //     throw new Error("Failed to update resource: " + error.message);
  //   }
  // };

  // const createResource = async (formValues) => {
  //   try {
  //     const response = await Api.post(
  //       "ResourceOurEmployee/ResourceEmployeeCreate",
  //       formValues
  //     );

  //     // Handle the response here if needed
  //   } catch (error) {
  //     throw new Error("Failed to create resource: " + error.message);
  //   }
  // };

  const handleFormError = (error) => {
    toast.error("Something went wrong. Please try again.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };

  //////////
  const [file, setFile] = useState();
  console.log('file', file)

  // function handleChange(e) {
  //   console.log(e.target.files);
  //   setFile(URL.createObjectURL(e.target.files[0]));
  //   // setFile(URL.createObjectURL(e.target.files[0]));
  // }
  function handleChange(e) {
    console.log('e', e)
    console.log('handleChange called');
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  console.log('file', file)

  const noticeOptions =
    noticeList &&
    noticeList.map((list) => {
      return { label: list?.noticePeriod, value: list?.noticePeriod };
    });
  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="personal-informations p-4">
          <div className="pages-title mb-3">Our Employee Info</div>
          <Row className="mb-4">
            <Col sm={24} md={24} xs={8} className="px-4">
              <div>
                <img
                  src={file}
                  style={{
                    width: "200px",
                    height: "200px",
                  }}
                />
              </div>
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <br />
              <label className="input-title">Upload Photo</label>
              <br />
              <input
                type="file"
                onChange={handleChange}
                // {...register("uploadPhoto", { required: true })}
              />
              {errors.uploadPhoto && (
                <span className="input-error">Photo is required</span>
              )}
            </Col>
          </Row>
          {/*  */}
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Employee Id Number</label> <br />
              <input
                className="Professional__UpdateProfile__Input"
                {...register("EmployeeId", { required: true })}
              />
              <br />
              {errors.EmployeeId && (
                <span className="input-error">Employee Id required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">First Name</label>
              <br />
              <input
                className="Professional__UpdateProfile__Input"
                {...register("firstname", { required: true })}
              />
              <br />
              {errors.firstname && (
                <span className="input-error">First Name required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Last Name</label> <br />
              <input
                className="Professional__UpdateProfile__Input"
                {...register("lastname", { required: true })}
              />
              <br />
              {errors.lastname && (
                <span className="input-error">Last Name required</span>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Phone Number</label> <br />
              <input
                className="Professional__UpdateProfile__Input"
                {...register("phoneNumber", { required: true })}
              />
              {errors.phoneNumber && (
                <span className="input-error"> Number required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Alternative Number</label> <br />
              <input
                className="Professional__UpdateProfile__Input"
                {...register("alternativeNumber", { required: true })}
              />
              <br />
              {errors.alternativeNumber && (
                <span className="input-error">Alternative Number required</span>
              )}
            </Col>

            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">PAN Number</label> <br />
              <input
                className="Professional__UpdateProfile__Input"
                {...register("panNumber", { required: true })}
              />
              <br />
              {errors.panNumber && (
                <span className="input-error"> PAN required</span>
              )}
            </Col>
          </Row>
          {/*  */}

          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Aadhar Number</label> <br />
              <input
                className="Professional__UpdateProfile__Input"
                {...register("aadharNumber", { required: true })}
              />
              <br />
              {errors.aadharNumber && (
                <span className="input-error"> Aadhar Number required</span>
              )}
            </Col>

            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Gender </label>
              <Dropdown
                className="input-field"
                value={gender}
                options={genderList}
                {...register("gender", { required: true })}
                optionLabel={"gender"}
                optionValue={"_id"}
                onChange={(e) => setGender(e.value)}
                placeholder="Select a Gender"
              />
              <br />
              {errors.gender && (
                <span className="input-error">Gender required</span>
              )}
            </Col>

            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Marital status </label>
              <Dropdown
                className="Professional__UpdateProfile__Input"
                value={Marital}
                options={maritalList}
                {...register("maritalstatus", { required: true })}
                optionLabel={"Marital"}
                optionValue={"_id"}
                onChange={(e) => setMarital(e.value)}
                placeholder="Select a Marital status"
              />
              <br />
              {/* <select
                className="Professional__UpdateProfile__Input"
                {...register("maritalstatus", { required: true })}
              >
                <option value="">select </option>
                <option value="a">Married</option>
                <option value="b">Unmarried</option>
                <option value="c">divorced</option>
              </select> */}
              {errors.maritalstatus && (
                <span className="input-error">status required</span>
              )}
            </Col>
          </Row>
          {/*  */}

          {/*  */}

          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Date of Birth </label>
              <input
                className="Professional__UpdateProfile__Input"
                type="date"
                {...register("dateofBirth", { required: true })}
              />
              <br />
              {errors.dateofBirth && (
                <span className="input-error">DOB required</span>
              )}
            </Col>

            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Email Id </label>
              <input
                type="email"
                className="Professional__UpdateProfile__Input"
                {...register("email", { required: true })}
              />
              <br />
              {errors.email && (
                <span className="input-error">Email required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Country </label>
              <Dropdown
                filter
                className="input-field"
                value={countryValue}
                options={countryList}
                {...register("country", { required: true })}
                optionLabel={"name"}
                optionValue={"id"}
                onChange={(e) => getState(e.value)}
              />
              {errors.country && (
                <p className="error-text-color">country is required</p>
              )}
            </Col>
          </Row>
          {/*  */}
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">State</label> <br></br>
              <Dropdown
                filter
                className="input-field p-0"
                value={stateValue}
                options={stateList}
                {...register("stateValue", { required: true })}
                optionLabel={"name"}
                optionValue={"id"}
                onChange={(e) => getDistrict(e.value)}
                placeholder="Select a State"
              />
              <br />
              {errors.stateValue && (
                <span className="input-error">State is required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">District</label>

              <Dropdown
                filter
                className="input-field p-0"
                value={districtValue}
                options={districtList}
                {...register("district", { required: true })}
                optionLabel={"name"}
                optionValue={"id"}
                onChange={(e) => getCity(e.value)}
                placeholder="Select a District"
              />
              <br />
              {errors.district && (
                <span className="input-error">District is required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">City</label> <br></br>
              <Dropdown
                filter
                className="input-field p-0"
                value={cityvalue}
                options={cityList}
                {...register("City", { required: true })}
                optionLabel={"cityName"}
                optionValue={"_id"}
                onChange={(e) => setCityValue(e.value)}
              />
              <br />
              {errors.City && (
                <span className="input-error">City is required</span>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Address</label>
              <textarea
                rows="1"
                className="Professional__UpdateProfile__Input"
                {...register("address", { required: true })}
              />

              {errors.address && (
                <span className="input-error">Address required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">PinCode </label> <br />
              <input
                className="Professional__UpdateProfile__Input"
                {...register("pincode", { required: true })}
              />
              <br />
              {errors.pincode && (
                <span className="input-error">PinCode required</span>
              )}
            </Col>

            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Upload Resume</label>
              <br />
              <input
                type="file"
                className="Professional__UpdateProfile__Input"
                // onChange={handleChange}
                {...register("resume", { required: true })}
              />

              {errors.resume && (
                <span className="input-error">Resume is required</span>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Employer User Name</label>
              <br />
              <input
                className="Professional__UpdateProfile__Input"
                {...register("employerUserName", { required: true })}
              />
              <br />
              {errors.employerUserName && (
                <span className="input-error">Employer User Name required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Employer Password</label> <br />
              <input
                className="Professional__UpdateProfile__Input"
                {...register("employerPasswordNumber", { required: true })}
              />
              {errors.employerPasswordNumber && (
                <span className="input-error"> Employer Password required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Date of Joining </label>
              <input
                className="Professional__UpdateProfile__Input"
                type="date"
                {...register("dateofJoing", { required: true })}
              />
              <br />
              {errors.dateofJoing && (
                <span className="input-error">DOJ required</span>
              )}
            </Col>
          </Row>

          <div className="px-4">
            <label>Passport is Available</label>
            <p>
              <input
                type="radio"
                value="Experience"
                name="Candidates"
                onChange={() => setVerify(!verify)}
              />
              &nbsp;&nbsp;&nbsp; Yes
            </p>
            <h6>
              {verify ? (
                <div>
                  <Row className="mb-4">
                    <Col sm={24} md={8} xs={24} className="px-4">
                      <label className="input-title">Passport Number</label>{" "}
                      <br />
                      <input
                        className="Professional__UpdateProfile__Input"
                        {...register("passportNumber", { required: true })}
                      />
                      {errors.passportNumber && (
                        <span className="input-error">
                          Passport Numbe required
                        </span>
                      )}
                    </Col>
                    <Col sm={24} md={8} xs={24} className="px-4">
                      <label className="input-title">
                        Passport Issue Date{" "}
                      </label>
                      <input
                        className="Professional__UpdateProfile__Input"
                        type="date"
                        {...register("passportIssueDate", { required: true })}
                      />
                      <br />
                      {errors.passportIssueDate && (
                        <span className="input-error">
                          Passport issue date required
                        </span>
                      )}
                    </Col>

                    <Col sm={24} md={8} xs={24} className="px-4">
                      <label className="input-title">
                        Passport Expiry Date{" "}
                      </label>
                      <input
                        className="Professional__UpdateProfile__Input"
                        type="date"
                        {...register("passportExpiryDate", { required: true })}
                      />
                      <br />
                      {errors.passportExpiryDate && (
                        <span className="input-error">
                          Passport Expiry Date required
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              ) : null}
            </h6>

            <p>
              <input
                type="radio"
                value="Fresher"
                name="Candidates"
                onChange={() => setVerify(false)}
              />
              &nbsp;&nbsp;&nbsp; No
            </p>
          </div>

          <Row className="mb-4">
            <Col className="px-4">
              <div className="Stud-qual-table">
                <tr>
                  <div>
                    <label className="mb-2">Qualification</label>
                  </div>
                  <td>
                    <div>
                      <table>
                        <tr>
                          <td align="center">
                            <b>Sl.No.</b>
                          </td>
                          <td align="center">
                            <b>Examination</b>
                          </td>
                          <td align="center">
                            <b>Board/Institution</b>
                          </td>
                          <td align="center">
                            <b>Major</b>
                          </td>
                          <td align="center">
                            <b>Percentage</b>
                          </td>
                          <td align="center">
                            <b>Year of Passing</b>
                          </td>
                        </tr>

                        <tr>
                          <td>1</td>
                          <td>Class X</td>
                          <td>
                            <input
                              style={{ marginTop: "8px", marginLeft: "5px" }}
                              type="text"
                              name="ClassX_Board"
                              className="input-field"
                              {...register("xboard", { required: true })}
                              maxlength="30"
                            />
                            <br />
                            {errors.xboard && (
                              <span className="Stud-qual-error">
                                ClassX_Board is required
                              </span>
                            )}
                          </td>
                          <td>
                            <input
                              style={{ marginTop: "8px" }}
                              type="text"
                              name="ClassX_major"
                              maxlength="30"
                              className="input-field"
                              {...register("xmajor", { required: true })}
                            />
                            <br />
                            {errors.xmajor && (
                              <span className="Stud-qual-error">
                                ClassX_major is required
                              </span>
                            )}
                          </td>
                          <td>
                            <input
                              style={{ marginTop: "15px" }}
                              type="text"
                              name="ClassX_Percentage"
                              maxlength="30"
                              className="input-field"
                              {...register("xper", { required: true })}
                            />
                            <br />
                            {errors.xper && (
                              <span className="Stud-qual-error">
                                ClassX_Percentage is required
                              </span>
                            )}
                          </td>
                          <td>
                            <input
                              style={{ marginTop: "8px" }}
                              type="text"
                              name="ClassX_YrOfPassing"
                              maxlength="30"
                              className="input-field"
                              {...register("xyop", { required: true })}
                            />
                            <br />
                            {errors.xyop && (
                              <span className="Stud-qual-error">
                                ClassX_YrOfPassing is required
                              </span>
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td>2</td>
                          <td>Class XII</td>
                          <td>
                            <input
                              style={{ marginTop: "15px", marginLeft: "5px" }}
                              type="text"
                              name="ClassXII_Board"
                              maxlength="30"
                              className="input-field"
                              {...register("xiiboard", { required: true })}
                            />
                            <br />
                            {errors.xiiboard && (
                              <span className="Stud-qual-error">
                                ClassXII_Board is required
                              </span>
                            )}
                          </td>
                          <td>
                            <input
                              style={{ marginTop: "15px" }}
                              type="text"
                              name="ClassXii_major"
                              maxlength="30"
                              className="input-field"
                              {...register("xiimajor", { required: true })}
                            />
                            <br />
                            {errors.xiimajor && (
                              <span className="Stud-qual-error">
                                ClassXII_major is required
                              </span>
                            )}
                          </td>
                          <td>
                            <input
                              style={{ marginTop: "15px" }}
                              type="text"
                              name="ClassXII_Percentage"
                              maxlength="30"
                              className="input-field"
                              {...register("xiiper", { required: true })}
                            />
                            <br />
                            {errors.xiiper && (
                              <span className="Stud-qual-error">
                                ClassXII_Percentage is required
                              </span>
                            )}
                          </td>
                          <td>
                            <input
                              style={{ marginTop: "15px" }}
                              type="text"
                              name="ClassXII_YrOfPassing"
                              maxlength="30"
                              className="input-field"
                              {...register("xiiyop", { required: true })}
                            />
                            <br />
                            {errors.xiiyop && (
                              <span className="Stud-qual-error">
                                ClassXII_YrOfPassing is required
                              </span>
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td>3</td>
                          <td>Under Graduation</td>
                          <td>
                            <input
                              style={{ marginTop: "15px", marginLeft: "5px" }}
                              type="text"
                              name="Graduation_Board"
                              {...register("gboard", { required: true })}
                              maxlength="30"
                              className="input-field"
                            />
                            <br />
                            {errors.gboard && (
                              <span className="Stud-qual-error">
                                Institution is required
                              </span>
                            )}
                          </td>
                          <td>
                            <input
                              style={{ marginTop: "15px" }}
                              type="text"
                              name="Classg_major"
                              maxlength="30"
                              className="input-field"
                              {...register("gmajor", { required: true })}
                            />
                            <br />
                            {errors.gmajor && (
                              <span className="Stud-qual-error">
                                major subject is required
                              </span>
                            )}
                          </td>
                          <td>
                            <input
                              style={{ marginTop: "15px" }}
                              type="text"
                              name="Graduation_Percentage"
                              maxlength="30"
                              className="input-field"
                              {...register("gper", { required: true })}
                            />
                            <br />
                            {errors.gper && (
                              <span className="Stud-qual-error">
                                Graduation_Percentage is required
                              </span>
                            )}
                          </td>
                          <td>
                            <input
                              type="text"
                              style={{ marginTop: "15px" }}
                              name="Graduation_YrOfPassing"
                              maxlength="30"
                              className="input-field"
                              {...register("gyop", { required: true })}
                            />
                            <br />
                            {errors.gyop && (
                              <span className="Stud-qual-error">
                                Graduation_YrOfPassing is required
                              </span>
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td>4</td>
                          <td>Post Graduation</td>
                          <td>
                            <input
                              style={{ marginTop: "15px", marginLeft: "5px" }}
                              type="text"
                              name="Masters_Board"
                              {...register("pgboard", { required: true })}
                              maxlength="30"
                              className="input-field"
                            />
                            <br />
                            {errors.pgboard && (
                              <span className="Stud-qual-error">
                                Post Graduation_institution is required
                              </span>
                            )}
                          </td>
                          <td>
                            <input
                              style={{ marginTop: "15px" }}
                              type="text"
                              name="Classm_major"
                              maxlength="30"
                              className="input-field"
                              {...register("mmajor", { required: true })}
                            />
                            <br />
                            {errors.mmajor && (
                              <span className="Stud-qual-error">
                                Masters_major subject is required
                              </span>
                            )}
                          </td>
                          <td>
                            <input
                              style={{ marginTop: "15px" }}
                              type="text"
                              name="Masters_Percentage"
                              maxlength="30"
                              className="input-field"
                              {...register("pgper", { required: true })}
                            />
                            <br />
                            {errors.pgper && (
                              <span className="Stud-qual-error">
                                Masters_Percentage is required
                              </span>
                            )}
                          </td>
                          <td>
                            <input
                              style={{ marginTop: "15px" }}
                              type="text"
                              name="Masters_YrOfPassing"
                              maxlength="30"
                              className="input-field"
                              {...register("pgyop", { required: true })}
                            />
                            <br />
                            {errors.pgyop && (
                              <span className="Stud-qual-error">
                                Masters_YrOfPassing is required
                              </span>
                            )}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </td>
                </tr>
                <br />
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="px-4">
              <p>
                {" "}
                <input
                  type="checkbox"
                  onChange={(e) => setVerify(e.target.checked)}
                />
                &nbsp;&nbsp;&nbsp; Add Degree
              </p>
              <h6>
                {verify ? (
                  <div>
                    <table className="ms-5">
                      <tr>
                        <td align="center">
                          <b>Masters</b>
                        </td>
                        <td align="center">
                          <b>Board/Institution</b>
                        </td>
                        <td align="center">
                          <b>Major</b>
                        </td>
                        <td align="center">
                          <b>Percentage</b>
                        </td>
                        <td align="center">
                          <b>Year of Passing</b>
                        </td>
                      </tr>
                      <tr>
                        {/* <td>Degree</td> */}
                        <td className="ms-3">
                          <input
                            type="text"
                            name="Masters_Board"
                            {...register("degrees", { required: true })}
                            maxlength="30"
                            className="input-field"
                          />
                          <br />
                          {errors.degrees && (
                            <span className="Stud-qual-error">
                              Degree is required
                            </span>
                          )}
                        </td>
                        <td className="ms-3">
                          <input
                            type="text"
                            name="Masters_Board"
                            {...register("degreeboard", { required: true })}
                            maxlength="30"
                            className="input-field"
                          />
                          <br />
                          {errors.degreeboard && (
                            <span className="Stud-qual-error">
                              Institution is required
                            </span>
                          )}
                        </td>
                        <td>
                          <input
                            type="text"
                            name="Classm_major"
                            maxlength="30"
                            className="input-field"
                            {...register("masmajor", { required: true })}
                          />
                          <br />
                          {errors.masmajor && (
                            <span className="Stud-qual-error">
                              major subject is required
                            </span>
                          )}
                        </td>
                        <td>
                          <input
                            type="text"
                            name="Masters_Percentage"
                            maxlength="30"
                            className="input-field"
                            {...register("degreeper", { required: true })}
                          />
                          <br />
                          {errors.degreeper && (
                            <span className="Stud-qual-error">
                              Percentage is required
                            </span>
                          )}
                        </td>
                        <td>
                          <input
                            type="text"
                            name="Masters_YrOfPassing"
                            maxlength="30"
                            className="input-field"
                            {...register("DEGREEyop", { required: true })}
                          />
                          <br />
                          {errors.DEGREEyop && (
                            <span className="Stud-qual-error">
                              Yr OfPassing is required
                            </span>
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>
                ) : null}
              </h6>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="px-4" sm={24} md={5} xs={24}>
              <p>
                <input
                  type="radio"
                  value="Fresher"
                  name="Candidates"
                  onChange={() => setCheck(false)}
                />
                &nbsp;&nbsp;&nbsp; Fresher Candidates
              </p>
            </Col>
            <Col className="px-4" sm={24} md={19}>
              <p>
                <input
                  type="radio"
                  value="Experience"
                  name="Candidates"
                  onChange={() => setCheck(!Check)}
                />
                &nbsp;&nbsp;&nbsp; Experience Candidates
              </p>
              <h6>
                {Check ? (
                  <div>
                    <table className="ms-5">
                      <tr>
                        <td align="center">
                          <b>Company Name</b>
                        </td>
                        <td align="center">
                          <b>Designation</b>
                        </td>
                        <td align="center">
                          <b>From Date</b>
                        </td>
                        <td align="center">
                          <b>To Date</b>
                        </td>
                      </tr>
                      <tr>
                        {/* <td>Degree</td> */}
                        <td className="ms-3">
                          <input
                            type="text"
                            name="Company_Name"
                            {...register("ExpCompanyName", { required: true })}
                            maxlength="30"
                            className="input-field"
                          />
                          <br />
                          {errors.ExpCompanyName && (
                            <span className="Stud-qual-error">
                              Company Name is required
                            </span>
                          )}
                        </td>
                        <td className="ms-3">
                          <input
                            type="text"
                            name="Designation"
                            {...register("designation", { required: true })}
                            maxlength="30"
                            className="input-field"
                          />
                          <br />
                          {errors.designation && (
                            <span className="Stud-qual-error">
                              Designation is required
                            </span>
                          )}
                        </td>
                        <td>
                          <input
                            type="date"
                            name="From_Date"
                            maxlength="30"
                            className="input-field"
                            {...register("ExpFromDate", { required: true })}
                          />
                          <br />
                          {errors.ExpFromDate && (
                            <span className="Stud-qual-error">
                              From Date is required
                            </span>
                          )}
                        </td>
                        <td>
                          <input
                            type="date"
                            name="To_Date"
                            maxlength="30"
                            className="input-field"
                            {...register("ExptoDate", { required: true })}
                          />
                          <br />
                          {errors.ExptoDate && (
                            <span className="Stud-qual-error">
                              To Data is required
                            </span>
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>
                ) : null}
              </h6>
            </Col>
          </Row>

          <div className="submitbuttons px-4">
            <button className="button1 m-2 p-2" type="submit">
              Submit
            </button>
            <button className="button2 m-2 p-2" type="reset">
              Reset
            </button>
          </div>
        </div>
        <ToastContainer />
      </form>
    </>
  );
}

export default PersonalInfo;
