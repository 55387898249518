import { Table, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { MdAdd, MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Api from "../../../Api";
import { Popconfirm } from "antd";
import "antd/dist/antd.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";

function BlogTable() {
  const navigate = useNavigate("");
  const [userData, setUserData] = useState([]);
  const userId = localStorage.getItem("userId");

  // const confirmDelete = (id) => {
  //   Modal.confirm({
  //     title: "Are you sure you want to delete this entry?",
  //     content: "This action cannot be undone.",
  //     okText: "Yes",
  //     okType: "danger",
  //     cancelText: "No",
  //     onOk: () => handleDelete(id),
  //   });
  // };

  const handleDelete = async (id) => {
    try {
      await Api.delete(`trainer/deletetrainerBlog/${id}`);
      setUserData((data) => data.filter((item) => item._id !== id));
    } catch (err) {
      console.log(err);
    }
  };
  console.log("userData", userData);
  const columns = [
    {
      title: "Tag",
      dataIndex: "Tag",
      key: "Tag",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
    },
    {
      title: "Actions",
      key: "actions",
      align: "center",
      // render: (_, record) => (
      //   <>
      //     <FaEdit
      //       size={"20"}
      //       onClick={() =>
      //         navigate("/trainer/Blog/TrainerBlog", { state: { record } })
      //       }
      //     />
      //     &nbsp; &nbsp;
      //     <MdDelete size={"20"} onClick={() => confirmDelete(record._id)} />
      //   </>
      // ),

      render: (_, record) => (
        <>
          {console.log(record, "ADF")}
          <div>
            <FontAwesomeIcon
              icon={faPenToSquare}
              style={{ cursor: "pointer" }}
              onClick={() => {
                console.log("Record", record);
                navigate("/trainer/Blog/TrainerBlog", {
                  state: {
                    record,
                  },
                });
              }}
              className="me-5"
            />
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => {
                handleDelete(record._id);
              }}
            >
              <FontAwesomeIcon
                icon={faTrashCan}
                style={{ cursor: "pointer" }}
              />
            </Popconfirm>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    try {
      const datas = await Api.get(`trainer/getspectrainer`);
      console.log("datas", datas);
      setUserData(datas.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  console.log("userData", userData);

  return (
    <div
      style={{
        marginTop: "40px",
        maxWidth: "90%",
        margin: "0 auto",
        padding: "20px",
      }}
    >
      <div style={{ marginTop: "60px" }}>
        <Container>
          <Button
            onClick={() => navigate("/trainer/Blog/NewtrainerBlog")}
            className="table__container-list-button"
          >
            <MdAdd size={"20"} /> &nbsp; Add New
          </Button>
          <br />
          <br />
          <Table
            columns={columns}
            dataSource={userData}
            pagination={{ pageSize: 5 }}
            rowKey={(record) => record._id || record.id}
            style={{ textAlign: "center" }}
          />
        </Container>
      </div>
    </div>
  );
}

export default BlogTable;
