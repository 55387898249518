import React from 'react';
import { Form, Input, Button, Row, Col, Select, message } from 'antd';
import Api from "../../../Api.js";

const { Option } = Select;

const CreateExamPattern = ({ courses, handleCourseChange, courseTechnologies }) => {
  const onFinishExamPattern = (values) => {
    Api.post('/exam/createexampattern', values)
      .then(() => message.success('Exam pattern created successfully'))
      .catch(() => message.error('Failed to create exam pattern'));
  };

  return (
    <Form layout="vertical" onFinish={onFinishExamPattern}>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label="Course" name="courseId" rules={[{ required: true }]}>
            <Select placeholder="Select course" onChange={handleCourseChange}>
              {courses.map(course => (
                <Option key={course._id} value={course._id}>{course.title}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Technology" name="technologyId" rules={[{ required: true }]}>
            <Select placeholder="Select technology">
              {courseTechnologies.map(tech => (
                <Option key={tech._id} value={tech._id}>{tech.tech_name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Week" name="week" rules={[{ required: true }]}>
            <Input placeholder="Week number" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Question Count" name="questionCount" rules={[{ required: true }]}>
            <Input type="number" placeholder="Enter question count" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label="Duration (minutes)" name="duration" rules={[{ required: true }]}>
            <Input type="number" placeholder="Exam duration" />
          </Form.Item>
        </Col>
      </Row>
      <Button type="primary" htmlType="submit">Create Exam Pattern</Button>
    </Form>
  );
};

export default CreateExamPattern;
