import React , {useState, useEffect} from "react";
import { Col, Row, Card } from "antd";
import "../../../Components/Css/Sidebar.scss";
import { AiOutlineUser, AiOutlineTeam,AiOutlineForm } from "react-icons/ai";
import { IoNewspaperOutline } from "react-icons/io5";
import { FaUserGraduate, FaChalkboardTeacher } from "react-icons/fa";
import Api from "../Api";

export default function StudentDashboard() {
  const [studentCount, setStudentCount] = useState([]);
  const [staffCount, setStaffCount] = useState([]);
  useEffect(() => {
    getCountStudent();
    getCountStaff();
  }, []);
  // Students
  const getCountStudent = async () => {
    await Api.get("/signup/getAll").then((res) => {
      setStudentCount(res.data.data.length);
    });
  };
    // Staff
  const getCountStaff = async () => {
    await Api.get("/college/college_get").then((res) => {
      setStaffCount(res.data.data.length);
    });
  };
  return (
    <div >
      <Row className="admin-row ms-5">
        <Col sm={24} md={12} lg={8} className="dash-col">
          <Card className="Dash-cards">
            <div className="card-field">
              <div>
                <FaChalkboardTeacher
                  className="dashIcons"
                
                />
              </div>
              <div>
            
                 <h4 className="fieldsname">Staff</h4>
                {/* <h4 className="fieldscount">55</h4> */}
                <h4 className="fieldscount">{staffCount}</h4>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm={24} md={12} lg={8} className="dash-col">
          <Card className="Dash-cards">
            <div className="card-field">
              <div>
                <FaUserGraduate
                  className="dashIcons"
                 
                />
              </div>
              <div>
                <h4 className="fieldsname">Students</h4>
                <h4 className="fieldscount">{studentCount}</h4>
                {/* <h4 className="fieldscount">450</h4> */}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
