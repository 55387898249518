import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "antd";
import { useNavigate, Link } from "react-router-dom";
import "../../../Components/Css/Dashboard.scss";
import jobs from "../../../assets/jobs.jpg";
import comments from "../../../assets/comment.jpg";
import trends from "../../../assets/trends.jpg";
import search from "../../../assets/search.jpg";
import resume from "../../../assets/resume.jpg.jpg";
import com from "../../../assets/com.jpg";
import Api from "./../../../Api";

// import "./EmployerDashboard.css";
import { BiBuilding, BiTimer, BiCalendar } from "react-icons/bi";
import { AiOutlineArrowRight } from "react-icons/ai";
import {
  MdPersonSearch,
  MdOutlinePermContactCalendar,
  MdOutlinePeopleOutline,
} from "react-icons/md";
import { BsAlarm } from "react-icons/bs";
import { SlCalender } from "react-icons/sl";

export default function EmployerDashboard() {
  const [projectCount, setProjectCount] = useState([]);
const [internshipCount,setInternshipCount]=useState([])
const [hiringCount,setHiringCount]=useState([])
  const userid = localStorage.getItem("userId");
  const id = localStorage.getItem("id");

console.log('first', userid)
  useEffect(()=>{
    getProjectCount();
    getInternshipCount();
    getHiringCount();
  })
  const getProjectCount = async () => {
    await Api.get(`/projectpost/getoneproject/${userid}`).then((res) => {
      console.log('firstres', res)
      setProjectCount(res.data.data.length);
    });
  };
  const getInternshipCount = async () => {
    await Api.get(`/Internship/getInternship/${id}`).then((res) => {
      console.log('firstres', res)
      setInternshipCount(res.data.data.length);
    });
  };
  const getHiringCount = async()=>{
    await Api.get("http://localhost:8088/selected/getSelected").then((res)=>{
      setHiringCount(res.data.data.length)
    })
  }
  return (
    <div>
      <Row className="admin-row">
        <Col sm={24} md={12} lg={8} className="dash-col">
          <Card className="Dash-cards">
            <div className="card-field">
              <div>
                <BiBuilding
                  className="dashIcons"
                  style={{ fontSize: "350%", color: " #4f7eb5" }}
                />
              </div>
              <div>
                <h4 className="fieldsname">No of Hiring</h4>
                <h4 className="fieldscount">{hiringCount}</h4>
              </div>
            </div>
          </Card>
        </Col>
        {/* <Col sm={24} md={12} lg={8} className="dash-col">
          <Card className="Dash-cards">
            <div className="card-field">
              <div>
                <BiBuilding
                  className="dashIcons"
                  style={{ fontSize: "350%", color: " #4f7eb5" }}
                />
              </div>
              <div>
                <h4 className="fieldsname">Number of Posting</h4>
                <h4 className="fieldscount">26</h4>
              </div>
            </div>
          </Card>
        </Col> */}
        <Col sm={24} md={12} lg={8} className="dash-col">
          <Card className="Dash-cards">
            <div className="card-field">
              <div>
                <BiBuilding
                  className="dashIcons"
                  style={{ fontSize: "350%", color: " #4f7eb5" }}
                />
              </div>
              <div>
                <h4 className="fieldsname">Number of Project</h4>
                <h4 className="fieldscount">{projectCount}</h4>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm={24} md={12} lg={8} className="dash-col">
          <Card className="Dash-cards">
            <div className="card-field">
              <div>
                <BiBuilding
                  className="dashIcons"
                  style={{ fontSize: "350%", color: " #4f7eb5" }}
                />
              </div>
              <div>
                <h4 className="fieldsname">Number of Internship</h4>
                <h4 className="fieldscount">{internshipCount}</h4>
              </div>
            </div>
          </Card>
        </Col>
      </Row> 
    
    </div>
  );
}
