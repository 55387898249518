import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "antd";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Api from "../../../Api";
import { Tabs } from "antd";
import Approved from "./Approved";
import  Badge from "react-bootstrap/Badge/";
import Stack from "react-bootstrap/Stack";


import "../../../Components/Css/Students/Studintdetails.scss";


function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = eventKey === activeEventKey;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#EBE6FF",
        paddingTop: "12px",
      }}
    >
      <p onClick={decoratedOnClick}>
        {children}
        {isCurrentEventKey ? <FiChevronUp /> : <FiChevronDown />}
      </p>
    </div>
  );
}

function Pending() {
  const [createForm, setcreateForm] = useState([]);
  const id = localStorage.getItem("userId");
  useEffect(() => {
    getCreateForm();
  }, []);

  const getCreateForm = async () => {
    await Api.get(`blog/requestgetone/${id}`).then((res) => {
      setcreateForm(res.data.data);
    });
  };

  return (
    <div className="p-4">
      <div className="pages-title" styles={{ color: "green" }}>
        Pending
      </div>
      
        
      <Row className="p-3">
        {createForm.map((data, i) => (
          <Col sm={24} md={24} lg={8} className="p-3">
            <Accordion defaultActiveKey="0">
              <Card className="pending-card">
                <Card.Body>
                  <Row>
                  <Col lg={16}>
                  <h4>{data.projectTitle}</h4>
                  <p className="Int-para1">{data.duration}</p>
                  <p className="Int-para2">
                    <b>Role:</b>
                    {data.role}
                  </p>
                  <p className="Int-para2">
                    <b>Mail-Id:</b>
                    {data.userEmail}
                  </p>
                  </Col>
                  <Col lg={8}>
                  <Stack direction="horizontal" className="pending-stack" >
                  <Badge pill bg="pending" style={{fontSize:'13px',fontWeight:'350',color:'black',backgroundColor: '#FFEB3B'}} className="pending-badge">Pending</Badge>
                  </Stack>
                  </Col>
                  </Row> 
                  <Card.Text>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <p>{data.description}</p>
                      

                      </Card.Body>
                    </Accordion.Collapse>
                  </Card.Text>
                
                 
                </Card.Body>
              </Card>
            </Accordion>
          </Col>
        ))}
      </Row>
     
    </div>
  );
}

export default Pending;
