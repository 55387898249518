import React, { useState, useEffect } from "react";
import "./blogs.scss";
import Layout from "../../Components/Layout";
import axios from "axios";
import { Col, Row, Card } from "react-bootstrap";
import { BsArrowRightCircle } from "react-icons/bs";
import { IoIosSearch } from "react-icons/io";
import { FaRegCommentDots } from "react-icons/fa";
import Api from "../../Api";

function Blogs() {
  const [blogs, setBlogs] = useState([]); // State to hold blog data
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch blogs from the backend
    const fetchBlogs = async () => {
      try {
        const response = await Api.get("/blog/getBlog"); // Replace with your API URL
        setBlogs(response.data.data); // Assuming your API response has the structure { data: [...] }
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []); // Empty dependency array ensures this runs once when the component mounts

  if (loading) {
    return <div>Loading...</div>; // Loading state
  }

  if (error) {
    return <div>Error fetching blogs: {error.message}</div>; // Error state
  }

  return (
    <Layout>
      <div>
        <header style={{ height: '200px', backgroundColor: 'whitesmoke', width: '100%', textAlign: 'center', paddingTop: '100px' }}>
          <h2>Blog</h2>
          <h5>Home/Blogs</h5>
        </header>
        <Row className="blog-slide">
          <Col lg={8}> {/* Left column for blogs */}
            <Row xs={1} md={2} className="g-4"> {/* Adjusting for 2 columns on larger screens */}
              {blogs.map((card) => (
                <Col key={card._id}>
                  <Card className="card-zoom" style={{ height: '500px', border: 'none', margin: '15px' }}>
                    <Card.Img className="blogs-image" variant='top' src={card.Image} alt={card.Title} style={{ height: '350px', objectFit: 'cover', borderRadius: '10px' }} />
                    <Card.Body className="cmts">
                      <div className="cmt-like">
                        <Card.Text>{card.date}</Card.Text>
                        <FaRegCommentDots style={{ marginLeft: '40px', fontSize: '20px' }} />
                        <Card.Text style={{ marginLeft: '20px' }}>{card.no}</Card.Text>
                      </div>
                      <Card.Title className='titlefont'>{card.Title}</Card.Title>
                      <Card.Text className='textblock'>{card.Description}</Card.Text>
                      <Card.Text>
                        Read More <BsArrowRightCircle className='rightarrow' />
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
          <Col lg={4}> {/* Right column for filters */}
            <div className="slide-search">
              <h4>Search by Keywords</h4>
              <div className="search-container">
                <IoIosSearch className="search-icon" />
                <input
                  type="text" // Add the input type here
                  placeholder="keywords"
                  className="search-input" // Add a class for styling
                />
              </div>
              <h4>Categories</h4>
              <ol start="1" className="category-list">
                <li>Education</li>
                <li>Information</li>
                <li>Interview</li>
                <li>Job Seeking</li>
                <li>Jobs</li>
                <li>Learn</li>
                <li>Skill</li>
              </ol>
              <h4>Recent Posts</h4>
              {/* <Card className="post-card">
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                  <div>
                    <Card.Img className="blogs-img" variant='top' src={Image1} style={{ height: '100px', objectFit: 'cover', width: '100px', marginRight: '20px', marginLeft: '20px', marginBottom: '20px' }} />
                  </div>
                  <div>
                    <Card.Text><h5>Attract Sales And Profits</h5><p>August 9, 2021</p></Card.Text>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    <Card.Img className="blogs-img" variant='top' src={Image2} style={{ height: '100px', objectFit: 'cover', width: '100px', marginRight: '20px', marginLeft: '20px', marginBottom: '20px' }} />
                  </div>
                  <div>
                    <Card.Text><h5>5 Tips For Your Job Interviews</h5><p>August 9, 2021</p></Card.Text>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    <Card.Img className="blogs-img" variant='top' src={Image3} style={{ height: '100px', objectFit: 'cover', width: '100px', marginRight: '20px', marginLeft: '20px', marginBottom: '20px' }} />
                  </div>
                  <div>
                    <Card.Text><h5>Overworked Newspaper Editor</h5><p>August 9, 2021</p></Card.Text>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    <Card.Img className="blogs-img" variant='top' src={Image4} style={{ height: '100px', objectFit: 'cover', width: '100px', marginRight: '20px', marginLeft: '20px', marginBottom: '20px' }} />
                  </div>
                  <div>
                    <Card.Text><h5>Attract Sales And Profits</h5><p>August 9, 2021</p></Card.Text>
                  </div>
                </div>
              </Card> */}
              <h4>Tags</h4>
              <Row className="tag-row">
                <Col className="tag-col">
                  <button>App</button>
                  <button>Administrative</button>
                  <button>Android</button>
                </Col>
                <Col className="tag-col">
                  <button>WordPress</button>
                  <button>Design</button>
                  <button>React</button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default Blogs;
