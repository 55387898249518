import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BiRupee, BiTimeFive } from "react-icons/bi";
import { CiCalendarDate, CiLocationOn } from "react-icons/ci";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { TfiBag } from "react-icons/tfi";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../../Api";

function ProjectRequestInnerPage() {
  const db = useLocation();
  //   const [createForm, setcreateForm] = useState();
  const navigate = useNavigate();

  const jobPost = db.state;

  const email = localStorage.getItem("email");
  const id = localStorage.getItem("id");
  const name = localStorage.getItem("name");
  console.log("jobPost", jobPost);
  const UserId = jobPost.UserID;
  const projectTitle = jobPost.projectTitle;
  const userEmail = jobPost.userEmail;
  const locations = jobPost.locations;
  const qualification = jobPost.qualification;
  const role = jobPost.role;
  const skill = jobPost.skill;
  const technology = jobPost.technology;

  const onSubmit = (e) => {
    const details = {
      role: name,
      UserID: UserId,
      employerID: id,
      userEmail: userEmail,
      projectTitle: projectTitle,
      email: email,
      locations: locations,
      qualification: qualification,
      role: role,
      skill: skill,
      technology: technology,
    };
    Api.post("projectpost/create_requestproject", details).then((res) => {
      console.log("res", res);
    });
  };
  return (
    <Container>
      <div>
        <div className="ms-4">
          <Row className="p-3">
            <Col>
              <h3 className="mt-3">{jobPost.projectTitle}</h3>

              <div className="ms-4 p-2">
                <h6>
                  <CiCalendarDate /> &nbsp;{jobPost.role}
                </h6>
                <h6>
                  <CiCalendarDate /> &nbsp;{jobPost.userEmail}
                </h6>
                <h6>
                  <TfiBag /> &nbsp;{jobPost.duration}
                </h6>
                <h6>
                  <TfiBag /> &nbsp;{jobPost.qualification}
                </h6>
                <h6>
                  <TfiBag /> &nbsp;{jobPost.skills}
                </h6>
                <h6>
                  <TfiBag /> &nbsp;{jobPost.languages}
                </h6>
              </div>
            </Col>
          </Row>

          <br />
          <div className="d-flex mt-3 mb-2">
            <Button
              className="login-button"
              variant="secondary"
              onClick={() => navigate("/employer/projetrequest")}
            >
              Rejected
            </Button>
            <Button
              className="login-button ms-5"
              variant="primary"
              onClick={(e) => {
                onSubmit();
              }}
            >
              Approved
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default ProjectRequestInnerPage;
