import React, { useState, useEffect } from "react";
import HeaderNavbar from "../../../Components/HeaderNavbar";
import Filter from "./Filter";
import careerblitz from "../../../Images/InstagramImage.png";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import "./skilltest-filter.scss";
import Api from "../../../Api";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact";
import {
  filterbySearch,
  filterSearchbyType,
  filterSearchbyLanguageOn,
  filterSearchbyTechnologyOn,
  filterSearchbyState,
  filterSearchbyDistrict,
  filterSearchbyCity,
  filterSearchbyTechnologyOff,
  filterSearchbyLanguageOff,
} from "../../../Store/Action/filterbySearchAction";

import { Radio } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function FilterPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mode, setMode] = useState(null);
  const arr = [{ name: "online" }, { name: "offline" }];
  const [value, setValue] = useState(null);

  const [search, setSearch] = useState([]);

  const [searchText, setSearchText] = useState();
  const [allBatch, setAllBatch] = useState();
  const [allBatchOne, setAllBatchOne] = useState();
  const [filterAllBactch, setfilterAllBatch] = useState();
  const [countryList, setcountryList] = useState([]);
  const [countryValue, setCountryValue] = useState();
  const [stateList, setstateList] = useState([]);
  const [stateValue, setStateValue] = useState();
  const [districtList, setdistrictList] = useState([]);
  const [districtValue, setDistrictValue] = useState();
  console.log("districtValue", districtValue);
  console.log("first", districtList);
  const [cityvalue, setCityValue] = useState();
  const [cityList, setcityList] = useState([]);
  console.log("cityList", cityList);
  console.log("cityvalue", cityvalue);
  const [techList, setTechList] = useState();
  const [technologyOn, setTechnologyOn] = useState();
  const [technologyOff, setTechnologyOff] = useState();
  const [language, setLanguage] = useState();
  const [languageOn, setLanguageOn] = useState();
  const [languageOff, setLanguageOff] = useState();
  const [allCourse, setAllCourse] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [careerblitzImage, setcareerblitzImage] = useState("");

  const selectedType = useSelector((state) => state.filter.type);
  const selectedCountryValue = useSelector((state) => state.filter.country);
  const selectedStateValue = useSelector((state) => state.filter.state);
  const selectedDistrictValue = useSelector((state) => state.filter.district);
  const selectedCityValue = useSelector((state) => state.filter.city);
  const selectedTechnologyOffValue = useSelector(
    (state) => state.filter.technologyOff
  );
  const selectedLanguageOffValue = useSelector(
    (state) => state.filter.languageOff
  );

  const selectedTechnologyOnValue = useSelector(
    (state) => state.filter.technologyOn
  );
  const selectedLanguageOnValue = useSelector(
    (state) => state.filter.languageOn
  );
  const checker = useSelector((state) => state.filter.checker);

  const city = [
    { label: "Cumbum", value: "Cumbum" },
    { label: "Theni", value: "Theni" },
    { label: "Bodi", value: "Bodi" },
  ];
  const onChange = (e) => {
    console.log(e.target.value, "value");
    setValue(e.target.value);
    dispatch(filterSearchbyType(e.target.value));
  };

  const getCountry = async () => {
    await Api.get("country/getallcountry").then((res) => {
      console.log(res.data, "response");
      setcountryList(res.data.data);
    });
  };

  const getcareerblitzLogo = async () => {
    await Api.get(`adminLogo/getLogo`).then((res) => {
      console.log("resImage", res);
      setcareerblitzImage(res.data[0].logo);
    });
  };

  // useEffect(() => {
  //   setSearchText(null);
  //   if (value != null) {
  //     setTechnologyOn(null);
  //     setLanguageOn(null);
  //     setTechnologyOff(null);
  //     setLanguageOff(null);
  //   const filterarray =
  //     filterAllBactch &&
  //     filterAllBactch?.filter((ele) => {
  //       if (ele?.courseMode?.toLowerCase() == value) {
  //         return ele;
  //       }
  //     });
  //   console.log(filterarray, "filteredArray");
  //   setAllBatch(filterarray);
  //   }
  // }, []);
  console.log(filterAllBactch, selectedCountryValue, "qwertyqwerty");

  useEffect(() => {
    console.log(selectedType, filterAllBactch, "TYPE");
    setfilterAllBatch(filterAllBactch);
    if (selectedType == "Offline") {
      console.log(filterAllBactch, "filterer");
      const filteredArray = filterAllBactch?.filter((ele) => {
        console.log(ele.country, selectedCountryValue, "ele");
        return (
          ele?.courseMode == selectedType &&
          (selectedCountryValue !== ""
            ? ele.country == selectedCountryValue
            : true) &&
          (selectedStateValue !== ""
            ? ele.state == selectedStateValue
            : true) &&
          (selectedDistrictValue !== ""
            ? ele.district == selectedDistrictValue
            : true) &&
          (selectedCityValue !== "" ? ele.city == selectedCityValue : true) &&
          (selectedTechnologyOffValue !== ""
            ? ele.course.toLowerCase() ==
              selectedTechnologyOffValue.toLowerCase()
            : true) &&
          (selectedLanguageOffValue !== ""
            ? ele.language == selectedLanguageOffValue
            : true)
        );
      });
      console.log(filteredArray, "filteredArray");
      setAllBatch(filteredArray);
    } else if (selectedType == "Online") {
      const onlineArray = filterAllBactch?.filter((ele) => {
        console.log(ele.courseMode, selectedType, "ele");
        return (
          ele?.courseMode == selectedType &&
          (selectedTechnologyOnValue != ""
            ? ele?.course == selectedTechnologyOnValue
            : true) &&
          (selectedLanguageOnValue != ""
            ? ele?.language == selectedLanguageOnValue
            : true)
        );
      });
      console.log(onlineArray, "onlineArray");
      setAllBatch(onlineArray);
    }
    setSearchText(null);
  }, [
    selectedCountryValue,
    selectedType,
    selectedTechnologyOnValue,
    selectedLanguageOnValue,
    selectedStateValue,
    selectedDistrictValue,
    selectedCityValue,
    selectedTechnologyOffValue,
    selectedLanguageOffValue,
  ]);

  const handleFilterChange = () => {};

  

  const getState = (country_id) => {
    setCountryValue(country_id);
    Api.get(`state/stateById/${country_id}`).then((res) => {
      setstateList(res.data.data);
    });
  };

  const getDistrict = (state_id) => {
    setStateValue(state_id);
    Api.get(`district/districtById/${state_id}`).then((res) => {
      setdistrictList(res.data.data);
    });
  };
  const getCity = (districtId) => {
    setDistrictValue(districtId);
    Api.get(`city/cityById/${districtId}`).then((res) => {
      setcityList(res.data.data);
    });
  };
  const getTechnology = async () => {
    await Api.get("Technology/get").then((res) => {
      const arr = res?.data?.map((r) => {
        return { label: r.Technology, value: r.Technology };
      });
      setTechList(arr);
    });
  };

  const getLanguage = async () => {
    await Api.get("fields/getlanguage").then((res) => {
      const arr = res?.data?.data.map((r) => {
        return { label: r.languageName, value: r.languageName };
      });
      setLanguage(arr);
    });
  };

  useEffect(() => {
    getCountry();
    getAllBatches();
    getTechnology();
    getLanguage();
    getcareerblitzLogo();
    getAllCourses();
    // getcareerblitzLogo();
  }, []);

  useEffect(() => {
    // Combine allBatchOne and allCourse into a single array and set combinedData state
    Promise.all([allBatchOne, allCourse]).then((values) => {
      const combinedData = [...values[0], ...values[1]];
      setAllBatch(combinedData);
      setfilterAllBatch(combinedData);
    });
  }, [allBatchOne, allCourse]);

  const result = allBatch?.filter((item) => {
    if (item.courseMode === value) {
      return item;
    }
  });

  const [data, setData] = useState();

  const [services, setservices] = useState([
    {
      id: 1,
      headding: "MERN STACK",
      sub: "Online / Ofline",
      image: careerblitz,
      tech: "Node.js, React.js, MongoDB, Express.js ",
    },
    {
      id: 2,
      headding: "MEAN STACK",
      sub: "Online / Ofline",
      image: careerblitz,
      tech: "Node.js, Angular,  MongoDB, Express.js",
    },
    {
      id: 3,
      headding: "JAVA DEVELOPER",
      sub: "Online / Ofline",
      image: careerblitz,
      tech: "Java, Springboot",
    },
    {
      id: 4,
      headding: "MANUAL TESTING",
      sub: "Online / Ofline",
      image: careerblitz,
      tech: "Jira, Test Cases, Test Scenario",
    },
    {
      id: 5,
      headding: "AUTOMATION ",
      sub: "Online / Ofline",
      image: careerblitz,
      tech: "Java with Selenium, Java with python",
    },
    {
      id: 6,
      headding: "AUTOMATION ",
      sub: "Online / Ofline",
      image: careerblitz,
      tech: "Java with Selenium, Java with python",
    },
  ]);

  useEffect(() => {
    // return () => {};
    const result = allBatch?.filter(
      (item) =>
        item.course?.toLowerCase().includes(searchText?.toLowerCase().trim()) ||
        item?.courseMode
          ?.toLowerCase()
          .includes(searchText?.toLowerCase().trim()) ||
        item?.frameWork
          ?.toLowerCase()
          .includes(searchText?.toLowerCase().trim())
    );
    setSearch(result);
  }, [searchText]);

  useEffect(() => {
    console.log(checker, checker);
  }, [selectedType, checker]);

  const getAllBatches = () => {
    Api.get(`Batch/get`).then((res) => {
      setAllBatchOne(res.data);
      // setfilterAllBatch(res.data);
    });
  };

  const getAllCourses = () => {
    Api.get(`traingCourse/getAllCourse`).then((res) => {
      setAllCourse(res.data);
    });
  };


  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const countryFilter = (e) => {
    if (languageOff != undefined && technologyOff != undefined) {
      const filterarray = filterAllBactch?.filter((ele) => {
        if (
          ele.course?.toLowerCase() == technologyOff.toLowerCase() &&
          value == ele.courseMode.toLowerCase() &&
          ele.language.toLowerCase() == languageOff.toLowerCase() &&
          ele.country == e.value
        ) {
          return ele;
        }
      });
      setAllBatch(filterarray);
    } else if (languageOff == undefined && technologyOff != undefined) {
      const filterarray = filterAllBactch?.filter((ele) => {
        if (
          ele.course?.toLowerCase() == technologyOff.toLowerCase() &&
          value == ele.courseMode?.ele.courseMode.toLowerCase() &&
          ele.country?.ele.country == e.value
        ) {
          return ele;
        }
      });
      setAllBatch(filterarray);
    } else if (languageOff != undefined && technologyOff == undefined) {
      const filterarray = filterAllBactch?.filter((ele) => {
        if (
          ele.language?.toLowerCase() == languageOff.toLowerCase() &&
          value == ele.courseMode.toLowerCase() &&
          ele.country == e.value
        ) {
          return ele;
        }
      });
      setAllBatch(filterarray);
    } else if (languageOff == undefined && technologyOff == undefined) {
      const filterarray = filterAllBactch?.filter((ele) => {
        if (value == ele.courseMode.toLowerCase() && ele.country == e.value) {
          return ele;
        }
      });
      setAllBatch(filterarray);
    }
  };
  // const [value, setValue] = useState(null);
  const clear = () => {
    getState(null);
    getDistrict(null);
    getCity(null);
    setTechnologyOff(null);
    setTechnologyOn(null);
    setLanguageOn(null);
    setLanguageOff(null);
  };
  return (
    <div className="filterpage_skillset">
      <div>
        <HeaderNavbar />
        
        <div className="filterpage_inner-wrapper">
          {/* <Col lg={3} md={4}>
            <div style={{ paddingTop: "90px" }} className="skillset_filter">
              <div className="filter-container ">
                <h2 className="title">Advanced Search</h2>
                <div className="filters">
                  <div
                    className="text-end clearFilter"
                    onClick={() => {
                      getCountry();
                      getAllBatches();
                      getTechnology();
                      getLanguage();
                      getcareerblitzLogo();
                      getAllCourses();
                      clear();
                    }}
                  >
                  
                    <AiOutlineClose />
                    <span>Clear Filter</span>
                    
                  </div>

                  <div className="form-group">
                    <h6>Type</h6>
                    <Radio.Group value={value} onChange={onChange}>
                      <Radio value={"Online"}>Online</Radio>
                      <Radio value={"Offline"}>Offline</Radio>
                    </Radio.Group>
                  </div>
                  {(selectedType === "Offline" || value === null) && (
                    <>
                      <div className="form-group">
                        <h6>Country</h6>

                        <Dropdown
                          filter
                          className="selectMenu"
                          value={countryValue}
                          options={countryList}
                          placeholder="Select Country"
                          optionLabel={"name"}
                          optionValue={"id"}
                          onChange={(e) => {
                            getState(e.value);
                            const countryData = countryList.filter(
                              (value) => value.id === e.value
                            );
                            dispatch(filterbySearch(countryData[0].name));

                          
                            console.log(countryData, "countryData");
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <h6>State</h6>
                        <Dropdown
                          filter
                          className="selectMenu"
                          placeholder="Select State"
                          value={stateValue}
                          options={stateList}
                          optionLabel={"name"}
                          optionValue={"id"}
                          onChange={(e) => {
                            getDistrict(e.value);
                            const stateData = stateList.filter(
                              (value) => value.id === e.value
                            );
                            dispatch(filterSearchbyState(stateData[0].name));
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <h6>District</h6>
                        <Dropdown
                          filter
                          className="selectMenu"
                          value={districtValue}
                          options={districtList}
                          placeholder="Select District"
                          optionLabel={"name"}
                          optionValue={"id"}
                          onChange={(e) => {
                            console.log("e.value", e.value);
                            getCity(e.value);
                            const districtData = districtList.filter(
                              (value) => value.id === e.value
                            );

                            dispatch(
                              filterSearchbyDistrict(districtData[0].name)
                            );
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <h6>City</h6>

                       
                        <Dropdown
                          filter
                          value={cityvalue}
                          options={cityList}
                          className="selectMenu"
                          placeholder="Select City"
                          optionLabel={"cityName"}
                          optionValue={"_id"}
                          onChange={(e) => {
                            console.log("e", e);
                            setCityValue(e.value);

                            const cityData = cityList.filter(
                              (value) => value.id === e.value
                            );
                            dispatch(filterSearchbyCity(cityData[0].name));
                            console.log(
                              "districtData[0].name",
                              cityData[0].name
                            );
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <h6>Technology</h6>
                        <Dropdown
                          filter
                          className="selectMenu"
                          value={technologyOff}
                          options={techList}
                          placeholder="Select Technology"
                          optionLabel={"label"}
                          optionValue={"value"}
                          onChange={(e) => {
                            setTechnologyOff(e.value);
                            dispatch(filterSearchbyTechnologyOff(e.value));
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <h6>Language</h6>
                        <Dropdown
                          filter
                          className="selectMenu"
                          value={languageOff}
                          options={language}
                          placeholder="Select Language"
                          optionLabel={"label"}
                          optionValue={"value"}
                          onChange={(e) => {
                            setLanguageOff(e.value);
                            dispatch(filterSearchbyLanguageOff(e.value));
                          }}
                        />
                      </div>
                    </>
                  )}
         
                  {selectedType === "Online" && (
                    <>
                      {" "}
                      <div className="form-group">
                        <h6>Technology</h6>
                        <Dropdown
                          filter
                          className="selectMenu"
                          value={technologyOn}
                          options={techList}
                          placeholder="Select Technology"
                          optionLabel={"label"}
                          optionValue={"value"}
                          onChange={(e) => (
                            setTechnologyOn(e.value),
                            dispatch(filterSearchbyTechnologyOn(e.value))
                          )}
                        />
                      </div>
                      <div className="form-group">
                        <h6>Language</h6>
                        <Dropdown
                          filter
                          className="selectMenu"
                          value={languageOn}
                          options={language}
                          placeholder="Select Language"
                          optionLabel={"label"}
                          optionValue={"value"}
                          onChange={(e) => (
                            setLanguageOn(e.value),
                            dispatch(filterSearchbyLanguageOn(e.value))
                          )}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col> */}

          <Col
          // md={8} lg={9}
          >
            <div className="allTemplePage__rightSection">
              <Container>
                <div className="d-flex me-3 justify-content-end">
              
                  <div className="filterpage--searchBar">
                    <InputGroup className="mb-3 searchwrapper">
                      <Form.Control
                        placeholder="Search Your Courses"
                        aria-describedby="SearchCourses"
                        className="filterpage--search-form"
                        onChange={handleSearch}/>
                      <InputGroup.Text id="SearchCourse" className="searchicon">
                        <BsSearch />
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                </div>
                {/* <RightSection /> */}
              </Container>
            </div>
            {searchText != null ? (
              <Row className="service_cards mt-4 mb-5">
                {search?.map((batch) => (
                  <Col
                    sm={12}
                    md={4}
                    className="service_card mt-5"
                    onClick={() =>
                      navigate(`/skilltest/filterpage/${batch._id}`)
                    }
                  >
                    <article>
                      <h2>{batch.course}</h2>
                      <div className="title">{batch.courseMode}</div>
                      <div className="pic">
                        <img
                          src={
                            batch.userId
                              ? `${process.env.REACT_APP_DEV_BASE_URL}${batch.companyLogo}`
                              : careerblitzImage
                          }
                          className="card_inside"
                        />
                      </div>

                      <div className="desc_technology mt-2 ">
                        <b>{batch.frameWork}</b>
                      </div>
                    </article>
                  </Col>
                ))}
              </Row>
            ) : (
              <Row className="service_cards mt-4 mb-5">
                {allBatch?.map((batch) => (
                  <Col
                    sm={12}
                    md={4}
                    className="service_card mt-5"
                    onClick={() =>
                      navigate(`/skilltest/filterpage/${batch._id}`)
                    }
                  >
                    <article>
                      {console.log(batch, "batch")}
                      <h2>{batch.course}</h2>
                      <div className="title">{batch.courseMode}</div>
                      <div className="pic">
                        <img
                          src={
                            batch.userId
                              ? `${process.env.REACT_APP_DEV_BASE_URL}${batch.companyLogo}`
                              : careerblitzImage
                          }
                          className="card_inside"
                        />
                      </div>

                      <div className="desc_technology mt-2 ">
                        <b>{batch.frameWork}</b>
                      </div>
                    </article>
                  </Col>
                ))}
              </Row>
            )}

            {(search?.length === undefined || search?.length === 0) &&
              allBatch?.length === 0 && (
                <div className="no_courses">
                  <p>No courses to show</p>
                </div>
              )}
          </Col>
        </div>
      </div>
     
    </div>
  );
}

export default FilterPage;
