import React, { useEffect, useState, useRef, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './StatsCounter.css';
import AOS from 'aos'; 
import 'aos/dist/aos.css'; 
import { ThemeContext } from './ThemeContext';

const StatsCounter = () => {
  const [courses, setCourses] = useState(0);
  const [jobListings, setJobListings] = useState(0);
  const [users, setUsers] = useState(0);
  const [employers, setEmployers] = useState(0);
  const [hasAnimated, setHasAnimated] = useState(false);
  const statsRef = useRef(null);

  useEffect(() => {
    AOS.init({
      duration: 1000, 
      once: false, 
    });
  }, []);

  const { theme } = useContext(ThemeContext); 

  const animateNumbers = (setValue, target, duration) => {
    let start = 0;
    const stepTime = Math.max(duration / target, 50);
    const increment = Math.ceil(target / (duration / stepTime));

    const timer = setInterval(() => {
      start += increment;
      if (start >= target) {
        setValue(target);
        clearInterval(timer);
      } else {
        setValue(start);
      }
    }, stepTime);
  };

  const handleIntersection = (entries) => {
    const entry = entries[0];
    if (entry.isIntersecting && !hasAnimated) {
      animateNumbers(setCourses, 5000, 4000);
      animateNumbers(setJobListings, 1200, 4000);
      animateNumbers(setUsers, 30000, 4000);
      animateNumbers(setEmployers, 5000, 4000);
      setHasAnimated(true);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => {
      if (statsRef.current) {
        observer.unobserve(statsRef.current);
      }
    };
  }, [hasAnimated]);

  return (
    <Container fluid ref={statsRef} className="my-5 stats-counter">
      <Row>
        <Col xs={12} md={3} className="text-center" data-aos="flip-left">
          <h2>{courses.toLocaleString()} +</h2>
          <h4>Available Courses</h4>
          <p className={theme.statsCounterText}>Explore a wide range of educational courses tailored to enhance your skills.</p>
        </Col>
        <Col xs={12} md={3} className="text-center" data-aos="flip-up">
          <h2>{jobListings.toLocaleString()} +</h2>
          <h4>Job Listings</h4>
          <p className={theme.statsCounterText}>Find numerous job opportunities that match your skills and interests.</p>
        </Col>
        <Col xs={12} md={3} className="text-center" data-aos="flip-down">
          <h2>{users.toLocaleString()} +</h2>
          <h4>Registered Users</h4>
          <p className={theme.statsCounterText}>Join our vibrant community of learners and professionals.</p>
        </Col>
        <Col xs={12} md={3} className="text-center" data-aos="flip-right">
          <h2>{employers.toLocaleString()} +</h2>
          <h4>Employers</h4>
          <p className={theme.statsCounterText}>Connect with top employers seeking skilled professionals.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default StatsCounter;
