import React, { useState, useEffect } from "react";
import { Row, Col, Card, Divider, message } from "antd"; // 'message' for feedback
import { useParams, useNavigate } from "react-router-dom";
import Api from "../../../Api";
import "./Learning.scss";
import _ from "lodash"; // Import lodash library
import { CalendarOutlined } from "@ant-design/icons";
import "aos/dist/aos.css";
import img from "../../../Images/card1.jpg";
import AOS from "aos";
import { FaCalendarDay } from "react-icons/fa";
import { IoMdTime } from "react-icons/io";
import { IoBookOutline } from "react-icons/io5";
import { GiTechnoHeart } from "react-icons/gi";
import { Tab, Tabs } from "react-bootstrap";
import InstructorDetails from "./Course Landing content/InstructorDetails";
import CourseCurriculum from "./Course Landing content/Curriculam";
import WhatYoullLearn from "./Course Landing content/WhatYoullLearn";

const CourseLandingPage = () => {
  const { id } = useParams(); // Getting course ID from URL
  const navigate = useNavigate(); // For redirecting after applying

  const [activeTab, setActiveTab] = useState("overview");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasApplied, setHasApplied] = useState(false); // State to track if student has applied

  const studentId = localStorage.getItem("userId"); // Fetch the logged-in student ID

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      delay: 300,
    });
  }, []);

  // Fetch course details and check if the student has already applied
  useEffect(() => {
    const fetchCourseById = async () => {
      try {
        const response = await Api.get(`/courses/getcourseById/${id}`);
        console.log("Course data:", response.data);
        setCourse(response.data);

        // Check if the student has already applied
        const appliedResponse = await Api.get(
          `signup/student_getone/${studentId}`
        );
        const studentData = appliedResponse.data.data;

        // Check if the student has applied to this course
        const applied = studentData.appliedCourses.some(
          (course) => course.courseId === id
        );
        setHasApplied(applied);
      } catch (error) {
        console.error(
          "Error fetching course by ID or checking application status:",
          error
        );
      }
    };
    fetchCourseById();
  }, [id, studentId]);

  if (!course) {
    return <div>Loading...</div>;
  }

  // Ensure whatYoullLearn is an array
  const parsedWhatYoullLearn = Array.isArray(course.whatYoullLearn)
    ? course.whatYoullLearn
    : [];

  // Handling technologies field with enhanced checks
  let parsedTechnologies = [];
  try {
    const technologiesString = course.technologies?.[0];
    if (Array.isArray(technologiesString)) {
      parsedTechnologies = technologiesString;
    } else if (typeof technologiesString === "string") {
      parsedTechnologies = technologiesString.startsWith("[")
        ? JSON.parse(technologiesString)
        : [technologiesString];
    }
  } catch (error) {
    console.error("Error parsing technologies:", error);
  }
  const formattedTechnologies = parsedTechnologies.join(", ");

  // Splitting whatYou'llLearn into chunks for better display
  const whatYoullLearnChunks = _.chunk(
    parsedWhatYoullLearn,
    Math.ceil(parsedWhatYoullLearn.length / 2)
  );

  // Parsing classTime and classDays to handle both strings and arrays
  const parsedClassTimes = Array.isArray(course.classTime)
    ? course.classTime.map((time) => time.trim()) // Handle as array
    : (course.classTime || "").split(",").map((time) => time.trim()); // Handle as comma-separated string

  const parsedClassDays = Array.isArray(course.classDays)
    ? course.classDays.map((day) => day.trim()) // Handle as array
    : (course.classDays || "").split(",").map((day) => day.trim()); // Handle as comma-separated string

  // Function to format date strings
  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle undefined or empty date string
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Function to handle course application
  const handleApply = async () => {
    setLoading(true);
    try {
      await Api.put("/exam/applycourse", {
        courseId: id, // The course ID from the URL
        studentId: studentId, // The student ID from local storage
      });
      message.success("You have successfully applied to the course!");
      setHasApplied(true); // Update state to show that the student has applied
    } catch (error) {
      message.error("Error applying to the course. Please try again.");
      console.error("Error applying for course:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="filterpage_skillset">
      <div className="col-12">
        <img src={img} alt="img" style={{ height: "60vh", width: "100vw" }} />
      </div>
      <div className="row  course-details">
        <div className="col-12 col-sm-9 col-md-5 col-lg-4 col-xl-4 col-xxl-4 apply-card">
          <img
            className="img-course"
            src={`${process.env.REACT_APP_DEV_BASE_URL}${course.image}`}
            alt={course.title}
          />
          <div className="course-details">
            <h1 className="course-title-card">{course.title}</h1>

            <p className="course-date">
              <CalendarOutlined className="date-icon" /> <b>Start Date:</b>{" "}
              {formatDate(course.startDate)}
            </p>
            <p className="course-date">
              <CalendarOutlined className="date-icon" /> <b>End Date:</b>{" "}
              {formatDate(course.endDate)}
            </p>

            <p className="course-tech">
              <b>Technologies:</b> {formattedTechnologies}
            </p>
            <p className="course-instructor">
              <b>Created By:</b> <span>{course.instructor}</span>
            </p>

            <div className="class-days">
              <h4 className="section-title">
                <CalendarOutlined /> <span>Class Days</span>
              </h4>
              <ul className="days-list">
                {parsedClassDays.map((day, index) => (
                  <li key={index} className="day-item">
                    <CalendarOutlined />
                    <p className="day-name">{day}</p>
                  </li>
                ))}
              </ul>
            </div>

            <div className="class-times">
              <h4 className="section-title">
                <CalendarOutlined /> <span>Class Times</span>
              </h4>
              <ul className="times-list">
                {parsedClassTimes.length === 2 ? (
                  <li className="time-item">
                    {parsedClassTimes[0]} <strong>to</strong>{" "}
                    {parsedClassTimes[1]}
                  </li>
                ) : (
                  parsedClassTimes.map((time, index) => (
                    <li key={index} className="time-item">
                      {time}
                    </li>
                  ))
                )}
              </ul>
            </div>

            <div className="apply-button-wrapper">
              <button
                className="landing-apply-btn"
                onClick={handleApply}
                disabled={loading || hasApplied}
              >
                {loading
                  ? "Applying..."
                  : hasApplied
                  ? "Already Applied"
                  : "Apply"}
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-7 col-xl-7 col-xxl-7 course-detail-container">
          <div className="tab-titles">
            <div
              className={`tab-title ${
                activeTab === "overview" ? "active" : ""
              }`}
              onClick={() => handleTabClick("overview")}
            >
              Overview
            </div>
            <div
              className={`tab-title ${
                activeTab === "curriculum" ? "active" : ""
              }`}
              onClick={() => handleTabClick("curriculum")}
            >
              Curriculum
            </div>
            <div
              className={`tab-title ${
                activeTab === "instructor" ? "active" : ""
              }`}
              onClick={() => handleTabClick("instructor")}
            >
              Instructor
            </div>
            {/* <div 
          className={`tab-title ${activeTab === 'reviews' ? 'active' : ''}`} 
          onClick={() => handleTabClick('reviews')}
        >
          Reviews
        </div> */}
            <div
              className={`tab-title ${
                activeTab === "what-you-learn" ? "active" : ""
              }`}
              onClick={() => handleTabClick("what-you-learn")}
            >
              What You Learn
            </div>
          </div>
          <div className="tab-contents">
            {activeTab === "overview" && <div> Overview Tab</div>}
            {activeTab === "curriculum" && (
              <div>
                <CourseCurriculum />
              </div>
            )}
            {activeTab === "instructor" && (
              <div>
                <InstructorDetails />
              </div>
            )}
            {activeTab === "reviews" && <div>Content for Reviews Tab</div>}
            {activeTab === "what-you-learn" && (
              <div>
                <WhatYoullLearn />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseLandingPage;
