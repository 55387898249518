import React, { useEffect, useState } from "react";
import { Table, Button, Input, Popconfirm, Modal } from "antd";
import "./Technology.scss";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";
// import DataListTable from "../datalistTable";

function TechnologyList() {
  const [techList, setTechList] = useState([]);
  const [dailogVal, setDailogVal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getData, setGetData] = useState();
  const [search, setSearch] = useState();
  console.log(getData, "ghjhjhgjh");

  useEffect(() => {
    getPattern();
    
  }, []);

  const getPattern = async () => {
    await Api.get("Technology/get").then((res) => {
      console.log(res.data, "////");
      setTechList(res.data);
    });
  };

  const deleteTechnology = (id) => {
    Api.delete(`Technology/delete/${id}`).then((resp) => {
      getPattern();
    });
  };
  const update = (ele) => {
    console.log(ele, "jjj");
    navigate("create", { state: { ele } });
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    const Technologys = e.target.value;
    console.log(typeof Technologys);

    const newData = techList.filter((list) =>
      list.Technology.toLowerCase().includes(Technologys.toLowerCase())
    );
    console.log(newData, "//");
    setGetData(newData);
  };
  console.log(search, "get");

  // const handleModalprops = (id) => {
  //   const filterdVal = techList.filter((ele) => {
  //     return ele._id === id;
  //   });
  //   setDailogVal(filterdVal[0]);
  //   return;
  // };

  const columns = [
    {
      title: <span style={{ fontSize: "18px", fontWeight:"bold" }}>Technology</span>,
      dataIndex: "Technology",
      align: "center",
    },
    {
      title: <span style={{ fontSize: "18px", fontWeight:"bold" }}>Created At</span>,
      align: "center",
      dataIndex: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (text, record) =>
        new Date(record.createdAt).toLocaleDateString("en-GB"),
    },
    {
      title: <span style={{ fontSize: "18px", fontWeight:"bold" }}>Action</span>,
      align: "center",
      dataIndex: "action",
      render: (_, record) => (
        <div>
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              update(record);
            }}
          />

          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => {
              deleteTechnology(record.id);
            }}
          >
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ paddingLeft: "10px", cursor: "pointer" }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  // const { Search } = Input;

  const onSearch = "";
  const navigate = useNavigate();
  return (
    <div>
      <div className="data-list-main">
        <h4 className="data-list-header">Technology List</h4>
        <div
          className="data-list-top mt-4 py-4 px-3"
          style={{ backgroundColor: "#c5c5c5" }}
        >
          <input
            className="data-list-search ps-2"
            placeholder="search"
            columns={columns}
            // dataList={techList}
            value={search}
            onChange={handleSearch}
            // enterButton
          />

          <Button
            className="data-list-button"
            onClick={() => navigate("create")}
          >
            <BsFillPersonPlusFill />
            &nbsp;Add New
          </Button>
        </div>
        <div className=".Datalist-Table">
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={getData ? getData : techList}
            pagination={true}
            className="mt-2"
          />
        </div>
      </div>
    </div>
  );
}

export default TechnologyList;
