import React, { useEffect, useState } from "react";
import { Table, Button, Input, Popconfirm, Modal } from "antd";
import "../../../Components/Css/Table.scss";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import DataListTable from "../datalistTable";
import { Collapse } from "antd";
const { Panel } = Collapse;

function QuestionBankQuestion() {
  const [techList, setTechList] = useState(null);
  const [dailogVal, setDailogVal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const[getData , setGetData]=useState([{week:"1"},{week:"2"}]);
  const [getData, setGetData] = useState([]);

  const [search, setSearch] = useState();

  const location = useLocation();

  const question = location.state && location.state.record.question;
  // console.log('first##',  location.record.question)

  //

  //  const id =location.state&&location.state.record.id._id

  // useEffect(() => {
  //   if(location.state)   { getQuestion();}
  // }, []);

  // const getQuestion = async () => {
  //   console.log('object :>> ', id);
  //   await Api.get(`QuestionBank/getid/${id}`)
  //   .then((res) => {
  //     console.log(res.data.totalWeeks,"////")
  //       setTechList(res.data);
  //   });
  // };

  // const update=(ele)=>{
  //   console.log(ele,"jjj")
  //   navigate("create",{ state: {  ele } })

  // }

  const deleteQuestionBank = (id) => {
    console.log(id, "asdf");
    Api.delete(`QuestionBank/delete/${id}`).then((resp) => {
      setGetData();
    });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const totalWeeks = e.target.value;
    console.log(typeof totalWeeks);

    const newData = techList.question.filter((list) =>
      list.totalWeeks.question.quest
        .toLowerCase()
        .includes(totalWeeks.toLowerCase())
    );

    setGetData(newData);
  };
  // console.log(search,"get")

  // const handleModalprops = (id) => {
  //   const filterdVal = techList.filter((ele) => {
  //     return ele._id === id;
  //   });
  //   setDailogVal(filterdVal[0]);
  //   return;
  // };

  const columns = [
    {
      title: "Question",
      dataIndex: "quest",
    },
    // {
    //   title: "Question",
    //   dataIndex:"choice",
    // },
    {
      title: "view",
      dataIndex: "action",
      render: (_, record) => (
        <div>
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ cursor: "pointer" }}
            // onClick={(e) => {
            //   navigate("qcreate", { state: { record } });
            // }}
          />
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => {
              deleteQuestionBank(record.id);
            }}
          >
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ paddingLeft: "10px", cursor: "pointer" }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const navigate = useNavigate();
  return (
    <div>
      <div className="data_list_main">
        <h4 className="data_list_header">Question </h4>
        <div className="data_list_top mt-4 py-4 px-3">
          <input
            className="data_list_search ps-2"
            placeholder="search"
            columns={columns}
            // dataList={techList}
            value={search}
            onChange={handleSearch}
            // enterButton
          />

          <Button
            className="data_list_button"
            onClick={() => navigate("qcreate")}
          >
            <BsFillPersonPlusFill />
            &nbsp;Add New
          </Button>
        </div>
        <div className="data_list_table">
          <Table
            columns={columns}
            dataSource={question}
            pagination={true}
            className="mt-2"
          />
        </div>
      </div>
    </div>
  );
}

export default QuestionBankQuestion;
