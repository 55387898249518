import React, { useState } from "react";
import { Layout } from "antd";
import Sidebar from "./Sidebar";
import "../../../Components/Css/Sidebar.scss";
import { Link, Outlet, useNavigate } from "react-router-dom";
import "antd/dist/antd.css";
import { AiOutlineClose } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import careerblitzIcon from "../../../Images/Careerblitz logo.png";
import { HiUserCircle } from "react-icons/hi";
import { IoMdArrowDropdown } from "react-icons/io";
import {Card} from "react-bootstrap";
import './NewNavbar.css';
import CustomNavbar from "./CustomNavbar";

const { Content } = Layout;

function DefaultLayout() {
  const [collapsed, setCollapsed] = useState(true);

  const handleChange = () => {
    if (window.innerWidth < 720) {
      setCollapsed(!collapsed);
    } else {
      setCollapsed(collapsed);
    }
  };
  const navigate = useNavigate();
  const [logout, setLogout] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("resize", handleChange);
    if (!localStorage.getItem("USER_AUTH_STATE")) navigate("/login");
  }, [logout]);
  const logoutHandler = (e) => {
    e.preventDefault();
    localStorage.removeItem("USER_AUTH_STATE");
    localStorage.removeItem("role");
    localStorage.removeItem("id");
    localStorage.removeItem("name");
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("regid");
    localStorage.removeItem("userId");
    localStorage.removeItem("companyName");

    setLogout(true);
  };

  return (
    <div >
      <div onChange={handleChange}>
        <div className="dashboards__headerNavs">
          <div className="dashboards__headerNavs--container">
            <div>
              {/* <span
                className="navMenu p-2"
                onClick={() => setCollapsed((prev) => !prev)}
              >
                {collapsed ? <AiOutlineClose /> : <GiHamburgerMenu />}
              </span> */}
              <img style={{width:"158px",height:"100px"}}
                className="careerblitz_icon"
                src={careerblitzIcon}
                alt="add"
                onClick={() => navigate("/")}
              />
             
              <span className="logo" onClick={() => navigate("/")}></span>
            </div>
            {/* <div>     
              <nav className="new-navbar">
      <Link to="profile">
    
    My Profile
  
      </Link>
      <Link to="areaInterest">
    
    Area of Interest
  
      </Link> */}
      {/* <div className="new-dropdown">
        <button className="new-dropbtn">Training</button>
        <div className="new-dropdown-content">
          <Link to="#">Curriculam</Link>
          <Link to="learning">Learning</Link>
        </div>
      </div> */}
      {/* <div className="new-dropdown">
        <button className="new-dropbtn">Services<IoMdArrowDropdown /></button>
        <div className="new-dropdown-content">
          <Link to="/service1">Service 1</Link>
          <Link to="/service2">Service 2</Link>
          <Link to="/service3">Service 3</Link>
        </div>
      </div>
      <Link to="/about">About</Link>
      <Link to="/contact">Contact</Link>
    </nav>
              </div> */}
              <div>
                {/* <CustomNavbar/> */}
              </div>
            <div className="logoutAvatar" onClick={logoutHandler}>
              <HiUserCircle
                style={{ fontSize: 40, cursor: "pointer", color: "grey" }}
              />
            </div>
          </div>
        </div>
      </div>
      <Sidebar collapsed={collapsed} />
      <div
        className={collapsed === true ? "main-content open" : "main-content"}
      >
        <Outlet />
      </div>
    </div>
  );
}

export default DefaultLayout;
