import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import Card from "react-bootstrap/Card";
import Api from "./../../../Api";
import { useLocation } from "react-router-dom";

const ViewApplicants = () => {
  const userId = localStorage.getItem("id");
  const [viewApplicantsLits, setViewApplicantsLits] = useState([]);
  const state = useLocation();
  const jobTitles = state.state.jobTitle;

  const normalizedFilteredJobTitle = jobTitles.trim().toLowerCase();
  const filteredData = viewApplicantsLits.filter(
    (item) => item.jobTitle.trim().toLowerCase() === normalizedFilteredJobTitle
  );

  useEffect(() => {
    getViewApplicantsLists();
  }, []);
  const getViewApplicantsLists = async () => {
    await Api.get(`/createForm/Approvedgetoneuser/${userId}`).then((res) => {
      setViewApplicantsLits(res.data.data);
    });
  };
  return (
    <div>
      <Row className="p-3">
        {filteredData.map((data, i) => (
          <Col sm={24} md={24} lg={12} className="p-3">
            <Card>
              <Card.Body>
                <h4>Job List </h4>
                <p className="Int-para2">
                  <b>Job Title</b> {data.jobTitle}
                </p>
                <p className="Int-para2">
                  <b>Roll:</b> {data.role}
                </p>
                <p className="Int-para2">
                  <b>Email id:</b> {data.email}
                </p>{" "}
                <p className="Int-para2">
                  <b>Experience:</b> {data.experience}
                </p>
                <p className="Int-para2">
                  <b>location:</b> {data.location}
                </p>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ViewApplicants;
