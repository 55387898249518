import React, { useState, useEffect } from "react";
import {
  Layout,
  Badge,
  Dropdown,
  Menu,
  Button,
  Space,
  Modal,
  Typography,
  Card,
} from "antd";
import Sidebar from "./Sidebar.js";
import "../../../Components/Css/Sidebar.scss";
import { useNavigate, Outlet } from "react-router-dom";
import "antd/dist/antd.css";
import { AiOutlineClose } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import careerblitzIcon from "../../../Images/Careerblitz logo.png";
import { HiUserCircle } from "react-icons/hi";
import { IoIosNotifications } from "react-icons/io";
import Image from "react-bootstrap/Image";
import Api from "../../../Api.js";
import { Col, Container, Form, Row } from "react-bootstrap";
import { RxCrossCircled } from "react-icons/rx";
import { MdOutlineOfflinePin } from "react-icons/md";
import { GiTeacher } from "react-icons/gi";

const { Content } = Layout;
const { Text, Title } = Typography;

function Dashboard() {
  const [collapsed, setCollapsed] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [trainerId, setTrainerId] = useState(localStorage.getItem("userId"));
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const handleChange = () => {
    if (window.innerWidth < 720) {
      setCollapsed(!collapsed);
    } else {
      setCollapsed(collapsed);
    }
  };

  const navigate = useNavigate();
  const UserName = localStorage.getItem("name");
  const [logout, setLogout] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", handleChange);
    if (!localStorage.getItem("USER_AUTH_STATE")) navigate("/login");

    const fetchNotifications = async () => {
      try {
        const response = await Api.get(
          `/notification/courseNotifications/${trainerId}`
        );
        setNotifications(response.data);
        console.log(response.data);
        setNotificationCount(response.data.length);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [logout, trainerId]);

  const logoutHandler = (e) => {
    e.preventDefault();
    localStorage.clear();
    setLogout(true);
  };

  const handleAccept = (notificationId) => {
    // Handle accept action (e.g., update notification status in the backend)
    console.log("Accepted notification ID:", notificationId);
    setModalVisible(false);
  };

  const handleReject = (notificationId) => {
    // Handle reject action (e.g., update notification status in the backend)
    console.log("Rejected notification ID:", notificationId);
    setModalVisible(false);
  };

  const handleNotificationClick = (notification) => {
    // Extract course title from the message
    const courseTitle = notification.message
      .split(":")[1]
      ?.trim()
      .split("Course Details:")[0]
      ?.trim();

    // Extract course details
    const courseDetails = notification.message
      .split("Course Details:")[1]
      ?.trim();
    const detailsArray = courseDetails
      .split(" - ")
      .map((detail) => detail.trim());
    const courseInfo = {};

    detailsArray.forEach((detail) => {
      const [key, value] = detail.split(": ");
      if (key && value) {
        courseInfo[key.toLowerCase().replace(/\s+/g, "_")] = value.replace(
          /["[\]]/g,
          ""
        );
      }
    });

    setSelectedNotification({ ...notification, courseTitle, courseInfo });
    setModalVisible(true);
  };

  const notificationMenu = (
    <Menu>
      {notifications.length > 0 ? (
        notifications.map((notif) => {
          const courseTitle = notif.message
            .split(":")[1]
            ?.trim()
            .split("Course Details:")[0]
            ?.trim();
          return (
            <Menu.Item key={notif._id}>
              <Card
                hoverable
                style={{
                  borderRadius: "10px",
                  boxShadow: "3px 4px 0px 0px rgba(0, 0, 0, 0.5)",
                  marginBottom: "10px",
                  backgroundColor: " rgb(244, 244, 246)",
                  fontFamily: "inherit",
                  border: "1px solid #10101",
                  width: "70vh",
                  height: "11vh",
                  borderColor: "#1890ff",
                }}
                onClick={() => handleNotificationClick(notif)}
              >
                <Row>
                  <Col lg={1}>
                    {" "}
                    <GiTeacher
                      style={{ color: "#1890ff", fontSize: "3vh" }}
                    />{" "}
                  </Col>
                  <Col lg={11}>
                    <Title
                      level={5}
                      style={{
                        margin: 0,
                        fontFamily: "inherit",
                        fontSize: "2vh",
                        fontWeight: "400",
                      }}
                    >
                      {courseTitle}
                    </Title>
                  </Col>
                </Row>
              </Card>
            </Menu.Item>
          );
        })
      ) : (
        <Menu.Item>No notifications</Menu.Item>
      )}
    </Menu>
  );

  return (
    <div>
      <div onChange={handleChange}>
        <div className="dashboards__headerNavs">
          <div className="dashboards__headerNavs--container">
            <div>
              <span
                className="navMenu p-2"
                onClick={() => setCollapsed((prev) => !prev)}
              >
                {collapsed ? <AiOutlineClose /> : <GiHamburgerMenu />}
              </span>
              <img
                className="careerblitz_icon"
                src={careerblitzIcon}
                alt="logo"
                onClick={() => navigate("/")}
              />
              {/* <span className="navName" onClick={() => navigate("/")}>
                    {UserName}
                  </span> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Dropdown
                overlay={notificationMenu}
                trigger={["click"]}
                overlayClassName="notification-dropdown"
              >
                <Badge count={notificationCount} offset={[10, 0]}>
                  <IoIosNotifications
                    style={{
                      color: "#1890ff",
                      fontSize: "35",
                      cursor: "pointer",
                    }}
                  />
                </Badge>
              </Dropdown>
              &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
              <div className="logoutAvatar" onClick={logoutHandler}>
                <HiUserCircle
                  style={{ fontSize: 45, cursor: "pointer", color: "grey" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sidebar collapsed={collapsed} />
      <div
        className={collapsed === true ? "main-content open" : "main-content"}
      >
        <Outlet />
      </div>

      {selectedNotification && (
        <Modal
          visible={modalVisible}
          title={
            <Title
              level={4}
              style={{
                textAlign: "center",
                marginTop: "3vh",
                fontWeight: "700",
              }}
            >
              {" "}
              Overview About Course Details
            </Title>
          }
          onCancel={() => setModalVisible(false)}
          footer={[
            <Button
              key="reject"
              danger
              onClick={() => handleReject(selectedNotification._id)}
              style={{ margin: "3vh 3vh 3vh auto" }}
            >
              <p style={{ fontSize: "2vh" }}>
                {" "}
                <RxCrossCircled style={{ marginBottom: "2px" }} /> Reject
              </p>
            </Button>,
            <Button
              key="accept"
              size="lg"
              type="primary"
              onClick={() => handleAccept(selectedNotification._id)}
              style={{ margin: "3vh 3vh 3vh auto" }}
            >
              <p style={{ fontSize: "2vh" }}>
                {" "}
                <MdOutlineOfflinePin style={{ marginBottom: "2px" }} /> Accept
              </p>
            </Button>,
          ]}
        >
          <Image
            src="https://img.freepik.com/free-vector/qualification-increase-course-skills-improvement-coaching_335657-3310.jpg?size=626&ext=jpg"
            style={{ height: "25vh", marginLeft: "12vh", width: "40vh" }}
            alt="image-image"
            rounded
          />
          <p></p>

          <div style={{ marginLeft: "3vh", marginTop: "4vh" }}>
            <div style={{ height: "10vh" }}>
              <Row>
                <Col lg={6}>
                  <p>
                    <Text strong>Course Title:</Text>
                  </p>
                </Col>
                <Col lg={6}>
                  <p>{selectedNotification.courseTitle}</p>
                </Col>
              </Row>
            </div>

            <div style={{ height: "5vh" }}>
              <Row>
                <Col lg={6}>
                  <p>
                    <Text strong>Description:</Text>
                  </p>
                </Col>
                <Col lg={6}>{selectedNotification.courseInfo?.Description}</Col>
              </Row>
            </div>

            <div style={{ height: "5vh" }}>
              <Row>
                <Col lg={6}>
                  <p>
                    <Text strong>Duration:</Text>
                  </p>
                </Col>
                <Col lg={6}>
                  <p>{selectedNotification.courseInfo?.duration}</p>
                </Col>
              </Row>
            </div>

            <div style={{ height: "5vh" }}>
              <Row>
                <Col lg={6}>
                  <p>
                    <Text strong>Start Date</Text>
                  </p>
                </Col>
                <Col lg={6}>
                  <p>{selectedNotification.courseInfo?.start_date}</p>
                </Col>
              </Row>
            </div>

            <div style={{ height: "5vh" }}>
              <Row>
                <Col lg={6}>
                  <p>
                    <Text strong>End Date:</Text>
                  </p>
                </Col>
                <Col lg={6}>
                  <p>{selectedNotification.courseInfo?.end_date}</p>
                </Col>
              </Row>
            </div>

            <div style={{ height: "7vh" }}>
              <Row>
                <Col lg={6}>
                  <p>
                    <Text strong>Class Days:</Text>
                  </p>
                </Col>
                <Col lg={6}>
                  <p>{selectedNotification.courseInfo?.class_days}</p>
                </Col>
              </Row>
            </div>

            <div style={{ height: "5vh" }}>
              <Row>
                <Col lg={6}>
                  <p>
                    <Text strong>Class Time:</Text>
                  </p>
                </Col>
                <Col lg={6}>
                  <p>{selectedNotification.courseInfo?.class_time}</p>
                </Col>
              </Row>
            </div>

            <div style={{ height: "5vh" }}>
              <Row>
                <Col lg={6}>
                  <p>
                    <Text strong>Mode:</Text>
                  </p>
                </Col>
                <Col lg={6}>
                  <p>{selectedNotification.courseInfo?.mode}</p>
                </Col>
              </Row>
            </div>

            <div style={{ height: "5vh" }}>
              <Row>
                <Col lg={6}>
                  <p>
                    <Text strong>Price:</Text>
                  </p>
                </Col>
                <Col lg={6}>
                  <p>{selectedNotification.courseInfo?.price}</p>
                </Col>
              </Row>
            </div>

            <div style={{ height: "5vh" }}>
              <Row>
                <Col lg={6}>
                  <p>
                    <Text strong>Course ID:</Text>
                  </p>
                </Col>
                <Col lg={6}>
                  <p>{selectedNotification._id}</p>
                </Col>
              </Row>
            </div>
          </div>
          {/*
         <Text strong>Course Title:</Text>
        <p>{selectedNotification.courseTitle}</p>

        <Text strong>Description:</Text>
        <p>{selectedNotification.courseInfo?.Description}</p>
        <Text strong>Instructor:</Text>
        <p>{selectedNotification.courseInfo?.instructor}</p>
        <Text strong>Duration:</Text>
        <p>{selectedNotification.courseInfo?.duration}</p>
        <Text strong>Start Date:</Text>
        <p>{selectedNotification.courseInfo?.start_date}</p>
        <Text strong>End Date:</Text>
        <p>{selectedNotification.courseInfo?.end_date}</p>
        <Text strong>Class Days:</Text>
        <p>{selectedNotification.courseInfo?.class_days}</p>
        <Text strong>Class Time:</Text>
        <p>{selectedNotification.courseInfo?.class_time}</p>
        <Text strong>Mode:</Text>
        <p>{selectedNotification.courseInfo?.mode}</p>
        <Text strong>Price:</Text>
        <p>{selectedNotification.courseInfo?.price}</p>
        <Text strong>Course ID:</Text>
        <p>{selectedNotification._id}</p> */}
        </Modal>
      )}
    </div>
  );
}

export default Dashboard;
