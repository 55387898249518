import React, { useState, useEffect, useCallback } from "react";
import { Typography } from "antd";
import { Button, Form, Input, Radio, Select, Checkbox, Row, Col } from "antd";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import "../../../Components/Css/Professional/professionalstyle.scss";
import { Dropdown } from "primereact";
import Api from "../../../Api";

export default function CourseInformation() {
  const [form] = Form.useForm();
  const [modeList, setModeList] = useState();
  const [tech, setTech] = useState();
  const [techList, setTechList] = useState();
  // const [value, setValue] = useState(1);
  const [textbox, setTextBox] = useState(null);
  const [courseField, setCourseField] = useState([]);
  // const [duration, setDuration] = useState()
  const [Technology, setTechnology] = useState([]);
  const { Option } = Select;
  const [trainer, setTrainerList] = useState();
  const [addForm, setAddForm] = useState([
    {
      course: "",
      CourseDuration: "",
      Fees: "",
      modeOfStudy: "",
      mode: "",
      Description: "",
      frameWork: "",
      startDate: "",
      Trainer: "",
      language: "",
      Technology: "",
      ContactNo: "",
      endDate:""
    },
  ]);

  console.log("addForm", addForm);
  // const courseAddHandler = () => {
  //   setCourseField((prev) => {});
  // };

  const {
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      gendercheck: "",
      startDate: "", // Ensure "startDate" is in defaultValues
      endDate: "",
    },
  });
console.log('firstsetValue', setValue)
  // const onSubmit = (data) => {
  //   console.log(data);
  //   handleFormSubmit();
  //   reset();
  // };
  useEffect(() => {
    getMode();
    getTech();
    getTrainer();
  }, []);

  const getTrainer = async () => {
    await Api.get("Trainer/get").then((res) => {
      setTrainerList(res.data);
    });
  };

  const getMode = async () => {
    await Api.get(`ourprofile/getByIDsssss/${id}`).then((res) => {
      setModeList(res.data.data);
    });
  };
  console.log("errors.addForm[0].course :>> ", errors && errors.addForm);
  const [mode, setMode] = useState("");

  const modeselect = [
    { label: "Online", value: "Online" },
    { label: "classroom", value: "classroom" },
  ];
  const frameWorkselect = [
    { label: "Next.js", value: "Next.js" },
    { label: "DevOps", value: "DevOps" },
  ];
  const id = localStorage.getItem("userId");
  console.log("getValues()", modeList, getValues());

  const handleFormSubmit = async () => {
    const formDataArray = addForm.map((formData) => ({
      userId: id,
      companyName: modeList[0].companyName,
      country: modeList[0].country,
      state: modeList[0].state,
      district: modeList[0].district,
      city: modeList[0].city,
      companyLogo: modeList[0].companyLogo,
      course: formData.course,
      frameWork: formData.frameWork,
      startDate: getValues().startDate,
      endDate:  getValues().endDate,
      Fees: formData.Fees,
      modeOfStudy: formData.modeOfStudy,
      courseMode: formData.mode,
      Description: formData.Description,
      Technology: getValues().Technology,
      language: formData.language,
      Trainer: formData.Trainer,
      ContactNo: formData.ContactNo,
    }));
    for (const formData of formDataArray) {
      await Api.post("traingCourse/create/course", formData).then((resp) => {
        console.log(resp, "resp");
      });
    }
  };

  // const handleFormSubmit = async () => {
  //   console.log('getValues()', modeList, getValues());
  //   const Details = {
  //     userId: id,
  //     companyName: modeList[0].companyName,
  //     country: modeList[0].country,
  //     state: modeList[0].state,
  //     district: modeList[0].district,
  //     city: modeList[0].city,
  //     companyLogo: modeList[0].companyLogo,
  //     course: getValues().addForm[0].course,
  //     frameWork: getValues().addForm[0].frameWork,
  //     startDate: getValues().startDate,
  //     endDate: getValues().endDate,
  //     // CourseDuration: getValues().addForm[0].CourseDuration,
  //     Fees: getValues().addForm[0].Fees,
  //     modeOfStudy: getValues().addForm[0].modeOfStudy,
  //     courseMode: getValues().addForm[0].mode,
  //     Description: getValues().addForm[0].Description,
  //     Technology: getValues().addForm[0].Technology,
  //     language: getValues().addForm[0].language,
  //   Trainer: getValues(). addForm[0].Trainer,
  //   ContactNo: getValues(). addForm[0].ContactNo,
  //     };
  //   await Api.post("traingCourse/create/course", Details).then((resp) => {
  //     // alert("created successfully");
  //     console.log(resp, "resp");
  //   });
  // };
  console.log("getValues", getValues());
  const handleAddQuestion = () => {
    setAddForm((prev) => [
      ...prev,
      {
        course: "",
        CourseDuration: "",
        Fees: "",
        modeOfStudy: "",
        mode: "",
        Description: "",
        startDate: "",
        Technology: "",
        endDate: "",
        language: "",
        Trainer: "",
        ContactNo: "",
      },
    ]);
  };
  const handleChangeSelect = (value) => {};
  const handleoption = useCallback((index, key, value) => {
    setAddForm((prev) => {
      const newValue = [...prev];
      newValue[index][key] = value;
      console.log("newValue :>> ", newValue);
      return newValue;
    });
  }, []);

  const handleStartDateChange = (event) => {
    const startDate = new Date(event.target.value);
    const endDate = new Date(startDate.getTime() + 90 * 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10);
    setValue("endDate", endDate);
    console.log("endDate", endDate);
  };
  const handleEndDateChange = (event) => {
    const endDate = event.target.value;
    setValue("endDate", endDate); 
  };
  

  //REMOVE OPTION
  const handleRemove = useCallback((idx, setValue) => {
    setAddForm((prev) => {
      const newOne = prev.filter((item, index) => index !== idx);
      setValue("addForm", newOne);
      return newOne;
    });
  }, []);
  const getTech = async () => {
    await Api.get("Technology/get").then((res) => {
      console.log("firstres", res);
      setTechList(res.data);
    });
  };
  return (
    <div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="pages-title mb-3 ms-3">
          <p>Course Information</p>
        </div>
        {addForm.map((item, index) => {
          return (
            <div className="personal-informations p-4">
              <Row md={16} className="mb-3">
                <Col sm={24} md={8} className=" p-2">
                  <label className="input-title">Course</label>
                  <br></br>
                  <input
                    name={`addForm[${index}].course`}
                    className="input-field"
                    // {...register("course", { required: true })}
                    value={addForm[index].course}
                    {...register(`addForm[${index}].course`, {
                      required: true,
                      onChange: (e) => {
                        handleoption(index, "course", e.target.value);
                      },
                    })}
                  />
                  <br />
                  {errors &&
                    errors.addForm &&
                    errors.addForm[index] &&
                    errors.addForm[index].course && (
                      <span className="input-error">Course is required</span>
                    )}
                </Col>
                <Col xs={24} sm={24} md={8} className="p-2">
                  <label>technology</label>
                  <br />
                  <Select
                    mode="multiple"
                    allowClear
                    name="Technology"
                    className="examSelect"
                    {...register("Technology", { required: true })}
                    // style={{ width: "100%" }}
                    placeholder="Please select a technology"
                    onChange={(e) => {
                      setTechnology(e);
                      setValue("Technology", e);
                      setTech(e);
                      handleChangeSelect();
                    }}
                  >
                    {techList?.map((list, i) => {
                      return (
                        <Option value={list?.Technology} key={i}>
                          {list?.Technology}
                        </Option>
                      );
                    })}
                  </Select>

                  {Technology.length > 1
                    ? null
                    : errors &&
                      errors.addForm &&
                      errors.addForm[index] &&
                      errors.addForm[index].Technology && (
                        <p className="text-danger">technology is required</p>
                      )}
                </Col>
                <Col sm={24} md={8} className="p-2">
                  <label
                    // style={{ marginBottom: "7px" }}
                    className="input-title"
                  >
                    Framework
                  </label>
                  <div className="status p-1">
                    <Dropdown
                      filter
                      name={`addForm[${index}].frameWork`}
                      className="input-field"
                      // value={frameWork}
                      options={frameWorkselect}
                      // {...register("frameWork", { required: true })}
                      value={addForm[index].frameWork}
                      {...register(`addForm[${index}].frameWork`, {
                        required: true,
                        onChange: (e) => {
                          handleoption(index, "frameWork", e.target.value);
                        },
                      })}
                    />
                  </div>
                  {errors &&
                    errors.addForm &&
                    errors.addForm[index] &&
                    errors.addForm[index].frameWork && (
                      <span className="input-error">FrameWork is required</span>
                    )}
                </Col>
              </Row>
              <Row>
                {/* <Col sm={24} md={8} className=" p-2">
                  <label className="input-title">Course Duration </label>
                  <input
                    className="input-field"
                    name={`addForm[${index}].CourseDuration`}
                    // {...register("CourseDuration", { required: true })}
                    value={addForm[index].CourseDuration}
                    {...register(`addForm[${index}].CourseDuration`, {
                      required: true,
                      onChange: (e) => {
                        handleoption(index, "CourseDuration", e.target.value);
                      },
                    })}
                  />
                  <br />
                  {errors &&
                    errors.addForm &&
                    errors.addForm[index] &&
                    errors.addForm[index].CourseDuration && (
                      <span className="input-error">
                        Course Duration is required
                      </span>
                    )}
                </Col> */}
                <Col sm={24} md={8} className=" p-2">
                  <label className="input-title">Fees </label>
                  <br />
                  <input
                    className="input-field"
                    name={`addForm[${index}].Fees`}
                    // {...register("Fees", { required: true })}
                    value={addForm[index].Fees}
                    {...register(`addForm[${index}].Fees`, {
                      required: true,
                      onChange: (e) => {
                        handleoption(index, "Fees", e.target.value);
                      },
                    })}
                  />
                  <br />
                  {errors &&
                    errors.addForm &&
                    errors.addForm[index] &&
                    errors.addForm[index].Fees && (
                      <span className="input-error">Fess is required</span>
                    )}
                </Col>
                <Col xs={24} sm={24} md={8} className="p-2">
                  <label className="input-title pe-5">language</label>
                  <br />
                  <select
                    className="courseSelect"
                    {...register(`addForm[${index}].language`, {
                      required: true,
                      onChange: (e) => {
                        handleoption(index, "language", e.target.value);
                      },
                    })}
                  >
                    <option value="" disabled selected>
                      Select a language
                    </option>

                    <option value="Tamil">Tamil</option>
                    <option value="Kanada">Kanada</option>
                    <option value="Hindi">Hindi</option>
                    <option value="English">English</option>
                  </select>
                  <br />
                  {errors &&
                    errors.addForm &&
                    errors.addForm[index] &&
                    errors.addForm[index].language && (
                      <span className="Blog-error">language is required</span>
                    )}
                </Col>
                <Col xs={24} sm={24} md={8} className="p-2">
                  <label className="input-title pe-5">Trainer Name</label>
                  <br />

                  <select
                    className="courseSelect"
                    {...register(`addForm[${index}].Trainer`, {
                      required: true,
                      onChange: (e) => {
                        handleoption(index, "Trainer", e.target.value);
                      },
                    })}
                  >
                    <option value="" disabled selected>
                      Select a Trainer
                    </option>
                    {trainer?.map((obj) => (
                      <option defaultValue={null} value={obj.firstName}>
                        {obj.firstName}
                      </option>
                    ))}
                  </select>
                  <br />
                  {errors &&
                    errors.addForm &&
                    errors.addForm[index] &&
                    errors.addForm[index].trainer && (
                      <span className="Blog-error">
                        Trainer name is required
                      </span>
                    )}
                </Col>
              </Row>
              <Row>
                <Col sm={24} md={8} className="p-2">
                  <label
                    style={{ marginBottom: "7px" }}
                    className="input-title"
                  >
                    Mode of Study
                  </label>
                  <div className="status p-1">
                    <Controller
                      control={control}
                      name={`addForm[${index}].modeOfStudy`}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Radio.Group {...field}>
                          <Radio value={"FullTime"}>Full Time</Radio>
                          <Radio value={"partTime"}>Part Time</Radio>
                        </Radio.Group>
                      )}
                      // {...register("modeOfStudy", { required: true })}
                      value={addForm[index].modeOfStudy}
                      {...register(`addForm[${index}].modeOfStudy`, {
                        required: true,
                        onChange: (e) => {
                          handleoption(index, "modeOfStudy", e.target.value);
                        },
                      })}
                    ></Controller>
                  </div>
                  {errors &&
                    errors.addForm &&
                    errors.addForm[index] &&
                    errors.addForm[index].modeOfStudy && (
                      <span className="input-error">
                        Mode of Study is required
                      </span>
                    )}
                </Col>
                <Col sm={24} md={8} className="p-2">
                  <label
                    // style={{ marginBottom: "7px" }}
                    className="input-title"
                  >
                    mode
                  </label>
                  <div className="status p-1">
                    <Dropdown
                      filter
                      name={`addForm[${index}].mode`}
                      className="input-field"
                      // value={mode}
                      options={modeselect}
                      // {...register("mode", { required: true })}
                      value={addForm[index].mode}
                      {...register(`addForm[${index}].mode`, {
                        required: true,
                        onChange: (e) => {
                          handleoption(index, "mode", e.target.value);
                        },
                      })}
                    />
                  </div>
                  {errors &&
                    errors.addForm &&
                    errors.addForm[index] &&
                    errors.addForm[index].mode && (
                      <span className="input-error">Mode is required</span>
                    )}
                </Col>
                <Col sm={24} md={8} className=" p-2">
                  <label className="input-title">Description </label>
                  <br />
                  <input
                    className="input-field"
                    name={`addForm[${index}].Description`}
                    // {...register("Description", { required: true })}
                    value={addForm[index].Description}
                    {...register(`addForm[${index}].Description`, {
                      required: true,
                      onChange: (e) => {
                        handleoption(index, "Description", e.target.value);
                      },
                    })}
                  />

                  <br />
                  {errors &&
                    errors.addForm &&
                    errors.addForm[index] &&
                    errors.addForm[index].Description && (
                      <span className="input-error">Description required</span>
                    )}
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={8} className="p-2">
                  <label className="input-title">start Date</label>
                  <input
                    className="batchInput"
                    type="date"
                    onChange={handleStartDateChange}
                    {...register("startDate", { required: true })}
                  />
                  <br />
                  {errors.startDate && (
                    <span className="Stud-personal-error">
                      start Date is required
                    </span>
                  )}
                </Col>
                <Col xs={24} sm={24} md={8} className="p-2">
                  <label className="input-title">End Date</label>
                  <input
                    className="batchInput"
                    type="date"
                    onChange={handleEndDateChange}

                    {...register("endDate", { required: true })}
                  />

                  <br />
                  {errors.endDate && (
                    <span className="Stud-personal-error">
                      End Date is required
                    </span>
                  )}
                </Col>
                <Col sm={24} md={8} className=" p-2">
                  <label className="input-title">Contact Number </label>
                  <br />
                  <input
                    type="number"
                    className="input-field"
                    name={`addForm[${index}].ContactNo`}
                    value={addForm[index].ContactNo}
                    {...register(`addForm[${index}].ContactNo`, {
                      required: true,
                      onChange: (e) => {
                        handleoption(index, "ContactNo", e.target.value);
                      },
                    })}
                  />

                  <br />
                  {errors &&
                    errors.addForm &&
                    errors.addForm[index] &&
                    errors.addForm[index].ContactNo && (
                      <span className="input-error">
                        Contact Number required
                      </span>
                    )}
                </Col>
              </Row>
              {index > 0 && (
                <Row>
                  <div className="d-flex justify-content-end">
                    <button
                      className="ms-4 mt-3 remove-button "
                      onClick={() => handleRemove(index, setValue)}
                    >
                      Remove
                    </button>
                  </div>
                </Row>
              )}
            </div>
          );
        })}

        <div style={{ marginRight: "14px" }} className="submitbuttons p-2">
          <button
            type="button"
            // onClick={() => setAddForm([...addForm, 1])}
            onClick={handleAddQuestion}
            className="button3 m-2 p-2"
          >
            Add Courses
          </button>
        </div>
        <div style={{ marginRight: "22px" }} className="submitbuttons p- 2">
          <button className="button1 m-2 p-2" type="submit">
            Submit
          </button>
          <button className="button2 m-2 p-2" type="reset">
            Reset
          </button>
        </div>
      </form>
    </div>
  );
}
