import { Rate } from 'antd';
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom'
import "../../../../Components/Css/Students/curriculamlandingpage.scss"
import 'aos/dist/aos.css';
import AOS from 'aos';




function OperatingSystem() {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Set animation duration in milliseconds
      easing: 'ease-in-out', // Animation easing
    });
  }, []);




  const Courses = [
    {
      id: 1,
      title: "Operating System",
      description:
        "Machine learning is a field of study in artificial intelligence concerned with the development and study of statistical algorithms that can learn from dataLearn the basics of React, including components, state, and props.",
      instructor: "John Doe",
      duration: "4 weeks",
      startDate: "2023-09-01",
      image:
        "https://img.freepik.com/free-vector/operating-system-concept-illustration_114360-2762.jpg?size=626&ext=jpg&ga=GA1.1.1729328857.1715275984&semt=ais_hybrid",
      rating: 4.5,
      originalPrice: "199",
      discountPrice: 99,
      student: "22,219 students",
      created: "created by",
      dev: "Development > Web Development",
    },
    {
      id: 2,
      title: "Data Structure",
      description:
        "computer science, a data structure is a data organization, and storage format that is usually chosen for efficient access to data.",
      instructor: "Jane Smith",
      duration: "6 weeks",
      startDate: "2023-10-01",
      image:
        "https://img.freepik.com/free-vector/data-economy-isometric-composition-with-isolated-platforms-connected-with-wires-human-characters-computer-infrastructure-elements-vector-illustration_1284-79924.jpg?t=st=1723532146~exp=1723535746~hmac=db88b4a22f02c97792753a4f8dd03b4d4c73489b79e8b80866367562d97a1c0d&w=996",
      rating: 5,
      originalPrice: 199,
      discountPrice: 99,
      student: "22,219 students",
      created: "created by",
      dev: "Development > Web Development",
    },
    {
      id: 3,
      title: "Machine Learning",
      description:
        "An operating system is system software that manages computer hardware and software resources, and provides common services for computer programs",
      instructor: "Mary Johnson",
      duration: "8 weeks",
      startDate: "2023-11-01",
      image:
        "https://img.freepik.com/free-vector/brain-with-digital-circuit-programmer-with-laptop-machine-learning-artificial-intelligence-digital-brain-artificial-thinking-process-concept-vector-isolated-illustration_335657-2246.jpg?size=626&ext=jpg&ga=GA1.1.957273099.1723532135&semt=ais_hybrid",
      rating: 3.8,
      originalPrice: 199,
      discountPrice: 99,
      student: "22,219 students",
      created: "created by",
      dev: "Development > Web Development",
    },
    {
      id: 4,
      title: "Software Engineering",
      description:
        "Software engineering is an engineering approach to software development. A practitioner, called a software engineer, applies the engineering design process to develop software",
      instructor: "Mary Johnson",
      duration: "7 weeks",
      startDate: "2023-11-01",
      image:
        "https://www.clarkson.edu/sites/default/files/2023-06/Software-Engineering-Hero-1600x900.jpg",
      rating: 3.3,
      originalPrice: 199,
      discountPrice: 99,
      student: "22,219 students",
      created: "created by",
      dev: "Development > Web Development",
    },
    {
      id: 5,
      title: "Math structure",
      description:
        "In mathematics, a structure is a set provided with some additional features on the set. Often, the additional features are attached or related to the set, so as to provide it with some additional meaning or significance",
      instructor: "Mary Johnson",
      duration: "3 weeks",
      startDate: "2023-11-01",
      image:
        "https://www.researchgate.net/publication/339015734/figure/fig3/AS:854701499224065@1580788125733/The-hierarchy-of-structures-in-the-Mathematical-Components-library-180-and-190-The.png",
      rating: 4.7,
      originalPrice: 199,
      discountPrice: 99,
      student: "22,219 students",
      created: "created by",
      dev: "Development > Web Development",
    },
    {
      id: 6,
      title: "Computer Networks",
      description:
        "A computer network is a set of computers sharing resources located on or provided by network nodes. Computers use common communication protocols over digital interconnections to communicate with each other.",
      instructor: "Mary Johnson",
      duration: "8 weeks",
      startDate: "2023-11-01",
      image:
        "https://img.freepik.com/premium-vector/internet-icon-technology-vector-communication-illustration-business-web-network-online-com_1013341-139773.jpg?size=626&ext=jpg&ga=GA1.1.957273099.1723532135&semt=ais_hybrid",
      rating: 3.9,
      originalPrice: 199,
      discountPrice: 99,
      student: "22,219 students",
      created: "created by",
      dev: "Development > Web Development",
    },
    {
      id: 7,
      title: "UNIX LAB",
      description:
        "Unix and Shell Programming. Intelligent Systems. Statistics and Numerical Techniques. Business Management. Management Accounting. Unix lab",
      instructor: "Mary Johnson",
      duration: "8 weeks",
      startDate: "2023-11-01",
      image:
        "https://img.freepik.com/premium-photo/very-realistic-3d-rendering-modern-corporate-office-white-glass-blue_190619-3513.jpg?w=1380",
      rating: 4.2,
      originalPrice: 199,
      discountPrice: 99,
      student: "22,219 students",
      created: "created by",
      dev: "Development > Web Development",
    },
    {
      id: 8,
      title: "Cloud Computing",
      description:
        "DaCloud Computing is a method of organizing and compiling data into one database, whereas data mining deals with fetching important data from databases",
      instructor: "Mary Johnson",
      duration: "8 weeks",
      startDate: "2023-11-01",
      image:
        "https://as2.ftcdn.net/v2/jpg/02/25/32/77/1000_F_225327769_RiJACfN3PPkcRUNU6dhWwTTKPDHf40nM.jpg",
      rating: 4.9,
      originalPrice: 199,
      discountPrice: 99,
      student: "22,219 students",
      created: "created by",
      dev: "Development > Web Development",
    },
  ];

  const items = [
    'Learn modern Angular, including standalone components & signals from the ground up & in great detail!',
    'Develop modern, complex, responsive and scalable web applications with Angular',
    'Learn TypeScript, a modern JavaScript superset, along the way',
    'Use the gained, deep understanding of the Angular fundamentals to quickly establish yourself as a frontend developer',

  ];

  const { id } = useParams();

  const course  = Courses.find((course) => course.id === parseInt(id));

  return (
    <div>
  <div style={{ position: "absolute", backgroundColor: "black", width: "100%"}} >
        <Row style={{ color: "white" }} >
          <Col lg={1}> </Col>
          <Col lg={5} style={{ marginTop: "18vh", marginBottom: "10vh" }} data-aos="fade-right">
          <div>

            <b style={{ fontSize: "3vh", fontFamily: "monospace", fontWeight: "500", color: "#5022c3" }}>{course.dev}</b>
            <b style={{ fontSize: "5vh", fontFamily: "monospace", fontWeight: "400", display: "flex", direction: "column" }}>{course.title}</b>
            <br />
            <em style={{ fontSize: "2vh", fontFamily: "monospace", fontWeight: "200", display: "flex", direction: "column" }}>  {course.description}</em>
            <br />
               {/* <b style={{ fontSize: "17px", color: "orange" }}>{course.rating}</b>&nbsp;
            <Rate
              disabled
              allowHalf
              defaultValue={course.rating}
              style={{ fontSize: "5px", color: "orange" }}
              className="course-rating"
            />
            <b style={{ marginLeft: "3vh", color: "#5022c3" }}>{course.student}</b>
            <br /> */}

            <em style={{ display: "flex", direction: "column", fontSize: "20px", fontWeight: "300" }}>  {course.created} <href style={{ color: "#5022c3", marginLeft: "1vh" }}>{course.instructor}</href></em>


            </div>
          </Col>
          <Col lg={6}></Col>

        </Row>

      </div>



      <div style={{ position: "relative", marginLeft: "110vh", paddingTop: "27vh"}}  data-aos="fade-left">
        <img
          src={course.image}
          style={{ width: "380px", height: "400px", borderRadius: "2vh", boxShadow: "-moz-initial" }}
          alt={course.title}
        />
      </div>



      <div>

        <Row>

          <Row>
            <Col lg={1}></Col>
            <Col lg={4}> <h2 style={{ display: "flex", justifyContent: "start", marginBottom: "5vh" }}>My List with Checkmarks</h2></Col>
            <Col lg={7}></Col>
          </Row>


          <Col lg={2}></Col>
          <Col lg={3}>
            <ul className="checklist">
              {items.map((item, index) => (
                <li key={index}>
                  <span role="img" aria-label="checkmark" className="checkmark">
                    ✔️
                  </span>
                  {item}
                </li>
              ))}
            </ul></Col>
          <Col lg={1}></Col>
          <Col lg={3}>
            <ul className="checklist">
              {items.map((item, index) => (
                <li key={index}>
                  <span role="img" aria-label="checkmark" className="checkmark">
                    ✔️
                  </span>
                  {item}
                </li>
              ))}
            </ul></Col>
          <Col lg={3}></Col>
        </Row>




      </div>


      {/* <div style={{ backgroundColor: "black", color: "white" }}>
        <Row>
          <Col lg="4"></Col>
          <Col lg="4">
            <p>{course.description}</p>
            <p>{course.rating}</p>
          </Col>
          <Col lg="4"></Col>
        </Row>
      </div> */}
      <div></div>

    </div>
  );
}

export default OperatingSystem;
