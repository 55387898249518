import React from "react";
import { FaEnvelope, FaLinkedin, FaChartLine, FaCode } from "react-icons/fa";
// import "./InfographicCV.css";

const InfographicCV = () => {
  return (
    <div className="infographic-cv">
      <header className="cv-header">
        <h1>Samantha Blue</h1>
        <p>Marketing Specialist</p>
      </header>

      <section className="cv-section">
        <h2>Skills</h2>
        <div className="skill-graph">
          <p><FaChartLine /> Digital Marketing</p>
          <p><FaCode /> Web Development</p>
        </div>
      </section>

      <section className="cv-section">
        <h2>Experience</h2>
        <ul>
          <li>Marketing Manager, Creative Inc. (2019-present)</li>
          <li>Assistant Manager, Design Studios (2017-2019)</li>
        </ul>
      </section>

      <section className="cv-section">
        <h2>Projects</h2>
        <ul>
          <li>Social Media Campaigns for Tech Co.</li>
          <li>Website Redesign for Design Studios</li>
        </ul>
      </section>

      <footer className="cv-footer">
        <p><FaLinkedin /> linkedin.com/in/samanthablue</p>
        <p><FaEnvelope /> samantha.blue@example.com</p>
      </footer>
    </div>
  );
};

export default InfographicCV;
