import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import {
  FaChalkboardTeacher,
  FaGraduationCap,
  FaCertificate,
  FaBookOpen,
  FaLaptopCode,
  FaBriefcase,
  FaSearch,
  FaEnvelopeOpenText,
  FaComments,
  FaTools,
  FaBlog,
  FaBullhorn,
} from "react-icons/fa"; // Import icons
import "bootstrap/dist/css/bootstrap.min.css";
import "./AboutUs.scss";
import Layout from "../Components/Layout";

const AboutUs = () => {
  return (
    <Layout>
      <Container fluid className="my-5">
        <h1 className="text-center mb-4">About Us</h1>
        <h3 className="text-center mb-4">
          Empowering Lifelong Learners & Career Builders
        </h3>
        <p
          className="text-center mb-5  "
          style={{ fontSize: "18px", fontWeight: "bold" }}
        >
          At CareerBlitz, we believe in unlocking potential and empowering
          individuals with the skills, certifications, and opportunities needed
          to excel in today's competitive world.
        </p>

        <Row className="mb-5 g-4">
          <Col md={4} className="px-2">
            <Card className="shadow-lg mb-4 h-100">
              <Card.Body>
                <Card.Title className="text-primary">
                  <FaBookOpen className="me-2" /> Wide Range of Courses
                </Card.Title>
                <Card.Text>
                  Dive into our extensive catalog of courses, spanning across
                  industries and disciplines. From foundational knowledge to
                  advanced skills, we offer something for everyone.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="px-2">
            <Card className="shadow-lg mb-4 h-100">
              <Card.Body>
                <Card.Title className="text-primary">
                  <FaCertificate className="me-2" /> Industry-Recognized
                  Certifications
                </Card.Title>
                <Card.Text>
                  Stand out in the job market with certifications that are
                  valued by leading global companies. Our partnerships ensure
                  that your credentials open doors to new opportunities.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="px-2">
            <Card className="shadow-lg mb-4 h-100">
              <Card.Body>
                <Card.Title className="text-primary">
                  <FaChalkboardTeacher className="me-2" /> Learn from Experts
                </Card.Title>
                <Card.Text>
                  Our courses are led by industry professionals. Learn from
                  their experiences, insights, and mentorship, giving you a
                  competitive edge in your career.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mb-5 g-4">
          <Col md={4} className="px-2">
            <Card className="shadow-lg mb-4 h-100">
              <Card.Body>
                <Card.Title className="text-primary">
                  <FaLaptopCode className="me-2" /> Flexible Learning Options
                </Card.Title>
                <Card.Text>
                  Study at your own pace through self-paced courses or opt for
                  live, interactive sessions. Our platform is built to fit into
                  your lifestyle.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="px-2">
            <Card className="shadow-lg mb-4 h-100">
              <Card.Body>
                <Card.Title className="text-primary">
                  <FaBriefcase className="me-2" /> Career-Focused Curriculum
                </Card.Title>
                <Card.Text>
                  Our courses align with employer needs, ensuring hands-on
                  projects help you land your dream job. Plus, enjoy job
                  placement support after completing your training.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <h3 className="text-center mb-4">Kickstart Your Career</h3>
        <Row className="mb-5 g-4">
          <Col md={4} className="px-2">
            <Card className="shadow-lg mb-4 h-100">
              <Card.Body>
                <Card.Title className="text-success">
                  <FaSearch className="me-2" /> Extensive Job Listings
                </Card.Title>
                <Card.Text>
                  Explore a wide array of job opportunities, from internships to
                  senior roles, across multiple industries.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="px-2">
            <Card className="shadow-lg mb-4 h-100">
              <Card.Body>
                <Card.Title className="text-success">
                  <FaEnvelopeOpenText className="me-2" /> Tailored Job
                  Recommendations
                </Card.Title>
                <Card.Text>
                  Get personalized job suggestions based on your skills and
                  preferences to find the best opportunities.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="px-2">
            <Card className="shadow-lg mb-4 h-100">
              <Card.Body>
                <Card.Title className="text-success">
                  <FaComments className="me-2" /> Resume & Cover Letter Support
                </Card.Title>
                <Card.Text>
                  Access tools and expert advice to create standout resumes and
                  cover letters that catch the eye of employers.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <h3 className="text-center mb-4">Empowering Colleges</h3>
        <Row className="mb-5 g-4">
          <Col md={4} className="px-2">
            <Card className="shadow-lg mb-4 h-100">
              <Card.Body>
                <Card.Title className="text-warning">
                  <FaTools className="me-2" /> Receive Applications
                </Card.Title>
                <Card.Text>
                  Streamline the admissions process by accepting and managing
                  student applications directly through our platform.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="px-2">
            <Card className="shadow-lg mb-4 h-100">
              <Card.Body>
                <Card.Title className="text-warning">
                  <FaBlog className="me-2" /> Post Blogs
                </Card.Title>
                <Card.Text>
                  Share educational content and campus updates by posting blogs,
                  fostering student engagement and connection.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="px-2">
            <Card className="shadow-lg mb-4 h-100">
              <Card.Body>
                <Card.Title className="text-warning">
                  <FaBullhorn className="me-2" /> Post Jobs
                </Card.Title>
                <Card.Text>
                  Advertise job opportunities to recruit faculty and staff
                  directly through your job portal.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <h3 className="text-center mb-4">Join Us Today</h3>
        <p
          className="text-center mb-5"
          style={{ fontSize: "16px", fontWeight: "bold" }}
        >
          Whether you're a student, professional, educator, or employer,
          CareerBlitz offers the tools and resources you need to achieve your
          goals. Discover, learn, and grow with us. Together, we’ll create a
          future full of opportunities.
        </p>
        <div className="text-center">
          <Button variant="primary" size="lg">
            Explore. Learn. Achieve.
          </Button>
        </div>
      </Container>
    </Layout>
  );
};

export default AboutUs;
