import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import "../../../Components/Css/Students/MenuProjects.scss";
import 'aos/dist/aos.css';
import AOS from 'aos';


function MenuProject() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Set animation duration in milliseconds
      easing: 'ease-in-out', // Animation easing
      delay:'300',
    });
  }, []);
  
  const items = [
    {
      id: 1,
      img: "https://images.unsplash.com/photo-1532619675605-1ede6c2ed2b0?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE1fHx8ZW58MHx8fHx8",
      title: "careerblitz Project",
      description:
        "Keep it simple, focus on learning and applying basic concepts. Use these projects as a way to build confidence and skills.",
    },
    {
      id: 2,
      img: "https://images.unsplash.com/photo-1531403009284-440f080d1e12?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEwfHx8ZW58MHx8fHx8",
      title: "Mini Project",
      description:
        "Mini projects are usually smaller in scale and are designed to be completed in a shorter timeframe. They often focus on a specific concept or skill.",
    },
    {
      id: 3,
      img: "https://plus.unsplash.com/premium_photo-1661293879952-c5c093282801?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8cHJvamVjdHxlbnwwfHwwfHx8MA%3D%3D",
      title: "Major Project",
      description:
        "Major projects are more comprehensive and require a greater investment of time and resources. They often involve complex problem-solving and in-depth analysis.",
    },
  ];

  return (
    <>
      <div className="project-heading">
        <p>Project Banner</p>
      </div>

      <div className="project-card" data-aos="zoom-in">
        <Row className="project-row">
          {items.map((item) => (
            <Col md={6} lg={4} className="project-col">
              <div className="first-row">
                <Card className="card-items">
                  <Card.Body>
                    <img src={item.img}></img>
                    <Card.Title style={{ marginTop: "2vh" }}>
                      {item.title}
                    </Card.Title>
                    <Card.Text>{item.description}</Card.Text>
                  </Card.Body>
                  <Card.Footer style={{display:"flex",justifyContent:"start"}}>
                    <Button variant="primary" className="project-view-btn">View</Button>
                  </Card.Footer>
                </Card>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default MenuProject;
