// import { Button, Input, Table } from "antd";
// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import Api from "../../../../Api";
// import moment from "moment";

// const ProfessionaList = () => {
//   const [data, setData] = useState([]);
//   const [searchText, setSearchText] = useState("");
//   const [search, setSearch] = useState([]);
//   const navigate = useNavigate();

//   function createdAtSorter(a, b) {
//     if (a.createdAt < b.createdAt) {
//       return -1;
//     }

//     const columns = [
//       {
//         title: "Registration Id",
//         dataIndex: "RegistrationId",
//       },
//       {
//         title: "Name",
//         dataIndex: "firstName",
//       },
//       {
//         title: "Email",
//         dataIndex: "email",
//       },
//       {
//         title: "Created At",
//         dataIndex: "createdAt",
//         sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
//         render: (text, record) =>
//           new Date(record.createdAt).toLocaleDateString("en-GB"),
//       },
//       {
//         title: "Action",
//         dataIndex: "action",
//         render: (_, record) => (
//           <div>
//             <Button
//               type=""
//               className="data-list-button"
//               onClick={() =>
//                 navigate(`/admin/ProfessionalList/details/${record?._id}`)
//               }
//             >
//               View Details
//             </Button>
//           </div>
//         ),
//       },
//     ];

//     const getData = async () => {
//       await Api.get("employee/Employee_get").then((res) => {
//         setData(res.data.data);
//       });
//     };
//     return 0;
//   }

//   const columns = [
//     {
//       title: "Registration Id",
//       dataIndex: "RegistrationId",
//     },
//     {
//       title: "Name",
//       dataIndex: "firstName",
//     },
//     {
//       title: "Email",
//       dataIndex: "email",
//     },
//     {
//       title: "Created At",
//       dataIndex: "createdAt",
//       sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
//       render: (text, record) =>
//         new Date(record.createdAt).toLocaleDateString("en-GB"),
//     },
//     {
//       title: "Action",
//       dataIndex: "action",
//       render: (_, record) => (
//         <div>
//           <Button
//             type=""
//             className="data-list-button"
//             onClick={() =>
//               navigate(`/admin/ProfessionalList/details/${record?._id}`)
//             }
//           >
//             View Details
//           </Button>
//         </div>
//       ),
//     },
//   ];

//   const getData = async () => {
//     await Api.get("employee/Employee_get").then((res) => {
//       setData(res.data.data);
//     });
//   };
//   useEffect(() => {
//     getData();
//     const results = data.filter(
//       (item) =>
//         item?.firstName
//           ?.toLowerCase()
//           .includes(searchText.toLowerCase().trim()) ||
//         item?.RegistrationId?.toLowerCase().includes(
//           searchText.toLowerCase().trim()
//         )
//     );
//     setSearch(results);
//   }, [searchText]);

//   const handleChange = (e) => {
//     setSearchText(e.target.value);
//   };

//   let dataCount = data.length;

//   return (
//     <div>
//       <div className="data-list-main">
//         <h4 className="data-list-header pages-title">Professional List</h4>
//         <h3 className="pages-title">Total Records: {dataCount}</h3>
//         <div
//           className="data-list-top mt-4 py-4 px-3"

//         >
//           <Input
//             className="data-list-search ps-2"
//             placeholder="search by name or Registration Id"
//             onChange={handleChange}
//             enterButton
//           />
//         </div>
//         {searchText ? (
//           <div className="data-list-table">
//             <Table
//               scroll={{ x: true }}
//               columns={columns}
//               dataSource={search}
//               // pagination={false}
//               className="mt-2"
//             />
//           </div>
//         ) : (
//           <div className="data-list-table">
//             <Table
//               scroll={{ x: true }}
//               columns={columns}
//               dataSource={data}
//               // pagination={false}
//               className="mt-2"
//             />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ProfessionaList;
import { Input, Table } from "antd";
import { Row, Col, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Api from "../../../../Api";
import moment from "moment";
import "../../../../Components/Css/Admin/StudentList.scss";
// import Button from 'react-bootstrap/Button';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { TiTick } from "react-icons/ti";
import { RiDeleteBin5Line } from "react-icons/ri";
import { TbRectangleVertical } from "react-icons/tb";
import { HiPencilSquare } from "react-icons/hi2";
import { BsDownload } from "react-icons/bs";
import Tooltip from 'react-bootstrap/Tooltip';
import { ToastContainer, toast } from "react-toastify";
import { MdOutlineRemoveRedEye } from "react-icons/md";
const StudentList = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState([]);
  const [viewProfile,setViewProfile] = useState()
  const navigate = useNavigate();

  // function createdAtSorter(a, b) {
  //     if (a.createdAt < b.createdAt) {
  //         return -1;
  //     }
  //     if (a.createdAt > b.createdAt) {
  //         return 1;
  //     }
  //     return 0;
  // }
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <Row>
          <Col className="pe-3">
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Change user Stauts</Tooltip>}>
            <a href="#">
            
              <TiTick
                alt
                className="tickIcon"
                style={{
                  backgroundColor: "#449d44",
                  color: "white",
                  fontSize: "22px",
                  width: "25px",
                }}
              />
            
            </a>
            </OverlayTrigger>
          </Col>
          <Col>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">View Profile</Tooltip>}>
            <a href={`/admin/ProfessionalList/details/${viewProfile}`}>
              <MdOutlineRemoveRedEye
                style={{
                  backgroundColor: "#4489e4",
                  color: "white",
                  fontSize: "22px",
                  width: "25px",
                }}
              />
            </a> 
            </OverlayTrigger>
          </Col>
        </Row>
        <Row  className="mt-2">
          <Col className="pe-3">
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete Profile</Tooltip>}>
            <div>
              <RiDeleteBin5Line
                className="tickIcon"
                onClick={()=>getDeletedata(viewProfile)}
                style={{
                  backgroundColor: "#fd6b75",
                  color: "white",
                  fontSize: "20px",
                  width: "25px",
                }}
              />
              </div>
            </OverlayTrigger>
          </Col>
          <Col>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit Package</Tooltip>}>
            <a href="#">
              <TbRectangleVertical
                className="tickIcon"
                style={{
                  backgroundColor: "#4489e4",
                  color: "white",
                  fontSize: "20px",
                  width: "25px",
                }}
              />
            </a>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row  className="mt-2">
          <Col className="pe-3">
            {" "}
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit Profile</Tooltip>}>
            <a href="#">
              <HiPencilSquare
                className="tickIcon"
                style={{
                  backgroundColor: "#449d44",
                  color: "white",
                  fontSize: "20px",
                  width: "25px",
                }}
              />{" "}
            </a>
            </OverlayTrigger>
          </Col>
          <Col>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Download Profile</Tooltip>}>
            <a href="#"> 
              <BsDownload
                className="tickIcon"
                style={{
                  backgroundColor: "#4489e4",
                  color: "white",
                  fontSize: "20px",
                  width: "25px",
                }}
              />
            </a>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Report</Tooltip>}>
            <a
              href="#"
              style={{
                backgroundColor: "#fd6b75",
                color: "white",
                fontSize: "14px",
                fontWeight: "bolder",
                padding: "3px 6px " ,
                borderRadius:"3px"
              }}
            >
              Report <span style={{color:"yellow",fontSize:"12px"}} >(0)</span>
            </a>
            </OverlayTrigger>
          </Col>
        </Row>
      </Popover.Body>
    </Popover>
  );
  const columns = [
    {
      title: "Employee Id",
      dataIndex: "RegistrationId",
    },
    {
      title: "Name",
      dataIndex: "firstName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone  ",
      dataIndex: "phone",
    },
    {
      title: "Add Date ",
      dataIndex: "addDate",
    },
    {
      title: "Package",
      dataIndex: "package",
    },
    // WDD
    // {
    //     title: 'Created At',
    //     dataIndex: 'createdAt',
    //     sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    //     render: (text, record) => new Date(record.createdAt).toLocaleDateString('en-GB')
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div>
          <OverlayTrigger trigger="click" placement="left" overlay={popover}>
            <Link
              type=""
              className="data-list-button"
              onClick={(e) =>
                {console.log("llll",record._id)
                setViewProfile(record._id)
                navigate(`/admin/ProfessionalList/details/${record?._viewProfile}`)}
              }
            >
              Click Here
            </Link>
          </OverlayTrigger>
        </div>
      ),
    },
  ];
  

  const getData = async () => {
    await Api.get("employee/Employee_get").then((res) => {
      setData(res.data.data);
    });
    console.log("setData :>> ", typeof data.map((item) => item?.phone));
  };
  // const getDeletedata = async (viewProfile) => {
  //   await Api.delete(`employee/Employee_delete/${viewProfile}`).then((res) => {
  //     console.log('res', res)
  //   });
  // };
  // const getDeletedata = async (viewProfile) => {
  //   try {
  //     const deleteApi1 = Api.delete(`employee/Employee_delete/${viewProfile}`);
  //     const deleteApi2 = Api.delete(`student/delete/userdb/${viewProfile}`);
  
  //     const [res1, res2] = await Promise.all([deleteApi1, deleteApi2]);
  
  //     console.log('Response 1:', res1);
  //     console.log('Response2', res2);
  //     getData();
      
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  const getDeletedata = async (viewProfile) => {
    try {
      const deleteApi1 = Api.delete(`employee/Employee_delete/${viewProfile}`);
      const deleteApi2 = Api.delete(`student/delete/userdb/${viewProfile}`);
  
      const [res1, res2] = await Promise.all([deleteApi1, deleteApi2]);
  
      console.log('Response 1:', res1);
      console.log('Response2', res2);
      getData();
  
      // Check if both deletions were successful
      if (res1.status === 200 && res2.status === 200) {
        toast.success('Deletion successful', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        // If any deletion fails, show an error message
        toast.error('Deletion failed', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error('Error:', error);
      // Show an error toast message if there's an exception
      toast.error('An error occurred', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };
  
  
  useEffect(() => {
    getData();
    const results = data.filter(
      (item) =>
        item?.firstName
          ?.toLowerCase()
          .includes(searchText.toLowerCase().trim()) ||
        item?.RegistrationId?.toLowerCase().includes(
          searchText.toLowerCase().trim()
        ) ||
        item?.email?.toLowerCase().includes(searchText.toLowerCase().trim()) ||
        item?.phone?.toString().includes(searchText)
    );
    setSearch(results);
  }, [searchText],[getDeletedata]);

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  let dataCount = data.length;

  return (
    <div>
      <div className="data-list-main">
        <h4 className="data-list-header pages-title"> Professional List</h4>
        <h3 className="pages-title">Total Records: {dataCount}</h3>
        <div className="data-list-top mt-4 py-2 px-3">
          <Input
            className="data-list-search ps-2"
            placeholder="search here"
            onChange={handleChange}
            enterButton
          />
        </div>
        {searchText ? (
          <div className="data-list-table">
            <Table
              scroll={{ x: true }}
              columns={columns}
              dataSource={search}
             className="mt-2"
            />
          </div>
        ) : (
          <div className="data-list-table">
            <Table
              scroll={{ x: true }}
              columns={columns}
              dataSource={data}
              // pagination={false}
              className="mt-2"
            />
          </div>
        )}
      </div>
      <ToastContainer/>
    </div>
  );
};

export default StudentList;
