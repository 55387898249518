import { Input, Table } from "antd";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Api from "../../../../Api";
import moment from "moment";
import "../../../Exam/Technology/Technology.scss";
import { Button } from "antd";
import "../../../../Components/Css/Admin/StudentList.scss";
// import Button from 'react-bootstrap/Button';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { TiTick } from "react-icons/ti";
import { RiDeleteBin5Line } from "react-icons/ri";
import { TbRectangleVertical } from "react-icons/tb";
import { HiPencilSquare } from "react-icons/hi2";
import { BsDownload, BsFillPersonPlusFill } from "react-icons/bs";
import Tooltip from "react-bootstrap/Tooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const StudentList = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState([]);
  const navigate = useNavigate();
  const [viewProfile, setViewProfile] = useState();
  const [ourEmployes,setOurEmployees]=useState()

  // function createdAtSorter(a, b) {
  //     if (a.createdAt < b.createdAt) {
  //         return -1;
  //     }
  //     if (a.createdAt > b.createdAt) {
  //         return 1;
  //     }
  //     return 0;
  // }
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <Row>
          <Col className="pe-3">
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Change user Stauts</Tooltip>
              }
            >
              <a href="#">
                <TiTick
                  alt
                  className="tickIcon"
                  style={{
                    backgroundColor: "#449d44",
                    color: "white",
                    fontSize: "22px",
                    width: "25px",
                  }}
                />
              </a>
            </OverlayTrigger>
          </Col>
          <Col>
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">View Profile</Tooltip>}
            >
              <a href="#">
                <MdOutlineRemoveRedEye
                  style={{
                    backgroundColor: "#4489e4",
                    color: "white",
                    fontSize: "22px",
                    width: "25px",
                  }}
                />
              </a>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="pe-3">
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Delete Profile</Tooltip>}
            >
              <div>
                <RiDeleteBin5Line
                  className="tickIcon"
                  onClick={() => getDatas(viewProfile)}
                  style={{
                    backgroundColor: "#fd6b75",
                    color: "white",
                    fontSize: "20px",
                    width: "25px",
                  }}
                />
              </div>
            </OverlayTrigger>
          </Col>
          <Col>
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Edit Package</Tooltip>}
            >
              <div>
              {/* <a href="#"> */}
                <TbRectangleVertical
                  className="tickIcon"
                  
                  style={{
                    backgroundColor: "#4489e4",
                    color: "white",
                    fontSize: "20px",
                    width: "25px",
                  }}
                />
                </div>
              {/* </a> */}
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="pe-3">
            {" "}
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Edit Profile</Tooltip>}
            >
              <div>
                <HiPencilSquare
                  className="tickIcon"
                  onClick={(e) => {
                    update(ourEmployes);
                  }}
                  style={{
                    backgroundColor: "#449d44",
                    color: "white",
                    fontSize: "20px",
                    width: "25px",
                  }}
                />
                </div>
            </OverlayTrigger>
          </Col>
          <Col>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Download Profile</Tooltip>
              }
            >
              <a href="#">
                <BsDownload
                  className="tickIcon"
                  style={{
                    backgroundColor: "#4489e4",
                    color: "white",
                    fontSize: "20px",
                    width: "25px",
                  }}
                />
              </a>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Report</Tooltip>}
            >
              <a
                href="#"
                style={{
                  backgroundColor: "#fd6b75",
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "bolder",
                  padding: "3px 6px ",
                  borderRadius: "3px",
                }}
              >
                Report{" "}
                <span style={{ color: "yellow", fontSize: "12px" }}>(0)</span>
              </a>
            </OverlayTrigger>
          </Col>
        </Row>
      </Popover.Body>
    </Popover>
  );
  const columns = [
    {
      title: "Employee Id ",
      dataIndex: "EmployeeId",
    },
    {
      title: "Name",
      dataIndex: "firstname",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone  ",
      dataIndex: "phoneNumber",
    },
    {
      title: "Add Date ",
      dataIndex: "addDate",
    },
    {
      title: "Package",
      dataIndex: "package",
    },
    // WDD
    // {
    //     title: 'Created At',
    //     dataIndex: 'createdAt',
    //     sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    //     render: (text, record) => new Date(record.createdAt).toLocaleDateString('en-GB')
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div>
          <OverlayTrigger trigger="click" placement="left" overlay={popover}>
            <Link
              type=""
              className="data-list-button"
              sty
              onClick={() => {
                setOurEmployees(record)
                setViewProfile(record._id);
                navigate(`/admin/StudentList/details/${record?._id}`);
              }}
            >
              Click Here
            </Link>
          </OverlayTrigger>
        </div>
      ),
    },
  ];
  const update = (ele) => {
    console.log(ele, "jjj");
    navigate("List", { state: { ele } });
  };

  const getData = async () => {
    await Api.get("ResourceOurEmployee/GetOurEmployee").then((res) => {
      setData(res.data.data);
    });
    console.log("setData :>> ", typeof data.map((item) => item?.phone));
  };
  const getDatas = async (viewProfile) => {
    await Api.delete(`ResourceOurEmployee/deleteemployee/${viewProfile}`).then(
      (res) => {
        console.log("res", res);
        if (res) { 
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          getData();
        }
      }
    );
  };
  useEffect(() => {
    getData();
    const results = data.filter(
      (item) =>
        item?.firstName
          ?.toLowerCase()
          .includes(searchText.toLowerCase().trim()) ||
        item?.RegistrationId?.toLowerCase().includes(
          searchText.toLowerCase().trim()
        ) ||
        item?.email?.toLowerCase().includes(searchText.toLowerCase().trim()) ||
        item?.phone?.toString().includes(searchText)
    );
    setSearch(results);
  }, [searchText]);

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  let dataCount = data.length;

  return (
    <div>
      <div className="data-list-main">
        <h4 className="data-list-header pages-title">Our Employees List</h4>
        <h3 className="pages-title">Total Records: {dataCount}</h3>
        <div className="data-list-top mt-4 py-2 px-3">
          <Input
            className="data-list-search ps-2"
            placeholder="search here"
            onChange={handleChange}
            enterButton
          />
          <Button
            className="data-list-button"
            onClick={() => navigate("/admin/Resource/OurEmployess/List")}
          >
            <BsFillPersonPlusFill />
            &nbsp;Add New
          </Button>
        </div>
        {searchText ? (
          <div className="data-list-table">
            <Table
              scroll={{ x: true }}
              columns={columns}
              dataSource={search}
              //  pagination={false}
              className="mt-2"
            />
          </div>
        ) : (
          <div className="data-list-table">
            <Table
              scroll={{ x: true }}
              columns={columns}
              dataSource={data}
              // pagination={false}
              className="mt-2"
            />
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default StudentList;
