import React, { useState, useEffect } from 'react';
import Api from '../../../Api';
import './AddSector.css';

function AddSector() {
  const [sectors, setSectors] = useState([]);
  const [sectorName, setSectorName] = useState('');

  useEffect(() => {
    // Fetch all sectors
    Api.get('/coursesector/getsector')
      .then(res => setSectors(res.data))
      .catch(err => console.error(err));
  }, []);

  const addSector = () => {
    if (sectorName) {
      Api.post('coursesector/createsector', { sectorName })
        .then(res => {
          setSectors([...sectors, res.data]);
          setSectorName(''); // Reset the input field after adding
        })
        .catch(err => console.error(err));
    } else {
      alert('Please enter a sector name.');
    }
  };

  return (
    <div className="addsector-container">
      <h2>Add Sector</h2>

      {/* Input for Sector Name */}
      <input
        type="text"
        value={sectorName}
        onChange={e => setSectorName(e.target.value)}
        placeholder="Enter Sector Name"
        className="sector-input"
      />

      {/* Add Sector Button */}
      <button 
        onClick={addSector} 
        disabled={!sectorName}
        className="add-button"
      >
        Add Sector
      </button>

      {/* Display Sectors */}
      <ul className="sector-list">
        {sectors.map(sector => (
          <li key={sector._id}>{sector.sectorName}</li>
        ))}
      </ul>
    </div>
  );
}

export default AddSector;
