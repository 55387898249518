// import React, { useEffect, useState } from "react";
// import { Row, Col } from "antd";
// import Card from "react-bootstrap/Card";
// import Api from "./../../../Api";
// import { useLocation } from "react-router-dom";

// const ViewJobInnerPage = () => {
//   const userId = localStorage.getItem("id");
//   const [viewApplicantsLits, setViewApplicantsLits] = useState([]);
//   const state = useLocation();
//   const jobTitles = state.state.jobTitle;

//   const normalizedFilteredJobTitle = jobTitles.trim().toLowerCase();
//   const filteredData = viewApplicantsLits.filter(
//     (item) => item.jobTitle.trim().toLowerCase() === normalizedFilteredJobTitle
//   );
//   console.log("first@@@", filteredData);
//   console.log("jobTitle", jobTitles);
//   useEffect(() => {
//     getViewApplicantsLists();
//   }, []);
//   const getViewApplicantsLists = async () => {
//     await Api.get(`/createForm/getoneJobrequest/${userId}`).then((res) => {
//       setViewApplicantsLits(res.data.data);
//     });
//   };

//   return (
//     <div>
//       <Row className="p-3">
//         {filteredData.map((data, i) => (
//           <Col sm={24} md={24} lg={12} className="p-3">
//             <Card>
//               <Card.Body>
//                 <h4>Job List </h4>
//                 <p className="Int-para2">
//                   <b>Job Title</b> {data.jobTitle}
//                 </p>
//                 <p className="Int-para2">
//                   <b>Roll:</b> {data.role}
//                 </p>
//                 <p className="Int-para2">
//                   <b>Email id:</b> {data.email}
//                 </p>{" "}
//                 <p className="Int-para2">
//                   <b>Experience:</b> {data.experience}
//                 </p>
//                 <p className="Int-para2">
//                   <b>location:</b> {data.location}
//                 </p>
//               </Card.Body>
//             </Card>
//           </Col>
//         ))}
//       </Row>
//     </div>

//   );
// };

// export default ViewJobInnerPage;

import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "antd";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import "../../../Components/Css/Students/Studintdetails.scss";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Api from "../../../Api";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = eventKey === activeEventKey;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#EBE6FF",
        paddingTop: "12px",
      }}
    >
      <p onClick={decoratedOnClick}>
        {children}
        {isCurrentEventKey ? <FiChevronUp /> : <FiChevronDown />}
      </p>
    </div>
  );
}

function ViewJobInnerPage() {
  const userId = localStorage.getItem("id");
  const [viewApplicantsLits, setViewApplicantsLits] = useState([]);
  const {state} = useLocation();
  console.log('state', state)
  const jobTitles = state?.jobTitle;
  const navigate = useNavigate();
  const normalizedFilteredJobTitle = jobTitles.trim().toLowerCase();
  const filteredData = viewApplicantsLits.filter(
    (item) => item.jobTitle.trim().toLowerCase() === normalizedFilteredJobTitle
  );
  useEffect(() => {
    getViewApplicantsLists();
  }, []);
  const getViewApplicantsLists = async () => {
    await Api.get(`/professional/getonejobs/${state?.jobId}`).then((res) => {
      setViewApplicantsLits(res.data.data);
    });
  };

  const handleSubmit = (data) => {
    navigate("jobrequestinnerpage", { state: data });
  };

  return (
    <div className="p-4">
      <div className="pages-title">Request</div>
      <Row className="p-3">
        {filteredData.map((data, i) => (
          <Col sm={24} md={24} lg={12} className="p-3">
            <Accordion defaultActiveKey="0">
              <Card
                className="Int-card"
                onClick={() => {
                  handleSubmit(data);
                }}
              >
                <Card.Body>
                  <h4>{data.projectTitle}</h4>
                  <p className="Int-para1">{data.jobTitle}</p>
                  <p className="Int-para2">
                    {/* {data.icon1} */}
                    {data.experience}
                  </p>
                  <p className="Int-para2">
                    {/* {data.icon2} */}
                    {data.qualification}
                  </p>
                  <p className="Int-para2">
                    {/* {data.icon3} */}
                    {data.skill}
                  </p>
                  <p className="Int-para2">
                    {/* {data.icon4} */}
                    {data.role}
                  </p>
                  <p className="Int-para2">
                    {/* {data.icon4} */}
                    {data.userEmail}
                  </p>
                  <Card.Text>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <p>{data.description}</p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card.Text>
                  <ContextAwareToggle eventKey="1">More</ContextAwareToggle>
                </Card.Body>
              </Card>
            </Accordion>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default ViewJobInnerPage;
