import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Button, Select } from "antd";
// import "../../../Components/Css/professionalstyle.scss";
import "../../../Components/Css/Professional/professionalstyle.scss";
import { Dropdown } from "primereact/dropdown";
import Api from "../../../Api.js";
import { Option } from "antd/lib/mentions";

function MyProfile() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({});

  const [details, setDetails] = useState();
  const [countryList, setcountryList] = useState([]);
  const [countryValue, setCountryValue] = useState();
  const [stateList, setstateList] = useState([]);
  const [stateValue, setStateValue] = useState();
  const [districtList, setdistrictList] = useState([]);
  const [districtValue, setDistrictValue] = useState();
  const [cityList, setcityList] = useState([]);
  const [cityValue, setCityValue] = useState();
  const [genderList, setGenderList] = useState();
  const [gender, setGender] = useState();
  const [maritalList, setMaritalList] = useState();
  const [Marital, setMarital] = useState();
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    getDetails();
    getCountry();
    getGender();
    getMarital();
    // getData();
  }, []);

  const getMarital = async () => {
    await Api.get("marital/getMarital").then((res) => {
      setMaritalList(res.data.data);
    });
  };

  const getGender = async () => {
    await Api.get("gender/getGenderlist").then((res) => {
      setGenderList(res.data.data);
    });
  };
  const getCountry = async () => {
    await Api.get("country/getallcountry").then((res) => {
      setcountryList(res.data.data);
    });
  };

  const getState = (country_id) => {
    setCountryValue(country_id);
    Api.get(`state/stateById/${country_id}`).then((res) => {
      setstateList(res.data.data);
    });
  };

  const getDistrict = (state_id) => {
    setStateValue(state_id);
    Api.get(`district/districtById/${state_id}`).then((res) => {
      setdistrictList(res.data.data);
    });
  };
  const getCity = (districtId) => {
    setDistrictValue(districtId);
    Api.get(`city/cityById/${districtId}`).then((res) => {
      setcityList(res.data.data);
    });
  };

  const userId = localStorage.getItem("userId");

  const [file, setFile] = useState();
  const [imagePreview, setImagePreview] = useState();

  function handleChange(e) {
    setFile(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  }

  const getDetails = async () => {
    try {
      const res = await Api.get(`trainer/Trainergetone/${userId}`);
      setUserData(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFormSubmit = async () => {
    const formData = getValues();
    const updatedData = { ...userData, ...formData };
    let countryName;
    let stateName;
    let districtName;
    let cityName;
    if (countryValue !== userData?.country) {
      countryName = countryList.find(
        (country) => country.id === countryValue
      ).name;
    } else {
      countryName = userData?.country;
    }

    if (stateValue !== userData?.state) {
      stateName = stateList.find((state) => state.id === stateValue).name;
    } else {
      stateName = userData?.state;
    }

    if (districtValue !== userData?.district) {
      districtName = districtList.find(
        (district) => district.id === districtValue
      ).name;
    } else {
      districtName = userData?.district;
    }

    if (cityValue !== userData?.city) {
      cityName = cityList.find((city) => city._id === cityValue).cityName;
    } else {
      cityName = userData?.city;
    }
    const Details = {
      ...updatedData,
      id: userId,
      firstName: formData.firstName,
      lastName: formData.lastName,
      address: formData.address,
      dob: formData.dob,
      email: formData.email,
      phone: formData.phone,
      gender: gender,
      maritalStatus: Marital,
      country: countryName,
      state: stateName,
      district: districtName,
      city: cityName,
      pincode: formData.pincode,
      profilePhoto: "",
      // resume: formData.resume,
    };

    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "darshan");

    const response = await fetch(
      "https://api.cloudinary.com/v1_1/dzblzw7ll/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    const cloudinaryData = await response.json();
    Details.profilePhoto = cloudinaryData.secure_url;

    await Api.put(`trainer/Trainerupdate/${userId}`, Details).then((resp) => {
      console.log("resp.data", resp.data);
    });
  };
  useEffect(() => {
    if (userData) {
      setValue("firstName", userData.firstName);
      setValue("lastName", userData.lastName);
      setValue("email", userData.email);
      setValue("address", userData.address);
      setValue("dob", userData.dob);
      setValue("phone", userData.phone);
      setValue("pincode", userData.pincode);
      setCountryValue(userData.country);
      setStateValue(userData.state);
      setDistrictValue(userData.district);
      setCityValue(userData.city);
      setGender(userData.gender);
      setMarital(userData.maritalStatus);
    }
  }, [userData]);

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="personal-informations p-4">
          <div className="pages-title mb-3">Personal Info</div>
          <Row className="mb-4">
            <Col sm={24} md={24} xs={8} className="px-4">
              <div>
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt=""
                    style={{
                      width: "150px",
                      height: "150px",
                    }}
                  />
                ) : (
                  <img
                    src={userData?.profilePhoto}
                    alt=""
                    style={{
                      width: "200px",
                      height: "180px",
                      marginBottom: "16px",
                    }}
                  />
                )}
              </div>
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <br />
              <label className="input-title">Upload Photo</label>
              <br />
              <input type="file" onChange={handleChange} />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">First Name</label>

              <input
                defaultValue={userData?.firstName}
                className="Professional__UpdateProfile__Input"
                {...register("firstName", { required: true })}
              />
              <br />
              {errors.firstName && (
                <span className="input-error">First Name required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Last Name</label>
              <input
                defaultValue={userData?.lastName}
                className="Professional__UpdateProfile__Input"
                {...register("lastName", { required: true })}
              />
              <br />
              {errors.lastName && (
                <span className="input-error">Last Name required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Phone Number</label>
              <input
                defaultValue={userData?.phone}
                className="Professional__UpdateProfile__Input"
                {...register("phone", { required: true })}
              />
              {errors.phone && (
                <span className="input-error"> Number required</span>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Date of Birth </label>
              <input
                defaultValue={userData?.dob}
                className="Professional__UpdateProfile__Input"
                type="date"
                {...register("dob", { required: true })}
              />
              <br />
              {errors.dob && <span className="input-error">DOB required</span>}
            </Col>

            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Gender </label>

              <Select
                className="input-field"
                value={gender}
                onChange={(value) => setGender(value)}
                placeholder="Select a Gender"
                style={{ border: "none" }}
              >
                {genderList?.map((option) => (
                  <Option key={option._id} value={option.gender}>
                    {option.gender}
                  </Option>
                ))}
              </Select>
              <br />
              {errors.gender && (
                <span className="input-error">Gender required</span>
              )}
            </Col>

            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Marital status </label>

              <Select
                className="input-field"
                value={Marital}
                onChange={(value) => setMarital(value)}
                placeholder="Select a Marital status"
                style={{ border: "none" }}
              >
                {maritalList?.map((option) => (
                  <Option key={option._id} value={option.Marital}>
                    {option.Marital}
                  </Option>
                ))}
              </Select>
              <br />

              {errors.maritalStatus && (
                <span className="input-error">status required</span>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Email Id </label>
              <input
                value={userData?.email}
                className="Professional__UpdateProfile__Input"
                {...register("email", { required: true })}
              />
              <br />
              {/* {errors.email && (
                <span className="input-error">Email required</span>
              )} */}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Address</label>
              <textarea
                value={userData?.address}
                rows="1"
                className="Professional__UpdateProfile__Input"
                {...register("address", { required: true })}
              />

              {errors.address && (
                <span className="input-error">Address required</span>
              )}
            </Col>

            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Country </label>

              <Select
                showSearch
                className="input-field"
                value={userData?.country}
                onChange={(value) => {
                  setCountryValue(value);
                  getState(value);
                }}
                filterOption={(input, option) => {
                  return option?.label
                    ?.toLowerCase()
                    .includes(input.toLowerCase());
                }}
                optionLabelProp="label"
              >
                {countryList.map((country) => (
                  <Option
                    key={country.id}
                    value={country.id}
                    label={country.name}
                  >
                    {country.name}
                  </Option>
                ))}
              </Select>

              {errors.country && (
                <p className="error-text-color">country is required</p>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">State</label>
              <Select
                showSearch
                className="input-field"
                value={userData?.state}
                onChange={(value) => getDistrict(value)}
                placeholder="Select a State"
                style={{ border: "none" }}
                filterOption={(input, option) => {
                  return option?.label
                    ?.toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {stateList.map((country) => (
                  <Option
                    key={country.id}
                    value={country.id}
                    label={country.name}
                  >
                    {country.name}
                  </Option>
                ))}
              </Select>
              <br />
              {errors.State && (
                <span className="input-error">State is required</span>
              )}
            </Col>

            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">District</label>

              <Select
                showSearch
                className="input-field"
                value={userData?.district}
                onChange={(value) => getCity(value)}
                placeholder="Select a Gender"
                style={{ border: "none" }}
                filterOption={(input, option) => {
                  return option?.label
                    ?.toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {districtList.map((country) => (
                  <Option
                    key={country.id}
                    value={country.id}
                    label={country.name}
                  >
                    {country.name}
                  </Option>
                ))}
              </Select>
              <br />
              {errors.districtValue && (
                <span className="input-error">District is required</span>
              )}
            </Col>
            <Col sm={24} md={8} className="px-4">
              <label>City</label>

              <Select
                showSearch
                className="input-field"
                value={userData?.city}
                onChange={(value) => setCityValue(value)}
                placeholder="Select a City"
                style={{ border: "none" }}
                filterOption={(input, option) => {
                  return option?.label
                    ?.toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {cityList.map((country) => (
                  <Option
                    key={country.id}
                    value={country.id}
                    label={country.cityName}
                  >
                    {country.cityName}
                  </Option>
                ))}
              </Select>
              {errors.city && <p className="text-danger">City is required</p>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Pincode</label>

              <input
                type="number"
                className="Professional__UpdateProfile__Input"
                {...register("pincode", { required: true })}
              />

              {errors.pincode && (
                <span className="input-error">Pincode is required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Upload Resume</label>

              <input
                type="file"
                alt=""
                className="Professional__UpdateProfile__Input"
                onChange={handleChange}
                {...register("resume", { required: true })}
              />

              {errors.resume && (
                <span className="input-error">Resume is required</span>
              )}
            </Col>
          </Row>

          <div className="submitbuttons px-4">
            <button className="button1 m-2 p-2" type="submit">
              Submit
            </button>
            <button className="button2 m-2 p-2" type="reset">
              Reset
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default MyProfile;
