// import React from "react";
// import { NavLink } from "react-router-dom";
// import { MdPerson, MdMenuBook } from "react-icons/md";
// import { FaMoneyBillTransfer } from "react-icons/fa6";
// import { FaBlogger } from "react-icons/fa";
// import "../../../Components/Css/Students/Student.scss";
// import { Menu } from "antd";

// import { CgProfile } from "react-icons/cg";

// function Sidebar({ collapsed }) {
//   const onClick = (e) => {
//     console.log("click ", e);
//   };
//   const role = localStorage.getItem("name");
//   const id = localStorage.getItem("regid");

//   return (
//     <>
//       <div className={collapsed === true ? "sidebarcontent open" : "d-none"}>
//         <Menu onClick={onClick} mode="inline" className="nav-list">
//           <NavLink
//             to="/trainer/trainerdashboard"
//             activeClassName="main-nav-style"
//           >
//             <div className="Nav-Icon2">
//               <CgProfile />
//             </div>
//             <div className="name-tag">{role}</div>
//             <div className="name-tag">{id}</div>
//           </NavLink>

//           <NavLink to="/trainer/trainerprofile" ClassName="main-nav-style">
//             <MdPerson className="Nav-Icon" />
//             <p className="side-title"> My Profile</p>
//           </NavLink>

//           <NavLink to="/trainer/trainerprofile" ClassName="main-nav-style">
//             <MdPerson className="Nav-Icon" />
//             <p className="side-title"> Courses</p>
//           </NavLink>

//           <NavLink
//             to="/professional/ProfessionalBlog"
//             ClassName="main-nav-style"
//           >
//             <FaBlogger className="Nav-Icon" />
//             <p className="side-title"> Blog</p>
//           </NavLink>

//           <NavLink to="/professional/CoursePackage" ClassName="main-nav-style">
//             <MdMenuBook className="Nav-Icon" />
//             <p className="side-title"> Package Details</p>
//           </NavLink>
//           <NavLink to="/trainer/transactionHistory/transactionHistory" ClassName="main-nav-style">
//             <FaMoneyBillTransfer className="Nav-Icon" />
//             <p className="side-title"> Transaction History</p>
//           </NavLink>
//         </Menu>
//       </div>
//     </>
//   );
// }

// export default Sidebar;

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  MdPerson,
  MdAreaChart,
  MdRequestPage,
  MdSend,
  MdMenuBook,
  MdDetails,
} from "react-icons/md";
import { AiFillProject } from "react-icons/ai";
import { FaBlogger } from "react-icons/fa";
import { GrCertificate } from "react-icons/gr";
import { CgProfile } from "react-icons/cg";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { SiBookstack } from "react-icons/si";
import { AiOutlineFileDone } from "react-icons/ai";
import "../../../Components/Css/Sidebar.scss";
import SidebarToggle from "../../Student/DefaultLayout/SidebarToogle";
import Api from '../../../Api.js'

function Sidebar() {
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [isRightSidebarCollapsed, setIsRightSidebarCollapsed] = useState(true);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [userData, setUserData] = useState({});
  const userId = localStorage.getItem("userId");

  const toggleRightSidebar = () => {
    setIsRightSidebarCollapsed((prev) => !prev);
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prev) => !prev);
  };

  const handleSubmenuClick = (submenu) => {
    if (submenu === activeSubmenu) {
      setActiveSubmenu(null);
      setIsRightSidebarCollapsed(true);
    } else {
      setActiveSubmenu(submenu);
      setIsRightSidebarCollapsed(false);
    }
  };

  const getName = () => {
    Api.get(`trainer/Trainergetone/${userId}`)
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => {
        console.error("Error fetching user data:", err);
      });
  };

  useEffect(() => {
    getName();
  }, [userId]);

  const hasSubmenuItems = () => activeSubmenu === "Course";

  return (
    <div className={`sidebar-container ${isSidebarCollapsed ? "collapsed" : ""}`}>
      <SidebarToggle toggleSidebar={toggleSidebar} isSidebarCollapsed={isSidebarCollapsed} />

      {!isSidebarCollapsed && (
        <div className="sidebar-content">
          <div className="nav-list-sidebar">
            <NavLink
              to="/trainer/trainerdashboard"
              className={({ isActive }) => (isActive ? "active nav-item" : "nav-item")}
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <div className="profile-icon">
                <CgProfile />
              </div>
              <div className="profile-details">
                <div className="profile-role">{userData.firstName}</div>
                <div className="profile-id">{localStorage.getItem("regid")}</div>
              </div>
            </NavLink>
            <NavLink
              to="/trainer/trainerprofile"
              className={({ isActive }) => (isActive ? "active nav-item" : "nav-item")}
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdPerson className="nav-icon" />
              <span className="side-title">My Profile</span>
            </NavLink>
            <div
              className="nav-item"
              onClick={() => handleSubmenuClick("Course")}
            >
              <GrCertificate className="nav-icon" style={{ fontSize: "22px", marginLeft: "6px" }} />
              <span className="side-title">Course</span>
            </div>
            <NavLink
              to="/trainer/Blog/BlogTable"
              className={({ isActive }) => (isActive ? "active nav-item" : "nav-item")}
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <FaBlogger className="nav-icon" />
              <span className="side-title">Blog</span>
            </NavLink>
            <NavLink
              to="/trainer/transactionHistory/transactionHistory"
              className={({ isActive }) => (isActive ? "active nav-item" : "nav-item")}
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <FaMoneyBillTransfer className="nav-icon" />
              <span className="side-title">Transaction History</span>
            </NavLink>
          </div>
        </div>
      )}

      {hasSubmenuItems() && (
        <div className={`right-sidebar ${isRightSidebarCollapsed ? "collapsed" : ""}`}>
          <div className="right-sidebar-header">
            <div className="collapse-btn" onClick={toggleRightSidebar}>
              {isRightSidebarCollapsed ? ">" : "<"}
            </div>
          </div>
          <NavLink to="/trainer/courses/courses-list" className="submenu-item">
            <SiBookstack className="nav-sub-icon" />
            <span className="sub-side-title">Course List</span>
          </NavLink>
          <NavLink to="/trainer/courses/approved-list" className="submenu-item">
            <AiOutlineFileDone className="nav-sub-icon" />
            <span className="sub-side-title">Approved Courses</span>
          </NavLink>
        </div>
      )}
    </div>
  );
}

export default Sidebar;
