import React, { useState } from "react";
import { Layout } from "antd";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import Sidebar from "./sidebar/sidebar";
import careerblitzIcon from "../../../Images/FerilNew2.png";
// import careerblitz from "../../Images/careerblitzNew2.png"
import { HiUserCircle } from "react-icons/hi";
import "../../../Components/Css/Sidebar.scss";
const { Content } = Layout;

function Defaultlayout() {
  // const [collapsed, setCollapsed] = useState(true);

  // const handleChange = () => {
  //   setCollapsed(!collapsed);
  // };
  const [collapsed, setCollapsed] = useState(true);

  const handleChange = () => {
    if (window.innerWidth < 720) {
      setCollapsed(!collapsed);
    } else {
      setCollapsed(collapsed);
    }
  };
  const navigate = useNavigate();

  // const UserName = localStorage.getItem("name");

  const [logout, setLogout] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("resize", handleChange);
    if (!localStorage.getItem("USER_AUTH_STATE")) navigate("/login");
  }, [logout]);
  const logoutHandler = (e) => {
    e.preventDefault();
    localStorage.removeItem("USER_AUTH_STATE");
    localStorage.removeItem("role");
    localStorage.removeItem("id");
    localStorage.removeItem("name");
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("regid");
    localStorage.removeItem("userId");
    localStorage.removeItem("companyName");

    setLogout(true);
  };
  return (
    <div>
      <div onChange={handleChange}>
        <div className="dashboards__headerNavs">
          <div className="dashboards__headerNavs--container">
            <div>
              <span
                className="navMenu p-2 "
                onClick={() => setCollapsed((prev) => !prev)}
              >
                {collapsed ? <AiOutlineClose /> : <GiHamburgerMenu />}
              </span>
              <img
                className="careerblitz_icon"
                src={careerblitzIcon}
                alt="add"
                onClick={() => navigate("/")}
              />
              <span className="logo" onClick={() => navigate("/")}></span>
            </div>
            <div className="logoutAvatar" onClick={logoutHandler}>
              <HiUserCircle
                style={{ fontSize: 45, cursor: "pointer", color: "grey" }}
              />
            </div>
        
          </div>
        </div>
      </div>
      <Sidebar collapsed={collapsed} />
      <div
        className={collapsed === true ? "main-content open" : "main-content"}
      >
        <Outlet />
      </div>
    </div>
  );
}

export default Defaultlayout;
