import React from "react";
import PropTypes from "prop-types";
import "./CustomButton.css";

const CustomButton = ({
  label = "Click Me",
  className = "",
  style = {},
  onClick = () => {}  // Provide default no-op
}) => {
  return (
    <button
      className={`button-54 ${className}`}
      style={style}
      role="button"
      onClick={onClick}  // Invoke onClick function directly
    >
      {label}
    </button>
  );
};

// Prop types for type safety
CustomButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
};

export default CustomButton;
