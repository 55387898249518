import React, { useState, useEffect } from "react";
import "../../../Components/Css/Employer/projectpost.scss";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import './Package.scss';



function Company() {
  const [institute, setInstitute] = useState([]);
  const [instituteValue, setInstituteValue] = useState();
  const [selectedPackage, setSelectedPackage] = useState(null);
  const packages = [
    { name: 'Classic', code: 'NY' },
    { name: 'Gold', code: 'RM' },
    { name: 'Platinum', code: 'LDN' },
    { name: 'Excutive', code: 'PRS' }
  ];

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const handleCreateFormSubmit = async () => {
    const userDetails = {
      PackageName: getValues().PackageName,
      FetchClients: getValues().FetchClients,
      BusinessPromotion: getValues().BusinessPromotion,
      Advertisment: getValues().Advertisment,
      Freelancing: getValues().Freelancing,
      Training: getValues().Training,
      ActualPrice: getValues().ActualPrice,
      OfferPrice: getValues().OfferPrice,

    };
    await Api.post(`Student/createVendormodel`, userDetails).then((resp) => {
      console.log("resp.data", resp.data);
    });
  };

 

  return (
    <div>
      <Container className=" content-style ">
        <Row style={{ marginLeft: "5%" }} className="package_page">
          <form onSubmit={handleSubmit(handleCreateFormSubmit)}>
            <br />
            <div className="pages-title mt-3">Create Packages:</div>
            <Row className="mt-4">
              <Col sm={12} lg={4}>
                <label className="create-title">Package Name</label>
                <Dropdown 
                  // editable placeholder="Select a City" className="w-full md:w-14rem" />
                  className="Create-input" 
                  {...register("PackageName", { required: true })}
                  value={selectedPackage} onChange={(e) => setSelectedPackage(e.value)} options={packages} optionLabel="name"
/>
                {errors.PackageName && (
                  <p className="error-text-color">PackageName is required</p>
                )}


              </Col>
              <Col sm={12} lg={4}>
                <div style={{ marginTop: "5%" }}> 
                <Row>
                      <Col>
                  <p className="classic">
                    Fetch Clients </p></Col>
                    <Col className="classics">   <input type="number" name="name" className="classic_right " 
                                      {...register("FetchClients", { required: true })}
                                      />
                    </Col>
                  </Row>
                  <Row>
                  <Col>
                  <p className="classic">
                  Business Promotion</p></Col>
                    <Col className="classics">
                  <input type="number" name="name"  className="classic_right " 
                                    {...register("BusinessPromotion", { required: true })}
                                    />
                  </Col>
                  </Row>
                    <Row>
                      <Col>
                  <p className="classic">
                   Advertisment </p></Col>
                    <Col className="classics">   <input type="number" name="name"  className="classic_right " 
                                                        {...register("Advertisment", { required: true })}
                                                        />
                    </Col>
                  </Row>
                  <Row>
                  <Col>
                  <p className="classic">
                    Freelancing</p></Col>
                    <Col className="classics">
                  <input type="number" name="name"  className="classic_right" 
                                                      {...register("Freelancing", { required: true })}
                                                      />
                  </Col>
                  </Row>
                  <Row>
                    <Col>
                  <p className="classic">
                    Training</p></Col>
                    <Col className="classics">  <input type="number" name="name"  className="classic_right " 
                                                        {...register("Training", { required: true })}
                                                        />
                  </Col>  </Row>
                  <Row>
                    <Col>
                  <p className="classic">
                    Actual Price</p></Col>
                    <Col className="classics">  <input type="number" name="name"  className="classic_right " 
                                                        {...register("ActualPrice", { required: true })}
                                                        />
                  </Col>  </Row>
                  <Row>
                    <Col>
                  <p className="classic">
                    Offer Price</p></Col>
                    <Col className="classics">  <input type="number" name="name"  className="classic_right " 
                                                        {...register("OfferPrice", { required: true })}
                                                        />
                  </Col>  </Row>
                </div>
              </Col>

            </Row>
            <br />
            <div className="submitbuttons p-2">
              <button
                className="button1 m-2 p-2"
                type="submit"
              // onClick={handleCreateFormSubmit}
              >
                Submit
              </button>
              <button className="button2 m-2 p-2" type="reset">
                Reset
              </button>
            </div>
          </form>
        </Row>
      </Container>
    </div>
  );
}

export default Company;
