// import React, { useState } from "react";
// import { useForm } from "react-hook-form";
// import { Row, Col } from "antd";
// import { Form } from "react-bootstrap";
// import "./CompanyListForm.css";
// import Api from "../../../Api";
// import { useNavigate } from "react-router-dom";

// function CompanyListForm() {
//   const {
//     register,
//     handleSubmit,
//     getValues,
//     formState: { errors },
//   } = useForm();
//   const onSubmit = () => {
//     handleCompanySubmit();
//   };

//   const [resume, setResume] = useState({});

//   const [resres, setResres] = useState({
//     status: null,
//     message: null,
//   });
//   const navigate = useNavigate();

//   const handleCompanySubmit = async () => {
//     const CompanyDetails = {
//       Title: getValues().Companytitle,
//       Description: getValues().Companydescription,
//     };
//     const data = new FormData();
//     data.append("resume", getValues().resume[0]);
//     console.log("getValues(", getValues().resume[0]);
//     console.log("getValuessssssssssssssssssss(", getValues().resume);

//     await Api.post("companylist/uploadImage", data).then(async (res) => {
//       if (res.status == 201) {
//         CompanyDetails.resume = res.data.path;
//         await Api.post(`companylist/createCompanyList`, CompanyDetails)
//           .then((res) => {
//             setResres({
//               status: res.data?.status,
//               message: res.data?.message,
//             });
//             localStorage.setItem("userId", res.data.data._id);
//             setTimeout(() => {
//               navigate("/login");
//             }, 700);
//           })
//           .catch((err) => {
//             setResres({
//               status: err?.response.data?.status,
//               message: err?.response.data?.message,
//             });
//           });
//     }})
//       // const uploadFile = async()=>{
//       //   const formData = new FormData();
//       //   formData.append('file', file);
//       //   formData.append('fileName', fileName);
//       //   try{
//       //        Api.post('http://localhost:3000/upload',
//       //        formData
//       //        )
//       //   }catch(ex){

//       //   }

//       //           }

//       // const SelectFile = async (event) => {
//       //   const file = event.target.files[0];
//       //   const type = file?.type?.split("/")[0];
//       //   const base64 = await convertBase64(file);
//       //   setImage(base64);
//       // };

//       // const convertBase64 = (file) => {
//       //   return new Promise((resolve, reject) => {
//       //     const fileReader = new FileReader();
//       //     fileReader.readAsDataURL(file);
//       //     fileReader.onload = () => {
//       //       resolve(fileReader.result);
//       //     };
//       //     fileReader.onerror = (error) => {
//       //       reject(error);
//       //     };
//       //   });
//       // };

//       const handleCompanySubmit = async () => {
//         const CompanyDetails = {
//           Title: getValues().Companytitle,
//           Description: getValues().Companydescription,
//           // Image: image,
//         };
//         console.log("CompanyDetails", CompanyDetails);

//         await Api.post(`companylist/createCompanyList`, CompanyDetails).then((resp) => {
//           console.log("resp.data", resp.data);
//         });
//       };

//   return (
//     <div>
//       <form onSubmit={handleSubmit(handleCompanySubmit)}>
//         <div className="Company-form p-5 ms-5">
//           <div className="pages-title mb-3">Company List</div>
//           <Row className="mb-3">
//             <Col md={24} lg={12} className="p-2">
//               <label className="input-title pe-5">Company Title</label>
//               <br />
//               <input
//                 style={{ width: "85%", outline: "none" }}
//                 className="Company-input"
//                 {...register("Companytitle", { required: true })}
//               />
//               <br />
//               {errors.Companytitle && (
//                 <span className="Company-error">Company Title required</span>
//               )}
//             </Col>
//           </Row>
//           <Row className="mb-3">
//             <Col sm={24} md={24} lg={12} className="p-2">
//               <label className="input-title pe-5">Company Description</label>
//               <br />
//               <textarea
//                 style={{ width: "85%", outline: "none" }}
//                 rows="1"
//                 className="Company-textArea p-2"
//                 {...register("Companydescription", { required: true })}
//               />
//               <br />
//               {errors.Companydescription && (
//                 <span className="Company-error">
//                   Company Description required
//                 </span>
//               )}
//             </Col>
//           </Row>
//           <Row>
//             <Col sm={24} md={24} lg={12} className="p-2">
//               <label className="input-title pe-5">Company Image </label>
//               <Form.Group controlId="Resume" className="mb-3 py-2">
//                 <Form.Label>Image</Form.Label>
//                 <Form.Control
//                   accept=".png"
//                   type="file"
//                   {...register("resume")}
//                   onChange={(e) => {
//                     setResume(e.target.files[0]);
//                     console.log("e.target.files[0]", e.target.files[0]);
//                   }}
//                 />
//               </Form.Group>
//               {errors.resume && (
//                 <span className="text-danger">Company Logo is required</span>
//               )}
//             </Col>
//           </Row>
//           <div className="submitbuttons p-2">
//             <button
//               className="button1 m-2 p-2"
//               type="submit"
//               onClick={handleSubmit(onSubmit)}
//             >
//               Submit
//             </button>
//             <button className="button2 m-2 p-2" type="reset">
//               Reset
//             </button>
//           </div>
//         </div>
//       </form>
//     </div>
//   );
// }}

// export default CompanyListForm;

import React, { useState, useEffect } from "react";
import { Table, Input, Button, Popconfirm } from "antd";
import "../DashboardTablelist/Studentlist.scss";
import { BsCheckLg, BsFillPersonPlusFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Api from "../../../Api";
import moment from "moment";

function Companylist() {
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState([]);
  const [companyList2, setCompanyList2] = useState([]);
  const data = companyList2.map((item) => item);

  const userId = localStorage.getItem("userId");

  function createdAtSorter(a, b) {
    if (a.createdAt < b.createdAt) {
      return -1;
    }
    if (a.createdAt > b.createdAt) {
      return 1;
    }
    return 0;
  }

  const columns = [
    {
      title: "Registration Id",
      dataIndex: "registerId",
    },
    {
      title: "Student Name",
      dataIndex: "domain",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    // {
    //   title: "Created At",
    //   dataIndex: "createdAt",
    //   sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    //   render: (text, record) =>
    //     new Date(record.createdAt).toLocaleDateString("en-GB"),
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div>
          {record.status === true ? (
            <Button
              type=""
              className="data-list-button"
              onClick={() => navigate(`/admin/student/studentblog/${record?._id}`)}>
              {" "}
              Approved
            </Button>
          ) : (
            <Button
              type=""
              //  className="data-list-button"
              onClick={() =>
                navigate(`/admin/student/studentblog/${record?._id}`)
              }
            >
              Click To Approve/Reject
            </Button>
          )}
        </div>
      ),
    },
  ];

  // const getCompany = async () => {
  //   await Api.get("companylist/getCompanyList").then((res) => {
  //     setCompanyList(res.data.data);
  //     console.log("Companyshow", companyList);
  //   });
  // };

  const getCompany2 = async () => {
    await Api.get("blog/getBloglist").then((res) => {
      console.log("blogsss", res.data.data);
      setCompanyList2(res.data.data);
    });
  };

  useEffect(() => {
    getCompany2();
    const results = companyList2.filter(
      (item) =>
        item?.companyName
          ?.toLowerCase()
          .includes(searchText.toLowerCase().trim()) ||
        item?.RegistrationId?.toLowerCase().includes(
          searchText.toLowerCase().trim()
        )
    );
    setSearch(results);
  }, [searchText]);

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  let dataCount = companyList2.length;

  return (
    <div className="data-list-main">
      <h4 className="data-list-header pages-title">Blog List</h4>
      <h4 className=" pages-title">Total Records : {dataCount}</h4>
      <div className="data-list-top mt-4 py-4 px-3">
        <Input
          // className="data-list-search ps-2"
          placeholder="search by Blog"
          onChange={handleChange}
          enterButton
        />

        <Button
          // className="data-list-button"
          onClick={() => navigate("/admin/studentblog/approvedblog")}
        >
          {" "}
          Approved Blogs{" "}
        </Button>
      </div>
      {searchText ? (
        <div className="data-list-table">
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={search}
            pagination={false}
            className="mt-2"
          />
        </div>
      ) : (
        <div className=".Datalist-Table">
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={companyList2}
            pagination={false}
            className="mt-2"
          />
        </div>
      )}
    </div>
  );
}

export default Companylist;
