import React, { useState, useEffect } from 'react';
import { Tabs, message } from 'antd';
import CreateTechnology from './CreateTechnology';
import CreateExamQuestion from './CreateExamQuestion';
import CreateExamPattern from './CreateExamPattern';
import Api from "../../../Api.js";

const { TabPane } = Tabs;

const ExamFields = () => {
  const [technologies, setTechnologies] = useState([]);
  const [courses, setCourses] = useState([]);
  const [courseTechnologies, setCourseTechnologies] = useState([]); 
  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    // Fetching all technologies
    Api.get('/exam/getalltechnology')
      .then(res => setTechnologies(res.data))
      .catch(err => message.error('Failed to load technologies'));

    // Fetching all courses
    Api.get('/courses/getAllCourses')
      .then(res => setCourses(res.data))
      .catch(err => message.error('Failed to load courses'));
  }, []);

  const handleCourseChange = (courseId) => {
    const selectedCourse = courses.find(course => course._id === courseId);
    
    if (selectedCourse) {
      setCourseTechnologies(technologies.filter(tech => selectedCourse.weeks.some(week => week.technologies.includes(tech._id))));
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
        <TabPane tab="Create Technology" key="1">
          <CreateTechnology />
        </TabPane>
        <TabPane tab="Create Exam Question" key="2">
          <CreateExamQuestion courseTechnologies={courseTechnologies} />
        </TabPane>
        <TabPane tab="Create Exam Pattern" key="3">
          <CreateExamPattern courses={courses} handleCourseChange={handleCourseChange} courseTechnologies={courseTechnologies} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ExamFields;
