import React, { useEffect, useState, useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Button } from "antd";
import "../../../../Components/Css/Professional/professionalstyle.scss";
import { Dropdown } from "primereact/dropdown";
import Api from "../../../../Api";
import { Container } from "react-bootstrap";
import "../../../../Components/Css/Admin/StudentList.scss";
import { RiDeleteBin2Fill} from "react-icons/ri";
import { BiPlusMedical} from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";


function PersonalInfo() {
  const { state: locationState } = useLocation();
  console.log('locationState', locationState)
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({ defaultValues: locationState ? locationState.ele : {} });
  
  const navigate = useNavigate();
  const [selectedTechnologys, setSelectedTechnologys] = useState(null);
  const [countryList, setcountryList] = useState([]);
  const [countryValue, setCountryValue] = useState();
  const [stateList, setstateList] = useState([]);
  const [noticeList, setNoticeList] = useState([]);
  const [stateValue, setStateValue] = useState();
  const [districtList, setdistrictList] = useState([]);
  const [districtValue, setDistrictValue] = useState();
  const [genderList, setGenderList] = useState();
  const [gender, setGender] = useState();
  const [maritalList, setMaritalList] = useState();
  const [Marital, setMarital] = useState();
  const [verify, setVerify] = useState(false);
  const [Check, setCheck] = useState(false);
  const [companyType, setCompanyType] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [cityList, setCityList] = useState([]);
  const [cityvalue, setCityValue] = useState();
  const [addForm, setAddForm] = useState([
    {
      name: "",
      Technologys: "",
      projects: "",
    },
  ]);

  const Technologys = [
    {label: "React", value: "React" },
    { label: "Node js", value: "Node" },
    { label: "Java", value: "Java" },
    { label: "React Native", value: "React Native" },
    { label: "Angular  ", value: "Angular" },
  ];
  const cities = [
    { name: "", code: "" },
    { name: "Ambattur", code: "Am" },
    { name: "Chromepet", code: "RM" },
    { name: "Triplicane", code: "LDN" },
    { name: "Vadapalani", code: "IST" },
    { name: "Perambur", code: "Pr" },
  ];
  useEffect(() => {
    getDetails();
    getCountry();
    getGender();
    getMarital();
  }, []);

  const getMarital = async () => {
    await Api.get("marital/getMarital").then((res) => {
      setMaritalList(res.data.data);
    });
  };

  const getGender = async () => {
    await Api.get("gender/getGenderlist").then((res) => {
      setGenderList(res.data.data);
    });
  };
  const getCountry = async () => {
    await Api.get("country/getallcountry").then((res) => {
      setcountryList(res.data.data);
    });
  };

  const getState = (country_id) => {
    setCountryValue(country_id);
    // console.log('country_id@@@@@@1', country_id);
    Api.get(`state/stateById/${country_id}`).then((res) => {
      // console.log('res44444 ', res);
      setstateList(res.data.data);
      console.log("res.data.data#####", res.data.data);
    });
  };

  const getDistrict = (state_id) => {
    setStateValue(state_id);
    Api.get(`district/districtById/${state_id}`).then((res) => {
      setdistrictList(res.data.data);
      console.log("res.data.data###111##", res.data.data);
    });
  };
  const getCity = (districtId) => {
    setDistrictValue(districtId);
    console.log("districtId@@@", districtId);

    Api.get(`city/cityById/${districtId}`).then((res) => {
      console.log("res@@@", res);
      setCityList(res.data.data);
      console.log("res.data.data@@@@@ ", res.data.data);
    });
  };

  const getDetails = async () => {
    const userId = localStorage.getItem("userId");
    console.log("userId", userId);

    await Api.get(`employeeUser/employee_getone/${userId}`).then((res) => {
      setValue("firstname", res.data.data?.firstName);
      setValue("lastname", res.data.data?.LastName);
      setValue("phoneNumber", res.data.data?.phone);
    });
  };

  // const handleFormSubmit = async (data) => {
  //   console.log("data@@ ", data);
  //   const Details = {
  //     AadharNumber: getValues().AadharNumber,
  //     CompanyAddress: getValues().CompanyAddress,
  //     CompanyLocation: getValues().CompanyLocation,
  //     CompanyName: getValues().CompanyName,
  //     nameOftheOffice: getValues().nameOftheOffice,
  //     dataOfJoing: getValues().dataOfJoing,
  //     Designation: getValues().Designation,
  //     EmployeeIdNumber: getValues().EmployeeIdNumber,
  //     FromDate: getValues().FromDate,
  //     Marital: getValues().Marital,
  //     Todate: getValues().Todate,
  //     address: getValues().address,
  //     alternativeNumber: getValues().alternativeNumber,
  //     cityValue: getValues().cityValue,
  //     country: getValues().country,
  //     dateofBirth: getValues().dateofBirth,
  //     districtValue: getValues().districtValue,
  //     emailId: getValues().emailId,
  //     firstName: getValues().firstName,
  //     gender: getValues().gender,
  //     lastName: getValues().lastName,
  //     panNumber: getValues().panNumber,
  //     passportExpiryDate: getValues().passportExpiryDate,
  //     passportIssueDate: getValues().passportIssueDate,
  //     passportNumber: getValues().passportNumber,
  //     phoneNumber: getValues().phoneNumber,
  //     pincode: getValues().pincode,
  //     stateValue: getValues().stateValue,
  //   };

  //   await Api.post(
  //     `ResourceContactorStaffing/ResourcesContactorStaffing`,
  //     Details
  //   ).then((resp) => {
  //     console.log("resp.data", resp.data);
  //   });
  // };



  const handleFormSubmit = (data) => {
    try {
      const Details = {
        AadharNumber: getValues().AadharNumber,
        CompanyAddress: getValues().CompanyAddress,
        CompanyLocation: getValues().CompanyLocation,
        CompanyName: getValues().CompanyName,
        nameOftheOffice: getValues().nameOftheOffice,
        dataOfJoing: getValues().dataOfJoing,
        Designation: getValues().Designation,
        EmployeeIdNumber: getValues().EmployeeIdNumber,
        FromDate: getValues().FromDate,
        Marital: getValues().Marital,
        Todate: getValues().Todate,
        address: getValues().address,
        alternativeNumber: getValues().alternativeNumber,
        cityValue: getValues().cityValue,
        country: getValues().country,
        dateofBirth: getValues().dateofBirth,
        districtValue: getValues().districtValue,
        emailId: getValues().emailId,
        firstName: getValues().firstName,
        gender: getValues().gender,
        lastName: getValues().lastName,
        panNumber: getValues().panNumber,
        passportExpiryDate: getValues().passportExpiryDate,
        passportIssueDate: getValues().passportIssueDate,
        passportNumber: getValues().passportNumber,
        phoneNumber: getValues().phoneNumber,
        pincode: getValues().pincode,
        stateValue: getValues().stateValue,
      };
      
      if (locationState) {
        //update
        Api.put(`ResourceContactorStaffing/putContactorStaffing/${locationState.ele._id}`, data).then((res) => {
          if(res.status === 200 ){
            toast.success(res.data.message,{
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            })
            
          }
          else{
toast.error("Edit failed",{ position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,})
          }
          // navigate(-1)
        })
      } else {
        //create
        Api.post(`ResourceContactorStaffing/ResourcesContactorStaffing`,
        Details)
          .then((resp) => {
            navigate(-1)
            console.log("111111", resp);
          })
          .catch((resp) => {
            console.log("error");
          });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong,please try again!!!",
        life: 3000,
      });
    }
  };
  //////////
  const [file, setFile] = useState();
  function handleChange(e) {
    console.log('e', e)
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  //
  const noticeOptions =
    noticeList &&
    noticeList.map((list) => {
      return { label: list?.noticePeriod, value: list?.noticePeriod };
    });
  const handleAddQuestion = () => {
    setAddForm((prev) => [
      ...prev,
      {
        name: "",
        Technologys: "",
        projects: "",
      },
    ]);
  };
  const handleoption = useCallback((index, key, value) => {
    setAddForm((prev) => {
      const newValue = [...prev];
      newValue[index][key] = value;
      console.log("newValue :>> ", newValue);
      return newValue;
    });
  }, []);
  // remove option
  const handleRemove = useCallback((idx, setValue) => {
    setAddForm((prev) => {
      const newOne = prev.filter((item, index) => index !== idx);
      setValue("addForm", newOne);
      return newOne;
    });
  }, []);
  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="personal-informations p-4">
          <div className="pages-title mb-3">Contactor Staffing Info</div>
          <Row className="mb-4">
            <Col sm={24} md={24} xs={8} className="px-4">
              <div>
                <img
                  src={file}
                  style={{
                    width: "200px",
                    height: "200px",
                  }}
                />
              </div>
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <br />
              <label className="input-title">Upload Photo</label>
              <br />
              <input
                type="file"
                onChange={handleChange}
                {...register("Photo", { required: true })}
              />
              {errors.Photo && (
                <span className="input-error">Photo is required</span>
              )}
            </Col>
          </Row>
          {/*  */}
          <div className="px-4 mb-3">
            <label>Type</label>

            <div className="px-4">
              <input
                type="radio"
                value="Experience"
                name="Candidates"
                onClick={() => setCompanyType(!companyType)}
              />
              &nbsp;&nbsp;&nbsp; Company
              <div>
                {companyType ? (
                  <div>
                    <Row className="mb-4">
                      <Col sm={24} md={8} xs={24} className="px-4">
                        <label className="input-title">Company Name</label>
                        <br />
                        <input
                          className="Professional__UpdateProfile__Input"
                          {...register("nameOftheOffice", { required: true })}
                        />
                        <br />
                        {errors.nameOftheOffice && (
                          <span className="input-error">
                            Company Name required
                          </span>
                        )}
                      </Col>
                      <Col sm={24} md={8} xs={24} className="px-4">
                        <label className="input-title">Comapny Address</label>
                        <textarea
                          rows="1"
                          className="Professional__UpdateProfile__Input"
                          {...register("CompanyAddress", { required: true })}
                        />

                        {errors.CompanyAddress && (
                          <span className="input-error">
                            Company Address required
                          </span>
                        )}
                      </Col>
                      <Col sm={24} md={8} xs={24} className="px-4">
                        <label className="input-title">Company Location</label>{" "}
                        <br></br>
                        <Dropdown
                          filter
                          className="input-field"
                          {...register("CompanyLocation", { required: true })}
                          value={selectedCity}
                          onChange={(e) => setSelectedCity(e.value)}
                          options={cities}
                          optionLabel="name"
                          placeholder="Select a City"
                        />
                        <br />
                        {errors.CompanyLocation && (
                          <span className="input-error">
                            Company Location is required
                          </span>
                        )}
                      </Col>
                    </Row>
                    {/*  */}
                    <h5>Candidates Details</h5>
                    {addForm.map((item, index) => {
                      return (
                        <div>
                        <Row className="mb-4">
                          <Col sm={24} md={8} xs={24} className="px-4">
                            <label className="input-title">Name</label>
                            <br />
                            <input
                              name={`addForm[${index}].name`}
                              className="Professional__UpdateProfile__Input"
                              value={addForm[index].name}
                              {...register(`addForm[${index}].name`, {
                                required: true,
                                onChange: (e) => {
                                  handleoption(index, "name", e.target.value);
                                },
                              })}
                            />
                            <br />
                            {errors &&
                              errors.addForm &&
                              errors.addForm[index] &&
                              errors.addForm[index].name && (
                                <span className="input-error">
                                  Course is required
                                </span>
                              )}
                          </Col>
                          <Col sm={24} md={8} xs={24} className="px-4">
                            <label className="input-title">Technologys</label>
                            <div>
                            <Dropdown
                              filter
                              // value={selectedTechnologys}
                              name={`addForm[${index}].Technologys`}
                              className="input-field"
                              // onChange={(e) => setSelectedTechnologys(e.value)}
                              options={Technologys}
                              value={addForm[index].Technologys}
                              // optionLabel="name"
                              // placeholder="Select a Technologys"
                              {...register(`addForm[${index}].Technologys`, {
                                required: true,
                                onChange: (e) => {
                                  handleoption(
                                    index,
                                    "Technologys",
                                    e.target.value
                                  );
                                },
                              })}
                            />
                            <br/>

                            {errors &&
                              errors.addForm &&
                              errors.addForm[index] &&
                              errors.addForm[index].Technologys && (
                                <span className="input-error">
                                  Mode is required
                                </span>
                              )}
                              </div>
                          </Col>
                          <Col sm={24} md={8} xs={24} className="px-4">
                            <label className="input-title">Project</label>
                            <br />
                            <input
                              name={`addForm[${index}].projects`}
                              className="Professional__UpdateProfile__Input"
                              value={addForm[index].projects}
                              {...register(`addForm[${index}].projects`, {
                                required: true,
                                onChange: (e) => {
                                  handleoption(
                                    index,
                                    "projects",
                                    e.target.value
                                  );
                                },
                              })}
                            />
                            <br />
                            {errors &&
                              errors.addForm &&
                              errors.addForm[index] &&
                              errors.addForm[index].projects && (
                                <span className="input-error">
                                  Course is required
                                </span>
                              )}
                          </Col>
                        </Row>
                         {index > 0 && (
                <Row className="CandidatesDetails_RemoveButton_Container">
                  <div >
                    <button
                      className="ms-4 mt-3 CandidatesDetails_RemoveButton"
                      onClick={() => handleRemove(index, setValue)}
                    >
                     <RiDeleteBin2Fill/> 
                    </button>
                  </div>
                </Row>
              )}
            </div>
                      );
                    })}

                    <div className="AddButtonDetails">
                      <button
                        className="AddCandidates_button"
                        onClick={handleAddQuestion}
                      >
                        <BiPlusMedical/> 
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
              <div>
                <input
                  type="radio"
                  value="Experience"
                  name="Candidates"
                  onClick={() => setCompanyType(false)}
                />
                &nbsp;&nbsp;&nbsp; Individual
              </div>
            </div>
          </div>

          {/*  */}
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Employee Id Number</label> <br />
              <input
                className="Professional__UpdateProfile__Input"
                {...register("EmployeeIdNumber", { required: true })}
              />
              <br />
              {errors.EmployeeIdNumber && (
                <span className="input-error">Employee Id required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">First Name</label> <br />
              <input
                className="Professional__UpdateProfile__Input"
                {...register("firstName", { required: true })}
              />
              <br />
              {errors.firstName && (
                <span className="input-error">First Name required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Last Name</label> <br />
              <input
                className="Professional__UpdateProfile__Input"
                {...register("lastName", { required: true })}
              />
              <br />
              {errors.lastName && (
                <span className="input-error">Last Name required</span>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Phone Number</label> <br />
              <input
                className="Professional__UpdateProfile__Input"
                {...register("phoneNumber", { required: true })}
              />
              {errors.phoneNumber && (
                <span className="input-error"> Number required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Alternative Number </label> <br />
              <input
                className="Professional__UpdateProfile__Input"
                {...register("alternativeNumber", { required: true })}
              />
              <br />
              {errors.alternativeNumber && (
                <span className="input-error">Alternative Number required</span>
              )}
            </Col>

            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">PAN Number</label> <br />
              <input
                className="Professional__UpdateProfile__Input"
                {...register("panNumber", { required: true })}
              />
              <br />
              {errors.panNumber && (
                <span className="input-error"> PAN required</span>
              )}
            </Col>
          </Row>

          {/*  */}

          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Aadhar Number</label> <br />
              <input
                className="Professional__UpdateProfile__Input"
                {...register("AadharNumber", { required: true })}
              />
              <br />
              {errors.AadharNumber && (
                <span className="input-error"> Aadhar Number required</span>
              )}
            </Col>

            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Gender </label>
              <Dropdown
                className="input-field"
                value={gender}
                options={genderList}
                {...register("gender", { required: true })}
                optionLabel={"gender"}
                optionValue={"_id"}
                onChange={(e) => setGender(e.value)}
                placeholder="Select a Gender"
              />
              <br />
              {errors.gender && (
                <span className="input-error">Gender required</span>
              )}
            </Col>

            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Marital status </label>
              <Dropdown
                className="Professional__UpdateProfile__Input"
                value={Marital}
                options={maritalList}
                {...register("Marital", { required: true })}
                optionLabel={"Marital"}
                optionValue={"_id"}
                onChange={(e) => setMarital(e.value)}
                placeholder="Select a Marital status"
              />
              <br />
              {errors.Marital && (
                <span className="input-error">status required</span>
              )}
            </Col>
          </Row>
          {/*  */}
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Date of Birth </label>
              <input
                className="Professional__UpdateProfile__Input"
                type="date"
                {...register("dateofBirth", { required: true })}
              />
              <br />
              {errors.dateofBirth && (
                <span className="input-error">DOB required</span>
              )}
            </Col>

            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Email Id </label>
              <input
                type="email"
                className="Professional__UpdateProfile__Input"
                {...register("emailId", { required: true })}
              />
              <br />
              {errors.emailId && (
                <span className="input-error">Email required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Country </label>
              <Dropdown
                filter
                className="input-field"
                value={countryValue}
                options={countryList}
                {...register("country", { required: true })}
                optionLabel={"name"}
                optionValue={"id"}
                onChange={(e) => getState(e.value)}
              />
              {errors.country && (
                <p className="error-text-color">country is required</p>
              )}
            </Col>
          </Row>
          {/*  */}
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">State</label> <br></br>
              <Dropdown
                filter
                className="input-field p-0"
                value={stateValue}
                options={stateList}
                {...register("stateValue", { required: true })}
                optionLabel={"name"}
                optionValue={"id"}
                onChange={(e) => getDistrict(e.value)}
                placeholder="Select a State"
              />
              <br />
              {errors.stateValue && (
                <span className="input-error">State is required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">District</label>

              <Dropdown
                filter
                className="input-field p-0"
                value={districtValue}
                options={districtList}
                {...register("districtValue", { required: true })}
                optionLabel={"name"}
                optionValue={"id"}
                onChange={(e) => getCity(e.value)}
                placeholder="Select a District"
              />
              <br />
              {errors.districtValue && (
                <span className="input-error">District is required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">City</label> <br></br>
              <Dropdown
                filter
                className="input-field p-0"
                value={cityvalue}
                options={cityList}
                {...register("City", { required: true })}
                optionLabel={"cityName"}
                optionValue={"_id"}
                onChange={(e) => setCityValue(e.value)}
              />
              <br />
              {errors.cityValue && (
                <span className="input-error">City is required</span>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Address</label>
              <textarea
                rows="1"
                className="Professional__UpdateProfile__Input"
                {...register("address", { required: true })}
              />

              {errors.address && (
                <span className="input-error">Address required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">PinCode </label> <br />
              <input
                className="Professional__UpdateProfile__Input"
                {...register("pincode", { required: true })}
              />
              <br />
              {errors.pincode && (
                <span className="input-error">PinCode required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Date of Joining </label>
              <input
                className="Professional__UpdateProfile__Input"
                type="date"
                {...register("dataOfJoing", { required: true })}
              />
              <br />
              {errors.dataOfJoing && (
                <span className="input-error">DOJ required</span>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Upload Resume</label>
              <br />
              <input
                type="file"
                className="Professional__UpdateProfile__Input"
                // onChange={handleChange}
                {...register("resume", { required: true })}
              />

              {errors.resume && (
                <span className="input-error">Resume is required</span>
              )}
            </Col>
          </Row>
          <div className="px-4">
            <label>Passport is Available</label>
            <div className="px-4">
              <p>
                <input
                  type="radio"
                  value="yes"
                  name="Passport"
                  onChange={() => setVerify(!verify)}
                />
                &nbsp;&nbsp;&nbsp; Yes
              </p>
              <h6>
                {verify ? (
                  <div>
                    <Row className="mb-4">
                      <Col sm={24} md={8} xs={24} className="px-4">
                        <label className="input-title">Passport Number</label>{" "}
                        <br />
                        <input
                          className="Professional__UpdateProfile__Input"
                          {...register("passportNumber", { required: true })}
                        />
                        {errors.passportNumber && (
                          <span className="input-error">
                            Passport Numbe required
                          </span>
                        )}
                      </Col>
                      <Col sm={24} md={8} xs={24} className="px-4">
                        <label className="input-title">
                          Passport Issue Date{" "}
                        </label>
                        <input
                          className="Professional__UpdateProfile__Input"
                          type="date"
                          {...register("passportIssueDate", { required: true })}
                        />
                        <br />
                        {errors.passportIssueDate && (
                          <span className="input-error">
                            Passport issue date required
                          </span>
                        )}
                      </Col>

                      <Col sm={24} md={8} xs={24} className="px-4">
                        <label className="input-title">
                          Passport Expiry Date{" "}
                        </label>
                        <input
                          className="Professional__UpdateProfile__Input"
                          type="date"
                          {...register("passportExpiryDate", {
                            required: true,
                          })}
                        />
                        <br />
                        {errors.passportExpiryDate && (
                          <span className="input-error">
                            Passport Expiry Date required
                          </span>
                        )}
                      </Col>
                    </Row>
                  </div>
                ) : null}
              </h6>

              <p>
                <input
                  type="radio"
                  value="No"
                  name="Passport"
                  onChange={() => setVerify(false)}
                />
                &nbsp;&nbsp;&nbsp; No
              </p>
            </div>
          </div>
          <div className="px-4">
            <label>Experience Details</label>
            <Row className="mb-4">
              <Col className="px-4" sm={24} md={5} xs={24}>
                <p>
                  <input
                    type="radio"
                    value="Fresher"
                    name="Candidates"
                    onChange={() => setCheck(false)}
                  />
                  &nbsp;&nbsp;&nbsp; Fresher Candidates
                </p>
              </Col>
              <Col className="px-4" sm={24} md={19}>
                <p>
                  <input
                    type="radio"
                    value="Experience"
                    name="Candidates"
                    onChange={() => setCheck(!Check)}
                  />
                  &nbsp;&nbsp;&nbsp; Experience Candidates
                </p>
                <h6>
                  {Check ? (
                    <div>
                      <Row className="mb-4">
                        <Col sm={24} md={6} xs={24} className="px-4">
                          <label className="input-title">Company Name</label>
                          <br />
                          <input
                            className="Professional__UpdateProfile__Input"
                            {...register("CompanyName", { required: true })}
                          />
                          <br />
                          {errors.CompanyName && (
                            <span className="input-error">
                              Company Name required
                            </span>
                          )}
                        </Col>
                        <Col sm={24} md={6} xs={24} className="px-4">
                          <label className="input-title">Designation</label>
                          <br />
                          <input
                            className="Professional__UpdateProfile__Input"
                            {...register("Designation", { required: true })}
                          />
                          <br />
                          {errors.Designation && (
                            <span className="input-error">
                              Designation required
                            </span>
                          )}
                        </Col>
                        <Col sm={24} md={6} xs={24} className="px-4">
                          <label className="input-title">From Date </label>
                          <input
                            className="Professional__UpdateProfile__Input"
                            type="date"
                            {...register("FromDate", { required: true })}
                          />
                          <br />
                          {errors.FromDate && (
                            <span className="input-error">
                              From Date required
                            </span>
                          )}
                        </Col>
                        <Col sm={24} md={6} xs={24} className="px-4">
                          <label className="input-title">To Date </label>
                          <input
                            className="Professional__UpdateProfile__Input"
                            type="date"
                            {...register("Todate", { required: true })}
                          />
                          <br />
                          {errors.Todate && (
                            <span className="input-error">
                              To Date required
                            </span>
                          )}
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                </h6>
              </Col>
            </Row>
          </div>

          <div className="submitbuttons px-4">
            <button className="button1 m-2 p-2" type="submit">
              Submit
            </button>
            <button className="button2 m-2 p-2" type="reset">
              Reset
            </button>
          </div>
        </div>
        <ToastContainer/>
      </form>
    </>
  );
}

export default PersonalInfo;
