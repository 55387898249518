import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "antd";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { BiRupee } from "react-icons/bi";
import { TfiBag } from "react-icons/tfi";
import { TbWorld } from "react-icons/tb";
import "../../../../Components/Css/Students/Studintdetails.scss";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Api from "../../../../Api";

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = eventKey === activeEventKey;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#EBE6FF",
        paddingTop: "12px",
      }}
    >
      <p onClick={decoratedOnClick}>
        {children}
        {isCurrentEventKey ? <FiChevronUp /> : <FiChevronDown />}
      </p>
    </div>
  );
}

function ConractPostApproved() {
  const [createForm, setcreateForm] = useState([]);
  const id = localStorage.getItem("id");
  const mail = localStorage.getItem("email");
  useEffect(() => {
    getCreateForm();
  }, []);
  const getCreateForm = async () => {
    await Api.get(`contract/getOnePostApproved/${id}`).then((res) => {
      setcreateForm(res.data.data);
    });
  };

  return (
    <div className="p-4">
      <div className="pages-title" styles={{ color: "green" }}>
        Approved
      </div>
      <Row className="p-3">
        {createForm.map((data, i) => (
          <Col sm={24} md={24} lg={12} className="p-3">
            <Accordion defaultActiveKey="0">
              <Card className="Int-card">
                <Card.Body>
                  <h4>{data.para1}</h4>
                  <p className="Int-para1">{data.jobTitle}</p>
                  <p className="Int-para">Comapny Email: {data.Useremail}</p>

                  <p className="Int-para">Hello: {data.companyName}</p>

                  <p className="Int-para">
                    The company will contact EmailID: {mail}
                  </p>
                </Card.Body>
              </Card>
            </Accordion>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default ConractPostApproved;
