import React, { useEffect, useState } from "react";
import { Carousel } from "antd";
import Api from "../../../../../Api";

import { Row, Col } from "react-bootstrap";

import "./FreelancingCarousel.css";
const FreelancingCarousel = () => {
  const [carouselImage, setCarouselImage] = useState([]);
  useEffect(() => {
    getCarouselImage();
    // deleteCompany(); 
  }, []);

  const getCarouselImage = async () => {
    await Api.get("/FreelancingCarousel/FreelancingCarouselget").then((res) => {
      setCarouselImage(res.data.data);
      // console.log("res.data", res.data);
      // console.log("carouselImage", carouselImage);
    });
  };

  return (
    <div>
      <div>
        <Row>
          {/* <Col lg={12} md={12}> */}
          <Carousel autoplay className="carouselContainer_freelancing">
            {carouselImage.map((carouselImage, i) => (
              <div>
                <h1 className="carouselHeading_title">
                  {carouselImage.title}
                  {console.log("carouselImage.title", carouselImage.title)}
                </h1>
                <img
                  width="100%"
                  className="carouselPlayimage"
                  // className="Freelancing_Carousel_container"
                  src={`http://localhost:8080/${carouselImage.image}`}
                  alt=""
                />
              </div>
            ))}
          </Carousel>

          {/* </Col> */}
        </Row>
      </div>
    </div>
  );
};

export default FreelancingCarousel;
