import React, { useState } from "react";
import "../../../Components/Css/Employer/projectpost.scss";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Api from "../../../Api";
import "./Package.scss";

function Company() {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState("Monthly"); // Plan type
  const packages = [
    { name: "Classic", code: "NY" },
    { name: "Gold", code: "RM" },
    { name: "Platinum", code: "LDN" },
  ];

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const handleCreateFormSubmit = async () => {
    const userDetails = {
      planDuration: selectedPlan, // Plan type (Monthly/Yearly)
      packageName: getValues().PackageName,
      jobPost: getValues().JobPost,
      contractStaffing: getValues().ContractStaffing,
      freelancing: getValues().Freelancing,
      actualPrice: getValues().ActualPrice,
      offerPrice: getValues().OfferPrice,
    };

    await Api.post("Student/createcompanymodel", userDetails).then((resp) => {
      console.log("resp.data", resp.data);
    });
  };

  return (
    <div>
      <Container className="content-style">
        <Row style={{ marginLeft: "5%" }} className="package_page">
          <form onSubmit={handleSubmit(handleCreateFormSubmit)}>
            <br />
            <div className="pages-title mt-3">Create Packages:</div>
            <Row className="mt-4">
              {/* Plan Duration Dropdown */}
              <Col sm={12} lg={4}>
                <div style={{ marginTop: "5%" }}>
                  <label className="create-title">Plan Duration</label>
                  <select
                    className="form-select"
                    value={selectedPlan}
                    onChange={(e) => setSelectedPlan(e.target.value)}
                  >
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                </div>
              </Col>

              {/* Package Name Selection */}
              <Col sm={12} lg={4}>
                <label className="create-title">Package Name</label>
                <div className="radio-group">
                  {packages.map((pkg) => (
                    <label key={pkg.code} className="radio-label">
                      <input
                        type="radio"
                        value={pkg.name}
                        {...register("PackageName", { required: true })}
                        checked={selectedPackage === pkg.name}
                        onChange={() => setSelectedPackage(pkg.name)}
                      />
                      {pkg.name}
                    </label>
                  ))}
                </div>
                {errors.PackageName && (
                  <p className="error-text-color">Package Name is required</p>
                )}
              </Col>

              {/* Other Form Fields */}
              <Col sm={12} lg={4}>
                <div style={{ marginTop: "5%" }}>
                  <Row>
                    <Col>
                      <p className="classic">Job Post</p>
                    </Col>
                    <Col className="classics">
                      <input
                        type="text"
                        className="classic_right"
                        {...register("JobPost", { required: true })}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="classic">Contract Staffing</p>
                    </Col>
                    <Col className="classics">
                      <input
                        type="number"
                        className="classic_right"
                        {...register("ContractStaffing", { required: true })}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="classic">Freelancing</p>
                    </Col>
                    <Col className="classics">
                      <input
                        type="number"
                        className="classic_right"
                        {...register("Freelancing", { required: true })}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="classic">Actual Price</p>
                    </Col>
                    <Col className="classics">
                      <input
                        type="number"
                        className="classic_right"
                        {...register("ActualPrice", { required: true })}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="classic">Offer Price</p>
                    </Col>
                    <Col className="classics">
                      <input
                        type="number"
                        className="classic_right"
                        {...register("OfferPrice", { required: true })}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <br />
            <div className="submitbuttons p-2">
              <button className="button1 m-2 p-2" type="submit">
                Submit
              </button>
              <button className="button2 m-2 p-2" type="reset">
                Reset
              </button>
            </div>
          </form>
        </Row>
      </Container>
    </div>
  );
}

export default Company;
