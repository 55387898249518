import React from 'react';
import './InstructorDetails.css'; 

const InstructorDetails = () => {
  const instructor = {
    name: "John Doe",
    bio: "John is a passionate educator with over 10 years of experience in teaching web development. He loves sharing his knowledge and helping students achieve their goals.",
    qualifications: [
      "M.S. in Computer Science",
      "Certified Web Developer",
      "JavaScript Expert"
    ],
    courses: [
      "React for Beginners",
      "Advanced JavaScript",
      "Web Development Bootcamp"
    ],
    image: "https://via.placeholder.com/150",
  };

  return (
    <div className="instructor-details">
      <div className="instructor-image">
        <img className='instructor-img' src={instructor.image} alt={`${instructor.name}`} />
        <div className='instructor-bio'>
        <h2 className='instructor-name'>{instructor.name}</h2>
        <p className="bio">{instructor.bio}</p>
        </div>
      </div>
      {/* <div className="instructor-info">
       
        <h3 >Qualifications:</h3>
        <ul>
          {instructor.qualifications.map((qual, index) => (
            <li key={index}>{qual}</li>
          ))}
        </ul>
        <h3>Courses Taught:</h3>
        <ul>
          {instructor.courses.map((course, index) => (
            <li key={index}>{course}</li>
          ))}
        </ul>
      </div> */}
    </div>
  );
};

export default InstructorDetails;
