import React, { useEffect, useState } from "react";
import { Tab, Tabs, Form, Row, Col, InputGroup, Button } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import "./PersonalInfo.scss";
import Api from "../../../Api";

const PersonalInfo = () => {
  const [profileImage, setProfileImage] = useState(null);
  const [key, setKey] = useState("personal");
  const [userData, setUserData] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [maritalList, setMaritalList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [countryValue, setCountryValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [districtValue, setDistrictValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const userId = localStorage.getItem("userId");

  const handleImageChange = (event) => {
    setProfileImage(URL.createObjectURL(event.target.files[0]));
  };

  useEffect(() => {
    getDetails();
    getCountry();
    getGender();
    getMarital();
  }, []);

  useEffect(() => {
    if (userData) {
      setCountryValue(userData?.locationDetails?.country || "");
      setStateValue(userData?.locationDetails?.state || "");
      setDistrictValue(userData?.locationDetails?.district || "");
      setCityValue(userData?.locationDetails?.city || "");
    }
  }, [userData]);

  console.log(userData);
  const getMarital = async () => {
    const res = await Api.get("marital/getMarital");
    setMaritalList(res.data.data);
  };

  const getGender = async () => {
    const res = await Api.get("gender/getGenderlist");
    setGenderList(res.data.data);
  };

  const getCountry = async () => {
    const res = await Api.get("country/getallcountry");
    setCountryList(res.data.data);
  };

  const getState = (country_id) => {
    Api.get(`state/stateById/${country_id}`)
      .then((res) => setStateList(res.data.data))
      .catch((error) => console.error("Error fetching states:", error));
  };

  const getDistrict = (state_id) => {
    Api.get(`district/districtById/${state_id}`).then((res) =>
      setDistrictList(res.data.data)
    );
  };

  const getCity = (districtId) => {
    Api.get(`city/cityById/${districtId}`).then((res) =>
      setCityList(res.data.data)
    );
  };

  const getDetails = async () => {
    Api.get(`signup/student_getone/${userId}`)
      .then((res) => {
        console.log("API Response:", res.data);

        setUserData(res?.data?.data);
        setProfileImage(res?.data?.data?.profileImage || null);
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedUserData = {
      ...userData,
      locationDetails: {
        ...userData.locationDetails,
        country: countryValue,
        state: stateValue,
        district: districtValue,
        city: cityValue,
      },
    };

    try {
      const response = await Api.put(
        `signup/update/${userId}`,
        updatedUserData
      );

      if (response.status === 200) {
        console.log("Profile updated successfully:", response.data);
        alert("Profile updated successfully!");
      } else {
        console.error("Failed to update profile:", response.data);
        alert("Failed to update profile.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile.");
    }
  };

  return (
    <div className="container-fluid">
      <Row className="personal-info-cont">
        <Col md={8} className="text-center">
          <div
            className="profile-image-wrapper"
            style={{ position: "relative", display: "inline-block" }}
          >
            {profileImage ? (
              <img
                src={profileImage}
                alt="Profile"
                className="img-fluid rounded-circle mb-3"
                style={{ width: "150px", height: "150px" }}
              />
            ) : (
              <div
                className="placeholder rounded-circle mb-3"
                style={{
                  width: "150px",
                  height: "150px",
                  backgroundColor: "#ddd",
                }}
              ></div>
            )}

            <label
              htmlFor="file-input"
              style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                backgroundColor: "#fff",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                boxShadow: "0 0 5px rgba(0,0,0,0.3)",
                border: "2px solid #007bff",
              }}
            >
              <i className="fas fa-upload" style={{ color: "#007bff" }}></i>
            </label>

            <input
              id="file-input"
              type="file"
              onChange={handleImageChange}
              style={{
                display: "none",
              }}
            />
          </div>

          <div
            className="username-display"
            style={{ marginTop: "10px", fontSize: "18px", fontWeight: "bold" }}
          >
            {userData.firstName} {userData.lastName}
          </div>
        </Col>

        <Col md={9} className="personal-tab-container">
          <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
            <Tab eventKey="personal" title="Personal Info">
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="firstName">
                      <Form.Label>First Name</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-user"></i>
                        </InputGroup.Text>
                        <Form.Control
                          className="custom-size"
                          type="text"
                          defaultValue={userData.firstName}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-user"></i>
                        </InputGroup.Text>
                        <Form.Control
                          className="custom-size"
                          type="text"
                          defaultValue={userData.lastName}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="phone">
                      <Form.Label>Phone Number</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-phone"></i>
                        </InputGroup.Text>
                        <Form.Control
                          className="custom-size"
                          type="text"
                          defaultValue={userData.phone}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="dob">
                      <Form.Label>Date of Birth</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-calendar-alt"></i>
                        </InputGroup.Text>
                        <Form.Control
                          className="custom-size"
                          type="date"
                          defaultValue={userData.dob}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="gender">
                      <Form.Label>Gender</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-venus-mars"></i>
                        </InputGroup.Text>
                        <Form.Select
                          className="select-option"
                          value={
                            userData.gender ? userData.gender.toLowerCase() : ""
                          }
                          onChange={(e) =>
                            setUserData({ ...userData, gender: e.target.value })
                          }
                        >
                          <option value="">Select a Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="transgender">Transgender</option>
                          <option value="other">Other</option>
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="email">
                      <Form.Label>Email Id</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-envelope"></i>
                        </InputGroup.Text>
                        <Form.Control
                          className="custom-size"
                          type="email"
                          defaultValue={userData.email}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Tab>

            <Tab eventKey="address" title="Address Info">
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="country">
                      <Form.Label>Country</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-globe"></i>
                        </InputGroup.Text>
                        <Form.Select
                          className="select-option"
                          value={countryValue}
                          onChange={(e) => {
                            setCountryValue(e.target.value);
                            getState(e.target.value);
                          }}
                        >
                          <option value="">Select Country</option>
                          {countryList.map((country) => (
                            <option key={country.id} value={country.id}>
                              {country.name}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="state">
                      <Form.Label>State</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-map-marker-alt"></i>
                        </InputGroup.Text>
                        <Form.Select
                          className="select-option"
                          value={stateValue}
                          onChange={(e) => {
                            setStateValue(e.target.value);
                            getDistrict(e.target.value);
                          }}
                        >
                          <option value="">Select State</option>
                          {stateList.map((state) => (
                            <option key={state.id} value={state.id}>
                              {state.name}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="district">
                      <Form.Label>District</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-map"></i>
                        </InputGroup.Text>
                        <Form.Select
                          className="select-option"
                          value={districtValue}
                          onChange={(e) => {
                            setDistrictValue(e.target.value);
                            getCity(e.target.value);
                          }}
                        >
                          <option value="">Select District</option>
                          {districtList.map((district) => (
                            <option key={district.id} value={district.id}>
                              {district.name}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="city">
                      <Form.Label>City</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-city"></i>
                        </InputGroup.Text>
                        <Form.Select
                          className="select-option"
                          value={cityValue}
                          onChange={(e) => setCityValue(e.target.value)}
                        >
                          <option value="">Select City</option>
                          {cityList.map((city) => (
                            <option key={city.id} value={city.id}>
                              {city.name}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="area">
                      <Form.Label>Area</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-map-pin"></i>
                        </InputGroup.Text>
                        <Form.Control
                          className="custom-size"
                          type="text"
                          defaultValue={userData?.locationDetails?.area || ""}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="address">
                      <Form.Label>Address</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-home"></i>
                        </InputGroup.Text>
                        <Form.Control
                          className="custom-size"
                          type="text"
                          defaultValue={
                            userData?.locationDetails?.address || ""
                          }
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="pincode">
                      <Form.Label>Pin Code</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-map-pin"></i>
                        </InputGroup.Text>
                        <Form.Control
                          className="custom-size"
                          type="text"
                          defaultValue={
                            userData?.locationDetails?.pincode || ""
                          }
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Tab>
          </Tabs>
          <div className="submit-container-personal-info">
            {" "}
            <Button
              variant="primary"
              onClick={handleSubmit}
              className="mt-3 submit-btn-personal-info"
            >
              Submit
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PersonalInfo;
