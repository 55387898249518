import React, { useState } from "react";
import "./skilltest.scss";
import { Carousel } from "antd";
import { Row, Col } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import styled from "styled-components";
import {
  FcParallelTasks,
  FcCamcorderPro,
  FcBullish,
  FcDepartment,
  FcOvertime,
  FcEngineering,
  FcNext,
} from "react-icons/fc";

import learn1 from "../../../Images/yellow.png";
import react from "../../../Images/pngwing.png";
import assess from "../../../Images/assess.jpg";
import standalone from "../../../Images/standalone.jpg";
import unlock from "../../../Images/unlock3.png";
import choose from "../../../Images/choose.png";
import badge from "../../../Images/badge12.png";
import standout from "../../../Images/standout.png";
import careerblitz from "../../../Images/InstagramImage.png";
import Layout from "../../../Components/Layout";
import { Space, Typography } from "antd";
import { Card } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
// import Layout from "antd/lib/layout/layout";
const { Meta } = Card;
const { Text, Link } = Typography;
const gridStyle = {
  width: "25%",
  textAlign: "center",
};

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 6,
  slidesToScroll: 3,
  autoplay: true,
  autoplaySpeed: 8000,
  pauseOnHover: true,
};

const StyledSlider = styled(Slider)`
  .slick-slide div {
    outline: none;
    width: 70%;
    height: 70px;
    margin: 0 auto;
  }
`;

function Skilltest() {
  const navigate = useNavigate();
  const [services, setservices] = useState([
    {
      id: 1,
      headding: "MERN STACK",
      sub: "Online / Ofline",
      image: careerblitz,
      tech: "Node.js, React.js, MongoDB, Express.js ",
    },
    {
      id: 2,
      headding: "MEAN STACK",
      sub: "Online / Ofline",
      image: careerblitz,
      tech: "Node.js, Angular,  MongoDB, Express.js",
    },
    {
      id: 3,
      headding: "JAVA DEVELOPER",
      sub: "Online / Ofline",
      image: careerblitz,
      tech: "Java, Springboot",
    },
    {
      id: 4,
      headding: "MANUAL TESTING",
      sub: "Online / Ofline",
      image: careerblitz,
      tech: "Jira, Test Cases, Test Scenario",
    },
    {
      id: 5,
      headding: "AUTOMATION ",
      sub: "Online / Ofline",
      image: careerblitz,
      tech: "Java with Selenium, Java with python",
    },
    // {
    //   id: 6,

    //   headding: "BGV",
    //   sub: "Update",
    //   image: "https://play-lh.googleusercontent.com/cllvLnSryJMRplj505fh_i-WwJ_jwXUzeBlYngSZjK1Cjfv5dPw2NtnXkwd5jTwU9rVR",
    // },
  ]);

  return (
    <Layout>
      <div className=" ">
        {/* .............................Imagecard................................ */}
        {/* <div>
      <Row>
        <Col sm={24} md={12} lg={12} xs={12} className="Int-col">
          <div>
            <h1>Grow your skills with us.</h1>
          </div>
          <div style={{ paddingTop: "50px", paddingLeft: "30px" }}>
            <button className="button1 m-2 p-2" type="submit">
              Student
            </button>
            <button className="button1 m-2 p-2" type="submit">
              Professional
            </button>
          </div>
        </Col>
        <Col sm={24} md={12} lg={12} xs={12} className="Int-col">
          <img className="image" src={skill} />
        </Col>
      </Row>
    </div> */}
        <div className="skill_banner">
          <img className="skill_image" src={learn1} />
          <div className="grow_text"></div>
          {/* <div className="fontdesign"> Grow your skills with us.</div> */}
          {/* <div className="student_professional_button mt-4">
          <button className="button1 m-2 p-2" type="submit">
            Student
          </button>

          <button className="button1 m-2 p-2" type="submit">
            Professional
          </button>
        </div> */}
        </div>

        {/* Why Skill test */}
        <div>
          <h1 className="companyheading mt-5">Why Skill Test</h1>
        </div>
        <Row>
          <Col sm={24} md={12} lg={8} className="skill_content_card">
            <Card
              hoverable
              style={{
                width: 240,
                height: 240,
                color: "#ff0000",
              }}
              cover={<img src={assess} />}
            >
              <b>
                {" "}
                <Meta title="Assess your skill" />
              </b>
            </Card>
          </Col>
          <Col sm={24} md={12} lg={8} className="skill_content_card">
            <Card
              hoverable
              style={{
                width: 240,
                height: 240,
                color: "#ff0000",
              }}
              cover={<img style={{ height: 240 }} src={standalone} />}
            >
              <b>
                {" "}
                <Meta title="Standout to recruiters" />
              </b>
            </Card>
          </Col>
          <Col sm={24} md={12} lg={8} className="skill_content_card">
            <Card
              hoverable
              style={{
                width: 240,
                height: 240,
                color: "#ff0000",
              }}
              cover={<img style={{ height: 240 }} src={unlock} />}
            >
              <b>
                {" "}
                <Meta title="Unlock benifits" />
              </b>
            </Card>
          </Col>
        </Row>

        <div className="pt-5">
          <h1 className="companyheading pt-5 ">Trending Courses</h1>

          <Row className="service_cards mt-4 mb-5">
            {services.map((services, i) => (
              <Col sm={12} md={4} className="service_card mt-5">
                <article onClick={() => navigate("/skilltest/filterpage")}>
                  <h2>{services.headding}</h2>
                  <div className="title">{services.sub}</div>
                  <div className="pic">
                    <img src={services.image} className="card_inside" />
                  </div>
                  <div className="desc_technology mt-2 ">
                    <b>{services.tech}</b>
                  </div>
                </article>
              </Col>
            ))}
          </Row>
          <div className="viewMore-skills mt-3">
            <button
              className="viewMore-skills-button"
              onClick={() => navigate("/skilltest/filterpage")}
            >
              View More
            </button>
          </div>
        </div>
        {/* How skill test */}
        {/* <div>
        <h2 className="companyheading mt-5">How Skill test works</h2>
      </div> */}
        {/* <Row style={{ padding: "32px", paddingTop: "0" }}>
        <Col sm={24} md={12} lg={8} style={{ padding: "10px" }}>
          <Card
            hoverable
            style={{
              width: 240,
              height: 240,
              border: "solid 1px",
              borderColor: "#e2e2e2",
              borderRadius: "8px",
            }}
            cover={<img src={choose} />}
          >
            <Meta title="Choose your skill" />
          </Card>
        </Col>
        <Col sm={24} md={12} lg={8} style={{ padding: "10px" }}>
          <Card
            hoverable
            style={{
              width: 240,
              height: 240,
              border: "solid 1px",
              borderColor: "#e2e2e2",
              borderRadius: "8px",
            }}
            cover={<img src={badge} />}
          >
            <Meta title="Get your Badge" />
          </Card>
        </Col>
        <Col sm={24} md={12} lg={8} style={{ padding: "10px" }}>
          <Card
            hoverable
            style={{
              width: 240,
              height: 240,
              border: "solid 1px",
              borderColor: "#e2e2e2",
              borderRadius: "8px",
            }}
            cover={<img src={standout} />}
          >
            <Meta title="Standout to our recruiters" />
          </Card>
        </Col>
      </Row> */}
        <div>
          <h1 className="companyheading mt-5 p-2 mb-5">Our Partners</h1>
          <div className="Logo_content mb-5">
            <div className="Logo_carousel">
              <div className="Logo_icon">
                <StyledSlider {...settings}>
                  <div>
                    <img
                      className="carousel_images"
                      src={
                        "https://instahyre-2.s3-ap-south-1.amazonaws.com/media/CACHE/images/images/profile/base/employer/27725/a6f16b892e/0/4309939c54b52d12444460070c7f8fd0.jpeg"
                      }
                    />
                  </div>
                  <div>
                    <img
                      className="carousel_images"
                      src={
                        "https://media-exp1.licdn.com/dms/image/C560BAQFicz38-PZFAQ/company-logo_200_200/0/1556818993608?e=2147483647&v=beta&t=RmDkimcni92Zb9-XWIQlqf-CBCT4lI7sca0nh3Y4bzA"
                      }
                    />
                  </div>
                  <img
                    className="carousel_images"
                    src={
                      "https://upwork-usw2-prod-assets-static.s3.us-west-2.amazonaws.com/org-logo/1151566978584199168"
                    }
                  />
                  <div>
                    <img
                      className="carousel_images"
                      src={
                        "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/guwlbct7skrki7erqacl"
                      }
                    />
                  </div>
                  <div>
                    <img
                      className="carousel_images"
                      src={
                        "https://media-exp1.licdn.com/dms/image/C560BAQFicz38-PZFAQ/company-logo_200_200/0/1556818993608?e=2147483647&v=beta&t=RmDkimcni92Zb9-XWIQlqf-CBCT4lI7sca0nh3Y4bzA"
                      }
                    />
                  </div>
                  <div>
                    <img
                      className="carousel_images"
                      src={
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARMAAAC3CAMAAAAGjUrGAAAAeFBMVEX///8AAADl5eWqqqp+fn67u7tZWVnd3d2Dg4MaGhpsbGzW1taenp7j4+MuLi6ioqLr6+v39/fu7u63t7fDw8OVlZWpqanNzc08PDx0dHQfHx9ERESxsbEVFRVNTU3JyclkZGSNjY0oKCgMDAw0NDRSUlIlJSWRkZGpr7FhAAAES0lEQVR4nO3Za3uaMByGcfBQPNRWwOPqWqld9/2/4UDMPyGBgMiu7cX9e6UkBHiAkEAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPhPJcvNabwbi3SzH1lV1vvpaVxxmu7XVqVok9p1lrHaxkiLrbbLpav852rULK8oxVYLqom8EbukXyDZz7DOYSvNL4+1NXLHSNpJ3+urfG+vxZUmqjuuVsx/Nm2nLF43tfAhy9+GiOTVsxfjssrCt6fHss76h6fOMq8wMRdU9/xJDtqXyTwwQ/k0G4gaGu7p2Xe84bSo8stbJXwq6iT+OomVSTjpl0n90euNvwwRych/LMVunlqqhGmgL/8GRzuT60o9Mgn28v+sVo9l0WWQzmRSt3VDftX7boqrT/P0NXC3tLo3k0tZeSMLZrfVX2RJMkQk3j6tsDP7tSZr4w78uZjMlMmTLE7c9H2ZzI8Lx63jCsbSwvP1v75E7WdlP/qyO++N6y7eqEdRZpyXLDKrRJkUbHQvPLXaV+1Ebia6Q3Az8XaVW2mi2Jp+AES+lbqTa35mFajbdhLs9JFbpqpkHLxJdYu6gJaSie4QZJt3ZmLEuw8y4/cwlqpBu3NSXflCjurLXVvdyK/B1+3Xh10lKkeCO33vJHosoS6qezMxro3MaexhKhP3GRbfSCb2lZSbOZl4Ll+VySj4Lcdx62fdTA6jyLYyG3OHB6+PxFChMnlqrqIyeXaLtrI/KpPbYD9Op1XpyMgk0F1vWb3Tc+e7sumDVfp7mDwKd2RScyJ0kZWJ+6yampkEMpl4v1bvlMmhuu2XSqHTjz3gL2XijmqrmejMsqJ6n0yqI6Jh0ij9o0yMB3zRNfbIxNqCZ//v9pf6k7Z7JwgyKRn16U+c0AcMRWVycEpet1eZZJK5a6sDc547yeXlRk0M7Ez0k+PS41msh5ri/EgMFTI+cUouqkBlUnMm1HF5xidqXOdkIhsIf92dSSzrvuk57Pn+o68nPdXJ3qyEJeNY+6WanlN7xrHq9nIz0UezVUOwoFsmMmEo7ic5q/nwchj6Inw2J1DxXp7/RneYVqadSSoFzfOdlV5uZ2I0PHcyefft81xWLEbfelg8VChhi+9H5sXHN1m8dzMJMrsdY39+zB1qwqXfXZRXrj43x5oD7KHt/cmi2/uTVVuddU0mzouolnNUZvIt/9VoX5+QYUJpe8+2GeY92zyoy8ROoEsmn/JXz4NnsmyY8az/fez1GT3A+9hTfSbWGemQiR7Smz2XnicPE4rvvf1XbG2yRpf39sWe1mWiX8FctWfyJX92lYPQs8qa6XsPTd93woM8oB/6vhOer1e5aqL6gnBmVmzNROduz4P1PHmo6WBsfQesfMArdfsO+FH9DnhKN1In0R/7TMaHv5bvgKs4WZlVG9qJBnl3DwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAGBQfwBlPjS2c+LczwAAAABJRU5ErkJggg=="
                      }
                    />
                  </div>
                  <div>
                    <img
                      className="carousel_images"
                      src={
                        "https://play-lh.googleusercontent.com/cllvLnSryJMRplj505fh_i-WwJ_jwXUzeBlYngSZjK1Cjfv5dPw2NtnXkwd5jTwU9rVR"
                      }
                    />
                  </div>
                </StyledSlider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default Skilltest;
