import React from 'react';
import Card from "react-bootstrap/Card";
import { Row, Col } from "antd";

function ClientInterview() {
  return (
    <div>
         <div className="pages-title mt-5 ms-5">Schedule Interview</div>
         <Row className="p-3">
          <Col sm={24} md={12} lg={6} className="p-3">
           
            <Card
            >
              <Card.Body>
                <h4>Meenu</h4>
                <p className="Int-para2">
                  <b>Date:</b> 
                </p>
                <p className="Int-para2">
                  <b>Time:</b> 
                </p>
                <p className="Int-para2">
                  <b>Mode:</b> 
                </p>
                <p className="Int-para2">
                  <b>Role:</b> 
                </p>
                <p className="Int-para2">
                  <b>AssignTo:</b> 
                </p>
              </Card.Body>
            </Card>
          </Col>
      </Row>
    </div>
  )
}

export default ClientInterview