import React, { useState } from "react";
import { Row, Col } from "antd";
import { useForm } from "react-hook-form";
import Api from "../../../../Api";
import { Dropdown } from "primereact/dropdown";

function Vendor() {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const [CheckReact, setCheckReact] = useState(false);
  const [CheckNode, setCheckNode] = useState(false);
  const [CheckNative, setCheckNative] = useState(false);
  const [CheckFlutter, setCheckFlutter] = useState(false);
  const [CheckJava, setCheckJava] = useState(false);
  const [CheckPhp, setCheckPhp] = useState(false);
  const [CheckAngular, setCheckAngular] = useState(false);

  const [reactValues, setReactValues] = useState({
    react1: "",
    react2: "",
    react3: "",
    react4: "",
    react5: "",
    react6: "",
  });
  const [nodeValues, setNodeValues] = useState({
    node1: "",
    node2: "",
    node3: "",
    node4: "",
    node5: "",
    node6: "",
  });
  const [reactNativeValues, setReactNativeValues] = useState({
    reactNative1: "",
    reactNative2: "",
    reactNative3: "",
    reactNative4: "",
    reactNative5: "",
    reactNative6: "",
  });
  const [javaValues, setJavaValues] = useState({
    java1: "",
    java2: "",
    java3: "",
    java4: "",
    java5: "",
    java6: "",
  });
  const [phpValues, setPhpValues] = useState({
    php1: "",
    php2: "",
    php3: "",
    php4: "",
    php5: "",
    php6: "",
  });
  const [flutterValues, setFlutterValues] = useState({
    flutter1: "",
    flutter2: "",
    flutter3: "",
    flutter4: "",
    flutter5: "",
    flutter6: "",
  });
  const [angularValues, setAngularValues] = useState({
    angular1: "",
    angular2: "",
    angular3: "",
    angular4: "",
    angular5: "",
    angular6: "",
  });

  console.log("reactValues", javaValues, phpValues);
  console.log("reactValues", flutterValues, angularValues);

  const id = localStorage.getItem("id");

  const handleFormSubmit = () => {
    const details = {
      CompanyID: id,
      FirstName: getValues().FirstName,
      LastName: getValues().LastName,
      First: getValues().First,
      location: getValues().location,
      Last: getValues().Last,
      phone: getValues().phone,
      email: getValues().email,
      employee: getValues().employee,
      react: reactValues,
      node: nodeValues,
      reactNative: reactNativeValues,
      php: phpValues,
      java: javaValues,
      angular: angularValues,
      flutter: flutterValues,
    };
    Api.post("/contract/createContract", details).then((res) => {
      console.log("res", res);
    });
  };
  const [companyType, setCompanyType] = useState("");
  const types = [
    { name: "Chennai", value: "Chennai" },
    { name: "Bangalore", value: "Bangalore" },
    { name: "Coimbatore", value: "Coimbatore" },
  ];

  return (
    <div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="personal-informations p-4">
          <div className="staff-title mb-5 ">Request Management</div>
          {/* <Row className="mt-5">
            <Col sm={24} md={10} lg={6} className="Stud-personal-col px-4 ">
              <label className="input-title">Company Name</label>
              <input
                className="seminarInput"
                type="text"
                {...register("companyName", { required: true })}
              />
              {errors.companyName && (
                <span className="Stud-personal-error">Company Name</span>
              )}
            </Col>
            <Col sm={24} md={10} lg={6} className="Stud-personal-col px-4 ">
              <label className="input-title">Website</label>
              <input
                className="seminarInput"
                type="text"
                {...register("Website", { required: true })}
              />
              {errors.Website && (
                <span className="Stud-personal-error">Website required</span>
              )}
            </Col>
            <Col sm={24} md={10} lg={6} className="Stud-personal-col px-4 ">
              <label className="input-title">
                Linkedin URL of contact person
              </label>
              <input
                className="seminarInput"
                type="text"
                {...register("linkedin", { required: true })}
              />
              {errors.linkedin && (
                <span className="Stud-personal-error">Contact person</span>
              )}
            </Col>
          </Row> */}
          <h6 className="mt-5 ms-3">Founder/Co Founder Name</h6>
          <Row>
            <Col sm={24} md={10} lg={6} className="Stud-personal-col px-4 ">
              <label className="input-title">First Name</label>
              <input
                className="seminarInput"
                type="text"
                {...register("FirstName", { required: true })}
              />
              {errors.FirstName && (
                <span className="Stud-personal-error">
                  FirstName is required
                </span>
              )}
            </Col>
            <Col sm={24} md={10} lg={6} className="Stud-personal-col px-4">
              <label className="input-title">Last Name</label>
              <input
                className="seminarInput"
                type="text"
                {...register("LastName", { required: true })}
              />
              {errors.LastName && (
                <span className="Stud-personal-error">LastName required</span>
              )}
            </Col>
          </Row>
          <h6 className="mt-2 ms-3">Contact Person</h6>
          <Row>
            <Col sm={24} md={10} lg={6} className="Stud-personal-col px-4 ">
              <label className="input-title">First Name</label>
              <input
                className="seminarInput"
                type="text"
                {...register("First", { required: true })}
              />
              {errors.First && (
                <span className="Stud-personal-error">
                  FirstName is required
                </span>
              )}
            </Col>
            <Col sm={24} md={10} lg={8} className="Stud-personal-col px-4 ">
              <label className="input-title">Last Name</label>
              <input
                className="seminarInput"
                type="text"
                {...register("Last", { required: true })}
              />
              {errors.Last && (
                <span className="Stud-personal-error">LastName required</span>
              )}
            </Col>
            <Col sm={24} md={10} lg={6} className="Stud-personal-col px-4 ">
              <label className="input-title">Phone</label>
              <input
                className="seminarInput"
                type="text"
                {...register("phone", { required: true })}
              />
              {errors.phone && (
                <span className="Stud-personal-error">phone is required</span>
              )}
            </Col>
          </Row>
          {/* <h6 className="mt-2 ms-3">Details</h6> */}
          <Row className="mt-1">
            <Col sm={24} md={10} lg={6} className="Stud-personal-col px-4 ">
              <label className="input-title">Location</label>
              <br />
              <Dropdown
                className="seminarInput"
                name="companyType"
                value={companyType}
                options={types}
                optionLabel="name"
                placeholder="Select a location"
                {...register("location", {
                  required: true,
                  onChange: (e) => {
                    setCompanyType(e.target.value);
                  },
                })}
              />

              {/* <input
                className="seminarInput"
                type="text"
                {...register("phone", { required: true })}
              /> */}
              {errors.location && (
                <span className="Stud-personal-error">
                  location is required
                </span>
              )}
            </Col>
            <Col sm={24} md={10} lg={6} className="Stud-personal-col px-4 ">
              <label className="input-title">Email</label>
              <input
                className="seminarInput"
                type="text"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span className="Stud-personal-error">Email is required</span>
              )}
            </Col>
            <Col sm={24} md={10} lg={6} className="Stud-personal-col px-4 ">
              <label className="input-title">Employee Strength</label>
              <input
                className="seminarInput"
                type="text"
                {...register("employee", { required: true })}
              />
              {errors.employee && (
                <span className="Stud-personal-error">
                  Employee is required
                </span>
              )}
            </Col>
          </Row>
          <h5
            className="mt-5 ms-3"
            style={{ borderBottom: "0.5px solid black", width: "75%" }}
          >
            Resources Details
          </h5>
          <div className="ms-3 mt-4">
            <h6>Technologies you are very good at</h6>

            <h6 className="mt-3">
              {" "}
              <input
                type="Checkbox"
                onChange={(e) => setCheckReact(e.target.checked)}
              />
              &nbsp;&nbsp;&nbsp;React JS
            </h6>
            <p>
              {CheckReact ? (
                <div>
                  <h5>React JS</h5>
                  <Row>
                    <Col sm={12} md={4} className="mt-4">
                      React JS no of resourses
                    </Col>
                    <Col sm={12} md={4}>
                      1-2 Years
                      <input
                        type="number"
                        name="react1"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      5-8years
                      <input
                        type="number"
                        name="react2"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      8-10 years
                      <input
                        type="number"
                        name="react3"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col sm={12} md={4}>
                      Rates per month in INR (for 1.2 lakh, write 120K)
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="react4"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="react5"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="react6"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
            </p>
            <h6>
              <input
                type="Checkbox"
                onChange={(e) => setCheckNode(e.target.checked)}
              />
              &nbsp;&nbsp;&nbsp;Node JS
            </h6>
            <p>
              {CheckNode ? (
                <div>
                  <h5>Node JS</h5>
                  <Row>
                    <Col sm={12} md={4} className="mt-4">
                      Node JS no of resourses
                    </Col>
                    <Col sm={12} md={4}>
                      1-2 Years
                      <input
                        type="number"
                        name="node1"
                        onChange={(e) => {
                          setNodeValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      {" "}
                      5-8years
                      <input
                        type="number"
                        name="node2"
                        onChange={(e) => {
                          setNodeValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      {" "}
                      8-10 years
                      <input
                        type="number"
                        name="node3"
                        onChange={(e) => {
                          setNodeValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col sm={12} md={4}>
                      Rates per month in INR (for 1.2 lakh, write 120K)
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="node4"
                        onChange={(e) => {
                          setNodeValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="node5"
                        onChange={(e) => {
                          setNodeValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="node6"
                        onChange={(e) => {
                          setNodeValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
            </p>
            <h6>
              <input
                type="Checkbox"
                onChange={(e) => setCheckNative(e.target.checked)}
              />
              &nbsp;&nbsp;&nbsp;React Native
            </h6>
            <p>
              {CheckNative ? (
                <div>
                  <h5>React Native</h5>
                  <Row>
                    <Col sm={12} md={4} className="">
                      React Native no of resourses
                    </Col>
                    <Col sm={12} md={4}>
                      1-2 Years
                      <input
                        type="number"
                        name="reactNative1"
                        onChange={(e) => {
                          setReactNativeValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      5-8years
                      <input
                        type="number"
                        name="reactNative2"
                        onChange={(e) => {
                          setReactNativeValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      8-10 years
                      <input
                        type="number"
                        name="reactNative3"
                        onChange={(e) => {
                          setReactNativeValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col sm={12} md={4}>
                      Rates per month in INR (for 1.2 lakh, write 120K)
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="reactNative4"
                        onChange={(e) => {
                          setReactNativeValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="reactNative5"
                        onChange={(e) => {
                          setReactNativeValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="reactNative6"
                        onChange={(e) => {
                          setReactNativeValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
            </p>
            <h6>
              <input
                type="Checkbox"
                onChange={(e) => setCheckJava(e.target.checked)}
              />
              &nbsp;&nbsp;&nbsp;Java
            </h6>
            <p>
              {CheckJava ? (
                <div>
                  <h5>Java</h5>
                  <Row>
                    <Col sm={12} md={4} className="mt-3">
                      Java no of resourses
                    </Col>
                    <Col sm={12} md={4}>
                      1-2 Years
                      <input
                        type="number"
                        name="java1"
                        onChange={(e) => {
                          setJavaValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      5-8years
                      <input
                        type="number"
                        name="java2"
                        onChange={(e) => {
                          setJavaValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      8-10 years
                      <input
                        type="number"
                        name="java3"
                        onChange={(e) => {
                          setJavaValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col sm={12} md={4}>
                      Rates per month in INR (for 1.2 lakh, write 120K)
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="java4"
                        onChange={(e) => {
                          setJavaValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="java5"
                        onChange={(e) => {
                          setJavaValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="java6"
                        onChange={(e) => {
                          setJavaValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
            </p>
            <h6>
              <input
                type="Checkbox"
                onChange={(e) => setCheckPhp(e.target.checked)}
              />
              &nbsp;&nbsp;&nbsp;PHP
            </h6>
            <p>
              {CheckPhp ? (
                <div>
                  <h5>PHP</h5>
                  <Row>
                    <Col sm={12} md={4} className="mt-4">
                      PHP no of resourses
                    </Col>
                    <Col sm={12} md={4}>
                      1-2 Years
                      <input
                        type="number"
                        name="php1"
                        onChange={(e) => {
                          setPhpValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      5-8years
                      <input
                        type="number"
                        name="php2"
                        onChange={(e) => {
                          setPhpValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      8-10 years
                      <input
                        type="number"
                        name="php3"
                        onChange={(e) => {
                          setPhpValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col sm={12} md={4}>
                      Rates per month in INR (for 1.2 lakh, write 120K)
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="php4"
                        onChange={(e) => {
                          setPhpValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="php5"
                        onChange={(e) => {
                          setPhpValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="php6"
                        onChange={(e) => {
                          setPhpValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
            </p>
            <h6>
              <input
                type="Checkbox"
                onChange={(e) => setCheckFlutter(e.target.checked)}
              />
              &nbsp;&nbsp;&nbsp;Flutter{" "}
            </h6>
            <p>
              {CheckFlutter ? (
                <div>
                  <h5>Flutter</h5>
                  <Row>
                    <Col sm={12} md={4} className="mt-4">
                      Flutter no of resourses
                    </Col>
                    <Col sm={12} md={4}>
                      1-2 Years
                      <input
                        type="number"
                        name="flutter1"
                        onChange={(e) => {
                          setFlutterValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      5-8years
                      <input
                        type="number"
                        name="flutter2"
                        onChange={(e) => {
                          setFlutterValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      8-10 years
                      <input
                        type="number"
                        name="flutter3"
                        onChange={(e) => {
                          setFlutterValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col sm={12} md={4}>
                      Rates per month in INR (for 1.2 lakh, write 120K)
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="flutter4"
                        onChange={(e) => {
                          setFlutterValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="flutter5"
                        onChange={(e) => {
                          setFlutterValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="flutter6"
                        onChange={(e) => {
                          setFlutterValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
            </p>
            <h6>
              <input
                type="Checkbox"
                onChange={(e) => setCheckAngular(e.target.checked)}
              />
              &nbsp;&nbsp;&nbsp;Angular
            </h6>
            <p>
              {CheckAngular ? (
                <div>
                  <h5>Angular</h5>
                  <Row>
                    <Col sm={12} md={4} className="mt-4">
                      Angular no of resourses
                    </Col>
                    <Col sm={12} md={4}>
                      1-2 Years
                      <input
                        type="number"
                        name="angular1"
                        onChange={(e) => {
                          setAngularValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      5-8years
                      <input
                        type="number"
                        name="angular2"
                        onChange={(e) => {
                          setAngularValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      8-10 years
                      <input
                        type="number"
                        name="angular3"
                        onChange={(e) => {
                          setAngularValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col sm={12} md={4}>
                      Rates per month in INR (for 1.2 lakh, write 120K)
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="angular4"
                        onChange={(e) => {
                          setAngularValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="angular5"
                        onChange={(e) => {
                          setAngularValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        name="angular6"
                        onChange={(e) => {
                          setAngularValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
            </p>
          </div>
          <div className="submitbuttons px-4">
            <button className="button1 m-2" type="submit">
              Submit
            </button>
            <button className="button2 m-2" type="reset">
              Reset
            </button>
          </div>
        </div>
      </form>
      {/* </Container> */}

    </div>
  );
}

export default Vendor;
