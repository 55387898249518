import React, { useContext, useEffect, useMemo, useState } from "react";
import { Row, Col } from "antd";
import { Button, Container } from "react-bootstrap";
import { CiCalendarDate, CiLocationOn } from "react-icons/ci";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { TfiBag } from "react-icons/tfi";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import "../../../Components/Css/Students/Studintdetails.scss";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Api from "../../../Api";
import { useLocation, useNavigate } from "react-router-dom";

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = eventKey === activeEventKey;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#EBE6FF",
        paddingTop: "12px",
      }}
    >
      <p onClick={decoratedOnClick}>
        {children}
        {isCurrentEventKey ? <FiChevronUp /> : <FiChevronDown />}
      </p>
    </div>
  );
}

function RequestProject() {
  const [createForm, setcreateForm] = useState([]);
  const navigate = useNavigate();
  const id = localStorage.getItem("userId");

  const state = useLocation();
  const jobTitles = state.state.projectTitle;
  console.log("createForm", jobTitles);
  console.log("createFdsasorm", createForm);

  const filteredData = useMemo(() => {
    if (!createForm || createForm.length === 0) return [];

    const normalizedFilteredJobTitle = jobTitles.trim().toLowerCase();
    return createForm.filter(
      (item) =>
        item.projectTitle.trim().toLowerCase() === normalizedFilteredJobTitle
    );
  }, [createForm, jobTitles]);
  console.log("filteredData", filteredData);

  useEffect(() => {
    getCreateForm();
  }, []);
  const getCreateForm = async () => {
    try {
      const response = await Api.get(`blog/request_getone/${id}`);
      const responseData = response.data.data;

      console.log("API Response:", responseData);

      if (Array.isArray(responseData)) {
        setcreateForm(responseData);
      } else if (
        responseData &&
        Array.isArray(responseData.yourArrayProperty)
      ) {
        setcreateForm(responseData.yourArrayProperty);
      } else {
        console.error("API response does not contain an array:", responseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = (data) => {
    navigate("projectinnerpage", { state: data });
  };

  return (
    <>
      <div>
        <Row className="p-3">
          {filteredData.length === 0 ? (
            <Col sm={24} className="p-3">
              <p>No matching projects found.</p>
            </Col>
          ) : (
            filteredData.map((data, i) => (
              <Col sm={24} md={24} lg={12} className="p-3">
                <Accordion defaultActiveKey="0">
                  <Card
                    className="Int-card"
                    onClick={() => {
                      handleSubmit(data);
                    }}
                  >
                    <Card.Body>
                      <h4>{data.role}</h4>
                      <p className="Int-para1">{data.projectTitle}</p>
                      <p className="Int-para2">
                        {/* {data.icon1} */}
                        {data.duration}
                      </p>
                      <p className="Int-para2">
                        {/* {data.icon2} */}
                        {data.qualification}
                      </p>
                      <p className="Int-para2">
                        {/* {data.icon3} */}
                        {data.skills}
                      </p>
                      <p className="Int-para2">
                        {/* {data.icon4} */}
                        {data.languages}
                      </p>
                      <p className="Int-para2">
                        {/* {data.icon4} */}
                        {data.userEmail}
                      </p>
                      <Card.Text>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            <p>{data.description}</p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card.Text>
                      <ContextAwareToggle eventKey="1">More</ContextAwareToggle>
                    </Card.Body>
                  </Card>
                </Accordion>
              </Col>
            ))
          )}
        </Row>
      </div>
    </>
  );
}

export default RequestProject;
