import React from "react";
import { Row, Col, Button, Card } from "react-bootstrap";
import { useForm, form } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Api from "../../../Api";

function DetailsForm() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
    form,
  } = useForm();
  const onSubmit = (data) => console.log(data);
  const id = localStorage.getItem("id");
  const handleCreateFormSubmit = async () => {
    const createFormDetails = {
      userId: id,
      Name: getValues().name,
      Date: getValues().date,
      Time: getValues().time,
      Mode: getValues().mode,
      Role: getValues().role,
      AssignTo: getValues().assignTo,
    };
    console.log("create", createFormDetails);
    await Api.post(`schedule/createinterview`, createFormDetails).then(
      (resp) => {
        console.log("resp.data", resp.data);
        navigate(-1);
      }
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit(handleCreateFormSubmit)} form={form}>
        <Row className="ms-5 mt-5">
          <Col lg={8}>
            <label>Name </label>
            <br />
            <input
              className="Create-input"
              {...register("name", { required: true })}
            />{" "}
            <br />
            {errors.name && (
              <span className="input-error">Name is required</span>
            )}
          </Col>{" "}
          <Col lg={8}>
            <label>Date </label>
            <br />
            <input
              type="date"
              className="Create-input"
              {...register("date", { required: true })}
            />{" "}
            <br />
            {errors.date && (
              <span className="input-error">Date is required</span>
            )}
          </Col>
          <Col lg={8}>
            <label>Time </label>
            <br />
            <input
              type="time"
              className="Create-input"
              {...register("time", { required: true })}
            />{" "}
            <br />
            {errors.time && (
              <span className="input-error">Time is required</span>
            )}
          </Col>
          <Col lg={8}>
            <label>Mode </label>
            <br />
            <select
              className="Create-input"
              {...register("mode", { required: true })}
            >
              <option value="">Choose the option </option>
              <option value="a">online</option>
              <option value="b">offline</option>
            </select>
            {/* <input
              {...register("mode", { required: true })}
              className="Create-input"
            />{" "} */}
            <br />
            {errors.mode && (
              <span className="input-error">Mode is required</span>
            )}
          </Col>
          <Col lg={8}>
            <label>Role </label>
            <br />
            <input
              {...register("role", { required: true })}
              className="Create-input"
            />{" "}
            <br />
            {errors.role && (
              <span className="input-error">Role is required</span>
            )}
          </Col>
          <Col lg={8}>
            <label>Assign To </label>
            <br />
            <input
              {...register("assignTo", { required: true })}
              className="Create-input"
            />{" "}
            <br />
            {errors.assignTo && (
              <span className="input-error">Assign is required</span>
            )}
          </Col>
        </Row>
        <div className="submitbuttons p-2">
          <button className="button1 m-2 p-2" type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default DetailsForm;
