import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Checkbox } from "antd";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { BiRupee } from "react-icons/bi";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { CiCalendarDate, CiLocationOn } from "react-icons/ci";
import { GrTechnology } from "react-icons/gr";
import { TfiBag } from "react-icons/tfi";
// import "../../../Components/Css/Students/Studintdetails.scss";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import { Input } from "antd";
import { useNavigate } from "react-router-dom";
import Api from "../../../../Api";
import { SiBookstack } from "react-icons/si";
import { GrLanguage } from "react-icons/gr";
// import { GrLanguage } from "react-icons/gr";
import { AiFillTablet } from "react-icons/ai";

const { Search } = Input;
const onSearch = (value) => console.log(value);

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );
  const isCurrentEventKey = eventKey === activeEventKey;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#EBE6FF",
        paddingTop: "12px",
      }}
    >
      <p onClick={decoratedOnClick}>
        {children}
        {isCurrentEventKey ? <FiChevronUp /> : <FiChevronDown />}
      </p>
    </div>
  );
}

function AppliedJob() {
  const navigate = useNavigate();

  const [createForm, setcreateForm] = useState([]);
  const [deleteForm, setDeleteForm] = useState([]);

  useEffect(() => {
    getCreateForm();
    deletePost();
  }, []);

  const getCreateForm = async () => {
    await Api.get("createForm/getForm").then((res) => {
      setcreateForm(res.data.data);
    });
  };

  const deletePost = (id) => {
    Api.delete(`createForm/deleteForm/${id}`).then((res) => {
      setDeleteForm(res.data.data);
    });
  };

  const onSubmit = (data) => {
    navigate("innerpage", { state: data });
  };

  return (
    <div className="Project-main">
      <Search
        placeholder="input search text"
        onSearch={onSearch}
        className="ms-5"
        style={{
          width: 200,
        }}
      />

      <Row className="Project-row">
        {createForm.map((createForm, i) => (
          <div>
            <Col sm={24} md={24} lg={24} className="Project-col">
              <Accordion defaultActiveKey="0">
                <Card className="Int-card">
                  <Card.Body>
                    <div className="Project-body">
                      <div>
                        <Checkbox>
                          <h5>{createForm.jobTitle}</h5>
                        </Checkbox>

                        <p className="Int-para2">
                          <CiLocationOn /> {createForm.locations}
                        </p>
                        <br />
                        <div className="Project-para-content">
                          <p className="Int-para2">{createForm.para2}</p>
                          <p className="Int-para2">
                            <AiOutlineUnorderedList />
                            {createForm.technology}
                          </p>
                          <p className="Int-para2">
                            <TfiBag />
                            {createForm.experience}
                            years
                          </p>

                          <p className="Int-para2">
                            <BiRupee />
                            {createForm.salaryRange}
                            Lakhs
                          </p>
                        </div>
                      </div>

                      <div className="Project-btns">
                        <button
                          onClick={() => {
                            onSubmit(createForm);
                          }}
                          className="button1 me-3 mb-3 p-2"
                        >
                          View
                        </button>
                        <button
                          className="button2 mb-3 p-2"
                          onClick={() => deletePost(createForm._id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Accordion>
            </Col>
          </div>
        ))}
      </Row>
    </div>
  );
}

export default AppliedJob;
