import React, { useCallback, useState, useRef, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, Select, TimePicker, Button } from "antd";
import { Dropdown } from "primereact/dropdown";
import { useNavigate, useLocation } from "react-router-dom";
import Api from "../../../Api";
import Form from "react-bootstrap/Form";
import { Radio } from "antd";
// import {Dropdown} from "react-bootstrap"
import "./Batch.scss";
import axios from "axios";
import PatternCreate from "../Exampattern/PatternCreate";
const { Option } = Select;

function BatchCreate() {
  const navigate = useNavigate();
  const toast = useRef(null);
  const { state: locationState } = useLocation();
  const [trainer, setTrainerList] = useState();
  const [techList, setTechList] = useState();
  const [courseList, setCourse] = useState();
  const [countryList, setcountryList] = useState([]);
  const [countryValue, setCountryValue] = useState();
  const [stateList, setstateList] = useState([]);
  const [stateValue, setStateValue] = useState();
  const [districtList, setdistrictList] = useState([]);
  const [districtValue, setDistrictValue] = useState();
  const [cityvalue, setCityValue] = useState();
  const [searchQuery, setSearchQuery] = useState();
  const [radio, setRadio] = useState("");
  const [examRadio, setExamRadio] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [examData, setExamData] = useState();
  const [batchError, setBatchError] = useState();
  // const [startDate, setStartDate] = useState();

  // for Exam Pattern

  const [valtech, setValtech] = useState();
  const [tech, setTech] = useState();
  const [valcourse, setValCourse] = useState([]);
  const [Technology, setTechnology] = useState([]);
  const [TechnologyList, setTechnologyList] = useState([]);
  const [retech, setReTech] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [duration, setDuration] = useState({
    hours: 1,
    minutes: 2,
    seconds: 3,
  });
  const [extraweek, setExtraWeek] = useState([
    {
      Week: "",
      limit: [{ Tech: "", count: "" }],
      time: "",
    },
  ]);

  const handleAddWeek = () => {
    setExtraWeek((prev) => [
      ...prev,
      {
        Week: "",
        limit: [{ Tech: "", Count: "" }],

        time: "",
      },
    ]);
    // setShow(true);
  };

  const handleoption = useCallback((index, key, value) => {
    setExtraWeek((prev) => {
      const newValue = [...prev];
      newValue[index][key] = value;
      return newValue;
    });
  }, []);

  //REMOVE OPTION
  const handleRemove = useCallback((idx, setValue) => {
    setExtraWeek((prev) => {
      const newOne = prev.filter((item, index) => {
        if (index != idx) {
          return item;
        }
      });
      // setValue();
      console.log("newOne", newOne);
      return newOne;
    });
  }, []);
  const handleChangeSelect = (value) => {};

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ defaultValues: locationState ? locationState.ele : {} });

  const handleFormSubmit = (data) => {
    try {
      if (locationState) {
        //update
        Api.put(`/Batch/update/${locationState.ele._id}`, data).then((res) => {
          navigate(-1);
        });
      } else {
        //create
        Api.post(`Batch/create`, data)
          .then((resp) => {
            navigate(-1);
          })
          .catch((resp) => {
            console.log("error", resp?.response.data.message);
            setBatchError(resp?.response.data.message);
          });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong,please try again!!!",
        life: 3000,
      });
    }
  };

  const getTrainer = async () => {
    await Api.get("Trainer/get").then((res) => {
      setTrainerList(res.data);
    });
  };

  const getTech = async () => {
    await Api.get("Technology/get").then((res) => {
      setTechList(res.data);
    });
  };
  const getCourse = async () => {
    await Api.get("Course/get").then((res) => {
      setCourse(res.data);
    });
  };
  useEffect(() => {
    getTrainer();
    getTech();
    getCourse();
    getState();
    getCountry();
    getDistrict();
    getExam();
  }, []);

  const batchTime = [
    {
      value: "Only Weekend(Sat-Sun)",
      label: "Only Weekend(Sat-Sun)",
    },
    {
      value: "Monday-Friday",
      label: "Monday-Friday",
    },
    {
      value: "Monday-Saturday",
      label: "Monday-Saturday",
    },
  ];
  const lastDate = [
    {
      value: "5 Days",
      label: "5 Days",
    },
    {
      value: "10 Days",
      label: "10 Days",
    },
    {
      value: "15 Days",
      label: "15 Days",
    },
    {
      value: "20 Days",
      label: "20 Days",
    },
  ];

  const [cityList, setCityList] = useState([]);
  const getCity = (districtId) => {
    setDistrictValue(districtId);
    Api.get(`city/cityById/${districtId}`).then((res) => {
      setCityList(res.data.data);
    });
  };

  // const city = [
  //   { label: "Cumbum", value: "Cumbum" },
  //   { label: "Theni", value: "Theni" },
  //   { label: "Bodi", value: "Bodi" },
  // ];
  const plainOptions = ["Online", "Offline"];

  const getExam = async () => {
    await Api.get("ExamPattern/get").then((res) => {
      setExamData(res.data);
    });
  };
  const getCountry = async () => {
    await Api.get("country/getallcountry").then((res) => {
      setcountryList(res.data.data);
    });
  };

  const getState = (country_id) => {
    console.log("stateList", country_id);
    setCountryValue(country_id);
    Api.get(`state/stateById/${country_id}`).then((res) => {
      setstateList(res.data.data);
    });
  };

  const getDistrict = (state_id) => {
    setStateValue(state_id);
    Api.get(`district/districtById/${state_id}`).then((res) => {
      setdistrictList(res.data.data);
    });
  };
  const onChange1 = ({ target: { value } }) => {
    setRadio(value);
  };
  const onChangeExamRadio = ({ target: { value } }) => {
    console.log("radio1 checked", value);
    setExamRadio(value);
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };
  const handleBatchTime = (value) => {
    console.log(`selected ${value}`);
  };
  const onChangeTime = (time, timeString) => {
    console.log(time, timeString);
  };
  const ExamOptions = ["Yes", "No"];

  const handleStartDateChange = (event) => {
    const startDate = new Date(event.target.value);
    const endDate = new Date(startDate.getTime() + 90 * 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10);
    setValue("endDate", endDate);
    console.log("startDate", startDate);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="Blog-form p-5 ms-5">
          <div className="pages-title mb-3">Create Batch</div>
          <Row className="mb-3">
            <Col xs={24} sm={24} md={8} className="p-2">
              <label className="input-title pe-5">Batch No.</label>
              <br />
              <input
                className="batchInput"
                {...register("batchNo", { required: true })}
              />
              <br />
              {batchError ? (
                <span className="Blog-error">{batchError}</span>
              ) : (
                <>
                  {errors.batchNo && (
                    <span className="Blog-error">
                      {" "}
                      batchNo mode is required
                    </span>
                  )}
                </>
              )}
            </Col>
            <Col xs={24} sm={24} md={8} className="p-2">
              <label className="input-title pe-5">Course Name</label>
              <br />
              {/* <input
                                className="batchInput"
                                {...register("Course", { required: true })}
                            /> */}
              <select
                className="courseSelect"
                {...register("course", { required: true })}
              >
                <option value="" disabled selected>
                  Select a course
                </option>
                {courseList?.map((obj) => (
                  <option value={obj.Course}>{obj.Course}</option>
                ))}
              </select>
              <br />
              {errors.Course && (
                <span className="Blog-error">Course is required</span>
              )}
            </Col>
            <Col xs={24} sm={24} md={8} className="p-2">
              <label className="input-title">start Date</label>
              <input
                className="batchInput"
                type="date"
                onChange={handleStartDateChange}
                {...register("startDate", { required: true })}
              />

              <br />
              {errors.startDate && (
                <span className="Stud-personal-error">
                  start Date is required
                </span>
              )}
            </Col>
            <Col xs={24} sm={24} md={8} className="p-2">
              <label className="input-title">End Date</label>
              <input
                className="batchInput"
                type="date"
                // defaultValue={endDate}
                {...register("endDate", { required: true })}
              />

              <br />
              {errors.endDate && (
                <span className="Stud-personal-error">
                  End Date is required
                </span>
              )}
            </Col>
            {/* <Col xs={24} sm={24} md={8} className="p-2">
                            <label className="input-title">Technology</label>
                            <select className="courseSelect"
                                {...register("Technology", { required: true })} >
                                <option value="" disabled selected>Select a technology</option>
                                {techList?.map(obj =>
                                    <option value={obj.Technology} >{obj.Technology}</option>
                                )}
                            </select>

                            <br />
                            {errors.Technology && (
                                <span className="Stud-personal-error">
                                    End Date is required
                                </span>
                            )}
                        </Col> */}
            <Col xs={24} sm={24} md={8} className="p-2">
              <label>technology</label>
              <br />
              <Select
                mode="multiple"
                allowClear
                name="Technology"
                className="examSelect"
                {...register("Technology", { required: true })}
                // style={{ width: "100%" }}
                placeholder="Please select a technology"
                onChange={(e) => {
                  setTechnology(e);
                  setValue("Technology", e);
                  setTech(e);
                  handleChangeSelect();
                }}
              >
                {techList?.map((list, i) => {
                  return (
                    <Option value={list?.Technology} key={i}>
                      {list?.Technology}
                    </Option>
                  );
                })}
              </Select>

              {Technology.length > 0
                ? null
                : errors.Technology && (
                    <p className="text-danger">technology is required</p>
                  )}
            </Col>

            <Col xs={24} sm={24} md={8} className="p-2">
              <label className="input-title pe-5">Frame Work</label>
              <br />

              {/* <select
                className="courseSelect"
                {...register("frameWork", { required: true })}
              >
                <option value="">Select a frameWork</option>
                <option value="angular">angular</option>
                <option value="next.js">next.js</option>

                {/* {techList?.map((obj) => (
                  <option value={obj.frameWork}>{obj.frameWork}</option>
                ))}
              </select> */}
              <select
                className="courseSelect"
                {...register("frameWork", { required: true })}
              >
                <option value="" disabled selected>
                  Select a frameWork
                </option>

                <option value="nest.js">nest.js</option>
                <option value="next.js">next.js</option>
              </select>
              <br />
              {errors.frameWork && (
                <span className="Blog-error">FrameWork is required</span>
              )}
            </Col>
            <Col xs={24} sm={24} md={8} className="p-2">
              <label className="input-title pe-5">Trainer Name</label>
              <br />

              <select
                className="courseSelect"
                {...register("Trainer", { required: true })}
              >
                <option value="" disabled selected>
                  Select a Trainer
                </option>
                {trainer?.map((obj) => (
                  <option defaultValue={null} value={obj.firstName}>
                    {obj.firstName}
                  </option>
                ))}
              </select>
              <br />
              {errors.Trainer && (
                <span className="Blog-error">Trainer name is required</span>
              )}
            </Col>
            <Col xs={24} sm={24} md={8} className="p-2">
              <label className="input-title pe-5">language</label>
              <br />
              <select
                className="courseSelect"
                {...register("language", { required: true })}
              >
                <option value="" disabled selected>
                  Select a language
                </option>

                <option value="Tamil">Tamil</option>
                <option value="Kanada">Kanada</option>
                <option value="Hindi">Hindi</option>
                <option value="English">English</option>
              </select>
              <br />
              {errors.language && (
                <span className="Blog-error">language is required</span>
              )}
            </Col>
            <Col xs={24} sm={24} md={8} className="p-2">
              <label className="input-title pe-5">Booking Last Date</label>
              <br />
              <select
                className="courseSelect"
                {...register("lastDate", { required: true })}
              >
                <option value="" disabled selected>
                  Select a Mode
                </option>
                {lastDate.map((obj) => (
                  <option value={obj.value}>{obj.value}</option>
                ))}
              </select>
              <br />
              {errors.lastDate && (
                <span className="Blog-error">last Date is required</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={8} className="p-2">
              <label className="input-title pe-5">Batch Schedule</label>
              <br />
              <Select
                className="batchSelectTime"
                // mode="multiple"
                autoSize={true}
                allowClear
                placeholder="Please select"
                onChange={handleBatchTime}
                options={batchTime}
              />
              <br />
              {errors.batchSchedule && (
                <span className="Blog-error">batch schedule is required</span>
              )}
            </Col>

            <Col xs={24} sm={24} md={8} className="p-2">
              <label className="input-title pe-5">Start Timing</label>
              <TimePicker
                className="courseSelect"
                use12Hours
                format="h:mm a"
                onChange={onChangeTime}
              />
            </Col>
            <Col xs={24} sm={24} md={8} className="p-2">
              <label className="input-title pe-5">End Timing</label>
              <TimePicker
                className="courseSelect"
                use12Hours
                format="h:mm a"
                onChange={onChangeTime}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={8} className="p-2">
              <label className="input-title pe-5">Description</label>
              <br />

              <textarea
                className="batchSelectTime"
                {...register("Description", { required: true })}
              />
              <br />
              {errors.Description && (
                <span className="Blog-error">batch schedule is required</span>
              )}
            </Col>
            <Col xs={24} sm={24} md={8} className="p-2">
              <label className="input-title pe-5">Description</label>
              <br />

              <textarea
                className="batchSelectTime"
                {...register("Description", { required: true })}
              />
              <br />
              {errors.Description && (
                <span className="Blog-error">batch schedule is required</span>
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={8} className="p-2 mt-3">
              <label>
                <h4>Course Mode</h4>
              </label>
              <br />
              <Controller
                control={control}
                name="courseMode"
                rules={{ required: true }}
                render={({ field }) => (
                  <Radio.Group {...field}>
                    <Radio onChange={onChange1} value={"Online"}>
                      Online
                    </Radio>
                    <Radio onChange={onChange1} value={"Offline"}>
                      Offline
                    </Radio>
                  </Radio.Group>
                )}
              >
                {" "}
              </Controller>
              <br />
              {errors.courseMode && (
                <span className="Blog-error">Course Mode is required</span>
              )}
            </Col>
          </Row>
          <Row>
            {radio === "Offline" ? (
              <>
                <Col xs={24} sm={24} md={8} className="p-2">
                  <label className="mb-1">Country</label>
                  <Dropdown
                    control={control}
                    filter
                    className="selectDropdown"
                    value={countryValue}
                    options={countryList}
                    {...register("country", { required: true })}
                    optionLabel={"name"}
                    optionValue={"id"}
                    onChange={(e) => {
                      {
                        console.log("eee", e.target.value);
                      }
                      getState(e.target.value);
                    }}
                  />
                  {/* <Controller
                                        control={control}
                                        name="country"
                                        rules={{ required: true }}
                                        render={({ field }) => (

                                            <Dropdown
                                                {...field}
                                                className="selectDropdown"
                                                filter
                                                options={countryList?.map(item => item?.name)}
                                                // value={countryList?.map(item => item.id)}
                                                optionLabel={"name"}
                                                optionValue={"id"}
                                                onChange={(e,) => {
                                                    { console.log("vvvv", e.target.value) }
                                                    getState(e.target.value)
                                                }}
                                            // onChange={(e, { value }) => {
                                            //     { console.log("vvvv", value) }
                                            //     getState(value)}}
                                            />
                                        )}

                                    /> */}

                  {errors.country && (
                    <p className="error-text-color">country is required</p>
                  )}
                </Col>
                <Col xs={24} sm={24} md={8} className="p-2">
                  <label className="mb-1">State</label>&nbsp;
                  <Dropdown
                    filter
                    className="selectDropdown"
                    value={stateValue}
                    options={stateList}
                    {...register("state", { required: true })}
                    optionLabel={"name"}
                    optionValue={"id"}
                    onChange={(e) => getDistrict(e.value)}
                  />
                  {/* <Controller
                                        control={control}
                                        name="country"
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Dropdown
                                                {...field}
                                                className="selectDropdown"
                                                filter
                                                options={stateList}
                                                value={stateValue}
                                                optionLabel={"name"}
                                                optionValue={"id"}
                                                onChange={(e) => getDistrict(e.value)}
                                            />
                                        )}
                                    /> */}
                  {errors.state && (
                    <p className="error-text-color">State is required</p>
                  )}
                </Col>
                <Col xs={24} sm={24} md={8} className="p-2">
                  <label className="mb-1">District</label>
                  <Dropdown
                    filter
                    className="selectDropdown"
                    value={districtValue}
                    options={districtList}
                    {...register("district", { required: true })}
                    optionLabel={"name"}
                    optionValue={"id"}
                    onChange={(e) => getCity(e.value)}
                  />

                  {/* <Controller
                                        control={control}
                                        name="country"
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Dropdown

                                                className="selectDropdown"
                                                filter
                                                options={districtList}
                                                value={districtValue}
                                                optionLabel={"name"}
                                                optionValue={"id"}
                                                onChange={(e) => setDistrictValue(e.value)}
                                            />
                                        )}
                                    /> */}

                  {errors.district && (
                    <p className="error-text-color">District is required</p>
                  )}
                </Col>
                <Col xs={24} sm={24} md={8} className="p-2">
                  <label className="mb-1">City</label>
                  <Dropdown
                    filter
                    className="selectDropdown"
                    value={cityvalue}
                    options={cityList}
                    {...register("City", { required: true })}
                    optionLabel={"CityName"}
                    optionValue={"_id"}
                    onChange={(e) => setCityValue(e.value)}
                  />
                  {errors.City && (
                    <p className="error-text-color">City is required</p>
                  )}
                </Col>
                <Col xs={24} sm={24} md={8} className="p-2">
                  <label className="input-title pe-5">Actual Fees</label>
                  <br />
                  <input
                    className="batchInput"
                    {...register("Actualfees", { required: true })}
                  />
                  <br />
                  {errors.fees && (
                    <span className="Blog-error">Fees is required</span>
                  )}
                </Col>
                <Col xs={24} sm={24} md={8} className="p-2">
                  <label className="input-title pe-5">Offer Fees </label>
                  <br />
                  <input
                    className="batchInput"
                    {...register("Offerfees", { required: true })}
                  />
                  <br />
                  {errors.fees && (
                    <span className="Blog-error">Fees is required</span>
                  )}
                </Col>
              </>
            ) : null}

            {radio === "Online" ? (
              <>
                <Col xs={24} sm={24} md={8} offset={4} className="p-2">
                  <label className="input-title pe-5">Actual Fees</label>
                  <br />
                  <input
                    className="batchInput"
                    {...register("Actualfees", { required: true })}
                  />
                  <br />
                  {errors.fees && (
                    <span className="Blog-error">Fees is required</span>
                  )}
                </Col>
                <Col xs={24} sm={24} md={8} className="p-2">
                  <label className="input-title pe-5">Offer Fees </label>
                  <br />
                  <input
                    className="batchInput"
                    {...register("Offerfees", { required: true })}
                  />
                  <br />
                  {errors.fees && (
                    <span className="Blog-error">Fees is required</span>
                  )}
                </Col>
              </>
            ) : null}
          </Row>

          {/* <div> // For Exam Pattern// </div> */}

          <Row>
            {/* <Row> */}
            <Col md={24} lg={12} className="p-2 mt-3">
              <h4>Do you want to add Exam Pattern</h4>
              <Radio.Group
                options={ExamOptions}
                onChange={onChangeExamRadio}
                value={examRadio}
              />
              <br />
              {/* <Radio.Group options={optionsWithDisabled} onChange={onChange2} value={value2} /> */}
            </Col>
            {/* </Row> */}
            <Row>
              {examRadio === "Yes" ? (
                <>
                  {/* <Col xs={24} sm={24} md={8} className="p-2">
                                            <label className="input-title pe-5">Course</label>
                                            <br />

                                            <select className="courseSelect"
                                                {...register("Course", { required: true })}>
                                                <option value="" disabled selected>Select a course</option>
                                                {courseList?.map(obj => <option value={obj.Course}>{obj.Course}</option>
                                                )}
                                            </select>
                                            {errors.Course && (
                                                <p className="error-text-color">Course is required</p>
                                            )}
                                        </Col>

                                        <Col xs={24} sm={24} md={8} className="p-2">
                                            <label>technology</label>
                                            <br />
                                            <Select
                                                mode="multiple"
                                                // allowClear
                                                name="Technology"
                                                className="examSelect"
                                                {...register("examTechnology", { required: true })}
                                                // style={{ width: "100%" }}
                                                placeholder="Please select a technology"
                                                onChange={(e) => {
                                                    setTechnology(e);
                                                    setValue("examTechnology", e);
                                                    setTech(e);
                                                    handleChangeSelect()
                                                }}
                                            >
                                                {techList?.map((list, i) => {
                                                    return (
                                                        <Option value={list?.Technology} key={i}>
                                                            {list?.Technology}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>


                                            {Technology.length > 0
                                                ? null
                                                : errors.examTechnology && (
                                                    <p className="text-danger">technology is required</p>
                                                )}
                                        </Col>
                                        <Col xs={24} sm={24} md={8} className="p-2">
                                            <label className="input-title pe-5">Number of Weeks</label>
                                            <br />
                                            <select
                                                className="examSelect"
                                                {...register("NumberOfWeeks", { required: true })}
                                            >
                                                <option value="">select Weeks</option>
                                                <option value="1">1 </option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5 </option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9 </option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
          
                                            </select>
                                            <br />
                                            {errors.NumberOfWeeks && (
                                                <span className="Blog-error">Week required</span>
                                            )}
                                        </Col> */}

                  {/* </Row> */}
                  <Row className="mb-3">
                    <Col sm={24} md={24} className="Stud-sem-col p-2">
                      {extraweek.map((item, index) => {
                        return (
                          <div>
                            <Row>
                              <Col
                                xs={24}
                                sm={24}
                                md={8}
                                className="Stud-sem-col"
                              >
                                <label className="input-title"> Week </label>
                                <br />
                                <select
                                  // rows="3"
                                  value={extraweek[index].Week}
                                  className="examSelect"
                                  // Name="Question"
                                  {...register(`weeks[${index}].Week`, {
                                    required: true,
                                    onChange: (e) => {
                                      handleoption(
                                        index,
                                        "Week",
                                        e.target.value
                                      );
                                    },
                                  })}
                                >
                                  <option value="">select Weeks</option>
                                  <option value="1">week1 </option>
                                  <option value="2">week2</option>
                                  <option value="3">week3</option>
                                  <option value="4">week4</option>
                                  <option value="5">week5 </option>
                                  <option value="6">week6</option>
                                  <option value="7">week7</option>
                                  <option value="8">week8</option>
                                  <option value="9">week9 </option>
                                  <option value="10">week10</option>
                                  <option value="11">week11</option>
                                  <option value="12">week12</option>
                                  {/* <option value="13">week13</option>
                <option value="14">week14</option>
                <option value="15">week15</option> */}
                                </select>

                                <br></br>
                                {errors.week && (
                                  <span className="input-error">
                                    weeks need
                                  </span>
                                )}
                              </Col>

                              <Col xs={24} sm={24} md={8} className="p-2">
                                <label className="input-title pe-5">
                                  Technology
                                </label>
                                <br />

                                <select
                                  allowClear
                                  className="examSelect"
                                  // value={(e) => e.target.value}
                                  {...register(
                                    `weeks[${index}].limit[0].Tech`,
                                    {
                                      required: false,
                                    }
                                  )}
                                >
                                  {tech?.map((list, i) => {
                                    return (
                                      <option value={list} key={i}>
                                        {list}
                                      </option>
                                    );
                                  })}
                                </select>
                                <select
                                  allowClear
                                  className="examSelect"
                                  // value={(e) => e.target.value}
                                  {...register(
                                    `weeks[${index}].limit[1].Tech`,
                                    {
                                      required: false,
                                    }
                                  )}
                                >
                                  {tech?.map((list, i) => {
                                    return (
                                      <option value={list} key={i}>
                                        {list}
                                      </option>
                                    );
                                  })}
                                </select>
                                <select
                                  allowClear
                                  className="examSelect"
                                  // value={(e) => e.target.value}
                                  {...register(
                                    `weeks[${index}].limit[2].Tech`,
                                    {
                                      required: false,
                                    }
                                  )}
                                >
                                  {tech?.map((list, i) => {
                                    return (
                                      <option value={list} key={i}>
                                        {list}
                                      </option>
                                    );
                                  })}
                                </select>
                              </Col>
                              <Col md={24} lg={4} className="p-2">
                                <label className="input-title pe-5">
                                  Count
                                </label>
                                <br />
                                <textarea
                                  rows="3"
                                  // value={extraweek[index].limit[0].count}
                                  className="input-field mt-2"
                                  // Name="Question"
                                  {...register(
                                    `weeks[${index}].limit[0].Count`,
                                    {
                                      required: true,
                                      // onChange: (e) => {
                                      //   handleoption(
                                      //     index,
                                      //     "limit[0].Count",
                                      //     e.target.value
                                      //   );
                                      // },
                                    }
                                  )}
                                />
                                <textarea
                                  rows="3"
                                  // value={extraweek[index].limit[1].count}
                                  className="input-field mt-1.5"
                                  // Name="Question"
                                  {...register(
                                    `weeks[${index}].limit[1].Count`,
                                    {
                                      required: true,
                                      onChange: (e) => {
                                        handleoption(
                                          index,
                                          "limit[1].Count",
                                          e.target.value
                                        );
                                      },
                                    }
                                  )}
                                />
                                <textarea
                                  rows="3"
                                  // value={extraweek[index].limit[2].count}
                                  className="input-field mt-1.5"
                                  // Name="Question"
                                  {...register(
                                    `weeks[${index}].limit[2].Count`,
                                    {
                                      required: true,
                                      // onChange: (e) => {
                                      //   handleoption(
                                      //     index,
                                      //     "limit[2].Count",
                                      //     e.target.value
                                      //   );
                                      // },
                                    }
                                  )}
                                />
                              </Col>

                              <Col md={24} lg={4} className="p-2">
                                <label className="input-title pe-5">Time</label>
                                <br />
                                <textarea
                                  rows="3"
                                  value={extraweek[index].time}
                                  className="input-field"
                                  // Name="Question"
                                  {...register(`weeks[${index}].time`, {
                                    required: true,
                                    onChange: (e) => {
                                      handleoption(
                                        index,
                                        "time",
                                        e.target.value
                                      );
                                    },
                                  })}
                                />
                              </Col>

                              {extraweek.length > 1 && (
                                <Col
                                  xs={24}
                                  sm={24}
                                  md={8}
                                  className="p-2 mt-3"
                                >
                                  <div className="remove-buttontag">
                                    <Button
                                      // variant="danger"
                                      className="remove-button mt-2 "
                                      disabled={extraweek.length <= 1}
                                      onClick={() => {
                                        handleRemove(index, setValue);
                                      }}
                                    >
                                      Remove Week
                                    </Button>
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </div>
                        );
                      })}
                      <br></br>
                      {errors.extraweek && (
                        <span className="input-error">weeks need</span>
                      )}
                    </Col>
                  </Row>
                  <div>
                    <button
                      type="button"
                      className="addbutton m-2 "
                      onClick={handleAddWeek}
                    >
                      Add week
                    </button>
                  </div>
                </>
              ) : null}
            </Row>
          </Row>
          <div className="submitbuttons p-2">
            <button className="button1 m-2 p-2" type="submit">
              Submit
            </button>
            <button className="button2 m-2 p-2" type="reset">
              Reset
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default BatchCreate;
