import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Row, Col } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import Api from "../../../Api";
import Form from "react-bootstrap/Form";
import { Dropdown } from "primereact";

function QuestionCreate() {
  const [details, setDetails] = useState([]);
  const [tech, setTech] = useState();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: locationState ? locationState.ele : {} });

  const addQuestion = {
    question: "",
    options: [
      { value: "", isCorrect: "false" },
      { value: "", isCorrect: "false" },
      { value: "", isCorrect: "false" },
      { value: "", isCorrect: "false" },
    ],
  };

  const [question, setQuestion] = useState([addQuestion]);

  const handleAddQuestion = () => {
    setQuestion((prev) => [...prev, addQuestion]);
  };

  const handleAddOption = (questionIndex) => {
    const updatedQuestions = [...question];
    updatedQuestions[questionIndex].options.push({
      value: "",
      isCorrect: "false",
    });
    setQuestion(updatedQuestions);
  };

  const getTechnology = () => {
    Api.get("/Technology/get").then((resp) => {
      setDetails(resp.data);
    });
  };

  const getById = async () => {
    if (locationState?.ele?.id) {
      await Api.get(`QuestionBank/getid/${locationState.ele.id}`).then((res) => {
        setQuestion(res.data.questions || [addQuestion]);
        setValue("Technology", res.data.Technology);
        setValue("totalWeeks.week", res.data.totalWeeks?.week);
      });
    }
  };

  useEffect(() => {
    getTechnology();
    getById();
  }, []);

  const handleFormSubmit = async (data) => {
    const payload = {
      Technology: data.Technology,
      questions: question.map((q) => ({
        question: q.question,
        options: q.options.map((opt) => ({
          value: opt.value,
          isCorrect: opt.isCorrect === "true",
        })),
      })),
      totalWeeks: {
        week: data.totalWeeks.week,
      },
    };

    try {
      if (locationState) {
        await Api.put(`/QuestionBank/update/${locationState.ele.id}`, payload);
      } else {
        await Api.post(`QuestionBank/create`, payload);
        navigate(-1);
      }
    } catch (error) {
      console.error("Error during question creation", error);
    }
  };

  return (
    <div className="question-create-container">
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="form-wrapper p-5 ms-5">
          <h2 className="page-title mb-3">Create Question</h2>

          <Row gutter={[16, 16]} className="mb-3">
            <Col xs={24} md={12}>
              <label className="input-title">Technology</label>
              <Dropdown
                filter
                className="blog-input"
                value={tech}
                options={details}
                optionLabel="Technology"
                optionValue="id"
                {...register("Technology", { required: true })}
                onChange={(e) => {
                  setTech(e.value);
                  setValue("Technology", e.value);
                }}
              />
              {errors.Technology && <p className="error-text">Technology is required</p>}
            </Col>

            <Col xs={24} md={12}>
              <label className="input-title">Number of Weeks</label>
              <select
                className="blog-input"
                {...register("totalWeeks.week", { required: true })}
              >
                <option value="">Select Weeks</option>
                {Array.from({ length: 20 }, (_, i) => (
                  <option key={i} value={i + 1}>{`Week ${i + 1}`}</option>
                ))}
              </select>
              {errors.totalWeeks && <span className="error-text">Weeks are required</span>}
            </Col>
          </Row>

          {question.map((q, questionIndex) => (
            <div key={questionIndex} className="question-section">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <textarea
                    className="blog-input"
                    placeholder="Enter Question"
                    value={q.question}
                    onChange={(e) => {
                      const updatedQuestions = [...question];
                      updatedQuestions[questionIndex].question = e.target.value;
                      setQuestion(updatedQuestions);
                    }}
                  />
                </Col>
              </Row>

              {q.options.map((option, optionIndex) => (
                <Row key={optionIndex} gutter={[16, 16]} className="option-row">
                  <Col span={16}>
                    <input
                      className="blog-input"
                      placeholder={`Option ${optionIndex + 1}`}
                      value={option.value}
                      onChange={(e) => {
                        const updatedQuestions = [...question];
                        updatedQuestions[questionIndex].options[optionIndex].value =
                          e.target.value;
                        setQuestion(updatedQuestions);
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <select
                      value={option.isCorrect}
                      onChange={(e) => {
                        const updatedQuestions = [...question];
                        updatedQuestions[questionIndex].options[optionIndex].isCorrect =
                          e.target.value;
                        setQuestion(updatedQuestions);
                      }}
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </Col>
                </Row>
              ))}
            </div>
          ))}

         
        </div>
      </Form>
      <div className="submit-buttons p-2">
            <button className="btn-submit m-2 p-2" type="submit">Submit</button>
            <button
              className="btn-reset m-2 p-2"
              type="reset"
              onClick={() => reset({ inputList: [], question: [] })}
            >
              Reset
            </button>
          </div>

      <style jsx>{`
        .form-wrapper {
          max-width: 100%;
          margin: 0 auto;
        }
        .input-title {
          font-weight: bold;
        }
        .page-title {
          font-size: 1.5rem;
        }
        .blog-input {
          width: 100%;
        }
        .question-section {
          margin-bottom: 20px;
        }
        .option-row {
          margin-top: 10px;
        }
        .submit-buttons {
          display: flex;
          justify-content: flex-start;
          paddingTop: 10%!important;
        }
        .btn-submit,
        .btn-reset {
          background-color: #007bff;
          color: white;
          border: none;
        }
      `}</style>
    </div>
  );
}

export default QuestionCreate;
