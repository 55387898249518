import { Button, Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Api from "../../../../Api";
import moment from "moment";

const InstituteList = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState([]);
  const navigate = useNavigate();

  function createdAtSorter(a, b) {
    if (a.createdAt < b.createdAt) {
      return -1;
    }
    if (a.createdAt > b.createdAt) {
      return 1;
    }
    return 0;
  }
  const columns = [
    {
      title: "Registration Id",
      dataIndex: "RegistrationId",
    },
    {
      title: "Institute Name",
      dataIndex: "instituteName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (text, record) =>
        new Date(record.createdAt).toLocaleDateString("en-GB"),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div>

            <div className="data-list-main">
                <h4 className="data-list-header pages-title">Institute List</h4>
                <h3 className='pages-title'>Total Records: {dataCount}</h3>
                <div className="data-list-top mt-4 py-4 px-3">
                    <Input className="data-list-search ps-2" placeholder="search by college name or Registration Id" onChange={handleChange} enterButton />
                </div>


                {searchText ?
                    <div className="data-list-table">
                        <Table
                            scroll={{ x: true }}
                            columns={columns}
                            dataSource={search}
                            // pagination={false}
                            className="mt-2"
                        />
                    </div> :
                    <div className="data-list-table">
                        <Table
                            scroll={{ x: true }}
                            columns={columns}
                            dataSource={data}
                            // pagination={false}
                            className="mt-2"
                        />
                    </div>
                }
            </div>
        </div>
      ),
    },
  ];

  const getData = async () => {
    await Api.get("institute/institute_get").then((res) => {
      setData(res.data.data);
    });
  };
  useEffect(() => {
    getData();
    const results = data.filter(
      (item) =>
        item?.instituteName
          ?.toLowerCase()
          .includes(searchText.toLowerCase().trim()) ||
        item?.RegistrationId?.toLowerCase().includes(
          searchText.toLowerCase().trim()
        )
    );
    setSearch(results);
  }, [searchText]);

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  let dataCount = data.length;

  return (
    <div>
      <div className="data-list-main">
        <h4 className="data-list-header pages-title">Institute List</h4>
        <h3 className="pages-title">Total Records: {dataCount}</h3>
        <div
          className="data-list-top mt-4 py-4 px-3 "
          style={{ backgroundColor: "#c5c5c5" }}
        >
          <Input
            className="data-list-search ps-2"
            placeholder="search by college name or Registration Id"
            onChange={handleChange}
            enterButton
          />
        </div>

        {searchText ? (
          <div className=".Datalist-Table">
            <Table
              columns={columns}
              dataSource={search}
              // pagination={false}
              className="mt-2"
            />
          </div>
        ) : (
          <div className=".Datalist-Table">
            <Table
              columns={columns}
              dataSource={data}
              // pagination={false}
              className="mt-2"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default InstituteList;
