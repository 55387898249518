import React from "react"
import "../Vendor/Package.css";
import { useNavigate } from 'react-router-dom';

// import "../../../Pages/Professional/CoursePackage/CoursePackage.css"
import { Col, Row, Card, Button, Container } from "react-bootstrap";

const CoursePackage = () => {
    const navigate=useNavigate()
    return (
        <div >
            <Row className=" ms-5" style={{ marginTop: "10%" }} >
                <Col lg={3} md={6} className=" mb-4" >
                    <Card className="CoursePackage ">
                        <Container>
                            <div>
                                <center>
                                    <h4 className="mt-2" style={{ fontWeight: "bold" }}>Classic</h4></center>
                                <h3 className="CoursePackage_heading"> <span>&#8377;</span> 349.92 <del className="CoursePackage_del" style={{ fontSize: "20px", color: "gray" }}>&#8377;500</del><span style={{ fontSize: "20px" }} className="CoursePackage_heading">/month</span></h3>
                                <center style={{ color: "green", fontWeight: "500" }}>
                                    <div>You Save &#8377; 150.08</div>
                                </center>
                                <div className="CoursePackage_ButtonContainer">
                                    <Button className="CoursePackage_Button">Upgrade</Button>
                                </div>
                                <div className="square_lists mt-4" style={{ marginTop: "10px" }}>
                                    <ul className="square">
                                        <li className="square_li">10 Job Post</li>
                                        <li className="square_li">10 Freelancer</li>
                                        <li className="square_li">10 Contact Staffing</li>
                                        <li className="square_li">10 Vendor Lists</li>
                                        <li className="square_li">10 Employee Timesheet</li>
                                        <li className="square_li">10 Attendance Tracking</li>
                                        <li className="square_li">10 Interview Tracking </li>
                                    </ul>
                                </div>
                            </div>
                        </Container>
                    </Card>
                </Col>
                <Col lg={3} md={6} className=" mb-4">

                    <Card className="CoursePackage ">
                        <Container>
                            <div>
                                <center>
                                    <h4 className="mt-2" style={{ fontWeight: "bold" }}>Gold</h4></center>
                                <h3 className="CoursePackage_heading"> <span>&#8377;</span> 749.92 <del className="CoursePackage_del" style={{ fontSize: "20px", color: "gray" }}>&#8377;1000</del><span style={{ fontSize: "20px" }} className="CoursePackage_heading">/month</span></h3>
                                <center style={{ color: "green", fontWeight: "500" }}>
                                    <div>You Save &#8377; 250.08</div>
                                </center>
                                <div className="CoursePackage_ButtonContainer">
                                    <Button className="CoursePackage_Button">Upgrade</Button>
                                </div>
                                <div className="mt-4">
                                    <ul className="square">
                                        <li className="square_li">20 Job Post</li>
                                        <li className="square_li">20 Freelancer</li>
                                        <li className="square_li">20 Contact Staffing</li>
                                        <li className="square_li">20 Vendor Lists</li>
                                        <li className="square_li">20 Employee Timesheet</li>
                                        <li className="square_li">20 Attendance Tracking</li>
                                        <li className="square_li">20 Interview Tracking </li>
                                    </ul>
                                </div>
                            </div>
                        </Container>
                    </Card>
                </Col>
                <Col lg={3} md={6} className=" mb-4">
                    <Card className="CoursePackage ">
                        <Container>
                            <div>
                                <center>
                                    <h4 className="mt-2" style={{ fontWeight: "bold" }}>Platinum</h4>
                                </center>
                                <h3 className="CoursePackage_heading"> <span>&#8377;</span> 2,499.92 <del className="CoursePackage_del" style={{ fontSize: "20px", color: "gray" }}>&#8377;3,000</del><span style={{ fontSize: "20px" }} className="CoursePackage_heading">/month</span></h3>
                                <center style={{ color: "green", fontWeight: "500" }}>
                                    <div>You Save &#8377; 500.08</div>
                                </center>

                                <div className="CoursePackage_ButtonContainer">
                                    <Button className="CoursePackage_Button">Upgrade</Button>
                                </div>
                                <div className="mt-4">
                                    <ul className="square">
                                        <li className="square_li">30 Job Post</li>
                                        <li className="square_li">30 Freelancer</li>
                                        <li className="square_li">30 Contact Staffing</li>
                                        <li className="square_li">30 Vendor Lists</li>
                                        <li className="square_li">30 Employee Timesheet</li>
                                        <li className="square_li">30 Attendance Tracking</li>
                                        <li className="square_li">30 Interview Tracking </li>
                                    </ul>
                                </div>
                            </div>
                        </Container>
                    </Card>
                </Col>
                <Col lg={3} md={6} className=" mb-4">
                    <Card className="CoursePackage ">
                        <Container>
                            <div >
                                <center>
                                    <h4 className="mt-2" style={{ fontWeight: "bold" }}>Exclusive</h4>
                                </center>
                                <h3 className="CoursePackage_heading" > <span>&#8377;</span> 4,999 <del className="CoursePackage_del" style={{ fontSize: "20px", color: "gray" }}>&#8377;7,000</del><span style={{ fontSize: "20px" }} className="CoursePackage_heading">/month</span></h3>
                                <center style={{ color: "green", fontWeight: "500" }}>
                                    <div>You Save &#8377; 2,001</div>
                                </center>
                                <div className="CoursePackage_ButtonContainer">
                                    <Button className="CoursePackage_Button">Upgrade</Button>
                                </div>
                                <div className="mt-4">
                                    <ul className="square">
                                        <li className="square_li">40 Job Post</li>
                                        <li className="square_li">40 Freelancer</li>
                                        <li className="square_li">40 Contact Staffing</li>
                                        <li className="square_li">40 Vendor Lists</li>
                                        <li className="square_li">40 Employee Timesheet</li>
                                        <li className="square_li">40 Attendance Tracking</li>
                                        <li className="square_li">40 Interview Tracking </li>
                                    </ul>
                                </div>
                            </div>
                        </Container>
                    </Card>
                </Col>

            </Row>
            <div className="upgrade_column mt-3">
              <Button className="button1" onClick={()=>{
                  navigate("/vendordashboard")
              }}>Back</Button>
              </div>
        </div>
    )
}
export default CoursePackage;