// import React, { useState } from "react";
// import { useForm } from "react-hook-form";
// import { Row, Col } from "antd";
// import { Form } from "react-bootstrap";
// import "./CompanyListForm.css";
// import Api from "../../../Api";
// import { useNavigate } from "react-router-dom";

// function CompanyListForm() {
//   const {
//     register,
//     handleSubmit,
//     getValues,
//     formState: { errors },
//   } = useForm();
//   const onSubmit = () => {
//     handleCompanySubmit();
//   };

//   const [resume, setResume] = useState({});

//   const [resres, setResres] = useState({
//     status: null,
//     message: null,
//   });
//   const navigate = useNavigate();

//   const handleCompanySubmit = async () => {
//     const CompanyDetails = {
//       Title: getValues().Companytitle,
//       Description: getValues().Companydescription,
//     };
//     const data = new FormData();
//     data.append("resume", getValues().resume[0]);
//     console.log("getValues(", getValues().resume[0]);
//     console.log("getValuessssssssssssssssssss(", getValues().resume);

//     await Api.post("companylist/uploadImage", data).then(async (res) => {
//       if (res.status == 201) {
//         CompanyDetails.resume = res.data.path;
//         await Api.post(`companylist/createCompanyList`, CompanyDetails)
//           .then((res) => {
//             setResres({
//               status: res.data?.status,
//               message: res.data?.message,
//             });
//             localStorage.setItem("userId", res.data.data._id);
//             setTimeout(() => {
//               navigate("/login");
//             }, 700);
//           })
//           .catch((err) => {
//             setResres({
//               status: err?.response.data?.status,
//               message: err?.response.data?.message,
//             });
//           });
//     }})
//       // const uploadFile = async()=>{
//       //   const formData = new FormData();
//       //   formData.append('file', file);
//       //   formData.append('fileName', fileName);
//       //   try{
//       //        Api.post('http://localhost:3000/upload',
//       //        formData
//       //        )
//       //   }catch(ex){

//       //   }

//       //           }

//       // const SelectFile = async (event) => {
//       //   const file = event.target.files[0];
//       //   const type = file?.type?.split("/")[0];
//       //   const base64 = await convertBase64(file);
//       //   setImage(base64);
//       // };

//       // const convertBase64 = (file) => {
//       //   return new Promise((resolve, reject) => {
//       //     const fileReader = new FileReader();
//       //     fileReader.readAsDataURL(file);
//       //     fileReader.onload = () => {
//       //       resolve(fileReader.result);
//       //     };
//       //     fileReader.onerror = (error) => {
//       //       reject(error);
//       //     };
//       //   });
//       // };

//       const handleCompanySubmit = async () => {
//         const CompanyDetails = {
//           Title: getValues().Companytitle,
//           Description: getValues().Companydescription,
//           // Image: image,
//         };
//         console.log("CompanyDetails", CompanyDetails);

//         await Api.post(`companylist/createCompanyList`, CompanyDetails).then((resp) => {
//           console.log("resp.data", resp.data);
//         });
//       };

//   return (
//     <div>
//       <form onSubmit={handleSubmit(handleCompanySubmit)}>
//         <div className="Company-form p-5 ms-5">
//           <div className="pages-title mb-3">Company List</div>
//           <Row className="mb-3">
//             <Col md={24} lg={12} className="p-2">
//               <label className="input-title pe-5">Company Title</label>
//               <br />
//               <input
//                 style={{ width: "85%", outline: "none" }}
//                 className="Company-input"
//                 {...register("Companytitle", { required: true })}
//               />
//               <br />
//               {errors.Companytitle && (
//                 <span className="Company-error">Company Title required</span>
//               )}
//             </Col>
//           </Row>
//           <Row className="mb-3">
//             <Col sm={24} md={24} lg={12} className="p-2">
//               <label className="input-title pe-5">Company Description</label>
//               <br />
//               <textarea
//                 style={{ width: "85%", outline: "none" }}
//                 rows="1"
//                 className="Company-textArea p-2"
//                 {...register("Companydescription", { required: true })}
//               />
//               <br />
//               {errors.Companydescription && (
//                 <span className="Company-error">
//                   Company Description required
//                 </span>
//               )}
//             </Col>
//           </Row>
//           <Row>
//             <Col sm={24} md={24} lg={12} className="p-2">
//               <label className="input-title pe-5">Company Image </label>
//               <Form.Group controlId="Resume" className="mb-3 py-2">
//                 <Form.Label>Image</Form.Label>
//                 <Form.Control
//                   accept=".png"
//                   type="file"
//                   {...register("resume")}
//                   onChange={(e) => {
//                     setResume(e.target.files[0]);
//                     console.log("e.target.files[0]", e.target.files[0]);
//                   }}
//                 />
//               </Form.Group>
//               {errors.resume && (
//                 <span className="text-danger">Company Logo is required</span>
//               )}
//             </Col>
//           </Row>
//           <div className="submitbuttons p-2">
//             <button
//               className="button1 m-2 p-2"
//               type="submit"
//               onClick={handleSubmit(onSubmit)}
//             >
//               Submit
//             </button>
//             <button className="button2 m-2 p-2" type="reset">
//               Reset
//             </button>
//           </div>
//         </div>
//       </form>
//     </div>
//   );
// }}

// export default CompanyListForm;

import React, { useCallback, useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Select, Modal } from "antd";
import { Form } from "react-bootstrap";
import "../AdminCompanyList/CompanyListForm.css";
import Api from "../../../Api";
import axios from "axios";
import { useParams } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";

function StudentBlogDetails() {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const [blogList, setBlogList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState("");

  const { id } = useParams();

  const navigate = useNavigate();

  // const getCompany = () => {
  //   Api.get(`employer/Employer_get/${id}`).then((res) => {
  //     setCompanyList(res.data);
  //   });
  // };

  // const userId = localStorage.getItem("personalId");

  const getBlogById = () => {
    Api.get(`blog/getblogbyid/${id}`).then((res) => {
      setBlogList(res.data.data);
    });
  };

  console.log("blogList", blogList);

  const approveBlog = async () => {
    await Api.put(`blog/updateBlogbyid/${id}`, {
      status: true,
    }).then((resp) => {
      console.log("resp", resp);
    });
  };
  const rejectBlog = async () => {
    await Api.delete(`blog/deleteBlog/${id}`).then((resp) => {
      console.log("deleted");
    });
    navigate("/admin/studentsBlog");
  };

  useEffect(() => {
    // getCompany();
    getBlogById();
  }, []);

  const handleOk = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <div className="viewseminar_container p-4">
        <div className="pages-title mb-3">Student Blog Details</div>
        <div className="seminar_card">
          <div className="flex padcheck">
            <div>
              <div className="inner_container_wrap">
                <div className="seminar_card_date">
                  <span className="pages-title">{blogList.Tag}</span>
                </div>
                <h4 className="seminar_card_title">Description:</h4>
                <h6 className="seminar_card_title">{blogList.description}</h6>

                {/* <div>
                  <h4 className="seminar_card_title">Description:</h4>
                  <h6 className="seminar_card_title">
                    {blogList.establishedYear}
                  </h6>
                </div> */}
                {/* <div className="inner_container_wrap_flex">
                  <div className="seminar_card_date_wrapper flex">
                    <p className="seminar_card_technlogies">
                      <span>react</span>
                      <span>node</span>
                    </p>
                  </div>
                </div> */}
                {/* <p className="seminar_card_brief_des">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugiat,
                  incidunt.
                </p> */}
                <div className="flex">
                  {/* <div className="seminar_card_details">
                    <p className="seminar_card_access">Access :Open for all</p>
                    <p className="seminar_card_mode">Mode :Online</p>
                  </div> */}
                  <div className="seminar_card_contact">
                    <p className="seminar_card_name">
                      Contact person :{blogList.studentName}
                    </p>
                    <p className="seminar_card_name">
                      Email Address :{blogList.email}
                    </p>
                    {/* <p className="seminar_card_number">
                      Contact number :{blogList.phone}
                    </p> */}
                  </div>
                </div>
                {/* <p className="seminar_card_link">
                  Registration Link :https://www.adada.com
                </p> */}
                <div className="seminar_card_button_wrapper">
                  {blogList.status === true ? (
                    <button className="seminar_card_button loginbutton">
                      Approved
                    </button>
                  ) : (
                    <>
                      <button
                        className="seminar_card_button loginbutton"
                        onClick={() => {
                          approveBlog();
                          setOpenModal(true);
                          setData(blogList?.status);
                        }}
                      >
                        Click to Approve
                      </button>
                      <button
                        className="seminar_card_button loginbutton"
                        onClick={() => {
                          rejectBlog();
                          // setOpenModal(true);
                          setData(blogList?.status);
                        }}
                      >
                        Click to Reject
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        visible={openModal}
        // maskStyle={{ width: "0px" }}
        // bodyStyle={{ height: "80px" }}
        onCancel={handleOk}
        footer={null}
        // width="27%"
        // style={{ position: "absolute", marginTop: "-5%", marginLeft: "70%", gridRow: "auto", }}
      >
        {/* <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}> */}

        <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
        <span style={{ paddingLeft: "3%" }}>Successfully Approved</span>
        {/* </div> */}
      </Modal>
    </div>
  );
}

export default StudentBlogDetails;
