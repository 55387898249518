import React, { useState, useEffect } from "react";
import { Col, Form, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import Api from "../../../Api.js";
import axios from "axios";
import "./Qualificationinfo.scss";

function QualificationInfo() {
  const [studentType, setStudentType] = useState("");
  const [standard, setStandard] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [schoolNames, setSchoolNames] = useState([]);
  const [board, setBoard] = useState("");
  const [tenthDetails, setTenthDetails] = useState({
    schoolName: "",
    percentage: "",
    yearOfPassing: "",
  });
  const [eleventhDetails, setEleventhDetails] = useState({
    schoolName: "",
    percentage: "",
    yearOfPassing: "",
  });
  const [twelfthDetails, setTwelfthDetails] = useState({
    schoolName: "",
    percentage: "",
    yearOfPassing: "",
  });
  const [ugDegree, setUgDegree] = useState("");
  const [pgDegree, setPgDegree] = useState("");
  const [phdDegree, setPhdDegree] = useState("");
  const [ugMajor, setUgMajor] = useState("");
  const [pgMajor, setPgMajor] = useState("");
  const [phdMajor, setPhdMajor] = useState("");
  const [ugUniversity, setUgUniversity] = useState("");
  const [ugCollegeName, setUgCollegeName] = useState("");
  const [ugCgpa, setUgCgpa] = useState(0);
  const [pgUniversity, setPgUniversity] = useState("");
  const [pgCollegeName, setPgCollegeName] = useState("");
  const [phdUniversity, setPhdUniversity] = useState("");
  const [phdCollege, setPhdCollege] = useState("");
  const [school10Name, setSchool10Name] = useState("");
  const [board10, setBoard10] = useState("");
  const [percentage10, setPercentage10] = useState(0);
  const [year10Passing, setYear10Passing] = useState(0);
  const [school12Name, setSchool12Name] = useState("");
  const [board12, setBoard12] = useState("");
  const [percentage12, setPercentage12] = useState(0);
  const [year12Passing, setYear12Passing] = useState(0);
  const [phdYearOfCompletion, setPhdYearOfCompletion] = useState(0);
  const [pgCgpa, setPgCgpa] = useState(0);
  const userId = localStorage.getItem("userId");
  const [pgDegreeVisible, setPgDegreeVisible] = useState(false);
  const [phdDegreeVisible, setPhdDegreeVisible] = useState(false);

  const handlePgCheckbox = (event) => {
    setPgDegreeVisible(event.target.checked);
  };

  const handlePhdCheckbox = (event) => {
    setPhdDegreeVisible(event.target.checked);
  };

  const ugMajors = {
    "B.A.": ["English", "History", "Political Science"],
    "B.Sc.": ["Mathematics", "Physics", "Chemistry", "Biology"],
    "B.Com.": ["Finance", "Accounting"],
    "B.E.": ["Mechanical Engineering", "Civil Engineering"],
    "B.Tech.": ["Computer Science", "Information Technology"],
    "B.B.A.": ["Business Management", "Entrepreneurship"],
    "B.Arch.": ["Architecture", "Urban Planning"],
    "B.Pharm.": ["Pharmacology", "Pharmaceutical Chemistry"],
    "B.C.A.": ["Software Development", "Information Systems"],
  };

  const pgMajors = {
    "M.A.": ["English Literature", "History"],
    "M.Sc.": ["Physics", "Chemistry", "Mathematics"],
    "M.Com.": ["Accounting", "Finance"],
    "M.E.": ["Computer Engineering", "Mechanical Engineering"],
    "M.Tech.": ["Software Engineering", "Data Science"],
    "M.B.A.": ["Marketing", "Human Resources", "Operations Management"],
    "M.Pharm.": ["Clinical Pharmacy", "Pharmaceutical Analysis"],
    "M.C.A.": ["Computer Applications", "Data Analytics"],
  };

  const phdMajors = {
    "PhD in Arts": ["Philosophy", "Linguistics"],
    "PhD in Science": ["Quantum Physics", "Organic Chemistry"],
    "PhD in Engineering": ["Robotics", "Nanotechnology"],
    "PhD in Commerce": ["Corporate Finance", "Economics"],
  };

  useEffect(() => {
    const fetchQualificationInfo = async () => {
      try {
        const response = await Api.get(`signup/student_getone/${userId}`);
        console.log(
          "Fetched Qualification Info:",
          response.data.data.QualificationInfo
        );
        const data = response?.data?.data?.educationalInfo;

        setStudentType(data?.type);

        if (data?.type === "school") {
          setStandard(data?.school?.currentStandard);
          setSchoolName(data?.school?.schoolName);
          setBoard(data?.school?.board);
          setTenthDetails({
            schoolName: data?.school?.schoolName10,
            percentage: data?.school?.percentage10,
            yearOfPassing: data?.school?.year10Passing,
          });
          setEleventhDetails({
            schoolName: data?.school?.schoolName11,
            percentage: data?.school?.percentage11,
            yearOfPassing: data?.school?.year11Passing,
          });
          setTwelfthDetails({
            schoolName: data?.school?.schoolName12,
            percentage: data?.school?.percentage12,
            yearOfPassing: data?.school?.year12Passing,
          });
        } else if (data?.type === "college") {
          setUgDegree(data?.college?.ugDegree?.degreeName);
          setUgMajor(data?.college?.ugDegree?.major);
          setUgUniversity(data?.college?.ugDegree?.university);
          setUgCollegeName(data?.college?.ugDegree?.collegeName);
          setUgCgpa(data?.college?.ugDegree?.cgpa);

          setPgDegree(data?.college?.pgDegree?.degreeName);
          setPgMajor(data?.college?.pgDegree?.major);
          setPgUniversity(data?.college?.pgDegree?.university);
          setPgCollegeName(data?.college?.pgDegree?.collegeName);
          setPgCgpa(data?.college?.pgDegree?.cgpa);

          setPhdDegree(data?.college?.phd?.title);
          setPhdMajor(data?.college?.phd?.major);
          setPhdUniversity(data?.college?.phd?.university);
          setPhdCollege(data?.college?.phd?.collegeName);

          setSchool10Name(data?.college?.school10Name);
          setBoard10(data?.college?.board10);
          setPercentage10(data?.college?.percentage10);
          setYear10Passing(data?.college?.year10Passing);

          setSchool12Name(data?.college?.school12Name);
          setBoard12(data?.college?.board12);
          setPercentage12(data?.college?.percentage12);
          setYear12Passing(data?.college?.year12Passing);
        }
      } catch (error) {
        console.error("Error fetching qualification info:", error);
      }
    };

    const fetchSchools = async () => {
      try {
        const response = await Api.get("/fields/getSchoolList");
        setSchoolNames(response?.data?.data);
      } catch (error) {
        console.error("Error fetching schools:", error);
      }
    };

    fetchQualificationInfo();
    fetchSchools();
  }, [userId]);

  const handleUpdate = async () => {
    const data = {
      type: studentType,
      school: {
        currentStandard: standard,
        schoolName,
        board,
        percentage10: tenthDetails.percentage,
        year10Passing: tenthDetails.yearOfPassing,
        schoolName10: tenthDetails.schoolName,
        percentage11: eleventhDetails.percentage,
        year11Passing: eleventhDetails.yearOfPassing,
        percentage12: twelfthDetails.percentage,
        year12Passing: twelfthDetails.yearOfPassing,
      },
      college: {
        school10Name,
        board10,
        percentage10,
        year10Passing,
        school12Name,
        board12,
        percentage12,
        year12Passing,
        ugDegree: {
          degreeName: ugDegree,
          major: ugMajor,
          university: ugUniversity,
          collegeName: ugCollegeName,
          cgpa: ugCgpa,
        },
        pgDegree: {
          degreeName: pgDegree,
          major: pgMajor,
          university: pgUniversity,
          collegeName: pgCollegeName,
          cgpa: pgCgpa,
        },
        phd: {
          title: phdDegree,
          major: phdMajor,
          university: phdUniversity,
          collgeName: phdCollege,
          yearOfCompletion: undefined,
        },
      },
    };

    try {
      const response = await Api.put(
        `http://localhost:8088/signup/education/${userId}`,
        data
      );
      console.log("Data updated:", response.data);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  return (
    <div className="container-fluid qua-info">
      <Col md={9} className="qualification-info-tab">
      <Tabs
        defaultActiveKey="school"
        id="qualification-info-tabs"
        className="mb-3 qualification-tabs" 

      >
        <Tab  eventKey="school" title="School" className="qualification-tab">
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group controlId="currentStandard">
                  <Form.Label>Current Standard</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-graduation-cap"></i>
                    </InputGroup.Text>
                    <Form.Control
                      as="select"
                      className="custom-size edu-qualification-select"
                      value={standard}
                      onChange={(e) => setStandard(Number(e.target.value))}
                    >
                      <option value="">Select</option>
                      {Array.from({ length: 12 }, (_, i) => i + 1).map(
                        (std) => (
                          <option key={std} value={std}>
                            {std}
                          </option>
                        )
                      )}
                    </Form.Control>
                  </div>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="schoolName">
                  <Form.Label>School Name</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-school"></i>
                    </InputGroup.Text>
                    <Form.Control
                      as="select"
                      className="custom-size edu-qualification-select"
                      value={schoolName}
                      onChange={(e) => setSchoolName(e.target.value)}
                    >
                      <option value="">Select School</option>
                      {schoolNames.map((school, index) => (
                        <option key={index} value={school.schoolName}>
                          {school.schoolName}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="board">
                  <Form.Label>Board</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-chalkboard-teacher"></i>
                    </InputGroup.Text>
                    <Form.Control
                      as="select"
                      className="custom-size edu-qualification-select"
                      value={board}
                      onChange={(e) => setBoard(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="State Board">State Board</option>
                      <option value="CBSE">CBSE</option>
                      <option value="ICSE">ICSE</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <h4 className="text-center mt-4">10th Standard Details</h4>
            <Row>
              <Col md={6}>
                <Form.Group controlId="tenthSchoolName">
                  <Form.Label>10th School Name</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-school"></i>
                    </InputGroup.Text>
                    <Form.Control
                      as="select"
                      className="custom-size edu-qualification-select"
                      value={tenthDetails.schoolName}
                      onChange={(e) =>
                        setTenthDetails({
                          ...tenthDetails,
                          schoolName: e.target.value,
                        })
                      }
                    >
                      <option value="">Select School</option>
                      {schoolNames.map((school, index) => (
                        <option key={index} value={school.schoolName}>
                          {school.schoolName}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="tenthPercentage">
                  <Form.Label>10th Percentage</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-percentage"></i>
                    </InputGroup.Text>
                    <Form.Control
                      className="custom-size"
                      type="number"
                      value={tenthDetails.percentage}
                      onChange={(e) =>
                        setTenthDetails({
                          ...tenthDetails,
                          percentage: e.target.value,
                        })
                      }
                      placeholder="10th Percentage"
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="tenthYearOfPassing">
                  <Form.Label>10th Year of Passing</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-calendar-alt"></i>
                    </InputGroup.Text>
                    <Form.Control
                      className="custom-size"
                      type="number"
                      value={tenthDetails.yearOfPassing}
                      onChange={(e) =>
                        setTenthDetails({
                          ...tenthDetails,
                          yearOfPassing: e.target.value,
                        })
                      }
                      placeholder="Year of Passing"
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <h4 className="text-center mt-4">11th Standard Details</h4>
            <Row>
              <Col md={6}>
                <Form.Group controlId="eleventhPercentage">
                  <Form.Label>11th Percentage</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-percentage"></i>
                    </InputGroup.Text>
                    <Form.Control
                      className="custom-size"
                      type="number"
                      value={eleventhDetails.percentage}
                      onChange={(e) =>
                        setEleventhDetails({
                          ...eleventhDetails,
                          percentage: e.target.value,
                        })
                      }
                      placeholder="11th Percentage"
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="eleventhYearOfPassing">
                  <Form.Label>11th Year of Passing</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-calendar-alt"></i>
                    </InputGroup.Text>
                    <Form.Control
                      className="custom-size"
                      type="number"
                      value={eleventhDetails.yearOfPassing}
                      onChange={(e) =>
                        setEleventhDetails({
                          ...eleventhDetails,
                          yearOfPassing: e.target.value,
                        })
                      }
                      placeholder="Year of Passing"
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <h4 className="text-center mt-4">12th Standard Details</h4>
            <Row>
              <Col md={6}>
                <Form.Group controlId="twelfthPercentage">
                  <Form.Label>12th Percentage</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-percentage"></i>
                    </InputGroup.Text>
                    <Form.Control
                      className="custom-size"
                      type="number"
                      value={twelfthDetails.percentage}
                      onChange={(e) =>
                        setTwelfthDetails({
                          ...twelfthDetails,
                          percentage: e.target.value,
                        })
                      }
                      placeholder="12th Percentage"
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="twelfthYearOfPassing">
                  <Form.Label>12th Year of Passing</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-calendar-alt"></i>
                    </InputGroup.Text>
                    <Form.Control
                      className="custom-size"
                      type="number"
                      value={twelfthDetails.yearOfPassing}
                      onChange={(e) =>
                        setTwelfthDetails({
                          ...twelfthDetails,
                          yearOfPassing: e.target.value,
                        })
                      }
                      placeholder="Year of Passing"
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Tab>

        <Tab eventKey="college" title="College">
          <Form>
            <Row>
              <h4 className="text-center my-3">10th Details</h4>
              <Col md={6}>
                <Form.Group controlId="school10Name">
                  <Form.Label>10th School Name</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-school"></i>
                    </InputGroup.Text>
                    <Form.Control
                      className="custom-size edu-qualification-input"
                      type="text"
                      value={school10Name}
                      onChange={(e) => setSchool10Name(e.target.value)}
                      placeholder="Enter 10th School Name"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="percentage10">
                  <Form.Label>10th Percentage</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-percentage"></i>
                    </InputGroup.Text>
                    <Form.Control
                      className="custom-size"
                      type="text"
                      placeholder="Enter 10th Percentage"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="board10">
                  <Form.Label>10th Board</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-chalkboard-teacher"></i>
                    </InputGroup.Text>
                    <Form.Control className="custom-size" as="select">
                      <option>Select 10th Board</option>
                      <option>CBSE</option>
                      <option>ICSE</option>
                      <option>State Board</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="year10Passing">
                  <Form.Label>10th Year of Passing</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-calendar-alt"></i>
                    </InputGroup.Text>
                    <Form.Control
                      className="custom-size"
                      type="text"
                      placeholder="Enter 10th Year of Passing"
                    />
                  </div>
                </Form.Group>
              </Col>

              <h4 className="text-center my-3">12th Details</h4>
              <Col md={6}>
                <Form.Group controlId="school12Name">
                  <Form.Label>12th School Name</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-school"></i>
                    </InputGroup.Text>
                    <Form.Control
                      className="custom-size"
                      type="text"
                      placeholder="Enter 12th School Name"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="percentage12">
                  <Form.Label>12th Percentage</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-percentage"></i>
                    </InputGroup.Text>
                    <Form.Control
                      className="custom-size"
                      type="text"
                      placeholder="Enter 12th Percentage"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="board12">
                  <Form.Label>12th Board</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-chalkboard-teacher"></i>
                    </InputGroup.Text>
                    <Form.Control className="custom-size" as="select">
                      <option>Select 12th Board</option>
                      <option>CBSE</option>
                      <option>ICSE</option>
                      <option>State Board</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="year12Passing">
                  <Form.Label>12th Year of Passing</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-calendar-alt"></i>
                    </InputGroup.Text>
                    <Form.Control
                      className="custom-size"
                      type="text"
                      placeholder="Enter 12th Year of Passing"
                    />
                  </div>
                </Form.Group>
              </Col>

              <h4 className="text-center my-3">Undergraduate (UG) Details</h4>
              <Col md={6}>
                <Form.Group controlId="ugDegree">
                  <Form.Label>UG Degree Name</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-graduation-cap"></i>
                    </InputGroup.Text>
                    <Form.Control className="custom-size" as="select">
                      <option>Select UG Degree</option>
                      <option>B.A.</option>
                      <option>B.Sc.</option>
                      <option>B.Com.</option>
                      <option>B.Tech.</option>
                      <option>B.E.</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="ugMajor">
                  <Form.Label>UG Major</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-book"></i>
                    </InputGroup.Text>
                    <Form.Control className="custom-size" as="select">
                      <option>Select UG Major</option>
                      <option>English</option>
                      <option>Mathematics</option>
                      <option>Physics</option>
                      <option>Computer Science</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="ugCollegeName">
                  <Form.Label>UG College Name</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-school"></i>
                    </InputGroup.Text>
                    <Form.Control
                      className="custom-size"
                      type="text"
                      placeholder="Enter UG College Name"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="ugCgpa">
                  <Form.Label>UG CGPA</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-percent"></i>
                    </InputGroup.Text>
                    <Form.Control
                      className="custom-size"
                      type="number"
                      placeholder="Enter UG CGPA"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group controlId="ugUniversity">
                  <Form.Label>UG University</Form.Label>
                  <div className="input-icon-container">
                    <InputGroup.Text>
                      <i className="fas fa-university"></i>
                    </InputGroup.Text>
                    <Form.Control
                      className="custom-size"
                      type="text"
                      placeholder="Enter UG University"
                    />
                  </div>
                </Form.Group>
              </Col>

              <h4 className="text-center my-3">
                Postgraduate (PG) Details (Optional)
              </h4>
              <div className="checkbox-container col-10">
                <label className="c-f-s">
                  Do you have a Postgraduate degree?{" "}
                </label>
                <input
                  className="checkbox-edu"
                  type="checkbox"
                  checked={pgDegree !== ""}
                  onChange={(e) => setPgDegree(e.target.checked ? "M.A." : "")}
                />
              </div>

              {pgDegree && (
                <>
                  <Col md={6}>
                    <Form.Group controlId="pgDegree">
                      <Form.Label>PG Degree Name</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-graduation-cap"></i>
                        </InputGroup.Text>
                        <Form.Control className="custom-size" as="select">
                          <option>Select PG Degree</option>
                          <option>M.A.</option>
                          <option>M.Sc.</option>
                          <option>M.Tech.</option>
                        </Form.Control>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="pgMajor">
                      <Form.Label>PG Major</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-book"></i>
                        </InputGroup.Text>
                        <Form.Control className="custom-size" as="select">
                          <option>Select PG Major</option>
                          <option>Marketing</option>
                          <option>Data Science</option>
                          <option>Computer Science</option>
                        </Form.Control>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="pgUniversity">
                      <Form.Label>PG University</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-university"></i>
                        </InputGroup.Text>
                        <Form.Control
                          className="custom-size"
                          type="text"
                          placeholder="Enter PG University"
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="pgCgpa">
                      <Form.Label>PG CGPA</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-percent"></i>
                        </InputGroup.Text>
                        <Form.Control
                          className="custom-size"
                          type="number"
                          placeholder="Enter PG CGPA"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group controlId="pgCollegeName">
                      <Form.Label>PG College Name</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-school"></i>
                        </InputGroup.Text>
                        <Form.Control
                          className="custom-size"
                          type="text"
                          placeholder="Enter PG College Name"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </>
              )}

              <h4 className="text-center my-3">PhD Details (Optional)</h4>
              <div className="checkbox-container col-10">
                <label className="c-f-s">Do you have a PhD degree? </label>
                <input
                  className="checkbox-edu"
                  type="checkbox"
                  checked={phdDegree !== ""}
                  onChange={(e) => setPhdDegree(e.target.checked ? "PhD" : "")}
                />
              </div>

              {phdDegree && (
                <>
                  <Col md={6}>
                    <Form.Group controlId="phdDegree">
                      <Form.Label>PhD Degree Name</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-graduation-cap"></i>
                        </InputGroup.Text>
                        <Form.Control className="custom-size" as="select">
                          <option>Select PhD Degree</option>
                          <option>PhD</option>
                        </Form.Control>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="phdMajor">
                      <Form.Label>PhD Major</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-book"></i>
                        </InputGroup.Text>
                        <Form.Control className="custom-size" as="select">
                          <option>Select PhD Major</option>
                          <option>Computer Science</option>
                          <option>Physics</option>
                        </Form.Control>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="phdUniversity">
                      <Form.Label>PhD University</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-university"></i>
                        </InputGroup.Text>
                        <Form.Control
                          className="custom-size"
                          type="text"
                          placeholder="Enter PhD University"
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="phdYearOfCompletion">
                      <Form.Label>Year of Completion</Form.Label>
                      <div className="input-icon-container">
                        <InputGroup.Text>
                          <i className="fas fa-calendar"></i>
                        </InputGroup.Text>
                        <Form.Control
                          className="custom-size"
                          type="number"
                          placeholder="Enter Year of Completion"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>
          </Form>
        </Tab>
      </Tabs>
      </Col>
      <div className="col-md-12 update-btn">
        <button className="quali-info-btn" onClick={handleUpdate}>
          Update Information
        </button>
      </div>
    </div>
  );
}

export default QualificationInfo;
