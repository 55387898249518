export const host = "https://careerblitz.in/api/";
// export const host = "http://localhost:4000/api/";

export const setAvatarRoute = `${host}api/auth/setavatar`;

export const recieveMessageRoute = `${host}message/receive_message`;
export const recieveOneMessageRoute = `${host}message/receive_one_message`;
export const allUsersRoute = `${host}student/student_getone/`;
export const sendMessageRoute = `${host}message/post`;
export const createChatList = `${host}chatlist/createchatList`;
