import React from "react";
// import "./ClassicCV.css";

const ClassicCV = () => {
  return (
    <div className="classic-cv">
      <header className="cv-header">
        <h1>Jane Smith</h1>
        <p>Email: jane@example.com | Phone: +91 123456789</p>
      </header>

      <section className="cv-section">
        <h2>Objective</h2>
        <p>
          Experienced professional looking for a position in project management where my skills in leadership and coordination will be utilized.
        </p>
      </section>

      <section className="cv-section">
        <h2>Work Experience</h2>
        <ul>
          <li>Project Manager, Company X (2018-2022)</li>
          <li>Assistant Manager, Company Y (2016-2018)</li>
        </ul>
      </section>

      <section className="cv-section">
        <h2>Education</h2>
        <p>Master of Business Administration - XYZ University (2014-2016)</p>
      </section>
    </div>
  );
};

export default ClassicCV;
