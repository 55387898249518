import React from "react";
import StudentDashboard from "../Pages/Student/StudentDashboard";
import MyProfile from "../Pages/Student/MyProfile";
import AreaInterest from "../Pages/Student/AreaInterest";
import Learning from "../Pages/Student/Learning";
import Blog from "../Pages/Student/Blog";
import Result from "../Pages/Student/Test/Result/Result";
import InternshipRequest from "../Pages/Student/InternshipRequest";
import IntershipDetails from "../Pages/Student/IntershipDetails";
import ChangeProfile from "../Pages/Student/ChangeProfile";
import StudentIntern from "../Pages/Student/StudentDashboard/Intern/intern";
import Request from "../Pages/Student/Project/Request";
import Pending from "../Pages/Student/Project/pending";
import Home from "../Pages/Student/Home/Home";
import Approved from "../Pages/Student/Project/Approved";
import Viewprojects from "../Pages/Student/Project/Viewprojects";
import Seminar from "../Pages/Student/Seminar/Seminar";
import SeminarsInnerPage from "../Pages/Student/Seminar/SeminarInnerPage";
import Quize from "../Pages/Student/Exam/Quiz";
import StudentScore from "../Pages/Student/StudentDashboard/Score/score";
import Questions from "../Pages/Student/Exam/Questions";
import Quizexam from "../Pages/Student/Exam/quizexam";
import Login from "../Pages/Student/RedirectPage/Login";
import InternshipPage from "../Pages/Student/IntershipDetails/InternshipPage";
// import Skilltest from "../Pages/Student/Skilltest/Skilltest";
import { Navigate } from "react-router-dom";
import Response from "../Pages/Student/Project/Response";
import MenuProject from "../Pages/Student/Project/MenuProject";
import Exam from "../Pages/Student/Exam/Exam";
import MenuEvent from "../Pages/Student/Events/MenuEvent";
import Curriculam from "../Pages/Student/Learning/Curriculam";
import OperatingSystem from "../Pages/Student/Learning/CurriculamCoursesPages/OperatingSystem";
import CourseLandingPage from "../Pages/Student/Learning/CourseLandingPage";
import MyCV from "../Pages/Student/StudentCV/MyCV";
import CreateProfile from "../Pages/Student/MyProfile";
import CounsellorLandingPage from "../../src/HomePageContent/CounsellorLandingPage";

const role = localStorage.getItem("role");

const StudentRoutes = [
  // {
  //   path: "",
  //   name: "MyProfile",
  //   element: role === "student" ? <MyProfile /> : <Navigate to="/login" />,
  // },
  {
    path: "",
    name: "dashboard",
    element:
      role === "student" ? <StudentDashboard /> : <Navigate to="/login" />,
  },
  {
    path: "profile",
    name: "MyProfile",
    element: role === "student" ? <MyProfile /> : <Navigate to="/login" />,
  },
  {
    path: "createprofile",
    name: "MyProfile",
    element: role === "student" ? <CreateProfile /> : <Navigate to="/login" />,
  },
  {
    path: "areaInterest",
    name: "AreaInterest",
    element: role === "student" ? <AreaInterest /> : <Navigate to="/login" />,
  },
  {
    path: "learning",
    name: "Learning",
    element: role === "student" ? <Learning /> : <Navigate to="/login" />,
  },
  {
    path: "curriculam",
    name: "curriculam",
    element: role === "student" ? <Curriculam /> : <Navigate to="/login" />,
  },
  {
    path: "operatingSystem/:id",
    name: "operatingSystem",
    element:
      role === "student" ? <OperatingSystem /> : <Navigate to="/login" />,
  },
  {
    path: "blog",
    name: "Blog",
    element: role === "student" ? <Blog /> : <Navigate to="/login" />,
  },
  {
    path: "/student/learning/courselandingpage/:id",
    name: "CourseLandingPage",
    element:
      role === "student" ? <CourseLandingPage /> : <Navigate to="/login" />,
  },
  // {
  //   path: "test",
  //   name: "test",
  //   element: <Test />,
  // },
  // {
  //   path: "test/quiz",
  //   name: "quiz",
  //   element: <Quiz />,
  // },
  {
    path: "test/result",
    name: "result",
    element: role === "student" ? <Result /> : <Navigate to="/login" />,
  },

  {
    path: "internshipRequest",
    name: "InternshipRequest",
    element:
      role === "student" ? <InternshipRequest /> : <Navigate to="/login" />,
  },
  {
    path: "InternshipPage",
    name: "InternshipPage",
    element: role === "student" ? <InternshipPage /> : <Navigate to="/login" />,
  },

  {
    path: "changeProfile",
    name: "ChangeProfile",
    element: role === "student" ? <ChangeProfile /> : <Navigate to="/login" />,
  },
  {
    path: "/student/intern",
    name: "studentintern",
    element: role === "student" ? <StudentIntern /> : <Navigate to="/login" />,
  },
  {
    path: "request",
    name: "Request",
    element: role === "student" ? <Request /> : <Navigate to="/login" />,
  },
  {
    path: "response",
    name: "Response",
    element: role === "student" ? <Response /> : <Navigate to="/login" />,
  },
  {
    path: "pending",
    name: "Pending",
    element: role === "student" ? <Pending /> : <Navigate to="/login" />,
  },
  {
    path: "approved",
    name: "Approved",
    element: role === "student" ? <Approved /> : <Navigate to="/login" />,
  },
  {
    path: "request/viewproject",
    name: "viewproject",
    element: role === "student" ? <Viewprojects /> : <Navigate to="/login" />,
  },

  {
    path: "exam",
    name: "exam",
    element: role === "student" ? <Exam /> : <Navigate to="/login" />,
  },
  {
    path: "project",
    name: "project",
    element: role === "student" ? <MenuProject /> : <Navigate to="/login" />,
  },
  {
    path: "/student/seminar",
    name: "seminar",
    element: role === "student" ? <Seminar /> : <Navigate to="/login" />,
  },
  {
    path: "/student/SeminarInnerPage",
    name: "seminarInnerPage",
    element:
      role === "student" ? <SeminarsInnerPage /> : <Navigate to="/login" />,
  },
  {
    path: "InternshipDetails",
    name: "InternshipDetails",
    element:
      role === "student" ? <IntershipDetails /> : <Navigate to="/login" />,
  },
  {
    path: "quiz",
    name: "quiz",
    element: role === "student" ? <Quize /> : <Navigate to="/login" />,
  },
  {
    path: "/student/quiz/examquestions",
    name: "examquestions",
    element: role === "student" ? <Questions /> : <Navigate to="/login" />,
  },
  {
    path: "quiz/Quizexam",
    name: "Quizexam",
    element: role === "student" ? <Quizexam /> : <Navigate to="/login" />,
  },
  {
    path: "/student/score",
    name: "studentscore",
    element: role === "student" ? <StudentScore /> : <Navigate to="/login" />,
  },
  {
    path: "login",
    name: "login",
    element: role === "student" ? <Login /> : <Navigate to="/login" />,
  },
  // {
  //   path: "skilltest",
  //   name: "skilltest",
  //   element: <Skilltest/>
  // },
  {
    path: "events",
    name: "events",
    element: role === "student" ? <MenuEvent /> : <Navigate to="/login" />,
  },
  {
    path: "myCV",
    name: "myCV",
    element: role === "student" ? <MyCV /> : <Navigate to="/login" />,
  },
];
export default StudentRoutes;
