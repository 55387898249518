import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import StudentSignupForm from "./StudentSignupForm";
import "./SignUpForm.css";
import ProfessionalSignupForm from "./ProfessionalSignupForm";
import TrainerSignupForm from "./TrainerSignupForm";
import Student from "../Images/student.jpg";
import Professional from "../Images/recruit.jpg";
import Trainer from "../Images/card3.jpg";
import EmployerSignupForm from "./EmployerSignupForm";
import CollegeSignupForm from "./CollegeSignupForm";
import VendorSignupForm from "./VendorSignupForm";

const SignupPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const aa = useParams();

  const [role, setRole] = useState(aa.role || "Student");
  const [a, setA] = useState(state);
  const [resetValue, setResetValue] = useState([]);

  const onChange = (e) => {
    const selectedRole = e.target.value;
    navigate(`/signup/${selectedRole}`);
    setRole(selectedRole);
    setA(selectedRole);
    setResetValue([]);
  };

  const renderLeftContent = () => {
    switch (role) {
      case "Student":
        return <StudentContent />;
      case "Professional":
        return <ProfessionalContent />;
      case "Company":
        return <CompanyContent />;
      case "College":
        return <CollegeContent />;
      case "Vendor":
        return <VendorContent />;
      default:
        return <DefaultContent />;
    }
  };
  const getBackgroundImage = (role) => {
    switch (role) {
      case "Student":
        return Student;
      case "Professional":
        return Professional;
      case "Trainer":
        return Trainer;
      // case "Company":
      //   return companyImage;
      // case "College":
      //   return collegeImage;
      // case "Vendor":
      //   return vendorImage;
      default:
        return Student;
    }
  };

  const renderRightForm = () => {
    switch (role) {
      case "Student":
        return <StudentSignupForm />;
      case "Professional":
        return <ProfessionalSignupForm />;
      case "Trainer":
        return <TrainerSignupForm />;
      case "Company":
        return <EmployerSignupForm />;
      case "College":
        return <CollegeSignupForm />;
      case "Vendor":
        return <VendorSignupForm />;
      default:
        return <StudentSignupForm />;
    }
  };

  return (
    <div
      className="cont-parent "
      style={{
        height: "100vh",
        width: "100vw",
        padding: "30px",
        overflowX: "hidden",
      }}
    >
      <Container fluid>
        <Row className="d-flex gap-5">
          <Col md={8} className="left-column">
            <img
              src={getBackgroundImage(role)}
              alt={`${role} background`}
              className="img-fluid imges"
              style={{
                width: "100%",
                height: "88vh",
                objectFit: "cover",
                borderRadius: "20px",
              }}
            />
            <div className="left-content">{renderLeftContent()}</div>
          </Col>
          <Col
            md={4}
            className="right-cont "
            style={{ padding: "30px", borderRadius: "20px" }}
          >
            <div className="role-select-top">
              <Form.Group controlId="roleSelect">
                <Form.Label>Select Role</Form.Label>
                <Form.Control as="select" onChange={onChange} value={a}>
                  <option value={"Student"}>Student</option>
                  <option value={"Professional"}>Professional</option>
                  <option value={"Trainer"}>Trainer</option>
                  <option value={"Company"}>Company</option>
                  <option value={"Vendor"}>Vendor</option>
                  <option value={"College"}>College</option>
                </Form.Control>
              </Form.Group>
            </div>

            <div className="right-form">{renderRightForm()}</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const StudentContent = () => (
  <div>
    <ul className="benefits-list"></ul>
  </div>
);

const ProfessionalContent = () => (
  <div>
    <h3>Professional Opportunities</h3>
    <p>
      Enhance your career with certifications and industry-recognized training.
    </p>
  </div>
);

const CompanyContent = () => (
  <div>
    <h3>Company Partnerships</h3>
    <p>Partner with us to train your workforce with top-tier skills.</p>
  </div>
);

const CollegeContent = () => (
  <div>
    <h3>College Collaborations</h3>
    <p>Collaborate with us to offer advanced courses to your students.</p>
  </div>
);

const VendorContent = () => (
  <div>
    <h3>Vendor Programs</h3>
    <p>
      Join as a vendor to offer your services to our learners and professionals.
    </p>
  </div>
);

const DefaultContent = () => (
  <div>
    <h3>Select a Role</h3>
    <p>Please choose a role from the dropdown to view more information.</p>
  </div>
);

export default SignupPage;
