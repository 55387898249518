import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { DatePicker, TimePicker, Checkbox, Radio, Input } from 'antd';

const { TextArea } = Input;

const Training = () => {
  const { register, handleSubmit, control, watch, formState: { errors } } = useForm();
  const [durationType, setDurationType] = useState('weekly');

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label>Course Type</label>
        <Radio.Group {...register('courseType', { required: true })}>
          <Radio value="seminar">Seminar</Radio>
          <Radio value="course">Course</Radio>
        </Radio.Group>
        {errors.courseType && <p>This field is required</p>}
      </div>

      <div>
        <label>Course Title</label>
        <Input {...register('courseTitle', { required: true })} />
        {errors.courseTitle && <p>This field is required</p>}
      </div>

      <div>
        <label>Description</label>
        <TextArea {...register('description', { required: true })} />
        {errors.description && <p>This field is required</p>}
      </div>

      <div>
        <label>Duration</label>
        <Radio.Group onChange={(e) => setDurationType(e.target.value)} value={durationType}>
          <Radio value="weekly">Weekly</Radio>
          <Radio value="monthly">Monthly</Radio>
        </Radio.Group>
      </div>

      {durationType === 'weekly' && (
        <div>
          <div>
            <label>Start Date</label>
            <Controller
              control={control}
              name="startDate"
              render={({ field }) => <DatePicker {...field} />}
              rules={{ required: true }}
            />
            {errors.startDate && <p>This field is required</p>}
          </div>

          <div>
            <label>End Date</label>
            <Controller
              control={control}
              name="endDate"
              render={({ field }) => <DatePicker {...field} />}
              rules={{ required: true }}
            />
            {errors.endDate && <p>This field is required</p>}
          </div>

          <div>
            <label>Days</label>
            <Checkbox.Group {...register('days', { required: true })}>
              <Checkbox value="Monday">Monday</Checkbox>
              <Checkbox value="Tuesday">Tuesday</Checkbox>
              <Checkbox value="Wednesday">Wednesday</Checkbox>
              <Checkbox value="Thursday">Thursday</Checkbox>
              <Checkbox value="Friday">Friday</Checkbox>
              <Checkbox value="Saturday">Saturday</Checkbox>
              <Checkbox value="Sunday">Sunday</Checkbox>
            </Checkbox.Group>
            {errors.days && <p>This field is required</p>}
          </div>

          <div>
            <label>Times</label>
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
              <div key={day}>
                {watch('days') && watch('days').includes(day) && (
                  <div>
                    <label>{day}</label>
                    <Controller
                      control={control}
                      name={`startTime_${day}`}
                      render={({ field }) => <TimePicker {...field} />}
                      rules={{ required: true }}
                    />
                    {errors[`startTime_${day}`] && <p>This field is required</p>}
                    <Controller
                      control={control}
                      name={`endTime_${day}`}
                      render={({ field }) => <TimePicker {...field} />}
                      rules={{ required: true }}
                    />
                    {errors[`endTime_${day}`] && <p>This field is required</p>}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {durationType === 'monthly' && (
        <div>
          <div>
            <label>Start Date</label>
            <Controller
              control={control}
              name="startDate"
              render={({ field }) => <DatePicker {...field} />}
              rules={{ required: true }}
            />
            {errors.startDate && <p>This field is required</p>}
          </div>

          <div>
            <label>End Date</label>
            <Controller
              control={control}
              name="endDate"
              render={({ field }) => <DatePicker {...field} />}
              rules={{ required: true }}
            />
            {errors.endDate && <p>This field is required</p>}
          </div>

          <div>
            <label>Weeks</label>
            <Checkbox.Group {...register('weeks', { required: true })}>
              <Checkbox value="Week 1">Week 1</Checkbox>
              <Checkbox value="Week 2">Week 2</Checkbox>
              <Checkbox value="Week 3">Week 3</Checkbox>
              <Checkbox value="Week 4">Week 4</Checkbox>
            </Checkbox.Group>
            {errors.weeks && <p>This field is required</p>}
          </div>

          <div>
            <label>Days</label>
            <Checkbox.Group {...register('days', { required: true })}>
              <Checkbox value="Monday">Monday</Checkbox>
              <Checkbox value="Tuesday">Tuesday</Checkbox>
              <Checkbox value="Wednesday">Wednesday</Checkbox>
              <Checkbox value="Thursday">Thursday</Checkbox>
              <Checkbox value="Friday">Friday</Checkbox>
              <Checkbox value="Saturday">Saturday</Checkbox>
              <Checkbox value="Sunday">Sunday</Checkbox>
            </Checkbox.Group>
            {errors.days && <p>This field is required</p>}
          </div>

          <div>
            <label>Times</label>
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
              <div key={day}>
                {watch('days') && watch('days').includes(day) && (
                  <div>
                    <label>{day}</label>
                    <Controller
                      control={control}
                      name={`startTime_${day}`}
                      render={({ field }) => <TimePicker {...field} />}
                      rules={{ required: true }}
                    />
                    {errors[`startTime_${day}`] && <p>This field is required</p>}
                    <Controller
                      control={control}
                      name={`endTime_${day}`}
                      render={({ field }) => <TimePicker {...field} />}
                      rules={{ required: true }}
                    />
                    {errors[`endTime_${day}`] && <p>This field is required</p>}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      <div>
        <label>Price</label>
        <Input {...register('price', { required: true })} />
        {errors.price && <p>This field is required</p>}
      </div>

      <div>
        <label>Mode</label>
        <Radio.Group {...register('mode', { required: true })}>
          <Radio value="online">Online</Radio>
          <Radio value="offline">Offline</Radio>
        </Radio.Group>
        {errors.mode && <p>This field is required</p>}
      </div>

      <button type="submit">Submit</button>
    </form>
  );
};

export default Training;
