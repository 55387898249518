import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BiRupee, BiTimeFive } from "react-icons/bi";
import { CiCalendarDate, CiLocationOn } from "react-icons/ci";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { TfiBag } from "react-icons/tfi";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../../../Api";

function StaffRequiredInnerPage() {
  const db = useLocation();
  //   const [createForm, setcreateForm] = useState();
  const navigate = useNavigate();

  const jobPost = db.state;

  const email = localStorage.getItem("email");
  const id = localStorage.getItem("id");
  const name = localStorage.getItem("name");
  console.log("jobPost", jobPost);
  // const UserId = jobPost.UserID;
  const Website = jobPost.Website;
  const userEmail = jobPost.userEmail;
  const location = jobPost.location;
  const FirstName = jobPost.FirstName;
  const LastName = jobPost.LastName;
  const phone = jobPost.phone;
  const companyName = jobPost.companyName;
  const CompanyID = jobPost.CompanyID;
  console.log("CompanyID", CompanyID);
  const onSubmit = (e) => {
    const details = {
      UserID: id,
      CompanyID: CompanyID,
      name: name,
      CompanyEmail: userEmail,
      UserEmail: email,
      FirstName: FirstName,
      LastName: LastName,
      location: location,
      phone: phone,
    };
    Api.post("contract/createApproved", details).then((res) => {
      console.log("res", res);
    });
  };
  return (
    <Container>
      <div>
        <div className="ms-4">
          <Row className="p-3">
            <Col>
              <h3 className="mt-3">{jobPost.First}</h3>

              <div className="ms-4 p-2">
                <h6>
                  <CiCalendarDate /> &nbsp;{jobPost.FirstName}
                </h6>
                <h6>
                  <CiCalendarDate /> &nbsp;{jobPost.location}
                </h6>
                <h6>
                  <TfiBag /> &nbsp;{jobPost.LastName}
                </h6>

                <h6>
                  <TfiBag /> &nbsp;{jobPost.email}
                </h6>
                <h6>
                  <TfiBag /> &nbsp;{jobPost.employee}
                </h6>

                <h6>
                  <TfiBag /> &nbsp;{jobPost.phone}
                </h6>
                <p>
                  <div>
                    <h5>React JS</h5>
                    <Row>
                      <Col sm={12} md={3} className="mt-4"></Col>
                      <Col sm={12} md={2}>
                        1-2 Years
                      </Col>
                      <Col sm={12} md={2}>
                        5-8years
                      </Col>
                      <Col sm={12} md={2}>
                        8-10 years
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col sm={12} md={3}>
                        React JS no of resourses
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.java[0].java1}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.java[0].java2}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.java[0].java3}
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col sm={12} md={3}>
                        Rates per month in INR (for 1.2 lakh, write 120K)
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.java[0].java4}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.java[0].java5}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.java[0].java6}
                      </Col>
                    </Row>
                  </div>
                </p>
                <p>
                  <div>
                    <Row>
                      <Col sm={12} md={3} className="mt-4"></Col>
                      <Col sm={12} md={2}>
                        1-2 Years
                      </Col>
                      <Col sm={12} md={2}>
                        5-8years
                      </Col>
                      <Col sm={12} md={2}>
                        8-10 years
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col sm={12} md={3}>
                        Node JS no of resourses
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.node[0].node1}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.node[0].node2}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.node[0].node3}
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col sm={12} md={3}>
                        Rates per month in INR (for 1.2 lakh, write 120K)
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.node[0].node4}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.node[0].node5}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.node[0].node6}
                      </Col>
                    </Row>
                  </div>
                </p>
                <p>
                  <div>
                    <h5>React Native</h5>
                    <Row>
                      <Col sm={12} md={3} className="mt-4"></Col>
                      <Col sm={12} md={2}>
                        1-2 Years
                      </Col>
                      <Col sm={12} md={2}>
                        5-8years
                      </Col>
                      <Col sm={12} md={2}>
                        8-10 years
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col sm={12} md={3}>
                        React Native no of resourses
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.reactNative[0].reactNative1}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.reactNative[0].reactNative2}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.reactNative[0].reactNative3}
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col sm={12} md={3}>
                        Rates per month in INR (for 1.2 lakh, write 120K)
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.reactNative[0].reactNative4}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.reactNative[0].reactNative5}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.reactNative[0].reactNative6}
                      </Col>
                    </Row>
                  </div>
                </p>
                <p>
                  <div>
                    <h5>Java</h5>
                    <Row>
                      <Col sm={12} md={3} className="mt-4"></Col>
                      <Col sm={12} md={2}>
                        1-2 Years
                      </Col>
                      <Col sm={12} md={2}>
                        5-8years
                      </Col>
                      <Col sm={12} md={2}>
                        8-10 years
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col sm={12} md={3}>
                        Java no of resourses
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.java[0].java1}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.java[0].java2}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.java[0].java3}
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col sm={12} md={3}>
                        Rates per month in INR (for 1.2 lakh, write 120K)
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.java[0].java4}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.java[0].java5}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.java[0].java6}
                      </Col>
                    </Row>
                  </div>
                </p>
                <p>
                  <div>
                    <h5>PHP</h5>
                    <Row>
                      <Col sm={12} md={3} className="mt-4"></Col>
                      <Col sm={12} md={2}>
                        1-2 Years
                      </Col>
                      <Col sm={12} md={2}>
                        5-8years
                      </Col>
                      <Col sm={12} md={2}>
                        8-10 years
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col sm={12} md={3}>
                        PHP no of resourses
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.php[0].php1}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.php[0].php2}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.php[0].php3}
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col sm={12} md={3}>
                        Rates per month in INR (for 1.2 lakh, write 120K)
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.php[0].php4}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.php[0].php5}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.php[0].php6}
                      </Col>
                    </Row>
                  </div>
                </p>
                <p>
                  <div>
                    <h5>Flutter </h5>
                    <Row>
                      <Col sm={12} md={3} className="mt-4"></Col>
                      <Col sm={12} md={2}>
                        1-2 Years
                      </Col>
                      <Col sm={12} md={2}>
                        5-8years
                      </Col>
                      <Col sm={12} md={2}>
                        8-10 years
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col sm={12} md={3}>
                        Flutter no of resourses
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.flutter[0].flutter1}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.flutter[0].flutter2}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.flutter[0].flutter3}
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col sm={12} md={3}>
                        Rates per month in INR (for 1.2 lakh, write 120K)
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.flutter[0].flutter4}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.flutter[0].flutter5}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.flutter[0].flutter6}
                      </Col>
                    </Row>
                  </div>
                </p>
                <p>
                  <div>
                    <h5>Angular </h5>
                    <Row>
                      <Col sm={12} md={3} className="mt-4"></Col>
                      <Col sm={12} md={2}>
                        1-2 Years
                      </Col>
                      <Col sm={12} md={2}>
                        5-8years
                      </Col>
                      <Col sm={12} md={2}>
                        8-10 years
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col sm={12} md={3}>
                        Angular no of resourses
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.angular[0].angular1}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.angular[0].angular2}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.angular[0].angular3}
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col sm={12} md={3}>
                        Rates per month in INR (for 1.2 lakh, write 120K)
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.angular[0].angular4}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.angular[0].angular5}
                      </Col>
                      <Col sm={12} md={2}>
                        {jobPost.angular[0].angular6}
                      </Col>
                    </Row>
                  </div>
                </p>
              </div>
            </Col>
          </Row>

          <br />
          <div className="d-flex mt-3 mb-2">
            <Button
              className="login-button"
              variant="secondary"
              //   onClick={() => navigate("/employer/projetrequest")}
            >
              Rejected
            </Button>
            <Button
              className="login-button ms-5"
              variant="primary"
              onClick={(e) => {
                onSubmit();
              }}
            >
              Approved
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default StaffRequiredInnerPage;
