import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { Button, Form, Input, Radio, Select, Row, Col } from "antd";
import { useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import Api from "../../../Api";

const { Title } = Typography;

export default function TrainingInstituteInformation() {
  const [form] = Form.useForm();
  // const [value, setValue] = useState(1);
  const [countryList, setcountryList] = useState([]);
  const [countryValue, setCountryValue] = useState();
  const [stateList, setstateList] = useState([]);
  const [stateValue, setStateValue] = useState();
  const [districtList, setdistrictList] = useState([]);
  const [districtValue, setDistrictValue] = useState();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      gendercheck: "",
    },
  });
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const [formLayout, setFormLayout] = useState("vertical");
  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };
  useEffect(() => {
    getCountry();
  }, []);

  const getCountry = async () => {
    await Api.get("country/getallcountry").then((res) => {
      setcountryList(res.data.data);
    });
  };

  const getState = (country_id) => {
    console.log("stateList", country_id);
    setCountryValue(country_id);
    Api.get(`state/stateById/${country_id}`).then((res) => {
      setstateList(res.data.data);
    });
  };

  const getDistrict = (state_id) => {
    setStateValue(state_id);
    Api.get(`district/districtById/${state_id}`).then((res) => {
      setdistrictList(res.data.data);
    });
  };

  const onSubmit = (data) => {
    // reset();
    console.log(data);
    const check = Api.post(`/students/ourprofiles`, data);
    console.log(check, 333333333333);
    check.then((resp) => {
      console.log("resp.data", resp.data);
      if (resp.data.statusCode === 200) {
      }
    });
    form.resetFields();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="personal-informations p-4">
          <div className="pages-title mb-3">
            Training Institute Information{" "}
          </div>
          <Row className="mb-3">
            <Col xs={24} sm={24} md={8} className=" p-2">
              <label className="input-title">Training Institute Name </label>
              <input
                className="input-field"
                {...register("schoolname", { required: true })}
              />

              <br />
              {errors.schoolname && (
                <span className="input-error">School Name is required</span>
              )}
            </Col>

            <Col xs={24} sm={24} md={8} className=" p-2">
              <label className="input-title">Country</label>
              <br></br>
              <Dropdown
                filter
                className="input-field"
                value={countryValue}
                options={countryList}
                {...register("country", { required: true })}
                optionLabel={"name"}
                optionValue={"id"}
                onChange={(e) => getState(e.value)}
              />
              {errors.country && (
                <p className="error-text-color">country is required</p>
              )}
            </Col>
            <Col xs={24} sm={24} md={8} className=" p-2">
              <label className="input-title">State</label>
              <br />
              <Dropdown
                filter
                className="input-field"
                value={stateValue}
                options={stateList}
                {...register("State", { required: true })}
                optionLabel={"name"}
                optionValue={"id"}
                onChange={(e) => getDistrict(e.value)}
              />
              {errors.State && (
                <p className="error-text-color">State is required</p>
              )}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={24} sm={24} md={8} className=" p-2">
              <label className="input-title">District</label>
              <br />
              <Dropdown
                filter
                className="input-field"
                value={districtValue}
                options={districtList}
                {...register("district", { required: true })}
                optionLabel={"name"}
                optionValue={"id"}
                onChange={(e) => setDistrictValue(e.value)}
              />

              {errors.district && (
                <p className="error-text-color">District is required</p>
              )}
            </Col>

            <Col xs={24} sm={24} md={8} className=" p-2">
              <label className="input-title">City </label>
              <br />
              <input
                className="input-field"
                {...register("city", { required: true })}
              />

              <br />
              {errors.city && (
                <span className="input-error">City is required</span>
              )}
            </Col>
            <Col xs={24} sm={24} md={8} className=" p-2">
              <label className="input-title">Address </label>
              <input
                className="input-field"
                {...register("address", { required: true })}
              />

              <br />
              {errors.address && (
                <span className="input-error">Address is required</span>
              )}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={24} sm={24} md={8} className=" p-2">
              <label className="input-title">Pincode</label>
              <input
                className="input-field"
                {...register("pincode", { required: true })}
              />

              <br />
              {errors.pincode && (
                <span className="input-error">Pincode is required</span>
              )}
            </Col>

            <Col xs={24} sm={24} md={8} className=" p-2">
              <label className="input-title">Contact Person </label>
              <br />
              <input
                className="input-field"
                {...register("contactperson", { required: true })}
              />

              <br />
              {errors.contactperson && (
                <span className="input-error">
                  Contact Person name is required
                </span>
              )}
            </Col>
            <Col xs={24} sm={24} md={8} className=" p-2">
              <label className="input-title">Contact Number </label>
              <br />
              <input
                className="input-field"
                {...register("contactnumber", { required: true })}
              />

              <br />
              {errors.contactnumber && (
                <span className="input-error">Contact Number is required</span>
              )}
            </Col>
            <Col xs={24} sm={24} md={8} className=" p-2">
              <label className="input-title">Designation </label>
              <br />
              <input
                className="input-field"
                {...register("Designation", { required: true })}
              />

              <br />
              {errors.Designation && (
                <span className="input-error">Designation is required</span>
              )}
            </Col>
            <Col xs={24} sm={24} md={8} className=" p-2">
              <label className="input-title">Department</label>
              <br />
              <input
                className="input-field"
                {...register("Department", { required: true })}
              />

              <br />
              {errors.Department && (
                <span className="input-error">Department is required</span>
              )}
            </Col>
            {/* </Row> */}
            {/* <Row className="mb-3"> */}
            <Col xs={24} sm={24} md={8} className=" p-2">
              <label className="input-title">Email Address </label>
              <br />
              <input
                className="input-field"
                {...register("emailAddress", { required: true })}
              />

              <br />
              {errors.emailAddress && (
                <span className="input-error">Email Address is required</span>
              )}
            </Col>
            <Col xs={24} sm={24} md={8} className=" p-2">
              <label className="input-title">Website </label>
              <input
                className="input-field"
                {...register("Website", { required: true })}
              />

              {/* <br />
              {errors.Website && (
                <span className="input-error">Address is required</span>
              )} */}
            </Col>
            <Col xs={24} sm={24} md={8} className=" p-2">
              <label className="input-title">Address </label>
              <textarea
                className="input-field"
                {...register("address", { required: true })}
              />

              <br />
              {errors.address && (
                <span className="input-error">Address is required</span>
              )}
            </Col>
          </Row>
          <div className="submitbuttons p-2">
            <button className="button1 m-2 p-2" type="submit">
              Submit
            </button>
            <button className="button2 m-2 p-2" type="reset">
              Reset
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
