import React from "react";
import { NavLink } from "react-router-dom";
import { MdPerson, MdMenuBook, MdRequestPage } from "react-icons/md";
import { FaBlogger } from "react-icons/fa";
import "../../../../Components/Css/Sidebar.scss";
import { Menu } from "antd";
import { CgProfile } from "react-icons/cg";
import { SnippetsOutlined } from "@ant-design/icons";
import { FaHandPaper } from "react-icons/fa";
import { SiSemanticweb } from "react-icons/si";
import { AiOutlineContacts } from "react-icons/ai";

const { SubMenu } = Menu;
function Sidebar({ collapsed }) {
  const onClick = (e) => {};
  const role = localStorage.getItem("name");
  const id = localStorage.getItem("regid");

  return (
    <div className={collapsed === true ? "sidebarcontent open" : "d-none"}>
      <Menu onClick={onClick} mode="inline" className="nav-list">
        <NavLink to="/college/companyhome" active ClassName="main-nav-style">
          <div className="Nav-Icon2">
            <CgProfile />
          </div>
          <div className="name-tag">{role}</div>
          <div className="name-tag">{id}</div>
        </NavLink>
        <NavLink to="/college/profile" activeClassName="main-nav-active-style">
          <MdPerson className="Nav-Icon" />
          <p className="side-title"> Profile</p>
        </NavLink>
        <NavLink
          to="/college/application"
          activeClassName="main-nav-active-style"
        >
          <MdMenuBook className="Nav-Icon" />
          <p className="side-title"> Received Apllications</p>
        </NavLink>
        {/* <NavLink
          to="/college/application"
          activeClassName="main-nav-active-style"
        >
          <MdMenuBook className="Nav-Icon" />
          Approved List
        </NavLink> */}
        <NavLink to="/college/blog" activeClassName="main-nav-active-style">
          <FaBlogger className="Nav-Icon" />
          <p className="side-title"> Blog</p>
        </NavLink>
        <SubMenu
          icon={<FaHandPaper size={20} className="Nav-Icon1" />}
          title="Jobs"
        >
          <NavLink to={"/college/CreateJob"} ClassName="sub-nav-style">
            <SnippetsOutlined className="Nav-Icon" />
            <p className="side-title"> Create Job</p>
          </NavLink>
          <NavLink to={"/college/viewjob"} ClassName="sub-nav-style">
            <SnippetsOutlined className="Nav-Icon" />
            <p className="side-title"> View Jobs</p>
          </NavLink>
          {/* <NavLink to={`/college/jobrequest`} ClassName="main-nav-style">
            <SnippetsOutlined className="Nav-Icon" />
            Job Request
          </NavLink> */}
          <NavLink to={"/college/form"} ClassName="sub-nav-style">
            <SnippetsOutlined className="Nav-Icon" />
            <p className="side-title"> Schedule Interview</p>
          </NavLink>
          {/* <NavLink
          to={`/employer/${companyName}/details`}
          ClassName="main-nav-style"
        > <SnippetsOutlined className="Nav-Icon" /> Details Form</NavLink> */}
        </SubMenu>
        <SubMenu
          icon={<AiOutlineContacts size={28} className="Nav-Icon1" />}
          title="Interview List"
        >
          <NavLink to={`/college/interview/selected`} ClassName="sub-nav-style">
            <SnippetsOutlined className="Nav-Icon" />
            <p className="side-title"> selected</p>
          </NavLink>
          <NavLink to={`/college/interview/hold`} ClassName="sub-nav-style">
            <SnippetsOutlined className="Nav-Icon" />
            <p className="side-title"> Hold</p>
          </NavLink>
          <NavLink
            to={`/college/interview/rejected`}
            ClassName="main-nav-style"
          >
            <SnippetsOutlined className="Nav-Icon" />
            <p className="side-title"> Rejected</p>
          </NavLink>
        </SubMenu>
        <SubMenu
          icon={<SiSemanticweb size={20} className="Nav-Icon1" />}
          title="Seminar"
        >
          <NavLink to="/college/seminar" ClassName="sub-nav-styles">
            <SnippetsOutlined className="Nav-Icon" />
            <p className="side-title"> Create Seminar</p>
          </NavLink>
          <NavLink to="/college/requestseminar" ClassName="sub-nav-styles">
            <SnippetsOutlined className="Nav-Icon" />
            <p className="side-title"> View Seminar</p>
          </NavLink>
        </SubMenu>

        {/* <NavLink to="/college/seminar" activeClassName="main-nav-active-style">
          <MdDetails className="Nav-Icon" />
          Seminar Details
        </NavLink> */}
        {/* <NavLink to="/college/profile" activeClassName="main-nav-active-style">
          <VscPersonAdd className="Nav-Icon" />
          Update Profile
        </NavLink> */}
      </Menu>
    </div>
  );
}

export default Sidebar;
