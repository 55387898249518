import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import "../../../Components/Css/Students/Student.scss";
import Api from "../../../Api";
import "aos/dist/aos.css";
import AOS from "aos";

function Kycvendor() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      delay: "300",
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const [PanCard, setPanCard] = useState({});
  const userId = localStorage.getItem("userId");
  const [userData, setUserData] = useState([]);
  console.log("ffd", userData);

  useEffect(() => {
    getDetails();
  }, []);
  const getDetails = async () => {
    Api.get(`signup/student_getone/${userId}`).then((res) => {
      setUserData(res?.data?.data.kycDetails);
    });
  };

  const handleFormSubmit = async () => {
    const Details = {
      pancardNumber: getValues().pancardNumber,
      aadharcardNumber: getValues().aadharcardNumber,
      gstNumber: getValues().gstNumber,
      addressProof: getValues().addressProof,
    };
    const data = new FormData();
    data.append("AddressProof", getValues().AddressProof[0]);

    await Api.put(`/vendor/addressproofImage/${userId}`, data).then(
      async (res) => {
        if (res.status == 201) {
          Details.AddressProof = res.data.path;
          await Api.put(`/vendor/vendorput/${userId}`, Details).then((resp) => {
            console.log("resp", resp);
          });
        }
      }
    );
  };

  return (
    <div>
      <Container data-aos="fade-down">
        <h4 className="pages-title mt-3">KYC Complaince</h4>
        <br />
        <p style={{ backgroundColor: "#fccc55", width: "40%" }}>
          Your details are required to meet kyc Complaince
        </p>
        <br />
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Row>
            <Col sm={12}  lg={2}>
              <label>PanCard Number: </label>
            </Col>
            <Col sm={12} lg={6}>
              <input
                defaultValue={userData?.panCard}
                onChange={(e) =>
                  setUserData({ ...userData, panCard: e.target.value })
                }
                {...register("pancardNumber", { required: true })}
                className="kyc-input"
              />
              {errors.pancardNumber && (
                <p className="text-danger">pancard number is required</p>
              )}
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={12} lg={2}>
              <label>AadharCard Number: </label>
            </Col>
            <Col sm={12} lg={6}>
              <input
                defaultValue={userData?.aadharCard}
                onChange={(e) =>
                  setUserData({ ...userData, aadharCard: e.target.value })
                }
                {...register("aadharcardNumber", { required: true })}
                className="kyc-input"
              />
              {errors.aadharcardNumber && (
                <p className="text-danger">AadharCard number is required</p>
              )}
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={12} lg={2}>
              <label>Pan or Adhar Upload Anyone: </label>
            </Col>
            <Col sm={12} lg={6}>
              <input
                className="kyc-input"
                style={{ width: "85%", outline: "none", height: "53%" }}
                type="file"
                {...register("AddressProof", { required: true })}
                onChange={(e) => {
                  setPanCard(e.target.files[0]);
                  console.log("e.target.files[0]", e.target.files[0]);
                }}
              />
              {errors.AddressProof && (
                <p className="text-danger">pancard upload is required</p>
              )}
              <p>
                Only 1 document in pdf,jpeg and png format with maximum 5Mb
                uploaded
              </p>
            </Col>
          </Row>
           <Row>
            <Col sm={12} lg={2}>
              <label>Address Proof: </label>
            </Col>
            <Col sm={12} lg={6}>
              <input
              defaultValue={userData?.aadharCard}
              onChange={(e) =>
                setUserData({ ...userData, aadharCard: e.target.value })
              }
                {...register("addressProof", { required: true })}
                className="kyc-input"
              />
              {errors.addressProof && (
                <p className="text-danger">Address is required</p>
              )}
            </Col>
          </Row> 
          <br />

          <div className="submitbuttons px-4">
            <button
              className="stud-profil-btn1 m-2 p-2"
              type="submit"
              onClick={handleFormSubmit}
            >
              Submit
            </button>
            <button className="button2 m-2 p-2" type="reset">
              cancel
            </button>
          </div>
        </form>
      </Container>
    </div>
  );
}

export default Kycvendor;
