import React, { useCallback, useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Select, Button, Input } from "antd";
import { Dropdown } from "primereact";
import { MultiSelect } from "primereact/multiselect";
import { useNavigate, useLocation } from "react-router-dom";
import Api from "../../../Api";
// import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import "./ExamPattern.scss"
import { Modal } from 'antd';
// import axios from "axios";

const { Option } = Select;

function PatternCreate() {
  const [valtech, setValtech] = useState();
  const [tech, setTech] = useState();
  const [course, setCourse] = useState([]);
  const [valcourse, setValCourse] = useState([]);
  const [Technology, setTechnology] = useState([]);
  const [TechnologyList, setTechnologyList] = useState([]);
  const [retech, setReTech] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [duration, setDuration] = useState({
    hours: 1,
    minutes: 2,
    seconds: 3
  });
  const [extraweek, setExtraWeek] = useState([
    {
      Week: "",
      limit: [{ Tech: "", count: "" }],
      time: "",
    },
  ]);

  const navigate = useNavigate();
  const toast = useRef(null);
  const { state: locationState } = useLocation();
  console.log("lll", locationState)
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: locationState ? locationState.ele : {} });

  useEffect(() => {
    getTechnology();
    getCourse();
  }, []);

  // const getQualification = async () => {
  //   await Api.get("qualificationForm/getAllqualification").then((res) => {
  //     setQualificationList(res.data.data);
  //   });
  // };

  // const weekvalue = {
  //   week: "",
  // };

  const handleAddWeek = () => {
    setExtraWeek((prev) => [
      ...prev,
      {
        Week: "",
        limit: [{ Tech: "", Count: "" }],

        time: "",
      },
    ]);
    // setShow(true);
  };
  const handleoption = useCallback((index, key, value) => {
    setExtraWeek((prev) => {
      const newValue = [...prev];
      newValue[index][key] = value;
      return newValue;
    });
  }, []);

  //REMOVE OPTION
  const handleRemove = useCallback((idx, setValue) => {
    // console.log("setValue", setValue);
    setExtraWeek((prev) => {
      const newOne = prev.filter((item, index) => {
        if (index != idx) {
          return item;
        }
      });
      // setValue();
      console.log("newOne", newOne);
      return newOne;
    });
  }, []);

  const getCourse = () => {
    Api.get(`/Course/get`).then((resp) => {
      setCourse(resp.data);
    });
  };

  const getTechnology = () => {
    Api.get(`/Technology/get`).then((resp) => {
      setTechnologyList(resp.data);
    });
  };

  const handleFormSubmit = (data) => {
    try {
      if (locationState) {
        //update
        Api.put(`/ExamPattern/update/${locationState.ele.id}`, data);
      } else {
        //create
        Api.post(`ExamPattern/create`, data)
          .then((resp) => {
            console.log("111111", resp);
          })
          .catch((resp) => {
            console.log("asdfghjk", resp);
          });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong,please try again!!!",
        life: 3000,
      });
    }
  };

  // const onChangeTime = duration => {
  //   const { hours, minutes, seconds } = duration;
  //   setState({ hours, minutes, seconds });
  // };
  const onChangeTime = (duration) => {
    setDuration(duration);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="Blog-form p-5 ms-5">
          <div className="pages-title mb-3">Create Pattern</div>
          <Row className="mb-3">
            <Col xs={24} sm={24} md={8} className="p-2">
              <label className="input-title pe-5">Course</label>
              <br />
              {/* {show == true ? <></> : <></>} */}
              {/* <Dropdown
                filter
                className="examSelect"
                // style={{ width: "100%" }}
                value={valcourse}
                options={course}
                {...register("Course", { required: true })}
                optionLabel={"Course"}
                optionValue={"id"}
                onChange={(e) => setValCourse(e.value)}
              /> */}
              <select className="courseSelect"
                {...register("Course", { required: true })} >
                <option value="" disabled selected>Select a course</option>
                {course?.map(obj =>
                  <option value={obj.Course} >{obj.Course}</option>
                )}
              </select>
              {errors.Course && (
                <p className="error-text-color">Course is required</p>
              )}
            </Col>

            <Col xs={24} sm={24} md={8} className="p-2">
              <label>technology</label>
              <br />
              <Select
                mode="multiple"
                // allowClear
                name="Technology"
                className="examSelect"
                {...register("Technology", { required: true })}
                // style={{ width: "100%" }}
                placeholder="Please select a technology"
                onChange={(e) => {
                  setTechnology(e);
                  setValue("Technology", e);
                  setTech(e);
                }}
              >
                {TechnologyList?.map((list, i) => {
                  return (
                    <Option value={list?.Technology} key={i}>
                      {list?.Technology}
                    </Option>
                  );
                })}
              </Select>
              {/* <select
  multiple
  name="Technology"
  className="examSelect"
  {...register("Technology", { required: true })}
  placeholder="Please select a technology"
  onChange={(e) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map(
      (option) => option.value
    );
    setTechnology(selectedOptions);
    setValue("Technology", selectedOptions);
    setTech(selectedOptions);
  }}
>
  {TechnologyList?.map((list, i) => {
    return (
      <option value={list?.Technology} key={i}>
        {list?.Technology}
      </option>
    );
  })}
</select> */}

              {Technology.length > 0
                ? null
                : errors.Technology && (
                  <p className="text-danger">technology is required</p>
                )}
            </Col>
            <Col xs={24} sm={24} md={8} className="p-2">
              <label className="input-title pe-5">Number of Weeks</label>
              <br />
              <select
                // style={{ width: "85%", outline: "none" }}
                className="examSelect"
                {...register("NumberOfWeeks", { required: true })}
              >
                <option value="">select Weeks</option>
                <option value="1">1 </option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5 </option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9 </option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                {/* <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option> */}
              </select>
              <br />
              {errors.NumberOfWeeks && (
                <span className="Blog-error">Week required</span>
              )}
            </Col>
            <Col>
              {/* <Modal open={isOpen}> */}
              {/* <Input
                addonAfter={
                  <DurationPicker
                    onChange={onChangeTime}
                    initialDuration={{ hours: 0, minutes: 0, seconds: 0 }}
                    maxHours={5}
                  />
                }
              /> */}
              {/* <Input>
      <DurationPicker
        onChange={onChangeTime}
        initialDuration={{ hours: 1, minutes: 2, seconds: 3 }}
        maxHours={5}
      />
    </Input> */}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={24} md={24} className="Stud-sem-col p-2">
              {extraweek.map((item, index) => {
                return (
                  <div>
                    <Row>
                      <Col xs={24} sm={24} md={8} className="Stud-sem-col">
                        <label className="input-title"> Week </label>
                        <br />
                        <select
                          // rows="3"
                          value={extraweek[index].Week}
                          className="examSelect"
                          // Name="Question"
                          {...register(`weeks[${index}].Week`, {
                            required: true,
                            onChange: (e) => {
                              handleoption(index, "Week", e.target.value);
                            },
                          })}
                        >
                          <option value="">select Weeks</option>
                          <option value="1">week1 </option>
                          <option value="2">week2</option>
                          <option value="3">week3</option>
                          <option value="4">week4</option>
                          <option value="5">week5 </option>
                          <option value="6">week6</option>
                          <option value="7">week7</option>
                          <option value="8">week8</option>
                          <option value="9">week9 </option>
                          <option value="10">week10</option>
                          <option value="11">week11</option>
                          <option value="12">week12</option>
                          {/* <option value="13">week13</option>
                          <option value="14">week14</option>
                          <option value="15">week15</option> */}
                        </select>

                        <br></br>
                        {errors.week && (
                          <span className="input-error">weeks need</span>
                        )}
                      </Col>

                      <Col xs={24} sm={24} md={8} className="p-2">
                        <label className="input-title pe-5">Technology</label>
                        <br />

                        <select
                          allowClear
                          className="examSelect"
                          // value={(e) => e.target.value}
                          {...register(`weeks[${index}].limit[0].Tech`, {
                            required: false,
                          })}
                        // style={{ width: "100%" }}
                        // onChange={(e) => {}}
                        >
                          {tech?.map((list, i) => {
                            return (
                              <option value={list} key={i}>
                                {list}
                              </option>
                            );
                          })}
                        </select>
                        <select
                          allowClear
                          className="examSelect"
                          // value={(e) => e.target.value}
                          {...register(`weeks[${index}].limit[1].Tech`, {
                            required: false,
                          })}
                        // style={{ width: "100%" }}
                        // onChange={(e) => {}}
                        >
                          {tech?.map((list, i) => {
                            return (
                              <option value={list} key={i}>
                                {list}
                              </option>
                            );
                          })}
                        </select>
                        <select
                          allowClear
                          className="examSelect"
                          // value={(e) => e.target.value}
                          {...register(`weeks[${index}].limit[2].Tech`, {
                            required: false,
                          })}
                        // style={{ width: "100%" }}
                        // onChange={(e) => {}}
                        >
                          {tech?.map((list, i) => {
                            return (
                              <option value={list} key={i}>
                                {list}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                      <Col md={24} lg={4} className="p-2">
                        <label className="input-title pe-5">Count</label>
                        <br />
                        <textarea
                          rows="3"
                          // value={extraweek[index].limit[0].count}
                          className="input-field mt-2"
                          // Name="Question"
                          {...register(`weeks[${index}].limit[0].Count`, {
                            required: true,
                            // onChange: (e) => {
                            //   handleoption(
                            //     index,
                            //     "limit[0].Count",
                            //     e.target.value
                            //   );
                            // },
                          })}
                        />
                        <textarea
                          rows="3"
                          // value={extraweek[index].limit[1].count}
                          className="input-field mt-1.5"
                          // Name="Question"
                          {...register(`weeks[${index}].limit[1].Count`, {
                            required: true,
                            onChange: (e) => {
                              handleoption(
                                index,
                                "limit[1].Count",
                                e.target.value
                              );
                            },
                          })}
                        />
                        <textarea
                          rows="3"
                          // value={extraweek[index].limit[2].count}
                          className="input-field mt-1.5"
                          // Name="Question"
                          {...register(`weeks[${index}].limit[2].Count`, {
                            required: true,
                            // onChange: (e) => {
                            //   handleoption(
                            //     index,
                            //     "limit[2].Count",
                            //     e.target.value
                            //   );
                            // },
                          })}
                        />
                      </Col>

                      <Col md={24} lg={4} className="p-2">
                        <label className="input-title pe-5">Time</label>
                        <br />
                        <textarea
                          rows="3"
                          value={extraweek[index].time}
                          className="input-field"
                          // Name="Question"
                          {...register(`weeks[${index}].time`, {
                            required: true,
                            onChange: (e) => {
                              handleoption(index, "time", e.target.value);
                            },
                          })}
                        />
                      </Col>

                      {extraweek.length > 1 && (
                        <Col xs={24} sm={24} md={8} className="p-2 mt-3" >
                          <div className="remove-buttontag">
                            <Button
                              // variant="danger"
                              className="remove-button mt-2 "
                              disabled={extraweek.length <= 1}
                              onClick={() => {
                                handleRemove(index, setValue);
                              }}
                            >
                              Remove Week
                            </Button>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                );
              })}
              <br></br>
              {errors.extraweek && (
                <span className="input-error">weeks need</span>
              )}
            </Col>
          </Row>
          <div>
            <button
              type="button"
              className="addbutton m-2 "
              onClick={handleAddWeek}
            >
              Add week
            </button>
          </div>

          <div className="submitbuttons p-2">
            <button
              className="button1 m-2 p-2"
              type="submit"
              // onClick={() => navigate(-1)}
            >
              Submit
            </button>
            <button className="button2 m-2 p-2" type="reset">
              Reset
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default PatternCreate;
