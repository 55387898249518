import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { SnippetsOutlined } from "@ant-design/icons";
import {
  MdPerson,
  MdAreaChart,
  MdRequestPage,
  MdSend,
  MdMenuBook,
  MdDetails,
} from "react-icons/md";
import { AiFillProject } from "react-icons/ai";
import { FaBlogger } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import "../../../Components/Css/Sidebar.scss";
import SidebarToggle from "../../Student/DefaultLayout/SidebarToogle";

function Sidebar() {
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [isRightSidebarCollapsed, setIsRightSidebarCollapsed] = useState(true);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleRightSidebar = () => {
    setIsRightSidebarCollapsed((prev) => !prev);
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prev) => !prev);
  };

  const handleSubmenuClick = (submenu) => {
    if (submenu === activeSubmenu) {
      setActiveSubmenu(null);
      setIsRightSidebarCollapsed(true);
    } else {
      setActiveSubmenu(submenu);
      setIsRightSidebarCollapsed(false);
    }
  };

  const role = localStorage.getItem("name");
  const id = localStorage.getItem("regid");

  const hasSubmenuItems = () => {
    return activeSubmenu === "Internship" || activeSubmenu === "Jobs";
  };

  return (
    <div
      className={`sidebar-container ${isSidebarCollapsed ? "collapsed" : ""}`}
    >
      <SidebarToggle
        toggleSidebar={toggleSidebar}
        isSidebarCollapsed={isSidebarCollapsed}
      />

      {!isSidebarCollapsed && (
        <div className="sidebar-content">
          <div className="nav-list-sidebar">
            <NavLink
              to=' '
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <div className="profile-icon">
                <CgProfile />
              </div>
              <div className="profile-details">
                <div className="profile-role">{role}</div>
                <div className="profile-id">{id}</div>
              </div>
            </NavLink>

            <NavLink to="/freelancing" ClassName="main-nav-style">
              <div className="glowing"> Freelancing</div>
            </NavLink>
            <NavLink
              to="/professional/professionalprofile"
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdPerson className="nav-icon" />
              <span className="side-title">My Profile</span>
            </NavLink>
            <NavLink
              to="/professional/AreaOfInterest"
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdAreaChart className="nav-icon" />
              <span className="side-title">Area Of Intrest</span>
            </NavLink>
            <div
              className="nav-item"
              onClick={() => handleSubmenuClick("Jobs")}
            >
              <AiFillProject
                className="nav-icon"
                style={{ fontSize: "22px", marginLeft: "6px" }}
              />
              <span className="side-title">Jobs</span>
            </div>
            <div
              className="nav-item"
              onClick={() => handleSubmenuClick("Internship")}
            >
              <AiFillProject
                className="nav-icon"
                style={{ fontSize: "22px", marginLeft: "6px" }}
              />
              <span className="side-title">Internship</span>
            </div>
            <NavLink
              to="/professional/seminar"
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdAreaChart className="nav-icon" />
              <span className="side-title">seminar</span>
            </NavLink>
            <NavLink
              to="/professional/learing"
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdMenuBook className="nav-icon" />
              <span className="side-title">Learning</span>
            </NavLink>
            <NavLink
              to="/professional/ProfessionalBlog"
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <FaBlogger className="nav-icon" />
              <span className="side-title">Blog</span>
            </NavLink>
            <NavLink
              to="/professional/CoursePackage"
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdMenuBook className="nav-icon" />
              <span className="side-title">Package details</span>
            </NavLink>
          </div>
        </div>
      )}

      {hasSubmenuItems() && (
        <div
          className={`right-sidebar ${
            isRightSidebarCollapsed ? "collapsed" : ""
          }`}
        >
          <div className="right-sidebar-header">
            <div className="collapse-btn" onClick={toggleRightSidebar}>
              {isRightSidebarCollapsed ? ">" : "<"}
            </div>
          </div>

          {activeSubmenu === "Jobs" && (
            <>
              <NavLink to="/professional/jobs" className="submenu-item">
                <MdRequestPage className="nav-sub-icon" />
                <span className="sub-side-title">Jobs</span>
              </NavLink>
              <NavLink to="/professional/jobrequests" className="submenu-item">
                <MdSend className="nav-sub-icon" />
                <span className="sub-side-title">Request</span>
              </NavLink>
              <NavLink to="/professional/jobrequest" className="submenu-item">
                <MdSend className="nav-sub-icon" />
                <span className="sub-side-title">Response</span>
              </NavLink>
            </>
          )}

          {activeSubmenu === "Internship" && (
            <>
              <NavLink
                to="/professional/InternshipList"
                className="submenu-item"
              >
                <MdRequestPage className="nav-sub-icon" />
                <span className="sub-side-title">Internship List</span>
              </NavLink>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Sidebar;
