import React, { useEffect, useState } from "react";
import { Col, Row, Card, Form, Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Api from "../../../Api";
import "../../../Components/seminar/viewSeminar.scss";
import { FaAnglesRight, FaFilter } from "react-icons/fa6";
import { FaBuilding, FaLaptop, FaMapMarkerAlt, FaClock, FaBriefcase, FaDollarSign, FaEnvelope } from "react-icons/fa"; 

export default function Internship() {
  const [createForm, setCreateForm] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    title: '',
    location: '',
    salary: '',
    experience: ''
  });
  const [dropdownOptions, setDropdownOptions] = useState({
    location: [],
    salary: [],
    experience: []
  });
  const [showFilterModal, setShowFilterModal] = useState(false);

  useEffect(() => {
    getCreateForm();
  }, []);

  useEffect(() => {
    setFilteredData(createForm);
    generateDropdownOptions(createForm);
  }, [createForm]);

  const getCreateForm = async () => {
    try {
      const res = await Api.get("Internship/getInternship"); 
      setCreateForm(res.data.data);
    } catch (error) {
      console.error("Error fetching internship data:", error);
    }
  };

  const generateDropdownOptions = (data) => {
    const locationOptions = [...new Set(data.map(item => item.location))];
    const salaryOptions = [...new Set(data.map(item => item.salary))];
    const experienceOptions = [...new Set(data.map(item => item.experience))];

    setDropdownOptions({
      location: locationOptions,
      salary: salaryOptions,
      experience: experienceOptions
    });
  };

  const navigate = useNavigate();

  const onSubmit = (data) => {
    navigate("/student/InternshipPage", { state: data });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const applyFilters = () => {
    const filtered = createForm.filter((data) => {
      return (
        (filters.title === '' || data.jobTitle.toLowerCase().includes(filters.title.toLowerCase())) &&
        (filters.location === '' || data.location === filters.location) &&
        (filters.salary === '' || data.salary === filters.salary) &&
        (filters.experience === '' || data.experience === filters.experience)
      );
    });
    setFilteredData(filtered);
  };

  const resetFilters = () => {
    setFilters({
      title: '',
      location: '',
      salary: '',
      experience: ''
    });
  };

  useEffect(() => {
    applyFilters();
  }, [filters]);

  return (
    <div>
      <div className="project-heading">
        <p>Internship Opportunities</p>
      </div>
      <div className="row internship-flex">
        <div className="col-9">
          <Button variant="primary" onClick={() => setShowFilterModal(true)} className="filter-button">
            <FaFilter /> Filter
          </Button>
        </div>
      </div>
    
      <Modal show={showFilterModal} onHide={() => setShowFilterModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Filter Internships</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              className="seminar-input"
              type="text"
              placeholder="Filter by Title"
              name="title"
              value={filters.title}
              onChange={handleFilterChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Location</Form.Label>
            <Form.Select
              className="seminar-select"
              name="location"
              value={filters.location}
              onChange={handleFilterChange}
            >
              <option value="">All</option>
              {dropdownOptions.location.map((loc, index) => (
                <option key={index} value={loc}>{loc}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Salary</Form.Label>
            <Form.Select
              className="seminar-select"
              name="salary"
              value={filters.salary}
              onChange={handleFilterChange}
            >
              <option value="">All</option>
              {dropdownOptions.salary.map((salary, index) => (
                <option key={index} value={salary}>{salary}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Experience</Form.Label>
            <Form.Select
              className="seminar-select"
              name="experience"
              value={filters.experience}
              onChange={handleFilterChange}
            >
              <option value="">All</option>
              {dropdownOptions.experience.map((exp, index) => (
                <option key={index} value={exp}>{exp}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <div className="d-flex justify-content-between">
            <Button variant="secondary" onClick={resetFilters}>
              Reset Filters
            </Button>
            <Button variant="primary" onClick={() => setShowFilterModal(false)}>
              Apply Filters
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Row className="card-row" xs={1} sm={2} md={3} xl={4}>
        {filteredData.map((data, i) => (
          <Col className="semi-card-col mb-4" key={i}>
            <Card className="seminar-cards">
              <Card.Header className="internship-title">
                <h4 className="seminar-card-title">{data.jobTitle}</h4>
                <p style={{ color: "rgb(220, 221, 222)" }}>{data.skill.join(", ")}</p>
              </Card.Header>
              <Card.Body className="semi-card-body">
                <div className="seminar-card-flex">
                  <FaBuilding className="seminar-icons" /> <label>Company: &nbsp;</label>
                  {data.companyWebsite}
                </div>
                <div className="seminar-card-flex">
                  <FaMapMarkerAlt className="seminar-icons" /> <label>Location: &nbsp;</label>
                  {data.location}
                </div>
                <div className="seminar-card-flex">
                  <FaClock className="seminar-icons" /> <label>Experience: &nbsp;</label>
                  {data.experience}
                </div>
                <div className="seminar-card-flex">
                  <FaDollarSign className="seminar-icons" /> <label>Salary: &nbsp;</label>
                  {data.salary}
                </div>
                <div className="seminar-card-flex">
                  <FaBriefcase className="seminar-icons" /> <label>Type: &nbsp;</label>
                  {data.jobType}
                </div>
                <div className="seminar-card-flex">
                  <FaLaptop className="seminar-icons" /> <label>Mode: &nbsp;</label>
                  {data.jobMode}
                </div>
                <div className="seminar-card-flex">
                  <FaEnvelope className="seminar-icons" /> <label>Contact: &nbsp;</label>
                  {data.companyMailId}
                </div>
              </Card.Body>
              <Card.Footer className="seminar-card-footer">
                <button
                  className="button-seminar"
                  onClick={() => onSubmit(data)}
                >
                  View Details <FaAnglesRight />
                </button>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}
