// import React from "react";
// import { Calendar } from "antd";

// const Calender = () => {
//   const onPanelChange = (value, mode) => {
//     console.log(value.format("YYYY-MM-DD"), mode);
//   };
//   return (
//     <div>
//       {" "}
//       <Calendar onPanelChange={onPanelChange} style={{ width: "50%" }} />;{" "}
//     </div>
//   );
// };
// export default Calender;


import { Badge, Calendar } from 'antd';
import "../../Components/Css/Employee/Calender.scss";
const getListData = (value) => {
  let listData;
  switch (value.date()) {
    case 8:
      listData = [
        {
          type: 'warning',
          content: 'CL',
        },
        // {
        //   type: 'success',
        //   content: 'This is usual event.',
        // },
      ];
      break;
    case 10:
      listData = [
        // {
        //   type: 'warning',
        //   content: 'This is warning event.',
        // },
        {
          type: 'success',
          content: 'HL',
        },
        // {
        //   type: 'error',
        //   content: 'This is error event.',
        // },
      ];
      break;
    case 15:
      listData = [
        // {
        //   type: 'warning',
        //   content: 'This is warning event',
        // },
        // {
        //   type: 'success',
        //   content: 'This is very long usual event。。....',
        // },
        // {
        //   type: 'error',
        //   content: 'This is error event 1.',
        // },
        // {
        //   type: 'error',
        //   content: 'This is error event 2.',
        // },
        // {
        //   type: 'error',
        //   content: 'This is error event 3.',
        // },
        {
          type: 'error',
          content: 'EL',
        },
      ];
      break;
    default:
  }
  return listData || [];
};
const getMonthData = (value) => {
  if (value.month() === 8) {
    return 1394;
  }
};
const Calender = () => {
  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };
  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };
  return <Calendar dateCellRender={dateCellRender} monthCellRender={monthCellRender} style={{ width:"80%" }} />;
};

export default Calender;

// import { Calendar, theme } from 'antd';
// const onPanelChange = (value, mode) => {
//   console.log(value.format('YYYY-MM-DD'), mode);
// };
// const Calender = () => {
//   const { token } = theme.useToken();
//   const wrapperStyle = {
//     width: 300,
//     border: `1px solid ${token.colorBorderSecondary}`,
//     borderRadius: token.borderRadiusLG,
//   };
//   return (
//     <div style={wrapperStyle}>
//       <Calendar fullscreen={false} onPanelChange={onPanelChange} />
//     </div>
//   );
// };
// export default Calender;
