import {React,useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Card } from "react-bootstrap";
import "./CollegeHome.scss";
import { Button } from "react-bootstrap";
import { SlGraduation } from "react-icons/sl";
// import staff from "../assets/staff.png";
import staff from "../../../assets/staff.png";
import students from "../../../assets/student.png";
import placement from "../../../assets/placement.png";
import internship from "../../../assets/internship.png";
import clg1 from "../../../assets/clg1.jpg";

import clg2 from "../../../assets/clg2.jpg";
import clg3 from "../../../assets/clg3.jpg";
import { Carousel } from "react-bootstrap";
import { GiTeacher } from "react-icons/gi";
import { IoLibraryOutline } from "react-icons/io5";
import { MdSportsHandball } from "react-icons/md";
import globe from "../../../assets/globe.png";
import 'aos/dist/aos.css';
import AOS from 'aos';



function College() {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Set animation duration in milliseconds
      easing: 'ease-in-out', // Animation easing
    });
  }, []);

  return (
    <div>
      <div>
        <Row>
          <Col className="start_clg">
            <Carousel className="clg_carousel">
              <Carousel.Item>
                <img
                  style={{ width: "100%", height: "80vh" }}
                  className="d-block w-100"
                  src={clg1}
                  alt="First slide"
                />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  style={{ width: "100%", height: "80vh" }}
                  className="d-block w-100"
                  src={clg2}
                  alt="Second slide"
                />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  style={{ width: "100%", height: "80vh" }}
                  className="d-block w-100"
                  src={clg3}
                  alt="Third slide"
                />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </div>
      <div style={{ paddingTop: "3%" }}>
        <Row>
          <Col className="card_clg" md={6}>
            <Card className="clg_card_content_divi" style={{}}  data-aos="fade-right" autoplay>
              <Card.Img
                src={staff}
                className="card_img_cad"
                style={{ width: "100%", height: "100%" }}
              />
              <div className="clg_txt_card_content_card">
                <Card.Text className="txt_card">
                  🏛
                  <br />
                  Staffs
                </Card.Text>
              </div>
            </Card>
          </Col>
          <Col className="card_clg" md={6}>
            <Card className="clg_card_content_divi" style={{}}  data-aos="fade-left">
              <Card.Img
                src={students}
                className="card_img_cad"
                style={{ width: "100%", height: "100%" }} 
              />
              <div className="clg_txt_card_content_card">
                <Card.Text className="txt_card">
                  <SlGraduation />
                  <br />
                  Students
                </Card.Text>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="card_start_clg" md={3}>
            <Card data-aos="fade-down"
              className="clg_hover_card_clr"
              style={{
                width: "18rem",
                backgroundColor: "#FF0000",
                color: "White",
              }}
            >
              <Card.Body>
                <Card.Title>
                  <GiTeacher size={40} />
                  <br />
                  Certified Teachers
                </Card.Title>
                <Card.Text>
                  A teaching qualification is one of a number of academic and
                  professional degrees that enables a person to become a
                  registered teacher.
                </Card.Text>
                <Button
                  style={{
                    color: "#FF0000",
                    backgroundColor: "White",
                    borderColor: "#FF0000",
                  }}
                >
                  View More
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="card_start_clg" md={3}>
            <Card data-aos="fade-up"
              className="clg_hover_card_"
              style={{
                width: "18rem",
                color: "#FF0000",
                backgroundColor: "White",
              }}
            >
              <Card.Body>
                <Card.Title>
                  <SlGraduation size={40} />
                  <br />
                  Special Eduction
                </Card.Title>
                <Card.Text>
                  The education differ socially, mentally, or physically from
                  the average to such an extent that they require modifications
                  of usual school practices.
                </Card.Text>
                <Button
                  style={{
                    backgroundColor: "#FF0000",
                    color: "White",
                    borderColor: "#FF0000",
                  }}
                >
                  View More
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="card_start_clg" md={3}>
            <Card data-aos="fade-down"
              className="clg_hover_card_clr"
              style={{
                width: "18rem",
                backgroundColor: "#FF0000",
                color: "White",
              }}
            >
              <Card.Body>
                <Card.Title>
                  <IoLibraryOutline size={40} />
                  <br />
                  Books & Library
                </Card.Title>
                <Card.Text>
                  A collection or group of collections of books / other print or
                  nonprint materials organized for use (reading, consultation,
                  study, research)
                </Card.Text>
                <Button
                  style={{
                    color: "#FF0000",
                    backgroundColor: "White",
                    borderColor: "#FF0000",
                  }}
                >
                  View More
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="card_start_clg" md={3}>
            <Card data-aos="fade-up"
              className="clg_hover_card_"
              style={{
                width: "18rem",
                color: "#FF0000",
                backgroundColor: "White",
              }}
            >
              <Card.Body>
                <Card.Title>
                  <MdSportsHandball size={40} />
                  <br />
                  Sports Club
                </Card.Title>
                <Card.Text>
                  The Club is developed to encourage physical activities and
                  sports. The aim is to bring together all the faculty and
                  participate in Institutional & State-Level Sports.
                </Card.Text>
                <Button
                  style={{
                    backgroundColor: "#FF0000",
                    color: "White",
                    borderColor: "#FF0000",
                  }}
                >
                  View More
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row style={{ paddingTop: "5%" }}>
          <Col>
            <h3 style={{ paddingLeft: "7%" }}>Explore Opportunities</h3>
          </Col>
        </Row>
        <Row>
          <Col className="clg_flex_card" md={4}>
            <Card className="clg_card_hov" style={{ width: "20rem" }} data-aos="flip-left">
              <img style={{ height: "25vh" }} src={placement} alt="..." />
              <Card.Body>
                <Card.Title
                  style={{ textAlign: "center", color: "#FF0000" }}
                ></Card.Title>
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "#FF0000",
                    borderColor: "#FF0000",
                  }}
                  variant="primary"
                >
                  PLACEMENTS
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={8} style={{ paddingTop: "3%" }}>
            <Card className="para_clg" style={{ width: "20rem" }}  data-aos="zoom-in">
              <Card.Text>
                Aid students to undertake final year project work in the
                organization Organize model tests, aptitude test, group
                discussions and contest in order to train final and pre-final
                students to face interviews and competitions Arrange off campus
                interviews for recruitment through Alumni.
              </Card.Text>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="clg_flex_card" md={5}>
            <Card className="clg_card_hov" style={{ width: "20rem" }} data-aos="flip-left">
              <img style={{ height: "25vh" }} src={internship} alt="..." />
              <Card.Body>
                <Card.Title
                  style={{ textAlign: "center", color: "#FF0000" }}
                ></Card.Title>
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "#FF0000",
                    borderColor: "#FF0000",
                  }}
                  variant="primary"
                >
                  INTERNSHIPS
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={7} style={{ paddingTop: "3%" }}>
            <Card className="para_clg" style={{ width: "20rem" }} data-aos="zoom-in">
              Internships help you understand your field, cement (or sometimes,
              change) your career goals, and they provide adequate experience to
              give you a fighting chance at securing an entry-level job after
              graduation.
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="clg_flex_card" md={6}>
            <Card className="clg_card_hov" style={{ width: "20rem" }} data-aos="flip-left">
              <img style={{ height: "25vh" }} src={globe} alt="..." />
              <Card.Body>
                <Card.Title
                  style={{ textAlign: "center", color: "#FF0000" }}
                ></Card.Title>
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "#FF0000",
                    borderColor: "#FF0000",
                    width:"23vh"

                  }}
                  variant="primary"
                >
                  GLOBAL SEMINAR
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} style={{ paddingTop: "3%" }}>
            <Card className="para_clg" style={{ width: "20rem" }} data-aos="zoom-in">
              Each summer, Students span the globe to explore the international
              dimensions of their academic interests in six-week courses taught
              by University.
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default College;
