import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Api from "../Api";
import "./counsellorCarousel.css";

function CounsellorCarousel() {
  const [counsellors, setCounsellors] = useState([]);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchCounsellors = async () => {
      try {
        const response = await Api.get("trainer/Trainerget"); // Replace with your API endpoint
        setCounsellors(response.data.data);
      } catch (error) {
        console.error("Error fetching counsellor data:", error);
      }
    };

    fetchCounsellors();
  }, []);

  const handleViewMore = () => {
    navigate("/CounsellorLandingPage"); // Navigate to the desired page
  };

  return (
    <div>
      <h1 className="text-center my-4" style={{ fontWeight: "500" }}>
        Our Counsellors
      </h1>
      <div className="counsellor-card-container">
        {counsellors.slice(0, 4).map((counsellor) => (
          <div className="counsellor-card" key={counsellor._id}>
            <img
              src={counsellor.profilePhoto}
              alt={`${counsellor.firstName} ${counsellor.lastName}`}
              className="counsellor-card-img"
            />
            <div className="counsellor-card-body">
              <h5 className="counsellor-card-title">{counsellor.firstName}</h5>
              <p className="counsellor-card-text">
                <strong>Experience:</strong>{" "}
                {counsellor.experience || "5 Years"}
              </p>
              <button className="counsellor-chat-button">Chat</button>
            </div>
          </div>
        ))}
      </div>

      {/* View More Button */}
      {counsellors.length > 4 && (
        <div className="text-center my-3">
          <button
            className="view-more-button"
            onClick={handleViewMore} // Call handleViewMore on click
          >
            View More
          </button>
        </div>
      )}
    </div>
  );
}

export default CounsellorCarousel;
