import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import styled from "styled-components";
import {
  allUsersRoute,
  recieveMessageRoute,
  recieveOneMessageRoute,
} from "../Utils/ApiRoutes";
import ChatContainer from "./ChatApplication";
import Contacts from "./ChatContacts";
import Welcome from "./WelcomeChat";

export default function Chat() {
  const socket = useRef();
  const [contacts, setContacts] = useState([]);
  const [currentUser, setCurrentUser] = useState("gowtham");
  const [currentChat, setCurrentChat] = useState();

  const [onlineUsers, setOnlineUsers] = useState([]);
  const [ids, setIds] = useState([]);
  console.log("ids", ids);

  useEffect(() => {
    const fetchUserData = async () => {
      const user = localStorage.getItem("role");
      if (user) {
        socket.current = io("wss://careerblitz.in", {
          transports: ["websocket"],
        });

        socket.current.on("connect", () => {
          console.log("Socket connected with ID:", socket.current.id);
        });

        socket.current.on("disconnect", () => {
          console.log("Socket disconnected");
        });
        socket.current.on("online-users", (users) => {
          setOnlineUsers(users);
        });
        socket.current.emit("add-user", user._id);
      }
    };

    fetchUserData();

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = localStorage.getItem("id");
        if (id) {
          const response = await axios.post(recieveOneMessageRoute, {
            to: id,
          });
          const studentIds = response.data.map((item) => item.sender);
          setIds(studentIds);
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const contactRequests = ids?.map((id) =>
          axios.get(`${allUsersRoute}${id}`)
        );
        const responses = await Promise.all(contactRequests);
        console.log("responses", responses);
        const contactsData = responses.map((res) => res.data.data);
        setContacts(contactsData);
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    fetchContacts();
  }, [ids]);

  const handleChatChange = (chat) => {
    setCurrentChat(chat);
  };

  return (
    <>
      <Container style={{ overflow: "hidden" }}>
        <div className="container">
          <Contacts contacts={contacts} changeChat={handleChatChange} />
          {currentChat === undefined ? (
            <Welcome username={currentUser?.firstName} />
          ) : (
            <ChatContainer currentChat={currentChat} socket={socket} />
          )}
        </div>
      </Container>
    </>
  );
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background-color: #80808040;
  .container {
    height: 85vh;
    width: 60vw;
    background-color: #00000076;
    display: grid;

    @media (min-width: 720px) and (max-width: 1080px) {
      grid-template-columns: 35% 65%;
    }
  }
`;
