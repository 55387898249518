import React, { useEffect, useState } from "react";
import { Table, Button, Input, Popconfirm, Modal } from "antd";
import "../../../Components/Css/Table.scss";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";
import 'aos/dist/aos.css';
import AOS from 'aos';
// import DataListTable from "../datalistTable";

function Curriculam() {
  const [techList, setTechList] = useState([]);
  const [getData, setGetData] = useState();
  const [search, setSearch] = useState();

  useEffect(() => {
    getCourse();
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Set animation duration in milliseconds
      easing: 'ease-in-out', // Animation easing
    });
  }, []);

  const getCourse = async () => {
    await Api.get("Course/get").then((res) => {
      setTechList(res.data);
    });
  };

  const deleteCourse = (id) => {
    Api.delete(`Course/delete/${id}`).then((resp) => {
      getCourse();
    });
  };
  const update = (ele) => {
    navigate("create", { state: { ele } });
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    const Courses = e.target.value;

    const newData = techList.filter((list) =>
      list.Course.toLowerCase().includes(Courses.toLowerCase())
    );
    setGetData(newData);
  };

  const columns = [
    {
      title: "Course Name",
      dataIndex: "Course",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },
    {
      title: "Course Mode",
      dataIndex: "courseMode",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },
    {
      title: "created At",
      dataIndex: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (text, record, index) =>(
       
      <div data-aos="fade-right" data-aos-delay={index * 100}>
          {new Date(record.createdAt).toLocaleDateString("en-GB")}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record, index) => (
        <div data-aos="zoom-in" data-aos-delay={index * 100}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              update(record);
            }}
          />

          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => {
              deleteCourse(record.id);
            }}
          >
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ paddingLeft: "10px", cursor: "pointer" }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  // const { Search } = Input;

  const onSearch = "";
  const navigate = useNavigate();
  return (
    <div>
      <div className="data_list_main">
        <h4 className="data_list_header">Course List</h4>
        <div
          className="data_list_top mt-4 py-4 px-3"
          style={{ backgroundColor: "#c5c5c5" }}
        >
          <input
            className="data_list_search ps-2"
            placeholder="search by course name"
            columns={columns}
            // dataList={techList}
            value={search}
            onChange={handleSearch}
            // enterButton
          />

          <Button
            className="data_list_button"
            onClick={() => navigate("curriculamcreate")}
          >
            <BsFillPersonPlusFill />
            &nbsp;Add New 
          </Button>
        </div>
        <div className="Datalist-Table">
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={getData ? getData : techList}
            pagination={true}
            className="mt-2"
          />
        </div>
      </div>
    </div>
  );
}

export default Curriculam;
