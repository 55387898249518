import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import ChatInput from "./ChatInput";
import Logout from "./Logout";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { sendMessageRoute, recieveMessageRoute } from "../Utils/ApiRoutes";
import image from "../../Images/chat.webp";
import { RiCheckDoubleLine } from "react-icons/ri";

export default function ChatApplication({ currentChat, socket }) {
  const [messages, setMessages] = useState([]);
  const scrollRef = useRef();
  const [arrivalMessage, setArrivalMessage] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      if (!currentChat) return;

      try {
        const id = localStorage.getItem("id");
        if (id && currentChat) {
          const response = await axios.post(recieveMessageRoute, {
            from: id,
            to: currentChat._id,
          });
          console.log("response", response);
          setMessages(response.data);
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchData();
  }, [currentChat]);

  useEffect(() => {
    if (socket.current && currentChat) {
      const handleMessageReceive = (msg) => {
        setArrivalMessage({
          fromSelf: false,
          message: msg,
        });
      };

      socket.current.on("msg-receive", handleMessageReceive);

      return () => {
        socket.current.off("msg-receive", handleMessageReceive);
      };
    }
  }, [socket, currentChat]);

  useEffect(() => {
    if (arrivalMessage && currentChat) {
      setMessages((prevMessages) => [...prevMessages, arrivalMessage]);
    }
  }, [arrivalMessage, currentChat]);

  const handleSendMsg = async (msg) => {
    const id = localStorage.getItem("id");
    if (!id || !currentChat) return;

    socket.current.emit("send-msg", {
      to: currentChat._id,
      from: id,
      msg,
    });

    const newMessage = { fromSelf: true, message: msg };
    setMessages((prevMessages) => [...prevMessages, newMessage]);

    await axios.post(sendMessageRoute, {
      from: id,
      to: currentChat._id,
      message: msg,
    });
  };
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const [currentDateTime, setCurrentDateTime] = useState("");

  useEffect(() => {
    const now = new Date();
    const formattedDateTime = now.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",

      hour12: true,
    });
    setCurrentDateTime(formattedDateTime);
  }, []);

  const [isTyping, setIsTyping] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);

  useEffect(() => {
    if (socket.current) {
      const handleTypingEvent = (data) => {
        if (data.from === currentChat._id) {
          setIsTyping(true);
          setTimeout(() => {
            setIsTyping(false);
          }, 3000);
        }
      };
      socket.current.on("online-users", (users) => {
        setOnlineUsers(users);
      });

      socket.current.on("user-typing", handleTypingEvent);

      return () => {
        socket.current.off("user-typing", handleTypingEvent);
      };
    }
  }, [socket, currentChat]);

  const isUserOnline = onlineUsers.includes(currentChat?._id);
  const [balanceInSeconds, setBalanceInSeconds] = useState(120);

  useEffect(() => {
    const interval = setInterval(() => {
      setBalanceInSeconds((prevBalance) => {
        if (prevBalance > 0) {
          return prevBalance - 1;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const minutes = Math.floor(balanceInSeconds / 60);
  const seconds = balanceInSeconds % 60;

  return (
    <Container>
      <div className="chat-header">
        <div className="user-details">
          {/* <div className="avatar">
            <img src={currentChat?.image} />
          </div>
          &nbsp;
          <div className="username">
            <div>
              <span
                style={{ position: "relative", top: "8px", fontSize: "13px" }}
              >
                {currentChat?.firstName}
              </span>
            </div>
            <div style={{ color: "green", position: "relative", top: "1px" }}>
              <span>Balance : </span>
              <span>
                ({minutes}:{seconds < 10 ? `0${seconds}` : seconds})
              </span>
            </div>
            <div>
              {isTyping ? (
                <span
                  style={{
                    fontSize: "10px",
                    position: "relative",
                    bottom: "6px",
                  }}
                >
                  Typing...
                </span>
              ) : (
                <>
                  {isUserOnline ? (
                    <span
                      style={{
                        fontSize: "10px",
                        position: "relative",
                        bottom: "6px",
                      }}
                    >
                      Online
                    </span>
                  ) : (
                    <span
                      style={{
                        fontSize: "10px",
                        position: "relative",
                        bottom: "6px",
                      }}
                    >
                      Offline
                    </span>
                  )}
                </>
              )}
            </div>
          </div> */}
          <div className="user-details">
            <div className="avatar">
              <img
                src={`data:image/svg+xml;base64,${currentChat.avatarImage}`}
                alt=""
              />
            </div>
            <div className="username">
              <h3>{currentChat.firstName}</h3>
            </div>
          </div>
        </div>
        <Logout />
      </div>
      <div
        className="chat-messages"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        {messages.map((message) => {
          let parsedMessage;

          try {
            parsedMessage = JSON.parse(message.message);
          } catch (e) {
            parsedMessage = message.message;
          }
          const messageTime = new Date(message.date).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });

          return (
            <div ref={scrollRef} key={uuidv4()}>
              <div
                className={`message ${
                  message.fromSelf ? "sended" : "recieved"
                }`}
              >
                <div className="content">
                  <span style={{ color: "black" }}>{parsedMessage}</span>

                  <br />
                  <span
                    style={{
                      color: "black",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    {messageTime}&nbsp;&nbsp;
                    <RiCheckDoubleLine size={17} color="#34b7f1" />
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <ChatInput
        handleSendMsg={handleSendMsg}
        currentChat={currentChat}
        socket={socket}
      />
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 10% 80% 10%;
  gap: 0.1rem;
  overflow: hidden;
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-rows: 15% 70% 15%;
  }
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f8b410;
    padding: 0 2rem;
    .user-details {
      display: flex;
      align-items: center;

      .avatar {
        img {
          height: 3rem;
        }
      }
      .username {
        span {
          font-size: 10px;
          font-weight: bold;
        }
      }
    }
  }
  .chat-messages {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #ffffff39;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    .message {
      display: flex;
      align-items: center;
      .content {
        max-width: 40%;
        overflow-wrap: break-word;
        padding: 1rem;
        font-size: 1.1rem;
        border-radius: 1rem;
        color: #d1d1d1;
        @media screen and (min-width: 720px) and (max-width: 1080px) {
          max-width: 70%;
        }
      }
    }
    .sended {
      justify-content: flex-end;
      .content {
        background-color: #fffbcc;
      }
    }
    .recieved {
      justify-content: flex-start;
      .content {
        background-color: white;
      }
    }
  }
`;
