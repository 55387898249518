import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Layout from '../Components/Layout';

const PricingPage = () => {
    const navigate = useNavigate(); 

  
    const handleEnrollClick = (price) => {
        navigate('/pricingpayform', { state: { price } }); 
    };

    return (
        <Layout>
        <Container className="mt-5">
            <h1 className="text-center mb-4">Our Pricing Plans</h1>
            <Row>

                <Col md={4} className="mb-4">
                    <Card className="shadow-sm border-0">
                        <Card.Body className="text-center">
                            <h4 className="card-title">Classic</h4>
                            <h2 className="pricing"><sup>Rs</sup>20000</h2>
                            <p>Course Duration: 3 Month</p>
                            <p>Mode: Online</p>
                            <p>Technologies Covered: HTML, CSS, JavaScript, Frontend, Backend</p>
                            <Button onClick={() => handleEnrollClick(20000)} variant="primary" size="lg">Enroll Now</Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4} className="mb-4">
                    <Card className="shadow-lg border-0">
                        <Card.Body className="text-center">
                            <h4 className="card-title">Gold</h4>
                            <h2 className="pricing"><sup>Rs</sup>40000</h2>
                            <p>Course Duration: 6 Months</p>
                            <p>Mode: Online & Offline</p>
                            <p>Technologies Covered: React, Node.js, Bootstrap, Frontend, Backend</p>
                            <Button onClick={() => handleEnrollClick(40000)} variant="success" size="lg">Enroll Now</Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4} className="mb-4">
                    <Card className="shadow-lg border-0">
                        <Card.Body className="text-center">
                            <h4 className="card-title">Diamond</h4>
                            <h2 className="pricing"><sup>Rs</sup>60000</h2>
                            <p>Course Duration: 1 Year</p>
                            <p>Mode: Online & Offline</p>
                            <p>Technologies Covered: Full Stack Development, Frontend, Backend, DevOps</p>
                            <Button onClick={() => handleEnrollClick(60000)} variant="warning" size="lg">Enroll Now</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <h2 className="text-center mt-5 mb-4">Refund Policy</h2>
            <Card>
                <Card.Body>
                    <h4 className="mb-3"><FaCheckCircle className="text-primary" /> Eligibility for Refunds</h4>
                    <p>If you experience double entry or payment failure due to our error, report it to us. We'll investigate and resolve the issue within 48 hours and provide a refund if applicable. Once the refund is approved by <strong>AROGANAM TECHNOLOGIES PVT LTD</strong>, it will take 8 to 10 working days to refund to your account.</p>

                    <h4 className="mb-3"><FaCheckCircle className="text-primary" /> Refund Process</h4>
                    <p>To request a refund, please follow these steps:</p>
                    <ul>
                        <li>Provide proof of purchase.</li>
                        <li>Fill out the refund request form.</li>
                        <li>Return the product if applicable.</li>
                    </ul>
                    <p>Once approved, you can expect to receive your refund within 8 to 10 working days.</p>

                    <h4 className="mb-3"><FaCheckCircle className="text-primary" /> Note</h4>
                    <p>The refund is only applicable for double payment or payment failure.</p>
                </Card.Body>
            </Card>
        </Container></Layout>
    );
};

export default PricingPage;
