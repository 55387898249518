import React, { useState, useEffect } from "react";
import { FaEnvelope, FaLinkedin, FaPhoneVolume, FaDownload,FaWhatsapp,FaFacebook } from "react-icons/fa";
import {TfiEmail} from "react-icons/tfi"
import { WhatsappShareButton, FacebookShareButton, EmailShareButton } from "react-share";
import {Button} from "react-bootstrap";
import "./MyCV.css";
import Api from "../../../Api.js";
import careerblitz from "../../../Images/Careerblitz logo.png";

const Resume = () => {
  useEffect(() => {
    getDetails();
    // getCountry();
    // getGender();
    // getMarital();
  }, []);

  const [details, setDetails] = useState();
  const [userData, setUserData] = useState([]);
  const userId = localStorage.getItem("userId");

  const getDetails = () => {
    Api.get(`signup/student_getone/${userId}`).then((res) => {
      setUserData(res.data.data);
      console.log("users:", userData);
    });
  };

  const resumeDownloadUrl = "/resume.pdf";

  return (
    <div className="resume">
      <div>
        <p>
          <img src={careerblitz} style={{ height: "80px", weight: "80px", textAlign: "center" }} alt="none" />
          <br />
          <b>
            careerblitz Certified
            <br />
            <p style={{ textTransform: "capitalize" }}>{userData?.role}</p>
          </b>
        </p>
      </div>
      <header className="resume-header fade-in">
        <h1>{userData?.firstName || ""}</h1>
        <p>Full Stack Developer | Chennai</p>
        <p>
          <a href="tel:{userData?.phone}" target="_blank" rel="noopener noreferrer">
            <FaPhoneVolume /> +91 {userData?.phone || ""}
          </a>{" "}
          |{" "}
          <a href="mailto:{userData?.email}" target="_blank" rel="noopener noreferrer">
            <FaEnvelope /> {userData?.email || ""}
          </a>
        </p>
      </header>

      <section className="resume-section slide-in">
        <h2>PROFILE</h2>
        <p>
          Hands on experience in React JS, HTML, CSS, and Java, specializing in Spring Boot Microservices, Eureka Server, and
          API Gateway. Proficient in building modern and scalable web applications using React's component-based architecture
          and Spring Boot's microservice backend framework. Skilled in designing and implementing RESTful APIs and ensuring
          secure authentication.
        </p>
      </section>

      {/* Skills, Education, Experience, Projects sections remain unchanged */}
      <section className="resume-section skills slide-in">
        <h2>Skills</h2>
        <ul>
          <li>React.js</li>
          <li>JavaScript</li>
          <li>HTML & CSS</li>
          <li>Responsive Design</li>
          <li>Git & GitHub</li>
          <li>Java Spring Boot</li>
          <li>AWS Cloud (EC2)</li>
        </ul>
      </section>

      <section className="resume-section education slide-in">
        <h2>Education</h2>
        <div className="school">
          <h3>M. Sc Computer Science</h3>
          <p>
            SRM College | March 2021 – April 2023
          </p>
          <h3>B. Sc Computer Science</h3>
          <p>SRM College | June 2018 – Feb 2021</p>
        </div>
      </section>

      <section className="resume-section experience slide-in">
        <h2>Experience</h2>
        <div className="job">
          <h5 style={{fontWeight:"bold"}}>Intern, Cross Hurdle Systems</h5>
          <p>Jan 2023 – April 2023 | Bangalore</p>
          <p>
            Worked on a Realtime Project using React JS, Java Spring Boot,
            Microservices, HTML, CSS, NoSQL.
          </p>
          <p>Project Name: careerblitz.io</p>
        </div>
        <div className="job">
          <h5 style={{fontWeight:"bold"}}>Cognex Technology Pvt Ltd</h5>
          <p>April 2023 – Present | Chennai</p>
          <p>
            Worked on a Project using React JS, Java Spring Boot,
            Microservices, HTML, CSS, MongoDB.
          </p>
          <p>Project Name: careerblitz.io, CineKraft , The Movers</p>
        </div>
     
      </section>

      <section className="resume-section projects slide-in">
        <h2>Projects</h2>
        <div className="project">
          <h3>CINEKRAFT</h3>
          <p>
            A comprehensive platform for the entertainment industry, providing
            tools for project management and vendor coordination.
          </p>
        </div>
        <div className="project">
          <h3>careerblitz.io</h3>
          <p>
            A job portal connecting students, employees, employers, and
            institutions for learning, job searches, postings, and campus
            events.
          </p>
        </div>
        <div className="project">
          <h3>The Movers</h3>
          <p>A premier destination for hassle-free relocation services.</p>
        </div>
      </section>

      <section className="resume-section contact slide-in">
        <h2>Contact Information</h2>
        <p>
          <FaLinkedin />
          &nbsp;&nbsp;
          <a href="https://www.linkedin.com/in/ram-00008624a/" target="_blank" rel="noopener noreferrer">
            Linkedin-ram
          </a>
        </p>
        <p>
          <FaPhoneVolume />
          &nbsp;&nbsp;
          <a href="tel:{userData?.phone}" target="_blank" rel="noopener noreferrer">
            +91 {userData?.phone}
          </a>
        </p>
        <p>
          <FaEnvelope />
          &nbsp;&nbsp;
          <a href="mailto:{userData?.email}" target="_blank" rel="noopener noreferrer">
            {userData?.email}
          </a>
        </p>
      </section>

      {/* Download and Share Buttons */}
      <section className="resume-section share-download">
        <h2>Download and Share</h2>
        {/* Download Resume */}
        <a href={resumeDownloadUrl} download="Resume.pdf" className="download-button">
          <FaDownload /> Download Resume
        </a>

        {/* Share via WhatsApp, Facebook, and Email */}
        <div className="share-buttons">
          <WhatsappShareButton url={resumeDownloadUrl} title="Check out my resume!" separator=":: ">
            <Button className="share-btn"><FaWhatsapp className="whatsapp_btn"/></Button>
          </WhatsappShareButton>

          <FacebookShareButton url={resumeDownloadUrl} quote="Check out my resume!">
            <Button className="share-btn"><FaFacebook className="facebook_btn"/></Button>
          </FacebookShareButton>

          <EmailShareButton url={resumeDownloadUrl} subject="My Resume" body="Here is my resume for your reference.">
            <Button className="share-btn"><TfiEmail className="mail_btn"/></Button>
          </EmailShareButton>
        </div>
      </section>
    </div>
  );
};

export default Resume;
