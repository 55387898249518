import React, { useEffect, useState } from "react";
import { GoLocation } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../../../Api";

const CompanyDetails = () => {
  const [data, setData] = useState([]);
  const { id } = useParams();

  const navigate = useNavigate();
  const companyName = data.companyName;
  const companyLogo = data.companyLogo;

  const getData = () => {
    Api.get(`ourprofile/getbyid/${id}`).then((res) => {
      setData(res.data);
    });
  };
  useEffect(() => {
    getData();
  }, []);
//onsubmit

  const onSubmit = () => {
    const details = {
      companyName: companyName,
      companyLogo: companyLogo,
    };
    Api.post("fields/createApproved", details).then((res) => {
      console.log("res", res);
    });
  };

  return (
    <div>
      <div className="viewseminar_container p-4">
        <div className="pages-title mb-3">Company Details</div>
        <div className="seminar_card">
          <div className="flex padcheck">
            <div>
              <div className="inner_container_wrap">
                <div className="seminar_card_date">
                  <span className="pages-title">{companyName}</span>
                </div>
                <div>
                  <img
                    className="homeBanner_image"
                    src={`${process.env.REACT_APP_DEV_BASE_URL}${companyLogo}`}
                  />
                </div>
                <div className="seminar_card_button_wrapper"></div>
                <div className="d-flex mt-3 mb-2">
                  <button
                    className="button1"
                    variant="secondary"
                    onClick={() => navigate("/admin/companylogo")}
                  >
                    Rejected
                  </button>
                  <button
                    className="button2 ms-5"
                    variant="primary"
                    onClick={(e) => {
                      onSubmit();
                    }}
                  >
                    Approved
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
