import HeaderNavbar from "../../Components/HeaderNavbar";
import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "antd";
import { GoLocation } from "react-icons/go";
import { RiSuitcaseLine } from "react-icons/ri";
import { TbWorld } from "react-icons/tb";
// import Api from "../../../../Api";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../Components/Layout";
import Api from "../../Api";
import { Dropdown } from "primereact/dropdown";
function HomeJobLandingPage() {
  const [search, setSearch] = useState(null);
  const [location, setlocation] = useState(null);
  const [experience, setexperience] = useState(null);
  const [salary, setsalary] = useState(null);
  const [createForm, setcreateForm] = useState([]);

  const [toggle, setToggle] = useState(false);

  const bylocation = (user, location) => {
    if (location) {
      return user.location === location;
    } else return user;
  };

  const byexperience = (user, experience) => {
    if (experience) {
      return user.experience === experience;
    } else return user;
  };
  const bysalary = (user, salary) => {
    if (salary) {
      return user.salary === salary;
    } else return user;
  };
  const bySearch = (user, search) => {
    if (search) {
      return user.jobTitle.toLowerCase().includes(search.toLowerCase());
    } else return user;
  };

  const filteredList = (users, location, experience, search) => {
    return users
      .filter((user) => bylocation(user, location))
      .filter((user) => bySearch(user, search))
      .filter((user) => byexperience(user, experience))
      .filter((user) => bysalary(user, salary));
  };

  useEffect(() => {
    getCreateForm();
    getLocation();
  }, []);
  const getCreateForm = async () => {
    await Api.get("createForm/getForm").then((res) => {
      setcreateForm(res.data.data);
    });
  };
  const data = useLocation();
  const job = data.state.jobTitle;
  const navigate = useNavigate();

  const [locationList, setLocationList] = useState([]);
  const [locationValue, setLocationValue] = useState();
  console.log("location", location);
  const getLocation = async () => {
    await Api.get("fields/getlocation").then((res) => {
      setLocationList(res.data.data);
    });
  };
  const onLocation = (e) => {
    console.log("e", e);
    setLocationValue(e.target.value);
  };

  return (
    <div>
      <HeaderNavbar />

      <Row style={{ position: "relative", top: "90px" }}>
        <Col md={6} lg={8}>
          <div className="pages-title mb-3 mt-2 ms-4 ">Filter</div>
          <div className="ms-4">
            <label className="mt-4">Technology</label>
            <br />
            <input
              className="filter_input"
              type="search"
              //   value={job}
              placeholder="search..."
              onChange={(e) => setSearch(e.target.value)}
            />
            <br />
            <label className="mt-4">Location</label>
            <br />
            <select
              className="filter_input"
              onChange={(e) => setlocation(e.target.value)}
            >
              <option value="">Choose Location</option>
              <option value="Chennai">Chennai</option>
              <option value="Bangalore">Bangalore</option>
              <option value="Coimbature">Coimbature</option>
              <option value="other">Other</option>
            </select>
            {/* <Dropdown
              className="Create-input"
              value={locationValue}
              options={locationList}
              //   {...register("location", { required: true })}
              optionLabel={"locationName"}
              optionValue={"_id"}
              onChange={onLocation}
              onChanges={(e) => {
                onLocation();
                setlocation(e.target.value);
              }}
            /> */}
            <br />
            <label className="mt-4">Experience</label>
            <br />
            <select
              className="filter_input"
              onChange={(e) => setexperience(e.target.value)}
            >
              <option value="">Choose Experience</option>
              <option value="Fresher">Fresher</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </select>

            <br />
            <label className="mt-4">Salary</label>
            <br />
            <select
              className="filter_input"
              onChange={(e) => setsalary(e.target.value)}
            >
              <option value="">Choose Salary</option>
              <option value="10000-20000">10000-20000</option>
              <option value="20000-30000">20000-30000</option>
            </select>
          </div>
        </Col>
        <Col md={6}>
          <div className="pages-title mb-3 mt-2">
            choose your matching profile
          </div>
          {filteredList(createForm, location, experience, search).map(
            (user) => (
              <Card
                className="profile-cards mt-5"
                onClick={() => {
                  navigate("/login");
                  setToggle((prev) => {
                    return !prev;
                  });
                }}
              >
                <div key={user.id} className="card-fields">
                  <div>
                    <div className="card_label">
                      <h5 className="jobcard_title">{user.jobTitle}</h5>
                    </div>
                    <div className="card_label">
                      <p className="card_company"> {user.experience}</p>
                    </div>
                    <div className="card_label">
                      <label>
                        <GoLocation />
                      </label>
                      {user.qualification}
                    </div>
                    <div className="card_label">
                      <label>
                        <TbWorld />
                      </label>
                      {user.location}
                    </div>
                    <div className="card_label">
                      <label>
                        <RiSuitcaseLine />
                      </label>
                      {user.jobDescription}
                    </div>
                  </div>
                </div>
              </Card>
            )
          )}
        </Col>
      </Row>
    </div>
  );
}

export default HomeJobLandingPage;
