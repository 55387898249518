import React, { useState, useEffect, createContext } from 'react';
import { themes } from './themes';

export const ThemeContext = createContext();
export const ThemeProvider = ({ children }) => {
  const storedThemeKey = localStorage.getItem('selectedTheme') || 'default';
  
  const [themeKey, setThemeKey] = useState(storedThemeKey); 
  const [theme, setTheme] = useState(themes[storedThemeKey] || themes.default); 

  const changeTheme = (newThemeKey) => {
    setThemeKey(newThemeKey);
    setTheme(themes[newThemeKey] || themes.default); 
    localStorage.setItem('selectedTheme', newThemeKey); 
  };

  useEffect(() => {
    const storedTheme = localStorage.getItem('selectedTheme');
    if (storedTheme) {
      setThemeKey(storedTheme);
      setTheme(themes[storedTheme]);
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, themeKey, changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
