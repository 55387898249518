import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Api from '../../../Api';
import './AddCategory.css';

function AddCategory() {
  const [categories, setCategories] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [sectorId, setSectorId] = useState(null);
  const [domains, setDomains] = useState([]);
  const [domainId, setDomainId] = useState(null);
  const [categoryName, setCategoryName] = useState('');

  // Fetch all sectors on component mount
  useEffect(() => {
    Api.get('/coursesector/getsector')
      .then(res => setSectors(res.data.map(sector => ({ value: sector._id, label: sector.sectorName }))))
      .catch(err => console.error(err));
  }, []);

  // Fetch domains when a sector is selected
  useEffect(() => {
    if (sectorId) {
      Api.get(`/coursedomain/getdomain/${sectorId}`)
        .then(res => setDomains(res.data.map(domain => ({ value: domain._id, label: domain.domainName }))))
        .catch(err => console.error(err));
    } else {
      setDomains([]); // Reset domains when no sector is selected
    }
  }, [sectorId]);

  // Fetch categories when a domain is selected
  useEffect(() => {
    if (domainId) {
      Api.get(`/coursecategory/getcategory/${domainId}`)
        .then(res => setCategories(res.data))
        .catch(err => console.error(err));
    } else {
      setCategories([]); // Reset categories when no domain is selected
    }
  }, [domainId]);

  const addCategory = () => {
    if (sectorId && domainId && categoryName) {
      Api.post('/coursecategory/createcategory', { sectorId, domainId, categoryName })
        .then(res => {
          setCategories([...categories, res.data]);
          setCategoryName(''); // Clear input after successful addition
        })
        .catch(err => console.error(err));
    } else {
      alert('Please select sector, domain, and enter category name.');
    }
  };

  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      opacity: state.isFocused ? 1 : 0,  // Show input only when focused
    }),
    control: (provided) => ({
      ...provided,
      cursor: 'text', // Keep the cursor style like a text input
    }),
  };

  return (
    <div>
      <br></br>
      <br></br>
      <h2>Add Category</h2>
    <div className="addcategory-container">
      

      {/* Select Sector */}
      <Select
        options={sectors}
        onChange={(selectedOption) => setSectorId(selectedOption?.value)}
        placeholder="Select Sector"
        styles={customStyles}
      />

      {/* Select Domain */}
      <Select
        options={domains}
        onChange={(selectedOption) => setDomainId(selectedOption?.value)}
        placeholder="Select Domain"
        isDisabled={!sectorId}
        styles={customStyles}
      />

      {/* Input Category Name */}
      <input
        type="text"
        value={categoryName}
        onChange={e => setCategoryName(e.target.value)}
        placeholder="Enter Category Name"
        disabled={!domainId}
        className="category-input"
      />

      {/* Add Category Button */}
      <button 
        onClick={addCategory} 
        disabled={!sectorId || !domainId || !categoryName}
        className="add-button"
      >
        Add Category
      </button>

      {/* Display categories */}
      <ul className="category-list">
        {categories.map(category => (
          <li key={category._id}>{category.categoryName}</li>
        ))}
      </ul>
    </div>
    </div>
  );
}

export default AddCategory;
