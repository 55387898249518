import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { SnippetsOutlined } from "@ant-design/icons";
import {
  MdPerson,
  MdAreaChart,
  MdRequestPage,
  MdSend,
  MdMenuBook,
  MdDetails,
  MdHome,
} from "react-icons/md";
import { VscCalendar } from "react-icons/vsc";
import { SiSemanticweb } from "react-icons/si";
import { BiMessageDetail } from "react-icons/bi";
import { BsBack, BsCalendar3 } from "react-icons/bs";
import { SiResurrectionremixos } from "react-icons/si";
import {
  AiFillProject,
  AiOutlineContacts,
  AiOutlineSolution,
} from "react-icons/ai";
import { FaBlogger, FaHandPaper } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import "../../../Components/Css/Sidebar.scss";
import SidebarToggle from "../../Student/DefaultLayout/SidebarToogle";

function Sidebar() {
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [isRightSidebarCollapsed, setIsRightSidebarCollapsed] = useState(true);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleRightSidebar = () => {
    setIsRightSidebarCollapsed((prev) => !prev);
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prev) => !prev);
  };

  const handleSubmenuClick = (submenu) => {
    if (submenu === activeSubmenu) {
      setActiveSubmenu(null);
      setIsRightSidebarCollapsed(true);
    } else {
      setActiveSubmenu(submenu);
      setIsRightSidebarCollapsed(false);
    }
  };

  const role = localStorage.getItem("name");
  const id = localStorage.getItem("regid");
  const companyName = localStorage.getItem("companyName");

  const hasSubmenuItems = () => {
    return (
      activeSubmenu === "jobs" ||
      activeSubmenu === "interviewlist" ||
      activeSubmenu === "internship" ||
      activeSubmenu === "employerdetails" ||
      activeSubmenu === "project" ||
      activeSubmenu === "seminar" ||
      activeSubmenu === "contractstaffing" ||
      activeSubmenu === "feedback"
    );
  };

  return (
    <div
      className={`sidebar-container ${isSidebarCollapsed ? "collapsed" : ""}`}
    >
      <SidebarToggle
        toggleSidebar={toggleSidebar}
        isSidebarCollapsed={isSidebarCollapsed}
      />

      {!isSidebarCollapsed && (
        <div className="sidebar-content">
          <div className="nav-list-sidebar">
            <NavLink
              to={`/employer/${companyName}/dashboard`}
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <div className="profile-icon">
                <CgProfile />
              </div>
              <div className="profile-details">
                <div className="profile-role">{role}</div>
                <div className="profile-id">{id}</div>
              </div>
            </NavLink>
            <NavLink
              to={`/employer/${companyName}`}
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdHome className="nav-icon" />
              <span className="side-title">Trending</span>
            </NavLink>
            <NavLink to="/freelancing" ClassName="main-nav-style">
              <div className="glowing"> Freelancing</div>
            </NavLink>
            <NavLink
              to={`/employer/${companyName}/dashboard`}
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdHome className="nav-icon" />
              <span className="side-title">Home</span>
            </NavLink>
            <NavLink
              to={`/employer/${companyName}/OurProfile`}
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdPerson className="nav-icon" />
              <span className="side-title">Our Profile</span>
            </NavLink>
            <NavLink
              to={`/employer/${companyName}/Calendar`}
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <VscCalendar className="nav-icon" />
              <span className="side-title">Leave Calendar</span>
            </NavLink>
            <NavLink
              to={`/employer/${companyName}/Resume`}
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <SiResurrectionremixos className="nav-icon" />
              <span className="side-title">Resume</span>
            </NavLink>
            <NavLink
              to={`/employer/${companyName}/Bgv`}
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <BsBack className="nav-icon" />
              <span className="side-title">Bgv</span>
            </NavLink>

            <div
              className="nav-item"
              onClick={() => handleSubmenuClick("jobs")}
            >
              <FaHandPaper
                className="nav-icon"
                style={{ fontSize: "22px", marginLeft: "6px" }}
              />
              <span className="side-title">Jobs</span>
            </div>
            <div
              className="nav-item"
              onClick={() => handleSubmenuClick("interviewlist")}
            >
              <AiOutlineContacts
                className="nav-icon"
                style={{ fontSize: "22px", marginLeft: "6px" }}
              />
              <span className="side-title">Interview List</span>
            </div>
            <div
              className="nav-item"
              onClick={() => handleSubmenuClick("internship")}
            >
              <AiOutlineContacts
                className="nav-icon"
                style={{ fontSize: "22px", marginLeft: "6px" }}
              />
              <span className="side-title">Internship</span>
            </div>
            <div
              className="nav-item"
              onClick={() => handleSubmenuClick("employerdetails")}
            >
              <AiOutlineContacts
                className="nav-icon"
                style={{ fontSize: "22px", marginLeft: "6px" }}
              />
              <span className="side-title">Employer Details</span>
            </div>
            <div
              className="nav-item"
              onClick={() => handleSubmenuClick("project")}
            >
              <FaHandPaper
                className="nav-icon"
                style={{ fontSize: "22px", marginLeft: "6px" }}
              />
              <span className="side-title">Project</span>
            </div>

            <NavLink
              to={`/employer/${companyName}/training`}
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdPerson className="nav-icon" />
              <span className="side-title">Training</span>
            </NavLink>
            <div
              className="nav-item"
              onClick={() => handleSubmenuClick("seminar")}
            >
              <SiSemanticweb
                className="nav-icon"
                style={{ fontSize: "22px", marginLeft: "6px" }}
              />
              <span className="side-title">Seminar</span>
            </div>
            <div
              className="nav-item"
              onClick={() => handleSubmenuClick("contractstaffing")}
            >
              <SiSemanticweb
                className="nav-icon"
                style={{ fontSize: "22px", marginLeft: "6px" }}
              />
              <span className="side-title">Contract Staffing</span>
            </div>
          </div>
        </div>
      )}

      {hasSubmenuItems() && (
        <div
          className={`right-sidebar ${
            isRightSidebarCollapsed ? "collapsed" : ""
          }`}
        >
          <div className="right-sidebar-header">
            <div className="collapse-btn" onClick={toggleRightSidebar}>
              {isRightSidebarCollapsed ? ">" : "<"}
            </div>
          </div>

          {activeSubmenu === "jobs" && (
            <>
              <NavLink
                to={`/employer/${companyName}/CreateJobForm`}
                className="submenu-item"
              >
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">Create Job</span>
              </NavLink>
              <NavLink
                to={`/employer/${companyName}/viewjob`}
                className="submenu-item"
              >
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">View Job</span>
              </NavLink>
              <NavLink
                to={`/employer/${companyName}/form`}
                className="submenu-item"
              >
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">Schedule Interview</span>
              </NavLink>
            </>
          )}

          {activeSubmenu === "interviewlist" && (
            <>
              <NavLink
                to={`/employer/${companyName}/selected`}
                className="submenu-item"
              >
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">selected</span>
              </NavLink>
              <NavLink
                to={`/employer/${companyName}/hold`}
                className="submenu-item"
              >
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">Hold</span>
              </NavLink>
              <NavLink
                to={`/employer/${companyName}/rejected`}
                className="submenu-item"
              >
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">Rejected</span>
              </NavLink>
            </>
          )}

          {activeSubmenu === "internship" && (
            <>
              <NavLink
                to={`/employer/${companyName}/createInternship`}
                className="submenu-item"
              >
                <AiOutlineSolution className="nav-sub-icon" />
                <span className="sub-side-title">Create Internship</span>
              </NavLink>
              <NavLink
                to={`/employer/${companyName}/viewrequest`}
                className="submenu-item"
              >
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">View Internship</span>
              </NavLink>
            </>
          )}

          {activeSubmenu === "employerdetails" && (
            <>
              <NavLink
                to={`/employer/${companyName}/employerdetail`}
                className="submenu-item"
              >
                <BiMessageDetail className="nav-sub-icon" />
                <span className="sub-side-title">Employer Details</span>
              </NavLink>
              <NavLink
                to={`/employer/${companyName}/emplyerregistration`}
                className="submenu-item"
              >
                <BiMessageDetail className="nav-sub-icon" />
                <span className="sub-side-title">Employer Registeration</span>
              </NavLink>
            </>
          )}

          {activeSubmenu === "project" && (
            <>
              <NavLink
                to={`/employer/${companyName}/project_post`}
                className="submenu-item"
              >
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">Post</span>
              </NavLink>
              <NavLink
                to={`/employer/${companyName}/ViewProject`}
                className="submenu-item"
              >
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">View Project</span>
              </NavLink>
              <NavLink
                to={`/employer/${companyName}/projectapproved`}
                className="submenu-item"
              >
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">Approved</span>
              </NavLink>
            </>
          )}

          {activeSubmenu === "seminar" && (
            <>
              <NavLink
                to={`/employer/${companyName}/createSeminarRequest`}
                className="submenu-item"
              >
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">Create Seminar</span>
              </NavLink>
              <NavLink
                to={`/employer/${companyName}/SeminarRequest`}
                className="submenu-item"
              >
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">View Seminar</span>
              </NavLink>
            </>
          )}
          {activeSubmenu === "contractstaffing" && (
            <>
              <NavLink
                to={`/employer/${companyName}/staff_vendor`}
                className="submenu-item"
              >
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">Vendor Requirment</span>
              </NavLink>
              <NavLink
                to={`/employer/${companyName}/staff_requirement`}
                className="submenu-item"
              >
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">Staff Post</span>
              </NavLink>
              <NavLink
                to={`/employer/${companyName}/staff_search`}
                className="submenu-item"
              >
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">View Post</span>
              </NavLink>
              <NavLink
                to={`/employer/${companyName}/contractrequest`}
                className="submenu-item"
              >
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">Request</span>
              </NavLink>
              <NavLink
                to={`/employer/${companyName}/approved`}
                className="submenu-item"
              >
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">Approved</span>
              </NavLink>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Sidebar;
