import React, { useState, useRef, useEffect } from 'react';
import './Carousel.css';
import img1 from '../Images/service1.jpg';
import img2 from '../Images/learn.jpg';
import img3 from '../Images/imagee3.jpg';
import { useNavigate } from 'react-router-dom';

const images = [
  { src: img1, title: "Comprehensive Learning Experience",description: "Access a broad range of courses, earn industry-recognized certifications, and learn from expert professionals." },
  { src: img2, title: "Comprehensive Career Support",description: "Explore diverse job listings and personalized recommendations. Get expert help with resumes, career advice, and skill development to enhance your employability." },
  { src: img3, title: "Comprehensive College Engagement Platform", description: "Accept applications, post blogs, advertise job opportunities, and promote seminars with ease. Showcase all your activities across the website to maximize visibility and engagement" },
];

function CarouselTop() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);
  const thumbnailRef = useRef(null);
  const autoSlideInterval = useRef(null);
  const timeAutoNext = 8000; 


  const navigate = useNavigate();

  useEffect(() => {
    startAutoSlide();
    
    return () => {
      clearInterval(autoSlideInterval.current);
    };
  }, []);

  const startAutoSlide = () => {
    autoSlideInterval.current = setInterval(() => {
      moveSlider('next');
    }, timeAutoNext);
  };

  const moveSlider = (direction) => {
    const sliderItems = sliderRef.current.querySelectorAll('.item-unique');
    const thumbnailItems = thumbnailRef.current.querySelectorAll('.item-unique');

    let nextIndex;

    thumbnailItems[currentIndex].classList.remove('show');

    if (direction === 'next') {
      nextIndex = (currentIndex + 1) % images.length;
      sliderRef.current.appendChild(sliderItems[0]); 
      thumbnailRef.current.appendChild(thumbnailItems[0]); 
      sliderRef.current.classList.add('next');
    } else {
      nextIndex = (currentIndex - 1 + images.length) % images.length;
      sliderRef.current.prepend(sliderItems[sliderItems.length - 1]); 
      thumbnailRef.current.prepend(thumbnailItems[thumbnailItems.length - 1]);
      sliderRef.current.classList.add('prev');
    }

    setCurrentIndex(nextIndex);

    const nextThumbnailIndex = (nextIndex + 1) % images.length; 
    thumbnailItems[nextThumbnailIndex].classList.add('show');

    sliderRef.current.addEventListener('animationend', () => {
      sliderRef.current.classList.remove('next', 'prev');
    }, { once: true });
  };

  return (
    <div className="body-unique">
      <div className="slider-unique">
        <div className="list-unique" ref={sliderRef}>
          {images.map((image, index) => (
            <div className={`item-unique ${index === currentIndex ? 'first-item' : ''}`} key={index}>
              <img src={image.src} alt={image.title} />
              <div className="content-unique">
                <div className="title-unique" style={{color:"#ff8260",fontSize:"50px"}}>{image.title}</div>
                <div className="type-unique">{image.type}</div>
                <div className="description-unique" style={{color:"#1272d0",fontSize:"20px",fontWeight:"bold"}}>{image.description}</div>
                <div className="buttons-unique">
                  <button className='viewplans'  onClick={() => navigate('/pricing')}>View Plans</button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="thumbnail-unique" style={{display:"none"}} ref={thumbnailRef}>
          {images.map((image, index) => (
            <div className={`item-unique ${index === (currentIndex + 1) % images.length ? 'show' : ''}`} key={index}>
              <img src={image.src} alt={image.title} />
            </div>
          ))}
        </div>
        <div className="nextPrevArrows-unique">
          <button className="prev-unique" onClick={() => moveSlider('prev')}>❮</button>
          <button className="next-unique" onClick={() => moveSlider('next')}>❯</button>
        </div>
      </div>
    </div>
  );
}

export default CarouselTop;
