import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Radio } from "antd";
import { Dropdown } from "primereact/dropdown";
import Api from "../../../Api.js";
import "../../../Components/Css/Students/Student.scss";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";

function Learning() {

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({});

  const [learning, setLearning] = useState([]);
  useEffect(() => {
    getLearning();
  }, []);
  const getLearning = async () => {
    await Api.get(`learningpost/getLearning/`).then((res) => {
      setLearning(res.data.data);
    });
  };
  //      const[learning, setLearning] = useState([]);
  //     const getLearning = async () => {  
  //   await Api.get(`learningpost/getLearning`).then((res) =>{
  //     setLearning(res.data.data);
  //   });
  // };

  const handleFormSubmit = async (id) => {
    const Details = {
      companyName: getValues().companyName,
      course: getValues().course,
      courseDuration: getValues().courseduration,
      courseFees: getValues().Fees,
    };
    const data = new FormData();
    data.append("learningFile", getValues().learningFile[0]);
    await Api.post("learningpost/uploadimage", data).then(async (res) => {
      if (res.status == 201) {
        Details.learningFile = res.data.path;
        await Api.post(`learningpost/createLearningt`, Details)
          .then((res) => {
            console.log('res', res);
          });
      }
    });
  };

  const [resume, setResume] = useState({})
  return (
    <div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="personal-informations p-4">
          <div className="pages-title mb-3">Course</div>
          <Row className="mb-3">
            {/* {Learning.map((data, i) => ( */}
            <Col sm={24} md={8} className=" p-2">
              <label>Upload Image</label>
              <input type="file" className="input-field "
                {...register("learningFile")}
                onChange={(e) => {
                  setResume(e.target.files[0]);
                  console.log("e.target.files[0]", e.target.files[0]);
                }}
              />
              {errors.learningFile && (
                <span className="input-error">
                  Upload Image is required
                </span>
              )}            </Col>
            <Col sm={24} md={8} className=" p-2">
              <label className="input-title">Company Name </label>
              <input
                className="input-field"
                {...register("companyName", { required: true })}
              />
              <br />
              {errors.companyName && (
                <span className="input-error">
                  Company Name is required
                </span>
              )}
            </Col>

            <Col sm={24} md={8} className=" p-2">
              <label className="input-title">Course</label>
              <input
                className="input-field"
                {...register("course", { required: true })}
              />
              <br />
              {errors.course && (
                <span className="input-error">
                  Course is required
                </span>
              )}
            </Col>
            <Col sm={24} md={8} className="p-2">
              <label className="input-title">Course Duration</label>
              <select
                className="input-field"
                {...register("courseduration", { required: true })}
              >
                <option value="">Select</option>
                <option value="">0-1 Month</option>
                <option value="a">0-2 Months</option>
                <option value="b">0-3 Months</option>
                <option value="c">0-4 Months</option>
                <option value="c">0-5 Months</option>
                <option value="c">0-6 Months</option>

              </select>
              <br />
              {errors.courseduration && (
                <span className="input-error">Course Duration required</span>
              )}
            </Col>
            <Col sm={24} md={8} className="p-2">
              <label className="input-title">Fees</label>
              <br />
              <textarea
                rows="1"
                className="input-field"
                {...register("Fees", { required: true })}
              />
              <br />
              {errors.Fees && (
                <span className="input-error">Fees is required</span>
              )}
            </Col>

          </Row>
          <div className="submitbuttons p-2">
            <button
              className="button1 m-2 p-2"
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </button>
            <button className="button2 m-2 p-2" type="reset">
              Reset
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Learning;
