import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import Layout from '../Components/Layout';

const TermsAndConditions = () => {
    return (
        <Layout>
        <Container className="mt-5">
            <h1 className="text-center mb-4">Terms and Conditions</h1>
            <Card>
                <Card.Body>
                    <h4 className="mb-3"><FaCheckCircle /> Introduction</h4>
                    <p>Welcome to Aroganam. These Terms and Conditions govern your use of our website, services, and educational content. By accessing or using our platform, you agree to comply with and be bound by these terms. If you do not agree with these terms, please do not use our services.</p>
                    
                    <h4 className="mb-3"><FaCheckCircle /> Services</h4>
                    <ul>
                        <li><strong>Educational Content:</strong> We provide access to various courses, educational materials, and related services. All content is for personal and educational use only.</li>
                        <li><strong>Account Registration:</strong> You may need to create an account to access certain features. You are responsible for maintaining the confidentiality of your account information and for all activities under your account.</li>
                    </ul>

                    <h4 className="mb-3"><FaCheckCircle /> Payment and Fees</h4>
                    <ul>
                        <li><strong>Payment:</strong> Payment for courses and services is processed through our secure payment system. You agree to pay all applicable fees as detailed at the time of purchase.</li>
                        <li><strong>No Refunds:</strong> As detailed in our Refund Policy, all payments are non-refundable once processed.</li>
                    </ul>

                    <h4 className="mb-3"><FaCheckCircle /> User Conduct</h4>
                    <ul>
                        <li><strong>Prohibited Activities:</strong> You agree not to engage in any activities that may harm or disrupt our services or infringe on the rights of others.</li>
                        <li><strong>Content Usage:</strong> You may not reproduce, distribute, or otherwise use our content for commercial purposes without our prior written consent.</li>
                    </ul>

                    <h4 className="mb-3"><FaCheckCircle /> Intellectual Property</h4>
                    <ul>
                        <li><strong>Ownership:</strong> All content and materials provided on our platform, including but not limited to text, graphics, and logos, are the property of AROGANAM TECHNOLOGIES PVT LTD and are protected by intellectual property laws.</li>
                        <li><strong>License:</strong> You are granted a limited, non-exclusive, non-transferable license to access and use our content for personal, non-commercial purposes.</li>
                    </ul>

                    <h4 className="mb-3"><FaCheckCircle /> Termination</h4>
                    <ul>
                        <li><strong>Termination by Us:</strong> We reserve the right to terminate or suspend your account if you violate these terms or engage in any illegal or harmful activities.</li>
                        <li><strong>Termination by You:</strong> You may terminate your account at any time by contacting our support team. Note that termination will not entitle you to any refunds.</li>
                    </ul>

                    <h4 className="mb-3"><FaCheckCircle /> Limitation of Liability</h4>
                    <ul>
                        <li><strong>Disclaimer:</strong> Our services are provided "as is" and "as available." We make no warranties or representations regarding the accuracy or completeness of the content.</li>
                        <li><strong>Limitation:</strong> To the maximum extent permitted by law, AROGANAM TECHNOLOGIES PVT LTD shall not be liable for any indirect, incidental, or consequential damages arising out of your use of our services.</li>
                    </ul>

                    <h4 className="mb-3"><FaCheckCircle /> Changes to Terms</h4>
                    <p>We reserve the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting on our website.</p>

                    <h4 className="mb-3"><FaCheckCircle /> Contact Information</h4>
                    <p>If you have any questions or concerns regarding these Terms and Conditions, please contact us at <a href="mailto:aroganamtech@gmail.com">aroganamtech@gmail.com</a>.</p>
                </Card.Body>
            </Card>
        </Container>
        </Layout>
    );
};

export default TermsAndConditions;
