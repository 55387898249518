import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { CheckCircleOutlined } from "@ant-design/icons";
import {
  FileSearchOutlined,
  StarOutlined,
  EditOutlined,
  CommentOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import {
  FormOutlined,
  ReadOutlined,
  SolutionOutlined,
  ScheduleOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import {
  FileDoneOutlined,
  ProjectOutlined,
  UserAddOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import AOS from "aos";
import "aos/dist/aos.css";
import "./ContentData.scss";
import { ThemeContext } from "./ThemeContext";

function ContentData() {
  const [activeIndex, setActiveIndex] = useState(0);
  const { theme } = useContext(ThemeContext);

  const handleCardClick = (index) => {
    setActiveIndex(index);
  };
  const features = [
    {
      title: "Comprehensive Learning Experience",
      icon: <CheckCircleOutlined className="right-icon" />,
      about:
        "Access a broad range of courses, earn industry-recognized certifications, and learn from expert professionals.",
    },
    {
      title: "Comprehensive Career Support",
      icon: <FileSearchOutlined className="right-icon" />,
      about:
        "Explore diverse job listings and personalized recommendations. Get expert help with resumes, career advice, and skill development to enhance your employability.",
    },
    // {
    //   title: "Comprehensive College Engagement Platform",
    //   icon: <StarOutlined className="right-icon" />,
    //   about:
    //     "Accept applications, post blogs, advertise job opportunities, and promote seminars with ease. Showcase all your activities across the website to maximize visibility and engagement.",
    // },
    // {
    //   title: "Integrated Talent Management Solutions",
    //   icon: <EditOutlined className="right-icon" />,
    //   about:
    //     "Easily post job openings and projects, streamline hiring, and conduct engaging seminars. Manage background checks and employee leave schedules efficiently—all within one cohesive platform.",
    // },
  ];

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);

  return (
    <Container fluid style={{ padding: "80px", borderRadius: "14px" }}>
      <h1
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontWeight: "500",
          marginBottom: "30px",
        }}
      >
        Our Services
      </h1>
      <Row>
        <Col md={6} data-aos="flip-up" className="d-flex flex-column">
          <ul className="list-unstyled" style={{ width: "90%" }}>
            {features.map((feature, index) => (
              <li
                key={index}
                onClick={() => handleCardClick(index)}
                className={theme.ourServiceLeft}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: activeIndex === index ? "#d6e9f2" : "transparent",
                  borderLeft: activeIndex === index ? "10px solid  red" : "",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  transition: "background 0.4s ease",
                }}
              >
                <div
                  className="icon-container"
                  style={{ marginRight: "15px" }}
                  data-aos="zoom-in"
                >
                  {feature.icon}
                </div>
                <div className="feature-contentt" data-aos="zoom-in">
                  <h4 className="mb-1 featur-left-title">{feature.title}</h4>
                  <h5 className={theme.ourServicesText}>{feature.about}</h5>
                </div>
              </li>
            ))}
          </ul>
        </Col>

        <Col md={6} data-aos="flip-right">
          <div
            className={theme.ourServiceRight}
            style={{
              padding: "20px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          >
            {activeIndex === 0 && (
              <>
                <div className="">
                  <ul className="list-unstyled" style={{ width: "90%" }}>
                    <li data-aos="fade-up" data-aos-delay="300">
                      <CheckCircleOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="mb-3 feature-title">
                          Wide Range of Courses
                        </h4>
                        <p className="feature-sub-content">
                          Explore our extensive catalog of courses, designed to
                          cover everything from foundational knowledge to
                          advanced skills in various fields.
                        </p>
                      </div>
                    </li>
                    <li data-aos="fade-up" data-aos-delay="400">
                      <CheckCircleOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="feature-title">
                          Industry-Recognized Certifications
                        </h4>
                        <p>
                          Earn certifications that are recognized by leading
                          companies and institutions, ensuring you stand out in
                          the job market.
                        </p>
                      </div>
                    </li>
                    {/* <li data-aos="fade-up" data-aos-delay="500">
                      <CheckCircleOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="feature-title">Learn from Experts</h4>
                        <p>
                          Our courses are taught by industry professionals with
                          real-world experience, ensuring you get practical
                          insights and mentorship.
                        </p>
                      </div>
                    </li>
                    <li data-aos="fade-up" data-aos-delay="600">
                      <CheckCircleOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="feature-title">Flexible Learning Options</h4>
                        <p>
                          Study at your own pace with flexible online courses or
                          join live sessions for a structured learning
                          experience.
                        </p>
                      </div>
                    </li>
                    <li data-aos="fade-up" data-aos-delay="700">
                      <CheckCircleOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="feature-title">Career-Focused Curriculum</h4>
                        <p>
                          Our courses are crafted to equip you with the skills
                          employers are seeking, with job placement assistance
                          available upon completion.
                        </p>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </>
            )}
            {activeIndex === 1 && (
              <>
                <div className="">
                  <ul className="list-unstyled" style={{ width: "90%" }}>
                    <li data-aos="fade-down" data-aos-delay="300">
                      <FileSearchOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="mt-4 feature-title">
                          Extensive Job Listings
                        </h4>
                        <p>
                          Discover a wide variety of job opportunities, from
                          internships to advanced roles, across multiple
                          industries.
                        </p>
                      </div>
                    </li>
                    <li data-aos="fade-down" data-aos-delay="400">
                      <StarOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="feature-title">
                          Tailored Job Recommendations
                        </h4>
                        <p>
                          Get job suggestions based on your skills and career
                          goals to find the best matches.
                        </p>
                      </div>
                    </li>
                    <li data-aos="fade-down" data-aos-delay="500">
                      <EditOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="feature-title">
                          Resume & Cover Letter Support
                        </h4>
                        <p>
                          Access tools and expert advice to create standout
                          resumes and cover letters.
                        </p>
                      </div>
                    </li>
                    <li data-aos="fade-down" data-aos-delay="600">
                      <CommentOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="feature-title">Career Counseling</h4>
                        <p>
                          Receive personalized career advice and interview prep
                          from industry professionals.
                        </p>
                      </div>
                    </li>
                    <li data-aos="fade-down" data-aos-delay="700">
                      <CalendarOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="feature-title">
                          Skill Development Courses
                        </h4>
                        <p>
                          Enhance your skills with our certification courses to
                          boost your employability.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            )}
            {activeIndex === 2 && (
              <>
                <div className="">
                  <ul className="list-unstyled" style={{ width: "90%" }}>
                    <li data-aos="fade-left" data-aos-delay="300">
                      <FormOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="mb-3 feature-title">
                          Receive Applications
                        </h4>
                        <p>
                          Colleges can accept and manage student applications
                          directly through our platform.
                        </p>
                      </div>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="400">
                      <ReadOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="feature-title">Post Blogs</h4>
                        <p>
                          Colleges can share educational content and campus
                          updates by posting blogs for student engagement.
                        </p>
                      </div>
                    </li>
                    <li data-aos="fade-left" data-aos-delay="500">
                      <SolutionOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="feature-title">Post Jobs</h4>
                        <p>
                          Advertise faculty, staff, and internship opportunities
                          to recruit talent directly through your job portal.
                        </p>
                      </div>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="600">
                      <ScheduleOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="feature-title">Conduct Seminars</h4>
                        <p>
                          Organize and promote seminars and webinars, allowing
                          students and visitors to register easily.
                        </p>
                      </div>
                    </li>
                    <li data-aos="fade-left" data-aos-delay="700">
                      <AppstoreOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="feature-title">Showcase Everywhere</h4>
                        <p>
                          Colleges can display their activities—applications,
                          jobs, blogs, and seminars—across the entire website.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            )}
            {activeIndex === 3 && (
              <>
                <div className="">
                  <ul className="list-unstyled" style={{ width: "90%" }}>
                    <li data-aos="flip-up" data-aos-delay="300">
                      <FileDoneOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="mb-3 feature-title">Post Jobs</h4>
                        <p>
                          Companies can advertise open job positions to attract
                          the best talent across various domains.
                        </p>
                      </div>
                    </li>
                    <li data-aos="flip-up" data-aos-delay="400">
                      <ProjectOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="feature-title">Post Projects</h4>
                        <p>
                          Post available projects for freelancers or external
                          teams to take part in and collaborate.
                        </p>
                      </div>
                    </li>
                    <li data-aos="flip-up" data-aos-delay="500">
                      <UserAddOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="feature-title">Hire Employees</h4>
                        <p>
                          Streamline the hiring process by reviewing
                          applications, interviewing, and onboarding employees
                          directly through the platform.
                        </p>
                      </div>
                    </li>
                    <li data-aos="flip-up" data-aos-delay="600">
                      <CalendarOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="feature-title">Conduct Seminars</h4>
                        <p>
                          Organize and promote company seminars, webinars, or
                          workshops, engaging both employees and clients.
                        </p>
                      </div>
                    </li>
                    <li data-aos="flip-up" data-aos-delay="700">
                      <SafetyCertificateOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="feature-title">
                          Background Verification
                        </h4>
                        <p>
                          Perform thorough background checks to ensure potential
                          employees meet all required standards and
                          qualifications.
                        </p>
                      </div>
                    </li>
                    <li data-aos="flip-up" data-aos-delay="800">
                      <SolutionOutlined className="tick-icon" />
                      <div className="feature-content">
                        <h4 className="feature-title">Leave Calendar</h4>
                        <p>
                          Manage employee leave schedules effectively using an
                          integrated leave calendar for better workforce
                          management.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ContentData;
