// import React from 'react'
// import "bootstrap/dist/css/bootstrap.min.css"
// import Form from 'react-bootstrap/Form';
// import './filterpage.css';
// import { obj } from "./object";
// import { Card, CardImg, Col, Dropdown, Row , checkbox} from 'react-bootstrap';

// function Stationery() {

//   return (
//     <div>
//           <Row>
//               <Col md={3} style={{backgroundColor:"yellow", position:"sticky"}}>

//                 <div class="dropdown">
//     <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton"
//     data-mdb-toggle="dropdown" aria-expanded="false">
//     Checkbox dropdown
//     </button>
//     <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
//         <li>
//             <a class="dropdown-item" href="#">
//                 <div class="form-check">
//                     <input class="form-check-input" type="checkbox" value="" id="Checkme1" />
//                     <label class="form-check-label" for="Checkme1">Check me</label>
//                 </div>
//             </a>
//         </li>
//         <li>
//             <a class="dropdown-item" href="#">
//                 <div class="form-check">
//                     <input class="form-check-input" type="checkbox" value="" id="Checkme2" checked />
//                     <label class="form-check-label" for="Checkme2">Check me</label>
//                 </div>
//             </a>
//         </li>
//         <li>
//             <a class="dropdown-item" href="#">
//                 <div class="form-check">
//                     <input class="form-check-input" type="checkbox" value="" id="Checkme3" />
//                     <label class="form-check-label" for="Checkme3">Check me</label>
//                 </div>
//             </a>
//         </li>
//         <li><hr class="dropdown-divider" /></li>
//         <li>
//             <a class="dropdown-item" href="#">
//                 <div class="form-check">
//                     <input class="form-check-input" type="checkbox" value="" id="Checkme4" checked />
//                     <label class="form-check-label" for="Checkme4">Check me</label>
//                 </div>
//             </a>
//         </li>
//     </ul>
// </div>
//               </Col>

//               <Col>
//                 <Row style={{backgroundColor:"#e1e1e0"}}>
//                 {obj.map((obj, i) => (
//                     <Col md={4} className="mx-5 mt-5 text-center">
//                   <Card style={{width:"12rem"}}>
//                   <CardImg src={obj.image} style={{height:"150px", weight:"300px"}}/>
//                   <Card.Title >{obj.id}</Card.Title>
//                   <Card.Text > {obj.name}</Card.Text>
//                   <Card.Text > {obj.price}</Card.Text>

//                 </Card>
//                   </Col>
//                 ))}
//                   </Row>
//               </Col>
//           </Row>

//     </div>
//   );
// }

// export default Stationery;

import React from "react";
import "./filterpage.css";
import {
  Nav,
  Navbar,
  NavDropdown,
  Button,
  Container,
  Card,
  Form,
  Row,
  Col,
  Carousel,
} from "react-bootstrap";
import stanew1 from "../../assets/stationary/stationary11.jpg";
import stanew2 from "../../assets/stationary/stationary22.jpg";
import addimg1 from "../../assets/stationary/addimg1.png";
import art from "../../assets/stationary/art.jpg";
import company from "../../assets/stationary/company.jpg";
import calnew from "../../assets/stationary/calnew.jpg";
import dainew from "../../assets/stationary/dainew.jpg";
import gift from "../../assets/stationary/gift.jpg";
import home from "../../assets/stationary/home.jpg";
import pennew from "../../assets/stationary/pennew.jpg";
import fancy from "../../assets/stationary/fancy.jpg";
import { useNavigate } from "react-router-dom";

function Stationary_page() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="Caros_content">
        <Carousel>
          <Carousel.Item>
            <img className="thing_img w-100" src={stanew1} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="thing_img w-100" src={stanew1} alt="Second slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="thing_img w-100" src={stanew2} alt="Third slide" />
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="stationary-card">
        {/* <div className="head">
            <h1 className='color'>Pen Collection</h1>
          </div> */}
        <Row>
          <Col className="cart1" md={3}>
            <Card className="card_ho" onClick={() => navigate("/Pen")}>
              <Card.Img src={pennew} alt="Card image" style={{}} />
              <Card.ImgOverlay>
                <Card.Title className="Card_in">Pen Collections</Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col className="cart1" md={3}>
            <Card className="card_ho" onClick={() => navigate("/art")}>
              <Card.Img src={art} alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title className="Card_in">Art Suppliers</Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col className="cart1" md={3}>
            <Card className="card_ho" onClick={() => navigate("/calculator")}>
              <Card.Img src={calnew} alt="Card image" />

              <Card.ImgOverlay>
                <Card.Title className="Card_in">Calculators </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col className="cart1" md={3}>
            <Card className="card_ho" onClick={() => navigate("/diary")}>
              <Card.Img src={dainew} alt="Card image" />

              <Card.ImgOverlay>
                <Card.Title className="Card_in">Dairys</Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
        <div>
          <Row className="Addimg">
            <img className="thing_img w-100" src={addimg1} alt="First slide" />
          </Row>
        </div>
        {/* <div className="head">
            <h1 className='color'>Art Suppliers</h1>
          </div> */}
        <Row>
          <Col className="cart1" md={3}>
            <Card className="card_ho" onClick={() => navigate("/company")}>
              <Card.Img src={company} alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title className="Card_in">Company Things</Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col className="cart1" md={3}>
            <Card className="card_ho" onClick={() => navigate("/home")}>
              <Card.Img src={home} alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title className="Card_in">Home Things</Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col className="cart1" md={3}>
            <Card className="card_ho" onClick={() => navigate("/fancy")}>
              <Card.Img src={fancy} alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title className="Card_in">Fancy Items </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col className="cart1" md={3}>
            <Card className="card_ho" onClick={() => navigate("/gift")}>
              <Card.Img src={gift} alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title className="Card_in">Gift@Greetings</Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
        {/* <div className="head">
            <h1 className='color'>Calculator</h1>
          </div>
          <Row>
            <Col className="cart1" md={3}>
              <Card className="card_ho" onClick={() => navigate("/basic")}>
                <Card.Img src={cal1} alt="Card image" />
                <Card.ImgOverlay>
                <Card.Title className="Card_in">Basic</Card.Title>
               </Card.ImgOverlay>
              </Card>
            </Col>
            <Col className="cart1" md={3}>
              <Card className="card_ho" onClick={() => navigate("/printing")}>
                <Card.Img src={cal2} alt="Card image" />
                <Card.ImgOverlay>
                <Card.Title className="Card_in">Printing</Card.Title>

                </Card.ImgOverlay>
              </Card>
            </Col>
            <Col className="cart1" md={3}>
              <Card className="card_ho" onClick={() => navigate("/graphing")}>
                <Card.Img src={cal3} alt="Card image" />
                <Card.ImgOverlay>
                <Card.Title className="Card_in">Graphing </Card.Title>
       
                </Card.ImgOverlay>
              </Card>
            </Col>
            <Col className="cart1" md={3}>
              <Card className="card_ho" onClick={() => navigate("/scientific")}>
                <Card.Img src={cal4} alt="Card image" />
                <Card.ImgOverlay>
                <Card.Title className="Card_in">Scientific</Card.Title>       
                </Card.ImgOverlay>
              </Card>
            </Col>
          </Row>
          <div className="head">
            <h1 className='color'>Diary/Notebooks</h1>
          </div>
          <Row>
            <Col className="cart1" md={3}>
              <Card className="card_ho" onClick={() => navigate("/diary")} >
                <Card.Img src={dar1} alt="Card image" />
                <Card.ImgOverlay>
                <Card.Title className="Card_in">Diary</Card.Title>

                </Card.ImgOverlay>
              </Card>
            </Col>
            <Col className="cart1" md={3}>
              <Card className="card_ho" onClick={() => navigate("/notepad")}>
                <Card.Img src={note} alt="Card image" />
                <Card.ImgOverlay>
                <Card.Title className="Card_in">Notepad</Card.Title>

               </Card.ImgOverlay>
              </Card>
            </Col>
            <Col className="cart1" md={3}>
              <Card className="card_ho" onClick={() => navigate("/journal")}>
                <Card.Img src={jour} alt="Card image" />
                <Card.ImgOverlay>
                <Card.Title className="Card_in">Journals</Card.Title>

                </Card.ImgOverlay>
              </Card>
            </Col>
            <Col className="cart1" md={3}>
              <Card className="card_ho" onClick={() => navigate("/scrap")}>
                <Card.Img src={scrap} alt="Card image" />
                <Card.ImgOverlay>
                <Card.Title className="Card_in">Scrap Books</Card.Title>

                </Card.ImgOverlay>
              </Card>
            </Col>
          </Row> */}
      </div>
    </div>
  );
}

export default Stationary_page;
