import React, { useEffect, useState } from "react";
import { Table, Button, Input, Popconfirm, Modal } from "antd";
import "../../../Components/Css/Table.scss";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";
import 'aos/dist/aos.css';
import AOS from 'aos';
// import DataListTable from "../datalistTable";

function BatchList() {
  const [batchList, setbatchList] = useState([]);
  const [getData, setGetData] = useState();
  const [search, setSearch] = useState();

  useEffect(() => {
    getBatchList();
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',    
    });
  }, []);

  const getBatchList = async () => {
    await Api.get("Batch/get").then((res) => {
      setbatchList(res.data);
    });
  };

  const deleteBatch = (id) => {
    Api.delete(`Batch/delete/${id}`).then((resp) => {
      getBatchList();
    });
  };

  const update = (ele) => {
    navigate("create", { state: { ele } });
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    const Batch = e.target.value;

    const newData = batchList.filter(
      (list) =>
        list.course.toLowerCase().includes(Batch.toLowerCase()) ||
        list.batchNo.toLowerCase().includes(Batch.toLowerCase())
    );
    setGetData(newData);
  };

  const columns = [
    {
      title: "Course Name",
      dataIndex: "course",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },
    {
      title: "Schedule",
      // dataIndex: "email",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
         {record.startDate} to {record.endDate}
        </div>
      ),
    },
    {
      title: "Batch No.",
      dataIndex: "batchNo",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },
    {
      title: "created At",
      dataIndex: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          { new Date(record.createdAt).toLocaleDateString("en-GB")}
        </div>
      ),
       
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record, index) => (
        <div data-aos="zoom-in"data-aos-delay={index * 100}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              update(record);
            }}
          />

          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => {
              deleteBatch(record._id);
            }}
          >
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ paddingLeft: "10px", cursor: "pointer" }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  // const { Search } = Input;

  const onSearch = "";
  const navigate = useNavigate();
  return (
    <div>
      <div className="data_list_main">
        <h4 className="data_list_header">Batch List </h4>
        <div
          className="data_list_top mt-4 py-4 px-3"
          style={{ backgroundColor: "#c5c5c5" }}
        >
          <input
            className="data_list_search ps-2"
            placeholder="search by course name or batch No."
            columns={columns}
            // dataList={batchList}
            value={search}
            onChange={handleSearch}
            // enterButton
          />

          <Button className="Datalist-Table" onClick={() => navigate("create")}>
            <BsFillPersonPlusFill />
            &nbsp;Add New
          </Button>
        </div>
        <div className="Datalist-Table">
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={getData ? getData : batchList}
            pagination={true}
            className="mt-2"
          />
        </div>
      </div>
    </div>
  );
}

export default BatchList;
