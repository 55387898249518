// import React from "react";
// import { Col, Row, Card } from "antd";
// import { useNavigate , Link } from "react-router-dom";
// import "../../../Components/Css/Dashboard.scss";
// import jobs from "../../../assets/jobs.jpg";
// import comments from "../../../assets/comment.jpg";
// import trends from "../../../assets/trends.jpg";
// import search from "../../../assets/search.jpg";
// import resume from "../../../assets/resume.jpg.jpg";
// import com from "../../../assets/com.jpg";
// import "./Home.scss";
// import { BiBuildings, BiTimer, BiCalendar } from "react-icons/bi";
// import { AiOutlineArrowRight } from "react-icons/ai";
// import {
//   MdPersonSearch,
//   MdOutlinePermContactCalendar,
//   MdOutlinePeopleOutline,
// } from "react-icons/md";
// import { BsAlarm } from "react-icons/bs";
// import { SlCalender } from "react-icons/sl";

// export default function EmployerDashboard() {
//   const navigate = useNavigate();
//   const data = [
//     {
//       Image: "https://images.pexels.com/photos/4491461/pexels-photo-4491461.jpeg?auto=compress&cs=tinysrgb&w=600",
//       Title: "Get more responses through chat with jobseekers",
//       Content:
//         "Quickly connect with jobseekers through chat and get more responses.",
//     },
//     {
//       Image: "https://images.pexels.com/photos/5668858/pexels-photo-5668858.jpeg?auto=compress&cs=tinysrgb&w=600",
//       Title: "Smart Comments,Trackers & Sementic Search in RESDEX",
//       Content:
//         "Improve efficiency by using smart comments,tasks and reminders.",
//     },
//     {
//       Image: "https://media.istockphoto.com/id/1361507082/photo/businessman-plan-business-growth-and-financial-increase-of-positive-indicators-in-the-year.jpg?b=1&s=612x612&w=0&k=20&c=vZHDbX2To-l7KA5Qr4sPelTpglbRgJ23yKlQkJivEZg=",
//       Title: "Trends",
//       Content:
//         "Hiring trends for IT,BFSI,BPO/ITes, & Pharma during Jul'22- Dec'22",
//     },
//   ];
//   const list = [
//     {
//       icon: <BiTimer />,
//       Title: "2 Minutes to Post",
//       Content:
//         "Quick and easy to Post a free job in less than 5 minute",
//     },
//     {
//       icon: <MdPersonSearch />,
//       Title: "Attract Audience",
//       Content: "Reach to over 8.69 crore talented jobseekers",
//     },
//     {
//       icon: <BiCalendar />,
//       Title: "30 days visibility",
//       Content: "Get quality applies guarenteed with 30 days of yours jobs ads.",
//     },
//   ];
//   return (
//     <div>

//       <div className="m-5">
//         <Card className="FirstCard">
//           <Row>
//             <Col sm={24} md={10}>
//             <img src="https://www.workitdaily.com/media-library/image.jpg?id=19296987&width=980"
//              className="FirstCardImage" />
//             </Col>
//              <Col sm={24} md={12} className="px-2 ms-5">
//               <h4>Search Candidate from India's Largest Talent Pool</h4>
//               <p>
//                 Source candidate from Resdex - India's Largest Pool and find the
//                 perfect talent for your organisation.
//               </p>
//               <button className="FirstCardButton" onClick={() => navigate("/employer/subscription")}>
//                 <h5 style={{ color:"#4f7eb5" }}>View All subscription Plans
//                 <AiOutlineArrowRight /></h5>
//               </button>
//             </Col>
//           </Row>
//         </Card>
//       </div>
//       <p className="DataPara mx-5 ">BLOG POST</p>
//       <h2 className="ms-5">What's New</h2>
//       <Row className="p-5 DataRow">
//         {data.map((data, i) => (
//           <Col sm={24} md={12} lg={6} className="p-2 mx-4">
//             <img src={data.Image} className="DataImage" />
//             <h4 className="mt-3">{data.Title}</h4>
//             <p className="mt-3 pe-4">{data.Content}</p>
//           </Col>
//         ))}
//       </Row>
//       <div className="mx-5 mt-5">
//         <p className="ThirdCardPara">KEY BENEFITS</p>
//         <h2 style={{ textAlign: "center" }}>Why Choose Job Posting?</h2>
//         <Row className="p-3 DataRow">
//           {list.map((list, i) => (
//             <Col sm={24} md={12} lg={8} className="DataCol">
//               <Card className="employercard">
//                 <div style={{ textAlign: "center" }}>
//                   <div style={{ fontSize: "50px" }}>{list.icon}</div>
//                   <h4 className="mt-3">{list.Title}</h4>
//                   <div className="mt-3 pe-4">{list.Content}</div>
//                 </div>
//               </Card>
//             </Col>
//           ))}
//         </Row>
//       </div>
//       <div className="mx-5 mt-5">
//         <p className="ThirdCardPara">PRODUCTS & SERVICES</p>
//         <h2 style={{ textAlign: "center" }}>
//           Job Portal is India's No.1 Job Posting
//         </h2>
//         <h2 style={{ textAlign: "center" }}>& Recruitment Platform</h2>
//         <Card className="FirstCard">
//         <Row>
//           <Col sm={24} md={12} lg={12} className="my-5 px-5">
//             <h3>Portal Job Posting Services - Get Quality Applies</h3>
//             <p>
//               Reach out to millions of jobseekers and hire quickly with our fast
//               and easy job posting services.
//             </p>
//             <Row>
//               <Col sm={12} md={12}>
//                 <BsAlarm className="DataLastIcon" />2 Minutes to Post
//               </Col>
//               <Col sm={12} md={12}>
//                 <MdOutlinePermContactCalendar className="DataLastIcon" />
//                 Unlimited Applies
//               </Col>
//               <Col sm={12} md={12}>
//                 <MdOutlinePeopleOutline className="DataLastIcon" />
//                 Attract Audience
//               </Col>
//               <Col sm={12} md={12}>
//                 <SlCalender className="DataLastIcon" />
//                 30 Day Visibility
//               </Col>
//             </Row>
//           </Col>
//           <Col sm={24} md={12} className="my-5 px-5">
//             <img src="https://images.pexels.com/photos/4344860/pexels-photo-4344860.jpeg?auto=compress&cs=tinysrgb&w=600"
//             className="DataImage" />
//           </Col>
//         </Row>
//         </Card>
//       </div>
//     </div>
//   );
// }

import React, { useState } from "react";
import { Carousel, Button, Row, Col } from "antd";
import 'antd/dist/antd.css'; // Import Ant Design styles

import { Card } from "react-bootstrap";
import "../../../Components/Css/Employer/Home.scss";
import { RiseOutlined, FallOutlined } from "@ant-design/icons";

function Home() {
  const [card, setCard] = useState([
    {
      id: 1,
      title: "IT Firms Tighten Employee Scrutiny Amid High Costs, Thin Margins",
      imageUrl:
        "https://bsmedia.business-standard.com/_media/bs/img/article/2022-07/21/full/1658426672-9664.jpg?im=Resize,width=220",
    },
    {
      id: 2,
      title:
        "Infosys Fires 600 Freshers After They Fail Internal Assessment: Report",
      imageUrl:
        "https://bsmedia.business-standard.com/_media/bs/img/article/2021-04/08/full/1617877079-2632.jpg?im=Resize,width=220",
    },
    {
      id: 3,
      title:
        "Net Hiring In India's Top Four IT Firms Drops By 97% To 1,940 In Q3FY23",
      imageUrl:
        "https://bsmedia.business-standard.com/_media/bs/img/article/2022-02/17/full/1645120958-7814.jpg?im=Resize,width=220",
    },
    {
      id: 4,
      title:
        "Happiest Minds To Acquire SMI Via Upfront, Deferred Equity For 10c",
      imageUrl:
        "https://bsmedia.business-standard.com/_media/bs/img/article/2022-10/05/full/1664988776-175.jpg?im=Resize,width=220",
    },
    {
      id: 5,
      title:
        "Big Tech And 'Sideloading' Debate; IT Firms Fear Security, Privacy Breaches",
      imageUrl:
        "https://bsmedia.business-standard.com/_media/bs/img/article/2021-09/19/full/1632072042-7252.jpg?im=Resize,width=220",
    },
    {
      id: 6,
      title:
        "Bucking The Trend, IT Companies In India To Increase Hiring In 2023: survey",
      imageUrl:
        "https://bsmedia.business-standard.com/_media/bs/img/article/2022-12/02/full/1669946337-2265.jpg?im=Resize,width=220",
    },
  ]);
  return (
    <div>
      <Carousel
        autoplay
        // style={{
        //   height: "500px",
        //   marginLeft: "-30px",
        //   background: "#364d79",
        //   width: "100%",
        // }}
      >
        <div>
          <img
            className="home-image"
            src="https://images.pexels.com/photos/1577882/pexels-photo-1577882.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          ></img>
        </div>
        <div>
          <img
            className="home-image"
            src="https://images.pexels.com/photos/2449452/pexels-photo-2449452.jpeg?auto=compress&cs=tinysrgb&w=600"
          ></img>
        </div>
        <div>
          <img
            className="home-image"
            src="https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&w=600"
          ></img>
        </div>
        <div>
          <img
            className="home-image"
            src="https://www.smartinsights.com/wp-content/uploads/2020/06/content-marketing-strategy-semrush.png"
          ></img>
        </div>
      </Carousel>


      <h2 className="home-head mt-5">Business Standard</h2>
      <hr />
      <div classname="scrolling">
        <marquee direction="left">
        <p className="home-scroll">
          <Button className="home-btnn">Just in</Button> &nbsp; *HDFC Q3 profit
          jumps 13% YoY to Rs 3,691 crore, AUM grows 13% to Rs 7 trn
          *Electronics manufacturing, auto get best of Budget spending on PLI
          scheme
        </p>
        </marquee>
      </div>
      <hr />
      <h2 className="home-head">Trending News</h2>
      <Row>
        {card.map((card, i) => (
          <Col xs={12} sm={12} lg={4}>
            <Card className="trend-card ms-4">
              <img src={card.imageUrl} className="card-image" />
              <a href="/" className="cardlink ms-1">
                {card.title}
              </a>
            </Card>
          </Col>
        ))}
      </Row>
      {/* <Row style={{ display:"flex",justifyContent:"center" }}>
          <Col sm={12} md={4}>
            <h6>Today Markets</h6>
           <p>Friday &nbsp; feb 3-2023</p> 
          </Col>
          <Col sm={12} md={4}>
            <h6><RiseOutlined style={{color:"green"}} size={15}/>  sensex</h6>
            <p>60208.04 (275.80)</p>
          </Col>
          <Col sm={12} md={4}>
            <h6><FallOutlined style={{color:"red"}} size={15}/>Gold</h6>
            <p>57715.00 (-170.00)</p>
          </Col>
          <Col sm={12} md={4}>
            <h6><RiseOutlined style={{color:"green"}} size={15}/> Silver</h6>
            <p>70200.00 (459.00)</p>
          </Col>
          <Col sm={12} md={4}>
            <h6>USD($)/INR</h6>
            <p>81.80 (0.11)</p>
          </Col>
          <Col sm={12} md={4}>
            <h6>Total Dashboard</h6>
            <Button type="success">Click Here </Button>
          </Col>
        </Row> */}
      <hr />
      <h1 className="mx-4">IT Companies</h1>
      <Row>
        <Col sm={12} md={4} className="home-emp-img mx-4">
          <img src="https://bsmedia.business-standard.com/_media/bs/img/article/2015-08/20/full/1440063888-9199.gif?im=Resize,width=220" />
        </Col>
        <Col className="mx-5 justify-content-sm-center" sm={12} md={8}>
          <p>February 02, 2023, Thursday</p>
          <h6>
            Job Growth In Indian IT Sector Declines 25% Over Corrections In
            Hiring
          </h6>
          <p>
            The Hiring Intent Declined Across Both Large IT Giants And Unicorns,
            While Trends Across Other IT Startups Remained Stable As ...
          </p>
          <Button className="btn-more">More Details---</Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col sm={12} md={4} className="home-emp-img mx-4">
          <img src="https://bsmedia.business-standard.com/_media/bs/img/hp/home-page/2021-04/26/full/1619434290-2884.jpg?im=Resize,width=220" />
        </Col>
        <Col className="mx-5 justify-content-sm-center" sm={12} md={8}>
          <p>January 30, 2023, Monday</p>
          <h6>
            Tech Mahindra's Q3 Profit Slides 5.3% As Expenses Jump On Macro
            Challenges
          </h6>
          <p>
            India's Tech Mahindra Reported A Fall In Third-Quarter Profit On
            Monday, Hurt By Higher Expenses And A Subdued Performance By Its ...
          </p>
          <Button className="btn-more">More Details---</Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col sm={12} md={4} className="home-emp-img mx-4">
          <img src="https://bsmedia.business-standard.com/_media/bs/img/article/2023-01/22/full/1674408178-941.jpg?im=Resize,width=220" />
        </Col>
        <Col className="mx-5 justify-content-sm-center" sm={12} md={8}>
          <p>January 27, 2023, Friday</p>
          <h6>
            Amid Layoffs, Techies Looking For Ways To Get More 'Secure' Jobs:
            Report
          </h6>
          <p>
            Global Tech Companies Have Laid Off Around 70,000 Employees In The
            Last One Year...
          </p>
          <Button className="btn-more">More Details---</Button>
        </Col>
      </Row>
      <div className="home-emp-foot mt-5">
        <h4 className="home-emp-center">Trending Now</h4>
        <Button className="btn-more">View All </Button>
      </div>
    </div>
  );
}

export default Home;
