import React from "react";
import { NavLink } from "react-router-dom";
import {
  MdOutlineDashboardCustomize,
  MdPerson,
  MdAreaChart,
  MdMenuBook,
  MdRequestPage,
  MdDetails,
  MdSend,
  MdSchool,
  MdOutlinePersonPin,
} from "react-icons/md";
import { VscPersonAdd } from "react-icons/vsc";
import {
  FaBlogger,
  FaGraduationCap,
  FaSchool,
  FaUserFriends,
  FaResolving,
} from "react-icons/fa";
import { AiFillProject, AiOutlineUser } from "react-icons/ai";
import { BiBuildings, BiBuilding } from "react-icons/bi";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { GiOfficeChair } from "react-icons/gi";
import { RiSuitcaseLine } from "react-icons/ri";

import "../../../../Components/Css/Sidebar.scss";
import { Menu } from "antd";
import { CgProfile } from "react-icons/cg";
// import link from "../../../../assets/accessories/Icon_pmg-removebg-preview,png"
import avathar from "../../../../assets/accessories/Icon_pmg-removebg-preview.png";

const { SubMenu } = Menu;

function Sidebar({ collapsed }) {
  const onClick = (e) => {
    console.log("click ", e);
  };
  const role = localStorage.getItem("name");
  const id = localStorage.getItem("regid");
  return (
    <div className={collapsed === true ? "sidebarcontent open" : "d-none"}>
      <Menu onClick={onClick} mode="inline" className="nav-list">
        <NavLink to="">
          <div className="Nav-Icon2">
            {/* <CgProfile /> */}
            <img
              src={avathar}
              alt="Admin_image"
              style={{ height: "100px" }}
            ></img>
          </div>
          <div className="name-tag">{role}</div>
          <div className="name-tag">{id}</div>
        </NavLink>
        <NavLink to="">
          <MdOutlineDashboardCustomize className="Nav-Icon" />
          Dashboard
        </NavLink>
        <SubMenu 
        icon ={<AiFillProject siz={20} className="Nav-Icon" /> }
        title="Course Fields">
          <NavLink to="/admin/sector">
          Sector
          </NavLink>
          <NavLink to="/admin/domain">
          Domain
          </NavLink>
          <NavLink to="/admin/category">
          Category
          </NavLink>
          <NavLink to="/admin/subcategory">
          SubCategory
          </NavLink>
        </SubMenu>
        <SubMenu
          icon={<AiFillProject size={20} className="Nav-Icon" />}
          title="Users"
        >
          <NavLink to="StudentList">
            <FaGraduationCap className="Nav-Icon" />
            Students
          </NavLink>
          <NavLink to="ProfessionalList">
            <MdOutlinePersonPin className="Nav-Icon" />
            Job Seeker
          </NavLink>
          <NavLink to="EmployerList">
            <AiOutlineUser className="Nav-Icon" />
            Employers
          </NavLink>
          {/* <NavLink to="Companylist">
            <MdOutlineDashboardCustomize className="Nav-Icon" />
            company List
          </NavLink>
          <NavLink to="TrainingInstitutionList">
            <FaSchool className="Nav-Icon" />
            Institute
          </NavLink> */}
          {/* <NavLink to="SchoolList">
            <BiBuilding className="Nav-Icon" />
            School
          </NavLink> */}
          <NavLink to="CollegeList">
            <BiBuildings className="Nav-Icon" />
            College
          </NavLink>
          <NavLink to="/admin/Users/Vendor">
            <BiBuildings className="Nav-Icon" />
            Vendors
          </NavLink>
          <NavLink to="/admin/Users/Trainers">
            <BiBuildings className="Nav-Icon" />
            Trainers
          </NavLink>
          <NavLink to="SchoolList">
            <BiBuilding className="Nav-Icon" />
            Councillor
          </NavLink>
          <NavLink to="#">
            <BiBuildings className="Nav-Icon" />
            Over All Revenue
          </NavLink>
          {/* <NavLink to="/admin/Users/OurEmployees">
            <BiBuildings className="Nav-Icon" />
            Our Employees
          </NavLink> */}

          {/* <NavLink to="companylogo">
            <FaGraduationCap className="Nav-Icon" />
            Logo
          </NavLink> */}
        </SubMenu>
        {/* <NavLink to="Companylist">
          <MdOutlineDashboardCustomize className="Nav-Icon" />
          company List
        </NavLink> */}
        <SubMenu
          icon={<AiFillProject size={20} className="Nav-Icon1" />}
          title="Exam"
        >
          <NavLink to="Question">
            <FaBlogger className="Nav-Icon" />
            QuestionBank
          </NavLink>
          <NavLink to="exampattern">
            <FaBlogger className="Nav-Icon" />
            ExamPattern
          </NavLink>
          {/* <NavLink to="Technology">
            <FaBlogger className="Nav-Icon" />
            Add Technology
          </NavLink> */}
          <NavLink to="curriculamcourse">
            <FaBlogger className="Nav-Icon" />
            Add Curriculam
          </NavLink>

          <NavLink to="examcourse">
            <FaBlogger className="Nav-Icon" />
            Add Course
          </NavLink>
          <NavLink to="trainer">
            <FaBlogger className="Nav-Icon" />
            Add Trainer
          </NavLink>
          <NavLink to="Batch">
            <FaBlogger className="Nav-Icon" />
            Add Batch
          </NavLink>
        </SubMenu>

        <SubMenu
          icon={<FaResolving size={20} className="Nav-Icon1" />}
          title="Resource"
        >
          <SubMenu
            icon={<FaResolving size={20} className="Nav-Icon1" />}
            title="Our Employees"
          >
            <NavLink to="/admin/Resource/OurEmployess">
              <MdOutlineDashboardCustomize className="Nav-Icon" />
              Our Employees list
            </NavLink>
            {/* <NavLink to="admin/Resource/OurEmployee/ActiveEmployee">
            <MdOutlineDashboardCustomize className="Nav-Icon" />
            Active Employee
          </NavLink>
          <NavLink to="admin/Resource/OurEmployee/InActiveEmployee">
            <MdOutlineDashboardCustomize className="Nav-Icon" />
         Inactive Employee
          </NavLink> */}
          </SubMenu>
          {/* <SubMenu
            icon={<FaResolving size={20} className="Nav-Icon1" />}
            title="Contactor Staffing"
          >
            <NavLink to="/admin/Resource/ContactorStaffing">
              <MdOutlineDashboardCustomize className="Nav-Icon" />
              Contactor Staffing list
            </NavLink>
            {/* <NavLink to="admin/Resource/ContactorStaffing/ActiveClients">
            <MdOutlineDashboardCustomize className="Nav-Icon" />
            Active Client
          </NavLink> */}
          {/* <NavLink to="admin/Resource/ContactorStaffing/InActiveClients">
            <MdOutlineDashboardCustomize className="Nav-Icon" />
            Inactive Client
          </NavLink> */}
          {/* </SubMenu> */}
        </SubMenu>
        <SubMenu
          icon={<GiOfficeChair size={20} className="Nav-Icon1" />}
          title="careerblitz"
        >
          {/* <NavLink to="/admin/users/careerblitz/Freelancing">
            <FaBlogger className="Nav-Icon" />
            Freelancing
          </NavLink> */}

          <NavLink to="/admin/users/careerblitz/Jobpost">
            <FaBlogger className="Nav-Icon" />
            Job Post
          </NavLink>
          <NavLink to="/admin/users/careerblitz/ProjectPost">
            <FaBlogger className="Nav-Icon" />
            Project Post
          </NavLink>
          {/* <SubMenu
            icon={<RiSuitcaseLine size={20} className="Nav-Icon1" />}
            title=" Contactor Staffing"
          >
            <NavLink to="/admin/users/careerblitz/ContactorStaffingRequestTable">
              <FaBlogger className="Nav-Icon" />
              Request
            </NavLink>
            <NavLink to="/admin/users/careerblitz/ContactorStaffing/PostTable">
              <FaBlogger className="Nav-Icon" />
              Post
            </NavLink>
          </SubMenu> */}
        </SubMenu>
        <SubMenu
          icon={<HiOutlineOfficeBuilding size={20} className="Nav-Icon1" />}
          title="Blogs & Events"
        >
          <NavLink to="Blogs">
            <FaBlogger className="Nav-Icon" />
            Blog
          </NavLink>
          <NavLink to="studentsBlog">
            <FaBlogger className="Nav-Icon" />
            Students Blog
          </NavLink>
        </SubMenu>

        <SubMenu
          icon={<AiFillProject size={20} className="Nav-Icon1" />}
          title="Others"
        >
          {/* <NavLink to="Jobpostlist">
            <MdOutlineDashboardCustomize className="Nav-Icon" />
            Jobpostlist
          </NavLink> */}

          {/* <NavLink to="Aboutus">
            <MdOutlineDashboardCustomize className="Nav-Icon" />
            AboutUs
          </NavLink> */}
          <NavLink to="companylogo">
            <FaGraduationCap className="Nav-Icon" />
            Logo
          </NavLink>
          {/* <SubMenu
            icon={<AiFillProject size={20} className="Nav-Icon1" />}
            title="Exam"
          >
            <NavLink to="Question">
              <FaBlogger className="Nav-Icon" />
              QuestionBank
            </NavLink>
            <NavLink to="exampattern">
              <FaBlogger className="Nav-Icon" />
              ExamPattern
            </NavLink>

            <NavLink to="examcourse">
              <FaBlogger className="Nav-Icon" />
              Add Course
            </NavLink>
            <NavLink to="trainer">
              <FaBlogger className="Nav-Icon" />
              Add Trainer
            </NavLink>
            <NavLink to="Batch">
              <FaBlogger className="Nav-Icon" />
              Add Batch
            </NavLink>
          </SubMenu> */}
          <SubMenu
            icon={<AiFillProject size={20} className="Nav-Icon1" />}
            title="Advertisement"
          >
            <NavLink to="Advertisementlist">
              <MdOutlineDashboardCustomize className="Nav-Icon" />
              Advertisementlist
            </NavLink>
            <NavLink to="homecarousellist">
              <MdOutlineDashboardCustomize className="Nav-Icon" />
              Home Carousel
            </NavLink>
            <NavLink to="homestudentlist">
              <MdOutlineDashboardCustomize className="Nav-Icon" />
              Home Student
            </NavLink>
          </SubMenu>
        </SubMenu>
        <SubMenu
          icon={<AiFillProject size={20} className="Nav-Icon1" />}
          title="Fields"
        >
          <NavLink to="universityname">
            <FaBlogger className="Nav-Icon" />
           Add University
          </NavLink>
          <NavLink to="/admin/createSchoolList">
            <FaBlogger className="Nav-Icon" />
            Add School
          </NavLink>
          <NavLink to="collegename">
            <FaBlogger className="Nav-Icon" />
            Add College
          </NavLink>
          
          <NavLink to="/admin/languageadded">
            <FaBlogger className="Nav-Icon" />
            Language
          </NavLink>
          <NavLink to="/admin/locationadded">
            <FaBlogger className="Nav-Icon" />
            Location
          </NavLink>
          <NavLink to="/admin/categoryadded">
            <FaBlogger className="Nav-Icon" />
            Category
          </NavLink>
          <NavLink to="/admin/qualificationadded">
            <FaBlogger className="Nav-Icon" />
            Qualification
          </NavLink>
          <NavLink to="/admin/users/fields/district">
            <FaBlogger className="Nav-Icon" />
            City
          </NavLink>
          <NavLink to="/admin/users/fields/area">
            <FaBlogger className="Nav-Icon" />
            Area
          </NavLink>
          <NavLink to="Technology">
            <FaBlogger className="Nav-Icon" />
            Add Technology
          </NavLink>
        </SubMenu>
        <SubMenu
          icon={<AiFillProject size={20} className="Nav-Icon1" />}
          title="Packages"
        >
          <NavLink to="student">
            <FaBlogger className="Nav-Icon" />
            Student
          </NavLink>
          <NavLink to="professional">
            <FaBlogger className="Nav-Icon" />
            Professional
          </NavLink>
          <NavLink to="company">
            <FaBlogger className="Nav-Icon" />
            Company
          </NavLink>
          <NavLink to="vendor">
            <FaBlogger className="Nav-Icon" />
            Vendor
          </NavLink>
        </SubMenu>

        {/* <NavLink to="Aboutus">
          <MdOutlineDashboardCustomize className="Nav-Icon" />
          AboutUs
        </NavLink> */}
      </Menu>
    </div>
  );
}
export default Sidebar;
