import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { BiRupee, BiTimeFive } from "react-icons/bi";
import { CiCalendarDate, CiLocationOn } from "react-icons/ci";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { TfiBag } from "react-icons/tfi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Api from "../../../Api";

function JobRequestInnerPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const name = localStorage.getItem("name");
  const companyName = localStorage.getItem("companyName");

  const jobPost = location.state;
  const UserID = jobPost.UserID;
  const userEmail = jobPost.userEmail;
  const jobId = jobPost.jobId;
  const jobTitle = jobPost.jobTitle;
  const email = jobPost.email;

  const onSubmit = (e) => {
    const details = {
      role: name,
      UserID: UserID,
      userEmail: userEmail,
      jobTitle: jobTitle,
      email: email,
      jobId: jobId,
    };
    Api.post("createForm/jobrequest", details).then((res) => {
      console.log("res", res);
    });
  };
  return (
    <Container>
      <div className="ms-4">
        <h3 className="mt-3">{jobPost.jobTitle}</h3>
        <h5 style={{ color: "blue" }}>{jobPost.jobRole}</h5>
        <div className="ms-4">
          <h6>
            <CiLocationOn /> chennai, Bangalore
          </h6>
          <h6>
            <CiCalendarDate /> october,11
          </h6>
          {/* <h6>
            <BiTimeFive /> {jobPost.jobType}
          </h6> */}
                    <h6>
            <TfiBag /> Experience :{jobPost.experience} years
          </h6>
          <h6>Email : {jobPost.userEmail}</h6>
          {/* <h6>
            <BiRupee /> {jobPost.salaryRange} lakh PA
          </h6> */}
          <h6>Qualification :{jobPost.qualification}</h6>
          <h6>Skills :{jobPost.skill} </h6>
        </div>
        <br />
        <div className="d-flex mt-3 mb-2">
          <Button
            className="login-button"
            variant="secondary"
            onClick={() =>
              navigate(`/employer/${companyName}/viewjob/jobrequest/${id}`)
            }
          >
            rejected
          </Button>
          <Button
            className="login-button ms-5"
            variant="primary"
            onClick={(e) => {
              onSubmit();
            }}
          >
            Approved
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default JobRequestInnerPage;
