import React from "react";
import "./Feril-Library.scss";
import { Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import library1 from "../../assets/library/library1.jpg";
import library2 from "../../assets/library/library2.jpg";
import library3 from "../../assets/library/library3.png";
import tamil_book1 from "../../assets/library/tamil_book1.jpg";
import english_book from "../../assets/library/english_book.jpg";
import kannada_book from "../../assets/library/kannada_book.jpg";
import hindi_book from "../../assets/library/hindi_book.jpg";
import { useNavigate } from "react-router-dom";

function Library() {
  const navigate = useNavigate();
  return (
    <div className="library_overall">
      <div className="library_firstcontent">
        <Row>
          <h2 className="library_secondtext">
            A careerblitz LIBRARY<br></br>A LIFELONG LOVE OF READING AND LEARNING -
            THAT'S OUR
            <br></br>Mission.
          </h2>
          {/* <Col className="language" md={3}>
            <Card className="book" style={{ width: "8rem" }}>
              <Card.Img src={tamil_book1} />
            </Card> */}
          {/* <Card className="mozhi">
              <p class="tooltiptext">
                <b>தமிழ்</b>
              </p>
              <Card.Body style={{ height: "2.3rem" }}>
                <Card.Title>
                  <b>TAMIL</b>
                </Card.Title>
              </Card.Body>
            </Card> */}
          {/* </Col> */}
          {/* <Col className="language" md={3}>
            <Card className="book" style={{ width: "8rem" }}>
              <Card.Img src={english_book} />
            </Card> */}

          {/* <Card className="mozhi">
              <p class="tooltiptext">
                <b>ENGLISH</b>
              </p>
              <Card.Body>
                <Card.Title>
                  <b>ENGLISH</b>
                </Card.Title>
              </Card.Body>
            </Card> */}
          {/* </Col>
          <Col className="language" md={3}>
            <Card className="book" style={{ width: "8rem" }}>
              <Card.Img src={kannada_book} />
            </Card> */}

          {/* <Card className="mozhi">
              <p class="tooltiptext">
                <b>ಕನ್ನಡ</b>
              </p>
              <Card.Body>
                <Card.Title>
                  <b>KANNADA</b>
                </Card.Title>
              </Card.Body>
            </Card> */}
          {/* </Col>
          <Col className="language" md={3}>
            <Card className="book" style={{ width: "8rem" }}>
              <Card.Img src={hindi_book} />
            </Card> */}

          {/* <Card className="mozhi">
              <p class="tooltiptext">
                <b>हिंदी</b>
              </p>
              <Card.Body>
                <Card.Title>
                  <b>HINDI</b>
                </Card.Title>
              </Card.Body>
            </Card> */}
          {/* </Col> */}
        </Row>

        {/* <Row>
          <Col className="job__related-end mt-5 ">
            <Card
              onClick={() => navigate("/careerblitzlibrary/videosection")}
              className="video-section_card "
            >
              <Card.Body className="Datacardrow">
                <Card.Title>
                  <b>VIDEO SECTION</b>
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}

        <div>
          {/* <h1 className="companyheading mt-5 p-2">Our Services</h1> */}

          <Row
            className="text-center mt-5"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Col xs={12} sm={6} md={4} lg={2} className="mt-1 p-0 mb-5">
              <Card
                className="partnercard "
                // onClick={() => navigate("/careerblitzpg")}
              >
                <img
                  className="partnerimage p-3"
                  src="https://img.freepik.com/free-vector/cheap-inn-affordable-guesthouse-college-dormitory-motel-check_335657-3156.jpg?size=626&ext=jpg&ga=GA1.2.1914499439.1676369083&semt=sph"
                  height={170}
                  width={"100%"}
                />
                <Card.Body>
                  <Card.Title>Audiobooks</Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} lg={2} className="mt-1 p-0 mb-5">
              <Card
                className="partnercard "
                onClick={() => navigate("/careerblitzlibrary/careerblitzbooks")}
              >
                <img
                  className="partnerimage p-3"
                  src="https://img.freepik.com/free-vector/book-readers-concept_74855-6263.jpg?size=626&ext=jpg&ga=GA1.2.1914499439.1676369083&semt=sph"
                  height={170}
                  width={"100%"}
                />
                <Card.Body>
                  <Card.Title>E-Books</Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} lg={2} className="mt-1 p-0 mb-5">
              <Card
                className="partnercard "
                onClick={() => navigate("/careerblitzlibrary/videosection")}
              >
                <img
                  className="partnerimage p-3"
                  src="https://img.freepik.com/premium-psd/laptop-gaming-monitor-keyboard-with-red-black-color-simple-design-3d-rendering-icon_557132-448.jpg?size=338&ext=jpg&ga=GA1.1.1914499439.1676369083&semt=sph"
                  height={170}
                  width={"100%"}
                />
                <Card.Body>
                  <Card.Title>Videos</Card.Title>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col xs={12} sm={6} md={4} lg={2} className="mt-1 p-0 mb-5">
              <Card
                className="partnercard "
                onClick={() => navigate("/accessories")}
              >
                <img
                  className="partnerimage p-3"
                  src="https://img.freepik.com/free-vector/smartwatch-concept-illustration_114360-4228.jpg?w=740&t=st=1677060454~exp=1677061054~hmac=5fb391471183ea7176fee57ad28ef8a133c258a4952f2f33fab515d0b746584f"
                  height={170}
                  width={"100%"}
                />
                <Card.Body>
                  <Card.Title>careerblitz Accessories</Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} lg={2} className="mt-1 p-0 mb-5">
              <Card
                className="partnercard "
                onClick={() => navigate("/stationary")}
              >
                <img
                  className="partnerimage p-3"
                  src="https://img.freepik.com/premium-photo/school-equipment-table_200402-857.jpg?size=626&ext=jpg&ga=GA1.2.1914499439.1676369083&semt=sph"
                  height={170}
                  width={"100%"}
                />
                <Card.Body>
                  <Card.Title>careerblitz Stationary</Card.Title>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
        </div>
        {/* <Row>
          <Col className="nool_image p-5" md={4}>
            <img
              className="order_book"
              src={library1}
              style={{ width: "4rem", height: "4rem" }}
              alt="..."
            />
            <h3>Great Collection of Books</h3>
            <p>
              Good collection of Books, <br></br>in multiple languages.
            </p>
          </Col>

          <Col className="nool_image p-5" md={4}>
            <img
              className="order_book"
              src={library2}
              style={{ width: "4rem", height: "4rem" }}
              alt="..."
            />
            <h3>Order Books</h3>
            <p>
              Browse our Catalog<br></br>
              Add Books to your Wishlist
            </p>
          </Col>
          <Col className="nool_image p-5" md={4}>
            <img
              className="order_book"
              src={library3}
              style={{ width: "4rem", height: "4rem" }}
              alt="..."
            />
            <h3>Home Deliver/Pickup</h3>
            <p>
              We delivered to our doorstep/<br></br>Pickup the Books
            </p>
          </Col>
        </Row> */}
      </div>
    </div>
  );
}
export default Library;
