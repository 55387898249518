import TrainerDashboard from "../Pages/Trainer/TrainerDashboard";
import Counsellorprofile from "../Pages/Trainer/CounsellingDefaultLayout/Counsellorprofile";
import { Navigate } from "react-router-dom";
import Chat from "../Pages/Trainer/CounsellingDefaultLayout/Chat";
import Transaction from "../Pages/Trainer/CounsellingDefaultLayout/Transaction";
import Dashboard from "../Pages/Trainer/CounsellingDefaultLayout/Dashboard";
import NewBlogForm from "../Pages/Trainer/CounsellingDefaultLayout/Blog/NewBlogForm";
import CounsBlogTable from "../Pages/Trainer/CounsellingDefaultLayout/Blog/CounsBlogTable";
import CounselorBlog from "../Pages/Trainer/CounsellingDefaultLayout/Blog/CounselorBlog";

const role = localStorage.getItem("role");

const CounsellerRoutes = [
  {
    path: "/counsellor/dashboard",
    name: "Dashboard",
    element: role === "counsellor" ? <Dashboard /> : <Navigate to="/login" />,
  },
  {
    path: "/counsellor/profile",
    name: "profile",
    element:
      role === "counsellor" ? <Counsellorprofile /> : <Navigate to="/login" />,
  },
  {
    path: "/counsellor/chat",
    name: "chat",
    element: role === "counsellor" ? <Chat /> : <Navigate to="/login" />,
  },
  {
    path: "/counsellor/transaction",
    name: "chat",
    element: role === "counsellor" ? <Transaction /> : <Navigate to="/login" />,
  },
  {
    path: "/counsellor/blog/NewBlogForm",
    name: "chat",
    element: role === "counsellor" ? <NewBlogForm /> : <Navigate to="/login" />,
  },
  {
    path: "/counsellor/blog/counsblogtable",
    name: "chat",
    element:
      role === "counsellor" ? <CounsBlogTable /> : <Navigate to="/login" />,
  },
  {
    path: "/counsellor/blog/counselorblog",
    name: "chat",
    element:
      role === "counsellor" ? <CounselorBlog /> : <Navigate to="/login" />,
  },
];

export default CounsellerRoutes;
