import React,{useState, useEffect} from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Dropdown } from "primereact/dropdown";
import { useForm } from "react-hook-form";
import { MultiSelect } from "primereact/multiselect";
import Api from "../../../Api";
import "../../../Components/Css/Employer/ContractStaffing.scss";

function Details() {
    const[skill, setSkill] =useState([]);
    const [skillist, setskilList] = useState([]);
    const [genderList, setGenderList] = useState();
    const [gender, setGender] = useState();
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
      } = useForm();

      useEffect(() => {
        getSkill();
        getGender();
      }, []);
      const getSkill = async () => {
        await Api.get("skills/getSkill").then((res) => {
          setskilList(res.data.data);
        });
      };
      const getGender= async()=>{
        await Api.get("gender/getGenderlist").then((res)=>{
          setGenderList(res.data.data);
        });
      };
      const handleFormSubmit = async ()=>{
         const DetailCollect ={
          Name: getValues().name,
          Technology: getValues(). skill,
          Gender : getValues(). gender,
          Position: getValues().position,
          Salary : getValues().salary,
          JobType: getValues().jobType,
         };
         console.log("collectdetails", DetailCollect);
         await Api.post(`details/createForm`, DetailCollect).then((resp) => {
           console.log("resp.data", resp.data);
         });
       };
       
       const datas =
         skillist &&
         skillist.map((list) => {
           return { label: list?.skill, value: list?.skill };
         });
  return (
    <div>
      <Container>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="personal-informations p-4">
            <div className="staff-title mb-5 ">Details</div>
            <Row className="mt-5">
              <Col sm={12} md={4} className=" px-4 ">
                <label className="input-title"> Name</label>
                <input
                  className="seminarInput"
                  type="text"
                  {...register("name", { required: true })}
                />
                {errors.name && (
                  <span className="Stud-personal-error"> Name is required</span>
                )}
              </Col>
              <Col sm={12} md={4} className=" px-4 ">
                <label className="input-title">Technology</label>
                <MultiSelect
                  className="create-select"
                  options={datas}
                  value={skill}
                  {...register("skill", {
                    required: true,
                    onChange: (e) => {
                      setSkill(e.value);
                    },
                  })}
                  style={{ width: "100%" }}
                  placeholder="Please select a Technology"
                ></MultiSelect>
                  {errors.skill && (
                      <p className="text-danger">Skill is required</p>
                    )} 
              </Col>
              <Col sm={12} md={4} className=" px-4 ">
                <label className="input-title">Gender
                </label>
                 <Dropdown
                className="input-drop"
                value={gender}
                options={genderList}
                {...register("gender", { required: true })}
                optionLabel={"gender"}
                optionValue={"_id"}
                onChange={(e) => setGender(e.value)}
                placeholder="Select a Gender"
              />
                {errors.gender && (
                  <span className="Stud-personal-error">Gender is required</span>
                )}
              </Col>
            </Row>
            <Row className="mt-5">
              <Col sm={12} md={4} className=" px-4 ">
                <label className="input-title"> Position</label>
                <input
                  className="seminarInput"
                  type="text"
                  {...register("position", { required: true })}
                />
                {errors.position && (
                  <span className="Stud-personal-error"> Position is required</span>
                )}
              </Col>
              <Col sm={12} md={4} className=" px-4 ">
                <label className="input-title">Salary(P/A)</label>
                <input
                  className="seminarInput"
                  type="text"
                  {...register("salary", { required: true })}
                />
                {errors.salary && (
                  <span className="Stud-personal-error">Salary is required</span>
                )}
              </Col>
              <Col sm={12} md={4} className=" px-4 ">
                <label className="input-title">Job Type
                </label>
                <input
                  className="seminarInput"
                  type="text"
                  {...register("jobType", { required: true })}
                />
                {errors.jobType && (
                  <span className="Stud-personal-error">Job type is required</span>
                )}
              </Col>
            </Row>
            <div className="submitbuttons px-4 mt-5">
            <button className="button1 m-2" type="submit">
              Submit
            </button>
            <button className="button2 m-2" type="reset">
              Reset
            </button>
          </div>
          </div>
        </form>
      </Container>
    </div>
  );
}

export default Details;
