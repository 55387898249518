// import React, { useState, useEffect } from "react";
// import Api from "../../Api";
// import { useForm } from "react-hook-form";
// import { Row, Card, Col, Button } from "react-bootstrap";
// import "../Vendor/Index.css";
// import { Dropdown } from "primereact/dropdown";

// function Myprofile() {
//   const [countryList, setcountryList] = useState([]);
//   const [countryValue, setCountryValue] = useState();
//   const [stateList, setstateList] = useState([]);
//   const [stateValue, setStateValue] = useState();
//   const [districtList, setdistrictList] = useState([]);
//   const [districtValue, setDistrictValue] = useState();

//   // const [selectedCity, setSelectedCity] = useState(null);
//   const cities = [
//     { name: "New York", code: "NY" },
//     { name: "Rome", code: "RM" },
//     { name: "London", code: "LDN" },
//     { name: "Istanbul", code: "IST" },
//     { name: "Paris", code: "PRS" },
//   ];
//   const [cityvalue, setCityValue] = useState();

//   const Cityselect = [
//     { label: "Theni", value: "Theni" },
//     { label: "Chennai", value: "Chennai" },
//     { label: "Tiruvannamalai", value: "Tiruvannamalai" },
//   ];

//   const city = [
//     { label: "Cumbum", value: "Cumbum" },
//     { label: "Theni", value: "Theni" },
//     { label: "Bodi", value: "Bodi" },
//   ];

//   useEffect(() => {
//     getCountry();
//   }, []);

//   const {
//     register,
//     getValues,
//     handleSubmit,
//     formState: { errors },
//   } = useForm();

//   const getCountry = async () => {
//     await Api.get("country/getallcountry").then((res) => {
//       setcountryList(res.data.data);
//     });
//   };

//   const getState = (country_id) => {
//     console.log("stateList", country_id);
//     setCountryValue(country_id);
//     Api.get(`state/stateById/${country_id}`).then((res) => {
//       setstateList(res.data.data);
//     });
//   };

//   const getDistrict = (state_id) => {
//     setStateValue(state_id);
//     Api.get(`district/districtById/${state_id}`).then((res) => {
//       setdistrictList(res.data.data);
//     });
//   };
//   const [cityList, setCityList] = useState([]);
//   const getCity = (districtId) => {
//     setDistrictValue(districtId);
//     Api.get(`city/cityById/${districtId}`).then((res) => {
//       console.log(res.data, "ghjfhjgf");
//       setCityList(res.data.data);
//     });
//   };
//   const [companyNamess, setCompanyName] = useState();
//   const [emailId, setEmailId] = useState();
//   const [addresss, setAddress] = useState();
//   const [phoneNumber, setPhoneNumber] = useState();
//   const [vendorDetails, setVendorDetails] = useState([]);
//   const [country, setCountry] = useState(null);
//   console.log("country@@", country);
//   const id = localStorage.getItem("userId");
//   useEffect(() => {
//     getValue();
//   }, []);
//   const getValue = () => {
//     Api.get(`/vendor/vendor_get/${id}`).then((res) => {
//       setVendorDetails(res.data.data);
//       setCompanyName(res.data.data.companyName);
//       setPhoneNumber(res.data.data.phone);
//       setEmailId(res.data.data.email);
//       setAddress(res.data.data.address);
//       setCountry(res.data.data.country);
//       console.log("res.data", res.data.data);
//     });
//   };
//   console.log("vendorDetails", vendorDetails);
//   console.log("companyNamess", companyNamess);

//   const handleFormSubmit = async () => {
//     console.log("getValues()", getValues());
//     const Details = {
//       Name: getValues().Name,
//       email: getValues().email,
//       phone: getValues().phone,
//       address: getValues().address,
//       businessName: getValues().businessName,
//       country: getValues().country,
//       state: getValues().state,
//       district: getValues().district,
//       city: getValues().city,
//     };
//     const userId = localStorage.getItem("userId");
//     console.log("Detailsss", Details);
//     await Api.put(`/vendor/vendorput/${userId}`, Details).then((resp) => {
//       alert("your Semester datas stored");
//       console.log(resp.data.data, "respppppp");
//     });
//   };

//   return (
//     <div>
//       <Col xs={12} md={8}>
//         <Card className="form_colum">
//           <h4
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               color: "#03022b",
//               fontWeight: "bold",
//               margin: "4%",
//             }}
//           >
//             Vendor Details
//           </h4>

//           <form onSubmit={handleSubmit(handleFormSubmit)}>
//             <Row className="xg-3">
//               <Col xs={12} md={4}>
//                 <div className="formrow">
//                   Name:
//                   <sup className="required_column">*</sup>
//                 </div>
//               </Col>
//               <Col xs={12} md={8}>
//                 <div>
//                   <input
//                     className="inputcolumn"
//                     type="text"
//                     name="name"
//                     {...register("Name", { required: true })}
//                     value={companyNamess}
//                     onChange={(e) => {
//                       setCompanyName(e.target.value);
//                     }}
//                     required="required"
//                     placeholder="Name"
//                   />
//                   {errors.Name && (
//                     <p className="text-danger">Name is required</p>
//                   )}
//                 </div>
//               </Col>
//             </Row>
//             <Row className="xg-3 mt-3">
//               <Col xs={12} md={4}>
//                 <div className="formrow">
//                   Email:
//                   <sup className="required_column">*</sup>
//                 </div>
//               </Col>
//               <Col xs={12} md={8}>
//                 <div>
//                   <input
//                     className="inputcolumn"
//                     type="text"
//                     name="name"
//                     value={emailId}
//                     onChange={(e) => {
//                       setEmailId(e.target.value);
//                     }}
//                     {...register("email", { required: true })}
//                     // required="required"
//                     placeholder="Name"
//                   />
//                   {errors.email && !emailId && (
//                     <p className="text-danger">Email is required</p>
//                   )}
//                 </div>
//               </Col>
//             </Row>
//             <Row className="xg-3 mt-1">
//               <Col xs={12} md={4}>
//                 <div className="formrow mt-2">
//                   Country:
//                   <sup className="required_column">*</sup>
//                 </div>
//               </Col>
//               <Col xs={12} md={8} className="mt-2">
//                 <Dropdown
//                   filter
//                   className="inputcolumn"
//                   optionLabel="name"
//                   optionValue="id"
//                   placeholder="Select a Country"
//                   value={country}
//                   options={countryList}
//                   // {...register("country", { required: true })}
//                   onChange={(e) => {
//                     setCountry(e.target.value); // Update the 'country' state when the selection changes
//                     getState(e.target.value); // You can also call the 'getState' function here if needed
//                   }}
//                 />
//                 {errors.country && (
//                   <p className="error-text-color-Profile">Country is required</p>
//                 )}
//               </Col>
//             </Row>
//             <Row className="xg-3 mt-1">
//               <Col xs={12} md={4}>
//                 <div className="formrow mt-2">
//                   State:
//                   <sup className="required_column">*</sup>
//                 </div>
//                 &nbsp;
//               </Col>
//               <Col xs={12} md={8} className="mt-2">
//                 <Dropdown
//                   filter
//                   className="inputcolumn"
//                   value={stateValue}
//                   options={stateList}
//                   {...register("state", { required: true })}
//                   optionLabel="name"
//                   optionValue={"id"}
//                   placeholder="Select a State"
//                   onChange={(e) => getDistrict(e.value)}
//                 />
//                 {errors.state && (
//                   <p className="error-text-color-Profile">State is required</p>
//                 )}
//               </Col>
//             </Row>
//             <Row className="xg-3 mt-1">
//               <Col xs={12} md={4}>
//                 <div className="formrow mt-2">
//                   District:
//                   <sup className="required_column">*</sup>
//                 </div>
//               </Col>
//               <Col xs={12} md={8} className="mt-2">
//                 <Dropdown
//                   filter
//                   className="inputcolumn"
//                   value={districtValue}
//                   options={districtList}
//                   {...register("district", { required: true })}
//                   optionLabel={"name"}
//                   placeholder="Select a District"
//                   optionValue={"id"}
//                   onChange={(e) => getCity(e.value)}
//                 />
//                 {errors.district && (
//                   <p className="error-text-color-Profile">District is required</p>
//                 )}{" "}
//               </Col>
//             </Row>
//             <Row className="xg-3 mt-1">
//               <Col xs={12} md={4}>
//                 <div className="formrow mt-2">
//                   City:
//                   <sup className="required_column">*</sup>
//                 </div>
//               </Col>
//               <Col xs={12} md={8} className="mt-2">
//                 <Dropdown
//                   filter
//                   className="inputcolumn"
//                   value={cityvalue}
//                   options={cityList}
//                   {...register("city", { required: true })}
//                   optionLabel={"cityName"}
//                   placeholder="Select a City"
//                   optionValue={"_id"}
//                   onChange={(e) => setCityValue(e.value)}
//                 />
//                 {/* {console.log("cityList", cityList)} */}
//                 {errors.city && (
//                   <p className="error-text-color-Profile">City is required</p>
//                 )}
//               </Col>
//             </Row>

//             <Row className="mt-3">
//               <Col xs={12} md={4}>
//                 <div className="formrow">
//                   Mobile Number:
//                   <sup className="required_column">*</sup>
//                 </div>
//               </Col>
//               <Col xs={12} md={8}>
//                 {" "}
//                 <div>
//                   <input
//                     className="inputcolumn"
//                     type="text"
//                     name="name"
//                     {...register("phone", { required: true })}
//                     value={phoneNumber}
//                     onChange={(e) => {
//                       setPhoneNumber(e.target.value);
//                     }}
//                     required="required"
//                     placeholder="Name"
//                   />
//                   {errors.phone && !phoneNumber && (
//                     <p className="text-danger">Phone is required</p>
//                   )}{" "}
//                 </div>
//               </Col>
//             </Row>
//             <Row className="mt-3">
//               <Col xs={12} md={4}>
//                 <div className="formrow">
//                   Address:
//                   <sup className="required_column">*</sup>
//                 </div>
//               </Col>
//               <Col xs={12} md={8}>
//                 <div>
//                   <input
//                     className="inputcolumn"
//                     type="text"
//                     name="name"
//                     {...register("address", { required: true })}
//                     value={addresss}
//                     onChange={(e) => {
//                       setAddress(e.target.value);
//                     }}
//                     required="required"
//                     placeholder="Name"
//                   />
//                   {errors.address && !addresss && (
//                     <p className="text-danger">Address is required</p>
//                   )}{" "}
//                 </div>
//               </Col>
//             </Row>
//             <Row className="mt-3">
//               <Col xs={12} md={4}>
//                 <div className="formrow">
//                   Business Name:
//                   <sup className="required_column">*</sup>
//                 </div>
//               </Col>
//               <Col xs={12} md={8}>
//                 {" "}
//                 <div>
//                   <input
//                     className="inputcolumn"
//                     type="text"
//                     name="name"
//                     value="test"
//                     {...register("businessName", { required: true })}
//                     required="required"
//                     placeholder="Name"
//                   />
//                   {errors.businessName && (
//                     <p className="text-danger">Name is required</p>
//                   )}
//                 </div>
//               </Col>
//             </Row>
//             <div className="upgrade_column mb-3">
//               <Button className="button1" type="submit">
//                 Upgrade
//               </Button>
//             </div>
//           </form>
//         </Card>
//       </Col>
//     </div>
//   );
// }

// export default Myprofile;

import React from "react";
import { Tabs } from "antd";
import { Link } from "react-router-dom";
import OurProfile from "../Vendor/TabsVendor";
// import Popup from '../Popup/Popup';
import KycComplaince from "../Vendor/KYCvendor";
// import AdminInfo from './AdminInfo.js';

function TabsProfile() {
  return (
    <div>
      <Tabs defaultActiveKey="1" centered className="p-3">
        <Tabs.TabPane tab="Vendor Information" key="1">
          <OurProfile />
        </Tabs.TabPane>
        <Tabs.TabPane tab="KYC Complaincess" key="2">
          {/* <p style={{ marginLeft:"30px" }}>KYC Complaince</p> */}
          {/* <Popup/> */}
          <KycComplaince />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default TabsProfile;
