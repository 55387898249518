// import React, { useEffect, useState } from 'react';
// import { GoLocation } from 'react-icons/go';
// import { useParams } from 'react-router-dom';
// import Api from '../../../../Api';

// const StudentDetails = () => {

//     const [data, setData] = useState([])
//     const { id } = useParams()

//     const getData = () => {
//         Api.get(`signup/student_getone/${id}`).then((res) => {
//             setData(res.data.data);

//         });
//     };
//     useEffect(() => {
//         getData()
//     }, [])

//     return (
//         <div>
//             <div className="viewseminar_container p-4">
//                 <div className="pages-title mb-3">Student Details</div>
//                 <div className="seminar_card">
//                     <div className="flex padcheck">
//                         <div>
//                             <div className="inner_container_wrap">
//                                 <div className="seminar_card_date">
//                                     <span className="pages-title">{data.firstName} {data.LastName}</span>
//                                 </div>
//                                 {/* <h5 className="seminar_card_title">Gender:</h5> */}
//                                 <label>Gender:</label>      <h6 className="seminar_card_title">{data.gender}</h6>
//                                 <h5 className="seminar_card_title">Marital Status:</h5>
//                                 <h6 className="seminar_card_title">{data.maritalStatus}</h6>

//                                 <div>
//                                     <h4 className="seminar_card_title">Address Details:</h4>
//                                     <h6 className="seminar_card_title mt-2">  <GoLocation /> {data.country},{data.state},{data.district}</h6>
//                                     <h6 className="seminar_card_title">{data.address},{data.pincode}</h6>
//                                 </div>
//                                 {/* <div className="inner_container_wrap_flex">
//               <div className="seminar_card_date_wrapper flex">
//                 <p className="seminar_card_technlogies">
//                   <span>react</span>
//                   <span>node</span>
//                 </p>
//               </div>
//             </div> */}
//                                 {/* <p className="seminar_card_brief_des">
//               Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugiat,
//               incidunt.
//             </p> */}
//                                 <div className="flex">
//                                     <div className="seminar_card_details">
//                                         {/* <p className="seminar_card_access">Access :Open for all</p> */}
//                                         {/* <p className="seminar_card_mode">Mode :Online</p> */}
//                                     </div>
//                                     <div className="seminar_card_contact">
//                                         <p className="seminar_card_name">Contact person :{data.employerName}</p>
//                                         <p className="seminar_card_name">Email Address :{data.email}</p>
//                                         <p className="seminar_card_number">
//                                             Contact number :{data.phone}
//                                         </p>
//                                     </div>
//                                 </div>
//                                 {/* <p className="seminar_card_link">
//                                 Registration Link :https://www.adada.com
//                             </p> */}
//                                 <div className="seminar_card_button_wrapper">

//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                 </div>
//             </div>
//         </div>
//     );
// };

// export default StudentDetails;
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";

import { Card, Row, Col, Container } from "react-bootstrap";
import { Dropdown } from "primereact/dropdown";
import "../../../../Components/Css/Admin/StudentsProfile.scss";
import { ImMobile } from "react-icons/im";
import { TiTick } from "react-icons/ti";
import Avathar from "../../../../assets/BossImg.png";
import { Button } from "react-bootstrap";
import Api from "../../../../Api";

const StudentDetails = () => {
  const [data, setData] = useState([]);
  const [qualificationDetails, SetqualificationDetails] = useState([]);
  const [ProjectList, setProjectList] = useState([]);

  const { id } = useParams();
  console.log("id ddddwddddddddd:>> ", id);

  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  const getData = () => {
    Api.get(`signup/student_getone/${id}`).then((res) => {
      setData(res.data.data);
    });
  };

  const getValues = () => {
    console.log("id@@@@ :>> ", id);
    Api.get(`student/getqualification/${id}`).then((res) => {
      SetqualificationDetails(res.data.data);
    });
  };
  // const getProjectList = () => {
  //   console.log("id!!!!!!! :>> ", id);

  //   Api.get(`/projectpost/getonerequest/${id}`).then((res) => {
  //     setProjectList(res.data.data[0]);
  //     console.log("res.data.data@@@@22", res.data.data);
  //   });
  // };
  useEffect(() => {
    getData();
    getValues();
    // getProjectList();
  }, []);

  const intenshipDetails = [
    {
      id: 1,
      title: "XXXXX",
      dataOfStart: "16/03/2022",
      dataOfEnd: "17/03/2021",
      CompanyName: "Cross Hurdle",
      CompanyLocation: "chennai",
      CompanyWebsite: "www.CrossHurdle.com",
      Description: "yhgujhhjhahxuyauhsxuh",
    },
    {
      id: 2,
      title: "YYYYYY",
      dataOfStart: "16/03/2021",
      dataOfEnd: "17/03/2023",
      CompanyName: "Thinroot",
      CompanyLocation: "Bangalore",
      CompanyWebsite: "www.Thinroot.com",
      Description: "yhgujhhjhahxuyauhsxuh",
    },
    {
      id: 3,
      title: "ZZZZZZZZ",
      dataOfStart: "16/03/2021",
      dataOfEnd: "17/03/2023",
      CompanyName: "Thinroot",
      CompanyLocation: "Bangalore",
      CompanyWebsite: "www.Thinroot.com",
      Description: "yhgujhhjhahxuyauhsxuh",
    },
  ];
  const ProjectDetails = [
    {
      id: 1,
      projectTitle: "careerblitz",
      projectDuration: "5 Months",
      Technology: "React js,Node",
      languagePreference: "Tamil,English,Malayalam",
      AdditionalSkills: "React Native",
      ProjectDescription: "careerblitz is a job search portal like naukri",
    },
    {
      id: 2,
      projectTitle: "Powerefund",
      projectDuration: "2 Months",
      Technology: "React js,Node",
      languagePreference: "Tamil,English,Malayalam",
      AdditionalSkills: "React Native",
      ProjectDescription: "powerefund is a yugyduywdhwuyhuwyduy",
    },
    {
      id: 3,
      projectTitle: "Temple",
      projectDuration: "7 Months",
      Technology: "React js,Node",
      languagePreference: "Tamil,English,Malayalam",
      AdditionalSkills: "React Native",
      ProjectDescription: "Temple is a  search for temples in online ",
    },
  ];
  const jobDetails = [
    {
      id: 1,
      jobTitle: "FrontEnd",
      jobRole: "Full Stack Developer",
      skils: "Reactjs,Node,Jest,react native",
      salaryRange: "50K",
      Experience: "1 year",
      CompanyName: "Cross Hurdle",
      CompanyEmailId: "crossHurdle@17gmail,com",
      CompanyWebsite: "www.crossHurdle.com",
      jobDescription: "yhuwuyuuyduy hwuyudywuyduywghwso",
    },
    {
      id: 2,
      jobTitle: "BackEnd Developer",
      jobRole: "Web Developer",
      skils: "Reactjs,Node,Jest,react native",
      salaryRange: "50K",
      Experience: "2 year",
      CompanyName: "Thinroot",
      CompanyEmailId: "Thinroot@17gmail,com",
      CompanyWebsite: "www.Thinroot.com",
      jobDescription: "yhuwuyuuyduy hwuyudywuyduywghwso",
    },
    {
      id: 3,
      jobTitle: "App developer",
      jobRole: "Mearn Stack",
      skils: "Reactjs,Node,Jest,react native",
      salaryRange: "50K",
      Experience: "3 year",
      CompanyName: "GreenData",
      CompanyEmailId: "crossHurdle@17gmail,com",
      CompanyWebsite: "www.crossHurdle.com",
      jobDescription: "yhuwuyuuyduy hwuyudywuyduywghwso",
    },
    {
      id: 4,
      jobTitle: "frondEnd",
      jobRole: "Web Developer",
      skils: "Reactjs,Node,Jest,react native",
      salaryRange: "50K",
      Experience: "4 year",
      CompanyName: "logic Bee",
      CompanyEmailId: "crossHurdle@17gmail,com",
      CompanyWebsite: "www.crossHurdle.com",
      jobDescription: "yhuwuyuuyduy hwuyudywuyduywghwso",
    },
  ];

  return (
    <div className="View_Profile">
      <div className="Links_cintainer">
        <h5
          style={{
            paddingTop: "30px",
            marginLeft: "20px",
            fontSize: "25px",
            color: "#666666",
          }}
        >
          View Profile
        </h5>
        {/* <Link to="StudentList"  style={{
          paddingTop: "30px",
          marginRight: "20px",
          fontSize: "20px",
        }}>back</Link> */}
        <a
          style={{
            paddingTop: "30px",
            marginRight: "20px",
            fontSize: "20px",
          }}
          onClick={() => navigate("/admin/StudentList")}
        >
          back
        </a>
      </div>
      <div>
        <Card style={{ margin: "0px 20px" }}>
          <Card className="View_Profile_imgDetails">
            <Row>
              <Col sm={12} md={12} lg={4} className="View_Profile_Container">
                <img className="View_Profile_ImageSide" src={Avathar}></img>
              </Col>
              <Col lg={8} sm={12} md={12}>
                <div className="View_Profile_Container_right">
                  <h3 className="Img_Name_Heading">
                    {data.firstName} {data.LastName}'s Profile(
                    {data.RegistrationId})
                  </h3>
                  <div>Profile created 15/03/23 </div>
                  <div style={{ marginTop: "10px", fontWeight: "700" }}>
                    23 yrs,
                  </div>
                  <div style={{ marginTop: "10px", fontWeight: "700" }}>
                    {data.district},{data.state},{data.country}
                  </div>
                  <div style={{ marginTop: "10px", fontWeight: "700" }}>
                    B.E.Mechanical Engineering
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <ImMobile />
                    +91{data.phone}(
                    <span style={{ color: "#ff0000" }}>
                      {" "}
                      <TiTick style={{ fontSize: "20px" }} />
                      Visible{" "}
                    </span>
                    )
                    <input
                      style={{ marginLeft: "10px" }}
                      type="checkbox"
                    ></input>
                  </div>
                  <div
                    style={{
                      color: "#ff0000",
                      fontSize: "15px",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                  >
                    Available Points 2
                  </div>
                  <div
                    style={{
                      color: "#ff0000",
                      fontSize: "15px",
                      fontWeight: "bold",
                      marginTop: "10px",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                  >
                    Package Expires in 140 days
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
          <div>
            <div className="ViewProfile_Contents">
              <div>
                <div
                  style={{
                    fontSize: "25px",
                    marginLeft: "20px",
                    fontWeight: "bolder",
                    color: "#ff0000",
                  }}
                >
                  Personal Information
                </div>
              </div>

              <button
                style={{ marginRight: "20%" }}
                className="ViewProfile_Button"
                onClick={() => setStatus(!status)}
              >
                Notes
              </button>
            </div>
            {status ? (
              <div className="Notes_content_contanier">
                <Card className="ViewProfile_cards">
                  <Row>
                    <Col lg={12}>
                      <div>
                        <div className="notes_heading">Notes</div>
                      </div>

                      <div className="ViewPage_Fields">
                        <label>
                          User Reason : <span style={{ color: "red" }}>*</span>
                        </label>
                        <Dropdown
                          style={{ marginLeft: "50px" }}
                          className="NotesDropdown"
                        ></Dropdown>
                      </div>
                      <div className="ViewPage_Fields mt-4">
                        <label>
                          Staff Comments :{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          style={{ marginLeft: "20px" }}
                          className="NotesDropdown"
                        ></input>
                      </div>
                      <div className="ViewPage_Fields mt-4">
                        <label>
                          Employee Name :{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Dropdown
                          style={{ marginLeft: "20px" }}
                          className="NotesDropdown"
                        ></Dropdown>
                      </div>
                      <div className="Notes_Submit_button mt-3">
                        <Button className="Notes_submitbtn">Submit</Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {" "}
                    <Col className="mx-5 mt-4 mb-5">
                      <div className="View_Profile_notesBottom">
                        <div>Created At</div>
                        <div>Notes</div>
                        <div>Employee</div>

                        <div>Staff Comments</div>
                        <div>Call Back Date</div>
                        <div>Time</div>
                      </div>
                      <hr></hr>
                    </Col>
                  </Row>
                </Card>
              </div>
            ) : null}
          </div>

          <div className="Notes_content_contanier">
            <Card className="ViewProfile_cards  mt-4">
              <h3 className="ViewProfile_PersonalDetails_heading mt-3">
                Personal Details
              </h3>
              {[data].map((i) => (
                <Row className="mb-3">
                  <Col lg={5} md={6} sm={12}>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          First Name :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.firstName ? i.firstName : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Phone Number :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.phone ? i.phone : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Email Id :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.email ? i.email : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Gender :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.gender ? i.gender : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Language :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.Language ? i.Language : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Marital Status :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.maritalStatus ? i.maritalStatus : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Date of Birth :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.dob ? i.dob : "N/A"}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={5} sm={12}>
                    <Row>
                      <Col lg={6} sm={12}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Country :
                        </div>
                      </Col>
                      <Col lg={6} sm={12}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.country ? i.country : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          State :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.state ? i.state : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          District :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.district ? i.district : "N/A"}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          City :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.city ? i.city : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Area :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.Area ? i.Area : "N/A"}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          PinCode :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.pincode ? i.pincode : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Address :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.address ? i.address : "N/A"}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}
            </Card>
          </div>
          {/* Education Details */}

          <div className="Notes_content_contanier">
            <Card className="ViewProfile_cards  mt-4 mb-5">
              <h3 className="ViewProfile_PersonalDetails_heading mt-3">
                Education Details
              </h3>
              {qualificationDetails.map((i) => (
                <Row className="mb-3">
                  <Col lg={5} md={6} sm={12}>
                    <Row>
                      <div
                        style={{
                          marginLeft: "30px",
                          fontWeight: "bolder",
                          color: "grey",
                        }}
                      >
                        10th Grade
                      </div>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          10th Percentage :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.xper ? i.xper : "N/A"}
                          {/* {value.xper ? value.xper : "N/A"} */}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          university/Institude :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.xboard ? i.xboard : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Year of passing :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.xyop ? i.xyop : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <div
                        style={{
                          marginLeft: "30px",
                          fontWeight: "bolder",
                          color: "grey",
                        }}
                      >
                        12th Grade/Equivalent diploma{" "}
                      </div>

                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          12th Percentage:
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.xiiper ? i.xiiper : "N/A"}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          university/Institude :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.xiiboard ? i.xiiboard : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          specialization :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.xiimajor ? i.xiimajor : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Year of Passing
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.xiiyop ? i.xiiyop : "N/A"}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={5} sm={12}>
                    <Row>
                      <div
                        style={{
                          marginLeft: "30px",
                          fontWeight: "bolder",
                          color: "grey",
                        }}
                      >
                        Under graduate{" "}
                      </div>
                      <Col lg={6} sm={12}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          CGPA :
                        </div>
                      </Col>
                      <Col lg={6} sm={12}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.gper ? i.gper : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Branch :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.gmajor ? i.gmajor : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          university/Institude :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.gboard ? i.gboard : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          {" "}
                          Year of Passing :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.gyop ? i.gyop : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <div
                        style={{
                          marginLeft: "30px",
                          fontWeight: "bolder",
                          color: "grey",
                        }}
                      >
                        Post graduate{" "}
                      </div>
                      <Col lg={6} sm={12}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          CGPA :
                        </div>
                      </Col>
                      <Col lg={6} sm={12}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.pgper ? i.pgper : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Branch :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.pgmajor ? i.pgmajor : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          university/Institude :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.pgboard ? i.pgboard : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Year of passing :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.pgyop ? i.pgyop : "N/A"}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}
            </Card>
          </div>
          {/*Intenship Details  */}
          <div className="Notes_content_contanier">
            <Card className="ViewProfile_cards  mt-4 mb-5">
              <h3 className="ViewProfile_PersonalDetails_heading mt-3">
                Intenship Details
              </h3>
              {intenshipDetails.map((i) => (
                <Row>
                  <Col lg={5} md={6} sm={12}>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Title :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.title ? i.title : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Date of Start :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.dataOfStart ? i.dataOfStart : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Date of End :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.dataOfEnd ? i.dataOfEnd : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Company Name :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.CompanyName ? i.CompanyName : "N/A"}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={5} sm={12}>
                    <Row>
                      {/*  */}
                      <Col lg={6} sm={12}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Company Location :
                        </div>
                      </Col>
                      <Col lg={6} sm={12}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.CompanyLocation ? i.CompanyLocation : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Company Website :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.CompanyWebsite ? i.CompanyWebsite : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Description :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.Description ? i.Description : "N/A"}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <div className="DividerLine_viewProfile">
                    <hr style={{ width: "80%" }}></hr>
                  </div>
                </Row>
              ))}

              {/*Project Details  */}
            </Card>
          </div>
          {/* <div className="Notes_content_contanier">
            <Card className="ViewProfile_cards  mt-4 mb-5">
              <h3 className="ViewProfile_PersonalDetails_heading mt-3">
                Project Details
              </h3>
              {[ProjectList].map((i) => (
                <Row className="mb-2">
                  <Col lg={5} md={6} sm={12}>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Project Title :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.projectTitle ? i.projectTitle : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Project Duration :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.duration ? i.duration : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Technology :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.qualification ? i.qualification : "N/A"}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={5} sm={12}>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Language Preference :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents  ms-3">
                          {i.languagePreference ? i.languagePreference : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Additional Skills :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.skill ? i.skill : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Project Description :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.duration ? i.duration : "N/A"}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <div className="DividerLine_viewProfile">
                    <hr style={{ width: "80%" }}></hr>
                  </div>
                </Row>
              ))}
            </Card>
          </div> */}

          {/* <div className="Notes_content_contanier">
             <Card className="ViewProfile_cards  mt-4 mb-5">
              <h3 className="ViewProfile_PersonalDetails_heading mt-3">
                Job Details
              </h3>
              {jobDetails.map((i) => (
                <Row className="mb-2">
                  <Col lg={5} md={6} sm={12}>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Job Title :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.jobTitle ? i.jobTitle : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Job Role :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.jobRole ? i.jobRole : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Skills :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.skils ? i.skils : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Salary Range :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.salaryRange ? i.salaryRange : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Experience :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ps-3">
                          {i.Experience ? i.Experience : "N/A"}
                        </div>
                      </Col>
                    </Row>
                  </Col>
               
                  <Col lg={5} sm={12}>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Company Name :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.CompanyName ? i.CompanyName : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Company Email Id :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.CompanyEmailId ? i.CompanyEmailId : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Company Website :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.CompanyWebsite ? i.CompanyWebsite : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <div
                          className="personalDetails_contents"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          Job Description :
                        </div>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        {" "}
                        <div className="personalDetails_contents ms-3">
                          {i.jobDescription ? i.jobDescription : "N/A"}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <div className="DividerLine_viewProfile">
                    <hr style={{ width: "80%" }}></hr>
                  </div>
                </Row>
              ))}
            </Card> 
          </div> */}
        </Card>
      </div>
    </div>
  );
};

export default StudentDetails;
