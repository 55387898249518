import { React, useState, useEffect } from "react";
import { Space, Table, Tag } from "antd";
import "../../../Components/Css/Employer/EmployerResume.scss";
import { Button, Modal } from "antd";
import Api from "../../../Api";
import { NavLink } from "react-router-dom";

function HoldList() {
  const [holdList, setHoldList] = useState([]);
  const id = localStorage.getItem("id");
  useEffect(() => {
    getHold();
  }, []);

  const getHold = async () => {
    await Api.get(`hold/getHold/${id}`).then((res) => {
      setHoldList(res.data.data);
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "name",
      render: (text) => <h6>{text}</h6>,
    },
    {
      title: "Role",
      dataIndex: "Role",
      key: "role",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Remarks",
      dataIndex: "Remark",
      key: "Remarks",
    },
  ];
  const data = [
    {
      key: "1",
      name: "aas",
      role: "Front End",
      Remarks: "62%",
    },
    {
      key: "2",
      name: "xxx",
      role: "Web Developer",
      Remarks: "58%",
    },
    {
      key: "3",
      name: "hello",
      role: "Designer",
      Remarks: "65%",
    },
  ];
  return (
    <div>
      <div className="pages-title mt-5 ms-5">Hold List:</div>
      <Table
        columns={columns}
        dataSource={holdList}
        className="mx-5 mt-5"
        style={{ width: "50%" }}
      />
      ;
    </div>
  );
}

export default HoldList;
