import React from "react";
import "./Project1.css";
import { Col, Row, Button, Card } from "react-bootstrap";
import { BsFilter } from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

function Project1() {
  const sk = [  
    {
      Image:
        "https://img14.360buyimg.com/n1/s300x300_jfs/t1/190842/5/2713/540402/609b523dE8d105522/2edf0076507ae396.png",
      Title: "Electronics",
      price: "Rs:50,899",
    },
    {
      Image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSthRLCwIDHQBt59lbh3c6KW_wVWoXljWUn8JbpGNrvuRJgVtGzJmcwtaUMtX2Djhx9nKk&usqp=CAU",
      Title: "Electronics",
      price: "Rs:50,999",
    },
    {
      Image:
        "https://5.imimg.com/data5/ECOM/Default/2022/3/EN/HW/BP/147282547/250-500x500.jpg",
      Title: "Electronics",
      price: "Rs:50,799",
    }
  ]
  const sk1=[
    {
      Image:
        "https://www.bennetts.co.uk/-/media/bikesocial/2021-january-images/top-10-ten-sportsbikes-2021/top-10-ten-sportsbikes-price-spec_thumb.ashx?h=444&w=740&la=en&hash=F9371F7E97540C842733781A1C8856F858B333B9",
      Title: "Bikes",
      price: "Rs:50,009",
    },
    {
      Image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXDkQcxaoOBq7qvHwTzxp1wLRp6Jh2ogJaSg&usqp=CAU",
      Title: "Bikes",
      price: "1,00,980",
    },
    {
      Image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxwPbkR1refv1kBNtQ53B2zxopN9tjArs6R8Cso55KzsAC1itYLjYNjiSv_3z0DRqGaXk&usqp=CAU",
      Title: "Bikes",
      price: "60,000",
    }
    
  ]
const sk2=[
   
    

      {Image:
         "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZAtvEUCOiaZ7cyM-5YCm9VB-_BdUxG6uUyw&usqp=CAU",
      Title: "Furnitures",
      price: "Rs:59,990",
    },

    {
      Image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAzsnAvGFDYjP3Onh3GXCOfjgapNZjok1MWA&usqp=CAU",
      Title: "Furnitures",
      price: "Rs:70,000",
    },
    {
      Image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKdPDYPVnLXQ_EJbgUYg4lP_3JLY9JjxwAUQ&usqp=CAU",
      Title: "Furnitures",
      price: "69,990",
    }
  ]
  const sk3=[
    {
      Image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJ5b7VLUI6X0EyC7Ta0Fa9TGGLeAQ3NM4O5cQwEjovWlU8ZRnuYUX7x5maklgiF7kKHFk&usqp=CAU",
      Title: "Home Appliances",
      price: "Rs:55,099",
    },
    {
      Image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4wZvZmFPJgGTXe5D4flKZgZg84TUoGfGryw&usqp=CAU",
      Title: "Home Appliances",
      price: "Rs:59,899",
    },
    {
      Image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQz6KJfYce6NmregO4lcxb4VKtqzJU4daYWYoLlNVurbWqmhn6x1RQ2yO53jHk7OYP-Bj4&usqp=CAU",
      Title: "Home Appliances",
      price: "Rs:49,000",
    }
  ];
const navigate= useNavigate();
  return (
    <div>
      <div>
        <Row>
       
          <Col
            md={3}
            style={{ backgroundColor: "#746262", color:"white", textAlign:"center"}}
            className="Filters" >
                <div className="Filter1">
            <h4>
              Filters <BsFilter size={30} />
            </h4>          
              <form className="Filter">               
                <select style={{ width: "50%" }}>
                  <option value="1"><b>Product Name</b></option>
                  <option value="2">Electronics</option>
                  <option value="3">Bikes</option>
                  <option value="4">Furniture</option>
                  <option value="5">Home Appliances</option>
                </select>
              </form>           
            <Row>
              <form className="Filter">                
                <select style={{ width: "50%" }}>
                  <option value="1"><b> Price</b></option>
                  <option value="2"> 3400 </option>
                  <option value="3"> 3200 </option>
                  <option value="4"> 2800 </option>
                  <option value="5"> 2400 </option>
                  <option value="6"> 2000 </option>
                </select>
              </form>
            </Row>
            <Row>
              <form  className="Filter">
                <select style={{ width: "50%" }}>
                  <option value="0"><b>Month</b></option>
                  <option value="1">1 Month</option>
                  <option value="2">2 Month</option>
                  <option value="3">3 Month</option>
                  <option value="4">4 Month</option>
                  <option value="5">5 Month</option>
                  <option value="6">6 Month</option>
                  <option value="7">7 Month</option>
                  <option value="8">8 Month</option>
                  <option value="9">9 Month</option>
                  <option value="10">10 Month</option>
                  <option value="11">11 Month</option>
                  <option value="12">12 Month</option>
                </select>
              </form>
            </Row>
            <Row>
              <form className="Filter">
                <select style={{ width: "50%" }}>
                  <option value="1"><b>Number of Item</b></option>
                  <option value="2">1</option>
                  <option value="3">2</option>
                  <option value="4">3</option>
                  <option value="5">4</option>
                  <option value="6">5</option>
                  <option value="7">6</option>
                </select>               
              </form>
            </Row>

            <Row className="Filter">
              <Button variant="danger" className="button1" style={{width:"50%"}}>
                Apply
              </Button>
            </Row>
            </div>
          </Col>
          

          <Col>
            <div className="Add-List1">
              <Row>
                {sk.map((sk, i) => (
                  <Col className="Add-List2" sm={12} md={6} lg={4}>
                    <Card style={{ width: "18rem" }} onClick={()=>navigate("/electronics")}>
                      <Card.Img variant="top" src={sk.Image}/> 
                      <Card.Body>
                        <Card.Title>{sk.Title}</Card.Title>
                        <Card.Text>{sk.price}</Card.Text>
                        
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
                {sk1.map((sk1, i) => (
                  <Col className="Add-List2" sm={12} md={6} lg={4}>
                    <Card style={{ width: "18rem" }} onClick={()=>navigate("/bikes")}>
                      <Card.Img variant="top" src={sk1.Image}/> 
                      <Card.Body>
                        <Card.Title>{sk1.Title}</Card.Title>
                        <Card.Text>{sk1.text}</Card.Text>
                        
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
                {sk2.map((sk2, i) => (
                  <Col className="Add-List2" sm={12} md={6} lg={4}>
                    <Card style={{ width: "18rem" }} onClick={()=>navigate("/furnitures")}>
                      <Card.Img variant="top" src={sk2.Image}/> 
                      <Card.Body>
                        <Card.Title>{sk2.Title}</Card.Title>
                        <Card.Text>{sk2.text}</Card.Text>
                        
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
                {sk3.map((sk3, i) => (
                  <Col className="Add-List2" sm={12} md={6} lg={4}>
                    <Card style={{ width: "18rem" }} onClick={()=>navigate("/home-appliances")}>
                      <Card.Img variant="top" src={sk3.Image}/> 
                      <Card.Body>
                        <Card.Title>{sk3.Title}</Card.Title>
                        <Card.Text>{sk3.text}</Card.Text>
                        
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>               
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default Project1;
