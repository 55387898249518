import { combineReducers } from "redux";
//**all components reducers imports */
import { emailVerificationReducer } from "./emailVerificationReducer";
import { filterbySearchReducer } from "./filterbySearchReducer";

const appReducer = combineReducers({
  email: emailVerificationReducer,
  filter: filterbySearchReducer,
});

export default appReducer;
