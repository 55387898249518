import React, { useEffect, useState } from "react";
import { Table, Input, Button, Popconfirm } from "antd";
import Api from "../../../Api";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const AdminCompanyApprovedList = () => {
  const [blogById, setBlogById] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [blogList, setBlogList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState([]);
  // const data = companyList.map((item) => item.status)
  const [companyList2, setCompanyList2] = useState([]);
  const data = companyList2.map((item) => item);
  // const navigate = useNavigate();
  const { id } = useParams();
  const navigate = useNavigate();

  //   const getBlogById = async () => {
  //     await Api.get("blog/getBloglist").then((res) => {
  //       console.log("blogsss", res.data.data);
  //       setCompanyList2(res.data.data);
  //     });
  //   };

  const columns = [
    {
      title: "Student Name",
      dataIndex: "studentName",
      // sorter: (a, b) =>  a.companyName.localeCompare(b.companyName),
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    // {
    //   title: "Established",
    //   dataIndex: "establishedYear",
    // },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      // sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (_, record) => (
        <div>
          {console.log("cre", record.createdAt)}
          {moment(record.createdAt).format("MM/dd/yyyy")}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div>
          {record.status === true ? (
            <Button
              type=""
              className="data-list-button"
              onClick={() =>
                navigate(`/admin/student/studentblog/${record?._id}`)
              }
            >
              {" "}
              Details{" "}
            </Button>
          ) : null}
        </div>
      ),
    },
  ];

  const getBlogById = async () => {
    await Api.get("blog/getBloglist/status").then((res) => {
      setBlogById(res.data);

      // console.log("Companyshow2", search);
    });
  };

  useEffect(() => {
    getBlogById();
    // getCompany();
    const results = blogById.filter((item) =>
      item?.companyName?.toLowerCase().includes(searchText.trim())
    );
    setSearch(results);
  }, [searchText]);

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <div>
      {console.log("data", blogById)}
      <div className="data-list-main">
        <h4 className="data-list-header">Approved Blogs </h4>
        <div
          className="data-list-top mt-4 py-4 px-3"
          style={{ backgroundColor: "#c5c5c5" }}
        >
          <Input
            className="data-list-search ps-2"
            placeholder="search by companny name"
            onChange={handleChange}
            enterButton
          />
        </div>
        {searchText ? (
          <div className=".Datalist-Table">
            <Table
              columns={columns}
              dataSource={search}
              pagination={false}
              className="mt-2"
            />
          </div>
        ) : (
          <div className=".Datalist-Table">
            <Table
              columns={columns}
              dataSource={blogById}
              pagination={false}
              className="mt-2"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminCompanyApprovedList;
