import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./RoleForm.css";
import AOS from "aos";
import "aos/dist/aos.css";
import StudentSignupForm from "./StudentSignupForm";
import CollegeSignupForm from "./CollegeSignupForm";
import VendorSignupForm from "./VendorSignupForm";
import TrainerSignupForm from "./TrainerSignupForm";
import EmployerSignupForm from "./EmployerSignupForm";
import ProfessionalSignupForm from "./ProfessionalSignupForm";
import Layout from "../Components/Layout";

const RoleForm = () => {
  const roles = [
    "Student",
    "Professional",
    "Trainers",
    "Vendor",
    "College",
    "Company",
  ];
  const [selectedRole, setSelectedRole] = useState(roles[0]);
  const [showForm, setShowForm] = useState(false);

  const handleRoleChange = (role) => {
    setSelectedRole(role);
    setShowForm(false);
  };

  const handleNext = () => {
    setShowForm(true);
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);

  return (
    <Layout>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-6 right-role-cont">
            <h4 style={{ textAlign: "center" }} className="im-text">
              IM
            </h4>
            <div className="row">
              {roles.map((role, index) => (
                <div className="col-md-6 role-content" key={index}>
                  <div
                    className={`d-flex align-items-center border rounded p-2 mb-3 
                  ${selectedRole === role ? "bg-primary text-white" : ""}`}
                    style={{
                      height: "60px",
                      position: "relative",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRoleChange(role)}
                  >
                    <input
                      type="radio"
                      className="form-check-input position-absolute"
                      name="role"
                      value={role}
                      checked={selectedRole === role}
                      onChange={() => handleRoleChange(role)}
                      style={{
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                      }}
                    />
                    <span className="ms-4 roles">
                      {role.charAt(0).toUpperCase() + role.slice(1)}
                    </span>
                  </div>
                </div>
              ))}
              <span className="border-signup"></span>
            </div>
          </div>

          <div className="col-md-6 role-content left">
            {showForm ? (
              <>
                {selectedRole === "Student" && <StudentSignupForm />}
                {selectedRole === "Professional" && <ProfessionalSignupForm />}
                {selectedRole === "Trainers" && <TrainerSignupForm />}
                {selectedRole === "Vendor" && <VendorSignupForm />}
                {selectedRole === "College" && <CollegeSignupForm />}
                {selectedRole === "Company" && <EmployerSignupForm />}
              </>
            ) : (
              <div className="role-cont">
                {selectedRole === "Student" && (
                  <div className="role-cont">
                    <ul
                      className="list-unstyled"
                      style={{ width: "90%", padding: 0, textAlign: "justify" }}
                    >
                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <span
                          className="number-circle"
                          data-aos="fade-up"
                          data-aos-delay="100"
                        >
                          1
                        </span>
                        <span className="line-connector line-connector-long-1"></span>
                        <div className="feature-content">
                          <h4 className="mb-3 feature-title">
                            Explore a Wide Range of Courses
                          </h4>
                          <p className="feature-sub-content">
                            Dive into our extensive catalog, which includes
                            courses that span from foundational knowledge to
                            advanced skills across various fields. Whether
                            you're a beginner or looking to upskill, there's
                            something for everyone.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="600"
                      >
                        <span className="number-circle">2</span>
                        <span className="line-connector line-connector-long-2"></span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Earn Industry-Recognized Certifications
                          </h4>
                          <p className="feature-sub-content">
                            Complete courses to earn certifications that are
                            acknowledged by leading companies and institutions.
                            These certifications can significantly enhance your
                            resume and improve your job prospects in a
                            competitive market.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="900"
                      >
                        <span className="number-circle">3</span>
                        <span className="line-connector line-connector-long-3"></span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Learn from Industry Experts
                          </h4>
                          <p className="feature-sub-content">
                            Benefit from courses taught by seasoned
                            professionals with real-world experience. Gain
                            practical insights and mentorship that can help you
                            apply what you've learned effectively in the
                            workplace.
                          </p>
                        </div>
                      </li>
                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="1200"
                      >
                        <span className="number-circle">4</span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Engage with a Career-Focused Curriculum
                          </h4>
                          <p className="feature-sub-content">
                            Our courses are designed with a focus on equipping
                            you with the skills that employers are actively
                            seeking. Additionally, we offer job placement
                            assistance upon completion to help you kickstart
                            your career.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}

                {selectedRole === "Professional" && (
                  <div className="role-cont">
                    <ul
                      className="list-unstyled"
                      style={{ width: "90%", padding: 0 }}
                    >
                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="300"
                      >
                        <span className="number-circle">1</span>
                        <span className="line-connector line-connector-long-1"></span>
                        <div className="feature-content">
                          <h4 className="mb-3 feature-title">
                            Diverse Job Opportunities
                          </h4>
                          <p className="feature-sub-content">
                            Explore a wide range of job listings, from
                            internships to advanced roles across multiple
                            industries. Our platform connects you with potential
                            candidates looking for exciting opportunities,
                            ensuring you find the right fit for your
                            organization's needs.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="600"
                      >
                        <span className="number-circle">2</span>
                        <span className="line-connector line-connector-long-2"></span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Personalized Job Recommendations
                          </h4>
                          <p className="feature-sub-content">
                            Receive tailored job suggestions based on
                            candidates' skills and career goals. This targeted
                            approach helps you match the right talent to your
                            job openings, making the hiring process more
                            efficient and effective.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="900"
                      >
                        <span className="number-circle">3</span>
                        <span className="line-connector line-connector-long-3"></span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Comprehensive Application Support
                          </h4>
                          <p className="feature-sub-content">
                            Provide candidates with the necessary tools and
                            expert advice to create standout resumes and cover
                            letters. By enhancing their application materials,
                            you increase the quality of applicants, making it
                            easier to identify top talent.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="1200"
                      >
                        <span className="number-circle">4</span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Skill Development and Career Guidance
                          </h4>
                          <p className="feature-sub-content">
                            Offer skill development courses and personalized
                            career counseling to help candidates enhance their
                            employability. By supporting candidates' growth, you
                            not only improve their skills but also foster a pool
                            of qualified applicants who align with your
                            company's needs.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
                {selectedRole === "College" && (
                  <div className="role-cont">
                    <ul
                      className="list-unstyled"
                      style={{ width: "90%", padding: 0 }}
                    >
                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <span className="number-circle">1</span>
                        <span className="line-connector line-connector-long-1"></span>
                        <div className="feature-content">
                          <h4 className="mb-3 feature-title">
                            Streamline Application Management
                          </h4>
                          <p className="feature-sub-content">
                            Receive and process student applications seamlessly
                            through our platform. Manage the entire admissions
                            process from one dashboard, making it easier to
                            track, review, and respond to applications.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="600"
                      >
                        <span className="number-circle">2</span>
                        <span className="line-connector line-connector-long-2"></span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Engage Students with Blogs
                          </h4>
                          <p className="feature-sub-content">
                            Keep your student body informed and engaged by
                            posting blogs about campus events, educational
                            content, and institutional news. This builds a
                            stronger connection with your students and attracts
                            new applicants.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="900"
                      >
                        <span className="number-circle">3</span>
                        <span className="line-connector line-connector-long-3"></span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Advertise Jobs and Internships
                          </h4>
                          <p className="feature-sub-content">
                            Easily post job openings for faculty, staff, and
                            internships. Reach potential candidates directly and
                            streamline your recruitment process by managing
                            applications through your portal.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="1200"
                      >
                        <span className="number-circle">4</span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Organize Seminars and Webinars
                          </h4>
                          <p className="feature-sub-content">
                            Host and promote educational seminars, webinars, and
                            workshops. Our platform allows easy registration for
                            students and visitors, helping you build a thriving
                            academic community.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
                {selectedRole === "Company" && (
                  <div className="role-cont">
                    <ul
                      className="list-unstyled"
                      style={{ width: "90%", padding: 0 }}
                    >
                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="300"
                      >
                        <span className="number-circle">1</span>
                        <span className="line-connector line-connector-long-1"></span>
                        <div className="feature-content">
                          <h4 className="mb-3 feature-title">
                            Attract Top Talent
                          </h4>
                          <p className="feature-sub-content">
                            Utilize our platform to post job openings and
                            attract qualified candidates. Reach a diverse pool
                            of talent and fill your positions with the best
                            applicants in various domains, ensuring your company
                            stays competitive.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="600"
                      >
                        <span className="number-circle">2</span>
                        <span className="line-connector line-connector-long-2"></span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Collaborate on Projects
                          </h4>
                          <p className="feature-sub-content">
                            Post projects to engage freelancers or external
                            teams for collaborative work. This flexibility
                            allows your company to tap into specialized skills
                            for project-based tasks, enhancing productivity and
                            innovation.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="900"
                      >
                        <span className="number-circle">3</span>
                        <span className="line-connector line-connector-long-3"></span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Simplify Hiring and Onboarding
                          </h4>
                          <p className="feature-sub-content">
                            Streamline your hiring process by reviewing
                            applications, conducting interviews, and managing
                            the onboarding of new employees directly through our
                            platform. This integrated approach saves time and
                            improves the overall candidate experience.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="1200"
                      >
                        <span className="number-circle">4</span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Enhance Workforce Management
                          </h4>
                          <p className="feature-sub-content">
                            Manage employee schedules, leaves, and background
                            verifications effortlessly. With integrated tools
                            for conducting thorough checks and maintaining leave
                            calendars, ensure your workforce meets the highest
                            standards and operates smoothly.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
                {selectedRole === "Trainers" && (
                  <div className="role-cont">
                    <ul
                      className="list-unstyled"
                      style={{ width: "90%", padding: 0 }}
                    >
                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <span className="number-circle">1</span>
                        <span className="line-connector line-connector-long-1"></span>
                        <div className="feature-content">
                          <h4 className="mb-3 feature-title">
                            Expand Your Reach
                          </h4>
                          <p className="feature-sub-content">
                            Leverage our platform to reach a wider audience of
                            learners. By offering courses online, you can
                            connect with students globally, increasing your
                            visibility and potential for higher enrollments.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="600"
                      >
                        <span className="number-circle">2</span>
                        <span className="line-connector line-connector-long-2"></span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Enhance Your Teaching Portfolio
                          </h4>
                          <p className="feature-sub-content">
                            Create and showcase a diverse range of courses that
                            demonstrate your expertise. Having a robust
                            portfolio not only attracts more students but also
                            establishes your credibility in your field.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="900"
                      >
                        <span className="number-circle">3</span>
                        <span className="line-connector line-connector-long-3"></span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Access to Tools and Resources
                          </h4>
                          <p className="feature-sub-content">
                            Utilize our platform’s tools for course creation,
                            management, and analytics. Access valuable resources
                            that help you design engaging content, track student
                            progress, and enhance the learning experience.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="1200"
                      >
                        <span className="number-circle">4</span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Networking Opportunities
                          </h4>
                          <p className="feature-sub-content">
                            Connect with other industry professionals and
                            trainers through our platform. Collaborate on
                            projects, share insights, and build a network that
                            can lead to new opportunities and partnerships.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
                {selectedRole === "Vendor" && (
                  <div className="role-cont">
                    <ul
                      className="list-unstyled"
                      style={{ width: "90%", padding: 0 }}
                    >
                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="300"
                      >
                        <span className="number-circle">1</span>
                        <span className="line-connector line-connector-long-1"></span>
                        <div className="feature-content">
                          <h4 className="mb-3 feature-title">
                            Showcase Your Products and Services
                          </h4>
                          <p className="feature-sub-content">
                            Utilize our platform to display your products and
                            services to a broader audience. With enhanced
                            visibility, you can attract potential clients and
                            customers effectively, boosting your sales and brand
                            recognition.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="600"
                      >
                        <span className="number-circle">2</span>
                        <span className="line-connector line-connector-long-2"></span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Access to Targeted Marketing Tools
                          </h4>
                          <p className="feature-sub-content">
                            Leverage our marketing tools designed specifically
                            for vendors. Promote your offerings through targeted
                            campaigns, reach your ideal audience, and track the
                            performance of your marketing efforts to optimize
                            results.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="900"
                      >
                        <span className="number-circle">3</span>
                        <span className="line-connector line-connector-long-3"></span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Gain Insights Through Analytics
                          </h4>
                          <p className="feature-sub-content">
                            Access detailed analytics to understand customer
                            behavior and preferences. Use these insights to
                            refine your offerings, enhance customer engagement,
                            and make informed business decisions to drive
                            growth.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="1200"
                      >
                        <span className="number-circle">4</span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Build Strong Partnerships
                          </h4>
                          <p className="feature-sub-content">
                            Connect with other vendors and businesses within our
                            ecosystem. Collaborate on projects, share resources,
                            and build partnerships that can lead to mutual
                            growth and expanded market reach.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}

                <button
                  className="btn btn-primary  role-section"
                  onClick={handleNext}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RoleForm;
