import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { MailOutlined, PhoneOutlined, LockOutlined, EyeOutlined, EyeInvisibleOutlined, GlobalOutlined, HomeOutlined } from "@ant-design/icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Api from "../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VendorSignupForm = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [resRes, setResRes] = useState(null);
  const [companyType, setCompanyType] = useState("Company");
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = async (data) => {
    const companyDetails = {
      type: companyType,
      name: companyType === "Company" ? data.companyName : data.username,
      email: data.email,
      password: data.password,
      website: data.website,
      phone: data.phone,
    };

    try {
      const res = await Api.post(`signup/createCompany`, companyDetails);
      setResRes({
        status: res.data?.status,
        message: res.data?.message,
      });
      toast.success(res.data?.message);
      setTimeout(() => {
        navigate("/");
      }, 10000);
    } catch (err) {
      setResRes({
        status: err?.response.data?.status,
        message: err?.response.data?.message,
      });
      toast.error(err?.response.data?.message);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleTypeChange = (event) => {
    setCompanyType(event.target.value);
  };

  return (
    <div className="signup-form-container">
      <h2 className="form-heading" style={{ color: "blue" }}>
        Company/Individual Signup
      </h2>

      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Form.Group className="custom-form-group">
          <Form.Label className="form-label">Type</Form.Label>
          <Form.Control
            as="select"
            value={companyType}
            onChange={handleTypeChange}
            className="custom-input"
          >
            <option value="Company">Company</option>
            <option value="Individual">Individual</option>
          </Form.Control>
        </Form.Group>

        {companyType === "Company" && (
          <Row>
            <Col md={6}>
              <Form.Group className="custom-form-group">
                <Form.Label className="form-label">Company Name</Form.Label>
                <Form.Control
                  {...register("companyName", { required: true })}
                  className="custom-input"
                  placeholder="Enter Company Name"
                  type="text"
                />
                {errors.companyName?.type === "required" && (
                  <p className="error-text-color">Company Name is required</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Email ID</Form.Label>
              <div className="input-icon-container">
                <MailOutlined className="input-icon" />
                <Form.Control
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  className="custom-input"
                  placeholder="Enter Email"
                  type="email"
                />
              </div>
              {errors.email?.type === "required" && (
                <p className="error-text-color">Email is required</p>
              )}
            </Form.Group>
          </Col>
          </Row>
        )}

        {companyType === "Individual" && (
          <Row>
            <Col md={6}>
              <Form.Group className="custom-form-group">
                <Form.Label className="form-label">Username</Form.Label>
                <div className="input-icon-container">
                <HomeOutlined className="input-icon"/>
                <Form.Control
                  {...register("username", { required: true })}
                  className="custom-input"
                  placeholder="Enter Username"
                  type="text"
                />
                </div>
                {errors.username?.type === "required" && (
                  <p className="error-text-color">Username is required</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Email ID</Form.Label>
              <div className="input-icon-container">
                <MailOutlined className="input-icon" />
                <Form.Control
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  className="custom-input"
                  placeholder="Enter Email"
                  type="email"
                />
              </div>
              {errors.email?.type === "required" && (
                <p className="error-text-color">Email is required</p>
              )}
            </Form.Group>
          </Col>
          </Row>
        )}

        <Row>
          
        <Col md={12}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Website</Form.Label>
              <div className="input-icon-container">
              <GlobalOutlined  className="input-icon"/>
              <Form.Control
                {...register("website", { required: true })}
                className="custom-input"
                placeholder="Enter Website URL"
                type="url"
              />
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Phone Number</Form.Label>
              <div className="input-icon-container">
                <PhoneOutlined className="input-icon" />
                <Form.Control
                  {...register("phone", {
                    minLength: 10,
                    maxLength: 10,
                    required: true,
                  })}
                  className="custom-input"
                  placeholder="Enter Phone Number"
                  type="text"
                />
              </div>
              {errors.phone?.type === "required" && (
                <p className="error-text-color">Phone Number is required</p>
              )}
              {errors.phone?.type === "minLength" && (
                <p className="error-text-color">
                  Minimum 10 digits are required
                </p>
              )}
              {errors.phone?.type === "maxLength" && (
                <p className="error-text-color">
                  Maximum 10 digits are required
                </p>
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Password</Form.Label>
              <div className="input-icon-container">
                <LockOutlined className="input-icon" />
                <Form.Control
                  {...register("password", { required: true, minLength: 6 })}
                  className="custom-input"
                  placeholder="Enter Password"
                  type={passwordVisible ? "text" : "password"}
                />
                <div
                  className="eye-icon-container"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>
              </div>
              {errors.password?.type === "required" && (
                <p className="error-text-color">Password is required</p>
              )}
              {errors.password?.type === "minLength" && (
                <p className="error-text-color">
                  Password must be at least 6 characters
                </p>
              )}
            </Form.Group>
          </Col>
         
        </Row>

        <Row>
      
        </Row>

        <Button className="next-btn" type="submit">
          Submit
        </Button>
        {resRes && (
          <div className={`response-message ${resRes.status}`}>
            <p>{resRes.message}</p>
          </div>
        )}

        <ToastContainer />
      </Form>
    </div>
  );
};

export default VendorSignupForm;
