import React from "react";
import HeaderNavbar from "../../Components/HeaderNavbar";
import Carousel from "react-bootstrap/Carousel";
import caro from "../../assets/library/Carousel-images/clem-onojeghuo-zlABb6Gke24-unsplash.jpg";
import img2 from "../../assets/library/Carousel-images/korie-cull-IzIME1jwjCY-unsplash.jpg";
import img3 from "../../assets/library/Carousel-images/patrick-tomasso-GXXYkSwndP4-unsplash.jpg";
import Dropdown from "react-bootstrap/Dropdown";
import { Col } from "react-bootstrap";
import thumb from "../../assets/library/Thumb-image/pexels-simon-berger-10609446.jpg";
import { useNavigate } from "react-router-dom";

function VideoSection() {
  const navigate = useNavigate();
  return (
    <div>
      <HeaderNavbar className="" />
      <div style={{ paddingTop: "75px" }}></div>
      <Carousel className="d-flex .carousel">
        <Carousel.Item className="height-helper item">
          <img className=" height-helpers " src={caro} alt="First slide" />
          <Carousel.Caption>
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="height-helper item">
          <img className=" height-helpers " src={img2} alt="Second slide" />
          <Carousel.Caption>
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="height-helper item">
          <img className=" height-helpers" src={img3} alt="Third slide" />
          <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <div className="bg-color">
        {/* <h3
          style={{
            marginTop: "20px",
          }}
        >
          Filter Searches
        </h3> */}

        <Col className="d-flex filter_search_container mt-5">
          <div
            style={{
              borderRadius: "5px",
            }}
          >
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Language
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">English</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Hindi</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Tamil</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="">
            <input
              className="prompt"
              type="text"
              placeholder="Search Videos..."
            />
          </div>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Technology
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">React</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Node</Dropdown.Item>
              <Dropdown.Item href="#/action-3">PHP</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <button className="upload-video-btn">Upload Videos</button> */}
        </Col>
        {/* <div class="featured-video">
          <div class="video-thumbnail">
            <img
              src="https://images.unsplash.com/photo-1506960002266-9cebbf4e9549?w=246&h=140&fit=crop"
              alt=""
            />
          </div>
          <div class="video-info">
            <h3 class="video-title">Really Cool Vlog 8</h3>
            <ul class="video-meta-data">
              <li class="video-creator">
                ReallyCoolVlogs <i class="fas fa-check-circle"></i>
              </li>
              <li class="video-views">252K views</li>
              <li class="video-upload-date">1 day ago</li>
            </ul>
            <p class="video-description">
              Another installment of the really cool vlog. Please like and share
              the video. Subscribe and activate notifications so you never miss
              an upload. Follow on social media etc.
            </p>
          </div>
        </div> */}
        <div className="mt-5 ">
          <h2>Trending Videos</h2>
          <div className="d-flex">
            <div
              className="items"
              onClick={() =>
                navigate({
                  pathname: "/careerblitzlibrary/videosection/abcd",
                  // navigate(`/admin/Companylist/Companyform/${record?._id}`)}

                  // search: `123`,
                  // inject code value into template
                })
              }
            >
              <img
                className="thumbnail-vid"
                src="https://i.ytimg.com/vi/cnNMo4u0L4M/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBmwC7cAyuf4NKNRntZ66BGu29PiQ"
                alt=""
              />
              <p className="duration">10:49</p>
              <h1 className="vid-title">What ACTUALLY happened with Twitter</h1>
              <p className="ch-name">Mrwhosetheboss</p>
              <p className="ch-name">1.2M views 1 day ago</p>
              <img
                className="channel-icon"
                src="https://yt3.ggpht.com/Ikb1C4ih2VMvfjma8OO5b39JnHL2CQcQgksB_I7TM-gGA3ERTY589OeLKCYyRQQO0nkE54-f=s68-c-k-c0x00ffffff-no-rj"
                alt=""
              />
            </div>
            <div className="items">
              <img className="thumbnail-vid" src={thumb} alt="" />
              <p className="duration">10:49</p>
              <h1 className="vid-title">What ACTUALLY happened with Twitter</h1>
              <p className="ch-name">Mrwhosetheboss</p>
              <p className="ch-name">1.2M views 1 day ago</p>
              <img
                className="channel-icon"
                src="https://yt3.ggpht.com/Ikb1C4ih2VMvfjma8OO5b39JnHL2CQcQgksB_I7TM-gGA3ERTY589OeLKCYyRQQO0nkE54-f=s68-c-k-c0x00ffffff-no-rj"
                alt=""
              />
            </div>
            <div className="items">
              <img
                className="thumbnail-vid"
                src="https://i.ytimg.com/vi/cnNMo4u0L4M/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBmwC7cAyuf4NKNRntZ66BGu29PiQ"
                alt=""
              />
              <p className="duration">10:49</p>
              <h1 className="vid-title">What ACTUALLY happened with Twitter</h1>
              <p className="ch-name">Mrwhosetheboss</p>
              <p className="ch-name">1.2M views 1 day ago</p>
              <img
                className="channel-icon"
                src="https://yt3.ggpht.com/Ikb1C4ih2VMvfjma8OO5b39JnHL2CQcQgksB_I7TM-gGA3ERTY589OeLKCYyRQQO0nkE54-f=s68-c-k-c0x00ffffff-no-rj"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="mt-5">
          <h2>All Videos</h2>
          <div className="items">
            <img
              className="thumbnail-vid"
              src="https://i.ytimg.com/vi/cnNMo4u0L4M/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBmwC7cAyuf4NKNRntZ66BGu29PiQ"
              alt=""
            />
            <p className="duration">10:49</p>
            <h1 className="vid-title">What ACTUALLY happened with Twitter</h1>
            <p className="ch-name">Mrwhosetheboss</p>
            <p className="ch-name">1.2M views 1 day ago</p>
            <img
              className="channel-icon"
              src="https://yt3.ggpht.com/Ikb1C4ih2VMvfjma8OO5b39JnHL2CQcQgksB_I7TM-gGA3ERTY589OeLKCYyRQQO0nkE54-f=s68-c-k-c0x00ffffff-no-rj"
              alt=""
            />
          </div>
          <div className="items">
            <img className="thumbnail-vid" src={thumb} alt="" />
            <p className="duration">10:49</p>
            <h1 className="vid-title">What ACTUALLY happened with Twitter</h1>
            <p className="ch-name">Mrwhosetheboss</p>
            <p className="ch-name">1.2M views 1 day ago</p>
            <img
              className="channel-icon"
              src="https://yt3.ggpht.com/Ikb1C4ih2VMvfjma8OO5b39JnHL2CQcQgksB_I7TM-gGA3ERTY589OeLKCYyRQQO0nkE54-f=s68-c-k-c0x00ffffff-no-rj"
              alt=""
            />
          </div>
          <div className="items">
            <img
              className="thumbnail-vid"
              src="https://i.ytimg.com/vi/cnNMo4u0L4M/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBmwC7cAyuf4NKNRntZ66BGu29PiQ"
              alt=""
            />
            <p className="duration">10:49</p>
            <h1 className="vid-title">What ACTUALLY happened with Twitter</h1>
            <p className="ch-name">Mrwhosetheboss</p>
            <p className="ch-name">1.2M views 1 day ago</p>
            <img
              className="channel-icon"
              src="https://yt3.ggpht.com/Ikb1C4ih2VMvfjma8OO5b39JnHL2CQcQgksB_I7TM-gGA3ERTY589OeLKCYyRQQO0nkE54-f=s68-c-k-c0x00ffffff-no-rj"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoSection;
