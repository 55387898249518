import React, { useEffect } from "react";
import "./Home.scss";
import { Carousel } from "antd";
import { Row, Col } from "antd";
import {
  FcParallelTasks,
  FcCamcorderPro,
  FcBullish,
  FcDepartment,
  FcOvertime,
  FcEngineering,
  FcNext,
} from "react-icons/fc";
import learn from "../../../Images/yellow.png";
import learn1 from "../../../Images/black.png";
import learn2 from "../../../Images/learn33.jpg";
import learn3 from "../../../Images/learn44.jpg";
import card1 from "../../../Images/card1.jpg";
import card2 from "../../../Images/card2.jpg";
import card3 from "../../../Images/card3.jpg";
import logo from "../../../Images/logo2.jpg";
import star from "../../../Images/star.png";

import { Space, Typography } from "antd";
import { Card } from "antd";
import 'aos/dist/aos.css';
import AOS from 'aos';





const { Meta } = Card;
const { Text, Link } = Typography;
const gridStyle = {
  width: "25%",
  textAlign: "center",
};
const Home = () => (




  
  useEffect(() => {
    AOS.init({
      duration: 1000, // Set animation duration in milliseconds
      easing: 'ease-in-out', // Animation easing
    });
  }, []),

  <div className="student_home_carousel">
    <Carousel autoplay>
      <div>
        <div>
          <img className="image" src={learn} />
        </div>
      </div>
      <div>
        <div>
          <img className="image" src={learn1} />
        </div>
      </div>
    </Carousel>

    {/* NEWS */}
    <div className="News-container ">
      <Row>
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-5">
          <section className="last-news wow fadeInDown">
            <div className="news-wrapper">
              <span className="news-box-colour slideup animated"></span>
              <span className="news-title delay-animated slidein">
                // TRENDING //
              </span>
              <div
                className="carousel slide carousel-last-news"
                data-ride="carousel"
              >
                <div className="carousel-inner ms-5" role="listbox">
                  <div className="carousel-item active ">
                    <p>Today's Trending Technologies in Market</p>
                  </div>
                  <div className="carousel-item">
                    <p>
                      AI, machine learning, and automation will create 9 percent
                      of new U.S. jobs by 2025
                    </p>
                  </div>
                  <div className="carousel-item">
                    <p>
                      Artificial intelligence will become more prevalent in 2023
                      with natural language processing
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Row>
    </div>

    {/* TRENDING CARDS */}
    <div className="Trending_card_container" data-aos="fade-right" >
      <div className="posts">
        <div className="posts__item posts__item--main">
          <div className="posts__image" >
            <img
              src="https://img.freepik.com/free-photo/close-up-image-programer-working-his-desk-office_1098-18707.jpg?w=900&t=st=1679667104~exp=1679667704~hmac=8257a509004c377aa3ee0fecb107b4edda10e8da25c944ef752a637abd536f99"
              alt="Post image"
            />
          </div>
          <div className="posts__information">
            <div className="posts__date">April 28, 2023</div>
            <div className="posts__title">
              <a href="#">The Future is here, on 2023</a>
            </div>
          </div>
        </div>

        {/* <div className="posts__item">
          <div className="posts__image">
            <img
              src="https://images.unsplash.com/photo-1541654056076-0a252e083078?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=0973edee71e034b2da232c4f4fe99cef&auto=format&fit=crop&w=500&q=60"
              alt="Post image"
            />
          </div>
          <div className="posts__information">
            <div className="posts__date">November 24, 2015</div>
            <div className="posts__title">
              <a href="#">
                How To Find Design Inspiration In The Simple Things Around You
              </a>
            </div>
            <div className="posts__author">
              <a href="#">
                <img
                  src="https://randomuser.me/api/portraits/men/33.jpg"
                  alt="Author"
                />
              </a>
            </div>
          </div>
        </div> */}
        <div className="normal" >
          <p className="demo-title">Technology</p>
          <div className="module">
            <div className="thumbnail">
              <img src="https://img.freepik.com/free-photo/ai-nuclear-energy-background-future-innovation-disruptive-technology_53876-129783.jpg?w=900&t=st=1679667407~exp=1679668007~hmac=184cb0f5666f5201473d8bbda31c9477c12b8709930a82f9a8d8a4a983e01b94" />
              <div className="date">
                <div>28</div>
                <div>Mar</div>
              </div>
            </div>
            <div className="Trending-content">
              <div className="category">AI</div>
              <h1 className="Trending-title">Artificial intelligence</h1>
              <h2 className="sub-title">The Future.</h2>
              <div className="description">
                At its simplest form, artificial intelligence is a field, which
                combines computer science and robust datasets, to enable
                problem-solving.
              </div>
            </div>
          </div>
        </div>
        {/* <div className="posts__item">
          <div className="posts__image">
            <img
              src="https://images.unsplash.com/photo-1541636752-a5ace3a8a2d6?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=b3d5ec3f81c8ab31d1e7b0809ad178a6&auto=format&fit=crop&w=500&q=60"
              alt="Post image"
            />
          </div>
          <div className="posts__information">
            <div className="posts__date">November 18, 2015</div>
            <div className="posts__title">
              <a href="#">
                The Only Guide To Choosing Website Photos You’ll Ever Need
              </a>
            </div>
            <div className="posts__author">
              <a href="#">
                <img
                  src="https://randomuser.me/api/portraits/men/33.jpg"
                  alt="Author"
                />
              </a>
            </div>
          </div>
        </div> */}
        <div className="normal"  data-aos="fade-right">
          <p className="demo-title">Cloud</p>
          <div className="module">
            <div className="thumbnail">
              <img src="https://img.freepik.com/free-vector/computer-cloud-network-technology_53876-100678.jpg?w=740&t=st=1679718984~exp=1679719584~hmac=24e1b06ba39f92c7bc244bfc16db9c6d57400bfe000cb9d8c601660f8c0e75ff" />
              <div className="date">
                <div>28</div>
                <div>Mar</div>
              </div>
            </div>
            <div className="Trending-content">
              <div className="category">Cloud</div>
              <h1 className="Trending-title"> Cloud computing</h1>
              <h2 className="sub-title">Infrastructure as a Service</h2>
              <div className="description">
                cloud computing is the delivery of computing services—including
                servers, storage, databases, networking, software, analytics,
                and intelligence—over the Internet
              </div>
            </div>
          </div>
        </div>
        {/* <div className="posts__item">
          <div className="posts__image">
            <img
              src="https://images.unsplash.com/photo-1541618236-90386f48b9ee?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=89485d218a2ffb816d895360eddd817d&auto=format&fit=crop&w=500&q=60"
              alt="Post image"
            />
          </div>
          <div className="posts__information">
            <div className="posts__date">November 08, 2015</div>
            <div className="posts__title">
              <a href="#">
                Growth Hack Your Way to a Successful Freelance Career
              </a>
            </div>
            <div className="posts__author">
              <a href="#">
                <img
                  src="https://randomuser.me/api/portraits/men/33.jpg"
                  alt="Author"
                />
              </a>
            </div>
          </div>
        </div> */}
        <div className="normal">
          <p className="demo-title">Fullstack</p>
          <div className="module">
            <div className="thumbnail">
              <img src="https://img.freepik.com/free-photo/programming-background-collage_23-2149901770.jpg?w=826&t=st=1679719319~exp=1679719919~hmac=f26ccc9b07bccfa9d3ce6580776789eb7d5fd260c2a57d950cf3cd9dd8e93ff3" />
              <div className="date">
                <div>28</div>
                <div>Mar</div>
              </div>
            </div>
            <div className="Trending-content">
              <div className="category">Fullstack</div>
              <h1 className="Trending-title">Fullstack Development</h1>
              <h2 className="sub-title">HTML and CSS.</h2>
              <div className="description">
                Full stack development is the end-to-end development of
                applications. It includes both the front end and back end of an
                application.
              </div>
            </div>
          </div>
        </div>
        <div className="normal" data-aos="fade-left">
          <p className="demo-title">MERN STACK</p>
          <div className="module">
            <div className="thumbnail">
              <img src="https://img.freepik.com/free-vector/programming-languages-learning-software-coding-courses-website-development-class-script-writing-it-programmers-cartoon-characters_335657-789.jpg?w=740&t=st=1679719409~exp=1679720009~hmac=1d0d150b5b75bba5f008a5bcd0048187f8d893cd152527821d8fc3b759e73e92" />
              <div className="date">
                <div>28</div>
                <div>Mar</div>
              </div>
            </div>
            <div className="Trending-content" >
              <div className="category">MERNSTACK</div>
              <h1 className="Trending-title">Mern stack Development</h1>
              <h2 className="sub-title"></h2>
              <div className="description">
                MERN stands for MongoDB, Express, React, Node, after the four
                key technologies that make up the stack. Express and Node make
                up the middle (application) tier.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <div className="Int-popular-head">
      <h2>Popular Searches</h2>
    </div>
    <Row>
      <Col sm={24} md={12} lg={4} xs={12} className="Int-col">
        <div>
          <button className="button">Hacking</button>
        </div>
      </Col>
      <Col sm={24} md={12} lg={4} xs={12} className="Int-col">
        <div>
          <button class="button">Finance</button>
        </div>
      </Col>
      <Col sm={24} md={12} lg={4} xs={12} className="Int-col">
        <div>
          <button class="button">Accounts</button>
        </div>
      </Col>
      <Col sm={24} md={12} lg={4} xs={12} className="Int-col">
        <div>
          <button class="button">Web-Designing</button>
        </div>
      </Col>
      <Col sm={24} md={12} lg={4} xs={12} className="Int-col">
        <div>
          <button class="button">App-Development</button>
        </div>
      </Col>
      <Col sm={24} md={12} lg={4} xs={12} className="Int-col">
        <div>
          <button class="button">Management</button>
        </div>
      </Col>
      <Col sm={24} md={12} lg={4} xs={12} className="Int-col">
        <div>
          <button class="button">Data Analyst</button>
        </div>
      </Col>
      <Col sm={24} md={12} lg={4} xs={12} className="Int-col">
        <div>
          <button class="button">Banking</button>
        </div>
      </Col>
      <Col sm={24} md={12} lg={4} xs={12} className="Int-col">
        <div>
          <button class="button">Java</button>
        </div>
      </Col>
      <Col sm={24} md={12} lg={4} xs={12} className="Int-col">
        <div>
          <button class="button">Business Analyst</button>
        </div>
      </Col>
      <Col sm={24} md={12} lg={4} xs={12} className="Int-col">
        <div>
          <button class="button">Sales</button>
        </div>
      </Col>
    </Row> */}
    {/* Card */}
    {/* <div className="Int-popular-head">
      <h2>Advantage</h2>
    </div> */}
    {/* <Row style={{ padding: "50px" }}>
      <Col sm={24} md={12} lg={8}>
        <Card
          hoverable
          style={{
            width: 240,
          }}
          cover={<img src={card1} />}
        >
          <Meta title="One on one Sessions" />
        </Card>
      </Col>
      <Col sm={24} md={12} lg={8}>
        <Card
          hoverable
          style={{
            width: 240,
          }}
          cover={<img src={card2} />}
        >
          <Meta title="Doubts clearing sessions" />
        </Card>
      </Col>
      <Col sm={24} md={12} lg={8}>
        <Card
          hoverable
          style={{
            width: 240,
          }}
          cover={
            <img src={card3} style={{ height: "160px", width: "240px" }} />
          }
        >
          <Meta title="Clear explanations" />
        </Card>
      </Col>
    </Row> */}
    {/* Star */}
    {/* <div className="Int-popular-head">
      <h2>Sponsered Companies</h2>
    </div> */}
    {/* <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
      }}
    >
      <Card
        style={{
          width: "240px",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          flexWrap: "wrap",
          margin: "10px",
        }}
      >
        <Row className="cardrow">
          <Col sm={24} md={24} lg={24} xs={24}>
            <div>
              <img className="logo" src={logo} />
            </div>
          </Col>
          <Col sm={24} md={24} lg={24} xs={24}>
            <div>Company Name</div>
          </Col>
          <Col
            sm={24}
            md={24}
            lg={24}
            xs={24}
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <div>
              <img className="star" src={star}></img>
            </div>
            <div>4.2</div>
          </Col>
          <Col sm={24} md={24} lg={24} xs={24}>
            <div>Course Name</div>
          </Col>
        </Row>
      </Card>
    </div> */}
  </div>
);
export default Home;
