import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./PGSTUDENTS.css";
import { BiBed, BiRupee } from "react-icons/bi";
import { HiLocationMarker } from "react-icons/hi";
import { BsFilter } from "react-icons/bs";
import {
  GiWifiRouter,
  GiMeal,
  GiWashingMachine,
  GiVacuumCleaner,
} from "react-icons/gi";
import { CgGym } from "react-icons/cg";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import hostel from "../../assets/pg/hostel.jpg";
import furniture from "../../assets/pg/furniture.png";
import table from "../../assets/pg/table.png";
function PGSTUDENTS() {
  const pgStudents = [
    {
      id: 1,
      image:
        "https://img.freepik.com/free-photo/group-teenagers-social-networking-using-several-tech-devices-high-school_662251-1933.jpg?size=626&ext=jpg&ga=GA1.2.786155833.1675059853&semt=ais",
      price: "8000",
      title: "Smart PG For Students",
      location: "Kudlu Gate",
      contact: "Contact us",
      number: "+12-678-234-6578",
      apply: "Apply",
    },
    {
      id: 2,
      image:
        "https://img.freepik.com/premium-photo/word-students-against-lecturer-sitting-lecture-hall_1134-51688.jpg?size=626&ext=jpg&ga=GA1.2.786155833.1675059853&semt=ais",
      price: "9000",
      title: "Prabhas PG For Students",
      location: "Electronic City",
      contact: "Contact us",
      number: "+12-778-834-9858",
      apply: "Apply",
    },
    {
      id: 3,
      image:
        "https://img.freepik.com/free-photo/academics-wisdom-literacy-study-icon_53876-122500.jpg?size=626&ext=jpg&ga=GA1.2.786155833.1675059853&semt=ais",
      price: "7500",
      title: "Thanu PG For Students",
      location: "HSR Layout",
      contact: "Contact us",
      number: "+12-888-556-9080",
      apply: "Apply",
    },
    {
      id: 4,
      image:
        "https://img.freepik.com/free-photo/academics-wisdom-literacy-study-icon_53876-122500.jpg?size=626&ext=jpg&ga=GA1.2.786155833.1675059853&semt=ais",
      price: "5300",
      title: "Ashraya PG For Students",
      location: "Kudlu Gate",
      contact: "Contact us",
      number: "+12-999-456-2345",
      apply: "Apply",
    },
    {
      id: 5,
      image:
        "https://img.freepik.com/free-photo/school-partners-sitting-their-desks_23-2147650740.jpg?size=626&ext=jpg&ga=GA1.2.786155833.1675059853&semt=ais",
      price: "6500",
      title: "Shree PG For Students",
      location: "Electronic City",
      contact: "Contact us",
      number: "+12-345-8970922",
      apply: "Apply",
    },
    {
      id: 6,
      image:
        "https://img.freepik.com/free-photo/african-female-graduate-cap-mantle-smiling-sitting-with-books_176420-14343.jpg?size=626&ext=jpg&ga=GA1.1.786155833.1675059853&semt=ais",
      price: "13000",
      title: "Bharani PG For Students",
      location: "HSR Layout",
      contact: "Contact us",
      number: "+12-456-989-6577",
      apply: "Apply",
    },
  ];
  return (
    <div>
      <div className="Align">
        <h1 className="align_text">PG FOR STUDENTS</h1>
      </div>
      <div>
        <Row>
          <Col md={2} className="navebar">
            <p className="align">
              <BsFilter size={30} />
              Filters
            </p>

            <Row>
              <Col className="locate">
                <b>
                  Location
                  <HiLocationMarker size={20} />
                </b>
                <br />
                <input type="checkbox" value="City1" />
                Kudlu Gate
                <br />
                <input type="checkbox" value="City2" />
                HSR Layout
                <br />
                <input type="checkbox" value="City3" />
                Electronic City
                <br />
                <input type="checkbox" value="City4" />
                Madiwala
              </Col>
            </Row>

            <Row>
              <Col>
                <b>
                  Budget
                  <BiRupee size={20} />
                </b>
                <br />
                <input type="checkbox" value="Price" />
                ₹5000-7000
                <br />
                <input type="checkbox" value="Price" />
                ₹7000-9000
                <br />
                <input type="checkbox" value="Price" />
                ₹9000-13000
                <br />
                <input type="checkbox" value="Price" />
                ₹13000-17000
              </Col>
            </Row>

            <Row>
              <Col className="Gender">
                <b>Gender</b>
                <br />
                <input type="checkbox" value="Boys" />
                Boys
                <br />
                <input type="checkbox" value="Girls" />
                Girls
              </Col>
            </Row>
            <Row>
              <Col className="Sharing">
                <b>Sharing</b>
                <br />
                <input type="checkbox" value="Single" />
                Single
                <br />
                <input type="checkbox" value="Two" />
                Two
                <br />
                <input type="checkbox" value="Three" />
                Three
              </Col>
            </Row>
            <Row>
              <Col className="FOOD">
                <b>Food</b>
                <br />
                <input type="checkbox" value="Food" />
                With Food
                <br />
                <input type="checkbox" value="Food" />
                Without Food
              </Col>
            </Row>
            <Row>
              <button variant="primary" className="button1">
                Apply
              </button>
            </Row>
          </Col>

          <Col>
            <div>
              {pgStudents.map((pgStudents, i) => (
                <Row>
                  <Col md={1}></Col>
                  <Col md={3}>
                    <img className="imagewh" src={pgStudents.image} alt="..." />
                  </Col>
                  <Col md={4}>
                    <Card className="map_card">
                      <Card.Body>
                        <Card.Title>{pgStudents.title}</Card.Title>
                        <Card.Text>
                          <BiRupee size={20} />
                          {pgStudents.price}
                        </Card.Text>
                        <Card.Text>
                          <HiLocationMarker size={20} />
                          {pgStudents.location}
                        </Card.Text>
                        <Card.Text>{pgStudents.contact}</Card.Text>
                        <Row>
                          <Col md={6}>
                            <button
                              variant="danger"
                              className="button1"
                              style={{ width: "150px" }}
                            >
                              {pgStudents.number}
                            </button>
                          </Col>
                          <Col md={6}>
                            <button variant="danger" className="button1">
                              {pgStudents.apply}
                            </button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              ))}
            </div>
          </Col>
        </Row>
      </div>

      <div>
        <Row>
          <Col md={4} className="paragrapha_align">
            <h1>The Sanitised and Furnished Room</h1>
            <p>
              Your deeply sanitised room will have all the furniture and
              facilities you need for a comfortable stay. You see, we believe
              that your chair shouldn’t multitask as a clothes rack. And your
              bed is not meant to be a study table.
            </p>
          </Col>
          <Col md={5}>
            <img className="hostel_pic" src={hostel} alt="..." />
          </Col>
        </Row>
        <Row className="foot">
          <Col>
            <h1>
              <BiBed className="bed" size={40} />
              <br />
            </h1>
            Bed with <br /> Mattress
          </Col>
          <Col>
            <img className="cupboard" src={furniture} alt="..." />
            <br />
            Spacious
            <br />
            Cupboard
          </Col>
          <Col>
            <img className="table" src={table} alt="..." />
            <br />
            Study Table
          </Col>
          <Col>
            <h1>
              <GiWifiRouter className="freewifi" size={40} />
            </h1>
            <br />
            Free wifi
          </Col>
        </Row>
        <Row>
          <Col>
            <GiMeal className="food" size={30} />
            <br />
            Healthy
            <br />
            Food
          </Col>
          <Col>
            <GiWashingMachine className="wash" size={40} />
            <br />
            Laundry
            <br />
          </Col>
          <Col>
            <CgGym className="gym" size={40} />
            <br />
            Gym
            <br />
            Facility
          </Col>
          <Col>
            <GiVacuumCleaner className="clean" size={40} />
            <br />
            Professional
            <br />
            RoomCleaning
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default PGSTUDENTS;
