import React, { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { Table, Button } from "antd";
import "../../../Components/Css/DataListTable.scss";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";
import moment from 'moment';


const DataListTable = ({
  title,
  handleSearch,
  columns,
  dataList,
  showAddNewBtn = true,
  handleAddNewBtn = () => { },
}) => {

 
  

  

  return (
    <>
      <div className="p-5">
        <h2>{title}</h2>
        
        <div className="Datalist-Table">
          <Table
            columns={columns}
            dataSource={dataList || []}
            pagination={{
              showSizeChanger: true,
              position: ["bottomCenter"],
            }}
            className="mt-2"
          />
        </div>
      </div>
    </>
  );
};

export default DataListTable;
