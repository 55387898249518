import React, { useState, useEffect } from "react";
import Api from "../../../Api";
import "./CourseCreate.css";
import { Row, Col, Button, Form, Spinner } from "react-bootstrap";
import moment from "moment";
import {  TimePicker, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select';

const { Option } = Select;
const { RangePicker } = TimePicker;

const CourseCreate = () => {
  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      opacity: state.isFocused ? 1 : 0,  // Show input only when focused
    }),
    control: (provided) => ({
      ...provided,
      cursor: 'text', // Keep the cursor style like a text input
    }),
  };
  const classDaysOptions = [
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    {value:'Thursday', label:'Thursday'},
    {value:'Friday',label:'Friday'},
    {value:'Saturday',label:'Saturday'},
    {value:'Sunday',label:'Sunday'},
  ];
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const { mode, course } = state || {};
  const [sectors, setSectors] = useState([]);
  const [sectorId, setSectorId] = useState(null);
  const [domains, setDomains] = useState([]);
  const [domainId, setDomainId] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [technologies, setTechnologies] = useState([]);
const [technologyId, setTechnologyId] = useState(null);

useEffect(() => {
  Api.get('exam/getalltechnology')
    .then(res => setTechnologies(res.data.map(tech => ({ value: tech._id, label: tech.tech_name }))))
    .catch(err => console.error(err));
}, []);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    instructor: "",
    duration: "",
    startDate: "",
    endDate: "",
    classDays: [],
    classTime: [],
    mode: "online",
    originalprice: "",
    offerprice:"",
    image: null,
    rating: 0,
    whatYoullLearn: [],
    technologies: [],
    sector:"",
    domain:"",
    category:"",
    subcategory:"",

  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Api.get('/coursesector/getsector')
      .then(res => setSectors(res.data.map(sector => ({ value: sector._id, label: sector.sectorName }))))
      .catch(err => console.error(err));
  }, []);

  // Fetch domains when a sector is selected
  useEffect(() => {
    if (sectorId) {
      Api.get(`/coursedomain/getdomain/${sectorId}`)
        .then(res => setDomains(res.data.map(domain => ({ value: domain._id, label: domain.domainName }))))
        .catch(err => console.error(err));
    } else {
      setDomains([]); // Reset domains when no sector is selected
    }
  }, [sectorId]);

  // Fetch categories when a domain is selected
  useEffect(() => {
    if (domainId) {
      Api.get(`/coursecategory/getcategory/${domainId}`)
        .then(res => setCategories(res.data.map(category => ({ value: category._id, label: category.categoryName }))))
        .catch(err => console.error(err));
    } else {
      setCategories([]); // Reset categories when no domain is selected
    }
  }, [domainId]);

  // Fetch sub-categories when a category is selected
  useEffect(() => {
    if (categoryId) {
      Api.get(`/coursesubcategory/getsubcategory/${categoryId}`)
        .then(res => setSubCategories(res?.data?.map(subCategory => ({ value: subCategory._id, label: subCategory.subcategoryName }))))
       
        .catch(err => console.error(err));
    } else {
      setSubCategories([]); // Reset subcategories when no category is selected
    }
  }, [categoryId]);
  useEffect(() => {
    if (mode === "edit" && course) {
      setFormData({
        title: course.title || "",
        description: course.description || "",
        instructor: course.instructor || "",
        duration: calculateDurationInWeeks(course.startDate, course.endDate),
        startDate: moment(course.startDate).format("YYYY-MM-DD"), // Parse and format startDate
        endDate: moment(course.endDate).format("YYYY-MM-DD"),
        classDays: course.classDays || [],
        classTime: course.classTime || [],
        mode: course.mode || "online",
        price: course.price || "",
        image: null, // image should be reset for security reasons
        rating: course.rating || 0,
        weeks: [{ weekNumber: 1, technologies: [ ], whatYoullLearn: [ ] }]
      });
    }
  }, [mode, course]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
// Sector
// Sector
const handleSectorChange = (selectedOption) => {
  setFormData({
    ...formData,
    sector: selectedOption?.label,
  });
  setSectorId(selectedOption?.value); // Store the ID for API calls
};

// Domain
const handleDomainChange = (selectedOption) => {
  setFormData({
    ...formData,
    domain: selectedOption?.label,
  });
  setDomainId(selectedOption?.value); // Store the ID for API calls
};

// Category
const handleCategoryChange = (selectedOption) => {
  setFormData({
    ...formData,
    category: selectedOption?.label,
  });
  setCategoryId(selectedOption?.value); // Store the ID for API calls
};

// Sub-Category
const handleSubCategoryChange = (selectedOption) => {
  setFormData({
    ...formData,
    subcategory: selectedOption?.label,
  });
  setSubCategoryId(selectedOption?.value);
  console.log('sub',subCategoryId) // Store the ID for API calls
};

  const calculateDurationInWeeks = (startDate, endDate) => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const weeks = Math.floor((end - start) / (1000 * 60 * 60 * 24 * 7));
      return `${weeks} weeks`;
    }
    return "";
  };

  const handleStartDateChange = (e) => {
    const startDate = e.target.value;
    const duration = calculateDurationInWeeks(startDate, formData.endDate);
    setFormData({
      ...formData,
      startDate,
      duration,
    });
  };

  const handleEndDateChange = (e) => {
    const endDate = e.target.value;
    const duration = calculateDurationInWeeks(formData.startDate, endDate);
    setFormData({
      ...formData,
      endDate,
      duration,
    });
  };

  const handleClassDaysChange = (selectedOptions) => {
    // selectedOptions is an array of selected objects when isMulti is true
    const selectedDays = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setFormData({
      ...formData,
      classDays: selectedDays, // Store the array of selected day values
    });
  };
  
  const handleTrainerChange = (value) => {
    setFormData({
      ...formData,
      instructor: value,
    });
  };

  const handleTimeRangeChange = (time) => {
    setFormData({
      ...formData,
      classTime: time ? [time[0].format("HH:mm a"), time[1].format("HH:mm a")] : [],
    });
  };

  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0], // Save the file object directly
    });
  };

  const handleWhatYoullLearnChange = (index, value) => {
    const newWhatYoullLearn = [...formData.whatYoullLearn];
    newWhatYoullLearn[index] = value;
    setFormData({ ...formData, whatYoullLearn: newWhatYoullLearn });
  };
  
  const handleTechnologiesChange = (index, value) => {
    const newTechnologies = [...formData.technologies];
    newTechnologies[index] = value;
    setFormData({ ...formData, technologies: newTechnologies });
  };

  const handleAddItem = (field) => {
    setFormData({ ...formData, [field]: [...formData[field], ""] });
  };

  const handleRemoveItem = (field, index) => {
    const newItems = formData[field].filter((_, i) => i !== index);
    setFormData({ ...formData, [field]: newItems });
  };
  const handleAddWeek = () => {
    console.log("Current Weeks:", formData.weeks); // Debug log
    const newWeek = { weekNumber: (formData.weeks?.length || 0) + 1, technologies: [""], whatYoullLearn: [""] };
    setFormData(prevFormData => ({
      ...prevFormData,
      weeks: [...(prevFormData.weeks || []), newWeek]
    }));
  };
  
  

  const handleRemoveWeek = (index) => {
    const newWeeks = formData.weeks.filter((_, i) => i !== index);
    setFormData({ ...formData, weeks: newWeeks });
  };

  const handleWeekChange = (index, key, value) => {
    const newWeeks = [...formData.weeks];
    newWeeks[index][key] = value;
    setFormData({ ...formData, weeks: newWeeks });
  };

  const handleAddArrayItem = (weekIndex, field) => {
    const newWeeks = [...formData.weeks];
    if (field === "technologies") {
      newWeeks[weekIndex][field].push("");
    } else {
      newWeeks[weekIndex][field].push("");
    }
    setFormData({ ...formData, weeks: newWeeks });
  };

  const handleRemoveArrayItem = (weekIndex, field, itemIndex) => {
    const newWeeks = [...formData.weeks];
    newWeeks[weekIndex][field].splice(itemIndex, 1);
    setFormData({ ...formData, weeks: newWeeks });
  };

  const handleArrayChange = (weekIndex, field, itemIndex, value) => {
    const newWeeks = [...formData.weeks];
    if (field === "technologies") {
      const technology = technologies.find(tech => tech.label === value);
      newWeeks[weekIndex][field][itemIndex] = technology ? technology.value : "";
    } else {
      newWeeks[weekIndex][field][itemIndex] = value;
    }
    setFormData({ ...formData, weeks: newWeeks });
  };
  const renderWeeks = () => {
    return formData?.weeks?.map((week, weekIndex) => (
      <div key={weekIndex} className="week-section">
        <h5>Week {week.weekNumber}</h5>
  
        {/* Technologies Input */}
        <Form.Group>
          {week.technologies.map((tech, techIndex) => (
            <div key={techIndex} className="dynamic-input">
              <Select
                options={technologies}
                value={technologies.find(option => option.value === tech) || null}
                onChange={(selectedOption) => handleArrayChange(weekIndex, "technologies", techIndex, selectedOption?.label)}
              />
              <Button variant="danger" onClick={() => handleRemoveArrayItem(weekIndex, "technologies", techIndex)}>
                Remove
              </Button>
            </div>
          ))}
          <Button onClick={() => handleAddArrayItem(weekIndex, "technologies")}>Add Technology</Button>
        </Form.Group>
  
        {/* What You'll Learn Input */}
        <Form.Group>
          {week.whatYoullLearn.map((learn, learnIndex) => (
            <div key={learnIndex} className="dynamic-input">
              <Form.Control
                type="text"
                value={learn}
                onChange={(e) => handleArrayChange(weekIndex, "whatYoullLearn", learnIndex, e.target.value)}
              />
              <Button variant="danger" onClick={() => handleRemoveArrayItem(weekIndex, "whatYoullLearn", learnIndex)}>
                Remove
              </Button>
            </div>
          ))}
          <Button onClick={() => handleAddArrayItem(weekIndex, "whatYoullLearn")}>Add Item</Button>
        </Form.Group>
  
        <Button variant="danger" onClick={() => handleRemoveWeek(weekIndex)}>
          Remove Week
        </Button>
      </div>
    ));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
  
    // Append all other fields except image
    Object.keys(formData).forEach((key) => {
      if (key !== "image") {
        // Do not stringify arrays
        if (Array.isArray(formData[key])) {
          data.append(key, JSON.stringify(formData[key])); // Send as JSON string if required by backend
        } else {
          data.append(key, formData[key] || "");
        }
      }
    });
  
    // Append the image file only if it's selected
    if (formData.image) {
      data.append("image", formData.image);
    }
  
    try {
      const response = 
          mode === "add"
              ? await Api.post("/courses/createcourse", data, {
                  headers: { "Content-Type": "multipart/form-data" },
              })
              : await Api.put(`/courses/updatecourse/${course._id}`, data, {
                  headers: { "Content-Type": "multipart/form-data" },
              });
  
      if (response.status === 200) {
          message.success("Course saved successfully!");
          navigate("/admin/examcourse");
      } else {
          message.error("Failed to save the course.");
      }
    } catch (error) {
      message.error("Error saving course. Please try again.");
      console.error("Error saving course:", error);
    } finally {
      setLoading(false);
    }
  };
  
  

const renderArrayInputFields = (field, label, changeFunction) => (
  <div className="form-group">
    <label>{label}:</label>
    {formData[field]?.map((item, index) => (
      <div key={index} className="dynamic-input">
        <Form.Control
          type="text"
          value={item}
          onChange={(e) => changeFunction(index, e.target.value)}
          className="mb-2"
        />
        <Button
          variant="danger"
          onClick={() => handleRemoveItem(field, index)}
          className="ml-2"
        >
          Remove
        </Button>
      </div>
    ))}
    <Button variant="secondary" onClick={() => handleAddItem(field)}>
      Add {label}
    </Button>
  </div>
);

// Usage
{renderArrayInputFields("whatYoullLearn", "What You'll Learn", handleWhatYoullLearnChange)}
{renderArrayInputFields("technologies", "Technologies", handleTechnologiesChange)}

const handleChangeArrayInput = (field, index, e) => {
  const newItems = [...formData[field]];
  newItems[index] = e.target.value;
  setFormData({ ...formData, [field]: newItems });
};


  return (
    <Form onSubmit={handleSubmit} className="course-create-form">
      <Row>
        <Col sm={12} md={6} lg={4} className="p-4">
          <Form.Group controlId="title">
            <Form.Label>Title:</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
        <Col sm={12} md={6} lg={4} className="p-4">
          <Form.Group controlId="description">
            <Form.Label>Description:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </Form.Group>
          </Col>
        <Col sm={12} md={6} lg={4} className="p-4">
          <Form.Group controlId="duration">
            <Form.Label>Duration:</Form.Label>
            <Form.Control
              type="text"
              name="duration"
              value={formData.duration}
              readOnly
            />
          </Form.Group>
        </Col>
        <Col sm={12} md={6} lg={4} className="p-4">
          <Form.Group controlId="startDate">
            <Form.Label>Start Date:</Form.Label>
            <Form.Control
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleStartDateChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="endDate" className="mt-2">
            <Form.Label>End Date:</Form.Label>
            <Form.Control
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleEndDateChange}
              required
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={6} lg={4} className="p-4">
        <Form.Group controlId="classDays">
    <Form.Label>Class Days:</Form.Label>
    <Select
      isMulti // Enables multiple selections
      placeholder="Select Days"
      value={classDaysOptions.filter(option => formData.classDays.includes(option.value))} // Set the selected options
      onChange={handleClassDaysChange} // Handles the change
      options={classDaysOptions} // The available days
      closeMenuOnSelect={false} // Keeps the menu open when selecting multiple options
    />
  </Form.Group>
        </Col>
        <Col sm={12} md={6} lg={4} className="p-4">
        <Form.Group>
          <Form.Label>Class Time</Form.Label>
          <RangePicker
            use12Hours
            format="h:mm a"
            value={
              formData.classTime.length > 0
                ? [moment(formData.classTime[0], "h:mm a"), moment(formData.classTime[1], "h:mm a")]
                : []
            }
            onChange={handleTimeRangeChange}
          />
        </Form.Group>
        </Col>
      </Row>

      {renderWeeks()}

<Button onClick={handleAddWeek}>Add Week</Button>

      <Row>
        <Col sm={12} md={6} lg={4} className="p-4">
          <Form.Group controlId="mode">
            <Form.Label>Mode:</Form.Label>
            <Form.Control
              as="select"
              name="mode"
              value={formData.mode}
              onChange={handleChange}
            >
              <option value="online">Online</option>
              <option value="offline">Offline</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={6} lg={4} className="p-4">
          <Form.Group controlId="originalprice">
            <Form.Label>Original Price:</Form.Label>
            <Form.Control
              type="number"
              name="originalprice"
              value={formData.originalprice}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
        <Col sm={12} md={6} lg={4} className="p-4">
          <Form.Group controlId="offerprice">
            <Form.Label>Offer Price:</Form.Label>
            <Form.Control
              type="number"
              name="offerprice"
              value={formData.offerprice}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
        <Col sm={12} md={6} lg={4} className="p-4">
          <Form.Group controlId="image">
            <Form.Label>Image:</Form.Label>
            <Form.Control
              type="file"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
      <Col sm={12} md={6} lg={4} className="p-4">
        <Form.Group controlId="sector">
          <Form.Label>Sector:</Form.Label>
          <Select
            options={sectors}
            onChange={handleSectorChange}
            placeholder="Select Sector"
            styles={customStyles}
            value={sectors.find(option => option.value === sectorId) || null}
          />
          {console.log(sectorId)}
        </Form.Group>
      </Col>
      <Col sm={12} md={6} lg={4} className="p-4">
        <Form.Group controlId="domain">
          <Form.Label>Domain:</Form.Label>
          <Select
            options={domains}
            onChange={handleDomainChange}
            placeholder="Select Domain"
            isDisabled={!sectorId}
            styles={customStyles}
            value={domains.find(option => option.value === domainId) || null}
            
          />
       
        </Form.Group>
      </Col>
      <Col sm={12} md={6} lg={4} className="p-4">
      <Form.Group controlId="category">
  <Form.Label>Category:</Form.Label>
  <Select
    options={categories}
    onChange={handleCategoryChange}
    placeholder="Select Category"
    isDisabled={!domainId}
    styles={customStyles}
    value={categories.find(option => option.value === categoryId) || null}
  />
</Form.Group>
      </Col>
      <Col sm={12} md={6} lg={4} className="p-4">
        <Form.Group controlId="subcategory">
          <Form.Label>Sub-Category:</Form.Label>
          <Select
            options={subCategories}
            onChange={handleSubCategoryChange}
            placeholder="Select Sub-Category"
            isDisabled={!categoryId}
            styles={customStyles}
            value={subCategories.find(option => option.value === subCategoryId) || null}
          />
        </Form.Group>
      </Col>
    </Row>
      <Row>
        <Col sm={12} className="p-4">
          <Button
            variant="primary"
            type="submit"
            disabled={loading}
            className="mt-3"
          >
            {loading ? <Spinner animation="border" size="sm" /> : "Save"}
          </Button>
        </Col>
      </Row>
      

    </Form>
  );
};

export default CourseCreate;
