import React from "react";

function TrainerPackageDetails() {
  return (
    <div className="mt-5">
      <div>
        <h1>TrainerPackageDetails</h1>
      </div>
    </div>
  );
}

export default TrainerPackageDetails;
