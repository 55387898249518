import React, { useEffect, useState } from "react";
import { Table, Button, Input, Popconfirm, Modal } from "antd";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";

function List() {
  const [List, setList] = useState([]);
  const [getData, setGetData] = useState();
  const [search, setSearch] = useState();

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    await Api.get("/aboutus/aboutus").then((res) => {
      setList(res.data.data);
    });
  };

  const deleteTechnology = (id) => {
    Api.delete(`/aboutus/deleteaboutus/${id}`).then((resp) => {
      getList();
    });
  };
  // const update = (ele) => {
  //   navigate("/admin/homestudentcreate", { state: { ele } });
  // };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    const name = e.target.value;
    console.log(typeof name);

    const newData = List.filter((list) =>
      list.Technology.toLowerCase().includes(name.toLowerCase())
    );
    setGetData(newData);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    // { title:"StudentImage",
    //   dataIndex:"StudentImage",
    //   },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div>
          {/* <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              update(record);
            }}
          /> */}

          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => {
              deleteTechnology(record._id);
            }}
          >
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ paddingLeft: "10px", cursor: "pointer" }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const onSearch = "";
  const navigate = useNavigate();
  return (
    <div>
      <div className="data-list-main">
        <h4 className="data-list-header">Home Carousel List</h4>
        <div
          className="data-list-top mt-4 py-4 px-3"
          style={{ backgroundColor: "#c5c5c5" }}
        >
          <input
            className="data-list-search ps-2"
            placeholder="search"
            columns={columns}
            value={search}
            onChange={handleSearch}
          />
          <Button
            className="data-list-button"
            onClick={() => navigate("/admin/homecarouseltcreate")}
          >
            <BsFillPersonPlusFill />
            &nbsp;Add New
          </Button>
        </div>
        <div className=".Datalist-Table">
          <Table
            columns={columns}
            dataSource={getData ? getData : List}
            pagination={true}
            className="mt-2"
          />
        </div>
      </div>
    </div>
  );
}

export default List;
