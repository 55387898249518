import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../Components/CustomButton";
import Api from "../../../Api";
import "./AddDetail.css";

const AddDetail = ({ onRoleTypeChange }) => {
  const trainerId = localStorage.getItem("userId");

  const [userData, setUserData] = useState([]);
  console.log("userData", userData);
  const [selectedRoles, setSelectedRoles] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoleType = async () => {
      try {
        const response = await Api.get(`trainer/Trainergetone/${trainerId}`);
        const { roletype } = response?.data?.data?.RoleTypeDetails || {};
        console.log("response", response);

        setSelectedRoles({
          trainer: roletype === "trainer" || roletype === "both",
          counsellor: roletype === "counsellor" || roletype === "both",
        });

        onRoleTypeChange(roletype);
      } catch (error) {
        console.error("Error fetching role type:", error);
      }
    };

    fetchRoleType();
  }, [trainerId, onRoleTypeChange]);

  const [role, setRole] = useState();
  console.log("role", role);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const details = {
      roletype: role,
      role: role,
    };
    try {
      const dd = await Api.put(`trainer/roletypedetails/${trainerId}`, details);
      console.log("dd", dd);
      const role = dd.data.trainer.role;
      localStorage.setItem("role", role);

      setIsModalVisible(true);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const handleModalOk = () => {
    setIsModalVisible(false);

    // navigate("/trainer/trainerdashboard");

    const roleType =
      selectedRoles.trainer && selectedRoles.counsellor
        ? "both"
        : selectedRoles.trainer
        ? "trainer"
        : selectedRoles.counsellor
        ? "counsellor"
        : "";

    // if (roleType === "trainer") {
    //   navigate("/trainer/trainerprofile/training"); // Navigate to training page
    // } else if (roleType === "counsellor") {
    //   navigate("/trainer/trainerprofile/counselling"); // Navigate to counselling page
    // } else if (roleType === "both") {
    //   navigate("/trainer/trainerprofile/training"); // Or some page for both, if needed
    // }

    if (roleType === "trainer") {
      navigate("/trainer/trainerdashboard");
    } else if (roleType === "counsellor") {
      navigate("/counsellor/dashboard");
    }
  };
  console.log("selected roles:", selectedRoles);

  const handleSelection = (role) => {
    console.log("role", role);
    setRole(role);
    setSelectedRoles((prevSelectedRoles) => ({
      trainer: role === "trainer",
      counsellor: role === "counsellor",
    }));
  };

  return (
    <div className="container">
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={8}>
          <h2 className="text-center">Add Details</h2>
          <Row className="justify-content-center g-4">
            <Col xs={12} md={5} className="mb-4">
              {" "}
              <Card
                className="text-center trainer-btnone"
                style={{
                  borderRadius: "10px",
                  cursor: "pointer",
                  border:
                    (selectedRoles?.trainer && !selectedRoles?.counsellor) ||
                    (selectedRoles?.trainer && selectedRoles?.counsellor)
                      ? "2px solid blue"
                      : "none",
                }}
                onClick={() => handleSelection("trainer")}
              >
                <Card.Img
                  variant="top"
                  src="https://cdn.prod.website-files.com/5e7fdf360c4ef47490fa4124/63349ddb1ff3105d4e20415e_Training.jpg"
                  alt="Training"
                  style={{
                    objectFit: "cover",
                    height: "235px",
                    borderRadius: "10px 10px 0 0",
                  }}
                  // onClick={() => navigate("/trainer/trainerdashboard")}
                />
                <div className="card-text">Training</div>
              </Card>
            </Col>
            <Col md={2}></Col>
            <Col xs={12} md={5} className="mb-4">
              {" "}
              <Card
                className="text-center  trainer-btnone"
                style={{
                  borderRadius: "10px",
                  cursor: "pointer",
                  border:
                    (selectedRoles?.counsellor && !selectedRoles?.trainer) ||
                    (selectedRoles?.trainer && selectedRoles?.counsellor)
                      ? "2px solid blue"
                      : "none",
                }}
                onClick={() => handleSelection("counsellor")}
              >
                <Card.Img
                  variant="top"
                  src="https://www.globalcareercounsellor.com/blog/wp-content/uploads/2019/06/career-counselling-in-india-1200x675.png"
                  alt="Career Counseling"
                  style={{
                    objectFit: "cover",
                    height: "235px",
                    borderRadius: "10px 10px 0 0",
                  }}
                />
                <div className="card-text">Counselling</div>
              </Card>
            </Col>
          </Row>

          <div className="text-center">
            <CustomButton label="Submit" onClick={(e) => handleSubmit(e)} />
          </div>
        </Col>
      </Row>

      <Modal
        title="Success"
        visible={isModalVisible}
        onOk={handleModalOk}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p>Your details have been submitted successfully!</p>
      </Modal>
    </div>
  );
};

export default AddDetail;
