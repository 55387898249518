import React from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import "./ContactUs.scss";
import Layout from "../Components/Layout";

const ContactUs = () => {
  return (
    <Layout>
      <Container fluid className="unique-contact-page py-5">
        <Container className="contactus-container">
          <h2 className="text-center get-in-touch mb-5">Get In Touch</h2>

          <Row className="mb-5">
            <Col md={4} className="mb-4 d-flex justify-content-center">
              <Card className="unique-contact-card">
                <Card.Body className="text-center unique-card-body">
                  <FaPhoneAlt size={40} className="mb-3 unique-contact-icon" />
                  <Card.Title>Phone</Card.Title>
                  <Card.Text className="card-text-contact">
                    +1 (800) 123-4567
                  </Card.Text>
                  <Card.Text className="card-text-contact">
                    Mon-Fri: 9 AM - 6 PM EST
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4} className="mb-4 d-flex justify-content-center">
              <Card className="unique-contact-card">
                <Card.Body className="text-center unique-card-body">
                  <FaEnvelope size={40} className="mb-3 unique-contact-icon" />
                  <Card.Title>Email</Card.Title>
                  <Card.Text className="card-text-contact">
                    <a href="mailto:info@careerblitz.com">
                      info@careerblitz.com
                    </a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4} className="mb-4 d-flex justify-content-center">
              <Card className="unique-contact-card">
                <Card.Body className="text-center unique-card-body">
                  <FaMapMarkerAlt
                    size={40}
                    className="mb-3 unique-contact-icon"
                  />
                  <Card.Title>Location</Card.Title>
                  <Card.Text className="card-text-contact">
                    123 CareerBlitz{" "}
                  </Card.Text>
                  <Card.Text className="card-text-contact">Bengaluru</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={6} className="mx-auto">
              <h4 className="text-center contact-heading mb-4">
                Send Us a Message
              </h4>

              <Form className="form-contact-cont">
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label className="contactus-label">Your Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter your name" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label className="contactus-label">
                    Your Email
                  </Form.Label>
                  <Form.Control type="email" placeholder="Enter your email" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formMessage">
                  <Form.Label className="contactus-label">
                    Your Message
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Enter your message"
                  />
                </Form.Group>

                <div className="text-center">
                  <Button variant="primary" type="submit" className="px-5">
                    Send Message
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  );
};

export default ContactUs;
