import { Button, Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Api from "../../../../Api";

const CompanyLogo = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState([]);
  const navigate = useNavigate();

  function createdAtSorter(a, b) {
    if (a.createdAt < b.createdAt) {
      return -1;
    }
    if (a.createdAt > b.createdAt) {
      return 1;
    }
    return 0;
  }
  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
    },
    {
      title: "Image",
      dataIndex: "companyLogo",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div>
          <Button
            type=""
            className="data-list-button"
            onClick={() =>
              navigate(`/admin/Companylist/details/${record?._id}`)
            }
          >
            View Details
          </Button>
        </div>
      ),
    },
  ];

  const getData = async () => {
    await Api.get("ourprofile/getAll").then((res) => {
      setData(res.data.data);
    });
  };
  useEffect(() => {
    getData();
    const results = data.filter(
      (item) =>
        item?.instituteName
          ?.toLowerCase()
          .includes(searchText.toLowerCase().trim()) ||
        item?.RegistrationId?.toLowerCase().includes(
          searchText.toLowerCase().trim()
        )
    );
    setSearch(results);
  }, [searchText]);

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  let dataCount = data.length;

  return (
    <div>
      <div className="data-list-main">
        <h4 className="data-list-header pages-title">Institute List</h4>
        <h3 className="pages-title">Total Records: {dataCount}</h3>
        <div
          className="data-list-top mt-4 py-4 px-3"
          style={{ backgroundColor: "#c5c5c5" }}
        >
          <Input
            className="data-list-search ps-2"
            placeholder="search by college name or Registration Id"
            onChange={handleChange}
            enterButton
          />
          <Button
            className="data-list-button"
            onClick={() => navigate("/admin/companylogo/approvedlist")}
          >
            Approved Companies
          </Button>
        </div>

        {searchText ? (
          <div className=".Datalist-Table">
            <Table
              columns={columns}
              dataSource={search}
              // pagination={false}
              className="mt-2"
            />
          </div>
        ) : (
          <div className=".Datalist-Table">
            <Table
              columns={columns}
              dataSource={data}
              // pagination={false}
              className="mt-2"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyLogo;
