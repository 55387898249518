import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { BiRupee, BiTimeFive } from "react-icons/bi";
import { CiCalendarDate, CiLocationOn } from "react-icons/ci";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { TfiBag } from "react-icons/tfi";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../../Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  // Import CSS for toast notifications

function Viewprojects() {
  const navigate = useNavigate();
  const view = useLocation();
  const data = view.state;

  const userEmail = localStorage.getItem("email");
  const UserId = localStorage.getItem("userId");
  const id = data.projectID;
  const name = view.state.role;
  const title = view.state.projectTitle;
  const email = view.state.email.email;
  const projectID = view.state.projectID;
  const duration = view.state.duration;
  const languages = view.state.languages;
  const mode = view.state.mode;
  const qualification = view.state.qualification;
  const skills = view.state.skills;

  const [buttonText, setButtonText] = useState("Apply");

  const onSubmit = async (e) => {
    e.preventDefault();
    const details = {
      role: name,
      UserID: UserId,
      projectID: projectID,
      projectTitle: title,
      email: email,
      userEmail: userEmail,
      duration: duration,
      languages: languages,
      mode: mode,
      qualification: qualification,
      skills: skills,
    };
    try {
      const res = await Api.post("blog/requestCreate", details);
      console.log("res", res);
      toast.success("Successfully applied for the project!");
      setButtonText("Applied");
    } catch (error) {
      console.error("Error applying for the project", error);
      toast.error("Failed to apply for the project. Please try again.");
    }
  };

  return (
    <div>
      <div className="ms-4">
        <Row className="p-3">
          <Col>
            <h3 className="mt-3">{title}</h3>

            <div className="ms-4 p-2">
              <h6>
                <CiCalendarDate /> &nbsp;{duration}
              </h6>
              <h6>
                <TfiBag /> &nbsp;{qualification}
              </h6>
              <h6>
                <AiOutlineUnorderedList />
                &nbsp;&nbsp;
                {languages}
              </h6>
              <h6>
                <AiOutlineUnorderedList /> &nbsp;{skills}
              </h6>
              <h6>
                <BiTimeFive /> &nbsp;{mode}
              </h6>
            </div>
          </Col>
        </Row>

        <br />
        <div className="d-flex mt-3 mb-2">
          <button
            className="button2"
            onClick={() => navigate("/student/request")}
          >
            Back
          </button>
          <button
            className="button1 ms-5"
            onClick={onSubmit}
            disabled={buttonText === "Applied"}  // Disable the button if already applied
          >
            {buttonText}
          </button>
          <ToastContainer style={{width:"400px",height:"200px",marginTop:"50px"}}/>
        </div>
      </div>
    </div>
  );
}

export default Viewprojects;
