import React from "react";
import Chatbox from "../../Chat/Chat.jsx";

function Chat() {
  return (
    <div style={{ overflow: "hidden" }}>
      <Chatbox />
    </div>
  );
}

export default Chat;
