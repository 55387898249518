import React from "react";
import "../../../Components/Css/Employer/projectpost.scss";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Api from "../../../Api";
import { ToastContainer, toast } from 'react-toastify'; // Import Toast components
import 'react-toastify/dist/ReactToastify.css'; // Import Toast styles

function CategoryAdded() {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const handleCreateFormSubmit = async () => {
    const userDetails = {
      categoryName: getValues().categoryName,
    };

    try {
      const resp = await Api.post(`vendor/createCategory`, userDetails);
      console.log("resp.data", resp.data);
      toast.success("Category created successfully!"); // Show success toast
      reset(); // Reset the form after successful submission
    } catch (error) {
      console.error("Error submitting the form:", error);
      toast.error("Failed to create category. Please try again."); // Show error toast
    }
  };

  return (
    <div>
      <Container className="content-style">
        <Row style={{ marginLeft: "5%" }}>
          <form onSubmit={handleSubmit(handleCreateFormSubmit)}>
            <br />
            <div className="pages-title mt-3">Create Category:</div>
            <Row className="mt-4" style={{ justifyContent: "flex-start", marginLeft: "5%" }}>
              <Col sm={12} md={4}>
                <label className="create-title">Category Name</label>
                <br />
                <input
                  {...register("categoryName", { required: true })}
                  className="Create-input"
                />
                {errors.categoryName && (
                  <p className="error-text-color">Category Name is required</p>
                )}
              </Col>
            </Row>
            <br />
            <div className="submitbuttons p-2">
              <button className="button1 m-2 p-2" type="submit">
                Submit
              </button>
              <button className="button2 m-2 p-2" type="reset">
                Reset
              </button>
            </div>
          </form>
          <ToastContainer position="top-right" autoClose={3000} /> {/* Add ToastContainer */}
        </Row>
      </Container>
    </div>
  );
}

export default CategoryAdded;