import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "antd";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import "../../../Components/Css/Students/Studintdetails.scss";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";
import { LuClock4 } from "react-icons/lu";
import { FaAnglesRight } from "react-icons/fa6";

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = eventKey === activeEventKey;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#EBE6FF",
        paddingTop: "12px",
      }}
    >
      <p onClick={decoratedOnClick}>
        {children}
        {isCurrentEventKey ? <FiChevronUp /> : <FiChevronDown />}
      </p>
    </div>
  );
}

function Request() {
  const [createForm, setcreateForm] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [durationFilter, setDurationFilter] = useState('');
  const [languageFilter, setLanguageFilter] = useState('');
  const [modeFilter, setModeFilter] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    getCreateForm();
  }, []);

  const handleSearch = value => {
    setSearchQuery(value);
  };

  const handleDurationChange = event => {
    setDurationFilter(event.target.value);
  };

  const handleLanguageChange = event => {
    setLanguageFilter(event.target.value);
  };

  const handleModeChange = event => {
    setModeFilter(event.target.value);
  };

  const getCreateForm = async () => {
    await Api.get("projectpost/get_project_post").then((res) => {
      setcreateForm(res.data.data);
    });
  };

  const filteredData = createForm.filter(data => {
    return (
      (searchQuery === '' || data.projectTitle.toLowerCase().includes(searchQuery.toLowerCase())) &&
      (durationFilter === '' || data.duration === durationFilter) &&
      (languageFilter === '' || data.languages.includes(languageFilter)) &&
      (modeFilter === '' || data.mode === modeFilter)
    );
  });

  const handleSubmit = (data) => {
    navigate("viewproject", { state: data });
  };

  return (
    <div className="p-4">
      <div className="pages-title">Requests</div>
      <Row className="p-3">
        <Col className="filter-col">
          <Card className="filter-request">
            <div className="Dilter mt-0">
              <div className="filter-title">Filter with..</div>
              <div className="ms-4 mb-5">
                <label className="mt-4">Project Title</label>
                <br />
                <input
                  className="filter_input"
                  type="search"
                  onChange={e => handleSearch(e.target.value)}
                  placeholder="search..."
                />
                <br />
                <label className="mt-4">Duration</label>
                <br />
                <select className="filter_input" onChange={handleDurationChange}>
                  <option value="">Duration</option>
                  <option value="5 months">5 months</option>
                  <option value="6 months">6 months</option>
                  <option value="7 months">7 months</option>
                  <option value="8 months">8 months</option>
                </select>
                <br />
                <label className="mt-4">Language</label>
                <br />
                <select className="filter_input" onChange={handleLanguageChange}>
                  <option value="">Language</option>
                  <option value="Tamil">Tamil</option>
                  <option value="Malayalam">Malayalam</option>
                  <option value="Telugu">Telugu</option>
                  <option value="English">Hindi</option>
                  <option value="English">English</option>
                </select>
                <br />
                <label className="mt-4">Mode</label>
                <br />
                <select className="filter_input" onChange={handleModeChange}>
                  <option value="">Mode</option>
                  <option value="Work From Office">Work From Office</option>
                  <option value="Remote">Remote</option>
                </select>
              </div>
            </div>
          </Card>
        </Col>
        <Col className="cards-col">
          <div className="cards-container">
            {filteredData.map((data, i) => (
              <Card
                className="Int-card"
                onClick={() => handleSubmit(data)} key={i}>
                <Card.Header className="crd-size">
                  <h4 className="Int-title">{data.projectTitle}</h4>
                  </Card.Header>
                  <Card.Body>
                  <p className="Int-para1">
                    <LuClock4 /> &nbsp;{data.duration}
                  </p>
                  <p className="Int-para2">
                    Skills: {data.skills}
                  </p>
                  <p className="Int-para2">
                    Qualification: {data.qualification}
                  </p>
                  <p className="Int-para2">
                    Languages: {data.languages}
                  </p>
                  <p className="Int-para2">
                    <b>Mode:</b> {data.mode}
                  </p>
                  </Card.Body>
                  <Card.Footer className="request-footer">
                  
                  <button className="view-left"  >
                      View Details <FaAnglesRight />{" "}
                    </button>
                  </Card.Footer>
                  <Card.Text>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <p>{data.description}</p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card.Text>
              </Card>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Request;
