import React from "react";

import "../../../Components/Css/Students/MenuEvents.scss";

function MenuEvent() {
  return (
    <>
      <div className="even">
        <p > Events </p>
      </div>
    </>
  );
}
export default MenuEvent;