import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Select, message } from 'antd';
import Api from "../../../Api.js";

const { Option } = Select;

const CreateExamQuestion = () => {
  const [technologies, setTechnologies] = useState([]);
  const [options, setOptions] = useState(['', '', '', '']);

  useEffect(() => {
    // Fetch technologies from the backend
    const fetchTechnologies = async () => {
      try {
        const response = await Api.get('exam/getalltechnology'); // Adjust the endpoint as needed
        console.log(response.data.tech_name); // Check the response data
        setTechnologies(response.data);
      } catch (error) {
        message.error('Failed to load technologies');
      }
    };

    fetchTechnologies();
  }, []); // Add an empty dependency array to run once on mount

  const onFinishExamQuestion = (values) => {
    Api.post('/exam/createquestion', values)
      .then(() => message.success('Exam question created successfully'))
      .catch(() => message.error('Failed to create exam question'));
  };

  return (
    <Form layout="vertical" onFinish={onFinishExamQuestion}>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label="Technology" name="technologyId" rules={[{ required: true }]}>
            <Select placeholder="Select technology">
              {technologies.map(tech => (
                <Option key={tech._id} value={tech._id}>{tech.tech_name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Week" name="week" rules={[{ required: true }]}>
            <Input placeholder="Week number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Question Text" name="questionText" rules={[{ required: true }]}>
            <Input placeholder="Enter exam question" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        {options.map((_, index) => (
          <Col span={6} key={index}>
            <Form.Item label={`Option ${index + 1}`} name={['options', index]} rules={[{ required: true }]}>
              <Input placeholder={`Enter option ${index + 1}`} />
            </Form.Item>
          </Col>
        ))}
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label="Correct Option" name="correctOption" rules={[{ required: true }]}>
            <Input type="number" placeholder="Correct option index (0-3)" />
          </Form.Item>
        </Col>
      </Row>
      <Button type="primary" htmlType="submit">Create Exam Question</Button>
    </Form>
  );
};

export default CreateExamQuestion;
