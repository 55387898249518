import React, { useState, useEffect, useContext } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { HiUserCircle } from "react-icons/hi";
import { Switch } from 'antd';
import logo from '../../Images/Careerblitz logo.png';
import "../Css/HeaderNavbar.scss";
import { ThemeContext } from "../../HomePageContent/ThemeContext";

const HeaderNavbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const { themeKey, changeTheme } = useContext(ThemeContext);

  const handleThemeChange = (checked) => {
    changeTheme(checked ? 'dark' : 'light');
  };

  const role = localStorage.getItem("role");
  const auth = localStorage.getItem("USER_AUTH_STATE");
  const companyName = localStorage.getItem("companyName");

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.pageYOffset !== 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const logoutHandler = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div className={`navbar-container ${isScrolled ? 'scrolled' : ''}`}>
      <nav className="navbar desktop-navbar">
        <div className="brand">
          <Link to="/">
            <img className="logo" src={logo} alt="Company Logo" />
          </Link>
        </div>
        <div className="nav-links">
          <NavLink to="/aboutus" className="nav-hover-link">About Us</NavLink>
          <NavLink to="/contactus" className="nav-hover-link">Contact Us</NavLink>
          <NavLink to="/courses" className="nav-hover-link">Courses</NavLink>
        </div>

        <div className="auth-buttons">
          <div className="theme-select">
            <Switch size="large"
              // defaultChecked
              checkedChildren={<b>Dark </b>}
              unCheckedChildren={<b>Light </b>}
              checked={themeKey === "dark"}
              onChange={handleThemeChange}
            />
          </div>
          {!auth ? (
            <>
              <Link to="/signup"><button className="btn">Sign Up</button></Link>
              <Link to="/login"><button className="btn primary">Log In</button></Link>
            </>
          ) : (
            <div className="user-icon">
              <HiUserCircle
                style={{ fontSize: 40, cursor: "pointer", color: "grey" }}
                onClick={() => setIsOpen(!isOpen)}
              />
              {isOpen && (
                <div className="dropdown-menu">
                  <Link to={`/${role}${role === "employer" ? `/${companyName}` : ""}`} onClick={() => setIsOpen(false)}>Dashboard</Link>
                  <hr />
                  <Link to="#" onClick={logoutHandler}>Log Out</Link>
                </div>
              )}
            </div>
          )}

        </div>

      </nav>

      <nav className="navbar mobile-navbar">
        <div className="brand">
          <Link to="/">
            <img className="logo" src={logo} alt="Company Logo" />
          </Link>
        </div>
        <button className="hamburger" onClick={() => setIsOpen(!isOpen)}>
          &#9776;
        </button>
        {isOpen && (
          <div className={`offcanvas ${isOpen ? 'show' : ''}`}>
            <div className="nav-links">
              <NavLink to="/aboutus" className="nav-link" onClick={() => setIsOpen(false)}>About Us</NavLink>
              <NavLink to="/contactus" className="nav-link" onClick={() => setIsOpen(false)}>Contact Us</NavLink>
              <NavLink to="/courses" className="nav-link" onClick={() => setIsOpen(false)}>Courses</NavLink>
              {!auth ? (
                <>
                  <Link to="/signup/Student" onClick={() => setIsOpen(false)}><button className="btn">Sign Up</button></Link>
                  <Link to="/login" onClick={() => setIsOpen(false)}><button className="btn primary">Log In</button></Link>
                </>
              ) : (
                <div className="auth-links">
                  <Link to={`/${role}${role === "employer" ? `/${companyName}` : ""}`} onClick={() => setIsOpen(false)}>
                    <button className="btn">Dashboard</button>
                  </Link>
                  <button className="btn logout" onClick={logoutHandler}>Log Out</button>
                </div>
              )}
            </div>
            <button className="close-button" onClick={() => setIsOpen(false)}>Close</button>
          </div>
        )}
      </nav>
    </div>
  );
};

export default HeaderNavbar;
