import React, { useEffect, useState } from "react";
import Api from "../Api";
import "./counsellorCarousel.css";
import Layout from "../Components/Layout";

function CounsellorLandingPage() {
  const [counsellors, setCounsellors] = useState([]);

  useEffect(() => {
    const fetchCounsellors = async () => {
      try {
        const response = await Api.get("trainer/Trainerget");
        setCounsellors(response.data.data);
      } catch (error) {
        console.error("Error fetching counsellor data:", error);
      }
    };
    fetchCounsellors();
  }, []);

  return (
    <Layout>
      <div>
        <h1 className="text-center my-4" style={{ fontWeight: "500" }}>
          All Counsellors
        </h1>
        <div className="counsellor-card-container-landing">
          {counsellors.map((counsellor) => (
            <div className="counsellor-card" key={counsellor._id}>
              <img
                src={counsellor.profilePhoto}
                alt={`${counsellor.firstName} ${counsellor.lastName}`}
                className="counsellor-card-img"
              />
              <div className="counsellor-card-body">
                <h5 className="counsellor-card-title">
                  {counsellor.firstName}
                </h5>
                <p className="counsellor-card-text">
                  <strong>Experience:</strong>{" "}
                  {counsellor.experience || "5 Years"}
                </p>
                <button className="counsellor-chat-button">Chat</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}

export default CounsellorLandingPage;
