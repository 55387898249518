import React, { useState } from "react";
import { Input, Button, Card, Row, Col, Rate, Avatar } from "antd";
import { BookOutlined } from "@ant-design/icons";
import { CiBookmark } from "react-icons/ci";
import { IoSearch } from "react-icons/io5";
import "./Courses.css";
import Footer from "../Components/Footer";
import HeaderNavBar from "../Components/HeaderNavbar/index";

const { Search } = Input;

// Dummy data for course cards
const coursesData = [
  {
    title: "React Front To Back",
    lessons: 12,
    students: 50,
    price: 60,
    originalPrice: 120,
    image:
      "https://rainbowit.net/html/histudy/assets/images/course/language-academy-01.jpg",
    reviews: 15,
    rating: 4.5,
    author: "Angela",
    category: "Development",
    discount: "40%",
    descriptions:
      "It is a long established fact that a reader will be distracted.",
    profileImage: "https://randomuser.me/api/portraits/women/44.jpg", // Added profile image
  },
  {
    title: "Angular Zero to Mastery",
    lessons: 8,
    students: 30,
    price: 80,
    originalPrice: 100,
    image:
      "https://rainbowit.net/html/histudy/assets/images/course/language-academy-02.jpg",
    reviews: 5,
    rating: 3.5,
    author: "Slaughter",
    category: "Languages",
    discount: "40%",
    descriptions: "Master Angular from scratch with this comprehensive course.",
    profileImage: "https://randomuser.me/api/portraits/men/32.jpg",
  },
  {
    title: "Complete Python Bootcamp",
    lessons: 15,
    students: 120,
    price: 100,
    originalPrice: 150,
    image:
      "https://rainbowit.net/html/histudy/assets/images/course/language-academy-04.jpg",
    reviews: 50,
    rating: 4.8,
    author: "Jane Smith",
    category: "Programming",
    discount: "30%",
    descriptions:
      "Learn Python from basics to advanced concepts in this extensive course.",
    profileImage: "https://randomuser.me/api/portraits/women/32.jpg",
  },
  {
    title: "UX/UI Design Fundamentals",
    lessons: 20,
    students: 85,
    price: 70,
    originalPrice: 140,
    image:
      "https://rainbowit.net/html/histudy/assets/images/course/language-academy-05.jpg",
    reviews: 25,
    rating: 4.3,
    author: "Emily Thompson",
    category: "Design",
    discount: "50%",
    descriptions:
      "Master the essentials of UX/UI design and create user-friendly experiences.",
    profileImage: "https://randomuser.me/api/portraits/women/58.jpg",
  },
  {
    title: "Data Science & Machine Learning",
    lessons: 18,
    students: 95,
    price: 120,
    originalPrice: 200,
    image:
      "https://rainbowit.net/html/histudy/assets/images/course/language-academy-06.jpg",
    reviews: 35,
    rating: 4.7,
    author: "Dr. Alan Turing",
    category: "Data Science",
    discount: "40%",
    descriptions:
      "Dive into the world of data science and machine learning with hands-on projects.",
    profileImage: "https://randomuser.me/api/portraits/men/47.jpg",
  },
  {
    title: "Angular Zero to Mastery",
    lessons: 8,
    students: 30,
    price: 80,
    originalPrice: 100,
    image:
      "https://rainbowit.net/html/histudy/assets/images/course/language-academy-02.jpg",
    reviews: 5,
    rating: 3.5,
    author: "Slaughter",
    category: "Languages",
    discount: "40%",
    descriptions: "Master Angular from scratch with this comprehensive course.",
    profileImage: "https://randomuser.me/api/portraits/men/32.jpg", // Added profile image
  },
];

const Courses = () => {
  const [filteredCourses, setFilteredCourses] = useState(coursesData);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (value) => {
    const filtered = coursesData.filter((course) =>
      course.title.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCourses(filtered);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const handleSearchClick = () => {
    handleSearch(searchTerm);
  };

  return (
    <div className="page-container">
      <HeaderNavBar />
      <div className="slide-section">
        <h1>All Courses</h1>
        <p>Courses that help beginner designers become true unicorns.</p>

        <div className="course-filter-section">
          <Input
            type="text"
            size="small"
            placeholder="Search your course..."
            value={searchTerm}
            onChange={handleInputChange}
            className="simple-search-input"
          />
          <Button
            type="primary"
            size="large"
            onClick={handleSearchClick}
            className="simple-search-btn"
          >
            <IoSearch />
          </Button>
          <Button type="primary">Enroll Now</Button>
        </div>
      </div>

      <div className="course-cards-container">
        <Row gutter={[24, 24]} className="course-cards-row">
          {filteredCourses.map((course, index) => (
            <Col
              style={{ padding: "20px" }}
              key={index}
              xs={24}
              sm={12}
              md={12}
              lg={8}
            >
              <Card
                style={{ height: "fit-content", padding: "25px" }}
                hoverable
                className="course-card"
                cover={
                  <img
                    alt={course.title}
                    src={course.image}
                    className="course-card-image"
                  />
                }
              >
                <div className="card-header">
                  <Rate
                    allowHalf
                    style={{ fontSize: "20px" }}
                    defaultValue={course.rating}
                    disabled
                  />
                  <span>({course.reviews} Reviews)</span>
                  <CiBookmark className="bookmark-icon" />
                </div>
                <Card.Meta
                  title={course.title}
                  description={
                    <>
                      <p>
                        {course.lessons} Lessons | {course.students} Students
                      </p>
                      <p>{course.descriptions}</p>{" "}
                      <div className="author-info">
                        <Avatar
                          src={course.profileImage}
                          size="small"
                          className="course-card-profileimg"
                        />{" "}
                        <span>
                          By {course.author} in {course.category}
                        </span>
                      </div>
                      <p>
                        <strong>${course.price}</strong>{" "}
                        <del>${course.originalPrice}</del>{" "}
                        <span>({course.discount} Off)</span>
                      </p>
                      <Button type="primary" className="learn-more-btn">
                        Learn More
                      </Button>
                    </>
                  }
                />
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default Courses;
