import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Api from "../../../../../Api";
import "../FreelancingCarouselAdmin/FreelancingCarouselAdmin.css";

const FreelancingCarouselAdmin = () => {
  const [Image, setimage] = useState({});
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  // const postData = () => {
  //   const data = new FormData();
  //   data.append("resume", getValues().resume[0]);
  //   Api.post(
  //     "/FreelancingCarousel/FreelancingCarouselPostImage",
  //     data
  //   ).then((res) => {});
  // };

  const onSubmit = (data) => {
    handleCompanySubmit();
  };

  const [resres, setResres] = useState({
    status: null,
    message: null,
  });
  const navigate = useNavigate();

  const handleCompanySubmit = async () => {
    const CompanyDetails = {
      title: getValues().title,
    };
    const data = new FormData();
    data.append("resume", getValues().resume[0]);

    await Api.post(
      "/FreelancingCarousel/FreelancingCarouselPostImage",
      data
    ).then(async (res) => {
      if (res.status == 201) {
        CompanyDetails.resume = res.data.path;
        await Api.post(
          `/FreelancingCarousel/FreelancingCarouselPost`,
          CompanyDetails
        )
          .then((res) => {
            setResres({
              status: res.data?.status,
              message: res.data?.message,
            });
            localStorage.setItem("userId", res.data.data._id);
            setTimeout(() => {
              // navigate("/admin/Companylist");
            }, 700);
          })
          .catch((err) => {
            setResres({
              status: err?.response.data?.status,
              message: err?.response.data?.message,
            });
          });
      }
    });
  };

  // const CompanyDetails = {
  //   title: getValues().title,
  // };
  // console.log("getValues()", getValues());

  // const handleCompanySubmit = async () => {
  //   const data = new FormData();
  //   data.append("resume", getValues().resume[0]);
  //   await Api.post(
  //     "/FreelancingCarousel/FreelancingCarouselPostImage",
  //     data
  //   ).then(async (res) => {
  //     if (res.status == 201) {
  //       CompanyDetails.resume = res.data.path;
  //       await Api.post(
  //         `/FreelancingCarousel/FreelancingCarouselPost`,
  //         CompanyDetails
  //       )
  //         .then((res) => {})
  //         .catch((err) => {});
  //     }
  //   });
  // };

  // const getFormData = (data, image) => {
  //   const formData = new FormData();
  //   formData.append("image", image);

  //   return formData;
  // };
  return (
    <div>
      <div className="card_container_freelancingAdmin">
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="container forncontent  p-3"
        >
          <Form.Group className="mb-3">
            <div>
              <Form.Label className="pe-5">Blog Title</Form.Label>
              <br />
              <input
                style={{ width: "45%", outline: "none" }}
                {...register("title", { required: true })}
              />
              <br />
              {errors.title && (
                <span className="Company-error"> Title required</span>
              )}
            </div>

            <Form.Label>Blog Image</Form.Label>
            <Form.Control
              accept=".jpg"
              type="file"
              {...register("resume")}
              style={{ width: "45%", outline: "none" }}
            />
            {errors.resume && (
              <span className="Company-error">image required</span>
            )}
          </Form.Group>
          <button type="submit" className="carouselAdminButton">
            Submit
          </button>
        </Form>
      </div>
    </div>
  );
};

export default FreelancingCarouselAdmin;
