import React, { useEffect, useState } from 'react';
import { GoLocation } from 'react-icons/go';
import { useParams } from 'react-router-dom';
import Api from '../../../../Api';

const InstituteDetails = () => {

    const [data, setData] = useState([])
    const { id } = useParams()

    const getData = () => {
        Api.get(`institute/institute_get/${id}`).then((res) => {
            setData(res.data);

        });
    };
    useEffect(() => {
        getData()
    }, [])

    return (
        <div>
            <div className="viewseminar_container p-4">
                <div className="pages-title mb-3">College Details</div>
                <div className="seminar_card">
                    <div className="flex padcheck">
                        <div>
                            <div className="inner_container_wrap">
                                <div className="seminar_card_date">
                                    <span className="pages-title">{data.instituteName}</span>
                                </div>
                                <h5 className="seminar_card_title">Website:</h5>
                                <h6 className="seminar_card_title">{data.instituteType}</h6>
                                <h5 className="seminar_card_title">Established:</h5>
                                <h6 className="seminar_card_title">{data.establishedYear}</h6>
                                <div>
                                    <h5 className="card_company">Services</h5>
                                    <h5> 1. {data.service}</h5>
                                </div>

                                <div>
                                    <h4 className="seminar_card_title">Address Details:</h4>
                                    <h6 className="seminar_card_title">  <GoLocation /> {data.country},{data.state},{data.district}</h6>
                                    <h6 className="seminar_card_title">{data.address},{data.pincode}</h6>
                                </div>
                                {/* <div className="inner_container_wrap_flex">
                  <div className="seminar_card_date_wrapper flex">
                    <p className="seminar_card_technlogies">
                      <span>react</span>
                      <span>node</span>
                    </p>
                  </div>
                </div> */}
                                {/* <p className="seminar_card_brief_des">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugiat,
                  incidunt.
                </p> */}
                                <div className="flex">
                                    <div className="seminar_card_details">
                                        {/* <p className="seminar_card_access">Access :Open for all</p> */}
                                        {/* <p className="seminar_card_mode">Mode :Online</p> */}
                                    </div>
                                    <div className="seminar_card_contact">
                                        <p className="seminar_card_name">Contact person :{data.employerName}</p>
                                        <p className="seminar_card_name">Email Address :{data.email}</p>
                                        <p className="seminar_card_number">
                                            Contact number :{data.phone}
                                        </p>
                                    </div>
                                </div>
                                {/* <p className="seminar_card_link">
                                    Registration Link :https://www.adada.com
                                </p> */}
                                <div className="seminar_card_button_wrapper">

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default InstituteDetails;