import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Button } from "antd";
// import "../../../Components/Css/professionalstyle.scss";
import "../../../Components/Css/Professional/professionalstyle.scss";
import { Dropdown } from "primereact/dropdown";
import Api from "../../../Api.js";


function MyProfile() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({});
  const [details, setDetails] = useState();
  const [countryList, setcountryList] = useState([]);
  const [countryValue, setCountryValue] = useState();
  const [stateList, setstateList] = useState([]);
  const [stateValue, setStateValue] = useState();
  const [districtList, setdistrictList] = useState([]);
  const [districtValue, setDistrictValue] = useState();
  const [genderList, setGenderList] = useState();
  const [gender, setGender] = useState();
  const [maritalList, setMaritalList] =useState();
  const[Marital,setMarital] =useState();

  useEffect(() => {
    getCountry();
    getGender();
    getMarital();
  }, []);

  const getMarital= async()=>{
    await Api.get("marital/getMarital").then((res)=>{
      setMaritalList(res.data.data);
    });
  };

  const getGender= async()=>{
    await Api.get("gender/getGenderlist").then((res)=>{
      setGenderList(res.data.data);
    });
  };
  const getCountry = async () => {
    await Api.get("country/getallcountry").then((res) => {
      setcountryList(res.data.data);
    });
  };

  const getState = (country_id) => {
    console.log("stateList", country_id);
    setCountryValue(country_id);
    Api.get(`state/stateById/${country_id}`).then((res) => {
      setstateList(res.data.data);
    });
  };

  const getDistrict = (state_id) => {
    setStateValue(state_id);
    Api.get(`district/districtById/${state_id}`).then((res) => {
      setdistrictList(res.data.data);
    });
  };
  const [file, setFile] = useState();

  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    // setFile(URL.createObjectURL(e.target.files[0]));
  }
  return (
    <div> <form onSubmit={handleSubmit()}>
    <div className="personal-informations p-4">
      <div className="pages-title mb-3">Personal Info</div>
      <Row className="mb-4">
        <Col sm={24} md={24} xs={8} className="px-4">
          <div>
            <img
              src={file}
              style={{
                width: "200px",
                height: "200px",
              }}
            />
          </div>
        </Col>
        <Col sm={24} md={8} xs={24} className="px-4">
          <br />
          <label className="input-title">Upload Photo</label>
          <br />
          <input type="file" onChange={handleChange} />
          {errors.photo && (
            <span className="input-error">Photo is required</span>
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={24} md={8} xs={24} className="px-4">
          <label className="input-title">First Name</label>
          <br />
          <input
            className="Professional__UpdateProfile__Input"
            {...register("firstname", { required: true })}
          />
<br/>
          {errors.firstname && (
            <span className="input-error">First Name required</span>
          )}
        </Col>
        <Col sm={24} md={8} xs={24} className="px-4">
          <label className="input-title">Last Name</label> <br />
          <input
            className="Professional__UpdateProfile__Input"
            {...register("lastname", { required: true })}
          /><br/>
          {errors.lastname && (
            <span className="input-error">Last Name required</span>
          )}
        </Col>
        <Col sm={24} md={8} xs={24} className="px-4">
          <label className="input-title">Phone Number</label> <br />
          <input
            className="Professional__UpdateProfile__Input"
            {...register("phoneNumber", { required: true })}
          />
          {errors.phoneNumber && (
            <span className="input-error"> Number required</span>
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={24} md={8} xs={24} className="px-4">
          <label className="input-title">Date of Birth </label>
          <input
            className="Professional__UpdateProfile__Input"
            type="date"
            {...register("dateofBirth", { required: true })}
          /><br/>
          {errors.dateofBirth && (
            <span className="input-error">DOB required</span>
          )}
        </Col>

        <Col sm={24} md={8} xs={24} className="px-4">
          <label className="input-title">Gender </label>
          <Dropdown
            className="input-field"
            value={gender}
            options={genderList}
            {...register("gender", { required: true })}
            optionLabel={"gender"}
            optionValue={"_id"}
            onChange={(e) => setGender(e.value)}
            placeholder="Select a Gender"
          /><br/>
          {errors.gender && (
            <span className="input-error">Gender required</span>
          )}
        </Col>

        <Col sm={24} md={8} xs={24} className="px-4">
          <label className="input-title">Marital status </label>
          <Dropdown
            className="Professional__UpdateProfile__Input"
            value={Marital}
            options={maritalList}
            {...register("maritalstatus", { required: true })}
            optionLabel={"Marital"}
            optionValue={"_id"}
            onChange={(e) => setMarital(e.value)}
            placeholder="Select a Marital status"
          /><br/>
          {/* <select
            className="Professional__UpdateProfile__Input"
            {...register("maritalstatus", { required: true })}
          >
            <option value="">select </option>
            <option value="a">Married</option>
            <option value="b">Unmarried</option>
            <option value="c">divorced</option>
          </select> */}
          {errors.maritalstatus && (
            <span className="input-error">status required</span>
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={24} md={8} xs={24} className="px-4">
          <label className="input-title">Passport Number</label>
          <br />
          <input
            className="Professional__UpdateProfile__Input"
            {...register("passport", { required: true })}
          />
<br/>
          {errors.passport && (
            <span className="input-error">Passport Number required</span>
          )}
        </Col>
        <Col sm={24} md={8} xs={24} className="px-4">
          <label className="input-title">Issused From</label> <br />
          <input type="date"
            className="Professional__UpdateProfile__Input"
            {...register("issuse", { required: true })}
          /><br/>
          {errors.issuse && (
            <span className="input-error">Issused date required</span>
          )}
        </Col>
        <Col sm={24} md={8} xs={24} className="px-4">
          <label className="input-title">Expriy Date</label> <br />
          <input type="date"
            className="Professional__UpdateProfile__Input"
            {...register("expriy", { required: true })}
          />
          {errors.expriy && (
            <span className="input-error"> Expriy Date required</span>
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={24} md={8} xs={24} className="px-4">
          <label className="input-title">Email Id </label>
          <input
            className="Professional__UpdateProfile__Input"
            {...register("email", { required: true })}
          />
<br/>
          {errors.email && (
            <span className="input-error">Email required</span>
          )}
        </Col>
        <Col sm={24} md={8} xs={24} className="px-4">
          <label className="input-title">Address</label>
          <textarea
            rows="1"
            className="Professional__UpdateProfile__Input"
            {...register("address", { required: true })}
          />

          {errors.address && (
            <span className="input-error">Address required</span>
          )}
        </Col>

        <Col sm={24} md={8} xs={24} className="px-4">
          <label className="input-title">Country </label>
          <Dropdown
            filter
            className="input-field"
            value={countryValue}
            options={countryList}
            {...register("country", { required: true })}
            optionLabel={"name"}
            optionValue={"id"}
            onChange={(e) => getState(e.value)}
          />
          {errors.country && (
            <p className="error-text-color">country is required</p>
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={24} md={8} xs={24} className="px-4">
          <label className="input-title">State</label> <br></br>
          <Dropdown
            className="input-field p-0"
            value={stateValue}
            options={stateList}
            {...register("stateValue", { required: true })}
            optionLabel={"stateName"}
            optionValue={"_id"}
            onChange={(e) => getDistrict(e.value)}
            placeholder="Select a State"
          /><br/>
          {errors.stateValue && (
            <span className="input-error">State is required</span>
          )}
        </Col>

        <Col sm={24} md={8} xs={24} className="px-4">
          <label className="input-title">District</label>

          <Dropdown
            className="input-field p-0"
            value={districtValue}
            options={districtList}
            {...register("districtValue", { required: true })}
            optionLabel={"districtName"}
            optionValue={"_id"}
            onChange={(e) => setDistrictValue(e.value)}
            placeholder="Select a District"
          />
          <br />
          {errors.districtValue && (
            <span className="input-error">District is required</span>
          )}
        </Col>
        {/* <Col sm={24} md={8} xs={24} className="px-4">
          <label className="input-title">Upload Resume</label>
          <br />
          <input
            type="file"
            className="Professional__UpdateProfile__Input"
            onChange={handleChange}
            {...register("resume", { required: true })}
          />

          {errors.resume && (
            <span className="input-error">Resume is required</span>
          )}
        </Col> */}
      </Row>

      <div className="submitbuttons px-4">
        <button className="button1 m-2 p-2" type="submit">
          Submit
        </button>
        <button className="button2 m-2 p-2" type="reset">
          Reset
        </button>
      </div>
    </div>
  </form></div>
  )
}

export default MyProfile