import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import Layout from '../Components/Layout';

const RefundPolicy = () => {
    return (
        <Layout>
        <Container className="mt-5">
            <h1 className="text-center mb-4">Refund Policy</h1>
            <Card>
                <Card.Body>
                    <h4 className="mb-3 text-primary"><FaCheckCircle /> Eligibility for Refunds</h4>
                    <p>If you experience double entry or payment failure due to our error, report it to us. We will investigate and resolve the issue within 48 hours, and provide a refund if applicable. Once the refund is approved by <strong>AROGANAM TECHNOLOGIES PVT LTD</strong>, it will take 8 to 10 working days to be credited to your account.</p>

                    <h4 className="mb-3 text-primary"><FaCheckCircle /> Refund Process</h4>
                    <p>To request a refund, please follow these steps:</p>
                    <ul>
                        <li>Provide proof of purchase.</li>
                        <li>Fill out the refund request form.</li>
                        <li>Return the product if applicable.</li>
                    </ul>
                    <p>Once approved, you can expect to receive your refund within 8 to 10 working days.</p>

                    <h4 className="mb-3 text-primary"><FaCheckCircle /> Note</h4>
                    <p>Refunds are only applicable for double payments or payment failures. In rare cases where duplicate payments or errors occur during the payment process, refunds may be considered on a case-by-case basis. This decision will be at the sole discretion of Aroganam.</p>

                    <h4 className="mb-3 text-primary"><FaCheckCircle /> Support Contact</h4>
                    <p>If you have any queries related to refunds or technical issues, please contact us at <a href="mailto:aroganamtech@gmail.com">aroganamtech@gmail.com</a>. We will assist you in resolving any issues, but refunds will not be granted outside the above-mentioned cases.</p>

                    <h4 className="mb-3 text-primary"><FaCheckCircle /> Policy Changes</h4>
                    <p>We reserve the right to modify or update this refund policy at any time without prior notice. Any changes will be effective from the date of publication on our website.</p>
                </Card.Body>
            </Card>
        </Container>
        </Layout>
    );
};

export default RefundPolicy;
