import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Api from "../../../Api";
import { Tabs } from "antd";
import "../../../Components/Css/Employer/CreateJobForm.scss";
import { AiOutlineSearch } from "react-icons/ai";
import { Space, Table } from "antd";
import InterviewPage from "./InterviewPage";
import JobListActions from "./JobListActions.js";
import DataListTable from "../../Admin/Dashboard/DataListTable.js";
import ApprovedForm from "./ApprovedForm";
import InterviewProcess from "./InterviewProcess";
import { useNavigate } from "react-router-dom";

function ScheduleForm() {
  const [InterviewList, setInterviewList] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [getData, setGetData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const btnStyles = { fontSize: "0.7rem" };
  const id = localStorage.getItem("id");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    getInterview();
    getList();
  }, []);

  const getList = () => {
    Api.get(`createForm/getForm/${id}`)
      .then((res) => {
        setGetData(res.data.data);
      })
      .catch((error) => console.log(error));
  };

  const getInterview = async () => {
    await Api.get("scheduleinterview/getAllinterview").then((res) => {
      setInterviewList(res.data.data);
    });
  };
  const navigate = useNavigate();
  const companyName = localStorage.getItem("companyName");

  const columns = [
    {
      title: "Role",
      dataIndex: "jobRole",
    },
    {
      title: "Job title",
      dataIndex: "jobTitle",
    },
    {
      title: "Technology",
      dataIndex: "technology",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <>
          {console.log("recorddddddddd", record)}
          <Button
            style={btnStyles}
            variant="primary"
            onClick={() =>
              navigate(`/college/form/ViewApplicants`, {
                state: record,
              })
            }
          >
            View Applicants
          </Button>
        </>
      ),
    },
  ];

  const filteredData = getData
    ? getData.filter((item) =>
        item.jobTitle.toLowerCase().includes(searchValue.toLowerCase())
      )
    : [];

  return (
    <div className="p-4">
      <form onSubmit={handleSubmit()}>
        <br />
        <div className="interivew-head">
          <div className="pages-title">Schedule Interview:</div>
          <div>
            {activeTab === "2" && (
              <>
                <AiOutlineSearch className="icon-tagSearch" />

                <input
                  placeholder="Search..."
                  className="inputSearchFields"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </>
            )}
            <Button>Post a Job</Button>
          </div>
        </div>
        <Row className="mt-4">
          <Col sm={12} mg={5} lg={12}>
            <h5>Interview</h5>
            <Tabs
              defaultActiveKey="1"
              onChange={handleTabChange} // Handle tab change
            >
              <Tabs.TabPane tab="Process " key="1">
                <InterviewProcess />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Upcoming " key="2">
                <DataListTable
                  showAddNewBtn={false}
                  columns={columns}
                  dataList={filteredData}
                />
              </Tabs.TabPane>
              &nbsp;
              <Tabs.TabPane tab="Approved " key="3">
                <ApprovedForm />
              </Tabs.TabPane>
              <Tabs.TabPane tab="View All " key="4">
                <InterviewPage />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default ScheduleForm;
