import {
  FILTER_BY_SEARCH,
  FILTER_SEARCH_BY_TYPE,
  FILTER_SEARCH_BY_STATE,
  FILTER_SEARCH_BY_DISTRICT,
  FILTER_SEARCH_BY_CITY,
  FILTER_SEARCH_BY_TECHNOLOGYOFF,
  FILTER_SEARCH_BY_TECHNOLOGYON,
  FILTER_SEARCH_BY_LANGUAGEON,
  FILTER_SEARCH_BY_LANGUAGEOFF,
} from "../type";

const initialState = {
  checker: false,
  type: "",
  country: "",
  state: "",
  district: "",
  city: "",
  technologyOn: "",
  languageOn: "",
  technologyOff: "",
  languageOff: "",
};

export const filterbySearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_SEARCH_BY_TYPE: {
      console.log(action.payload, "actionpayload");
      return {
        ...state,
        type: action.payload,
        checker: !state.checker,
      };
    }
    case FILTER_BY_SEARCH: {
      console.log(action.payload, "actionpayload");
      return {
        ...state,
        country: action.payload,
      };
    }
    case FILTER_SEARCH_BY_STATE: {
      console.log(action.payload, "actionpayload");
      return {
        ...state,
        state: action.payload,
      };
    }
    case FILTER_SEARCH_BY_DISTRICT: {
      console.log(action.payload, "actionpayload");
      return {
        ...state,
        district: action.payload,
      };
    }
    case FILTER_SEARCH_BY_TECHNOLOGYOFF: {
      console.log(action.payload, "actionpayload");
      return {
        ...state,
        technologyOff: action.payload,
      };
    }
    case FILTER_SEARCH_BY_TECHNOLOGYON: {
      console.log(action.payload, "actionpayload");
      return {
        ...state,
        technologyOn: action.payload,
      };
    }
    case FILTER_SEARCH_BY_CITY: {
      console.log(action.payload, "actionpayload");
      return {
        ...state,
        city: action.payload,
      };
    }
    case FILTER_SEARCH_BY_LANGUAGEOFF: {
      console.log(action.payload, "actionpayload");
      return {
        ...state,
        languageOff: action.payload,
      };
    }
    case FILTER_SEARCH_BY_LANGUAGEON: {
      console.log(action.payload, "actionpayload");
      return {
        ...state,
        languageOn: action.payload,
      };
    }
    default:
      return state;
  }
};
