import React, { useEffect, useState } from "react";
import { Table, Button, Input, Popconfirm, Modal } from "antd";
import "../../../Components/Css/Table.scss";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";
import 'aos/dist/aos.css';
import AOS from 'aos'; 
// import DataListTable from "../datalistTable";

function TrainerList() {
  const [trainerList, setTrainerList] = useState([]);
  const [getData, setGetData] = useState();
  const [search, setSearch] = useState();

  useEffect(() => {
    getTrainer();
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',    
    });
  }, []);
  
  const getTrainer = async () => {
    await Api.get("Trainer/get").then((res) => {
      setTrainerList(res.data);
    });
  };

  const deleteTrainer = (id) => {
    Api.delete(`Trainer/delete/${id}`).then((resp) => {
      getTrainer();
    });
  };
  const update = (ele) => {
    navigate("create", { state: { ele } });
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    const Trainer = e.target.value;

    const newData = trainerList.filter((list) =>
      list.firstName.toLowerCase().includes(Trainer.toLowerCase())
    );
    setGetData(newData);
  };

  const columns = [
    {
      title: "Trainer Name",
      // dataIndex: "firstName",
      render: (text, record, index) => (
          <div data-aos="fade-right" data-aos-delay={index * 100}>
          {record.firstName} {record.lastName}
        </div>
        
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },
    {
      title: "Experience",
      dataIndex: "Experience",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },
    {
      title: "Expertise",
      dataIndex: "Expertise",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },
    {
      title: "Added At",
      dataIndex: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          { new Date(record.createdAt).toLocaleDateString("en-GB")}
        </div>
      ),
       
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_, record, index) => (
        <div data-aos="zoom-in"data-aos-delay={index * 100} >
          {console.log("eIdddd", record)}
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              update(record);
            }}
          />

          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => {
              deleteTrainer(record._id);
            }}
          >
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ paddingLeft: "10px", cursor: "pointer" }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  // const { Search } = Input;

  const onSearch = "";
  const navigate = useNavigate();
  return (
    <div>
      <div className="data_list_main">
        <h4 className="data_list_header">Trainer List</h4>
        <div
          className="data_list_top mt-4 py-4 px-3"
          style={{ backgroundColor: "#c5c5c5" }}
        >
          <input
            className="data_list_search ps-2"
            placeholder="search by name"
            columns={columns}
            // dataList={trainerList}
            value={search}
            onChange={handleSearch}
            // enterButton
          />

          <Button className="Datalist-Table" onClick={() => navigate("create")}>
            <BsFillPersonPlusFill />
            &nbsp;Add New
          </Button>
        </div>
        <div className="Datalist-Table">
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={getData ? getData : trainerList}
            pagination={true}
            className="mt-2"
          />
        </div>
      </div>
    </div>
  );
}

export default TrainerList;
