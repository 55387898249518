import { React, useState, useEffect } from "react";
import { Space, Table, Tag } from "antd";
import "../../../Components/Css/Employer/EmployerResume.scss";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "antd";
import { NavLink } from "react-router-dom";
import Api from "../../../Api";

function ApprovedForm() {
  const navigate = useNavigate();
  const [approveList, setApproveList] = useState([]);
  const id = localStorage.getItem("id");
  useEffect(() => {
    getApprove();
  }, []);

  const getApprove = async () => {
    await Api.get(`createForm/Approvedgetoneuser/${id}`).then((res) => {
      setApproveList(res.data.data);
      console.log("Approveshow", approveList);
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "jobTitle",
      key: "name",
      render: (text) => <h6>{text}</h6>,
    },
    {
      title: "Role",
      dataIndex: "email",
      key: "role",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div>
          <Button
            type=""
            className="data-list-button"
            onClick={() => navigate(`/college/form/details`)}
          >
            View Details
          </Button>
        </div>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      name: "Manu",
      role: "Front End",
      ReasontoReject: "Performance not well",
    },
    {
      key: "2",
      name: "moni",
      role: "Web Developer",
      ReasontoReject: "communication pblm",
    },
    {
      key: "3",
      name: "Kabi",
      role: "Designer",
      ReasontoReject: "Mech dept",
    },
  ];
  return (
    <div>
      <div className="pages-title mt-3 ms-5">Approved List:</div>
      <Table
        columns={columns}
        dataSource={approveList}
        className="mx-5 "
        style={{ width: "50%" }}
      />
      ;
    </div>
  );
}

export default ApprovedForm;
