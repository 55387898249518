import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Select } from "antd";
import Api from "../../../Api";
import "../../../Components/Css/Students/Student.scss";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import { Link, useNavigate } from "react-router-dom";

export default function Quiz() {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const navigateToExamQuestions = (data, filterData) => {
    const url = "/student/quiz/examquestions";
    const state = { data, filterData };
    // navigate(url, { state });
    // navigate("/student/quiz/examquetions")
    navigate(url, { state });
  };
  var techNo;
  var specificWeek = [];
  const onSubmit = (data) => {
    console.log(data, "filterDataggvgv");
    // filterData.map((value) => techNo = value.Technology[0])
    // filterData.map((value) => specificWeek = value.weeks[0].Week)
    // navigateToExamQuestions(data, filterData);
    navigate("/student/quiz/examquestions", { state: { data, filterData } });

    // navigate(`/student/quiz/examquestions/${techNo}/${specificWeek}`);
  };

  const [techValue, setTechValue] = useState();
  const [techList, setTechList] = useState([]);
  const [course, setCourse] = useState();
  const getExamPattern = async () => {
    await Api.get("ExamPattern/get").then((res) => {
      // console.log("examPaterern", res.data.map(item => item?.weeks?.map(item => item.Week)))
      setTechList(res.data);
      // setQuestionbank(res.data);
      console.log("resexam", res.data);
    });
  };

  const filterData = techList.filter((item) => {
    if (item.id === course) {
      return item;
    }
  });
  const WeekData = filterData;
  const WeekData2 = { filterData };
  console.log("weekData", WeekData2);
  // const getTech = async () => {
  //   await Api.get("Course/get").then((res) => {
  //     // setTechList(res.data);
  //     console.log("res.data", res.data);
  //   });
  // };

  useEffect(() => {
    // getTech();
    getExamPattern();
  }, []);

  return (
    <div className="p-5">
      <div className="pages-title mb-3">Quiz</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="Stud_quiz">
          <Row className="mb-3">
            <Col sm={24} md={10} className="Stud-exam-col p-2">
              <label>Course</label>
              <Dropdown
                className="input-field"
                value={techValue}
                options={techList}
                placeholder={"Select Course"}
                {...register("Course", { required: true })}
                optionLabel={"Course"}
                optionValue={"id"}
                onChange={(e) => {
                  setTechValue(e.value);
                  setCourse(e.value);
                }}
              />
              {errors.Course && (
                <span className="Stud-personal-error">
                  Technology is required
                </span>
              )}
            </Col>
            <Col sm={24} md={10} className="Stud-exam-col p-2">
              <label>Week</label>
              <select
                placeholder={"Select Week"}
                className="Stud-exam-input"
                {...register("Week", { required: true })}
              >
                <option value="">Select Week</option>
                {WeekData.map((item) =>
                  item?.weeks.map((items) => (
                    <option value={item?.Week}>{items?.Week}</option>
                  ))
                )}
                {/* {WeekData.map((item => <option value="2">{item}</option>))} */}
                {/* <option value="2">Week2</option>
                <option value="3">Week3</option>
                <option value="4">Week4</option>
                <option value="5">Week5</option>
                <option value="6">Week6</option>
                <option value="7">Week7</option>
                <option value="8">Week8</option>
                <option value="9">Week9</option>
                <option value="10">Week10</option>
                <option value="11">Week11</option>
                <option value="12">Week12</option> */}
              </select>
              <br />
              {errors.Week && (
                <span className="Stud-personal-error">Week is required</span>
              )}
            </Col>
          </Row>
          <br></br>
          <div className="submitbuttons p-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button className="button2 m-2 p-2" type="submit" style={{ color: 'red' }}>
  Lets Go
</button>
</div>
        </div>
      </form>
    </div>
  );
}
