import React, { useState, useEffect } from "react";
import "../../../Components/Css/Employer/projectpost.scss";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import './Package.scss';

function Student() {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const packages = [
    { name: 'Classic', code: 'NY' },
    { name: 'Gold', code: 'RM' },
    { name: 'Platinum', code: 'LDN' },
    { name: 'Excutive', code: 'PRS' }
  ];

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const handleCreateFormSubmit = async () => {
    const userDetails = {
      PackageName: getValues().PackageName,
      ProjectRequest: getValues().ProjectRequest,
      Freelancing: getValues().Freelancing,
      UploadVideo: getValues().UploadVideo,
      ActualPrice: getValues().ActualPrice,
      OfferPrice: getValues().OfferPrice,
    };
    await Api.post(`Student/create`, userDetails).then((resp) => {
      console.log("resp.data", resp.data);
    });
  };

  //   useEffect(() => {
  //     getInstitute();
  //   }, []);
  //   console.log("institute", institute);
  //   const getInstitute = async () => {
  //     await Api.get("fields/getAll").then((res) => {
  //       setInstitute(res.data.data);
  //     });
  //   };
  //   const onUniversity = (e) => {
  //     setInstituteValue(e.value);
  //   };

  return (
    <div>
      <Container className=" content-style ">
        <Row style={{ marginLeft: "5%" }}>
          <form onSubmit={handleSubmit(handleCreateFormSubmit)}>
            <br />
            <div className="pages-title mt-3">Create Packages:</div>
            <Row className="mt-4">
              <Col sm={12} lg={4}>
                <label className="create-title">Package Name</label>
                <Dropdown
                  // editable placeholder="Select a City" className="w-full md:w-14rem" />
                  className="Create-input"
                  {...register("PackageName", { required: true })}
                  value={selectedPackage} onChange={(e) => setSelectedPackage(e.value)} options={packages} optionLabel="name"
                />
                {errors.PackageName && (
                  <p className="error-text-color">PackageName is required</p>
                )}


              </Col>
              <Col sm={12} lg={4}>
                <div style={{ marginTop: "7%" }}>
                  <Row>
                    <Col>
                      <p className="classic">
                        Project Request </p></Col>
                    <Col className="classics">   <input type="text" name="name" className="classic_right "
                      {...register("ProjectRequest", { required: true })}
                    />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="classic">
                        Freelancing</p></Col>
                    <Col className="classics">
                      <input type="number" name="name" className="classic_right "
                        {...register("Freelancing", { required: true })}

                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="classic">
                        Upload Video </p></Col>
                    <Col className="classics">   <input type="number" name="name" className="classic_right "
                      {...register("UploadVideo", { required: true })}

                    />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="classic">
                        Actual Price</p></Col>
                    <Col className="classics">
                      <input type="number" name="name" className="classic_right "
                        {...register("ActualPrice", { required: true })}

                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="classic">
                        offer Price</p></Col>
                    <Col className="classics">  <input type="number" name="name" className="classic_right "
                      {...register("OfferPrice", { required: true })}

                    />
                    </Col>  </Row>
                </div>
              </Col>

            </Row>
            <br />
            <div className="submitbuttons p-2">
              <button
                className="button1 m-2 p-2"
                type="submit"
              // onClick={handleCreateFormSubmit}
              >
                Submit
              </button>
              <button className="button2 m-2 p-2" type="reset">
                Reset
              </button>
            </div>
          </form>
        </Row>
      </Container>
    </div>
  );
}

export default Student;
