import { Input, Table, Button } from "antd";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Api from "../../../../Api";
import moment from "moment";
import "../../../../Components/Css/Admin/StudentList.scss";

// import Button from 'react-bootstrap/Button';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { TiTick } from "react-icons/ti";
import { RiDeleteBin5Line } from "react-icons/ri";
import { TbRectangleVertical } from "react-icons/tb";
import { HiPencilSquare } from "react-icons/hi2";
import { BsDownload } from "react-icons/bs";
import Tooltip from "react-bootstrap/Tooltip";
import { BsFillPersonPlusFill } from "react-icons/bs";

import { MdOutlineRemoveRedEye } from "react-icons/md";
const StudentList = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState([]);
  const [viewProfile, setViewProfile] = useState();
  const navigate = useNavigate();

  // function createdAtSorter(a, b) {
  //     if (a.createdAt < b.createdAt) {
  //         return -1;
  //     }
  //     if (a.createdAt > b.createdAt) {
  //         return 1;
  //     }
  //     return 0;
  // }
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <Row>
          <Col className="pe-3">
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Change user Stauts</Tooltip>
              }
            >
              <a href="#">
                <TiTick
                  alt
                  className="tickIcon"
                  style={{
                    backgroundColor: "#449d44",
                    color: "white",
                    fontSize: "22px",
                    width: "25px",
                  }}
                />
              </a>
            </OverlayTrigger>
          </Col>
          <Col>
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">View Profile</Tooltip>}
            >
              {/* <a href={`/admin/StudentList/details/${viewProfile}`}> */}
              <a href={"#"}>
                <MdOutlineRemoveRedEye
                  style={{
                    backgroundColor: "#4489e4",
                    color: "white",
                    fontSize: "22px",
                    width: "25px",
                  }}
                />
              </a>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="pe-3">
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Delete Profile</Tooltip>}
            >
              <a href="#">
                {" "}
                <RiDeleteBin5Line
                  className="tickIcon"
                  style={{
                    backgroundColor: "#fd6b75",
                    color: "white",
                    fontSize: "20px",
                    width: "25px",
                  }}
                />
              </a>
            </OverlayTrigger>
          </Col>
          <Col>
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Edit Package</Tooltip>}
            >
              <a href="#">
                <TbRectangleVertical
                  className="tickIcon"
                  style={{
                    backgroundColor: "#4489e4",
                    color: "white",
                    fontSize: "20px",
                    width: "25px",
                  }}
                />
              </a>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="pe-3">
            {" "}
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Edit Profile</Tooltip>}
            >
              <a href="#">
                <HiPencilSquare
                  className="tickIcon"
                  style={{
                    backgroundColor: "#449d44",
                    color: "white",
                    fontSize: "20px",
                    width: "25px",
                  }}
                />{" "}
              </a>
            </OverlayTrigger>
          </Col>
          <Col>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Download Profile</Tooltip>
              }
            >
              <a href="#">
                <BsDownload
                  className="tickIcon"
                  style={{
                    backgroundColor: "#4489e4",
                    color: "white",
                    fontSize: "20px",
                    width: "25px",
                  }}
                />
              </a>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Report</Tooltip>}
            >
              <a
                href="#"
                style={{
                  backgroundColor: "#fd6b75",
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "bolder",
                  padding: "3px 6px ",
                  borderRadius: "3px",
                }}
              >
                Report{" "}
                <span style={{ color: "yellow", fontSize: "12px" }}>(0)</span>
              </a>
            </OverlayTrigger>
          </Col>
        </Row>
      </Popover.Body>
    </Popover>
  );
  const columns = [
    {
      title: "Contact Person Name",
      render: (_, record) => <span>{`${record.First} ${record.Last}`}</span>,
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Location",
      dataIndex: "location",
    },

    // WDD
    // {
    //     title: 'Created At',
    //     dataIndex: 'createdAt',
    //     sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    //     render: (text, record) => new Date(record.createdAt).toLocaleDateString('en-GB')
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div>
          <OverlayTrigger trigger="click" placement="left" overlay={popover}>
            <Link
              type=""
              className="data-list-button"
              onClick={(e) => {
                console.log("llll", record._id);
                setViewProfile(record._id);
                navigate(`/admin/StudentList/details/${record?._viewProfile}`);
              }}
            >
              Click Here
            </Link>
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  const getData = async () => {
    await Api.get("/contract/getAllPost").then((res) => {
      setData(res.data.data);
    });
    console.log("setData :>> ", typeof data.map((item) => item?.phone));
  };
  useEffect(() => {
    getData();
    const results = data.filter(
      (item) =>
        item?.firstName
          ?.toLowerCase()
          .includes(searchText.toLowerCase().trim()) ||
        item?.RegistrationId?.toLowerCase().includes(
          searchText.toLowerCase().trim()
        ) ||
        item?.email?.toLowerCase().includes(searchText.toLowerCase().trim()) ||
        item?.phone?.toString().includes(searchText)
    );
    setSearch(results);
  }, [searchText]);

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  let dataCount = data.length;

  return (
    <div>
      <div className="data-list-main">
        <h4 className="data-list-header pages-title"> Request List</h4>
        <h3 className="pages-title">Total Records: {dataCount}</h3>
        <div className="data-list-top mt-4 py-2 px-3">
          <Input
            className="data-list-search ps-2"
            placeholder="search here"
            onChange={handleChange}
            enterButton
          />
          <Button
            className="data-list-button"
            onClick={() =>
              navigate("/admin/users/careerblitz/ContactorStaffing/Request")
            }
          >
            <BsFillPersonPlusFill />
            &nbsp;Add New
          </Button>
        </div>
        {searchText ? (
          <div className="data-list-table">
            <Table
              scroll={{ x: true }}
              columns={columns}
              dataSource={search}
              //  pagination={false}
              className="mt-2"
            />
          </div>
        ) : (
          <div className="data-list-table">
            <Table
              scroll={{ x: true }}
              columns={columns}
              dataSource={data}
              // pagination={false}
              className="mt-2"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentList;
