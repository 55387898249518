import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  MdPerson,
  MdAreaChart,
  MdRequestPage,
  MdSend,
  MdMenuBook,
  MdDetails,
} from "react-icons/md";
import { AiFillProject } from "react-icons/ai";
import { FaBlogger, FaComment } from "react-icons/fa";
import { GrCertificate } from "react-icons/gr";
import { CgProfile } from "react-icons/cg";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { SiBookstack } from "react-icons/si";
import { AiOutlineFileDone } from "react-icons/ai";
import "../../../Components/Css/Sidebar.scss";
import SidebarToggle from "../../Student/DefaultLayout/SidebarToogle";
import Api from '../../../Api.js'

function Sidebar() {
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [isRightSidebarCollapsed, setIsRightSidebarCollapsed] = useState(true);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [userData, setUserData] = useState({});
  const userId = localStorage.getItem("userId");

  const toggleRightSidebar = () => {
    setIsRightSidebarCollapsed((prev) => !prev);
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prev) => !prev);
  };

  const handleSubmenuClick = (submenu) => {
    if (submenu === activeSubmenu) {
      setActiveSubmenu(null);
      setIsRightSidebarCollapsed(true);
    } else {
      setActiveSubmenu(submenu);
      setIsRightSidebarCollapsed(false);
    }
  };

  const getName = () => {
    Api.get(`trainer/Trainergetone/${userId}`)
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => {
        console.error("Error fetching user data:", err);
      });
  };

  useEffect(() => {
    getName();
  }, [userId]);



  return (
    <div className={`sidebar-container ${isSidebarCollapsed ? "collapsed" : ""}`}>
      <SidebarToggle toggleSidebar={toggleSidebar} isSidebarCollapsed={isSidebarCollapsed} />

      {!isSidebarCollapsed && (
        <div className="sidebar-content">
          <div className="nav-list-sidebar">
            <NavLink
              to="/counsellor/dashboard"
              className={({ isActive }) => (isActive ? "active nav-item" : "nav-item")}
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <div className="profile-icon">
                <CgProfile />
              </div>
              <div className="profile-details">
                <div className="profile-role">{userData.firstName}</div>
                <div className="profile-id">{localStorage.getItem("regid")}</div>
              </div>
            </NavLink>
            <NavLink
             to="/counsellor/profile"
              className={({ isActive }) => (isActive ? "active nav-item" : "nav-item")}
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdPerson className="nav-icon" />
              <span className="side-title">My Profile</span>
            </NavLink>
            <NavLink
             to="/counsellor/chat"
              className={({ isActive }) => (isActive ? "active nav-item" : "nav-item")}
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <FaComment className="nav-icon" />
              <span className="side-title">Chat</span>
            </NavLink>
           
            <NavLink
              to="/counsellor/blog/counsblogtable"
              className={({ isActive }) => (isActive ? "active nav-item" : "nav-item")}
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <FaBlogger className="nav-icon" />
              <span className="side-title">Blog</span>
            </NavLink>
            
            <NavLink
            to="/counsellor/transaction"
              className={({ isActive }) => (isActive ? "active nav-item" : "nav-item")}
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <FaMoneyBillTransfer className="nav-icon" />
              <span className="side-title">Transaction History</span>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sidebar;
