import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Robot from "../../assets/robot.gif";
const WelcomeMessage = ({ username }) => {
  return (
    <Container>
      <img alt="robot" src={Robot} />
      <h1 style={{ color: "white" }}>
        Welcome, <span>{username}!</span>
      </h1>
      <h3 style={{ color: "white" }}>
        Please select a chat to Start messaging.
      </h3>
    </Container>
  );
};
export default WelcomeMessage;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
  flex-wrap: wrap;
  img {
    height: 20rem;
  }
  span {
    color: #4e0eff;
  }
`;
