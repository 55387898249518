import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import Layout from '../../Layout';

const PrivacyPolicy = () => {
  const sectionStyle = {
    marginBottom: '20px',
    padding: '15px',
    border: '1px solid #e0e0e0',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
  };

  const headingStyle = {
    color: '#343a40',
    fontWeight: 'bold',
    marginBottom: '10px',
  };

  const paragraphStyle = {
    marginBottom: '10px',
    lineHeight: '1.5',
  };

  const listStyle = {
    listStyle: 'none',
    paddingLeft: '0',
  };

  return (
    <Layout>
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '30px' }}>Privacy Policy</h1>

      <div style={sectionStyle}>
        <h5 style={headingStyle}>Introduction</h5>
        <p style={paragraphStyle}>
          Aroganam is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and protect your personal information when you use our website and services.
        </p>
      </div>

      <div style={sectionStyle}>
        <h5 style={headingStyle}>Information We Collect</h5>
        <ul style={listStyle}>
          <li>
            <FaCheckCircle className="text-success" /> <strong>Personal Information:</strong> When you register for an account or make a purchase, we may collect personal information such as your name, email address, phone number, and payment details.
          </li>
          <li>
            <FaCheckCircle className="text-success" /> <strong>Usage Data:</strong> We collect information about your interactions with our website and services, including IP addresses, browser types, and pages visited.
          </li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h5 style={headingStyle}>How We Use Your Information</h5>
        <ul style={listStyle}>
          <li>
            <FaCheckCircle className="text-success" /> <strong>To Provide Services:</strong> We use your information to process transactions, deliver educational content, and communicate with you regarding your account and our services.
          </li>
          <li>
            <FaCheckCircle className="text-success" /> <strong>To Improve Services:</strong> We may use your data to analyze usage patterns and enhance our offerings.
          </li>
          <li>
            <FaCheckCircle className="text-success" /> <strong>To Communicate:</strong> We may send you updates, newsletters, or promotional materials if you have opted in to receive them.
          </li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h5 style={headingStyle}>Data Protection</h5>
        <ul style={listStyle}>
          <li>
            <FaCheckCircle className="text-success" /> <strong>Security:</strong> We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure.
          </li>
          <li>
            <FaCheckCircle className="text-success" /> <strong>Retention:</strong> We retain your information only for as long as necessary to fulfill the purposes outlined in this policy or as required by law.
          </li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h5 style={headingStyle}>Sharing Your Information</h5>
        <ul style={listStyle}>
          <li>
            <FaCheckCircle className="text-success" /> <strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our platform, processing payments, or performing other functions.
          </li>
          <li>
            <FaCheckCircle className="text-success" /> <strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid legal requests.
          </li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h5 style={headingStyle}>Your Rights</h5>
        <ul style={listStyle}>
          <li>
            <FaCheckCircle className="text-success" /> <strong>Access and Correction:</strong> You have the right to access and correct your personal information. You can update your information through your account settings or by contacting us.
          </li>
          <li>
            <FaCheckCircle className="text-success" /> <strong>Opt-Out:</strong> You may opt out of receiving promotional communications by following the unsubscribe instructions in the emails you receive.
          </li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h5 style={headingStyle}>Cookies and Tracking Technologies</h5>
        <p style={paragraphStyle}>
          We use cookies and similar technologies to enhance your experience on our website. You can manage your cookie preferences through your browser settings.
        </p>
      </div>

      <div style={sectionStyle}>
        <h5 style={headingStyle}>Children’s Privacy</h5>
        <p style={paragraphStyle}>
          <strong>Age Restrictions:</strong> Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children.
        </p>
      </div>

      <div style={sectionStyle}>
        <h5 style={headingStyle}>Changes to Privacy Policy</h5>
        <p style={paragraphStyle}>
          We may update this Privacy Policy from time to time. Any changes will be effective upon posting on our website.
        </p>
      </div>

      <div style={sectionStyle}>
        <h5 style={headingStyle}>Contact Information</h5>
        <p style={paragraphStyle}>
          <strong>Support:</strong> If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:aroganamtech@gmail.com">aroganamtech@gmail.com</a>.
        </p>
      </div>
    </div>
    </Layout>
  );
};

export default PrivacyPolicy;
