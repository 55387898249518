import React from "react";
import { Container } from "react-bootstrap";
import ReactPlayer from "react-player";
import HeaderNavbar from "../../Components/HeaderNavbar";
// import Container from "react-bootstrap/Container";
import "./Feril-Library.scss";

function PlayerSection() {
  return (
    <>
      <HeaderNavbar className="" />
      <div className="full-playersection--wrapper">
        <div>
          <div className="video-wrapper ">
            <ReactPlayer
              url={"https://player.vimeo.com/video/71787467"}
              controls
              className="player"
            />{" "}
            <h1 className="playersection-h1">Video Title</h1>
          </div>
          <aside class="aside bg-style">
            {/* <!-- Media Objects --> */}
            <div class="next-up-bar">
              <h5 className="playersection-h5">Up next</h5>
              <h5 className="playersection-h5">Autoplay</h5>
              <i class="material-icons md-18 md-dark">info</i>
              <div class="switch-container">
                <div class="switch-bar"></div>
                <div class="switch-circle"></div>
              </div>
            </div>
            <div class="media up-next">
              <img
                class="media-figure img-aside"
                src="http://i.ytimg.com/vi/ZSFDm3UYkeE/default.jpg"
                alt=""
              />
              <div class="media-body">
                <h3 className="playersection-h3">
                  Contributing to Open Source Part I: The Easy Way
                </h3>
                <span class="aside-span">by The Odin Project</span>
                <span class="aside-span">7,372 views</span>
              </div>
            </div>
            <div class="media">
              <img
                class="media-figure img-aside"
                src="http://i.ytimg.com/vi/ZSFDm3UYkeE/default.jpg"
                alt=""
              />
              <div class="media-body">
                <h3 className="playersection-h3">
                  Contributing to Open Source Part I: The Easy Way
                </h3>
                <span class="aside-span">by The Odin Project</span>
                <span class="aside-span">7,372 views</span>
              </div>
            </div>
            <div class="media">
              <img
                class="media-figure img-aside"
                src="http://i.ytimg.com/vi/ZSFDm3UYkeE/default.jpg"
                alt=""
              />
              <div class="media-body">
                <h3 className="playersection-h3">
                  Contributing to Open Source Part I: The Easy Way
                </h3>
                <span class="aside-span">by The Odin Project</span>
                <span class="aside-span">7,372 views</span>
              </div>
            </div>
            <h5 class="btn-flat playersection-h5">
              <span className="playersection-span">
                <strong>SHOW MORE</strong>
              </span>
            </h5>
          </aside>
        </div>
        <div className="video-rest_wrapper">
          <section class="bg-style ">
            {/* <!-- Title --> */}
            <h1 className="playersection-h1">
              Contributing to Open Source Part I: The Easy Way
            </h1>
            {/* <!-- Media Object --> */}
            <div className="media">
              <img
                class="img-profile media-figure"
                src="https://yt3.ggpht.com/-UbUyULJMBoM/AAAAAAAAAAI/AAAAAAAAAAA/LGD8KPvBEdc/s88-c-k-no/photo.jpg"
                alt=""
              />
              <div class="media-body">
                <h3 className="playersection-h3">The Odin Project</h3>
                <div class="subscribe">
                  <button class="subscribe-btn playersection-btn">
                    <i class="material-icons md-light md-18">
                      play_circle_filled
                    </i>
                    <span className="playersection-span">Subscribe</span>
                  </button>
                  <span class="subscribe-count">145</span>
                </div>
              </div>
              <span class="lead-view-count">7,372</span>
            </div>
            {/* <!-- Button Bar --> */}
            <div class="lead-btn-row">
              <div class="lead-social-btn">
                <a href="">
                  <i class="material-icons md-dark">add</i>
                  <span className="playersection-span">Add to</span>
                </a>
                <a href="" className="playersection-a">
                  <i class="material-icons md-dark md-18">share</i>
                  <span className="playersection-span">Share</span>
                </a>
                <a className="playersection-a">
                  <i class="material-icons md-dark">more_horiz</i>
                  <span className="playersection-span">More</span>
                </a>
              </div>
              <div class="lead-voting-btn">
                <a href="" className="playersection-a">
                  <i class="material-icons md-dark md-18">thumb_up</i>
                  <span className="playersection-span">42</span>
                </a>
                <a className="playersection-a">
                  <i class="material-icons md-dark md-18">thumb_down</i>
                  <span className="playersection-span">0</span>
                </a>
              </div>
            </div>
          </section>
          {/* <!-- Description --> */}
          <section class="bg-style">
            <h3 className="playersection-h3">Published on Feb 27, 2014</h3>
            <p className="playersection-p">
              How do you contribute to open source? In this video, I'll describe
              the easiest way to make a simple contribution to an open source
              project -- without using the command line.
            </p>
            <div class="btn-flat">
              <span className="playersection-span">
                <strong>SHOW MORE</strong>
              </span>
            </div>
          </section>
          {/* <!-- Comments --> */}
          <section class="comments bg-style">
            {/* <!-- Create Comment --> */}
            <h3 className="playersection-h3">ALL COMMENTS (2)</h3>
            <div class="media">
              <img
                class="img-profile media-figure"
                alt=""
                src="https://yt3.ggpht.com/-xKakHjmg9Cg/AAAAAAAAAAI/AAAAAAAAAAA/-7SNTsiGgTE/s88-c-k-no/photo.jpg"
              />
              <textarea
                class="media-body playersection-textarea"
                placeholder="Add a pubic comment..."
              ></textarea>
            </div>
            {/* <!-- View Comments --> */}
            <button class="comment-filter playersection-btn">
              <span className="playersection-span">Top comments</span>
              <i class="material-icons md-dark md-18">arrow_drop_down</i>
            </button>
            {/* <!-- Comment --> */}
            <div class="media">
              {/* <!-- Media Object --> */}
              <img
                class="img-profile media-figure"
                src="https://yt3.ggpht.com/-xKakHjmg9Cg/AAAAAAAAAAI/AAAAAAAAAAA/-7SNTsiGgTE/s88-c-k-no/photo.jpg"
              />
              <div class="media-body">
                <p class="username">
                  Bill Smith
                  <span className="playersection-span">9 months ago</span>
                </p>
                <p class="media-body">This is a hilarious comment.</p>
                {/* <!-- Comment Social Interactions --> */}
                <div class="comment-btn-bar">
                  <a href="" className="playersection-a">
                    <span>Reply</span>
                  </a>
                  <a href="" className="playersection-a">
                    <i class="material-icons md-dark md-18 md-inactive">
                      thumb_up
                    </i>
                  </a>
                  <a href="" className="playersection-a">
                    <i class="material-icons md-dark md-18 md-inactive">
                      thumb_down
                    </i>
                  </a>
                </div>
              </div>
            </div>
          </section>
          {/* </article> */}
          {/* <!-- Aside --> */}
        </div>
      </div>
    </>
  );
}

export default PlayerSection;
