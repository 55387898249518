import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Api from '../../../Api';
import './AddDomain.css';

function AddDomain() {
  const [domains, setDomains] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [sectorId, setSectorId] = useState(null);
  const [domainName, setDomainName] = useState('');

  useEffect(() => {
    // Fetch all sectors
    Api.get('/coursesector/getsector')
      .then(res => setSectors(res.data.map(sector => ({ value: sector._id, label: sector.sectorName }))))
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    // Fetch domains when sector changes
    if (sectorId) {
      Api.get(`/coursedomain/getdomain/${sectorId}`)
        .then(res => setDomains(res.data))
        .catch(err => console.error(err));
    } else {
      setDomains([]);
    }
  }, [sectorId]);

  const addDomain = () => {
    if (sectorId && domainName) {
      Api.post('/coursedomain/createdomain', { sectorId, domainName })
        .then(res => {
          setDomains([...domains, res.data]);
          setDomainName(''); // Clear input after successful addition
        })
        .catch(err => console.error(err));
    } else {
      alert('Please select a sector and enter a domain name.');
    }
  };


  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      opacity: state.isFocused ? 1 : 0,  // Show input only when focused
    }),
    control: (provided) => ({
      ...provided,
      cursor: 'text', // Keep the cursor style like a text input
    }),
  };

  return (
    <div>
      <br></br>
      <br></br>
    <h2>Add Domain</h2>
    <div className="adddomain-container">
     

      {/* Select Sector */}
      <Select
        options={sectors}
        onChange={(selectedOption) => setSectorId(selectedOption?.value)}
        placeholder="Select Sector"
        styles={customStyles}
      />

      {/* Input Domain Name */}
      <input
        type="text"
        value={domainName}
        onChange={e => setDomainName(e.target.value)}
        placeholder="Enter Domain Name"
        className="domain-input"
        disabled={!sectorId}
      />

      {/* Add Domain Button */}
      <button 
        onClick={addDomain} 
        disabled={!sectorId || !domainName}
        className="add-button"
      >
        Add Domain
      </button>

      {/* Display domains */}
      <ul className="domain-list">
        {domains.map(domain => (
          <li key={domain._id}>{domain.domainName}</li>
        ))}
      </ul>
    </div>
    </div>
  );
}

export default AddDomain;
