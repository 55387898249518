import React from "react";
import "../../../Components/Css/Employer/projectpost.scss";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Api from "../../../Api";

function LocationAdded() {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const handleCreateFormSubmit = async () => {
    const userDetails = {
      locationName: getValues().locationName,
    };
    await Api.post(`fields/createlocation`, userDetails).then((resp) => {
      console.log("resp.data", resp.data);
    });
  }; 

  return (
    <div>
      <Container className=" content-style ">
        <Row style={{ marginLeft: "5%" }}>
          <form onSubmit={handleSubmit(handleCreateFormSubmit)}>
            <br />
            <div className="pages-title mt-3">Create Location:</div>
            <Row className="mt-4" style={{justifyContent:"flex-start",marginLeft:"5%"}}>
              <Col sm={12} md={4}>
                <label className="create-title">Location Name</label>
                <br />
                <input
                  {...register("locationName", { required: true })}
                  className="Create-input"
                />
                {errors.locationName && (
                  <p className="error-text-color">locationName is required</p>
                )}
              </Col>
            </Row>
            <br />
            <div className="submitbuttons p-2">
              <button
                className="button1 m-2 p-2"
                type="submit"
                // onClick={handleCreateFormSubmit}
              >
                Submit
              </button>
              <button className="button2 m-2 p-2" type="reset">
                Reset
              </button>
            </div>
          </form>
        </Row>
      </Container>
    </div>
  );
}

export default LocationAdded;
