import React from "react";
import "./Accessories.css";
import { Row, Col } from "react-bootstrap";
import { Carousel } from "react-bootstrap";
import Card from "react-bootstrap/Card";
// import Button from "react-bootstrap/Button";
import Accessories1 from "../../assets/accessories/Accessories1.jpg";
import Accessories2 from "../../assets/accessories/Accessories2.jpg";
import Accessories3 from "../../assets/accessories/Accessories3.jpg";
import Accessories4 from "../../assets/accessories/Accessories4.jpg";
import discount_img1 from "../../assets/accessories/discount_img1.jpg";
import discount_img2 from "../../assets/accessories/discount_img2.png";
import watch from "../../assets/accessories/watch.jpg";
import jewellery from "../../assets/accessories/jewellery.jpg";
import sunglasses from "../../assets/accessories/sunglasses.jpg";
import handbag from "../../assets/accessories/handbag.jpg";
import search from "../../assets/accessories/search.jpg";
import order_icon from "../../assets/accessories/order_icon.jpg";
import delivery_icon from "../../assets/accessories/delivery_icon.png";
// import icon_image from "../assets/icon_image.jpg";
import { useNavigate } from "react-router-dom";

function Accessories() {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <Carousel>
          <Carousel.Item>
            <img
              className="accessories_caros"
              src={Accessories1}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="accessories_caros"
              src={Accessories2}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="accessories_caros"
              src={Accessories3}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="accessories_caros"
              src={Accessories4}
              alt="Fourth slide"
            />
          </Carousel.Item>
        </Carousel>
        <br />
      </div>

      <div>
        <Row>
          <Col className="flixier_product" md={3}>
            <Card
              className="access_products"
              onClick={() => navigate("/watch")}
            >
              <Card.Img
                className="productcontent_access"
                src={watch}
              ></Card.Img>
              <Card.Title className="accessproduct_tit">
                <h3 className="accessproduct_txt">Watch</h3>
              </Card.Title>
            </Card>
          </Col>

          <Col className="flixier_product" md={3}>
            <Card
              className="access_products"
              onClick={() => navigate("/jewellery")}
            >
              <Card.Img
                className="productcontent_access"
                src={jewellery}
              ></Card.Img>
              <Card.Title className="accessproduct_tit">
                <h3 className="accessproduct_txt">Jewellery</h3>
              </Card.Title>
            </Card>
          </Col>

          <Col className="flixier_product" md={3}>
            <Card
              className="access_products"
              onClick={() => navigate("/sunglass")}
            >
              <Card.Img
                className="productcontent_access"
                src={sunglasses}
              ></Card.Img>
              <Card.Title className="accessproduct_tit">
                <h3 className="accessproduct_txt">Sunglasses</h3>
              </Card.Title>
            </Card>
          </Col>

          <Col className="flixier_product" md={3}>
            <Card
              className="access_products"
              onClick={() => navigate("/handbag")}
            >
              <Card.Img
                className="productcontent_access"
                src={handbag}
              ></Card.Img>
              <Card.Title className="accessproduct_tit">
                <h3 className="accessproduct_txt">Handbag</h3>
              </Card.Title>
            </Card>
          </Col>
        </Row>
      </div>

      <div className="offers">
        <Row>
          <Col className="discounts" md={6}>
            <img
              src={discount_img1}
              alt="furniture_discount"
              className="discounts"
              style={{ width: "94%" }}
            />
          </Col>

          <Col className="discounts" md={6}>
            <img
              src={discount_img2}
              alt="laptop_discount"
              className="discounts"
              style={{ width: "94%" }}
            />
          </Col>
        </Row>
      </div>
      {/* <h1 className="head">
        <u>Order Now</u>
      </h1> */}

      {/* <div className="flexicon">
        <Row>
          <Col className="icon" md={3}>
            <Card className="review">
              <Card.Img className="icon_img" src={icon_image}></Card.Img>
              <Card.Title className="product_tit">
                <h4 className="text">Maddy</h4>
              </Card.Title>
              <p className="text">
                Aenean lacinia bibendum nulla sed consectetur Donec ullamcorper
                .
              </p>
              <Button variant="danger" className="button_ho">
                Read More
              </Button>
            </Card>
          </Col>

          <Col className="icon" md={3}>
            <Card className="review">
              <Card.Img className="icon_img" src={icon_image}></Card.Img>
              <Card.Title className="product_tit">
                <h4 className="text">Maddy</h4>
              </Card.Title>
              <p className="text">
                Aenean lacinia bibendum nulla sed consectetur Donec ullamcorper
                .
              </p>
              <Button variant="danger" className="button_ho">
                Read More
              </Button>
            </Card>
          </Col>
          <Col className="icon" md={3}>
            <Card className="review">
              <Card.Img className="icon_img" src={icon_image}></Card.Img>
              <Card.Title className="product_tit">
                <h4 className="text">Maddy</h4>
              </Card.Title>
              <p className="text">
                Aenean lacinia bibendum nulla sed consectetur Donec ullamcorper
                .
              </p>
              <Button variant="danger" className="button_ho">
                Read More
              </Button>
            </Card>
          </Col>
        </Row>
      </div> */}

      <div>
        <Row>
          <Col className="collect_img" md={4}>
            <img
              className="order_must"
              src={search}
              style={{ width: "4rem", height: "4rem" }}
              alt="..."
            />
            <h3>Great Collection of Accessories</h3>
            <p>
              Good collection of Accessories, <br></br>in manymore products.
            </p>
          </Col>

          <Col className="collect_img" md={4}>
            <img
              className="order_must"
              src={order_icon}
              style={{ width: "4rem", height: "4rem" }}
              alt="..."
            />
            <h3>Order Accessories</h3>
            <p>
              Browse our Catalog<br></br>
              Add Accessories to your Wishlist
            </p>
          </Col>
          <Col className="collect_img" md={4}>
            <img
              className="order_must"
              src={delivery_icon}
              style={{ width: "4rem", height: "4rem" }}
              alt="..."
            />
            <h3>Home Deliver/Pickup</h3>
            <p>
              We delivered to our doorstep/<br></br>Pickup the Accessories
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default Accessories;
