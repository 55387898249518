import React, { useState, useEffect } from "react";
import { Table, Input, Button, Popconfirm } from "antd";
import "./Studentlist.scss";
import { BsCheckLg, BsFillPersonPlusFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Api from "../../../Api";
import moment from "moment";

function Companylist() {
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState([]);
  const [companyList2, setCompanyList2] = useState([]);
  const data = companyList2.map((item) => item);

  function createdAtSorter(a, b) {
    if (a.createdAt < b.createdAt) {
      return -1;
    }
    if (a.createdAt > b.createdAt) {
      return 1;
    }
    return 0;
  }

  const columns = [
    {
      title: "Registration Id",
      dataIndex: "RegistrationId",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Established",
      dataIndex: "establishedYear",
    },
    {
      title: "Description",
      dataIndex: "Description",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (text, record) =>
        new Date(record.createdAt).toLocaleDateString("en-GB"),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div>
          {record.status === "Approved" ? (
            <Button
              type=""
              className="data-list-button"
              onClick={() =>
                navigate(`/admin/Companylist/Companyform/${record?._id}`)
              }
            >
              {" "}
              Approved Details
            </Button>
          ) : (
            <Button
              type=""
              //  className="data-list-button"
              onClick={() =>
                navigate(`/admin/Companylist/Companyform/${record?._id}`)
              }
            >
              Click To Approve/Reject
            </Button>
          )}
        </div>
      ),
    },
  ];

  // const getCompany = async () => {
  //   await Api.get("companylist/getCompanyList").then((res) => {
  //     setCompanyList(res.data.data);
  //     console.log("Companyshow", companyList);
  //   });
  // };

  const getCompany2 = async () => {
    await Api.get("employer/Employer_get").then((res) => {
      setCompanyList2(res.data.data);
    });
  };

  useEffect(() => {
    getCompany2();
    const results = companyList2.filter(
      (item) =>
        item?.companyName
          ?.toLowerCase()
          .includes(searchText.toLowerCase().trim()) ||
        item?.RegistrationId?.toLowerCase().includes(
          searchText.toLowerCase().trim()
        )
    );
    setSearch(results);
  }, [searchText]);

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  let dataCount = companyList2.length;

  return (
    <div className="data-list-main">
      <h4 className="data-list-header pages-title">Company List</h4>
      <h4 className=" pages-title">Total Records : {dataCount}</h4>
      <div className="data-list-top mt-4 py-4 px-3">
        <Input
          // className="data-list-search ps-2"
          placeholder="search by company name or registration Id"
          onChange={handleChange}
          enterButton
        />

        <Button
          // className="data-list-button"
          onClick={() => navigate("/admin/Companylist/approved/")}
        >
          {" "}
          Approved Companies{" "}
        </Button>
      </div>
      {searchText ? (
        <div className="data-list-table">
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={search}
            pagination={false}
            className="mt-2"
          />
        </div>
      ) : (
        <div className=".Datalist-Table">
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={companyList2}
            pagination={false}
            className="mt-2"
          />
        </div>
      )}
    </div>
  );
}

export default Companylist;
