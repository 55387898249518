import React, { useState } from "react";
import { Tabs } from "antd";
import Myprofile from "./Myprofile";
import Training from "./Training";
import KYC from "./KYC";

export default function TrainerProfile() {
  const [selectedRoleType, setSelectedRoleType] = useState("");

  const handleRoleTypeChange = (roleType) => {
    setSelectedRoleType(roleType);
  };

  const isTrainerSelected =
    selectedRoleType === "trainer" || selectedRoleType === "both";
  const isCounsellorSelected =
    selectedRoleType === "counsellor" || selectedRoleType === "both";

  return (
    <div className="professionaltabs">
      <Tabs
        defaultActiveKey="1"
        className="p-3"
        style={{ fontSize: "11px !important", marginTop: "24px" }}
      >
        {/* <Tabs.TabPane className="tabtext p-3" tab="Add Details" key="1">
          <AddDetail onRoleTypeChange={handleRoleTypeChange} />
        </Tabs.TabPane> */}

        <Tabs.TabPane
          className="tabtext p-3"
          tab="Personal Information"
          key="1"
        >
          <Myprofile />
        </Tabs.TabPane>

        {/* {isTrainerSelected && (
          <Tabs.TabPane className="tabtext p-3" tab="Training" key="3">
            <Training />
          </Tabs.TabPane>
        )} */}

        {/* {isCounsellorSelected && (
          <Tabs.TabPane className="tabtext p-3" tab="Counselling" key="4">
            <Counselling />
          </Tabs.TabPane>
        )} */}

        <Tabs.TabPane className="tabtext p-3" tab="Training" key="2">
          <Training />
        </Tabs.TabPane>
        <Tabs.TabPane className="tabtext p-3" tab="KYC" key="3">
          <KYC />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
