import React from 'react'
import "../../../Components/Css/Employer/CreateJobForm.scss";
import interview from "./../../../assets/interview.png";

function InterviewProcess() {
  return (
    <div>
              <div className="second-card-details">
                <h2
                  className=" d-flex justify-content-center mt-2"
                  style={{ color: "gray" }}
                >
                  Interview Process
                </h2>
                <br />
                <h6 className="d-flex justify-content-center">
                  Aptitude Round <br />
                  <br />
                  Technical/Coding Round <br />
                  <br />
                  HR Round
                </h6>
                <img src={interview} className="image-tag"></img>
              </div>
    </div>
  )
}

export default InterviewProcess