import { React, useState, useEffect } from "react";
import { Space, Table, Tag } from "antd";
import "../../../Components/Css/Employer/EmployerResume.scss";
import { Button, Modal } from "antd";
import { NavLink } from "react-router-dom";
import Api from "../../../Api";

function RejectedList() {
  const [rejectList, setRejectList] = useState([]);
  const id = localStorage.getItem("id");
  useEffect(() => {
    getReject();
  }, []);

  const getReject = async () => {
    await Api.get(`reject/getReject/${id}`).then((res) => {
      setRejectList(res.data.data);
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "name",
      render: (text) => <h6>{text}</h6>,
    },
    {
      title: "Role",
      dataIndex: "Role",
      key: "role",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Reason to Reject",
      dataIndex: "Reason",
      key: "ReasontoReject",
    },
  ];
  const data = [
    {
      key: "1",
      name: "Manu",
      role: "Front End",
      ReasontoReject: "Performance not well",
    },
    {
      key: "2",
      name: "moni",
      role: "Web Developer",
      ReasontoReject: "communication pblm",
    },
    {
      key: "3",
      name: "Kabi",
      role: "Designer",
      ReasontoReject: "Mech dept",
    },
  ];
  return (
    <div>
      <div className="pages-title mt-5 ms-5">Rejected List:</div>
      <Table
        columns={columns}
        dataSource={rejectList}
        className="mx-5 mt-5"
        style={{ width: "50%" }}
      />
      ;
    </div>
  );
}

export default RejectedList;
