// import { React, useState, useEffect } from "react";
// import { Row, Col, Button, Card,Container} from "react-bootstrap";
// import { useForm, form } from "react-hook-form";
// import Api from "../../../Api";
// import { Tabs } from "antd";
// import "../../../Components/Css/Employer/CreateJobForm.scss";
// import interview from "./../../../assets/interview.png";
// import { AiOutlineSearch } from "react-icons/ai";
// import { NavLink } from "react-router-dom";
// import { Space, Table, Tag } from "antd";
// import InterviewPage from "./InterviewPage";
// import JobListActions from "./JobListActions.js";
// import DataListTable from "../../Admin/Dashboard/DataListTable.js";
// import ApprovedForm from "./ApprovedForm";
// import DetailsForm from "./DetailsForm";
// import InterviewProcess from "./InterviewProcess";

// function ScheduleForm() {
//   const [Interview, setInterview] = useState("");
//   const [InterviewList, setInterviewList] = useState([]);
//   // const [show,setShow]=useState(true);
//   // const[value,setValue]=useState("");
//   const [number1, setNumber1] = useState();
//   const [number2, setNumber2] = useState();
//   const [total, setTotal] = useState();
//   const {
//     register,
//     handleSubmit,
//     getValues,
//     watch,
//     formState: { errors },
//     form,
//   } = useForm();
//   const onSubmit = (data) => console.log(data);

//   useEffect(() => {
//     getInterview();
//     getList();
//   }, []);
//   const [getData, setGetData] = useState();

//   const getList = () => {
//     Api.get("createForm/getForm")
//       .then((res) => {
//         setGetData(res.data.data);
//       })
//       .catch((error) => console.log(error));
//   };
//   const getInterview = async () => {
//     await Api.get("scheduleinterview/getAllinterview").then((res) => {
//       setInterviewList(res.data.data);
//     });
//   };

//   // const columns = [
//   //   {
//   //     title: "Name",
//   //     dataIndex: "name",
//   //     key: "name",
//   //     render: (text) => <h6>{text}</h6>,
//   //   },
//   //   {
//   //     title: "Job Title",
//   //     dataIndex: "title",
//   //     key: "title",
//   //     render: (text) => <a>{text}</a>,
//   //   },
//   //   {
//   //     title: "Action",
//   //     key: "action",
//   //     render: (_, record) => (
//   //       <Space size="middle">
//   //         {/* <a>View {record.name}</a> */}
//   //         <NavLink to="/" >View</NavLink>
//   //       </Space>
//   //     ),
//   //   },
//   // ];
//   const columns = [
//     {
//       title: "Role",
//       dataIndex: "jobRole",
//     },
//     {
//       title: "Job title",
//       dataIndex: "jobTitle",
//     },
//     {
//       title: "Technology",
//       dataIndex: "technology",
//     },
//     {
//       title: "Action",
//       dataIndex: "",
//       key: "x",
//       render: (_, record) => <JobListActions record={record} />,
//     },
//   ];
//   const data = [
//     {
//       key: "1",
//       name: "xxx",
//       title: "FrontEnd Developer",
//     },
//     {
//       key: "2",
//       name: "yyy",
//       title: "Web Developer",
//     },
//     {
//       key: "3",
//       name: "afdf",
//       title: "Designer",
//     },
//     {
//       key: "4",
//       name: "zzz",
//       title: "Php Developer",
//     },
//   ];
//   const [searchValue, setSearchValue] = useState("");
//   const handleSearchInputChange = (e) => {
//     setSearchValue(e.target.value);
//   };

//   const filteredData = getData ? getData.filter((item) =>
//   item.jobTitle.toLowerCase().includes(searchValue.toLowerCase())
// ) : [];

//   return (
//     <div className="p-4">
//       <form onSubmit={handleSubmit()} form={form}>
//         <br />
//         <div className="interivew-head">
//           <div className="pages-title ">Schedule Interview:</div>
//           <div>
//             <AiOutlineSearch className="icon-tagSearch" />   <input
//             placeholder="Search..."
//             className="inputSearchFields"
//             value={searchValue}
//             onChange={handleSearchInputChange}
//           />
//             <Button>Post a Job</Button>
//           </div>
//         </div>
//         <Row className="mt-4">
//           <Col sm={12} mg={5} lg={12}>
//             {/* <Card className="interview-card"> */}
//               <h5>Interview</h5>
//               <Tabs defaultActiveKey="1">
//               <Tabs.TabPane tab="Process " key="1">
//                 <InterviewProcess/>
//                 </Tabs.TabPane>
//                 <Tabs.TabPane tab="Upcoming " key="2">

//                   {/* <Table dataSource={data} columns={columns}  dataList={getData} className="mx-5 mt-5" style={{ width: "80%" }} />; */}
//                   <DataListTable
//                     showAddNewBtn={false}
//                     columns={columns}
//                     dataList={filteredData}
//                     // handleSearch={handleSearch}
//                     // title="Job List"
//                   />
//                 </Tabs.TabPane>
//                 &nbsp;
//                 <Tabs.TabPane tab="Approved " key="3">
//                   <ApprovedForm />
//                 </Tabs.TabPane>
//                 {/* <Tabs.TabPane tab="Details " key="3">
//                   <DetailsForm />
//                 </Tabs.TabPane> */}
//                 <Tabs.TabPane tab="View All " key="4">
//                   <InterviewPage />
//                 </Tabs.TabPane>
//               </Tabs>
//             {/* </Card> */}
//           </Col>
//         </Row>
//       </form>
//     </div>
//   );
// }

// export default ScheduleForm;

import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Api from "../../../Api";
import { Tabs } from "antd";
import "../../../Components/Css/Employer/CreateJobForm.scss";
import { AiOutlineSearch } from "react-icons/ai";
import { Space, Table } from "antd";
import InterviewPage from "./InterviewPage";
import JobListActions from "./JobListActions.js";
import DataListTable from "../../Admin/Dashboard/DataListTable.js";
import ApprovedForm from "./ApprovedForm";
import InterviewProcess from "./InterviewProcess";
import { useNavigate } from "react-router-dom";

function ScheduleForm() {
  const [InterviewList, setInterviewList] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [getData, setGetData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const btnStyles = { fontSize: "0.7rem" };
  const id = localStorage.getItem("id");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    getInterview();
    getList();
  }, []);

  const getList = () => {
    Api.get(`createForm/getForm/${id}`)
      .then((res) => {
        setGetData(res.data.data);
      })
      .catch((error) => console.log(error));
  };

  const getInterview = async () => {
    await Api.get("scheduleinterview/getAllinterview").then((res) => {
      setInterviewList(res.data.data);
    });
  };
  const navigate = useNavigate();
  const companyName = localStorage.getItem("companyName");

  const columns = [
    {
      title: "Role",
      dataIndex: "jobRole",
    },
    {
      title: "Job title",
      dataIndex: "jobTitle",
    },
    {
      title: "Technology",
      dataIndex: "technology",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <>
          <Button
            style={btnStyles}
            variant="primary"
            //  onClick={() => setModalOpen(true)}
            // onClick={() => navigate(`/admin/EmployerList/details/${record?._id}`)}
            // onClick={() => navigate(`/employer/${companyName}/jobrequest`)}
            onClick={() =>
              navigate(`/employer/${companyName}/ViewApplicants`, {
                state: record,
              })
            }
          >
            View Applicants
          </Button>
          {/* <JobListActions
            record={record}
            onClick={() => {
              // Your onClick logic here
              console.log('Button clicked for record:', record);
            }}
          /> */}
        </>
      ),
    },
  ];

  const filteredData = getData
    ? getData.filter((item) =>
        item.jobTitle.toLowerCase().includes(searchValue.toLowerCase())
      )
    : [];

  return (
    <div className="p-4">
      <form onSubmit={handleSubmit()}>
        <br />
        <div className="interivew-head">
          <div className="pages-title">Schedule Interview:</div>
          <div>
            {activeTab === "2" && (
              <>
                <AiOutlineSearch className="icon-tagSearch" />

                <input
                  placeholder="Search..."
                  className="inputSearchFields"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </>
            )}
            <Button>Post a Job</Button>
          </div>
        </div>
        <Row className="mt-4">
          <Col sm={12} mg={5} lg={12}>
            <h5>Interview</h5>
            <Tabs
              defaultActiveKey="1"
              onChange={handleTabChange} // Handle tab change
            >
              <Tabs.TabPane tab="Process " key="1">
                <InterviewProcess />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Upcoming " key="2">
                <DataListTable
                  showAddNewBtn={false}
                  columns={columns}
                  dataList={filteredData}
                />
              </Tabs.TabPane>
              &nbsp;
              <Tabs.TabPane tab="Approved " key="3">
                <ApprovedForm />
              </Tabs.TabPane>
              <Tabs.TabPane tab="View All " key="4">
                <InterviewPage />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default ScheduleForm;
