  import React, { useEffect, useState } from "react";
  import HeaderNavbar from "../../../Components/HeaderNavbar";
  import { Row, Col, Button } from "react-bootstrap"; // Imported Button from react-bootstrap
  import careerblitz from "../../../Images/InstagramImage.png";
  import { useNavigate } from "react-router-dom";
  import "./FilterLandingPage.scss";
  import { TiTickOutline } from "react-icons/ti";
  import Tab from "react-bootstrap/Tab";
  import Nav from "react-bootstrap/Nav";
  import Api from "../../../Api";
  import { useParams } from "react-router-dom";

  function FilterLandingPage() {
    const navigate = useNavigate();
    const { _id: id } = useParams();
    const [allBatch, setAllBatch] = useState([]);
    const [allBatchOne, setAllBatchOne] = useState();
    const [filterAllBactch, setfilterAllBatch] = useState();
    const [allCourse, setAllCourse] = useState();

    console.log("allBatch", allBatch);

    useEffect(() => {
      getAllBatches();
      getAllCourses();
    }, []);

    useEffect(() => {
      // Combine allBatchOne and allCourse into a single array and set combinedData state
      Promise.all([allBatchOne, allCourse]).then((values) => {
        const combinedData = [...values[0], ...values[1]];
        setAllBatch(combinedData);
        setfilterAllBatch(combinedData);
      });
    }, [allBatchOne, allCourse]);

    const getAllBatches = () => {
      Api.get(`Batch/get`).then((res) => {
        setAllBatchOne(res.data);
        // setfilterAllBatch(res.data);
      });
    };

    const getAllCourses = () => {
      Api.get(`traingCourse/getAllCourse`).then((res) => {
        setAllCourse(res.data);
      });
    };

    const filteredId = allBatch?.filter((ele) => ele._id === id);

    const [services, setservices] = useState([
      {
        id: 1,
        headding: "MERN STACK",
        sub: "Online / Ofline",
        image: careerblitz,
        tech: "Node.js, React.js, MongoDB, Express.js ",
      },
      {
        id: 2,
        headding: "MEAN STACK",
        sub: "Online / Ofline",
        image: careerblitz,
        tech: "Node.js, Angular,  MongoDB, Express.js",
      },
      {
        id: 3,
        headding: "JAVA DEVELOPER",
        sub: "Online / Ofline",
        image: careerblitz,
        tech: "Java, Springboot",
      },
      {
        id: 4,
        headding: "MANUAL TESTING",
        sub: "Online / Ofline",
        image: careerblitz,
        tech: "Jira, Test Cases, Test Scenario",
      },
      {
        id: 5,
        headding: "AUTOMATION ",
        sub: "Online / Ofline",
        image: careerblitz,
        tech: "Java with Selenium, Java with python",
      },
      {
        id: 6,
        headding: "AUTOMATION ",
        sub: "Online / Ofline",
        image: careerblitz,
        tech: "Java with Selenium, Java with python",
      },
    ]);

    return (
      <div className="course_landingpage">
        <HeaderNavbar />
        <Row
          style={{
            paddingTop: "90px",
            paddingInline: "30px",
            paddingBottom: "90px",
          }}
          className="outer-container"
        >
          {filteredId?.length > 0 &&
            filteredId.map((ele) => {
              {
                console.log(ele, "hai");
              }
              return (
                <Col className="course_landingpage-left--section" md={8}>
                  <div
                    style={{
                      // outline: "2px solid black",
                      paddingInline: "30px",

                      paddingBlock: "20px",
                      borderRadius: "10px",
                    }}
                    className="inner-container"
                  >
                    {/* <h2>The Complete 2023 Web Development Bootcamp</h2> */}
                    <h2>{ele.course}</h2>
                    <img src="" alt="" />
                    <p className="inner-container--subtitle">
                      Become a Full-Stack Web Developer with just ONE course.
                      HTML, CSS, Javascript, Node, React, MongoDB, Web3 and DApps
                    </p>
                    <p className="createdby--landingpage">
                      Tech-Stack :<span>{ele.Technology}</span>
                    </p>
                    <span className="bestseller">BestSeller</span>{" "}
                    <p className="createdby--landingpage">
                      Created by{" "}
                      
                      <span>{ele.Trainer}</span>
                    </p>
                    
                    <div className="lang-btn--wrapper">
                      <p className="languages-landingpage">
                        Languages : <span>{ele.language}</span>
                      </p>
                      <p>
                      Contact Number:&nbsp;
                      <button className="button-example ">
                      {ele.ContactNo}
                      </button>
                        </p>
                        <div>
                        <Button variant="primary" onClick={() => {/* Add your logic for Apply button */}}>
                          Apply
                        </Button>
                      </div>
                      
                    </div>
                  </div>

                  <div className="landingpage-second--section">
                    <h5>What you'll learn</h5>
                    <p>
                      <span className="tick-icons">
                        <TiTickOutline />
                      </span>
                      <span>
                        Build 16 web development projects for your portfolio,
                        ready to apply for junior developer jobs.
                      </span>
                    </p>
                    <p>
                      <span className="tick-icons">
                        <TiTickOutline />
                      </span>
                      <span>
                        After the course you will be able to build ANY website you
                        want.
                      </span>
                    </p>
                    <p>
                      <span className="tick-icons">
                        <TiTickOutline />
                      </span>
                      <span>Master backend development with Node</span>
                    </p>
                  </div>

                  <div className="allBatch--container">
                    <h5 className="batches--title">Batches</h5>
                    <div className="allBatch--wrapper">
                      <div className="batchtable_date_day ">
                        <span className="batchtable_date">
                          {new Date(ele?.startDate)?.toLocaleString("en-US", {
                            month: "short",
                            day: "numeric",
                          })}

                          <span>
                            <sup>th</sup>
                          </span>
                        </span>
                        <span className="batchtable_week">Weekdays</span>
                      </div>
                      <div className="batchtable_time_week">
                        <span className="batchtable_dayweek">
                          MON &amp; FRI({" "}
                          {Math.round(
                            (new Date(ele?.endDate).getTime() -
                              new Date(ele?.startDate).getTime()) /
                              (1000 * 60 * 60 * 24 * 7)
                          )}{" "}
                          Weeks){" "}
                        </span>
                        <span className="batchtable_time m-5">
                          {new Date(ele?.startDate).toLocaleDateString("en-US", {
                            month: "numeric",
                            day: "numeric",
                            year: "numeric",
                          })}{" "}
                          -{" "}
                          {new Date(ele?.endDate).toLocaleDateString("en-US", {
                            month: "numeric",
                            day: "numeric",
                            year: "numeric",
                          })}
                        </span>
                    
                      </div>
                    </div>
                    
                  </div>
                </Col>
              );
            })}
          <Col className="course_landingpage-right--section" md={3} lg={3}>
            <h4>Related Courses</h4>
            <Row className="service_cards mt-4 mb-5">
              {allBatch.slice(2, 5).map((service) => {
                console.log("service", service);
                return (
                  <Col
                    sm={4}
                    md={4}
                    lg={4}
                    className="service_card mt-5"
                    onClick={() =>
                      navigate(`/skilltest/filterpage/${service._id}`)
                    }
                  >
                    <article>
                      <h2>{service.Technology}</h2>
                      <div className="title">{service.courseMode}</div>
                      <div className="pic">
                        <img src={careerblitz} className="card_inside" />
                      </div>
                      <div className="desc_technology mt-2">
                        <b>{service.frameWork}</b>
                      </div>
                    </article>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

  export default FilterLandingPage;
