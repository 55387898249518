import React, { useState, useEffect } from "react";
import Api from "../../../Api";
import "../Profile/Training.css"; // Import the CSS file for styling

const Training = () => {
  const trainerId = localStorage.getItem("userId");

  const [trainingDetails, setTrainingDetails] = useState({
    totalyearsofexp: "",
    technology: [], // Ensure this is initialized as an empty array
    preferredmode: "",
    studentstrained: "",
    trainingtype: "",
    max_no_of_students_trained_at_once: "",
  });
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Fetch the existing training details
  useEffect(() => {
    Api.get(`/trainer/Trainergetone/${trainerId}`)
      .then((response) => {
        console.log("training", response);
        const data = response?.data?.data?.professionalDetails || {};
        // Ensure technology is always an array
        setTrainingDetails({
          ...data,
          technology: Array.isArray(data.technology) ? data.technology : [],
        });
      })
      .catch((err) => {
        console.error(err);
        setError("Failed to fetch trainer details");
      });
  }, [trainerId]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTrainingDetails({ ...trainingDetails, [name]: value });
  };

  // Handle technology inputs changes
  const handleTechnologyChange = (index, event) => {
    const { name, value } = event.target;
    const updatedTechnologies = trainingDetails.technology.map((tech, idx) =>
      idx === index ? { ...tech, [name]: value } : tech
    );
    setTrainingDetails({ ...trainingDetails, technology: updatedTechnologies });
  };

  // Add a new technology field
  const addTechnology = () => {
    setTrainingDetails({
      ...trainingDetails,
      technology: [...trainingDetails.technology, { name: "", experience: "" }],
    });
  };

  // Remove a technology field
  const removeTechnology = (index) => {
    setTrainingDetails({
      ...trainingDetails,
      technology: trainingDetails.technology.filter((_, idx) => idx !== index),
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await Api.put(
        `/trainer/trainerprofessionalinfo/${trainerId}`,
        trainingDetails
      );
      setMessage(response.data.message);
      setError("");
    } catch (error) {
      setError("Error updating training details");
      setMessage("");
    }
  };

  return (
    <div className="training-container">
      <h2>Update Training Details</h2>
      {message && <p className="success-message">{message}</p>}
      {error && <p className="error-message">{error}</p>}

      <form onSubmit={handleSubmit}>
        <div className="form-grid">
          <div className="form-group">
            <label>Total Years of Experience</label>
            <input
              type="text"
              name="totalyearsofexp"
              value={trainingDetails.totalyearsofexp || ""}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Domain</label>
            <select
              name="preferredmode"
              value={trainingDetails.preferredmode || ""}
              onChange={handleChange}
              required
            >
              <option value="">Select Mode</option>
              <option value="online">Online</option>
              <option value="offline">Offline</option>
              <option value="both">Both</option>
            </select>
          </div>

          <div className="form-group">
            <label>Subdomain</label>
            <input
              type="text"
              name="studentstrained"
              value={trainingDetails.studentstrained || ""}
              onChange={handleChange}
            />
          </div>
          {/* 
          <div className="form-group">
            <label>Training Type</label>
            <select
              name="trainingtype"
              value={trainingDetails.trainingtype || ""}
              onChange={handleChange}
            >
              <option value="">Select Training Type</option>
              <option value="Corporate">Corporate</option>
              <option value="Individual">Individual</option>
              <option value="Both">Both</option>
            </select>
          </div> */}

          <div className="form-group">
            <label>Area of interest</label>
            <input
              type="text"
              name="max_no_of_students_trained_at_once"
              value={trainingDetails.max_no_of_students_trained_at_once || ""}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* <h3>Technologies</h3>
        {trainingDetails?.technology?.map((tech, index) => (
          <div className="technology-group" key={index}>
            <div className="form-group">
              <label>Technology Name</label>
              <input
                type="text"
                name="name"
                value={tech.name}
                onChange={(e) => handleTechnologyChange(index, e)}
              />
            </div>
            <div className="form-group">
              <label>Experience (Years)</label>
              <input
                type="number"
                name="experience"
                value={tech.experience}
                onChange={(e) => handleTechnologyChange(index, e)}
              />
            </div>
            <button
              type="button"
              className="remove-technology-button"
              onClick={() => removeTechnology(index)}
            >
              Remove
            </button>
          </div>
        ))} */}

        {/* <button
          type="button"
          className="add-technology-button"
          onClick={addTechnology}
        >
          Add Technology
        </button> */}

        <div className="submit-container">
          <button
            type="submit"
            className="submit-button"
            style={{ width: "220px" }}
          >
            Update Training Details
          </button>
        </div>
      </form>
    </div>
  );
};

export default Training;
