import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BiRupee, BiTimeFive } from "react-icons/bi";
import { CiCalendarDate, CiLocationOn } from "react-icons/ci";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { TfiBag } from "react-icons/tfi";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../../../Api";

function StaffRequiredInnerPage() {
  const value = useLocation();
  //   const [createForm, setcreateForm] = useState();
  const navigate = useNavigate();

  const jobPost = value.state;

  const email = localStorage.getItem("email");
  const id = localStorage.getItem("id");
  const name = localStorage.getItem("name");
  console.log("jobPost", jobPost);
  // const UserId = jobPost.UserID;
  //   const PositionName = jobPost.PositionName;
  //   const Experience = jobPost.Experience;
  //   const location = jobPost.location;
  //   const budget = jobPost.budget;
  //   const technology = jobPost.technology;
  //   const JD = jobPost.JD;
  //   const CompanyID = jobPost.CompanyID;

  const onSubmit = (e) => {
    const details = {
      // CompanyID: CompanyID,
      // UserID: id,
      // PositionName: PositionName,
      // Experience: Experience,
      // budget: budget,
      // technology: technology,
      // JD: JD,
      // location: location,
    };
    Api.post("contract/createRequests", details).then((res) => {
      console.log("res", res);
    });
  };
  return (
    <Container>
      <div>
        <div className="ms-4">
          <Row className="p-3">
            <Col>
              <h3 className="mt-3">{jobPost.PositionName}</h3>

              <div className="ms-4 p-2">
                <h6>
                  <CiCalendarDate /> &nbsp;{jobPost.technology}
                </h6>
                <h6>
                  <CiCalendarDate /> &nbsp;{jobPost.budget}
                </h6>
                <h6>
                  <TfiBag /> &nbsp;{jobPost.Experience}
                </h6>
                <h6>
                  <TfiBag /> &nbsp;{jobPost.JD}
                </h6>

                <h6>
                  <TfiBag /> &nbsp;{jobPost.location}
                </h6>
              </div>
              {/* <div className="d-flex mt-3 mb-2">
                <Button
                  className="login-button"
                  variant="secondary"
                  //   onClick={() => navigate("/employer/projetrequest")}
                >
                  Rejected
                </Button>
                <Button
                  className="login-button ms-5"
                  variant="primary"
                  onClick={(e) => {
                    onSubmit();
                  }}
                >
                  Approved
                </Button>
              </div> */}
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
}

export default StaffRequiredInnerPage;
