export const themes = {
    default: {
    homeContainer:"home-container",
    ourServiceRight:"content-display",
    ourServiceLeft:"dynamic-container-features",
    ourServicesText:"our-service-text",
    testimonialContainer:"testimonial-container",
    trendingCourse:".course-carousel",
    statsCounterText:"stats-text"
    },
    dark: {
       homeContainer:"home-container-dark",
        ourServiceRight:"content-display-dark",
        ourServiceLeft:"dynamic-container-features-dark",
        ourServicesText:"our-service-text-dark",
        testimonialContainer:"testimonial-container-dark",
        trendingCourse:".course-carousel-dark",
        statsCounterText:"stats-text-dark"
    },
    light: {
        homeContainer:"home-container"
    },
  };
  