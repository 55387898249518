import React from "react";
import "./AddList.css";
import { Row, Col, Card, Stack, Button } from "react-bootstrap";
import pic4 from "../../assets/rental/pic4.avif";

function AddList() {
  const tsk = [
    {
      Header: "Conditions",
      Title: "Rule conditions",
      Text:
        "1) Introduction This following sets out the terms and conditions on which you may use the content on business-standard.com website,ll information received by us from yourmn busine ss-standard.com or other digital products of Business Standard will be used by Business Standard in accordance with our Privacy Policy. Kindly read the below mentioned details. Personal Subscription ServicesPayment details: When you purchase a subscription, you must provide us with complete and accurate payment information. By submitting payment details you promise that you are entitled to purchase a subscription using those payment detailsSubscription Period, Renewal and Cancellation of Personal Subscriptions Renewals: If you chose to pay 7 days in advance of any changes to the price in your subscription that will apply upon next monthly renewal. ",
    },
  ];
  return (
    <div>
      <div className="AddLists">
        <Row>
          <Col sm={12} md={6} className="im">
            <img src={pic4} width="100%" />
          </Col>
          <Col>
            <div className="AddList">
              <Row>
                <p style={{ fontSize: "100%", paddingLeft: "4%" }}>
                  Mobile Model
                </p>
                <Col sm={12} md={4}>
                  <form className="Add1 pt-2">
                    <p style={{ fontSize: "100%" }}>Tenure</p>
                    <select className="Ad" style={{ width: "100px" }}>
                      <option>select</option>
                      <option>1 Month</option>
                      <option>3 Month</option>
                      <option>6 Month</option>
                      <option>9 Month</option>
                      <option>12 Month</option>
                    </select>
                  </form>
                </Col>
                <Col sm={12} md={4}>
                  <form className="Add1 pt-2">
                    <p style={{ fontSize: "100%" }}>Delivery Date</p>
                    <input
                      type="date"
                      className="Ad"
                      style={{ width: "100px" }}
                    />
                  </form>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="Add2 pt-2">
                  <from>
                    <p style={{ fontSize: "100%" }}>Quantity</p>
                    <select className="Ads" style={{ width: "100px" }}>
                      <option>select</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                  </from>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={4} className="Add3 pt-2">
                  <p style={{ fontSize: "100%" }}>Monthly Rent</p>

                  <form>
                    <select className="Ad" style={{ width: "100px" }}>
                      <option>select</option>
                      <option>4000</option>
                      <option>3600</option>
                      <option>3200</option>
                      <option>2600</option>
                      <option>2000</option>
                    </select>
                  </form>
                </Col>

                <Col sm={12} md={4} className="Add4 pt-2">
                  <p style={{ fontSize: "100%" }}>Deposite</p>

                  <form>
                    <select className="Ad" style={{ width: "100px" }}>
                      <option>select</option>
                      <option>8000</option>
                      <option>7000</option>
                      <option>6000</option>
                      <option>5000</option>
                      <option>2000</option>
                    </select>
                  </form>
                </Col>
              </Row>

              <Row>
                <Col className="Add5" md={4}>
                  <Button variant="danger"> Add </Button>
                </Col>
                <Col className="Add5" md={4}>
                  <Button variant="danger"> Rent </Button>
                </Col>
                <Col className="Add5" md={4}>
                  <Button variant="danger">Contact</Button>
                </Col>
              </Row>
            </div>
          </Col>
          <hr />
          <div>
            <Row className="Adding" style={{ backgroundColor: "slategray" }}>
              <Col md={8} className="Des p-3">
                <Stack gap={3} variant="outline-secondary">
                  <div
                    className="bg-secondary text-white border"
                    variant="light"
                  >
                    <p>
                      <b>Specification:</b>
                    </p>
                    Technical Specification For electrical Work Medium Voltage
                    (415 V) Switchboards Sheet Steel Distribution Boards
                    Lighting Sub Distribution Boards Induction Motors
                    Interlocking Switch Socket and Plug Lighting Fixtures &
                    Accessories Cables 8 Prefabricated Ladder Type Cable Racks
                    Local Control Stations Junction Box Erection Specification
                    16 Typical arrangement of cables buried in slits Arrangement
                    of connections of Earth Conductors Typical Details of
                    connections in Earth Pit Earth Pit Details Cables 8
                    Prefabricated Ladder Type Cable Racks Local Control Stations
                    Junction Box Erection Specification 16 Typical arrangement
                    of cables buried in slits Arrangement of connections of
                    Earth Conductors Typical Details of connections in Earth Pit
                    Earth Pit Details Earth Electrode for Earthing Al
                    Accessories for Earth Pit Earth Bus steel Tubular Lighting
                    Pole PDS Drawings SLD of PMCC & New Pump area Drg
                  </div>
                  <div className="bg-secondary text-white border">
                    <p>
                      <b>Description:</b>
                    </p>
                    1. Turn features into benefits Want a superweapon to ignite
                    your customer’s interest in a product? It’s right under your
                    nose: Take your product’s unique features and turn them into
                    benefits.Open your door to the world of grilling with the
                    sleek Spirit II E-210 gas grill. This two burner grill is
                    built to fit small spaces, and packed with features such as
                    the powerful GS4 grilling system, iGrill capability, and
                    convenient side tables for placing serving trays. small
                    spaces, and packed with features such as the powerful GS4
                    grilling system, iGrill capability, and convenient side
                    tables for placing serving trays. Welcome to the Weber
                    family. 2. Anticipate your buyer’s pain points You know what
                    I want even more than a BBQ? A puppy. A snuggly, cuddly,
                    floppy-eared little puppy.3. Make your buyer smarter Your
                    job on the product page is to educate your customer. Write
                    detailed, informative copy that helps your customer make a
                    smart purchase decision.4. Tell a story Who doesn’t love a
                    good story? Bedtime stories, ghost stories, war stories,
                    tragic star-crossed lover stories,
                    that-awkward-thing-I-got-mocked-for-in-middle-school
                    stories.
                  </div>
                </Stack>
              </Col>
              <Col>
                <Row>
                  {tsk.map((tsk, i) => (
                    <Col className="AddedList p-3" md={12}>
                      <Card
                        className="bg-secondary text-white"
                        style={{ width: "24rem" }}
                      >
                        <Card.Body>
                          <Card.Header>{tsk.Header}</Card.Header>
                          <Card.Title>{tsk.Title}</Card.Title>
                          <Card.Text>{tsk.Text}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </div>
        </Row>
      </div>
    </div>
  );
}
export default AddList;
