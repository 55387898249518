import { Input, Table } from "antd";
import { Row, Col, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Api from "../../../../Api";
import moment from "moment";
import "../../../../Components/Css/Admin/StudentList.scss";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { TiTick } from "react-icons/ti";
import { RiDeleteBin5Line } from "react-icons/ri";
import { TbRectangleVertical } from "react-icons/tb";
import { HiPencilSquare } from "react-icons/hi2";
import { BsDownload } from "react-icons/bs";
import Tooltip from "react-bootstrap/Tooltip";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const StudentList = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState([]);
  const navigate = useNavigate();

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        {/* Popover content here */}
      </Popover.Body>
    </Popover>
  );

  const columns = [
    {
      title: "Employee Id",
      dataIndex: "RegistrationId",
    },
    {
      title: "Name",
      dataIndex: "firstName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone  ",
      dataIndex: "phone",
    },
    {
      title: "Add Date ",
      dataIndex: "addDate",
    },
    {
      title: "Package",
      dataIndex: "package",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div>
          <OverlayTrigger trigger="click" placement="left" overlay={popover}>
            <Link
              type=""
              className="data-list-button"
              onClick={() =>
                navigate(`/admin/StudentList/details/${record?._id}`)
              }
            >
              Click Here
            </Link>
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  const getData = async () => {
    try {
      const response = await Api.get("/trainer/Trainerget");
      setData(response.data.data);
      setSearch(response.data.data); // Initialize search with full data
    } catch (error) {
      console.error("Error fetching trainer list: ", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const results = data.filter(
      (item) =>
        item?.firstName
          ?.toLowerCase()
          .includes(searchText.toLowerCase().trim()) ||
        item?.RegistrationId?.toLowerCase().includes(
          searchText.toLowerCase().trim()
        ) ||
        item?.email?.toLowerCase().includes(searchText.toLowerCase().trim()) ||
        item?.phone?.toString().includes(searchText)
    );
    setSearch(results);
  }, [searchText, data]);

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  let dataCount = data.length;

  return (
    <div>
      <div className="data-list-main">
        <h4 className="data-list-header pages-title">Trainers List</h4>
        <h3 className="pages-title">Total Records: {dataCount}</h3>
        <div className="data-list-top mt-4 py-2 px-3">
          <Input
            className="data-list-search ps-2"
            placeholder="search here"
            onChange={handleChange}
            enterButton
          />
        </div>
        <div className="data-list-table">
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={searchText ? search : data}
            className="mt-2"
          />
        </div>
      </div>
    </div>
  );
};

export default StudentList;
