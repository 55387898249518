import Addcategory from "../Pages/Vendor/Addcategory";
import Addlist from "../Pages/Vendor/Addlist";
import Advertisement from "../Pages/Vendor/Advertisement";
import Enquiry from "../Pages/Vendor/Enquiry";
import Myprofile from "../Pages/Vendor/Myprofile";
import Offer from "../Pages/Vendor/Offer";
import Packages from "../Pages/Vendor/Packages";
import Packageoffers from "../Pages/Vendor/Packageoffers";

const Routers = [
  {
    path: "vendordashboard/myprofile",
    name: "Myprofile",
    element: <Myprofile />,
  },
  {
    path: "/vendordashboard/addcategory",
    name: "Addcategory",
    element: <Addcategory />,
  },
  {
    path: "vendordashboard/advertisement",
    name: "Advertisement",
    element: <Advertisement />,
  },
  {
    path: "vendordashboard/offer",
    name: "Offer",
    element: <Offer />,
  },
  {
    path: "vendordashboard/addlist",
    name: "Addlist",
    element: <Addlist />,
  },
  {
    path: "vendordashboard/enquiry",
    name: "Enquiry",
    element: <Enquiry />,
  },
  {
    path: "vendordashboard/packages",
    name: "Packages",
    element: <Packages />,
  },
  {
    path: "packageoffers",
    name: "Packageoffers",
    element: <Packageoffers />,
  },
];

export default Routers;
