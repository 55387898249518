import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import "../../../Components/Css/Students/Studintdetails.scss";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";
import RequestRedirect from "../RequestRedirect";
import DataListTable from "../../Admin/Dashboard/DataListTable";

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = eventKey === activeEventKey;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#EBE6FF",
        paddingTop: "12px",
      }}
    >
      <p onClick={decoratedOnClick}>
        {children}
        {isCurrentEventKey ? <FiChevronUp /> : <FiChevronDown />}
      </p>
    </div>
  );
}

function ViewRequest() {
  const [createForm, setcreateForm] = useState([]);
  const navigate = useNavigate();
  const id = localStorage.getItem("id");

  useEffect(() => {
    getCreateForm();
  }, []);
  const getCreateForm = async () => {
    await Api.get(`Internship/getInternship/${id}`).then((res) => {
      setcreateForm(res.data.data);
    });
  };
  console.log("createForm", createForm);
  const companyName = localStorage.getItem("companyName");

  const columns = [
    {
      title: "Job Role",
      dataIndex: "jobRole",
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
    },
    {
      title: "Job Description",
      dataIndex: "jobDescription",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <div>
          {console.log("recordJobid", record)}
          <Button
            type="primary"
            onClick={() =>
              navigate(`/employer/${companyName}/internshiprequest`, {
                state: record,
              })
            }
          >
            View Applicants
          </Button>
        </div>
      ),
    },
  ];
  console.log("createForm", createForm);
  const handleSearch = (e) => {
    const jobTitle = e.target.value;
    const newData = createForm.filter((list) =>
      list.jobTitle.toLowerCase().includes(jobTitle.toLowerCase())
    );
    setcreateForm(newData);
    if (jobTitle === "") {
      setcreateForm();
    }
  };

  return (
    <div className="p-4">
      <DataListTable
        showAddNewBtn={false}
        columns={columns}
        dataList={createForm}
        handleSearch={handleSearch}
        title="Job List"
      />
    </div>
  );
}

export default ViewRequest;
