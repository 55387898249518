import React from 'react'

const ApprovedList = () => {
  return (
    <div>
      ApprovedList
    </div>
  )
}

export default ApprovedList
