import React from "react";
import { Container } from "react-bootstrap";
import { BiRupee, BiTimeFive } from "react-icons/bi";
import { CiCalendarDate, CiLocationOn } from "react-icons/ci";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { TfiBag } from "react-icons/tfi";
import { useLocation } from "react-router-dom";
import "../../../Components/Css/Students/Student.scss";

function SeminarsInnerPage() {
  const location = useLocation();

  const viewSeminar = location.state;

  console.log("viewSeminar", viewSeminar);
  return (
    <Container className="custom-container mt-5">
      <div>
        <h4 className="text-center mt-3">SEMINAR DETAILS</h4>
      </div>
      <div className="seminarpage mt-3 ms-4">
        <h3 className="studseminar">{viewSeminar.seminarTitle}</h3>
        <h5 style={{ marginLeft: "2%" }}>{viewSeminar.technology}</h5>
        <div className="ms-4">
          <div className="studseminardetail">
            <h6>Location:</h6>
            <p className="studsemimode">{viewSeminar.SeminarMode}</p>
          </div>
          <div className="studseminardetail">
            <h6>Date:</h6>
            <p className="seminardate">
              {viewSeminar.fromdate} - {viewSeminar.todate}
            </p>
          </div>
          <div className="studseminardetail">
            <h6>Time:</h6>
            <p className="seminartime"> {viewSeminar.time} </p>
          </div>
          <div className="studseminardetail">
            <h6>Contact Person:</h6>
            <p className="seminarconract">{viewSeminar.contactPerson}</p>
          </div>
          <div className="studseminardetail">
            <h6>Contact Number:</h6>
            <p className="seminarnumber">{viewSeminar.contactNumber}</p>
          </div>
          <div className="studseminardetail">
            <h6>Fees:</h6>
            <p className="studsemifees">{viewSeminar.fees}</p>
          </div>
          <div className="studseminardetail">
            <h6>Audience:</h6>
            <p className="studsemiaudience">{viewSeminar.audience}</p>
          </div>
          <div className="studseminardetail">
            <h6>About:</h6>
            <p className="studsemiabout">{viewSeminar.about}</p>
          </div>
          <div className="studseminardetail">
            <h6>RegistrationLink:</h6>
            <p className="studsemireg" style={{ color: "blue" }}>{viewSeminar.registrationLink}</p>
          </div>
        </div>
        <br />
      </div>
    </Container>
  );
}
export default SeminarsInnerPage;
