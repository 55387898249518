import React, { useEffect, useState } from "react";
import { Table, Popconfirm } from "antd";
import { FaTrashAlt } from "react-icons/fa";
import { BsFillPenFill } from "react-icons/bs";
import Api from "../../../Api";

function FreelancingPost_AdminTable() {
  const [companyList, setCompanyList] = useState([]);
  useEffect(() => {
    getCompany();
  }, []);

  const getCompany = () => {
    Api.get("/freelancingPost//FreelancingGet").then((res) => {
      setCompanyList(res.data[0].data);
      console.log("first", res.data);
      console.log("sceond", res.data[0]);
      console.log("third", res.data.data);
    });
  };

  const columns = [
    {
      title: "job Title",
      dataIndex: "jobTitle",
    },
    {
      title: "price",
      dataIndex: "price",
    },
    {
      title: "country",
      dataIndex: "country",
    },
    {
      title: "language",
      dataIndex: "language",
    },
    {
      title: "timingForPay",
      dataIndex: "timingForPay",
    },
    {
      title: "technology",
      dataIndex: "technology",
    },
    {
      title: "description",
      dataIndex: "description",
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div>
          <BsFillPenFill style={{ cursor: "pointer" }} />
          <Popconfirm
            title="Sure to delete?"
            // onClick={(id) => {
            //   deleteCompany(id);
            // }}
            // onConfirm={() => deleteCompany(record.id)}
          >
            <FaTrashAlt style={{ paddingLeft: "10px", cursor: "pointer" }} />
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table
        columns={columns}
        dataSource={companyList}
        pagination={false}
        className="mt-2"
      />
    </div>
  );
}

export default FreelancingPost_AdminTable;
