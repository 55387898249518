import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { DatePicker } from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  LockOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "./StudentSignupForm.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Api from "../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CollegeSignupForm = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [resRes, setResRes] = useState(null);
  const [universities, setUniversities] = useState([]);
  const [colleges, setColleges] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState("");
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  useEffect(() => {
    getUniversities();
  }, []);

  const getUniversities = async () => {
    try {
      const res = await Api.get("fields/getUniversityList");
      setUniversities(res.data.data);
    } catch (error) {
      console.error("Error fetching universities:", error);
      toast.error("Failed to load universities. Please try again later.");
    }
  };

  const getColleges = async (universityId) => {
    console.log("universityId", universityId);
    setSelectedUniversity(universityId);
    try {
      const res = await Api.get(`fields/getAllCollege/${universityId}`);
      setColleges(res.data.data);
    } catch (error) {
      console.error("Error fetching colleges:", error);
      toast.error("Failed to load colleges. Please try again later.");
    }
  };

  const handleFormSubmit = async (data) => {
    const collegeDetails = {
      university: data.university,
      collegeName: data.collegeName,
      collegeType: data.collegeType,
      collegeWebsite: data.collegeWebsite,
      phone: data.phone,
      email: data.email,
      establishedYear: data.establishedYear,
      dob: data.dob,
      password: data.password,
      role: "college",
    };

    try {
      const res = await Api.post(`college/college_create`, collegeDetails);
      setResRes({
        status: res.data?.status,
        message: res.data?.message,
      });
      toast.success(res.data?.message);
      setTimeout(() => {
        navigate("/");
      }, 10000);
    } catch (err) {
      setResRes({
        status: err?.response.data?.status,
        message: err?.response.data?.message,
      });
      toast.error(err?.response.data?.message);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="signup-form-container">
      <h2 className="form-heading" style={{ color: "blue" }}>
        College Signup
      </h2>

      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Row>
          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">University</Form.Label>
              <Form.Control
                as="select"
                {...register("university", { required: true })}
                className="custom-input"
                onChange={(e) => getColleges(e.target.value)}
              >
                <option value="">Select University</option>
                {universities.map((university) => (
                  <option key={university._id} value={university._id}>
                    {university.UniversityName}
                  </option>
                ))}
              </Form.Control>
              {errors.university?.type === "required" && (
                <p className="error-text-color">University is required</p>
              )}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">College Name</Form.Label>
              <Form.Control
                as="select"
                {...register("collegeName", { required: true })}
                className="custom-input"
              >
                <option value="">Select College</option>
                {colleges.map((college) => (
                  <option key={college._id} value={college.collegeName}>
                    {college.collegeName}
                  </option>
                ))}
              </Form.Control>
              {errors.collegeName?.type === "required" && (
                <p className="error-text-color">College Name is required</p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">College Type</Form.Label>
              <Form.Control
                {...register("collegeType", { required: true })}
                className="custom-input"
                as="select"
              >
                <option value="private">Private</option>
                <option value="public">Public</option>
              </Form.Control>
              {errors.collegeType?.type === "required" && (
                <p className="error-text-color">College Type is required</p>
              )}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">College Website</Form.Label>
              <Form.Control
                {...register("collegeWebsite", {
                  required: true,
                  pattern: {
                    value: /^https?:\/\/(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                    message: "Invalid website URL",
                  },
                })}
                className="custom-input"
                placeholder="Enter College Website"
                type="text"
              />
              {errors.collegeWebsite && (
                <p className="error-text-color">
                  {errors.collegeWebsite.message}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Phone Number</Form.Label>
              <Form.Control
                {...register("phone", {
                  minLength: 10,
                  maxLength: 10,
                  required: true,
                })}
                className="custom-input"
                placeholder="Enter Phone Number"
                type="text"
              />
              {errors.phone?.type === "required" && (
                <p className="error-text-color">Phone Number is required</p>
              )}
              {errors.phone?.type === "minLength" && (
                <p className="error-text-color">
                  Minimum 10 digits are required
                </p>
              )}
              {errors.phone?.type === "maxLength" && (
                <p className="error-text-color">
                  Maximum 10 digits are required
                </p>
              )}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Email ID</Form.Label>
              <Form.Control
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                className="custom-input"
                placeholder="Enter Email"
                type="email"
              />
              {errors.email && (
                <p className="error-text-color">{errors.email.message}</p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Established Year</Form.Label>
              <DatePicker
                format="YYYY"
                // {...register("establishedYear", { required: true })}
                onChange={(date, dateString) => {
                  setValue("establishedYear", dateString);
                }}
                className="custom-input"
                picker="year"
              />
              {/* {errors.establishedYear && (
                <p className="error-text-color">Established Year is required</p>
              )} */}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Date of Birth</Form.Label>
              <DatePicker
                format="DD-MM-YYYY"
                // {...register("dob", { required: true })}
                onChange={(date, dateString) => {
                  setValue("dob", dateString);
                }}
                className="custom-input date"
              />
              {/* {errors.dob && (
                <p className="error-text-color">Date of Birth is required</p>
              )} */}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Password</Form.Label>
              <div className="input-icon-container">
                <LockOutlined className="input-icon" />
                <Form.Control
                  {...register("password", { required: true, minLength: 6 })}
                  className="custom-input"
                  placeholder="Enter Password"
                  type={passwordVisible ? "text" : "password"}
                />
                <div
                  className="eye-icon-container"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>
              </div>
              {errors.password?.type === "required" && (
                <p className="error-text-color">Password is required</p>
              )}
              {errors.password?.type === "minLength" && (
                <p className="error-text-color">
                  Password must be at least 6 characters
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Button className="next-btn" type="submit">
          Submit
        </Button>
        {resRes && (
          <div className={`response-message ${resRes.status}`}>
            <p>{resRes.message}</p>
          </div>
        )}

        <ToastContainer />
      </Form>
    </div>
  );
};

export default CollegeSignupForm;
