import React from "react";
import "./Rent.css";
import { Row, Col } from "react-bootstrap";
import { Carousel } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import lap from "../../assets/rental/lap.jpg";
import fur from "../../assets/rental/fur.jpg";
import motorbike from "../../assets/rental/motorbike.jpg";
import homeappliances from "../../assets/rental/homeappliances.jpg";
import laptop_discount from "../../assets/rental/laptop_discount.jpg";
import furniture_discount from "../../assets/rental/furniture_discount.jpg";
import electronic from "../../assets/rental/electronic.jpg";
import furniture from "../../assets/rental/furniture.jpg";
import bike from "../../assets/rental/bike.jpg";
import appliances from "../../assets/rental/appliances.jpg";
import icon_image from "../../assets/rental/icon_image.jpg";
import { useNavigate } from "react-router-dom";

function Rent() {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <Carousel>
          <Carousel.Item>
            <img className="caros_img" src={lap} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="caros_img" src={fur} alt="Second slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="caros_img" src={motorbike} alt="Third slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="caros_img"
              src={homeappliances}
              alt="Fourth slide"
            />
          </Carousel.Item>
        </Carousel>
        <br />
      </div>

      <div>
        <Row>
          <Col className="flex_product" md={3}>
            <Card className="product" onClick={() => navigate("/Electronic")}>
              <Card.Img className="product_content" src={electronic}></Card.Img>
              <Card.Title className="product_tit">
                <h3 className="product_txt">Electronic</h3>
              </Card.Title>
            </Card>
          </Col>

          <Col className="flex_product" md={3}>
            <Card className="product" onClick={() => navigate("/Bike")}>
              <Card.Img className="product_content" src={bike}></Card.Img>
              <Card.Title className="product_tit">
                <h3 className="product_txt">Bike</h3>
              </Card.Title>
            </Card>
          </Col>

          <Col className="flex_product" md={3}>
            <Card className="product" onClick={() => navigate("/Furniture")}>
              <Card.Img className="product_content" src={furniture}></Card.Img>
              <Card.Title className="product_tit">
                <h3 className="product_txt">Furniture</h3>
              </Card.Title>
            </Card>
          </Col>

          <Col className="flex_product" md={3}>
            <Card
              className="product"
              onClick={() => navigate("/Home-Appliances")}
            >
              <Card.Img className="product_content" src={appliances}></Card.Img>
              <Card.Title className="product_tit">
                <h3 className="product_txt">Home Applinces</h3>
              </Card.Title>
            </Card>
          </Col>
        </Row>
      </div>

      <div className="offer">
        <Row>
          <Col className="discount" md={6}>
            <img
              src={furniture_discount}
              alt="furniture_discount"
              className="discount"
              style={{ width: "94%" }}
            />
          </Col>

          <Col className="discount" md={6}>
            <img
              src={laptop_discount}
              alt="laptop_discount"
              className="discount"
              style={{ width: "94%" }}
            />
          </Col>
        </Row>
      </div>
      <h1 className="heading">
        <u>User's Ratings and Reviews</u>
      </h1>

      <div className="flexicon">
        <Row>
          <Col className="icon" md={3}>
            <Card
              className="review"
              // style={{ width: "15rem", height: "18rem" }}
            >
              <Card.Img
                className="icon_img"
                src={icon_image}
                // style={{ width: "7rem", height: "8rem" }}
              ></Card.Img>
              <Card.Title className="product_tit">
                <h4 className="text">Maddy</h4>
              </Card.Title>
              <p className="text">
                Aenean lacinia bibendum nulla sed consectetur Donec ullamcorper
                .
              </p>
              <Button
                variant="danger"
                className="button_ho"
                style={{ width: "170px" }}
              >
                Read More
              </Button>
            </Card>
          </Col>

          <Col className="icon" md={3}>
            <Card
              className="review"
              // style={{ width: "15rem", height: "18rem" }}
            >
              <Card.Img
                className="icon_img"
                src={icon_image}
                // style={{ width: "7rem", height: "8rem" }}
              ></Card.Img>
              <Card.Title className="product_tit">
                <h4 className="text">Maddy</h4>
              </Card.Title>
              <p className="text">
                Aenean lacinia bibendum nulla sed consectetur Donec ullamcorper
                .
              </p>
              <Button
                variant="danger"
                className="button_ho"
                style={{ width: "170px" }}
              >
                Read More
              </Button>
            </Card>
          </Col>
          <Col className="icon" md={3}>
            <Card
              className="review"
              // style={{ width: "15rem", height: "18rem" }}
            >
              <Card.Img
                className="icon_img"
                src={icon_image}
                // style={{ width: "7rem", height: "8rem" }}
              ></Card.Img>
              <Card.Title className="product_tit">
                <h4 className="text">Maddy</h4>
              </Card.Title>
              <p className="text">
                Aenean lacinia bibendum nulla sed consectetur Donec ullamcorper
                .
              </p>
              <Button
                variant="danger"
                className="button_ho"
                style={{ width: "170px" }}
              >
                Read More
              </Button>
            </Card>
          </Col>
          {/* <Col className="icon" md={3}>
            <Card
              className="review"
              // style={{ width: "15rem", height: "18rem" }}
            >
              <Card.Img
                className="icon_img"
                src={icon_image}
                // style={{ width: "7rem", height: "8rem" }}
              ></Card.Img>
              <Card.Title className="product_tit">
                <h4 className="text">Maddy</h4>
              </Card.Title>
              <p className="text">
                Aenean lacinia bibendum nulla sed consectetur Donec ullamcorper
                .
              </p>
              <Button variant="danger" className="button_ho">
                Read More
              </Button>
            </Card>
          </Col> */}
        </Row>
      </div>
    </div>
  );
}
export default Rent;
