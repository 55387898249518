import React from "react";
import { RiSuitcaseLine } from "react-icons/ri";
import { TbWorld } from "react-icons/tb";
import { BiLocationPlus } from "react-icons/bi";
import "../../../Components/Css/Professional/FreelancingPay_Clickhere.scss";

const FreelancingPay_Clickhere = () => {
  return (
    <div className="job_description_title">
      <div className="description_content">
        <h4 className="job_title_heading">Web Development</h4>
        <div className="heading_class ">Company Name</div>
        <li className="sub_heading_class "> Cross Hurdle System</li>
        <div className="heading_class mt-2">Location</div>
        <li className="sub_heading_class">
          <label>
            {" "}
            <BiLocationPlus />
          </label>
          <span style={{ marginLeft: "5px" }}>Chennai</span>
        </li>
        <div className="heading_class mt-2">Technology</div>
        <li className="sub_heading_class">
          <label>
            {" "}
            <TbWorld />
          </label>
          <span style={{ marginLeft: "5px" }}>React</span>
        </li>
        <div className="heading_class mt-2">Experience</div>
        <li className="sub_heading_class">
          {" "}
          <label>
            {" "}
            <RiSuitcaseLine />
          </label>
          <span
            style={{
              marginLeft: "5px",
            }}
          >
            1-2yrs
          </span>
        </li>
        <div className="heading_class mt-2">Job Description</div>
        <li className="sub_heading_class">Strong programming skills (React)</li>
        <li className="sub_heading_class">Excellent communication skills</li>
        <li className="sub_heading_class">
          Knowledge of multiple front-end languages and libraries (e.g. HTML/
          CSS, JavaScript, XML,jQuery)
        </li>
        <li className="sub_heading_class">
          {" "}
          Required experience with REST APIs (ExpressJS), Server-side
          development using Node JS
        </li>
        <div className="description_button_container">
          <button className="description_button mt-5 mb-3">contact us</button>
          <button className="description_button1 mt-5 mb-3 ms-3">Apply</button>
        </div>
      </div>
    </div>
  );
};

export default FreelancingPay_Clickhere;
