import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Dropdown } from "antd";
import "../../../Components/Css/Students/Student.scss";
import Api from "../../../Api";
import 'aos/dist/aos.css';
import AOS from 'aos';

function BlogCollege() {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Set animation duration in milliseconds
      easing: 'ease-in-out', // Animation easing
    });
  }, []);

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);
  useEffect(() => {
    getDomain();
    getStudentDetails();
  }, []);

  const [details, setDetails] = useState([]);
  const userId = localStorage.getItem("personalId");
  // const userId = "641ab4155fbaed089ae3b638";

  const getStudentDetails = () => {
    Api.get(`studentdata/getonepersonal/${userId}`).then((res) => {
      console.log("res", res);
      setDetails(res.data.data);
    });
  };

  console.log("details", details);

  const [domainList, setDomainList] = useState();
  const [domain, setDomain] = useState();

  const getDomain = async () => {
    await Api.get("domainProfile/getDomain").then((res) => {
      setDomainList(res.data.data);
    });
  };

  console.log("details", details);

  const handleFormSubmit = async () => {
    const Details = {
      studentName: details.firstName,
      email: details.email,
      registerId: details.registerId,
      domain: getValues().domain,
      subdomain: getValues().subdomain,
      // Image: getValues().Image,
      Tag: getValues().Tag,
      description: getValues().description,
    };
    console.log(getValues(), "getgetget");
    const data = new FormData();
    data.append("blog", getValues().Image[0]);

    await Api.post("blog/uploadImage", data).then(async (res) => {
      Details.Image = res.data.path;
      console.log("res.data.path", res.data.path);
      await Api.post(`blog/createBlog`, Details).then((resp) => {
        console.log("resp.data", resp.data);
      });
    });
  };

  function handleChange(e) {
    console.log(e.target.files);
    // setFile(URL.createObjectURL(e.target.files[0]));
  }

  return (
    <div className="p-5"  data-aos="fade-down-right">
      <div className="pages-title mb-3">Blog</div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="">
          <Row className="mb-3">
            <Col sm={24} md={8} className="Stud-blog-col p-2">
              <label>Domain</label>
              <br />

              <select
                className="Stud-blog-input"
                {...register("domain", { required: true })}
              >
                <option value="">Select Domain</option>
                <option value="IT">IT</option>
                <option value="Construction">Construction</option>
                <option value="Hr">Hr</option>
                <option value="Mech">Mech</option>
              </select>
              <br />
              {errors.domain && (
                <span className="Stud-blog-error">Domain is required</span>
              )}
            </Col>
            <Col sm={24} md={8} className="Stud-blog-col p-2">
              <label></label>
              <select
                className="Stud-blog-input"
                {...register("subdomain", { required: true })}
              >
                <option value="">Select </option>
                <option value="Software">Software</option>
                <option value="Hardware">Hardware</option>
                <option value="Engineer">Engineer</option>
                <option value="Finance">Finance</option>
              </select>
              <br />
              {errors.subdomain && (
                <span className="Stud-blog-error"> is required</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={24} md={8} className="Stud-blog-col p-2">
              <label>Tag</label>
              <input
                type="title"
                className="Stud-blog-input"
                {...register("Tag", { required: true })}
              />
              <br />
              {errors.Tag && (
                <span className="Stud-blog-error">Tag required</span>
              )}
            </Col>

            <Col sm={24} md={8} className="Stud-blog-col p-2">
              <label>Upload Image</label>
              <input
                type="file"
                className="Stud-blog-input"
                onChange={(e) => {
                  handleChange(e);
                }}
                {...register("Image", { required: true })}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={24} md={8} className="Stud-blog-col p-2">
              <label>Description</label>
              <textarea
                rows="3"
                className="Stud-blog-input"
                {...register("description", { required: true })}
              />
              {errors.description && (
                <span className="Stud-blog-error">Description required</span>
              )}
            </Col>
          </Row>
          <div className="submitbuttons p-2">
            <button className="button1 m-2 p-2" type="submit">
              Submit
            </button>
            <button className="button2 m-2 p-2" type="reset">
              Reset
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default BlogCollege;
