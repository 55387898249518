import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Select } from "antd";
// import "../../../Components/Css/professionalstyle.scss";
import "../../../Components/Css/Professional/professionalstyle.scss";
import Api from "../../../Api.js";
import { Option } from "antd/lib/mentions";

function PersonalInfo() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({});

  // const onSubmit = data => console.log(data);

  const [countryList, setcountryList] = useState([]);
  const [countryValue, setCountryValue] = useState();
  const [stateList, setstateList] = useState([]);
  const [stateValue, setStateValue] = useState();
  const [districtList, setdistrictList] = useState([]);
  const [districtValue, setDistrictValue] = useState();
  // const [cityList, setcityList] = useState([]);
  // const [cityValue, setCityValue] = useState();
  const [genderList, setGenderList] = useState();
  const [gender, setGender] = useState();
  const [maritalList, setMaritalList] = useState();
  const [Marital, setMarital] = useState();
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    getDetails();
    getCountry();
    getGender();
    getMarital();
  }, []);

  const getMarital = async () => {
    await Api.get("marital/getMarital").then((res) => {
      setMaritalList(res.data.data);
    });
  };

  const getGender = async () => {
    await Api.get("gender/getGenderlist").then((res) => {
      setGenderList(res.data.data);
    });
  };
  const getCountry = async () => {
    await Api.get("country/getallcountry").then((res) => {
      setcountryList(res.data.data);
    });
  };

  const getState = (country_id) => {
    console.log("stateList", country_id);
    setCountryValue(country_id);
    Api.get(`state/stateById/${country_id}`).then((res) => {
      setstateList(res.data.data);
      setValue("country", country_id);
    });
  };

  const getDistrict = (state_id) => {
    setStateValue(state_id);
    Api.get(`district/districtById/${state_id}`).then((res) => {
      setdistrictList(res.data.data);
      setValue("state", state_id);
    });
  };
  // const getCity = (district_Id) => {
  //   setDistrictValue(district_Id);
  //   Api.get(`city/cityById/${district_Id}`).then((res) => {
  //     setcityList(res.data.data);
  //     setValue("district", district_Id);
  //   });
  // };

  const userId = localStorage.getItem("userId");

  const getDetails = async () => {
    try{
      const res = await Api.get(`employeeUser/employee_getone/${userId}`);
      setUserData(res.data.data);
    }
    catch (err) {
      console.log(err);
    }
  };

  const handleFormSubmit = async () => {
    const formData = getValues();
    const updatedData = { ...userData, ...formData };
    let countryName;
    let stateName;
    let districtName;
    if (countryValue !== userData?.country) {
      countryName = countryList.find(
        (country) => country.id === countryValue
      ).name;
    } else {
      countryName = userData?.country;
    }

    if (stateValue !== userData?.state) {
      stateName = stateList.find((state) => state.id === stateValue).name;
    } else {
      stateName = userData?.state;
    }

    if (districtValue !== userData?.district) {
      districtName = districtList.find(
        (district) => district.id === districtValue
      ).name;
    } else {
      districtName = userData?.district;
    }
    const Details = {
      ...updatedData,
      gender: gender,
      maritalStatus: Marital,
      dob: formData.dob,
      address: formData.address,
      country: countryName,
      state: stateName,
      district: districtName,
    };
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "darshan");

    const response = await fetch("https://api.cloudinary.com/v1_1/dzblzw7ll/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    const cloudinaryData = await response.json();
    Details.profilePhoto = cloudinaryData.secure_url;
    await Api.put(`employee/Employee_update/${userId}`, Details).then(
      (resp) => {
        console.log("resp.data", resp.data);
      }
    );
  };
  //////////
  const [file, setFile] = useState();
  const [imagePreview, setImagePreview] = useState();

  function handleChange(e) {
    console.log(e.target.files);
    setFile(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  }
  ///////
  useEffect(() => {
    if (userData) {
      setValue("firstName", userData.firstName);
      setValue("lastName", userData.lastName);
      setValue("email", userData.email);
      setValue("address", userData.address);
      setValue("dob", userData.dateofBirth);
      setValue("phone", userData.phone);
      setValue("pincode", userData.pincode);
      setValue("city", userData.city);
      setCountryValue(userData.country);
      setStateValue(userData.state);
      setDistrictValue(userData.district);
      setGender(userData.gender);
      setMarital(userData.maritalStatus);
    }
  }, [userData]);
  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="personal-informations p-4">
          <div className="pages-title mb-3">Personal Info</div>
          <Row className="mb-4">
            <Col sm={24} md={24} xs={8} className="px-4">
              <div>
                { imagePreview ?
                (<img
                  src={imagePreview}
                  alt=""
                  style={{
                    width: "200px",
                    height: "200px",
                  }}/>) : (<img
                    src={userData?.profilePhoto}
                    alt=""
                    style={{
                      width: "200px",
                      height: "200px",
                    }}/> )
                }
              </div>
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <br />
              <label className="input-title">Upload Photo</label>
              <br />
              <input type="file" onChange={handleChange} />
              {errors.photo && (
                <span className="input-error">Photo is required</span>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">First Name</label>
              <input
                defaultValue={userData?.firstName}
                className="Professional__UpdateProfile__Input"
                {...register("firstName", { required: true })}
              />
              <br />
              {errors.firstname && (
                <span className="input-error">First Name required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Last Name</label>
              <input
                defaultValue={userData?.LastName}
                className="Professional__UpdateProfile__Input"
                {...register("lastName", { required: true })}
              />
              <br />
              {errors.lastname && (
                <span className="input-error">Last Name required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Phone Number</label>
              <input
                defaultValue={userData?.phone}
                className="Professional__UpdateProfile__Input"
                {...register("phone", { required: true })}
              />
              {errors.phoneNumber && (
                <span className="input-error"> Number required</span>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Date of Birth </label>
              <input
                defaultValue={userData?.dob}
                className="Professional__UpdateProfile__Input"
                type="date"
                {...register("dob", { required: true })}
              />
              <br />
              {errors.dob && <span className="input-error">DOB required</span>}
            </Col>

            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Gender </label>
              {/* <Dropdown
                className="input-field"
                value={gender}
                options={genderList}
                {...register("gender", { required: true })}
                optionLabel={"gender"}
                optionValue={"_id"}
                onChange={(e) => setGender(e.value)}
                placeholder="Select a Gender"
              /> */}
              <Select
                className="input-field"
                value={gender}
                onChange={(value) => setGender(value)}
                placeholder="Select a Gender"
                style={{ border: "none" }}
              >
                {genderList?.map((option) => (
                  <Option key={option._id} value={option.gender}>
                    {option.gender}
                  </Option>
                ))}
              </Select>
              <br />
              {errors.gender && (
                <span className="input-error">Gender required</span>
              )}
            </Col>

            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Marital status </label>
              {/* <Dropdown
                className="Professional__UpdateProfile__Input"
                value={Marital}
                options={maritalList}
                {...register("maritalStatus", { required: true })}
                optionLabel={"Marital"}
                optionValue={"_id"}
                onChange={(e) => setMarital(e.value)}
                placeholder="Select a Marital status"
              /> */}
              <Select
                className="input-field"
                value={Marital}
                onChange={(value) => setMarital(value)}
                placeholder="Select a Marital status"
                style={{ border: "none" }}
              >
            {maritalList?.map((option) => (
              <Option key={option._id} value={option.Marital}>
              {option.Marital}
            </Option>
          ))}
              </Select>
              {errors.maritalstatus && (
                <span className="input-error">status required</span>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Email Id </label>
              <input
                // defaultValue={userData?.email}
                className="Professional__UpdateProfile__Input"
                {...register("email", { required: true })}
              />
              <br />
              {errors.email && (
                <span className="input-error">Email required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Address</label>
              <textarea
                defaultValue={userData?.address}
                rows="1"
                className="Professional__UpdateProfile__Input"
                {...register("address", { required: true })}
              />

              {errors.address && (
                <span className="input-error">Address required</span>
              )}
            </Col>

            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Country </label>
              {/* <Dropdown
                filter
                className="input-field"
                value={countryValue}
                options={countryList}
                {...register("country", { required: true })}
                optionLabel={"name"}
                optionValue={"id"}
                onChange={(e) => getState(e.value)}
              /> */}
              <Select
                className="input-field"
                value={countryValue}
                onChange={(value) => {
                  setCountryValue(value);
                  getState(value);
                }}
                filterOption={(input, option) => {
                  return option?.label
                    ?.toLowerCase()
                    .includes(input.toLowerCase());
                }}
                optionLabelProp="label"
                placeholder="Select a Country"
                style={{ border: "none" }}
              >
                {countryList?.map((country) => (
                  <Option key={country.id} value={country.id} label={country.name}>
                    {country.name}
                  </Option>
                ))}
              </Select>
              {errors.country && (
                <p className="error-text-color">country is required</p>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">State</label>
              {/* <Dropdown
                filter
                className="input-field"
                value={stateValue}
                options={stateList}
                {...register("State", { required: true })}
                optionLabel={"name"}
                optionValue={"id"}
                onChange={(e) => getDistrict(e.value)}
              > */}
              <Select
                className="input-field"
                value={stateValue}
                onChange={(value) => {
                  setStateValue(value);
                  getDistrict(value);
                }}
                placeholder="Select a State"
                style={{ border: "none" }}
              >
                {stateList?.map((state) => (
                  <Option key={state.id} value={state.id}>
                    {state.name}
                  </Option>
                ))}
              </Select>
              <br />
              {errors.State && (
                <span className="input-error">State is required</span>
              )}
            </Col>

            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">District</label>

              {/* <Dropdown
                filter
                className="input-field"
                value={districtValue}
                options={districtList}
                {...register("district", { required: true })}
                optionLabel={"name"}
                optionValue={"id"}
                onChange={(e) => getCity(e.value)}
              /> */}
              <Select
                className="input-field"
                value={districtValue}
                onChange={(value) => {
                  setDistrictValue(value);
                  // getDistrict(value);
                }}
                placeholder="Select a District"
                style={{ border: "none" }}
              >
                {districtList?.map((district) => (
                  <Option key={district.id} value={district.id}>
                    {district.name}
                  </Option>
                ))}
              </Select>
              <br />
              {errors.district && (
                <span className="input-error">District is required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">City</label>
              {/* <Dropdown
                filter
                className="input-field"
                value={cityValue}
                options={cityList}
                {...register("city", { required: true })}
                optionLabel={"cityName"}
                optionValue={"_id"}
                onChange={(e) => setCityValue(e.value)}
                placeholder="Select a city"
              /> */}
              <input
                className="input-field"
                defaultValue={userData?.cityValue}
                placeholder="Select a City"
                style={{ border: "none" }}
                {...register("city", { required: true })}
              />
              {errors.city && <p className="text-danger">City is required</p>}
            </Col>
          </Row>
          <Row>
          <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Pincode</label>

              <input
                type="number"
                className="Professional__UpdateProfile__Input"
                {...register("pincode", { required: true })}
              />

              {errors.pincode && (
                <span className="input-error">Pincode is required</span>
              )}
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4">
              <label className="input-title">Upload Resume</label>
              <input
                type="file"
                className="Professional__UpdateProfile__Input"
                onChange={handleChange}
              />
              {errors.resume && (
                <span className="input-error">Resume is required</span>
              )}
            </Col>
          </Row>

          <div className="submitbuttons px-4">
            <button className="button1 m-2 p-2" type="submit">
              Submit
            </button>
            <button className="button2 m-2 p-2" type="reset">
              Reset
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default PersonalInfo;
