import React from "react";
import "./AccessoriesItem.css";
import { Col, Row, Button, Card } from "react-bootstrap";
import { BsFilter } from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

function AccessoriesItem() {
  const ctr = [  
    {
      Image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyM5NrcQfJa16H9PSPMULXb3B-ICudQ3_VOgKJ-P9R7spS4h5A4SPio7DLw_IKRm3cbfg&usqp=CAU",
      Title: "Watches",
      price: "Rs:9,000",
    },
    {
      Image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSchU0OZS-OGNb5nnp1Ud51W1Q1tlCTOSra9CSzRoHTreTgTWoIkwszJi6XMeSPp5cckhE&usqp=CAU",
      Title: "Watches",
      price: "Rs:2,980",
    },
    {
      Image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQC0-Ajn4NvPGTtMaSiEkmPY4kI9J8XkXfh6g&usqp=CAU",
      Title: "Watches",
      price: "Rs:7,999",
    }
  ]
  const ctr1=[
    {
      Image:
        "https://mgdm.in/wp-content/uploads/sites/452/2022/12/4.jpg",
      Title: "Jewellery",
      price: "Rs:1,45,000",
    },
    {
      Image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwTV8-Nuge2GCSzGiWBIF0d3h5I32Lrt4sGFdgEIuu24-eVlT5EM51fLvaaeVVPAEtL6M&usqp=CAU",
      Title: "Jewellery",
      price: "Rs:2,40,999",
    },
    {
      Image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJUdU_R7_6Z--70G6ND-wjzA7Snv8OSW7N5zYBgpfrypf-eQPxd-JKPzpWluipRqGGiYI&usqp=CAU",
      Title: "Jewellery",
      price: "Rs:1,25,699",
    }
    
  ]
const ctr2=[
        {Image:
         "https://cdn.shopify.com/s/files/1/0084/1616/5946/products/Back_9_Blackout_3Q_600x600.jpg?v=1669927712",
         Title: "Sunglasses",
         price: "Rs:999",
    },

    {
      Image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQekPDhINe0oz99KTRTWRaWGAE6qVbJ4tRWkjjh43zX_7fYRqZSszLGi3IETrfSArG8U2E&usqp=CAU",
      Title: "Sunglasses",
      price: "Rs:1099",
    },
    {
      Image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOxV0N3j_uUpWLvqCg1vQf-rTvHtM9XfkT8Fv7t_ZwFPEnqMtb0WVW1LUm8Ic5tfV1Xzk&usqp=CAU",
      Title: "Sunglasses",
      price: "Rs:990",
    }
  ]
  const ctr3=[
    {
      Image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQveWsTcr0Tg7NuYqOd20U-jCerHKhDr44h0Jne0FIoI9V5u9PylUufmW_CbPSmkNOy_4I&usqp=CAU",
      Title: "HandBags",
      price: "Rs:10,999",
    },
    {
      Image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjPQ-i4HoEU7kQMnawXgBix0WAokpcq1pEF8hDsX_9IGzXRI0lJW5ozna3mQoEU_MAbzE&usqp=CAU",
      Title: "HandBags",
      price: "Rs:8,500",
    },
    {
      Image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_KBiGzqo9FI-SS4_HW6YR1h7RcAezNmK0Rw&usqp=CAU",
      Title: "HandBags",
      price: "Rs:11,000",
    }
  ];
const navigate= useNavigate();
  return (
    <div>
      <div>
        <Row>
       
          <Col
            md={3}
            style={{ backgroundColor: "#746262", color:"white", textAlign:"center"}}
            className="Filterspart" >
                <div className="Filterpart">
            <h4>
              Filters <BsFilter size={30} />
            </h4>          
              <form className="FilterItems">               
                <select style={{ width: "50%" }}>
                  <option value="1"><b>Product Name</b></option>
                  <option value="2">Watches</option>
                  <option value="3">Jewellery</option>
                  <option value="4">Sunglasses</option>
                  <option value="5">HandBags</option>
                </select>
              </form>           
            <Row>
              <form className="FilterItems">                
                <select style={{ width: "50%" }}>
                  <option value="1"><b> Price</b></option>
                  <option value="2"> 11,500 Above</option>
                  <option value="2"> 3400 - 11,500</option>
                  <option value="3"> 2800 - 3200 </option>
                  <option value="4"> 2400 - 2800 </option>
                  <option value="5"> 2000 - 2400 </option>                
                </select>
              </form>
            </Row>
            <Row>
              <form  className="FilterItems">
                <select style={{ width: "50%" }}>
                  <option value="0"><b>Month</b></option>
                  <option value="1">1 Month</option>
                  <option value="2">2 Month</option>
                  <option value="3">3 Month</option>
                  <option value="4">4 Month</option>
                  <option value="5">5 Month</option>
                  <option value="6">6 Month</option>
                  <option value="7">7 Month</option>
                  <option value="8">8 Month</option>
                  <option value="9">9 Month</option>
                  <option value="10">10 Month</option>
                  <option value="11">11 Month</option>
                  <option value="12">12 Month</option>
                </select>
              </form>
            </Row>
            <Row>
              <form className="FilterItems">
                <select style={{ width: "50%" }}>
                  <option value="1"><b>Number of Item</b></option>
                  <option value="2">1</option>
                  <option value="3">2</option>
                  <option value="4">3</option>
                  <option value="5">4</option>
                  <option value="6">5</option>
                  <option value="7">6</option>
                </select>               
              </form>
            </Row>

            <Row className="FilterItems">
              <Button variant="danger" className="button1" style={{width:"50%"}}>
                Apply
              </Button>
            </Row>
            </div>
          </Col>
          

          <Col>
            <div className="CartList1">
              <Row>
                {ctr.map((ctr, i) => (
                  <Col className="CartList2 p-3" sm={12} md={6} lg={4}>
                    <Card style={{ width: "18rem" }} onClick={()=>navigate("/watches-page")}>
                      <Card.Img variant="top" src={ctr.Image}/> 
                      <Card.Body>
                        <Card.Title>{ctr.Title}</Card.Title>
                        <Card.Text>{ctr.price}</Card.Text>
                        
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
                {ctr1.map((ctr1, i) => (
                  <Col className="CartList2 p-3" sm={12} md={6} lg={4}>
                    <Card style={{ width: "18rem" }} onClick={()=>navigate("/jewellery_page")}>
                      <Card.Img variant="top" src={ctr1.Image}/> 
                      <Card.Body>
                        <Card.Title>{ctr1.Title}</Card.Title>
                        <Card.Text>{ctr1.price}</Card.Text>
                        
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
                {ctr2.map((ctr2, i) => (
                  <Col className="CartList2 p-3" sm={12} md={6} lg={4}>
                    <Card style={{ width: "18rem" }} onClick={()=>navigate("/sunglasses_page")}>
                      <Card.Img variant="top" src={ctr2.Image}/> 
                      <Card.Body>
                        <Card.Title>{ctr2.Title}</Card.Title>
                        <Card.Text>{ctr2.price}</Card.Text>
                        
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
                {ctr3.map((ctr3, i) => (
                  <Col className="CartList2 p-3" sm={12} md={6} lg={4}>
                    <Card style={{ width: "18rem" }} onClick={()=>navigate("/handbags-page")}>
                      <Card.Img variant="top" src={ctr3.Image}/> 
                      <Card.Body>
                        <Card.Title>{ctr3.Title}</Card.Title>
                        <Card.Text>{ctr3.price}</Card.Text>
                        
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>               
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default AccessoriesItem;
