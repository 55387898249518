import React, { useEffect, useState } from "react";
import { Table, Button, Input, Popconfirm, Modal } from "antd";
import "../../../Components/Css/Table.scss";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import DataListTable from "../datalistTable";
function QuestionBankWeek() {
  const [techList, setTechList] = useState(null);
  const [dailogVal, setDailogVal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const[getData , setGetData]=useState([{week:"1"},{week:"2"}]);
  const [getData, setGetData] = useState([]);

  const [search, setSearch] = useState();

  console.log(techList && techList.totalWeeks.week, "ghjhjhgjh");
  const location = useLocation();

  const id = location.state && location.state.record.id;

  useEffect(() => {
    if (location.state) {
      getWeek();
    }
  }, []);

  const getWeek = async () => {
    console.log("object :>> ", id);
    await Api.get(`QuestionBank/getid/${id}`).then((res) => {
      console.log(res.data.totalWeeks, "////");
      setTechList(res.data);
    });
  };

  const update = () => {
    // navigate("question",{state:{record}})
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const week = e.target.value;
    console.log(typeof week);

    const newData = techList.totalWeeks.filter((list) =>
      list.totalWeeks.toLowerCase().includes(week.toLowerCase())
    );

    setGetData(newData);
  };
  // console.log(search,"get")

  // const handleModalprops = (id) => {
  //   const filterdVal = techList.filter((ele) => {
  //     return ele._id === id;
  //   });
  //   setDailogVal(filterdVal[0]);
  //   return;
  // };

  const columns = [
    {
      title: "Week",
      dataIndex: "week",
    },
    {
      //   title: "view",
      //   dataIndex: "view",
      title: "view",
      dataIndex: "action",
      render: (_, record) => (
        <div>
          {console.log("jdlkjklds@@@@", record)};
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              navigate("questionBank", { state: { record } });
            }}
          />
        </div>
      ),
    },
  ];

  const navigate = useNavigate();
  return (
    <div>
      <div className="data_list_main">
        <h4 className="data_list_header">Weeks </h4>
        <div className="data_list_top mt-4 py-4 px-3">
          <input
            className="data_list_search ps-2"
            placeholder="search"
            columns={columns}
            // dataList={techList}
            value={search}
            onChange={handleSearch}
            // enterButton
          />

          <Button
            className="data_list_button"
            onClick={() => navigate("qcreate")}
          >
            <BsFillPersonPlusFill />
            &nbsp;Add New
          </Button>
        </div>
        <div className="data_list_table">
          <Table
            columns={columns}
            dataSource={(techList && techList.totalWeeks) || []}
            pagination={true}
            className="mt-2"
          />
        </div>
      </div>
    </div>
  );
}

export default QuestionBankWeek;
