import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Row, Col } from "antd";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";

function InterviewPage() {
  const [createForm, setcreateForm] = useState([]);
  const navigate = useNavigate();
  const id = localStorage.getItem("id");

  useEffect(() => {
    getCreateForm();
  }, []);

  const getCreateForm = async () => {
    await Api.get(`schedule/getOneDetails/${id}`).then((res) => {
      setcreateForm(res.data.data);
    });
  };
  const handleSubmit = (data) => {
    navigate(`/employer/:companyName/ViewSchedule/${data._id}`, {
      state: data,
    });
  };

  return (
    <div>
      <Row className="p-3">
        {createForm.map((data, i) => (
          <Col sm={24} md={24} lg={12} className="p-3">
            <Card
              onClick={() => {
                handleSubmit(data);
              }}
            >
              <Card.Body>
                <h4>{data.Name}</h4>
                <p className="Int-para2">
                  <b>Date:</b> {data.Date}
                </p>
                <p className="Int-para2">
                  <b>Time:</b> {data.Time}
                </p>
                <p className="Int-para2">
                  <b>Mode:</b> {data.Mode}
                </p>
                <p className="Int-para2">
                  <b>Role:</b> {data.Mode}
                </p>
                <p className="Int-para2">
                  <b>AssignTo:</b> {data.AssignTo}
                </p>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default InterviewPage;
