import React from "react";
import { Tabs } from "antd";
import ContractRequest from "./ContractRequest";
import "../../../../Components/Css/Employer/ContractStaffing.scss";
import ContractRequests from "./ContractRequests";

function Indexs() {
  return (
    <div>
      <Tabs defaultActiveKey="1" centered className="p-3">
        <Tabs.TabPane tab="Required value" key="1">
          <ContractRequest />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Response value" key="2">
          <ContractRequests />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default Indexs;
