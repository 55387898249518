import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import Api from "../../Api";
import "./EmailStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { getEmailVerification } from "../../Store/Action/emailVerificationAction";
import { Spinner } from "react-bootstrap";
const EmailVerify = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.email);
  const [isLoading, setIsLoading] = useState(true);
  const param = useParams();
  console.log("dataddddddd", data);
  useEffect(() => {
    verifyEmailUrl();
  }, []);

  const verifyEmailUrl = async () => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_DEV_BASE_URL}signup/student/user/${param.id}/verify/${param.token}`
        )
        .then(() => {
          dispatch(getEmailVerification(true));
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {data.verifyUrl === true ? (
            <div className="container" style={{ textAlign: "center" }}>
              <img
                src="https://static.vecteezy.com/system/resources/previews/015/123/644/original/email-with-document-and-round-green-check-mark-icon-successful-verification-concepts-email-icon-png.png"
                alt="success_img"
                style={{ width: "148px", height: "141px", marginTop: "18%" }}
              />
              <h1 className="mt-4">Email verified successfully</h1>
              <Link to="/login">
                <button className="green_btn mt-2">Login</button>
              </Link>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <img
                src="https://static.vecteezy.com/system/resources/previews/015/123/644/original/email-with-document-and-round-green-check-mark-icon-successful-verification-concepts-email-icon-png.png"
                alt="success_img"
                style={{ width: "148px", height: "141px", marginTop: "15%" }}
              />
              <h1 className="mt-5">Your Email Id is already verified</h1>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EmailVerify;
