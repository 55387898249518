import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Api from '../../../Api';
import './quiz.scss';
import { Row, Col, Select, Button, Input } from "antd";

const Questions = () => {
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [result, setResult] = useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
  });
  const [timeLeft, setTimeLeft] = useState(0);
  const location = useLocation();
  const [filteredQuestion, setFilteredQuestion] = useState();
  const interValRef = useRef();
  const q = filteredQuestion?.flatMap(item => item);
  const questions = q;

  const { choice, _id, quest, correctOption } = (questions && questions.length > 0) ? questions[activeQuestion] : {};

  const onClickNext = () => {
    setSelectedAnswerIndex(null);
    setResult((prev) =>
      selectedAnswer
        ? {
          ...prev,
          correctAnswers: prev.correctAnswers + 1,
        }
        : { ...prev, wrongAnswers: prev.wrongAnswers + 1 }
    );
    if (activeQuestion !== questions.length - 1) {
      setActiveQuestion((prev) => prev + 1);
    } else {
      setActiveQuestion(0);
      setShowResult(true);
    }
  };

  const onAnswerSelected = (answer, index) => {
    setSelectedAnswerIndex(index);
    setSelectedAnswer(answer === correctOption);
  };

  const resetQuiz = () => {
    setActiveQuestion(0);
    setShowResult(false);
    setResult({
      correctAnswers: 0,
      wrongAnswers: 0
    });
  };

  const getQuestion = async () => {
    await Api.get("QuestionBank/get").then((res) => {
      let filtered_data = [];
      let filtered_questions = [];

      for (let i = 0; i < res.data.length; i++) {
        let technology = res.data[i];
        for (let j = 0; j < technology.question.length; j++) {
          let question = technology.question[j];
          if (question.week === location.state.data.Week &&
            location.state.filterData.some(filterItem => filterItem.Technology.includes(technology.Technology))) {
            filtered_questions.push(question);
          }
        }
        if (filtered_questions.length > 0) {
          let filtered_technology = {
            Technology: technology.Technology,
            question: filtered_questions.slice(0, 5)
          };
          filtered_data.push(filtered_technology);
        }
        filtered_questions = [];
      }

      setFilteredQuestion(filtered_data.map(item => item.question.slice(0, 5)));
    });
  };

  useEffect(() => {
    getQuestion();
  }, []);

  useEffect(() => {
    const totalTimePerQuestion = 60; // 60 seconds per question
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          if (activeQuestion < questions.length - 1) {
            setActiveQuestion((prevQuestion) => prevQuestion + 1);
          } else {
            setShowResult(true);
          }
          return totalTimePerQuestion;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [activeQuestion, questions]);

  useEffect(() => {
    setTimeLeft(60); // Reset time when active question changes
  }, [activeQuestion]);

  const addLeadingZero = (number) => (number > 9 ? number : `0${number}`);

  return (
    <div>
      <div className="quiz-container">
        {!showResult ? (
          <div>
            <div>
              <h3 className='timer'>
                TimeLeft: {`${Math.floor(timeLeft / 60)}`.padStart(2, '0')}:
                {`${timeLeft % 60}`.padStart(2, '0')}
              </h3>
            </div>
            <div>
              <span className="active-question-no">
                {addLeadingZero(activeQuestion + 1)}
              </span>
              <span className="total-question">
                /{addLeadingZero(q?.length)}
              </span>
            </div>
            <h2>{filteredQuestion?.flatMap(item => item)[activeQuestion]?.quest} </h2>

            <ul>
              {choice?.option?.map((answer, index) => (
                <li
                  onClick={() => {
                    onAnswerSelected(answer, index);
                  }}
                  key={answer}
                  className={
                    selectedAnswerIndex === index ? 'selected-answer' : null
                  }>
                  {String.fromCharCode(index + 65)}.  {answer} {/* Add alphabet based on index */}
                </li>
              ))}
            </ul>

            <div className="flex-right">
              <button
                onClick={onClickNext}
                disabled={selectedAnswerIndex === null}>
                {activeQuestion === questions?.length - 1 ? 'Finish' : 'Next'}
              </button>
            </div>
          </div>
        ) : (
          <div className="result">
            <h3>Result</h3>
            <p>
              Total Question: <span>{questions?.length}</span>
            </p>
            <p>
              Correct Answers:<span> {result.correctAnswers}</span>
            </p>
            <p>
              Wrong Answers:<span> {result.wrongAnswers}</span>
            </p>
            <button type="submit" className='score_section_button' onClick={resetQuiz}>
              Try Again!!
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Questions;
