import React from 'react'
import { Tabs } from "antd";
import Pending from './pending';
import Approved from './Approved';
import "../../../Components/Css/Students/Student.scss"
const Response = () => {
  return (
    <div className='Student-response'>
         <Tabs style={{marginTop:'35px'}}>
         <Tabs.TabPane className='tab-pending' tab="Pending" key="1">
            <Pending/>
         </Tabs.TabPane>
      <Tabs.TabPane className='tab-approved' tab="Approved" key="2">
        <Approved/>
      </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default Response