import React from "react";
import { Tabs } from "antd";
import { Link } from "react-router-dom";
// import OurProfile from "./OurProfile";
// import Popup from "../Popup/Popup";
// import KycComplaince from "./KycComplaince/KycComplaince";
import "../../../../Components/Css/Employer/ContractStaffing.scss";
import ContractApprovedList from "./ContractApprovedList";
import ConractPostApproved from "./ContractPostApproved";

function Index() {
  return (
    <div>
      <Tabs defaultActiveKey="1" centered className="p-3">
        <Tabs.TabPane tab="Required value" key="1">
          <ContractApprovedList />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Response value" key="2">
          <ConractPostApproved />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default Index;
