import React from "react";

const Courses = [
    {
      id: 1,
      title: 'Introduction to React',
      description: 'Learn the basics of React, including components, state, and props.',
      instructor: 'John Doe',
      image:"https://cdn.freebiesupply.com/logos/large/2x/react-1-logo-png-transparent.png",
      duration: '4 weeks',
      startDate: '2023-09-01',
      price: '$199',
    },
    {
      id: 2,
      title: 'Advanced JavaScript',
      description: 'Deep dive into JavaScript concepts like closures, prototypes, and async programming.',
      instructor: 'Jane Smith',
      duration: '6 weeks',
      startDate: '2023-10-01',
      price: '$299',
    },
    {
      id: 3,
      title: 'Full-Stack Development',
      description: 'Learn to build and deploy full-stack web applications using modern technologies.',
      instructor: 'Mary Johnson',
      duration: '8 weeks',
      startDate: '2023-11-01',
      price: '$399',
    },
  ];
  
  export default Courses;
  