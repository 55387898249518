import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Popconfirm } from "antd";
import Api from "../../../Api.js";

const CoursesList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [courseDetails, setCourseDetails] = useState(null);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
 console.log("Courses:", courses)
  // Retrieve trainer ID from local storage
  const userId = localStorage.getItem("id");

  useEffect(() => {
    const fetchAllCourses = async () => {
      try {
        const response = await Api.get(`/trainercourse/courses/672890eb67776a34a444e0ae`);
        console.log("FetchAllCourses", response.data);
        setCourses(response.data.data);
      
        setLoading(false);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchAllCourses();
  }, []);

  const showModal = (course) => {
    setCourseDetails(course);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleApprove = async (course) => {
    try {
      const response = await Api.put(
        `/trainercourse/course/approve/${course._id}`
      );
      if (response.data.message === "Course approved successfully") {
        setCourses((prevCourses) =>
          prevCourses.map((c) =>
            c._id === course._id ? { ...c, approval: true } : c
          )
        );
        console.log("Course approved:", course.courseName);
      }
    } catch (error) {
      console.error("Error approving course:", error);
    }
  };

  const columns = [
    {
      title: "Course Name",
      dataIndex: "courseName",
      align: "center",
      key: "courseName",
    },
    {
      title: "Starting Date",
      dataIndex: "startDate",
      align: "center",
      key: "startDate",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Ending Date",
      dataIndex: "endDate",
      align: "center",
      key: "endDate",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Total Weeks",
      dataIndex: "totalWeeks",
      align: "center",
      key: "totalWeeks",
    },
    // {
    //   title: "Course Fee",
    //   dataIndex: "courseFee",
    //   align: "center",
    //   key: "courseFee",
    //   render: (fee) => `$${fee.toFixed(2)}`,
    // },
    {
      title: "Action",
      align: "center",
      key: "action",
      render: (_, record) => (
        <span>
          <Button
            onClick={() => showModal(record)}
            type="button"
            style={{ margin: 2 }}
          >
            View
          </Button>
          <Popconfirm
            title="Are you sure you want to approve this course?"
            onConfirm={() => handleApprove(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" style={{ margin: 2 }}>
              Approve
            </Button>
          </Popconfirm>

          {/* <Popconfirm
            title="Are you sure you want to reject this course?"
            onConfirm={() => handleReject(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger style={{ margin: 2 }}>
              Reject
            </Button>
          </Popconfirm> */}
        </span>
      ),
    },
  ];

  return (
    <div
      style={{
        marginTop: "65px",
        maxWidth: "90%",
        margin: "0 auto",
        padding: "20px",
      }}
    >
      <h3 style={{ margin: "55px 0px 20px 0px" }}>Courses List</h3>
      <Table
        columns={columns}
        dataSource={courses.filter((course) => !course.approval)}
        loading={loading}
        pagination={{ pageSize: 5 }}
      />

      <Modal
        title="Course Details"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {courseDetails && (
          <div>
            <p><strong>Course Name:</strong> {courseDetails.courseName}</p>
            <p><strong>Starting Date:</strong>{" "}{new Date(courseDetails.startDate).toLocaleDateString()}</p>
            <p><strong>Ending Date:</strong>{" "}{new Date(courseDetails.endDate).toLocaleDateString()}</p>
            <p><strong>Total Weeks:</strong> {courseDetails.totalWeeks}</p>
            <p><strong>Timing:</strong> {courseDetails.timing}</p>
            <p><strong>Student Count:</strong> {courseDetails.studentCount}</p>
            {/* <p><strong>Course Fee:</strong> ${courseDetails.courseFee.toFixed(2)}</p> */}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CoursesList;
