import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Row, Col } from "antd";
import { Button } from "react-bootstrap";
import Api from "../../../Api";
import { useLocation, useNavigate } from "react-router-dom";
import interview from "./../../../assets/interview.png";
import { NavLink } from "react-router-dom";
import "../../../Components/Css/Employer/CreateJobForm.scss";

function ViewSchedule() {
  const location = useLocation();
  const data = location.state;
  const id = localStorage.getItem("id");

  return (
    <div>
      <div>
        <Row className="p-3">
          <Col sm={24} md={12} className="p-3">
            <Card className="ViewScheduleCardContainer">
              <Card.Body>
                <h5>{data.Name} </h5>
                <h6>Role:{data.Role}</h6>
                <h6>Mode:{data.Mode}</h6>
                <h6>Date:{data.Date}</h6>
                <h6>Time:{data.Time}</h6>
                <h6>Assign To:{data.AssignTo}</h6>
                <br />
                <div style={{ display: "flex" }}>
                  <NavLink to="/employer/:companyName/selectform">
                    <Button variant="success" className="ms-5">
                      Approved
                    </Button>
                  </NavLink>
                  <NavLink to="/employer/:companyName/holdform">
                    <Button variant="primary" className="ms-3">
                      Hold
                    </Button>
                  </NavLink>
                  <NavLink to="/employer/:companyName/rejectform">
                    <Button variant="danger" className="ms-3">
                      Reject
                    </Button>
                  </NavLink>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ViewSchedule;
