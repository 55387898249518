import { React, useState, useEffect } from "react";
import { Space, Table, Tag } from "antd";
import "../../../Components/Css/Employer/EmployerResume.scss";
import { Button, Modal } from "antd";
import { NavLink } from "react-router-dom";
import Api from "../../../Api";

function SelectedList() {
  const [selectList, setSelectList] = useState([]);
  const id = localStorage.getItem("id");
  useEffect(() => {
    getSelect();
  }, []);

  const getSelect = async () => {
    await Api.get(`selected/getSelected/${id}`).then((res) => {
      setSelectList(res.data.data);
      console.log("selectshow", selectList);
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "name",
      render: (text) => <h6>{text}</h6>,
    },
    {
      title: "Role",
      dataIndex: "Role",
      key: "role",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Remarks",
      dataIndex: "Remark",
      key: "Remarks",
    },
  ];
  const data = [
    {
      key: "1",
      name: "Manju",
      role: "Front End",
      Remarks: "xxx",
    },
    {
      key: "2",
      name: "Bharathi",
      role: "Web Developer",
      Remarks: "xxx",
    },
    {
      key: "3",
      name: "Sakthi",
      role: "Designer",
      Remarks: "xxx",
    },
    {
      key: "4",
      name: "Kannan",
      role: "Php Developer",
      Remarks: "xxx",
    },
    {
      key: "5",
      name: "Priya",
      role: "Testing Department",
      Remarks: "xxx",
    },
  ];
  return (
    <div>
      <div className="pages-title mt-5 ms-5">Selected List:</div>
      <Table
        columns={columns}
        dataSource={selectList}
        className="mx-5 mt-5"
        style={{ width: "50%" }}
      />
      ;
    </div>
  );
}

export default SelectedList;
