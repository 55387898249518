import React from "react";
// import "./MinimalCV.css";

const MinimalCV = () => {
  return (
    <div className="minimal-cv">
      <header className="cv-header">
        <h1>Michael Green</h1>
        <p>Web Developer | Email: michael@example.com</p>
      </header>

      <section className="cv-section">
        <h2>Profile Summary</h2>
        <p>
          A highly motivated web developer with a strong background in building responsive and scalable applications using React.js and Node.js.
        </p>
      </section>

      <section className="cv-section">
        <h2>Skills</h2>
        <ul>
          <li>React.js</li>
          <li>Node.js</li>
          <li>HTML & CSS</li>
          <li>JavaScript</li>
        </ul>
      </section>

      <section className="cv-section">
        <h2>Work Experience</h2>
        <p>Software Engineer, Tech Co (2020-present)</p>
      </section>
    </div>
  );
};

export default MinimalCV;
