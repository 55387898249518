import React, { useContext, useEffect } from 'react';
import { Card, Container } from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Testimonials.css';
import AOS from 'aos'; 
import 'aos/dist/aos.css'; 
import img from '../Images/profile.png'
import { ThemeContext } from './ThemeContext';

const testimonials = [
  {
    name: 'John Doe',
    role: 'Web Developer',
    feedback: 'This platform has significantly improved my skills and helped me land my dream job!',
    image: img,
  },
  {
    name: 'Jane Smith',
    role: 'Graphic Designer',
    feedback: 'I love the variety of courses offered. The instructors are highly knowledgeable and supportive.',
    image: img,
  },
  {
    name: 'Mike Johnson',
    role: 'Data Analyst',
    feedback: 'The hands-on approach to learning was exactly what I needed to advance my career.',
    image: img,
  },
  {
    name: 'Emily Davis',
    role: 'Marketing Specialist',
    feedback: 'An excellent platform that offers a wealth of knowledge and opportunities!',
    image: img,
  },
  {
    name: 'Chris Lee',
    role: 'Software Engineer',
    feedback: 'The courses are well-structured and very engaging. Highly recommended!',
    image: img,
  },
];

const Testimonials = () => {

  const { theme } = useContext(ThemeContext); 

  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, 
      once: false,
    });
  }, []);

  return (
    <Container fluid className={theme.testimonialContainer}>
      <h1 className="text-center mb-4" style={{fontWeight:"500"}}>What Our Users Say</h1>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} data-aos={index % 2 === 0 ? 'fade-right' : 'fade-left'} data-aos-delay={`${index * 100}`}>
            <Card className="testimonial-card text-center shadow-sm border-0">
              <Card.Img
                variant="top"
                src={testimonial.image}
                alt={testimonial.name}
                className="rounded-circle mx-auto"
                style={{ width: '100px', height: '100px' }}
              />
              <Card.Body>
                <Card.Text style={{fontStyle:"italic"}} className="font-weight-light">
                  "{testimonial.feedback}"
                </Card.Text>
                <Card.Title className="font-weight-bold">{testimonial.name}</Card.Title>
                <Card.Subtitle className="text-muted">{testimonial.role}</Card.Subtitle>
              </Card.Body>
            </Card>
          </div>
        ))}
      </Slider>
    </Container>
  );
};

export default Testimonials;
