import React, {useState} from "react";
import { Container, Row, Col, button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import "./kycComplaince.scss";
import Api from "../../../../Api";

function KycComplaince() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const [PanCard, setPanCard] = useState({});
  const [AddressProof, setAddressproof] = useState({});

  const handleFormSubmit = async () => {
    const Details = {
      panno: getValues().panno,
      address: getValues().address,
    };
    const data = new FormData();
    data.append("PanCard", getValues().PanCard[0]);
    // data.append("AddressProof", getValues().AddressProof[0]);
    await Api.post("kyc/uploadImage", data).then(async (res) => {
      if (res.status == 201) {
        Details.PanCard = res.data.path;
        // Details.AddressProof = res.data.path;
        await Api.post(`kyc/createkyc`, Details).then((resp) => {
          // localStorage.setItem("personalId", resp.data.data._id);
        });
      }
    });
  };

  return (
    <div>
      <Container>
        <h4 className="pages-title mt-3">KYC Complaince</h4>
        <br />
        <p style={{ backgroundColor: "#fccc55", width: "40%" }}>
          {" "}
          Your Company details are required to meet kyc Complaince
        </p>
        <br />
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Row>
            <Col sm={12} lg={2}>
              <label>PanCard Number: </label>
            </Col>
            <Col sm={12} lg={6}>
              <input
                {...register("panno", { required: true })}
                className="kyc-input"
              />
              {errors.panno && (
                <p className="text-danger">pancard number is required</p>
              )}
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={12} lg={2}>
              <label>AadharCard Number: </label>
            </Col>
            <Col sm={12} lg={6}>
              <input
                {...register("panno", { required: true })}
                className="kyc-input"
              />
              {errors.panno && (
                <p className="text-danger">AadharCard number is required</p>
              )}
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={12} lg={2}>
              <label>GST Number: </label>
            </Col>
            <Col sm={12} lg={6}>
              <input
                {...register("panno", { required: true })}
                className="kyc-input"
              />
              {errors.panno && (
                <p className="text-danger">GST number is required</p>
              )} 
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={12} lg={2}>
              <label>Pan or Adhar Upload Anyone: </label>
            </Col>
            <Col sm={12} lg={6}>
              <input
                className="kyc-input"
                style={{ width: "85%", outline: "none" }}
                type="file"
                {...register("PanCard", { required: true })}
                onChange={(e) => {
                  setPanCard(e.target.files[0]);
                  console.log("e.target.files[0]", e.target.files[0]);
                }}
              />
              {errors.PanCard && (
                <p className="text-danger">pancard upload is required</p>
              )}
              <p>
                Only 1 document in pdf,jpeg and png format with maximum 5Mb
                uploaded
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={2}>
              <label>Address Proof: </label>
            </Col>
            <Col sm={12} lg={6}>
              <input
                {...register("address", { required: true })}
                className="kyc-input"
              />
              {errors.address && (
                <p className="text-danger">Address is required</p>
              )}
            </Col>
          </Row>
          <br />
          {/* <Row>
            <Col sm={12} lg={2}>
              <label>Address Proof Upload: </label>
            </Col>
            <Col sm={12} lg={6}>
              <input
                className="kyc-input"
                style={{ width: "85%", outline: "none" }}
                type="file"
                {...register("AddressProof", { required: true })}
                onChange={(e) => {
                  setAddressproof(e.target.files[0]);
                  console.log("e.target.files[0]", e.target.files[0]);
                }}
              />
              {errors.AddressProof && (
                <p className="text-danger">Address Proof is required</p>
              )}
              <p>
                Only 1 document in pdf,jpeg and png format with maximum 5Mb
                uploaded
              </p>
            </Col>
          </Row> */}
          <div className="submitbuttons px-4">
            <button
              className="button1 m-2 p-2"
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </button>
            <button className="button2 m-2 p-2" type="reset">
              cancel
            </button>
          </div>
        </form>
      </Container>
    </div>
  );
}

export default KycComplaince;
