// import React from "react";
// // import '../styles/Result.css';
// import { Link } from "react-router-dom";

// import ResultTable from "./ResultTable";

// export default function Result() {
//   function onRestart() {
//     console.log("on Restart");
//   }

//   return (
//     <div className="container">
//       <h1 className="title ">Quiz Application</h1>

//       <div className="result flex-center">
//         <div className="flex">
//           <span>Technology : </span>
//           <span className="bold">React</span>
//         </div>
//         <div className="flex">
//           <span>Week : </span>
//           <span className="bold">1</span>
//         </div>
//         <div className="flex">
//           <span>Total Quiz Points : </span>
//           <span className="bold">50</span>
//         </div>
//         <div className="flex">
//           <span>Total Questions : </span>
//           <span className="bold">05</span>
//         </div>
//         <div className="flex">
//           <span>Total Attempts : </span>
//           <span className="bold">03</span>
//         </div>
//         <div className="flex">
//           <span>Total Earn Points : </span>
//           <span className="bold">30</span>
//         </div>
//         <div className="flex">
//           <span>Quiz Result</span>
//           <span className="bold">Passed</span>
//         </div>
//       </div>

//       <div className="start">
//         <Link className="btn" to={"/student/test"} onClick={onRestart}>
//           Restart
//         </Link>
//       </div>

//       <div className="container">
//         {/* result table */}
//         <ResultTable></ResultTable>
//       </div>
//     </div>
//   );
// }
import React from 'react'

function Result() {
  return (
    <div>
      <p>chggvgv</p>
    </div>
  )
}

export default Result
