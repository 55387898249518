import React, { useState } from "react";
import Api from "../../../Api";
import "../Course/CourseCreate";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import { Select, TimePicker } from "antd";

import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const { RangePicker: CustomRangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";
const weekFormat = "MM/DD";
const monthFormat = "YYYY/MM";

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];
const customFormat = (value) => `custom format: ${value.format(dateFormat)}`;
const customWeekStartEndFormat = (value) =>
  `${dayjs(value).startOf("week").format(weekFormat)} ~ ${dayjs(value)
    .endOf("week")
    .format(weekFormat)}`;

const { Option } = Select;
const { RangePicker } = TimePicker;

const CreateCurriculam = () => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    instructor: "",
    duration: "",
    startDate: "",
    endDate: "",
    classDays: [],
    classTime: [],
    mode: "online",
    price: "",
    image: "",
    rating: 0,
    whatYoullLearn: [],
    technologies: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const calculateDurationInWeeks = (startDate, endDate) => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const weeks = Math.floor((end - start) / (1000 * 60 * 60 * 24 * 7));
      return `${weeks} weeks`;
    }
    return "";
  };

  const handleStartDateChange = (e) => {
    const startDate = e.target.value;
    const endDate = formData.endDate;
    const duration = calculateDurationInWeeks(startDate, endDate);
    setFormData({
      ...formData,
      startDate: startDate,
      duration: duration,
    });
  };

  const handleEndDateChange = (e) => {
    const endDate = e.target.value;
    const startDate = formData.startDate;
    const duration = calculateDurationInWeeks(startDate, endDate);
    setFormData({
      ...formData,
      endDate: endDate,
      duration: duration,
    });
  };

  const handleClassDaysChange = (value) => {
    setFormData({
      ...formData,
      classDays: value,
    });
  };

  const handleTrainerChange = (value) => {
    setFormData({
      ...formData,
      instructor: value,
    });
  };

  const handleTimeRangeChange = (time) => {
    setFormData({
      ...formData,
      classTime: time
        ? [time[0].format("hh:mm A"), time[1].format("hh:mm A")]
        : [],
    });
  };
  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0], // Save the file object directly
    });
  };

  const handleTechnologiesChange = (index, value) => {
    const newTechnologies = [...formData.technologies];
    newTechnologies[index] = value;
    setFormData({ ...formData, technologies: newTechnologies });
  };

  const handleWhatYoullLearnChange = (index, value) => {
    const newWhatYoullLearn = [...formData.whatYoullLearn];
    newWhatYoullLearn[index] = value;
    setFormData({ ...formData, whatYoullLearn: newWhatYoullLearn });
  };

  const handleAddLearningPoint = () => {
    setFormData({
      ...formData,
      whatYoullLearn: [...formData.whatYoullLearn, ""],
    });
  };

  const handleAddTechnology = () => {
    setFormData({ ...formData, technologies: [...formData.technologies, ""] });
  };

  const handleRemoveLearningPoint = (index) => {
    const newWhatYoullLearn = formData.whatYoullLearn.filter(
      (_, i) => i !== index
    );
    setFormData({ ...formData, whatYoullLearn: newWhatYoullLearn });
  };

  const handleRemoveTechnology = (index) => {
    const newTechnologies = formData.technologies.filter((_, i) => i !== index);
    setFormData({ ...formData, technologies: newTechnologies });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a new FormData instance
    const data = new FormData();
    // Append all form data
    Object.keys(formData).forEach((key) => {
      if (Array.isArray(formData[key])) {
        data.append(key, JSON.stringify(formData[key]));
      } else {
        data.append(key, formData[key]);
      }
    });

    try {
      const response = await Api.post("/courses/createcourse", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Course created successfully:", response.data);
      setFormData({
        title: "",
        description: "",
        instructor: "",
        duration: "",
        startDate: "",
        endDate: "",
        classDays: [],
        classTime: [],
        mode: "online",
        price: "",
        image: null,
        rating: 0,
        whatYoullLearn: [],
        technologies: [],
      });
    } catch (error) {
      console.error("Error creating course:", error);
    }
  };
  const [size, setSize] = useState("middle");
  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };
  return (
    <form onSubmit={handleSubmit} className="course-create-form">
      <div className="form-row">
        <Row>
          <Col className="p-2" sm={12} md={6} lg={4}>
            <div className="form-group">
              <label>Title:</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </div>
          </Col>
          <Col className="p-2" sm={12} md={6} lg={4}>
            <div className="form-group">
              <label>Duration:</label>
              <input
                type="text"
                name="duration"
                value={formData.duration}
                readOnly
              />
            </div>{" "}
          </Col>
          <Col className="p-2" sm={12} md={6} lg={4}>
            {/* <div className="form-group">
          <label>Trainer:</label>
          <Select
            placeholder="Select Trainer"
            style={{ width: "100%" }}
            onChange={handleTrainerChange}
            value={formData.instructor}
            required
          >
            <Option value="John Doe">John Doe</Option>
            <Option value="Jane Smith">Jane Smith</Option>
            <Option value="Mike Johnson">Mike Johnson</Option>
          </Select>
        </div> */}
            <Col className="p-2" sm={12} md={6} lg={4}>
              <div className="form-group" style={{ display: "flex" }}>
                <div className="form-group">
                  <label>Start Date:</label>
                  <input
                    type="date"
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleStartDateChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>End Date:</label>
                  <input
                    type="date"
                    name="endDate"
                    value={formData.endDate}
                    onChange={handleEndDateChange}
                    required
                  />
                </div>
              </div>
            </Col>
          </Col>
        </Row>
      </div>

      <div className="form-row">
        <Row>
          {/* <Space
          direction="vertical"
          style={{
            width: "33%",
          }}
        >
          <DatePicker.RangePicker
            status="error"
            style={{
              width: "100%",
            }}
          />
        </Space> */}
          <Col className="p-2" sm={12} md={6} lg={4}>
            <div className="form-group">
              <label>Class Days:</label>
              <Select
                mode="multiple"
                placeholder="Select Days"
                style={{ width: "100%" }}
                value={formData.classDays}
                onChange={handleClassDaysChange}
                required
              >
                <Option value="Monday">Monday</Option>
                <Option value="Tuesday">Tuesday</Option>
                <Option value="Wednesday">Wednesday</Option>
                <Option value="Thursday">Thursday</Option>
                <Option value="Friday">Friday</Option>
              </Select>
            </div>
          </Col>
          <Col className="p-2" sm={12} md={6} lg={4}>
            <div className="form-group">
              <label>Subject:</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </div>
          </Col>
          <Col className="p-2" sm={12} md={6} lg={4}>
            <div className="form-group">
              <label>Class Time:</label>
              <RangePicker
                format="hh:mm A"
                use12Hours
                value={
                  formData.classTime.length > 0
                    ? [
                        moment(formData.classTime[0], "hh:mm A"),
                        moment(formData.classTime[1], "hh:mm A"),
                      ]
                    : null
                }
                onChange={handleTimeRangeChange}
                required
              />
            </div>{" "}
          </Col>{" "}
        </Row>
      </div>

      <div className="form-row">
        <Row>
          <Col className="p-2" sm={12} md={6} lg={4}>
            <div className="form-group">
              <label>Mode:</label>
              <select name="mode" value={formData.mode} onChange={handleChange}>
                <option value="online">Online</option>
                <option value="offline">Offline</option>
              </select>
            </div>{" "}
          </Col>
          <Col className="p-2" sm={12} md={6} lg={4}>
            <div className="form-group">
              <label>Price:</label>
              <input
                type="text"
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
              />
            </div>{" "}
          </Col>
          <Col className="p-2" sm={12} md={6} lg={4}>
            <div className="form-group">
              <label>Image:</label>
              <input
                type="file"
                name="image"
                onChange={handleImageChange}
                accept="image/*"
                required
              />
            </div>
          </Col>
        </Row>
      </div>

      <div className="form-row">
        <Row>
          <Col className="p-2" sm={12} md={6} lg={4}>
            <div className="form-group">
              <label>Rating:</label>
              <input
                type="number"
                name="rating"
                value={formData.rating}
                onChange={handleChange}
                required
                min="0"
                max="5"
              />
            </div>
          </Col>
          <Col className="p-2" sm={12} md={6} lg={6}>
            <div className="form-group">
              <label>Description:</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </div>
          </Col>
        </Row>
      </div>

      <Row>
        <Col className="p-2" sm={12} md={6} lg={4}>
          <div>
            <label>What You'll Learn:</label>
            {formData.whatYoullLearn.map((point, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <button
                  type="button"
                  onClick={() => handleRemoveLearningPoint(index)}
                  style={{
                    backgroundColor: "#f44336",
                    color: "white",
                    borderRadius: "50%",
                    width: "24px",
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  &times;
                </button>
                &nbsp;
                <textarea
                  type="text"
                  value={point}
                  onChange={(e) =>
                    handleWhatYoullLearnChange(index, e.target.value)
                  }
                  className="learning-input"
                />
              </div>
            ))}
            <button
              type="button"
              className="add-learning-btn"
              onClick={handleAddLearningPoint}
            >
              Add Learning Point
            </button>
          </div>
        </Col>
        <Col className="p-2" sm={12} md={6} lg={4}>
          <div>
            <label>Topics:</label>
            {formData.technologies.map((tech, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <button
                  type="button"
                  onClick={() => handleRemoveTechnology(index)}
                  style={{
                    backgroundColor: "#f44336",
                    color: "white",
                    borderRadius: "50%",
                    width: "24px",
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  &times;
                </button>
                &nbsp;
                <input
                  type="text"
                  value={tech}
                  onChange={(e) =>
                    handleTechnologiesChange(index, e.target.value)
                  }
                  className="technology-input"
                />
              </div>
            ))}
            <button
              type="button"
              className="add-learning-btn"
              onClick={handleAddTechnology}
            >
              Add Topics
            </button>
          </div>
        </Col>
      </Row>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <button type="submit" className="course-create-btn">
          Create Course
        </button>
      </div>
    </form>
  );
};

export default CreateCurriculam;
