import React, { useEffect, useState } from "react";
import { Table, Button, Input, Popconfirm, Modal } from "antd";
import "../../../Components/Css/Table.scss";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";
import 'aos/dist/aos.css';
import AOS from 'aos';
// import DataListTable from "../datalistTable";

function ExamPatternList() {
  const [techList, setTechList] = useState([]);
  const [dailogVal, setDailogVal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getData, setGetData] = useState();
  const [search, setSearch] = useState();
  console.log(getData, "ghjhjhgjh");

  useEffect(() => {
    getPattern();
  }, []);


  useEffect(() => {
    AOS.init({
      duration: 1000, // Set animation duration in milliseconds
      easing: 'ease-in-out', // Animation easing
    });
  }, []);


  const getPattern = async () => {
    await Api.get("ExamPattern/get").then((res) => {
      console.log(res.data, "////");
      setTechList(res.data);
    });
  };

  const deleteExamPattern = (id) => {
    console.log(id, "asdf");
    Api.delete(`ExamPattern/delete/${id}`).then((resp) => {
      getPattern();
    });
  };
  const update = (ele) => {
    console.log(ele, "jjj");
    navigate("PatternCreate", { state: { ele } });
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    const Technologys = e.target.value;
    console.log(typeof Technologys);

    const newData = techList.filter((list) =>
      list.Technology.toLowerCase().includes(Technologys.toLowerCase())
    );
    console.log(newData, "//");
    setGetData(newData);
  };
  console.log(search, "get");

  const handleModalprops = (id) => {
    const filterdVal = techList.filter((ele) => {
      return ele._id === id;
    });
    setDailogVal(filterdVal[0]);
    return;
  };

  const columns = [
    {
      title: "Course",
      dataIndex: "Course",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },
    {
      title: "Technology",
      dataIndex: "Technology",
      render: (_, record) => {
        return (
          <div data-aos="fade-down">
            <ul>
              {record?.Technology?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        );
      },
    },
    
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div data-aos="zoom-in">
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              update(record);
            }}
          />

          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => {
              deleteExamPattern(record.id);
            }}
          >
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ paddingLeft: "10px", cursor: "pointer" }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  // const { Search } = Input;

  const onSearch = "";
  const navigate = useNavigate();
  return (
    <div>
      <div className="data_list_main">
        <h4 className="data_list_header">Exam Pattern </h4>
        <div
          className="data_list_top mt-4 py-4 px-3"
          style={{ backgroundColor: "#c5c5c5" }}
        >
          <input
            className="data_list_search ps-2"
            placeholder="search"
            columns={columns}
            // dataList={techList}
            value={search}
            onChange={handleSearch}
          // enterButton
          />

          <Button
            className="Datalist-Table"
            onClick={() => navigate("PatternCreate")}
          >
            <BsFillPersonPlusFill />
            &nbsp;Add New
          </Button>
        </div>
        <div className="Datalist-Table">
          <Table
            columns={columns}
            dataSource={getData ? getData : techList}
            pagination={true}
            className="mt-2"
          />
        </div>
      </div>
    </div>
  );
}

export default ExamPatternList;
