import React, { useEffect, useState } from "react";
import { Table, Input, Popconfirm } from "antd";
import Api from "../../../../Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

const CompanyApprovedList = () => {
  const [companyList, setCompanyList] = useState([]);

  const deleteApproved = (id) => {
    Api.delete(`fields/deleteApproved/${id}`).then((res) => {
      console.log("res", res);
    });
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
    },
    {
      title: "Company Logo",
      dataIndex: "companyLogo",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => deleteApproved(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ paddingLeft: "10px", cursor: "pointer" }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getCompany();
  }, []);
  const getCompany = async () => {
    await Api.get("fields/getApproved").then((res) => {
      setCompanyList(res.data.data);
    });
  };

  return (
    <div>
      <div className="data-list-main">
        <h4 className="data-list-header">Approved Logo </h4>
        <div
          className="data-list-top mt-4 py-4 px-3"
          style={{ backgroundColor: "#c5c5c5" }}
        >
          <Input
            className="data-list-search ps-2"
            placeholder="search by companny name"
            enterButton
          />
        </div>

        <div className=".Datalist-Table">
          <Table
            columns={columns}
            dataSource={companyList}
            pagination={false}
            className="mt-2"
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyApprovedList;
