import React, { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";

function SidebarToggle({ toggleSidebar, isSidebarCollapsed }) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 800) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize(); 

    window.addEventListener("resize", handleResize); 

    return () => window.removeEventListener("resize", handleResize); 
  }, []);

  return (
    <div className="sidebar-toggle-btn" onClick={toggleSidebar}>
      {isSidebarCollapsed || isMobile ? ( 
        <FaBars size={24} />
      ) : (
        <FaTimes size={24} />
      )}
    </div>
  );
}

export default SidebarToggle;
