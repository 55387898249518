import React, { useState, useEffect } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { GoLocation } from "react-icons/go";
import { RiSuitcaseLine } from "react-icons/ri";
import { CgNotes } from "react-icons/cg";
import { CiSaveDown2 } from "react-icons/ci";
import "./Jobs.scss";
import Api from "../../../../Api";
import { useNavigate } from "react-router-dom";
import { MdOutlineDescription } from "react-icons/md";
import { FaRegMoneyBillAlt } from "react-icons/fa";

function Jobs() {
  const [createForm, setcreateForm] = useState([]);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState(null);
  const [location, setlocation] = useState(null);
  const [experience, setexperience] = useState(null);
  const [salary, setsalary] = useState(null);

  const getCreateForm = async () => {
    await Api.get("createForm/getForm").then((res) => {
      setcreateForm(res.data.data);
    });
  };
  const navigate = useNavigate();

  const onSubmit = (data) => {
    navigate("/professional/innerpage", { state: data });
  };

  useEffect(() => {
    getCreateForm();
    getUsers(createForm);
  }, []);

  const getUsers = (createForm) => {
    setUsers(createForm);
  };
  const bylocation = (createForm, location) => {
    if (location) {
      return createForm.location === location;
    } else return createForm;
  };
  const byexperience = (createForm, experience) => {
    if (experience) {
      return createForm.experience === experience;
    } else return createForm;
  };
  const bysalary = (createForm, salary) => {
    if (salary) {
      return createForm.salaryRange === salary;
    } else return createForm;
  };
  const bySearch = (createForm, search) => {
    if (search) {
      return createForm.jobTitle.toLowerCase().includes(search.toLowerCase());
    } else return createForm;
  };
  const filteredList = (users, location, experience, search) => {
    return users
      .filter((createForm) => bylocation(createForm, location))
      .filter((createForm) => bySearch(createForm, search))
      .filter((createForm) => byexperience(createForm, experience))
      .filter((createForm) => bysalary(createForm, salary));
  };
console.log("jobdata:",createForm);
  return (
    <div className=" mt-5">
      <p className="ThirdCardPara">JOBS</p>
      <h2 style={{ textAlign: "center", marginBottom: "1" }}>
        Choose your matching profile
      </h2>
      {/* <div className="jobsearchbox mt-5">
          <input
            type="text"
            className="job-search1"
            placeholder="Search your job here"
          />

          <input type="text" className="job-search2" placeholder="location" />

          <button className="button1 jobsearchbutton">
            <span>Search</span>
          </button>
        </div> */}
      <Row>
        <Col md={4} lg={4}>
          <div className="DescriptionBoxFilter mt-0 ">
            <div className="pages-title mb-3 mt-2 ms-4 ">Filter</div>
            <div className="ms-4 mb-5">
              <label className="mt-4">Jobtitle</label>
              <br />
              <input
                className="filter_input"
                type="search"
                placeholder="search..."
                onChange={(e) => setSearch(e.target.value)}
              />
              <br />

              <label className="mt-4">Location</label>
              <br />
              <select
                className="filter_input"
                onChange={(e) => setlocation(e.target.value)}
              >
                {/* <option value={createForm.location}>
                  {createForm.location}
                </option> */}
                <option value="">Choose Location</option>
                <option value="Madurai">"Madurai"</option>
                <option value="Bangalore">Bangalore</option>
                <option value="coimbatore">coimbatore</option>
                <option value="other">Other</option>
              </select>

              <br />

              {/* <label className="mt-4">Experience</label>
                <br />
                <select
                  className="filter_input"
                  onChange={(e) => setexperience(e.target.value)}
                >
                  <option value="">Choose Experience</option>
                  <option value="Fresher">Fresher</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select> */}

              <br />
              <label className="mt-4">Salary</label>
              <br />
              <select
                className="filter_input"
                onChange={(e) => setsalary(e.target.value)}
              >
                <option value="">Choose Salary</option>
                <option value="20000">20000</option>
                <option value="33">20000-30000</option>
                <option value="2 ">2</option>
              </select>
              <br />
              <label className="mt-4"> Experience </label>
              <br />
              <select
                className="filter_input"
                onChange={(e) => setexperience(e.target.value)}
              >
                <option value="">Choose Experience</option>
                <option value="1years"> 1</option>
                <option value="2years"> 2</option>
                <option value="3"> 3</option>
                <option value="4"> 4</option>
                <option value="5"> 5</option>
              </select>
              {/* <Slider
                min={0}
                max={30}
                // onChange={(e) => setexperience(e.target.value)}
              /> */}
            </div>
          </div>
        </Col>
        <Col md={7} lg={7}>
          {/* .........................................Job Card.................................................. */}
          <div>
            {filteredList(createForm, location, experience, search).map(
              (createForm) => (
                <Card
                  className="jobscard mt-0 mb-5"
                  onClick={() => {
                    onSubmit(createForm);
                    // setToggle((prev) => {
                    //   return !prev;
                    // });
                  }}
                  // onClick={() =>
                  //   navigate("/professional/innerpage", { state: data })
                  // }
                >
                  <h2 className="">{createForm.jobTitle}</h2>
                  <h5 className="mb-1" style={{ color: "blue" }}>
                    {createForm.compName}
                  </h5>
                  <div className="mt-1">
                   <h1> <GoLocation /> {createForm.location}</h1>
                  </div>
                  <div className="mt-1">
                    <RiSuitcaseLine /> {createForm.experience} years
                  </div>
                  <div className="mt-1">
                    <CgNotes /> {createForm.skill}
                  </div>
                  <div className="mt-1">
                    <MdOutlineDescription /> {createForm.jobDescription}
                  </div>
                  <div className="mt-1">
                    <FaRegMoneyBillAlt /> {createForm.salaryRange}
                  </div>
                  <div className="save-icon">
                    <CiSaveDown2 />{" "}
                  </div>
                  {/* </div> */}
                </Card>
              )
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default Jobs;
