import React from "react";

import ProfessionalDashboard from "../Pages/Professional/ProfessionalDashboard";
import ProfessionalProfile from "../Pages/Professional/MyProfile/index";
import AreaOfInterest from "../Pages/Professional/AreaOfInterest/index";
import ProfessionalBlog from "../Pages/Professional/ProfessionalBlog/index";
import UpdateProfile from "../Pages/Professional/UpdateProfile/index";
import Jobs from "../Pages/Professional/ProfessionalDashboard/Jobs/jobs";
import Freelancing from "../Pages/Professional/Freelancing/Freelancing";
import FreelancingPost from "../Pages/Professional/Freelancing/FreelancingPost";
import FreelancingPay from "../Pages/Professional/Freelancing/FreelancingPay";
import FreelancingPay_Clickhere from "../Pages/Professional/Freelancing/FreelancingPay_Clickhere";
import FreelancingPost_AdminTable from "../Pages/Admin/FreelancingPost_AdminTable/FreelancingPost_AdminTable";
// import Jobs from "../Pages/Professional/ProfessionalDashboard/Jobs/jos"
// import Jobs from "../Pages/Professional/ProfessionalDashboard/Jobs/jobs";
import JobSearch from "../Pages/Professional/ProfessionalDashboard/Jobs/Jobfilter";
import ProfessionalLearing from "../Pages/Professional/learing";
import JobInnerPage from "../Pages/Professional/ProfessionalDashboard/Jobs/JobInnerPage";
import ProfessionalSeminar from "../Pages/Professional/Seminar/ProfessionalSeminar";
import FreelancingRequest from "../Pages/Professional/Freelancing/FreelancingRequest/FreelancingRequest";

import SeminarsInnerPage from "../Pages/Student/Seminar/SeminarInnerPage";
import Login from "../Pages/Student/RedirectPage/Login";
import JobRequest from "../Pages/Professional/ProfessionalDashboard/Jobs/JobRequest";
import JobRequestProfessional from "../Pages/Professional/ProfessionalDashboard/Jobs/JobRequestProfessional";
import AppliedJob from "../Pages/Professional/ProfessionalDashboard/Jobs/Appliedjob";
// import Freelancing from "../Pages/Professional/Freelancing/Freelancing";
// import FreelancingPost from "../Pages/Professional/Freelancing/FreelancingPost";
// import FreelancingPay from "../Pages/Professional/Freelancing/FreelancingPay";
// import FreelancingPay_Clickhere from "../Pages/Professional/Freelancing/FreelancingPay_Clickhere";
// import FreelancingPost_AdminTable from "../Pages/Admin/FreelancingPost_AdminTable/FreelancingPost_AdminTable";
// import FreelancingRequest from "../Pages/Professional/Freelancing/FreelancingRequest/FreelancingRequest";
import CoursePackage from "../Pages/Professional/CoursePackage/CoursePackage";
import { Navigate } from "react-router-dom";
import JobRequestInnerPage from "../Pages/Professional/ProfessionalDashboard/Jobs/JobRequestInnerPage";
import InternshipList from "../Pages/Professional/Internship/InternshipList";
import InnerPage from "../Pages/Professional/Seminar/InnerPage";
import InternshipPage from "../Pages/Student/IntershipDetails/InternshipPage";
const role = localStorage.getItem("role");
const ProfessionalRoutes = [
  {
    path: "/professional",
    name: "ProfessionalDashboard",
    element:
      role === "professional" ? (
        <ProfessionalDashboard />
      ) : (
        <Navigate to="/login" />
      ),
  },

  {
    path: "/professional/professionalprofile",
    name: "ProfessionalProfile",
    element:
      role === "professional" ? (
        <ProfessionalProfile />
      ) : (
        <Navigate to="/login" />
      ),
  },
  {
    path: "/professional/AreaOfInterest",
    name: "AreaOfInterest",
    element:
      role === "professional" ? <AreaOfInterest /> : <Navigate to="/login" />,
  },
  {
    path: "/professional/ProfessionalBlog",
    name: "ProfessionalBlog",
    element:
      role === "professional" ? <ProfessionalBlog /> : <Navigate to="/login" />,
  },
  {
    path: "/professional/updateProfile",
    name: "Update Profile",
    element:
      role === "professional" ? <UpdateProfile /> : <Navigate to="/login" />,
  },
  {
    path: "/professional/InternshipList",
    name: "IntershipList",
    element:
      role === "professional" ? <InternshipList /> : <Navigate to="/login" />,
  },
  {
    path: "/professional/InternshipPage",
    name: "InternshipPage",
    element:
      role === "professional" ? <InternshipPage /> : <Navigate to="/login" />,
  },
  // {
  //   path: "/professional/freelancing",
  //   name: "Freelancing",
  //   element: <Freelancing />,
  // },
  // {
  //   path: "/professional/freelancePost",
  //   name: "FreelancingPost",
  //   element: <FreelancingPost />,
  // },
  // {
  //   path: "/professional/freelancingPay",
  //   name: "FreelancingPay",
  //   element: <FreelancingPay />,
  // },
  // {
  //   path: "/professional/freelancingpay_Clickhere",
  //   name: "FreelancingPay_Clickhere",
  //   element: <FreelancingPay_Clickhere />,
  // },
  // {
  //   path: "/professional/FreelancingPost_AdminTable",
  //   name: "FreelancingPost_AdminTable",
  //   element: <FreelancingPost_AdminTable />,
  // },
  // {
  //   path: "/professional/FreelancingRequest",
  //   name: "FreelancingRequest",
  //   element: <FreelancingRequest />,
  // },
  {
    path: "/professional/jobs",
    name: "professionaljobs",
    element: role === "professional" ? <Jobs /> : <Navigate to="/login" />,
  },
  {
    path: "/professional/freelancing",
    name: "Freelancing",
    element:
      role === "professional" ? <Freelancing /> : <Navigate to="/login" />,
  },
  {
    path: "/professional/freelancePost",
    name: "FreelancingPost",
    element:
      role === "professional" ? <FreelancingPost /> : <Navigate to="/login" />,
  },
  {
    path: "/professional/freelancingPay",
    name: "FreelancingPay",
    element:
      role === "professional" ? <FreelancingPay /> : <Navigate to="/login" />,
  },
  {
    path: "/professional/freelancingpay_Clickhere",
    name: "FreelancingPay_Clickhere",
    element:
      role === "professional" ? (
        <FreelancingPay_Clickhere />
      ) : (
        <Navigate to="/login" />
      ),
  },
  {
    path: "/professional/FreelancingPost_AdminTable",
    name: "FreelancingPost_AdminTable",
    element:
      role === "professional" ? (
        <FreelancingPost_AdminTable />
      ) : (
        <Navigate to="/login" />
      ),
  },
  {
    path: "/professional/jobrequest",
    name: "jobrequest",
    element:
      role === "professional" ? <JobRequest /> : <Navigate to="/login" />,
  },

  {
    path: "/professional/jobrequests",
    name: "jobrequest",
    element:
      role === "professional" ? (
        <JobRequestProfessional />
      ) : (
        <Navigate to="/login" />
      ),
  },
  {
    path: "/professional/jobrequests/jobrequestinnerpage",
    name: "jobrequest",
    element:
      role === "professional" ? (
        <JobRequestInnerPage />
      ) : (
        <Navigate to="/login" />
      ),
  },
  {
    path: "/professional/appliedjobs",
    name: "Appliedjob",
    element:
      role === "professional" ? <AppliedJob /> : <Navigate to="/login" />,
  },
  {
    path: "learing",
    name: "professionallearing",
    element:
      role === "professional" ? (
        <ProfessionalLearing />
      ) : (
        <Navigate to="/login" />
      ),
  },
  {
    path: "/professional/jobfilter",
    name: "professionaljobfilter",
    element: role === "professional" ? <JobSearch /> : <Navigate to="/login" />,
  },
  {
    path: "/professional/innerpage",
    name: "JobInnerPage",
    element:
      role === "professional" ? <JobInnerPage /> : <Navigate to="/login" />,
  },
  {
    path: "/professional/seminar",
    name: "seminar",
    element:
      role === "professional" ? (
        <ProfessionalSeminar />
      ) : (
        <Navigate to="/login" />
      ),
  },
  {
    path: "/professional/login",
    name: "login",
    element: role === "professional" ? <Login /> : <Navigate to="/login" />,
  },
  {
    path: "/professional/seminar/viewsinnerpage",
    name: "viewsinnerpage",
    element: role === "professional" ? <InnerPage /> : <Navigate to="/login" />,
  },
  {
    path: "/professional/CoursePackage",
    name: "CoursePackage",
    element:
      role === "professional" ? <CoursePackage /> : <Navigate to="/login" />,
  },
];
export default ProfessionalRoutes;
