import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import "../../../../src/Components/Css/institute/homepage.scss";
import { BiBuildings, BiTimer, BiCalendar } from "react-icons/bi";
import { Card } from "antd";
import {
  MdPersonSearch,
  MdOutlinePermContactCalendar,
  MdOutlinePeopleOutline,
} from "react-icons/md";
import { BsAlarm } from "react-icons/bs";
import { SlCalender } from "react-icons/sl";
import { useNavigate } from "react-router-dom";

function Homepage() {
  const navigate = useNavigate();
  const list = [
    {
      imageUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSonmGbzH9SOhIeh8lhYHV5_7jhDJQGTNxjyfHRQ_HDwF5irL2Ke5Kkd2PBqMCwLyL3HF4&usqp=CAU",
      Title: "100+ students applied",
      Content:
        "An institution of higher learning that offer study courses with the aim of training and teaching students in a specific field of study.",
    },
    {
      // imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsw_9PZw8ByfNV_nI1dQ1KgkQO7Ge58RTePHHIiBHkRsBlD9RorhtnmaI4BhhYRkwIZS0&usqp=CAU",
      imageUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQF-pKih-pX3LpNZzvFRG6mkb9G-DkBH35JGw&usqp=CAU",
      Title: "Mostly Searched",
      Content:
        "Technology today is evolving at a rapid pace, enabling faster change and progress, that staying current with emerging technologies and latest technology trends. ",
    },
    {
      imageUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEX1xNWcxdHnzupscGOXNvQW5i2Uh9B0gjOA&usqp=CAU",
      Title: "Certification",
      Content:
        "A certificate is an official document which states that particular facts are true, or which you receive when you have successfully completed a course of study or training",
    },
  ];
  return (
    <div className="Home-main">
      <div className="Home-second">
        <h1 className="Home-center p-2">
          LEARNING TODAY, LEADING TOMORROW
          <br />
        </h1>
        <h5> We are team of talented designers for teaching websites </h5>
        <Button className="institute_home_button">Get started</Button>
      </div>
      <div className="mx-5 mt-5">
        <h2 style={{ textAlign: "center" }}>
          Software Training and Development
        </h2>
        <Row className="p-3 DataRow">
          {list.map((list, i) => (
            <Col sm={24} md={12} lg={4} className="DataCol">
              <div className="home-card p-3">
                <div style={{ textAlign: "center" }}>
                  <img src={list.imageUrl}></img>
                  <h4 className="mt-3">{list.Title}</h4>
                  <div className="mt-3 pe-4">{list.Content}</div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <Row className="mt-4">
        <Col sm={12} md={6}>
          <img
            className="home-img"
            src="https://traininginchrompet.com/img/banner-1.png"
          ></img>
        </Col>
        <Col sm={12} md={6}>
          <h3> Training Institude Posting Services </h3>
          <h5 className="mt-4">Logic bee Technology</h5>
          <h6 className="content-home">
            A choice that makes a big difference in your carrer. It is committed
            to helping its student to reach their goals and training
            experiences, by providing the inovative surroundings and faculties.
          </h6>
          <Col className="mt-3">
            <h6>
              <BsAlarm size={20} className="Home-Icon" />
              Timing
            </h6>
          </Col>
          <Col>
            <h6>
              <MdOutlinePermContactCalendar size={20} className="Home-Icon" />
              Course Name
            </h6>
          </Col>
          <Col>
            <h6>
              {" "}
              <MdOutlinePeopleOutline size={20} className="Home-Icon" /> Attract
              Trainee
            </h6>
          </Col>
          <Col>
            <h6>
              <SlCalender size={20} className="Home-Icon" />
              30 Day Visibility
            </h6>
          </Col>
        </Col>
      </Row>
      <div className="Home-foot">
        <h5 className="Home-center">We Provide the Best Subscription plans</h5>
        <Button
          className="btn-foot"
          onClick={() => navigate("/employer/subscription")}
        >
          View All{" "}
        </Button>
      </div>
    </div>
  );
}

export default Homepage;
