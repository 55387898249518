export const EMAIL_VERIFICATION = "EMAIL_VERIFICATION";
export const FILTER_BY_SEARCH = "FILTER_BY_SEARCH";
export const FILTER_SEARCH_BY_TYPE = "FILTER_SEARCH_BY_TYPE";
export const FILTER_SEARCH_BY_STATE = "FILTER_SEARCH_BY_STATE";
export const FILTER_SEARCH_BY_DISTRICT = "FILTER_SEARCH_BY_DISTRICT";
export const FILTER_SEARCH_BY_CITY = "FILTER_SEARCH_BY_CITY";
export const FILTER_SEARCH_BY_TECHNOLOGYOFF = "FILTER_SEARCH_BY_TECHNOLOGYOFF";
export const FILTER_SEARCH_BY_TECHNOLOGYON = "FILTER_SEARCH_BY_TECHNOLOGYON";
export const FILTER_SEARCH_BY_LANGUAGEOFF = "FILTER_SEARCH_BY_LANGUAGEOFF";
export const FILTER_SEARCH_BY_LANGUAGEON = "FILTER_SEARCH_BY_LANGUAGEON";
