import React from 'react'

const ApplicationResult = () => {
  return (
    <div>
      ApplicationResult
    </div>
  )
}

export default ApplicationResult
