import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Api from "../../../Api";
import "./AddSubCategory.css";

function SubCategory() {
  const [sectors, setSectors] = useState([]);
  const [sectorId, setSectorId] = useState(null);
  const [domains, setDomains] = useState([]);
  const [domainId, setDomainId] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoryName, setSubCategoryName] = useState('');
  const [message, setMessage] = useState('');

  // Fetch all sectors on component mount
  useEffect(() => {
    Api.get('/coursesector/getsector')
      .then(res => setSectors(res.data.map(sector => ({ value: sector._id, label: sector.sectorName }))))
      .catch(err => console.error(err));
  }, []);

  // Fetch domains when a sector is selected
  useEffect(() => {
    if (sectorId) {
      Api.get(`/coursedomain/getdomain/${sectorId}`)
        .then(res => setDomains(res.data.map(domain => ({ value: domain._id, label: domain.domainName }))))
        .catch(err => console.error(err));
    } else {
      setDomains([]); // Reset domains when no sector is selected
    }
  }, [sectorId]);

  // Fetch categories when a domain is selected
  useEffect(() => {
    if (domainId) {
      Api.get(`/coursecategory/getcategory/${domainId}`)
        .then(res => setCategories(res.data.map(category => ({ value: category._id, label: category.categoryName }))))
        .catch(err => console.error(err));
    } else {
      setCategories([]); // Reset categories when no domain is selected
    }
  }, [domainId]);

  // Fetch sub-categories when a category is selected
  useEffect(() => {
    if (categoryId) {
      Api.get(`/coursesubcategory/getsubcategory/${categoryId}`)
        .then(res => setSubCategories(res.data.map(subCategory => ({ value: subCategory._id, label: subCategory.subCategoryName }))))
        .catch(err => console.error(err));
    } else {
      setSubCategories([]); // Reset subcategories when no category is selected
    }
  }, [categoryId]);

  const addSubCategory = () => {
    if (sectorId && domainId && categoryId && subCategoryName) {
      Api.post('/coursesubcategory/createsubcategory', { sectorId, domainId, categoryId, subCategoryName })
        .then(res => {
          setSubCategories([...subCategories, { value: res.data._id, label: res.data.subCategoryName }]);
          setSubCategoryName(''); // Reset input field after successful addition
          setMessage('Sub-Category added successfully.');
        })
        .catch(err => {
          console.error(err);
          setMessage('Error adding sub-category.');
        });
    } else {
      setMessage('Please complete all fields before adding.');
    }
  };

  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      opacity: state.isFocused ? 1 : 0,  // Show input only when focused
    }),
    control: (provided) => ({
      ...provided,
      cursor: 'text', // Keep the cursor style like a text input
    }),
  };

  return (
    <div className="subcategory-container">
      <br></br>
      <br></br>

      <h2>Sub-Categories</h2>

      <div className="subcategory-grid">
        {/* Select Sector */}
        <Select
          isSearchable={true}
          options={sectors}
          onChange={(selectedOption) => setSectorId(selectedOption?.value)}
          placeholder="Select Sector"
          styles={customStyles}
        />

        {/* Select Domain (filtered by sector) */}
        <Select
          options={domains}
          onChange={selectedOption => setDomainId(selectedOption?.value)}
          placeholder="Select Domain"
          isDisabled={!sectorId}
          styles={customStyles}
          value={domains.find(option => option.value === domainId) || null}
        />

        {/* Select Category (filtered by domain) */}
        <Select
          options={categories}
          onChange={selectedOption => setCategoryId(selectedOption?.value)}
          placeholder="Select Category"
          isDisabled={!domainId}
          styles={customStyles}
          value={categories.find(option => option.value === categoryId) || null}
        />

        {/* Input Sub-Category Name */}
        <input
          type="text"
          value={subCategoryName}
          onChange={e => setSubCategoryName(e.target.value)}
          placeholder="Enter Sub-Category Name"
          disabled={!categoryId}
          className="subcategory-input"
        />
      </div>

      {/* Add Sub-Category Button */}
      <button 
        onClick={addSubCategory} 
        disabled={!sectorId || !domainId || !categoryId || !subCategoryName} 
        className="add-button"
      >
        Add Sub-Category
      </button>

      {/* Message Display */}
      {message && <div className="message">{message}</div>}

      {/* Display Sub-Categories */}
      <ul className="subcategory-list">
        {subCategories.map(subCategory => (
          <li key={subCategory.value}>{subCategory.label}</li>
        ))}
      </ul>
    </div>
  );
}

export default SubCategory;
