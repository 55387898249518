import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Api from "../../../Api";

function Create() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const [Carousel, setCarousel] = useState({});

  const handleFormSubmit = async () => {
    const details = {
      name: getValues().name,
    };
    const data = new FormData();
    data.append("Carousel", getValues().Carousel[0]);
    await Api.post("/aboutus/UploadCarousel", data).then(async (res) => {
      details.Carousel = res.data.path;
      await Api.post("/aboutus/createaboutus", details).then(async (res) => {
        console.log(res);
      });
    });
  };

  const navigate = useNavigate();

  return (
    <div>
      <Container>
        <h4 className="pages-title mt-5">Carousel UploadImage</h4>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Row className="pt-5">
            <Col sm={12} lg={2}>
              <label>Name: </label>
            </Col>
            <Col sm={12} lg={6}>
              <input
                {...register("name", { required: true })}
                className="kyc-input"
              />
              {errors.name && <p className="text-danger">name is required</p>}
            </Col>
          </Row>
          <Row className="pt-5">
            <Col sm={12} lg={2}>
              <label>Upload Image :</label>
            </Col>
            <Col sm={12} lg={6}>
              <input
                className=""
                type="file"
                {...register("Carousel", { required: true })}
                onChange={(e) => {
                  setCarousel(e.target.files[0]);
                  console.log("e.target.files[0]", e.target.files[0]);
                }}
              />
              {errors.Carousel && (
                <p className="text-danger">uploadImage is required</p>
              )}
            </Col>
          </Row>
          <div className="mt-5 px-4" style={{ textAlign: "center" }}>
            <button
              className="button1 m-2 p-2"
              type="submit"
              onClick={() => navigate(-1)}
            >
              Submit
            </button>
            <button className="button2 m-2 p-2" type="reset">
              Cancel
            </button>
          </div>
        </form>
      </Container>
    </div>
  );
}

export default Create;
