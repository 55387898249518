import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { DownOutlined, FileDoneOutlined, UpOutlined } from '@ant-design/icons'; 
import './Curriculam.scss'; 
import Api from '../../../../Api.js';

function CourseCurriculum() {
    const [activeIndex, setActiveIndex] = useState(null);
    const contentRefs = useRef([]); 
    const [contentHeights, setContentHeights] = useState([]); 

 

    const curriculum = [
        { 
            section: "Introduction to the Course", 
            subtopics: [
                "Overview of the Course",
                "Objectives and Goals",
                "Course Prerequisites",
                "Object-Oriented Programming",
                "Data Structures and Algorithms",
                "Error Handling and Debugging"
            ]
        },
        { 
            section: "Module 1: Basics of Programming", 
            subtopics: [
                "Introduction to Programming Concepts",
                "Setting Up Your Environment",
                "Writing Your First Program"
            ]
        },
        { 
            section: "Module 2: Advanced Topics", 
            subtopics: [
                "Object-Oriented Programming",
                "Data Structures and Algorithms",
                "Error Handling and Debugging"
            ]
        },
        { 
            section: "Final Project", 
            subtopics: [
                "Project Overview",
                "Submission Guidelines",
                "Evaluation Criteria"
            ]
        },
    ];

    const toggleSection = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    useEffect(() => {
        setContentHeights(
            contentRefs.current.map(ref => (ref ? ref.scrollHeight : 0))
        );
    }, []);

    return (
        <div className="container-fluid mt-4">
            <div className="row">
                <div className="col-md-12">
                    <h2 className='h2-content'>Course Curriculum</h2>
                    <div>
                        {curriculum.map((curriculumItem, index) => (
                            <div key={index} className="curriculum-section mb-2">
                                <div 
                                    onClick={() => toggleSection(index)} 
                                    style={{ cursor: 'pointer', padding: '15px', background: '#f8f9fa', border: '1px solid #dee2e6', borderRadius: '4px' }}
                                >
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0">
                                            {curriculumItem.section}
                                        </h5>
                                        <Button
                                            variant="link"
                                            className="btn-icon"
                                            style={{ padding: '0' }}
                                        >
                                            {activeIndex === index ? (
                                                <UpOutlined style={{ fontSize: '20px', color: '#007bff' }} />
                                            ) : (
                                                <DownOutlined style={{ fontSize: '20px', color: '#007bff' }} />
                                            )}
                                        </Button>
                                    </div>
                                </div>
                                <div 
                                    ref={(el) => (contentRefs.current[index] = el)}
                                    style={{
                                        maxHeight: activeIndex === index ? `${contentHeights[index]}px` : '0px',
                                        overflow: 'hidden',
                                        transition: 'max-height 0.5s ease',
                                    }}
                                >
                                    <div style={{ padding: '10px', background: '#e9ecef' }}>
                                        <ul className='sub-topic-style'>
                                            {curriculumItem.subtopics.map((subtopic, subIndex) => (
                                                <li className='li-course-sub-topic' key={subIndex}><FileDoneOutlined className='curriculam-icon' />{subtopic}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CourseCurriculum;
