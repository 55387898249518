import React from 'react';
import { Container, Card } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import Layout from '../Components/Layout';

const CancellationPolicy = () => {
    return (
        <Layout>
        <Container className="mt-5">
            <h1 className="text-center mb-4">Cancellation Policy</h1>
            <Card>
                <Card.Body>
                    <h4 className="mb-3"><FaCheckCircle className="text-primary" /> Introduction</h4>
                    <p>At Aroganam, we are committed to offering valuable educational services. Please read the following cancellation policy thoroughly before making any payments or enrolling in any courses.</p>

                    <h4 className="mb-3"><FaCheckCircle className="text-primary" /> Eligibility for Refunds</h4>
                    <p>If you experience double entry or payment failure due to our error, report it to us. We'll investigate and resolve the issue within 48 hours and provide a refund if applicable. Once the refund is approved by <strong>AROGANAM TECHNOLOGIES PVT LTD</strong>, it will take 8 to 10 working days to refund to your account.</p>

                    <h4 className="mb-3"><FaCheckCircle className="text-primary" /> Refund Process</h4>
                    <p>To request a refund, please follow these steps:</p>
                    <ul>
                        <li>Provide proof of purchase.</li>
                        <li>Fill out the refund request form.</li>
                        <li>Return the product if applicable.</li>
                    </ul>
                    <p>Once approved, you can expect to receive your refund within 8 to 10 working days.</p>

                    <h4 className="mb-3"><FaCheckCircle className="text-primary" /> Note</h4>
                    <p>The refund is only applicable for double payment or payment failure.</p>

                    <h4 className="mb-3"><FaCheckCircle className="text-primary" /> Project Modifications and Scope Changes</h4>
                    <p>Clients are encouraged to review and approve project scopes and service agreements before work begins. Any modifications requested after service delivery will incur additional costs. Refunds will not be issued for services or solutions already provided, even in cases of scope adjustments.</p>

                    <h4 className="mb-3"><FaCheckCircle className="text-primary" /> Only Double Entry</h4>
                    <p>All financial transactions and critical system changes will undergo dual verification. This ensures that every entry or modification is reviewed by two separate individuals or systems before being finalized, preventing errors and ensuring accountability.</p>

                    <h4 className="mb-3"><FaCheckCircle className="text-primary" /> No Cancellations Post Payment</h4>
                    <p>Once a payment is successfully processed, the enrollment is confirmed. We do not accept cancellations after the payment has been completed, whether it is for courses, learning materials, or any other services provided by AROGANAM TECHNOLOGIES PVT LTD.</p>

                    <h4 className="mb-3"><FaCheckCircle className="text-primary" /> Non-Transferable Payments</h4>
                    <p>Payments made for a specific course or service are non-transferable. This means that the paid amount cannot be applied to any other courses or services, nor can it be transferred to another user.</p>

                    <h4 className="mb-3"><FaCheckCircle className="text-primary" /> Subscription-Based Services</h4>
                    <p>If your purchase involves a subscription-based service, you may choose to stop using the service at any time. However, no refund or prorated amount will be provided for unused portions of the subscription period. Please refer to our Refund Policy for more details.</p>

                    <h4 className="mb-3"><FaCheckCircle className="text-primary" /> Promotional Offers and Discounts</h4>
                    <p>Any cancellations or modifications for enrollments made under a promotional offer or discount will not be considered. Promotional offers are subject to terms, and once payment is made, the cancellation clause still applies.</p>

                    <h4 className="mb-3"><FaCheckCircle className="text-primary" /> Content Access</h4>
                    <p>Once access to the content or courses is granted after the payment, no cancellations will be accepted. Please ensure you fully understand the course content and service before making a payment.</p>
                </Card.Body>
            </Card>
        </Container>
        </Layout>
    );
};

export default CancellationPolicy;
