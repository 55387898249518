import React, { useState, useEffect, useContext } from 'react';
import { Container, Card, Button } from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CourseCarousel.css';
import AOS from 'aos'; 
import 'aos/dist/aos.css'; 
import img from '../Images/unlock1.png';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from './ThemeContext';

const courses = [
  {
    image: img,
    title: 'The Complete 2024 Web Development Bootcamp',
    author: 'Dr. Angela Yu',
    rating: 4.7,
    reviews: 405603,
    price: '₹3,099',
    tag: 'Bestseller',
    duration: '60 hours',
    level: 'Beginner',
    description: 'Learn to code by building real-world applications. Perfect for beginners!',
  },
  {
    image: img,
    title: 'The Web Developer Bootcamp 2024',
    author: 'Colt Steele',
    rating: 4.7,
    reviews: 276170,
    price: '₹3,099',
    duration: '40 hours',
    level: 'Intermediate',
    description: 'A comprehensive bootcamp for aspiring web developers.',
  },
  {
    image: img,
    title: 'Web Development Masterclass - Online Certification',
    author: 'YouAccel Training',
    rating: 4.3,
    reviews: 9957,
    price: '₹3,099',
    duration: '30 hours',
    level: 'Advanced',
    description: 'Master web development with this online certification course.',
  },
  {
    image: img,
    title: 'The Complete Web Developer Course 3.0',
    author: 'Rob Percival',
    rating: 4.3,
    reviews: 72254,
    price: '₹4,499',
    duration: '50 hours',
    level: 'Beginner to Advanced',
    description: 'Learn to build websites and web applications from scratch.',
  },
];

const CourseCarousel = () => {

  const { theme } = useContext(ThemeContext); 
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);

  return (
    <Container fluid className={theme.trendingCourse}>
      <h1 className="text-center my-4" style={{fontWeight:"500"}}>Our Trending Courses</h1>
      <Slider {...settings} className="course-slider">
        {courses.map((course, index) => (
          <CourseCard key={index} course={course} index={index} />
        ))}
      </Slider>
    </Container>
  );
};

const CourseCard = ({ course, index }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const navigate = useNavigate(); 

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const handlePriceClick = () => {
    navigate('/pricingpayform', { state: { price: course.price } });
  };

  return (
    <div
      className="p-3 mt-3"
      data-aos={index % 2 === 0 ? "fade-up-right" : 'fade-up-left'}
      data-aos-delay={`${index * 100}`} 
    >
      <Card className={`course-card flip-card ${isFlipped ? 'flipped' : ''}`}>
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <Card.Img variant="top" className='cou-image' src={course.image} />
            <Card.Body className='course-card-body'>
              <Card.Title className='course-title'>{course.title}</Card.Title>
              <Card.Text>
                {course.author} <br />
                <strong>Rating: {course.rating} ({course.reviews} reviews)</strong>
              </Card.Text>
              <Button variant="success" className="price-button" onClick={handlePriceClick}>
                rs: {course.price}
              </Button>
              {course.tag && <span className="bestseller-badge">{course.tag}</span>}
              <div className="flip-icon" onClick={handleFlip}>
                <i className="fas fa-sync-alt"></i>
              </div>
            </Card.Body>
          </div>
          <div className="flip-card-back">
            <Card.Body>
              <Card.Title>{course.title}</Card.Title>
              <Card.Text>
                <strong>Duration:</strong> {course.duration} <br />
                <strong>Level:</strong> {course.level} <br />
                <strong>Description:</strong> {course.description}
              </Card.Text>
              <Button variant="secondary" className="back-button" onClick={handleFlip}>
                Back
              </Button>
            </Card.Body>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CourseCarousel;
