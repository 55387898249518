import React from "react";
import "../../../Components/Css/Employer/projectpost.scss";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useForm, form } from "react-hook-form";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";

function HoldForm() {
  const navigate = useNavigate();
  const id = localStorage.getItem("id");
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const handleCreateFormSubmit = async () => {
    const createHoldDetails = {
      userId: id,
      Name: getValues().name,
      Role: getValues().role,
      Remark: getValues().remark,
    };

    await Api.post(`hold/createHold`, createHoldDetails).then((resp) => {
      console.log("resp.data", resp.data);
      navigate(-2);
    });
  };
  return (
    <div>
      <Container className=" content-style ">
        <Row style={{ marginLeft: "5%" }}>
          <form onSubmit={handleSubmit(handleCreateFormSubmit)}>
            <br />
            <div className="pages-title mt-3">Hold Form:</div>
            <Row className="mt-4">
              <Col sm={12} lg={4}>
                <label className="create-title">Name</label>
                <br />
                <input
                  {...register("name", { required: true })}
                  className="Create-input"
                />
                {errors.name && (
                  <p className="error-text-color">Name is required</p>
                )}
              </Col>
              <Col sm={12} lg={4}>
                <label className="create-title">Role</label>
                <br />
                <input
                  {...register("role", { required: true })}
                  className="Create-input"
                />
                {errors.role && (
                  <p className="error-text-color">Role is required</p>
                )}
              </Col>
              <Col md={4}>
                <label className="create-title">Remarks</label>
                <br />
                <input
                  {...register("remark", { required: true })}
                  className="Create-input"
                />
                {errors.remark && (
                  <p className="error-text-color">Remark is required</p>
                )}
              </Col>
            </Row>
            <br />
            <div className="submitbuttons p-2">
              <button className="button1 m-2 p-2" type="submit">
                Submit
              </button>
              <button className="button2 m-2 p-2" type="reset">
                Reset
              </button>
            </div>
          </form>
        </Row>
      </Container>
    </div>
  );
}

export default HoldForm;
