import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Row, Col } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../../Api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "aos/dist/aos.css";
import AOS from "aos";

function TrainerBlog() {
  const location = useLocation();
  console.log("location", location);
  const editData = location.state?.record;
  const [file, setFile] = useState();
  const [userData, setUserData] = useState(editData || {});
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate("");

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: "ease-in-out",
    });
    if (editData) {
      Object.keys(editData).forEach((key) => setValue(key, editData[key]));
    }
  }, [editData, setValue]);

  const handleFormSubmit = async () => {
    const formData = getValues();
    try {
      const Details = {
        id: editData ? editData._id : userId,
        domain: formData.domain,
        subdomain: formData.subdomain,
        Tag: formData.Tag,
        Image: "",
        description: formData.description,
      };

      const data = new FormData();
      data.append("file", file);
      data.append("upload_preset", "darshan");

      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dzblzw7ll/image/upload",
        { method: "POST", body: data }
      );
      const cloudinaryData = await response.json();
      Details.Image = cloudinaryData.secure_url;

      if (editData) {
        await Api.put(`trainer/updatetrainerBlog/${editData._id}`, Details);
      } else {
        await Api.post(`trainer/addTrainerBlog`, Details);
      }
      toast.success("Blog entry successfully saved!");
      setTimeout(() => {
        navigate("/trainer/Blog/BlogTable");
      }, 3000);
    } catch (error) {
      console.error(error);
      toast.error("Error saving blog entry.");
    }
  };

  return (
    <div className="p-5 mt-5">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Row className="mb-3">
          <Col sm={24} md={8} className="p-2">
            <label>Domain</label>
            <select
              {...register("domain", { required: true })}
              className="Stud-blog-input"
            >
              <option value="">Select Domain</option>
              <option value="IT">IT</option>
              <option value="Construction">Construction</option>
              <option value="HR">HR</option>
              <option value="Mech">Mech</option>
            </select>
            {errors.domain && <span>Domain is required</span>}
          </Col>
          <Col sm={24} md={8} className="Stud-blog-col p-2">
            <label>Sub-Domain</label>
            <select
              // value={subdomain}
              {...register("subdomain", { required: true })}
              // onChange={(e) => setSubdomain(e.target.value)}
              className="Stud-blog-input"
            >
              <option value="">Select Sub-Domain</option>
              <option value="Software">Software</option>
              <option value="Hardware">Hardware</option>
              <option value="Engineer">Engineer</option>
              <option value="Finance">Finance</option>
            </select>

            <br />
            {errors.subdomain && (
              <span className="Stud-personal-error">
                Sub-Domain is required
              </span>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={24} md={8} className="Stud-blog-col p-2">
            <label>Tag</label>
            <input
              // defaultValue={userData?.Tag}
              type="title"
              className="Stud-blog-input"
              {...register("Tag", { required: true })}
            />
            <br />
            {errors.Tag && (
              <span className="Stud-personal-error">Tag required</span>
            )}
          </Col>

          <Col sm={24} md={8} className="Stud-blog-col p-2">
            <label>Upload Image</label>
            <input
              type="file"
              accept=".png,.jpg,.jpeg"
              className="Stud-blog-input"
              // onChange={(e) => {
              //   handleChange(e);
              // }}
              // {...register("Image", { required: true })}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={24} md={8} className="Stud-blog-col p-2">
            <label>Description</label>
            <textarea
              // defaultValue={userData?.description}
              rows="3"
              className="Stud-blog-input"
              {...register("description", { required: true })}
            />
            {errors.description && (
              <span className="Stud-personal-error">Description required</span>
            )}
          </Col>
        </Row>
        <div className="submitbuttons p-2">
          <button className="button1 m-2 p-2" type="submit" style={{}}>
            Submit
          </button>
          <button className="button2 m-2 p-2" type="reset">
            Reset
          </button>
          <ToastContainer />
        </div>
      </form>
    </div>
  );
}

export default TrainerBlog;
