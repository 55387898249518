import React from "react";
import { RiSuitcaseLine } from "react-icons/ri";
import { TbWorld } from "react-icons/tb";
import { BiLocationPlus } from "react-icons/bi";
import "../../Components/Css/Professional/FreelancingPay_Clickhere.scss";
import { useLocation } from "react-router-dom";

const FreelancingPay_Clickhere = () => {
  const state = useLocation().state;
  console.log("state", state);
  return (
    <div className="job_description_title">
      <div className="description_content">
        <h4 className="job_title_heading">{state.jobTitle}</h4>
        <div className="heading_class ">Technology</div>
        <li className="sub_heading_class ">{state.technology}</li>
        <div className="heading_class mt-2">Country</div>
        <li className="sub_heading_class">
          <label>
            <BiLocationPlus />
          </label>
          <span style={{ marginLeft: "5px" }}>{state.country}</span>
        </li>
        <div className="heading_class mt-2">Timing For PAy</div>
        <li className="sub_heading_class">
          <label>
            <TbWorld />
          </label>
          <span style={{ marginLeft: "5px" }}>{state.timingForPay}</span>
        </li>

        <div className="heading_class mt-2">Language</div>
        <li className="sub_heading_class">
          <label>
            <RiSuitcaseLine />
          </label>
          <span
            style={{
              marginLeft: "5px",
            }}
          >
            {state.language}
          </span>
        </li>
        <div className="heading_class mt-2">Job Description</div>
        <li className="sub_heading_class">{state.description}</li>

        <div className="description_button_container">
          <button className="button2 mt-5 mb-3">contact us</button>
          <button className="button1 mt-5 mb-3 ms-3">Apply</button>
        </div>
      </div>
    </div>
  );
};

export default FreelancingPay_Clickhere;
