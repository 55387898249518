import React from 'react';
import { CheckOutlined } from '@ant-design/icons'; 
import './WhatYoullLearn.scss';

function WhatYoullLearn() {
    const learningOutcomes = [
        "Master programming fundamentals and best practices",
        "Develop proficiency in setting up and managing a development environment",
        "Create structured programs using core coding concepts",
        "Implement essential data structures and algorithms for efficient coding",
        "Apply effective debugging and error-handling techniques",
        "Build a comprehensive, practical final project that showcases all learned skills",
    ];

    return (
        <div className="container mt-4">
            <h2 className='h2-content'>What You’ll Learn</h2>
            <ul className="learning-outcomes-list">
                {learningOutcomes.map((outcome, index) => (
                    <li key={index} className="learning-outcome-item">
                        <CheckOutlined style={{ color: '#28a745', fontSize: '18px', marginRight: '10px' }} />
                        {outcome}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default WhatYoullLearn;
