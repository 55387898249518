import TrainerDashboard from "../Pages/Trainer/TrainerDashboard";
import { Navigate } from "react-router-dom";
import TrainerProfile from "../Pages/Trainer/Profile";
import TransactionHistory from "../Pages/Trainer/TransactionHistory/TransactionHistory";
import CoursesList from "../Pages/Trainer/Courses/CoursesList";
import ApprovedCourse from "../Pages/Trainer/Courses/ApprovedCourse";
import TrainerBlog from "../Pages/Trainer/TrainerBlog/TrainerBlog";
import TrainerPackageDetails from "../Pages/Trainer/TrainerBlog/TrainerPackageDetails";
import BlogTable from "../Pages/Trainer/TrainerBlog/BlogTable";
import NewtrainerBlog from "../Pages/Trainer/TrainerBlog/NewtrainerBlog";
import Trainer from "../Pages/Trainer/NewPage/Trainer";
import AddDetails from "../Pages/Professional/MyProfile/AddDetails";

const role = localStorage.getItem("role");

const TrainerRoutes = [
  {
    path: "/trainer/trainerdashboard",
    name: "TrainerDashboard",
    element:
      role === "trainer" ? <TrainerDashboard /> : <Navigate to="/login" />,
  },
  {
    path: "/trainer/trainerprofile",
    name: "TrainerProfile",
    element: role === "trainer" ? <TrainerProfile /> : <Navigate to="/login" />,
  },
  {
    path: "/trainer/courses/courses-list",
    name: "CoursesList",
    element: role === "trainer" ? <CoursesList /> : <Navigate to="/login" />,
  },
  {
    path: "/trainer/courses/approved-list",
    name: "ApprovedCourses",
    element: role === "trainer" ? <ApprovedCourse /> : <Navigate to="/login" />,
  },
  {
    path: "/trainer/trainerprofile/training",
    name: "Trainerdetails",
    element:
      role === "trainer" ? (
        <TrainerProfile defaultTab="3" />
      ) : (
        <Navigate to="/login" />
      ),
  },
  {
    path: "/trainer/trainerprofile/counselling",
    name: "Trainerdetails",
    element:
      role === "trainer" ? (
        <TrainerProfile defaultTab="4" />
      ) : (
        <Navigate to="/login" />
      ),
  },
  {
    path: "/trainer/transactionHistory/transactionHistory",
    name: "TransactionHistory",
    element:
      role === "trainer" ? <TransactionHistory /> : <Navigate to="/login" />,
  },
  {
    path: "/trainer/Blog/TrainerBlog",
    name: "Trainerblog",
    element: role === "trainer" ? <TrainerBlog /> : <Navigate to="/login" />,
  },
  {
    path: "/trainer/Blog/BlogTable",
    name: "Trainerblog",
    element: role === "trainer" ? <BlogTable /> : <Navigate to="/login" />,
  },
  {
    path: "/trainer/Blog/TrainerPackageDetails",
    name: "Trainerpackagedetails",
    element:
      role === "trainer" ? <TrainerPackageDetails /> : <Navigate to="/login" />,
  },

  {
    path: "/trainer/adddetails",
    name: "Trainerpackagedetails",
    element: role === "trainer" ? <AddDetails /> : <Navigate to="/login" />,
  },
  {
    path: "/trainer/Blog/NewtrainerBlog",
    name: "Trainerpackagedetails",
    element: role === "trainer" ? <NewtrainerBlog /> : <Navigate to="/login" />,
  },
];

export default TrainerRoutes;
