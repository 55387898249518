// import React from "react";
// import { Col, Row, Card } from "antd";
// import "../../../Components/Css/Dashboard.scss";
// import { AiOutlineUser } from "react-icons/ai";
// import { MdOutlinePersonPin } from "react-icons/md";
// import { FaSchool, FaGraduationCap } from "react-icons/fa";
// import { BiBuildings, BiBuilding } from "react-icons/bi";
// import { useNavigate } from "react-router-dom";

// export default function AdminDashboard() {
//   const navigate = useNavigate();
//   return (
//     <div className=" ">
//       <Row className="admin-row">
//         <Col sm={24} md={12} lg={8} className="dash-col">
//           <Card className="Dash-cards" onClick={() => navigate("/admin/score")}>
//             <div className="card-field">
//             <div>
//               <FaGraduationCap className="fieldicon1"/> {" "}
//             </div>
//               <div>
//                 <h4 className="fieldsname">Students</h4>
//                 <h4 className="fieldscount">200</h4>
//               </div>
//             </div>
//           </Card>
//         </Col>
//         <Col sm={24} md={12} lg={8} className="dash-col">
//           <Card className="Dash-cards">
//             <div className="card-field">
//             <div>
//               <MdOutlinePersonPin className="fieldicon2"/>
//             </div>
//               <div>
//                 <h4 className="fieldsname">Professional</h4>
//                 <h4 className="fieldscount">100</h4>
//               </div>
//             </div>
//           </Card>
//         </Col>
//         <Col sm={24} md={12} lg={8} className="dash-col">
//           <Card className="Dash-cards">
//             <div className="card-field">
//             <div>
//               <AiOutlineUser className="fieldicon3"/>
//             </div>
//               <div>
//                 <h4 className="fieldsname">Employer</h4>
//                 <h4 className="fieldscount">100</h4>
//               </div>
//             </div>
//           </Card>
//         </Col>
//         <Col sm={24} md={12} lg={8} className="dash-col">
//           <Card className="Dash-cards">
//             <div className="card-field">
//             <div>
//               <BiBuildings className="fieldicon4"/>
//             </div>
//               <div>
//                 <h4 className="fieldsname">Colleges</h4>
//                 <h4 className="fieldscount">100</h4>
//               </div>
//             </div>
//           </Card>
//         </Col>
//         <Col sm={24} md={12} lg={8} className="dash-col">
//           <Card className="Dash-cards">
//             <div className="card-field">
//             <div>
//               <FaSchool className="fieldicon5"/>
//             </div>
//               <div>
//                 <h4 className="fieldsname">Schools</h4>
//                 <h4 className="fieldscount">100</h4>
//               </div>
//             </div>
//           </Card>
//         </Col>
//         <Col sm={24} md={12} lg={8} className="dash-col">
//           <Card className="Dash-cards">
//             <div className="card-field">
//             <div>
//               <BiBuilding className="fieldicon6"/>
//             </div>
//               <div>
//                 <h4 className="fieldsname">Training institution</h4>
//                 <h4 className="fieldscount">100</h4>
//               </div>
//             </div>
//           </Card>
//         </Col>
//       </Row>
//       <div className="colors"></div>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import { Col, Row, Card, Divider } from "antd";
import "../../../Components/Css/Dashboard.scss";
import {
  AiFillPauseCircle,
  AiFillPicture,
  AiOutlineUser,
} from "react-icons/ai";
import { MdOutlinePersonPin } from "react-icons/md";
import { FaSchool, FaGraduationCap } from "react-icons/fa";
import { BiBuildings, BiBuilding } from "react-icons/bi";
import { GiSunflower, GiTakeMyMoney } from "react-icons/gi";

import { HiUserGroup } from "react-icons/hi";

import Api from "./../../../Api";
import { Await } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import 'aos/dist/aos.css';
import AOS from 'aos';

export default function AdminDashboard() {
  const [studentCount, setStudentCount] = useState([]);
  const [professionalCount, setProfessionalCount] = useState([]);
  const [employerCount, setEmployerCount] = useState([]);
  const [institutionCount, setInstitutionCount] = useState([]);
const [vendorCount,setVendorCount]=useState([])
  const [collegeCount, setCollegeCount] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000, // Set animation duration in milliseconds
      easing: 'ease-in-out', // Animation easing
    });
  }, []);

  useEffect(() => {
    getCountStudent();
    getCountProfessional();
    getCountEmployer();
    getCountinstitutionCount();
    getCollege();
    getCountVendor();
  }, []);
  // Students
  const getCountStudent = async () => {
    await Api.get("/signup/getAll").then((res) => {
      setStudentCount(res.data.data.length);
    });
  };
  // professional
  const getCountProfessional = async () => {
    await Api.get("/employee/Employee_get").then((res) => {
      setProfessionalCount(res.data.data.length);
    });
  };
  // Employer
  const getCountEmployer = async () => {
    await Api.get("/employer/Employer_get").then((res) => {
      setEmployerCount(res.data.data.length);
      console.log("empcount", res);
    });
  };
//vendor
  const getCountVendor = async () => {
    await Api.get("/vendor/getAll").then((res) => {
      setVendorCount(res.data.data.length);
      console.log("empcount", res);
    });
  };
  // Institution
  const getCountinstitutionCount = async () => {
    await Api.get("/institute/institute_get").then((res) => {
      setInstitutionCount(res.data.data.length);
    });
  };
  // college
  const getCollege = async () => {
    await Api.get("/college/college_get").then((res) => {
      setCollegeCount(res.data.data.length);
    });
  };

  return (
    <div className=" " style={{ backgroundColor: "#eaeef3" }}>
      <Row className="admin-row">
        <Col sm={24} md={12} lg={6} className="dash-col">
          <Card data-aos="flip-left"
            className="AdminDash-cards"
            style={{
              backgroundColor: "#a979d1",
              borderColor: "#a979d1",
              width: "100%",
              height: "138px",
            }}
            onClick={() => navigate("/admin/score")}
          >
            <div className="card-field">
              <div>
                <FaGraduationCap className="Afieldicon1" />{" "}
              </div>
              <Divider type="vertical"></Divider>
              <div>
                <h4 className="fieldscount1">{studentCount}</h4>
                <h4 className="fieldsname1">Students</h4>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm={24} md={12} lg={6} className="dash-col">
          <Card data-aos="flip-left"
            className="AdminDash-cards"
            style={{
              backgroundColor: "#FFB100",
              borderColor: "#FFB100",
              width: "100%",
              height: "138px",
            }}
          >
            <div className="card-field">
              <div>
                <MdOutlinePersonPin className="Afieldicon2" />
              </div>
              <Divider type="vertical"></Divider>

              <div>
                <h4 className="fieldscount1">{professionalCount}</h4>
                <h4 className="fieldsname1">Professionals</h4>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm={24} md={12} lg={6} className="dash-col">
          <Card data-aos="flip-left"
            className="AdminDash-cards"
            style={{
              backgroundColor: "#2DCDDF",
              borderColor: "#2DCDDF",
              width: "100%",
              height: "138px",
            }}
          >
            <div className="card-field">
              <div>
                <AiOutlineUser className="Afieldicon3" />
              </div>
              <Divider type="vertical"></Divider>

              <div>
                {/* <h4 className="fieldscount1">{employerCount}</h4> */}
                <h4 className="fieldscount1">20</h4>

                <h4 className="fieldsname1">Employers</h4>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm={24} md={12} lg={6} className="dash-col">
          <Card data-aos="flip-left"
            className="AdminDash-cards"
            style={{
              backgroundColor: "#c32143",
              borderColor: "#c32143",
              width: "100%",
              height: "138px",
            }}
          >
            <div className="card-field">
              <div>
                <BiBuildings className="Afieldicon4" />
              </div>
              <Divider type="vertical"></Divider>

              <div>
                <h4 className="fieldscount1">{collegeCount}</h4>
                <h4 className="fieldsname1">Colleges</h4>
              </div>
            </div>
          </Card>
        </Col>

        <Col sm={24} md={12} lg={6} className="dash-col">
          <Card data-aos="flip-right"
            className="AdminDash-cards"
            style={{
              backgroundColor: "#e91e63",
              borderColor: "#e91e63",
              width: "100%",
              height: "138px",
            }}
          >
            <div className="card-field">
              <div>
                <BiBuilding className="Afieldicon6" />
              </div>
              <Divider type="vertical"></Divider>

              <div>
                <h4 className="fieldscount1">{institutionCount}</h4>
                <h4 className="fieldsname1">Training institution</h4>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm={24} md={12} lg={6} className="dash-col">
          <Card data-aos="flip-right"
            className="AdminDash-cards"
            style={{
              backgroundColor: "#2196F3",
              borderColor: "#2196F3",
              width: "100%",
              height: "138px",
            }}
          >
            <div className="card-field">
              <div>
                <GiSunflower className="Afieldicon6" />
              </div>
              <Divider type="vertical"></Divider>

              <div>
                <h4 className="fieldscount1">{vendorCount}</h4>
                <h4 className="fieldsname1">Vendors</h4>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm={24} md={12} lg={6} className="dash-col">
          <Card data-aos="flip-right"
            className="AdminDash-cards"
            style={{
              backgroundColor: "#E67D21",
              borderColor: "#E67D21",
              width: "100%",
              height: "138px",
            }}
          >
            <div className="card-field">
              <div>
                <GiTakeMyMoney className="Afieldicon6" />
              </div>
              <Divider type="vertical"></Divider>

              <div>
                <h4 className="fieldscount1">{institutionCount}</h4>
                <h4 className="fieldsname1">Over All Revenue</h4>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm={24} md={12} lg={6} className="dash-col">
          <Card data-aos="flip-right" 
            className="AdminDash-cards"
            style={{
              backgroundColor: "#5CB85C",
              borderColor: "#5CB85C",
              width: "100%",
              height: "138px",
            }}
          >
            <div className="card-field">
              <div>
                <HiUserGroup className="Afieldicon6" />
              </div>
              <Divider type="vertical"></Divider>

              <div>
                <h4 className="fieldscount1">{institutionCount}</h4>
                <h4 className="fieldsname1">Our Employees</h4>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <div className="colors"></div>
    </div>
  );
}
