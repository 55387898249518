import React, { useEffect, useState } from "react";
import "../../Components/Css/Professional/FreelancingPay.scss";
import { Row, Col, Card } from "antd";
import { BiLocationPlus } from "react-icons/bi";
import { TbWorld } from "react-icons/tb";
import { RiSuitcaseLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Api from "../../Api";
function FreelancingPay() {
  const users = [
    {
      id: 1,
      title: "Web Development",
      name: "React.js",
      companyname: "Cross Hurdle Systems",
      location: "Chennai",
      experience: "1",
    },
    {
      id: 2,
      title: "App development",
      name: "Angular",
      companyname: "Thinroot Software",
      location: "Bangalore",
      experience: "2",
    },
    {
      id: 3,
      title: "Backend Devoper",
      name: "Node.js",
      companyname: "Leba",
      location: "Coimbatore",
      experience: "1",
    },
    {
      id: 4,
      title: "Tech Support",
      name: "React.js",
      companyname: "Leanne Graham",
      location: "Bangalore",
      experience: "2",
    },
    {
      id: 5,
      title: "Tech Support",
      name: "React.js",
      companyname: "Leanne Graham",
      location: "Bangalore",
      experience: "4",
    },
  ];

  const [value, setValue] = useState([]);
  const [filterValue, setFilterValue] = useState([]);
  useEffect(() => {
    // getValues(users);
    getData();
  }, []);
  // const getValues = (users) => {
  //   // setValue(users);
  //   setFilterValue(users);
  // };

  const getData = async () => {
    await Api.get("/freelancingPost/FreelancingGet").then((res) => {
      setValue(res.data.data);
      setFilterValue(res.data.data);
    });
  };

  const handleSearch = (e) => {
    const name = e.target.value;
    const newData = value.filter((list) =>
      list.name.toLowerCase().includes(name.toLowerCase())
    );
    setFilterValue(newData);
    if (name === "") {
      setFilterValue(value);
    }
  };
  const handleLocation = (e) => {
    const location = e.target.value;
    const newValue = value.filter((lists) =>
      lists.location.toLowerCase().includes(location.toLowerCase())
    );
    setFilterValue(newValue);
    if (location === "") {
      setFilterValue(value);
    }
  };
  const handleTitle = (e) => {
    const title = e.target.value;
    const newTitle = value.filter((list1) =>
      list1.title.toLowerCase().includes(title.toLowerCase())
    );
    setFilterValue(newTitle);
    if (title === "") {
      setFilterValue(value);
    }
  };
  const navigate = useNavigate();

  return (
    <div className="">
      <Row>
        <Col lg={6}>
          <div className="filterContainer">
            <div className="pages-title mb-3 mt-2 ms-4">Filter</div>
            <div className="mt-4 ms-4">
              <label>Technology</label>
              <br />
              <input
                type="text"
                placeholder="Search..."
                onChange={handleSearch}
              />

              <br />
              <label className="mt-4">Location</label>
              <br />
              <select onChange={handleLocation}>
                <option value="">Choose Location</option>
                <option value="Chennai">Chennai</option>
                <option value="Bangalore">Bangalore</option>
                <option value="Coimbatore">Coimbatore</option>
              </select>
              <br />
              <label className="mt-4">Title</label>
              <br />
              <select onChange={handleTitle}>
                <option value="">Choose Title</option>
                <option value="Web Development">Web Development</option>
                <option value="App development">App development</option>
                <option value="Backend Devoper">Backend Devoper</option>
                <option value="Tech Support">Tech Support</option>
              </select>
            </div>
          </div>
        </Col>
        <Col sm={24} lg={18}>
          <div className="pages-title mb-3 mt-2 ms-4">
            Choose Your Matching Profile
          </div>
          <div className="card_Col_Container">
            {filterValue.map((index) => (
              <Card className="cardContainer ">
                <h4 className="payCardtitle">{index.jobTitle}</h4>
                <h6 className="paySubTitle">{index.technology}</h6>
                <h6 className="paySubTitle">{index.languages}</h6>
                <h6 className="paySubTitle">{index.price}</h6>

                <div className="locationIcon">
                  <label>
                    <BiLocationPlus />
                  </label>
                  {index.country}
                </div>
                <div className="reactIcon">
                  <label>
                    <TbWorld />
                  </label>
                  <span style={{ marginLeft: "4px" }}>{index.description}</span>
                </div>
                <div className="experienceIcon">
                  <label>
                    <RiSuitcaseLine />
                  </label>
                  <span style={{ marginLeft: "3px" }}>
                    {index.timingForPay}
                  </span>
                </div>
                <br />
                <div className=" Card_submitButton_container mt-2 me-2">
                  <button
                    className="button1"
                    type="submit"
                    onClick={() =>
                      navigate("/freelancingPay/freelancingpay_Clickhere", {
                        state: index,
                      })
                    }
                  >
                    Click here
                  </button>
                </div>
              </Card>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default FreelancingPay;
